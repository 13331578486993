import { SelectInput } from '@frontend/basic-forms';
import { UserStateName } from '@frontend/user/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import useUserStateSelect from './user-state-select.controller';

const ID = 'user-state-select';
export interface UserStateSelectProps {
    label?: string;
    className?: string;
    required?: boolean;
    submitted: boolean;
    onChange: (newValue: { value: UserStateName | string; label: any }, ) => void;
    value?: string | null;
    dispatch: ThunkDispatch<any, any, Action>;
}

export const UserStateSelect = (props: UserStateSelectProps) => {
    const viewProps = useUserStateSelect(props);

    return (
        <SelectInput
            id={ID}
            className={props.className}
            label={props.label || 'User state'}
            submitted={props.submitted}
            required={props.required}
            options={viewProps.options}
            value={viewProps.value}
            onChange={(value) => props.onChange(value as { value: UserStateName | string; label: any })}
        />
    );
};

export default UserStateSelect;
