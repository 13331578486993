import { Logger } from '@frontend/Logger';
import { useAppSelector } from '@frontend/common';
import { fetchPackage, packageStore } from '@frontend/package/redux';
import { Package } from '@frontend/package/types';
import { fetchSpotModuleSlot, slotStore } from '@frontend/slot/redux';
import { Slot } from '@frontend/slot/types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { navigationStore } from '../../redux/user-interface-navigation-slice';
import { UserInterfaceTemplateProps } from '../../user-interface-template-props';

interface ViewProps {
    slot: Slot | null;
}
const usePudoDropoff = (props: UserInterfaceTemplateProps): ViewProps => {
    const navigationState = useAppSelector(useSelector, navigationStore);
    const slotState = useAppSelector(useSelector, slotStore);
    const packageState = useAppSelector(useSelector, packageStore);
    const cache = props.cache || navigationState.cache;

    const [pack, changePackage] = useState<Package | null>(null);
    const [slot, changeSlot] = useState<Slot | null>(null);

    useEffect(() => {
        if (cache && cache.account_id && cache.transaction_id && cache.package_id) {
            props.dispatch(
                fetchPackage({
                    accountId: cache.account_id,
                    transactionId: cache.transaction_id,
                    packageId: cache.package_id
                })
            );
        } else Logger.error('Incorrect information passed', {}, { cache, packageState });
    }, []);

    useEffect(() => {
        const found = packageState.unordered.find((s) => s.id == cache?.package_id);
        if (found) changePackage(found);
    }, [cache, packageState]);

    useEffect(() => {
        if (pack) {
            props.dispatch(
                fetchSpotModuleSlot({
                    spotId: pack.spot_id,
                    moduleId: pack.module_id,
                    slotId: pack.slot_id
                })
            );
        } else Logger.error('Incorrect information passed', {}, { pack, slotState });
    }, [pack]);

    useEffect(() => {
        const found = slotState.unordered.find((s) => s.id == pack?.slot_id);
        if (found) changeSlot(found);
    }, [pack, slotState]);

    return {
        slot
    };
};

export default usePudoDropoff;
