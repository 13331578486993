import React from "react";
import { FormattedMessage } from "react-intl";

export enum UserStateName {
    USER_CREATED = "user_created",
    USER_REGISTERED = "user_registered",
    USER_BLOCKED = "user_blocked"
}

export class UserState {
    static readonly USER_CREATED = new UserState(
        UserStateName.USER_CREATED,
        (
            <FormattedMessage
                id='UserState.created'
                description='The displayed value for the UserState created'
                defaultMessage='User created'
            />
        )
    );

    static readonly USER_REGISTERED = new UserState(
        UserStateName.USER_REGISTERED,
        (
            <FormattedMessage
                id='UserState.registered'
                description='The displayed value for the UserState registered'
                defaultMessage='User registered'
            />
        )
    );

    static readonly USER_BLOCKED = new UserState(
        UserStateName.USER_BLOCKED,
        (
            <FormattedMessage
                id='UserState.blocked'
                description='The displayed value for the UserState blocked'
                defaultMessage='User blocked'
            />
        )
    );

    static readonly ALL = [UserState.USER_CREATED, UserState.USER_REGISTERED, UserState.USER_BLOCKED];

    private constructor(public readonly value: UserStateName, public readonly displayName: React.ReactNode) {}

    static getByValue(value: UserStateName): UserState | undefined {
        return UserState.ALL.find((userState) => userState.value === value);
    }
}