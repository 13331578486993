import { useEffect, useState } from 'react';

import { getClassName } from '../input-class';
import { PhoneInputProps } from './phone-input.component';

interface ViewProps {
    valid: boolean;
    touched: boolean;
    inputClass: string;
    isValid: () => boolean;
    changeTouched: React.Dispatch<React.SetStateAction<boolean>>;
    allowedInput: (value: string) => boolean;
}

const internationalRegex =
    /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/;

const usePhoneInput = (props: PhoneInputProps): ViewProps => {
    const [touched, changeTouched] = useState<boolean>(false);
    const [valid, changeValid] = useState<boolean>(props.valid !== undefined ? props.valid : true);

    const allowedInput = (value: string): boolean => {
        if (value == '' || value == '+') return true;
        return /^[0-9]+$/.test(value.replace('+', ''));
    };

    const isValid = (): boolean => {
        let check = true;
        if (check === true && props.required === true && (props.value === '' || props.value === undefined)) check = false;
        if (props.valid !== undefined && check === true) check = props.valid;

        if (props.value && check) {
            check = internationalRegex.test(props.value);
            /*if(props.value.startsWith('+')) check = internationalRegex.test(props.value);
            else check = nonInternational.test(props.value);*/
        }

        changeValid(check);
        return check;
    };

    useEffect(() => {
        if (props.isValidCallback) {
            props.isValidCallback(isValid());
        } else isValid();
    }, [props.value, props.valid, props.required]);

    useEffect(() => {
        if (props.submitted) {
            !touched && changeTouched(true);
        }
    }, [props.submitted]);
    return {
        valid,
        touched,
        inputClass: getClassName(touched, valid, props.value),
        isValid,
        changeTouched,
        allowedInput
    };
};

export default usePhoneInput;
