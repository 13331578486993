import { Logger } from '@frontend/Logger';
import { AuthenticationManager } from '@frontend/authentication-v2';

export enum WebsocketState {
    NEW,
    INIT,
    CONNECTED,
    ERROR
}

type CallBackFunction = (data: MessageEvent<string>) => void;

export abstract class PubSubConnection {
    #blocked: boolean;
    #service: string;
    #websocket: WebSocket | undefined;

    #websocketState: WebsocketState = WebsocketState.NEW;
    #emitter = new EventEmitter<WebsocketState>();

    #connectionTimeout: number | undefined;

    protected callbacks: Map<string, CallBackFunction> = new Map<string, CallBackFunction>();
    protected variables: Array<{ [key: string]: any }> = [];

    constructor(service: string, variables?: Array<{ [key: string]: any }>) {
        this.#blocked = false;
        this.#service = service;
        this.variables = variables || [];
        this.initiateWebsocket();
    }

    get service() {
        return this.#service;
    }
    get websocket() {
        return this.#websocket;
    }
    get websocketState() {
        return this.#websocketState;
    }
    set websocketState(state: WebsocketState) {
        this.#emitter.emit(state);
        this.#websocketState = state;
    }

    get connectionTimeout() {
        return this.#connectionTimeout;
    }

    subscribeToWebsocketState(callback: (value: WebsocketState) => void) {
        this.#emitter.subscribe(callback);
    }

    unsubscribeFromWebsocketState(callback: (value: WebsocketState) => void) {
        this.#emitter.unsubscribe(callback);
    }

    protected abstract onMessageEvent(event: MessageEvent<string>): void;

    public send = (message: string): boolean => {
        if (this.#websocket == undefined || this.#websocketState === WebsocketState.NEW) {
            Logger.error('No websocket connection available. Skip sending message.', {}, message);
            return false;
        }
        if (this.#websocketState === WebsocketState.INIT) {
            Logger.error('Websocket connection available but not ready yet. Skip sending message.', {}, message);
            return false;
        }
        this.#websocket.send(message);
        return true;
    };

    public cleanup() {
        if (this.#websocket) {
            this.#websocket.close(1000);
            this.#websocket.onopen = null;
            this.#websocket.onmessage = null;
            this.#websocket.onclose = null;
            this.#websocket.onerror = null;
        }
    }

    public async destroy() {
        await new Promise((resolve) =>
            setInterval(() => {
                if (!this.#blocked) resolve(null);
            }, 500)
        );
        this.cleanup();
        this.#websocket = undefined;
    }

    public addCallback(id: string, callback: CallBackFunction): PubSubConnection {
        this.callbacks.set(id, callback);
        return this;
    }
    public removeCallback(id: string): PubSubConnection {
        this.callbacks.delete(id);
        return this;
    }

    protected initiateWebsocket() {
        this.#blocked = true;
        let wssUrl = 'wss://' + this.#service + '-api.' + process.env['NX_API_DOMAIN'] + '/socket/v1/subscribe';
        if (process.env['NX_BUILD_ENV'] === 'edge') {
            const service_proxy_ports: { [key: string]: number } = {
                'account-api': 8000,
                'authentication-api': 8001,
                'authorization-api': 8002,
                'badge-api': 8003,
                'budget-api': 8027,
                'certificate-api': 8021,
                'contact-api': 8004,
                'document-api': 8020,
                'edge-api': 8019,
                'event-api': 8005,
                'import-api': 8022,
                'module-api': 8006,
                'notification-api': 8007,
                'package-api': 8016,
                'product-api': 8008,
                'rakinda-api': 8018,
                'slot-api': 8009,
                'spot-api': 8010,
                'stock-api': 8017,
                'sync-api': 8011,
                'transaction-api': 8012,
                'user-interface-api': 8015,
                'user-api': 8013,
                'workflow-api': 8014
            };
            wssUrl = 'ws://localhost:' + service_proxy_ports[this.#service + '-api'] + '/socket/v1/subscribe';
        }
        AuthenticationManager.getInstance()
            .waitForToken()
            .then((token) => {
                const vars = this.variables.map((variable) => `&${Object.keys(variable)[0]}=${Object.values(variable)[0]}`).join('');
                const url = `${wssUrl}?jwt_token=${token.jwt_token}${vars}`;
                this.#websocket = new WebSocket(url);
                this.#websocket.onopen = (event: Event) => this.onOpen(event);
                this.#websocket.onmessage = (event: MessageEvent<string>) => this.onMessage(event);
                this.#websocket.onclose = (event: CloseEvent) => this.onClose(event);
                this.#websocket.onerror = (event: Event) => this.onError(event);
            })
            .catch(() => {
                Logger.warn('No valid token information found. Thus unable to connect websocket.');
            })
            .finally(() => {
                this.#blocked = false;
            });
    }

    protected onOpen(event: Event): void {
        Logger.log('PubSub websocket connection succeeded.', {}, this.websocketState);
        this.#connectionTimeout = undefined;
        this.websocketState = WebsocketState.CONNECTED;
        dispatchEvent(new CustomEvent<Event>('PubSub:onOpen', event));
    }

    protected onMessage(event: MessageEvent<string>): void {
        Logger.log('Received PubSub websocket message.', {}, event.data);
        dispatchEvent(new CustomEvent<MessageEvent<string>>(`PubSub:onMessage:${this.#service}`, { detail: event }));
        this.callbacks.forEach((callback) => callback(event));
        this.onMessageEvent(event);
    }

    protected onClose(event: CloseEvent): void {
        Logger.warn('PubSub websocket connection closed.', {}, { code: event.code, reason: event.reason });
        if (!AuthenticationManager.getInstance().hasValidToken()) AuthenticationManager.getInstance().refresh();
        this.cleanup();
        this.#websocket = undefined;
        this.initiateWebsocket();
        this.#websocketState = WebsocketState.NEW;
        dispatchEvent(new CustomEvent<CloseEvent>('PubSub:onClose', event));
    }

    protected onError(event: Event): void {
        Logger.error('PubSub websocket connection error.', {}, event);
        this.cleanup();
        this.websocketState = WebsocketState.ERROR;
        this.#connectionTimeout = 2; //calculateNextTimeout(this.#connectionTimeout || 1);
        setTimeout(() => {
            this.websocketState = WebsocketState.NEW;
            this.#websocket = undefined;
            this.initiateWebsocket();
        }, 1000 * (this.#connectionTimeout || 1));

        dispatchEvent(new CustomEvent<Event>('PubSub:onError', event));
    }

    /**
     * This funtion will wait indefinitely until the websocket is connected
     * only then the returned promise will resolve
     */
    public waitUntilConnected(): Promise<PubSubConnection> {
        return new Promise((resolve) => {
            if (this.websocketState === WebsocketState.CONNECTED) resolve(this);
            else {
                const timeout = setInterval(() => {
                    if (this.websocketState === WebsocketState.CONNECTED) {
                        resolve(this);
                        clearInterval(timeout);
                    }
                }, 1000);
            }
        });
    }
}

/**
 * uses finbonacci sequence to calculate next timeout
 * @param previousTimeout
 * @param minimumTimeout min set to 5 seconds
 * @param maximumTimeout max set to 1 minute
 * @returns
 */
// function calculateNextTimeout(previousTimeout: number, minimumTimeout = 5, maximumTimeout = 60) {
//     if (previousTimeout >= maximumTimeout) return previousTimeout;
//     let fib1 = 0;
//     let fib2 = 1;
//     let nextFibonacci = 0;

//     while (nextFibonacci < previousTimeout) {
//         nextFibonacci = fib1 + fib2;
//         fib1 = fib2;
//         fib2 = nextFibonacci;
//     }

//     const newTimeout = previousTimeout + nextFibonacci;
//     return Math.max(minimumTimeout, Math.min(newTimeout, maximumTimeout));
// }

export class EventEmitter<T> {
    private subscribers: ((value: T) => void)[] = [];

    subscribe(callback: (value: T) => void) {
        this.subscribers.push(callback);
    }

    unsubscribe(callback: (value: T) => void) {
        this.subscribers = this.subscribers.filter((sub) => sub !== callback);
    }

    emit(value: T) {
        this.subscribers.forEach((sub) => sub(value));
    }
}
