import { PaginatedResponse } from '@frontend/api-utils';

export interface BasicAuth {
    first_name: string | null;
    last_name: string | null;
    country: string | null;
    email: string;
    id: string;
    user_id: string;
    password: string;
    status: BasicAuthStatus;
    login_attempt: number;
    creation_timestamp: string;
}

export interface CreateBasicAuth {
    email: string;
    status?: BasicAuthStatus | null;
    password?: string | null;
}

export interface UpdateBasicAuth {
    status?: BasicAuthStatus | null;
    password?: string | null;
}

export interface ChangePassword {
    password?: string;
    password_confirmation?: string;
    old_password?: string;
}

export enum BasicAuthStatus {
    BLOCKED = 'blocked',
    ACTIVE = 'active'
}

export type BasicAuthListResponse = PaginatedResponse<BasicAuth>;

export enum BasicAuthQueryParams {
    USER_ID = 'user_id'
}
