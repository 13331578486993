import { WorkflowTrigger, WorkflowTriggerName } from "@frontend/workflow/types";
import React from "react";
import { FormattedMessage } from "react-intl";

export enum TransactionTriggerName {
    TRANSACTION_STATE_CHANGED = 'transaction_state_changed',
    TRANSACTION_PROCESS = 'transaction_process',
    TRANSACTION_PACKAGE_PROCESSED = 'transaction_package_processed',
    TRANSACTION_PROCESSED = 'transaction_processed',
}

export class TransactionTrigger extends WorkflowTrigger {
    static readonly TRANSACTION_STATE_CHANGED = new TransactionTrigger(
        TransactionTriggerName.TRANSACTION_STATE_CHANGED,
        (
            <FormattedMessage
                id='TransactionTrigger.transaction_state_changed'
                description='The displayed value for the TransactionTrigger transaction_state_changed'
                defaultMessage='Transaction state changed'
            />
        )
    );
    static readonly TRANSACTION_PROCESS = new TransactionTrigger(
        TransactionTriggerName.TRANSACTION_PROCESS,
        (
            <FormattedMessage
                id='TransactionTrigger.transaction_process'
                description='The displayed value for the TransactionTrigger transaction_process'
                defaultMessage='Transaction process'
            />
        )
    );
    static readonly TRANSACTION_PACKAGE_PROCESSED = new TransactionTrigger(
        TransactionTriggerName.TRANSACTION_PACKAGE_PROCESSED,
        (
            <FormattedMessage
                id='TransactionTrigger.transaction_package_processed'
                description='The displayed value for the TransactionTrigger transaction_package_processed'
                defaultMessage='Transaction package processed'
            />
        )
    );
    static readonly TRANSACTION_PROCESSED = new TransactionTrigger(
        TransactionTriggerName.TRANSACTION_PROCESSED,
        (
            <FormattedMessage
                id='TransactionTrigger.transaction_processed'
                description='The displayed value for the TransactionTrigger transaction_processed'
                defaultMessage='Transaction processed'
            />
        )
    );

    static override readonly ALL = [
        TransactionTrigger.TRANSACTION_STATE_CHANGED,
        TransactionTrigger.TRANSACTION_PROCESS,
        TransactionTrigger.TRANSACTION_PACKAGE_PROCESSED,
        TransactionTrigger.TRANSACTION_PROCESSED,
        ...super.ALL
    ];

    static override getByValue(trigger: TransactionTriggerName | WorkflowTriggerName): WorkflowTrigger | undefined {
        return this.ALL.find((a) => a.value == trigger);
    }
    
}