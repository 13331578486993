import { EntityType } from "@frontend/common";
import { ModalHeader, ModalType, Spinner, StringPlaceholder } from "@frontend/elements";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";

import { ObjectDetailModalMapper } from "@frontend/rendering/details";
import React from "react";
import { createPortal } from "react-dom";
import useObjectDetailModal from './object-detail-modal.controller';

const ID = 'object-detail-modal';

export interface Props<T> {
    id: string;
    objectType: EntityType;
    callback?: (object: T) => void;
    resolve: (id: string) => void;
    dispatch: ThunkDispatch<any, any, Action>;
    /**
     * @description If children is provided, it will be rendered inside the modal body
     * Otherwise, the modal will try to render default information
     */
    children?: React.ReactNode

    handleClose: () => void;
    show: boolean;
    type?: ModalType;
    customWidth?: number;

    overwriteKeys?: Map<keyof T, React.ReactNode>;
    overwriteViewDetails?: () => void;
}

export const ObjectDetailModal = <T extends {id: string} & object>(props: Props<T>) => {
    const viewProps = useObjectDetailModal<T>(props);

    if (!props.show) {
        return <></>;
    }

    const root = document.getElementById('root');
    if (!root) throw new Error('Root node not found. Cannot render modal.');

    if(!viewProps.object) return createPortal(
        <div
            className='modal display-block'
            aria-modal='true'
            role='dialog'
            id={ID + '-' + props.id}>
            <div
                className={`${viewProps.modalType} ${props.show ? '' : 'hide'}`}
                style={viewProps.width}
                ref={viewProps.modalRef}>
                <ModalHeader
                    id={`${ID}-header`}
                    handleClose={props.handleClose}
                    title={<StringPlaceholder loaded={!!viewProps.title}>{viewProps.title}</StringPlaceholder>}
                    handleView={viewProps.viewDetails}
                />
                <div className='modal-body'>
                    <Spinner />
                </div>
            </div>
        </div>,
        root
    );

    return createPortal(
        <div
            className='modal display-block'
            aria-modal='true'
            role='dialog'
            id={ID + '-' + props.id}>
            <div
                className={`${viewProps.modalType} ${props.show ? '' : 'hide'}`}
                style={viewProps.width}
                ref={viewProps.modalRef}>
                <ModalHeader
                    id={`${ID}-header`}
                    handleClose={props.handleClose}
                    title={viewProps.title}
                    handleView={viewProps.viewDetails}
                />
                <div className='modal-body'>
                    {props.children ? props.children : (
                        <ObjectDetailModalMapper<T>
                            object={viewProps.object}
                            keyOverwrite={props.overwriteKeys}
                        />
                    )}
                </div>
            </div>
        </div>,
        root
    );
};

export default ObjectDetailModal;
