import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { EntityType, SliceStatus } from '@frontend/common';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

import { AuthorizationClient } from '../api/authorization-client';
import { PermissionTemplatesDict, UserPermissions } from '../permission';
import { RestrictionListResponse } from '../restriction';
import { RoleListResponse } from '../role';

interface AuthorizationState {
    current: {
        type: EntityType;
        id: string;
        roles: RoleListResponse | null;
        restrictions: RestrictionListResponse | null;
    } | null;
    roleTemplates: { [key: string]: string[] } | null;
    permissionTemplates: PermissionTemplatesDict | null;
    permissions: UserPermissions | null;
    lastUpdate: number | null;
    status: SliceStatus;
}

const initialState: AuthorizationState = {
    current: null,
    roleTemplates: null,
    permissionTemplates: null,
    permissions: null,
    lastUpdate: null,
    status: SliceStatus.INIT
};

const authorizationSlice = createSlice({
    name: 'authorization',
    initialState,
    reducers: {
        removeCurrentRoles(state) {
            state.current = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchRoleTemplates.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchRoleTemplates.fulfilled, (state, action) => {
                state.roleTemplates = action.payload;
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchPermissionTemplates.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchPermissionTemplates.fulfilled, (state, action) => {
                state.permissionTemplates = action.payload;
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchEntityRoles.pending, (state, action) => {
                state.status = SliceStatus.LOADING;
                if (state.current == null || state.current.id != action.meta.arg.entityId) {
                    state.current = {
                        id: action.meta.arg.entityId,
                        type: action.meta.arg.type,
                        roles: state.current ? state.current.roles : null,
                        restrictions: null
                    };
                }
            })
            .addCase(fetchEntityRoles.fulfilled, (state, action) => {
                if (state.current == undefined) return;
                const startPos = action.meta.arg.queryParams
                    ? toNumber(action.meta.arg.queryParams.size) * (toNumber(action.meta.arg.queryParams.index) - 1)
                    : 0;
                if (state.current.roles == null) {
                    state.current.roles = {
                        ...action.payload,
                        results: new Array(action.payload.count)
                    };
                    state.current.roles.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.current.roles.results.length !== action.payload.count) {
                        state.current.roles.count = action.payload.count;
                        state.current.roles.results = new Array(action.payload.count);
                    }
                    state.current.roles.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }

                state.current = {
                    id: action.meta.arg.entityId,
                    type: action.meta.arg.type,
                    roles: action.payload,
                    restrictions: null
                };
                state.status = SliceStatus.IDLE;
            })
            .addCase(deleteEntityRole.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(deleteEntityRole.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                if (state.current == null || state.current.roles == null) return;
                const found = state.current.roles.results.find((r) => r.id === action.meta.arg.roleId);
                if (found == undefined) return;
                state.current.roles.results.splice(state.current.roles.results.indexOf(found), 1);
            })
            .addCase(fetchEntityRestrictions.pending, (state, action) => {
                state.status = SliceStatus.LOADING;
                if (state.current == null || state.current.id != action.meta.arg.entityId) {
                    state.current = {
                        id: action.meta.arg.entityId,
                        type: action.meta.arg.type,
                        roles: null,
                        restrictions: state.current ? state.current.restrictions : null
                    };
                }
            })
            .addCase(fetchEntityRestrictions.fulfilled, (state, action) => {
                if (state.current == undefined) return;
                const startPos = action.meta.arg.queryParams
                    ? toNumber(action.meta.arg.queryParams.size) * (toNumber(action.meta.arg.queryParams.index) - 1)
                    : 0;
                if (state.current.restrictions == null) {
                    state.current.restrictions = {
                        ...action.payload,
                        results: new Array(action.payload.count)
                    };
                    state.current.restrictions.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.current.restrictions.results.length !== action.payload.count) {
                        state.current.restrictions.count = action.payload.count;
                        state.current.restrictions.results = new Array(action.payload.count);
                    }
                    state.current.restrictions.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }

                state.current = {
                    id: action.meta.arg.entityId,
                    type: action.meta.arg.type,
                    roles: null,
                    restrictions: action.payload
                };
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchPermissions.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchPermissions.fulfilled, (state, action) => {
                state.lastUpdate = Date.now();
                state.permissions = action.payload;
                state.status = SliceStatus.IDLE;
            });
    }
});

export const fetchEntityRoles = createAsyncThunk<RoleListResponse, { type: EntityType; entityId: string; queryParams?: ApiQueryParams<DefaultQueryParams> }>(
    'fetchEntityRoles',
    async (variables: { type: EntityType; entityId: string; queryParams?: ApiQueryParams<DefaultQueryParams> }, { rejectWithValue }) => {
        try {
            return await AuthorizationClient.fetchEntityRoles(variables.type, variables.entityId, variables.queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const deleteEntityRole = createAsyncThunk<void, { type: EntityType; entityId: string; roleId: string }>(
    'deleteEntityRole',
    async (variables: { type: EntityType; entityId: string; roleId: string }, { rejectWithValue }) => {
        try {
            return await AuthorizationClient.deleteEntityRole(variables.type, variables.entityId, variables.roleId);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchRoleTemplates = createAsyncThunk<any>('fetchRoleTemplates', async (_, { rejectWithValue }) => {
    try {
        return await AuthorizationClient.fetchRoleTemplates();
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const fetchPermissionTemplates = createAsyncThunk<PermissionTemplatesDict>('fetchPermissionTemplates', async (_, { rejectWithValue }) => {
    try {
        return await AuthorizationClient.fetchPermissionTemplates();
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const fetchEntityRestrictions = createAsyncThunk<
    RestrictionListResponse,
    { type: EntityType; entityId: string; queryParams?: ApiQueryParams<DefaultQueryParams> }
>(
    'fetchEntityRestrictions',
    async (variables: { type: EntityType; entityId: string; queryParams?: ApiQueryParams<DefaultQueryParams> }, { rejectWithValue }) => {
        try {
            return await AuthorizationClient.fetchEntityRestrictions(variables.type, variables.entityId, variables.queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchPermissions = createAsyncThunk<UserPermissions>('fetchPermissions', async (_, { rejectWithValue }) => {
    try {
        return await AuthorizationClient.fetchPermissions();
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const authorizationStore = { authorization: authorizationSlice.reducer };
export const { removeCurrentRoles } = authorizationSlice.actions;
