import React from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import usePasswordBadge from './password-badge.controller';

export interface PasswordBadgeProps {
    password: string;
}

export const PasswordBadge = (props: PasswordBadgeProps) => {
    const viewProps = usePasswordBadge(props);

    return (
        <div
            className='d-flex justify-content-end'
            onClick={() => viewProps.changeShowPassword((prev) => !prev)}>
            <div className='w-70'>{viewProps.renderPassword()}</div>
            <div className='ms-2'>{viewProps.showPassword ? <FaEyeSlash cursor='pointer' /> : <FaEye cursor='pointer' />}</div>
        </div>
    );
};
