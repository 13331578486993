import { Spinner } from '@frontend/elements';
import { EnterPinData, UserInterfaceImplementation } from '@frontend/user-interface/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

import ChallengeForm from './challenge-form/challenge-form.component';
import useEnterPin, { PinWorkflowState } from './enter-pin.controller';
import PinCodeForm from './pin-code-form/pin-code-form.component';

export interface EnterPinProps {
    dispatch: ThunkDispatch<any, any, Action>;
    userInterface: UserInterfaceImplementation<EnterPinData>;
}

export const EnterPin = (props: EnterPinProps) => {
    const viewProps = useEnterPin();
    if (viewProps.state == PinWorkflowState.ENTER_PIN)
        return (
            <PinCodeForm
                maxPinLength={props.userInterface.data.pinLength}
                callback={viewProps.changePinCodes}
            />
        );
    if (viewProps.state == PinWorkflowState.ENTER_CHALLENGE)
        return (
            <ChallengeForm
                pinCodes={viewProps.pinCodes}
                callback={viewProps.changePinCodes}
            />
        );

    return <Spinner />;
};
