import { isWeekend } from '@frontend/common';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { DateInputProps } from './date-input.component';

interface ViewProps {
    changeTouched: Dispatch<SetStateAction<boolean>>;
    format: string;
    isValid: () => void;
    valid: boolean;
    touched: boolean;
}

const useDateInput = (props: DateInputProps): ViewProps => {
    const [touched, changeTouched] = useState<boolean>(false);
    const [valid, changeValid] = useState<boolean>(props.valid ? props.valid : false);
    const format = props.dateTime ? 'YYYY-MM-DDTHH:mm' : 'YYYY-MM-DD';

    const isValid = (): boolean => {
        let check = true;
        if (check && props.addedValidCheck) check = props.addedValidCheck(props.value);
        if (check === true && props.required === true && props.value === undefined) check = false;
        if (props.valid !== undefined && check === true) check = props.valid;
        if (check && props.disallowWeekends === true && props.value) check = !isWeekend(props.value);
        if (props.minDate && props.value && props.minDate.getTime() > props.value.getTime()) check = false;
        if (props.maxDate && props.value && props.maxDate.getTime() < props.value.getTime()) check = false;
        changeValid(check);
        return check;
    };

    useEffect(() => {
        if (props.isValidCallback) {
            props.isValidCallback(isValid());
        } else isValid();
    }, [props.value, props.valid]);

    useEffect(() => {
        if (props.submitted) {
            !touched && changeTouched(true);
        }
    }, [props.submitted]);

    return {
        changeTouched,
        format,
        isValid,
        valid,
        touched
    };
};

export default useDateInput;
