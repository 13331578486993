export enum SettingEntityType {
    ACCOUNT = 'account',
    AUTHENTICATION = 'authentication',
    BADGE = 'badge',
    CONTACT = 'contact',
    DEVICE = 'device',
    DRIVER = 'driver',
    MODULE = 'module',
    PACKAGE = 'package',
    PRODUCT = 'product',
    SPOT = 'spot',
    SLOT = 'slot',
    TRANSACTION = 'transaction',
    USER = 'user',
    WORKFLOW = 'workflow',
}