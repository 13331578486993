import { DefaultModal, DefaultModalProps, ModalHeader, ModalType } from '@frontend/elements';
import { EventEntityType } from '@frontend/events/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

import { Rakinda } from '../../rakinda';
import { RakindaDetail } from '../rakinda-detail.component';
import { useRakindaDetailModal } from './rakinda-detail-modal.controller';

const EventTable = React.lazy(() => import('@frontend/events/components').then((module) => ({ default: module.EventTable })));
const ID = 'rakinda-detail-modal';

export interface RakindaDetailModalProps extends DefaultModalProps {
    rakinda: Rakinda;
    dispatch: ThunkDispatch<any, any, Action>;
}

const RakindaDetailModal = (props: RakindaDetailModalProps) => {
    const viewProps = useRakindaDetailModal(props);
    return (
        <DefaultModal
            type={ModalType.PANE}
            id={ID}
            {...props}>
            <ModalHeader
                handleClose={props.handleClose}
                id={ID + '-header'}
                title={props.rakinda.name ? props.rakinda.name : props.rakinda.identifier}
                handleView={viewProps.viewDetails}
            />
            <div className='modal-body'>
                <div className='d-flex flex-row w-100'>
                    <div className='d-flex flex-column w-100 h-100'>
                        <RakindaDetail rakinda={props.rakinda} />
                    </div>
                </div>
            </div>
            <EventTable
                entity_id={props.rakinda.id}
                entity_type={EventEntityType.RAKINDA}
                dispatch={props.dispatch}
                limit={5}
                hidePagination
                hideActions
                title
            />
        </DefaultModal>
    );
};

export default RakindaDetailModal;
