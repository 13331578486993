import { useAppSelector } from '@frontend/common';
import { userInterfaceRequest } from '@frontend/user-interface/events';
import { userInterfaceStore } from '@frontend/user-interface/redux';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { UserInterfaceBadgeProps } from './user-interface-badge.component';

interface ViewProps {
    displayName?: string;
    disabled: boolean;
}

const useUserInterfaceBadge = (props: UserInterfaceBadgeProps): ViewProps => {
    const [requested, changeRequested] = useState<boolean>(false);
    const userInterfaceState = useAppSelector(useSelector, userInterfaceStore);
    const [disabled, changeDisabled] = useState<boolean>(false);
    const [displayName, changeDisplayName] = useState<string | undefined>(props.name);
    useEffect(() => {
        if (props.id != undefined) {
            const found = userInterfaceState.unordered.find((ui) => ui.id == props.id);
            if (found) {
                changeDisplayName(found.name);
            } else if (requested == false) {
                changeRequested(true);
                userInterfaceRequest(props.id);
            }

            if (requested && !found) {
                changeDisabled(true);
                changeDisplayName('Not found');
            }
        }
    }, [userInterfaceState, props.id]);

    return {
        displayName,
        disabled
    };
};

export default useUserInterfaceBadge;
