import { EntityType } from '@frontend/common';
import { useBadgeRepository } from '@frontend/repository';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
export interface Props<T> {
    type: EntityType;
    mapDisplayName: (entity: T) => string | undefined;
    resolve: (id: string) => void;
    id?: string;
}

export const ObjectBadge = <T,>(props: Props<T>) => {
    const repository = useBadgeRepository(props.type, props.mapDisplayName, props.resolve, props.id);
    return (
        <span
            id={'badge-' + props.id}
            className={`badge badge-secondary ${repository.disabled ? 'disabled' : 'cursor-pointer'} m-1`}
            onClick={(e) => !repository.disabled && repository.onClick(e)}>
            <repository.icon className='me-2'/>
            {repository.displayName ? (
                <OverlayTrigger
                    overlay={<Tooltip id={'tooltip'}>{repository.displayName}</Tooltip>}
                    placement='top'
                    delay={{ show: 300, hide: 150 }}>
                    <span>{repository.displayName.length > 30 ? repository.displayName.slice(0, 20) + '...' : repository.displayName}</span>
                </OverlayTrigger>
            ) : (
                <span className='placeholder-glow'>
                    <span className='placeholder'>loading</span>
                </span>
            )}
        </span>
    );
}
