import { AuthorizationClient, UpdateRestriction, fetchEntityRestrictions } from '@frontend/authorization';
import { ErrorHandler } from '@frontend/error-handler';
import { Dispatch, SetStateAction, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { UpdateRestrictionFormProps } from './update-restriction-form.component';

interface ViewProps {
    submitted: boolean;
    formValues: UpdateRestriction;
    changeFormValues: Dispatch<SetStateAction<UpdateRestriction>>;
    formValid: FormValid;
    changeFormValid: Dispatch<SetStateAction<FormValid>>;
    onSubmit: (e: any) => void;
}
interface FormValid {
    type: boolean;
    key: boolean;
    value: boolean;
    right: boolean;
}

const useUpdateRestrictionForm = (props: UpdateRestrictionFormProps): ViewProps => {
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const [formValid, changeFormValid] = useState<FormValid>({ type: false, key: false, value: false, right: false });
    const [formValues, changeFormValues] = useState<UpdateRestriction>({
        type: props.restriction.type,
        key: props.restriction.key,
        value: props.restriction.value,
        right: 'read'
    });
    const authorizationState = useAppSelector((state) => state.authorization);
    const dispatch = useAppDispatch();

    const onSubmit = (e: any) => {
        e.preventDefault();
        changeSubmitted(true);
        if (authorizationState.current == null) return;
        AuthorizationClient.patchEntityRestriction(authorizationState.current.type, authorizationState.current.id, props.restriction.id, formValues)
            .then((res) => {
                if (authorizationState.current)
                    dispatch(fetchEntityRestrictions({ type: authorizationState.current.type, entityId: authorizationState.current.id }));
                props.onClose();
            })
            .catch(ErrorHandler.handleError);
    };

    return {
        changeFormValid,
        changeFormValues,
        formValid,
        formValues,
        onSubmit,
        submitted
    };
};

export default useUpdateRestrictionForm;
