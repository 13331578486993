import { EntityType, useAppSelector } from '@frontend/common';
import { ErrorHandler } from '@frontend/error-handler';
import { resolveStore } from '@frontend/repository';
import { ToastUtil } from '@frontend/toast-utils';
import { UserClient } from '@frontend/user/api';
import { userRequest } from '@frontend/user/events';
import { User } from '@frontend/user/types';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { UserDetailPageProps } from './user-detail-page.component';

interface ViewProps {
    user: User | undefined;
    showDeleteModal: boolean;
    showUpdateModal: boolean;
    changeShowDeleteModal: (value: React.SetStateAction<boolean>) => void;
    changeShowUpdateModal: (value: React.SetStateAction<boolean>) => void;
    removeUser: () => void;
    activeSubNav: string;
    changeActiveSubNav: Dispatch<SetStateAction<string>>;
}

const useUserDetailPage = (props: UserDetailPageProps): ViewProps => {
    const navigate = useNavigate();
    const location = useLocation();
    const { userId } = useParams();
    const resolvedState = useAppSelector(useSelector, resolveStore);
    const [user, changeUser] = useState<User | undefined>(undefined);
    const [showDeleteModal, changeShowDeleteModal] = useState<boolean>(false);
    const [showUpdateModal, changeShowUpdateModal] = useState<boolean>(false);
    const [activeSubNav, changeActiveSubNav] = useState<string>('events');

    useEffect(() => {
        if (!userId) return;
        const found = resolvedState.resolved?.[EntityType.USER]?.[userId] as User | undefined;
        if (found == undefined) userRequest(userId);
        else changeUser(found);
    }, [userId, resolvedState]);

    function removeUser() {
        if (!user) return;
        UserClient.deleteUser(user.account_id, user.id)
            .then(() => {
                changeShowDeleteModal(false);
                ToastUtil.success(`User ${user.email} deleted`);
                navigate('/' + location.pathname.split('/').slice(1, -2).join('/'));
            })
            .catch(ErrorHandler.handleError);
    }

    return {
        user,
        changeShowDeleteModal,
        changeShowUpdateModal,
        showDeleteModal,
        showUpdateModal,
        removeUser,
        activeSubNav,
        changeActiveSubNav
    };
};

export default useUserDetailPage;
