import { Table } from '@frontend/table';

import PermissionOverview from '../../permissions/permission-overview/permission-overview.component';
import RoleListElement from './role-list-element';
import useRoleList from './role-list.controller';

const ID = 'role-list';
export interface RoleListProps {}

const RoleList = (props: RoleListProps) => {
    const viewProps = useRoleList(props);

    return (
        <div
            id={ID}
            className='d-flex flex-column h-100'>
            <Table
                selectEnabled
                selectedItemsCallback={viewProps.changeSelectedRoles}
                id={ID + '-table'}
                columns={viewProps.columns}
                data={viewProps.data}
                RowElement={RoleListElement}
                page={(value) => viewProps.setPage(value + 1)}
                pageSize={(value) => viewProps.setPageSize(value)}
                pageCount={viewProps.pageCount}
            />
            <div className='h-50'>
                <PermissionOverview roles={viewProps.selectedRoles} />
            </div>
        </div>
    );
};

export default RoleList;
