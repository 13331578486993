import { ApiError, ApiQueryParams, DefaultQueryParams, PaginatedResponse } from '@frontend/api-utils';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export interface ListState {
    list: {
        [key: string]: {
            results:
                | {
                      id: string;
                      [key: string]: any;
                  }[]
                | null;
            count: number | null;
        };
    };
}

const initialState: ListState = {
    list: {}
};

export const listSlice = createSlice({
    name: 'list',
    initialState,
    reducers: {
        update(state, action: PayloadAction<{ key: string; value: any }>) {
            if (state.list[action.payload.key] && state.list[action.payload.key].results) {
                state.list[action.payload.key].results = state.list[action.payload.key].results!.map((e: any) =>
                    e.id == action.payload.value.id ? action.payload.value : e
                );
            }
        },
        clear(state, action: PayloadAction<string>) {
            if (state.list[action.payload] && state.list[action.payload].results) {
                state.list[action.payload].results = null;
                state.list[action.payload].count = null;
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetch.fulfilled, (state, action) => {
            state.list[action.meta.arg.key] = action.payload;
        });
    }
});

type Options = {
    key: string;
    params: ApiQueryParams<DefaultQueryParams | string | number>;
    func: (queryParams?: ApiQueryParams<DefaultQueryParams | string | number>) => Promise<PaginatedResponse<any>>;
};
export const fetch = createAsyncThunk<PaginatedResponse<any>, Options>('fetch', async (options: Options, { rejectWithValue }) => {
    try {
        return await options.func(options.params);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const listStore = { lists: listSlice.reducer };
export const { update, clear } = listSlice.actions;
