import { ClassType } from '@frontend/common';
import { ConfirmationModal, DefaultModal, Dropdown, DropdownItem, HorizontalNavbar, Spinner } from '@frontend/elements';
import { EventEntityType } from '@frontend/events/types';
import { ENTITY_ICONS } from '@frontend/icons';
import { CommonMessage } from '@frontend/lang';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';

import { IoSettings } from 'react-icons/io5';
import UpdateContactForm from '../../forms/update-contact-form/update-contact-form.component';
import { ContactDetail } from '../contact-detail.component';
import useContactDetailPage from './contact-detail-page.controller';

const EventTable = React.lazy(() => import('@frontend/events/components').then((module) => ({ default: module.EventTable })));

const ID = 'contact-detail-page';

export interface ContactDetailPageProps {
    dispatch: ThunkDispatch<any, any, Action>;
}
export const ContactDetailPage = (props: ContactDetailPageProps) => {
    const viewProps = useContactDetailPage(props);

    if (!viewProps.contact) {
        return (
            <div className='card'>
                <div className='card-body'>
                    <Spinner />
                </div>
            </div>
        );
    }

    return (
        <div id={ID}>
            <div className='card mt-3'>
                <div className='card-header d-flex justify-content-between'>
                    <h4>{`${viewProps.contact.first_name} ${viewProps.contact.last_name}`}</h4>
                    <Dropdown
                        icon={IoSettings}
                        classType={ClassType.LIGHT}
                    >
                        <DropdownItem
                            label={CommonMessage.BUTTONS.DELETE}
                            onClick={() => viewProps.changeShowDeleteModal(true)}
                            icon={FaTrash}
                            classType={ClassType.DANGER}
                        />
                        <DropdownItem
                            label={CommonMessage.BUTTONS.UPDATE}
                            onClick={() => viewProps.changeShowUpdateModal(true)}
                            icon={FaEdit}
                            classType={ClassType.INFO}
                        />
                    </Dropdown>
                </div>
                <div className='card-body'>
                    <ContactDetail contact={viewProps.contact} />
                </div>
            </div>
            <HorizontalNavbar
                navItems={[{ title: 'Events', icon: ENTITY_ICONS.EVENT }]}
                onChange={(v) => viewProps.changeActiveSubNav(v)}
            />

            {viewProps.activeSubNav === 'events' && (
                <div className='card mt-3'>
                    <div className='card-header'>
                        <h4>Events</h4>
                    </div>
                    <div className='card-body'>
                        <EventTable
                            entity_id={viewProps.contact.id}
                            entity_type={EventEntityType.CONTACT}
                            dispatch={props.dispatch}
                        />
                    </div>
                </div>
            )}

            {viewProps.showDeleteModal && (
                <ConfirmationModal
                    handleClose={() => viewProps.changeShowDeleteModal(false)}
                    message={CommonMessage.FORMS.DELETE_OBJECT('contact', `${viewProps.contact.first_name} ${viewProps.contact.last_name}`)}
                    onConfirm={() => viewProps.removeContact()}
                    show={viewProps.showDeleteModal}
                    severity={ClassType.DANGER}
                />
            )}
            {viewProps.showUpdateModal && (
                <DefaultModal
                    handleClose={() => viewProps.changeShowUpdateModal(false)}
                    show={viewProps.showUpdateModal}>
                    <UpdateContactForm
                        contact={viewProps.contact}
                        onClose={() => viewProps.changeShowUpdateModal(false)}
                    />
                </DefaultModal>
            )}
        </div>
    );
};
