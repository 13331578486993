import { BasicAuth, BasicAuthStatus } from '@frontend/authentication-methods/types';
import { CommonMessage } from '@frontend/lang';
import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { ImBlocked } from 'react-icons/im';

import { DateBadge } from '../../../badge-components/date-badge/date-badge.component';
import { PasswordBadge } from '../../../badge-components/password-badge/password-badge.component';
import { ObjectField } from '../../../modals/object-field.component';

export interface BasicAuthDetailProps {
    basicAuth: BasicAuth;
}

export const BasicAuthDetail = (props: BasicAuthDetailProps) => {
    return (
        <>
            <ObjectField
                fieldName={CommonMessage.FORMS.NAME}
                value={props.basicAuth.first_name + ' ' + props.basicAuth.last_name}
            />
            <ObjectField
                fieldName={CommonMessage.FORMS.EMAIL()}
                value={props.basicAuth.email}
            />
            <ObjectField
                fieldName={'Loggin attempts'}
                value={props.basicAuth.login_attempt}
            />
            <ObjectField
                fieldName='Password'
                value={<PasswordBadge password={props.basicAuth.password} />}
            />
            <ObjectField
                fieldName='Status'
                value={
                    props.basicAuth.status === BasicAuthStatus.ACTIVE ? (
                        <FaCheckCircle
                            size={18}
                            color='green'
                        />
                    ) : (
                        <ImBlocked
                            size={18}
                            color='red'
                        />
                    )
                }
            />
            <ObjectField
                fieldName={CommonMessage.OBJECTS.DEFAULT.CREATION_TIMESTAMP}
                value={
                    <DateBadge
                        date={props.basicAuth.creation_timestamp}
                        displayTime
                        inline
                    />
                }
            />
        </>
    );
};
