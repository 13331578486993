import React from 'react';

import { DefaultSelectProps } from '../basic-forms';
import { SelectInput } from '../select-input/select-input.component';

const ID = 'contact-type-select';

export const ContactTypeSelect = (props: DefaultSelectProps) => {
    return (
        <SelectInput
            {...props}
            id={ID}
            isClearable
            options={Object.entries(ContactType).map((c) => ({ value: c[1], label: c[1] }))}
            value={props.value}
            submitted={props.submitted}
            onChange={props.onChange}
        />
    );
};

export enum ContactType {
    PERSON = 'person',
    GROUP = 'group'
}
