import { Table } from '@frontend/table';
import { Transaction } from '@frontend/transaction/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

import TransactionVariablesElement from './table/transaction-variables-element';
import useTransactionVariables from './transaction-variables.controller';

export interface TransactionVariablesProps {
    transaction: Transaction;
    dispatch: ThunkDispatch<any, any, Action>;
}

export const TransactionVariables = (props: TransactionVariablesProps) => {
    const viewProps = useTransactionVariables(props);
    return (
        <div className='card mt-3'>
            <div className='card-header'>
                <h5 className='card-title'>Transaction Variables</h5>
            </div>
            <div className='card-body'>
                <Table
                    columns={viewProps.columns}
                    RowElement={(row) => TransactionVariablesElement({ ...row, dispatch: props.dispatch })}
                    data={viewProps.data}
                />
            </div>
        </div>
    );
};
