import { APIClient } from "@frontend/api-utils";

const endpoint = '/user-api/v1';

export class UserDeviceClient extends APIClient {

    public static async postIdentifyDevice(accountId: string, userId: string, body: {id: string, driver_id: string}): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/users/${userId}/identify-device`, body);
        return await this.handleVoidResponse(response);
    }
}
