import { useAppSelector } from '@frontend/common';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { AuthorizationClient } from '../../api/authorization-client';
import { UserPermissions } from '../../permission';
import { authorizationStore } from '../../redux/authorization-slice';

const AuthorizationContext = createContext({
    permissions: {} as UserPermissions | null,
    getPermissions: (): void => {},
    changeLastRequest: (date: number): void => {}
});

export const AuthorizationProvider = (props: { children: any; dispatch: ThunkDispatch<any, any, Action> }) => {
    const authorizationState = useAppSelector(useSelector, authorizationStore);
    const [lastUpdate, changeLastUpdate] = useState<number>(Date.now());
    const [lastRequest, changeLastRequest] = useState<number>(Date.now());
    const [permissions, changePermissions] = useState<UserPermissions | null>(null);

    useEffect(() => {
        getPermissions();
        changeLastUpdate(Date.now());
        changeLastRequest(Date.now());
    }, []);

    useEffect(() => {
        if (lastUpdate < lastRequest - 10 * 60 * 1000) {
            getPermissions();
            changeLastUpdate(Date.now());
        }
    }, [lastRequest]);

    useEffect(() => {
        if (authorizationState.permissions) {
            changePermissions(authorizationState.permissions);
        }
    }, [authorizationState.permissions]);

    const getPermissions = (): void => {
        AuthorizationClient.fetchPermissions().then((result) => {
            changePermissions(result);
        });
    };

    return <AuthorizationContext.Provider value={{ permissions, getPermissions, changeLastRequest }}>{props.children}</AuthorizationContext.Provider>;
};

export const useAuthorization = () => {
    const context = useContext(AuthorizationContext);
    if (!context) {
        throw new Error('useAuthorization must be used within an AuthorizationProvider');
    }
    return context;
};
