import { AsyncSelectInputSingle } from '@frontend/basic-forms';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

import { ApiQueryParams } from '@frontend/api-utils';
import { DefaultModal, ModalType } from '@frontend/elements';
import { WorkflowQueryParams } from '@frontend/workflow/types';
import { CreateWorkflowForm } from '../create-workflow-form/create-workflow-form.component';
import useWorkflowSelect from './workflow-select.controller';

const ID = 'workflow-select';
export interface WorkflowSelectProps {
    id?: string;
    label?: React.ReactNode;
    required?: boolean;
    submitted: boolean;
    helpMessage?: React.ReactNode;
    onChange?: (newValue: string | null) => void;
    value?: string | null;
    className?: string;
    disabled?: boolean;
    useConditionedStyling?: boolean;
    isClearable?: boolean;
    allowURLChange?: boolean;
    /**
     * @deprecated
     */
    dispatch?: ThunkDispatch<any, any, Action>;
    createEnabled?: boolean;
    queryParams?: ApiQueryParams<WorkflowQueryParams>;
}

export const WorkflowSelect = (props: WorkflowSelectProps) => {
    const viewProps = useWorkflowSelect(props);

    return (
        <>
            <AsyncSelectInputSingle
                id={props.id || ID}
                label={props.label || 'Workflow'}
                className={props.className}
                helpMessage={props.helpMessage}
                submitted={props.submitted}
                required={props.required}
                options={viewProps.options}
                onChange={viewProps.onChange}
                value={viewProps.value?.value}
                isDisabled={viewProps.overwriteDisabled || props.disabled}
                onMenuScrollToBottom={viewProps.onScrollToBottom}
                loadOptions={viewProps.search}
                isClearable={props.isClearable}
                useConditionedStyling={props.useConditionedStyling}
            />
            <DefaultModal
                show={viewProps.showCreateModal}
                handleClose={() => viewProps.changeShowCreateModal(false)}
                type={ModalType.PROMPT}>
                <CreateWorkflowForm
                    onClose={() => viewProps.changeShowCreateModal(false)}
                    dispatch={props.dispatch}
                    callback={(workflow) => {props.onChange && props.onChange(workflow.id)}}
                />
            </DefaultModal>
        </>
    );
};
