import { SelectInput } from '@frontend/basic-forms';
import React from 'react';
import useAccessLogTypeSelect from './access-log-type-select.controller';

const ID = 'access-log-type-select';

export interface AccessLogTypeSelectProps {
    id?: string;
    required?: boolean;
    submitted: boolean;
    onChange?: (newValue?: string) => void;
    value?: string | null;
    className?: string;
    disabled?: boolean;
    label?: React.ReactNode;
    useConditionedStyling?: boolean;
    isClearable?: boolean;
    allowURLChange?: boolean;
    helpMessage?: React.ReactNode;
}

export const AccessLogTypeSelect = (props: AccessLogTypeSelectProps) => {
    const viewProps = useAccessLogTypeSelect(props);
    return (
        <SelectInput
            id={props.id || ID}
            label={props.label || 'Access log type'}
            className={props.className}
            submitted={props.submitted}
            helpMessage={props.helpMessage}
            required={props.required}
            options={viewProps.options}
            onChange={(newValue: any) => viewProps.onChange(newValue?.value, props.allowURLChange)}
            value={viewProps.value?.value}
            isDisabled={props.disabled}
            isClearable={props.isClearable}
            useConditionedStyling={props.useConditionedStyling}
        />
    );
};
