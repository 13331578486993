import { Logger } from '@frontend/Logger';
import { useAppSelector } from '@frontend/common';
import { ProductCategoryClient } from '@frontend/product-category/api';
import { ProductCategory } from '@frontend/product-category/types';
import { ProductClient } from '@frontend/product/api';
import { Product } from '@frontend/product/types';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { navigationStore } from '../../../redux/user-interface-navigation-slice';
import { BasketEditorProps } from './basket-editor.component';

interface ViewProps {
    productCount: number | null;
    categorieCount: number | null;
    categories: ProductCategory[] | null;
    products: Product[] | null;
    changePage: () => void;
    textFilter: string;
    changeTextFilter: React.Dispatch<React.SetStateAction<string>>;
    category: ProductCategory | null;
    changeCategory: React.Dispatch<React.SetStateAction<ProductCategory | null>>;
    product: Product | null;
    changeProduct: React.Dispatch<React.SetStateAction<Product | null>>;
    onBack: (id?: string) => void;
}

const useBasketEditor = (props: BasketEditorProps): ViewProps => {
    const timer = useRef<NodeJS.Timeout | null>(null);
    const navigationState = useAppSelector(useSelector, navigationStore);
    const [productCount, changeProductCount] = useState<number | null>(null);
    const [categorieCount, changeCategorieCount] = useState<number | null>(null);
    const [categories, changeCategories] = useState<ProductCategory[] | null>(null);
    const [products, changeProducts] = useState<Product[] | null>(null);
    const [page, changePage] = useState<number>(0);
    const [textFilter, changeTextFilter] = useState<string>('');
    const [product, changeProduct] = useState<Product | null>(null);
    const [category, changeCategory] = useState<ProductCategory | null>(null);
    const [filterChanged, changeFilterChanged] = useState<boolean>(false);
    const currentParentCategory = props.categories && props.categories[props.categories.length - 1];

    useEffect(() => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
            changeCategories(null);
            changeProducts(null);
            changePage(0);
            changeFilterChanged(true);
        }, 500);
    }, [textFilter]);

    useEffect(() => {
        changeFilterChanged(false);
        request();
    }, [props.categories, page, filterChanged]);

    const request = async () => {
        if (props.userInterface.data.catalogId) {
            ProductClient.fetchCatalogProducts(
                { catalog_id: props.userInterface.data.catalogId, category_id: currentParentCategory?.id, search: textFilter, index: page.toString() },
                navigationState.user?.auth || undefined
            )
                .then((response) => {
                    changeProductCount(response.count);
                    if (page == 0) changeProducts(response.results);
                    else changeProducts((products ?? []).concat(response.results));
                })
                .catch(Logger.error);
        } else if (currentParentCategory) {
            ProductClient.fetchCategoryProducts(
                props.userInterface.account_id,
                { root_category_id: currentParentCategory.id, search: textFilter, index: page.toString() },
                navigationState.user?.auth || undefined
            )
                .then((response) => {
                    changeProductCount(response.count);
                    if (page == 0) changeProducts(response.results);
                    else changeProducts((products ?? []).concat(response.results));
                })
                .catch(Logger.error);
        } else {
            ProductClient.fetchProducts({ search: textFilter, index: page.toString() }, navigationState.user?.auth || undefined)
                .then((response) => {
                    changeProductCount(response.count);
                    if (page == 0) changeProducts(response.results);
                    else changeProducts((products ?? []).concat(response.results));
                })
                .catch(Logger.error);
        }
        ProductCategoryClient.fetchProductCategories(
            { search: textFilter, size: '500', parent_id: currentParentCategory ? currentParentCategory.id : textFilter.length > 0 ? undefined : 'null' },
            navigationState.user?.auth || undefined
        )
            .then((response) => {
                changeCategorieCount(response.count);
                changeCategories(response.results);
            })
            .catch(Logger.error);
    };

    const onBack = (id?: string) => {
        changeCategory(null);
        if (id && props.categories && props.categories[props.categories.length - 1].id !== id) {
            props.onBack && props.onBack(id);
        }
    };

    return {
        productCount,
        categorieCount,
        categories,
        products,
        changePage: () => changePage(page + 1),
        textFilter,
        changeTextFilter,
        category,
        changeCategory,
        product,
        changeProduct,
        onBack
    };
};

export default useBasketEditor;
