import { CheckboxInput, NumberInput, TextInput } from '@frontend/basic-forms';
import { ClassType } from '@frontend/common';
import { ModalFooter } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';
import React from 'react';
import { FaPlus } from 'react-icons/fa';


import { Workflow } from '@frontend/workflow/types';
import useUpdateWorkflowForm from './update-workflow-form.controller';

const ID = 'update-workflow-form';

export interface UpdateWorkflowFormProps {
    workflow: Workflow;
    onClose: () => void;
}

export const UpdateWorkflowForm = (props: UpdateWorkflowFormProps) => {
    const viewProps = useUpdateWorkflowForm(props);

    return (
        <div id={ID}>
            <form onSubmit={viewProps.onSubmit}>
                <div className='modal-header'>
                    <h4>Update Workflow</h4>
                </div>
                <div className='modal-body'>
                    <TextInput
                        id={ID + '-nameInput'}
                        label='Name'
                        errorMessage='Please fill in a valid name.'
                        value={viewProps.formValues.name}
                        onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, name: value })}
                        isValidCallback={(valid) => {
                            viewProps.changeFormValid({ ...viewProps.formValid, name: valid });
                        }}
                        submitted={viewProps.submitted}
                    />
                    <NumberInput
                        id={ID + '-revisionInput'}
                        label='Revision'
                        errorMessage='Please fill in a valid revision.'
                        value={viewProps.formValues.revision}
                        onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, revision: value })}
                        isValidCallback={(valid) => {
                            viewProps.changeFormValid({ ...viewProps.formValid, revision: valid });
                        }}
                        submitted={viewProps.submitted}
                    />
                    <CheckboxInput
                        id={ID + '-activeCheckbox'}
                        label='Active'
                        value={viewProps.formValues.active}
                        submitted={viewProps.submitted}
                        onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, active: value })}
                    />
                </div>
                <ModalFooter
                    justifyContent='between'
                    buttons={[
                        {
                            hide: false,
                            text: CommonMessage.BUTTONS.CANCEL,
                            type: ClassType.SECONDARY,
                            onClick: (e) => {
                                e?.preventDefault();
                                props.onClose();
                            }
                        },
                        {
                            hide: false,
                            text: CommonMessage.BUTTONS.SUBMIT,
                            type: ClassType.SUCCESS,
                            icon: FaPlus,
                            buttonType: 'submit'
                        }
                    ]}
                />
            </form>
        </div>
    );
};
