import { useAppSelector } from '@frontend/common';
import { userRequest } from '@frontend/user/events';
import { userStore } from '@frontend/user/redux';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { UserBadgeProps } from './user-badge.component';

interface ViewProps {
    displayName?: { first_name?: string; last_name?: string } | string;
    disabled: boolean;
}
/**
 * @deprecated
 */
const useUserBadge = (props: UserBadgeProps): ViewProps => {
    const [requested, changeRequested] = useState<boolean>(false);
    const userState = useAppSelector(useSelector, userStore);
    const [displayName, changeDisplayName] = useState<{ first_name?: string; last_name?: string } | undefined | string>(undefined);
    const [disabled, changeDisabled] = useState<boolean>(false);
    useEffect(() => {
        if (props.id != undefined) {
            const found = userState.unordered.find((u) => u.id == props.id);
            if (found) {
                if (!found.first_name) {
                    changeDisplayName(found.email);
                } else {
                    changeDisplayName({ first_name: found.first_name, last_name: found.last_name });
                }
                changeDisabled(false);
            } else if (requested == false) {
                changeRequested(true);
                userRequest(props.id);
            }

            if (requested && !found) {
                changeDisabled(true);
                changeDisplayName('Not found');
            }
        }
    }, [userState, props.id]);
    return { displayName, disabled };
};

export default useUserBadge;
