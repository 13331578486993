import React, { Component } from 'react';

export class ErrorBoundary extends Component {
    override state = { error: null };
    constructor(props: any | Readonly<any>) {
        super(props);
        this.state = { error: null };
    }

    static getDerivedStateFromError(error: any) {
        return { error };
    }

    override render() {
        if (this.state.error) {
            return <h1 className='text-danger text-center'>Something went wrong.</h1>;
        }

        // eslint-disable-next-line react/prop-types
        return (this.props as { children: React.ReactNode }).children;
    }
}

export default ErrorBoundary;
