import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';

import { AttachModuleToDriverBody, CreateModule, Module, ModuleListResponse, ModuleQueryParams, ModuleUpdate, SearchModuleQueryParams } from '@frontend/module/types';

const endpoint = '/module-api/v1';

export class ModuleClient extends APIClient {
    public static async fetchModules(queryParams?: ApiQueryParams<DefaultQueryParams | ModuleQueryParams>): Promise<ModuleListResponse> {
        return this.apiPaginated<ModuleListResponse, DefaultQueryParams | ModuleQueryParams>(`${endpoint}/modules`, queryParams);
    }

    public static async searchModules(queryParams?: ApiQueryParams<DefaultQueryParams | SearchModuleQueryParams>): Promise<ModuleListResponse> {
        return this.apiPaginated<ModuleListResponse, DefaultQueryParams | SearchModuleQueryParams>(`${endpoint}/modules-search`, queryParams);
    }

    public static async resolveModules(moduleIds: string[]): Promise<ModuleListResponse> {
        const response = await this.post(`${endpoint}/modules-resolve`, { ids: moduleIds });
        return await this.handleResponse<ModuleListResponse>(response);
    }

    public static async fetchSpotModules(spotId: string, queryParams?: ApiQueryParams<DefaultQueryParams | ModuleQueryParams>): Promise<ModuleListResponse> {
        return this.apiPaginated<ModuleListResponse, DefaultQueryParams | ModuleQueryParams>(`${endpoint}/spots/${spotId}/modules`, queryParams);
    }

    public static async postSpotModules(spotId: string, module: CreateModule): Promise<Module> {
        const response = await this.post(`${endpoint}/spots/${spotId}/modules`, module);
        return await this.handleResponse<Module>(response);
    }

    public static async fetchSpotModule(spotId: string, moduleId: string): Promise<Module> {
        const response = await this.fetch(`${endpoint}/spots/${spotId}/modules/${moduleId}`);
        return await this.handleResponse<Module>(response);
    }

    public static async patchSpotModule(spotId: string, moduleId: string, module: ModuleUpdate): Promise<Module> {
        const response = await this.patch(`${endpoint}/spots/${spotId}/modules/${moduleId}`, module);
        return await this.handleResponse<Module>(response);
    }

    public static async deleteSpotModule(spotId: string, moduleId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/spots/${spotId}/modules/${moduleId}`);
        return await this.handleVoidResponse(response);
    }

    public static async attachModuleToDriver(spotId: string, moduleId: string, body: AttachModuleToDriverBody): Promise<void> {
        const response = await this.post(`${endpoint}/spots/${spotId}/modules/${moduleId}/drivers`, body);
        return await this.handleVoidResponse(response);
    }

    public static async deleteModules(moduleIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/modules-delete`, { ids: moduleIds });
        return await this.handleVoidResponse(response);
    }
}
