import { DefaultModalProps, LoadingModal, ModalType } from '@frontend/elements';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

import { ContactDetailModal } from '../contact-detail-modal/contact-detail-modal.component';
import useContactDetailModalWrapper from './contact-detail-modal-wrapper.controller';

export interface ContactDetailModalWrapperProps extends DefaultModalProps {
    id: string;
    dispatch: ThunkDispatch<any, any, Action>;
}

export const ContactDetailModalWrapper = (props: ContactDetailModalWrapperProps): JSX.Element => {
    const viewProps = useContactDetailModalWrapper(props);
    if (!viewProps.contact) {
        return (
            <LoadingModal
                type={ModalType.PANE}
                customWidth={40}
                {...props}
            />
        );
    }
    return (
        <ContactDetailModal
            contact={viewProps.contact}
            customWidth={40}
            {...props}
        />
    );
};
