import React from 'react';
import Select from 'react-select';

import { getClassName, getInputClassMultiSelect } from '../input-class';
import { SelectInputProps } from '../select-input/select-input.component';
import useMultiSelectInput from './multi-select-input.controller';

const ID = 'creatable-select-input';

export interface MultiSelectInputProps extends SelectInputProps {
    label: React.ReactNode;
    valid?: boolean;
    isValidCallback?: (valid: boolean) => void;
    onFocusChange?: () => void;
    submitted: boolean;
    value?: any[] | any;
    useConditionedStyling?: boolean;
    errorMessage?: React.ReactNode;
}

export const MultiSelectInput = (props: MultiSelectInputProps) => {
    const viewProps = useMultiSelectInput(props);

    return (
        <div
            id={ID}
            className={`form-group ${props.className ? props.className : ''}`}>
            <label>
                {props.required ? <span className='text-danger me-1'>&#9679;</span> : <></>} {props.label}
            </label>
            <Select
                id={props.id || 'mutli-select'}
                styles={{
                    control: (baseStyles) => ({
                        ...baseStyles,
                        boxShadow: 'none',
                        minWidth: '190px',
                        padding: '1.204px 0px 1.204px 10px',
                        borderRadius: '0.5rem'
                    }),
                    menu: (baseStyle) => ({
                        ...baseStyle,
                        zIndex: 9999
                    }),
                    valueContainer: (baseStyle) => ({
                        ...baseStyle,
                        padding: '0px'
                    })
                }}
                classNames={{
                    control: () =>
                        props.useConditionedStyling === false
                            ? 'form-control'
                            : props.isMulti
                            ? getInputClassMultiSelect(viewProps.touched, viewProps.valid, props.value)
                            : getClassName(
                                  viewProps.touched,
                                  viewProps.valid,
                                  props.value == null || props.value == undefined ? undefined : JSON.stringify(props.value)
                              )
                }}
                options={props.options}
                value={viewProps.findValue()}
                isMulti={props.isMulti}
                onChange={(v, a) => props.onChange && props.onChange(v, a)}
                placeholder={props.placeholder}
                isClearable={props.isClearable}
                isDisabled={props.isDisabled}
                defaultValue={props.defaultValue}
            />
        </div>
    );
};
