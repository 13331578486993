import { Buttons } from './Buttons';
import { Events } from './Events';
import { Forms } from './Forms';
import { Lists } from './List';
import { Objects } from './Objects';

export const CommonMessage = {
    OBJECTS: Objects,
    FORMS: Forms,
    BUTTONS: Buttons,
    EVENTS: Events,
    LISTS: Lists
};
