import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';

import { TransactionClient } from '@frontend/transaction/api';
import { seedTransactions } from '@frontend/transaction/redux';
import { Transaction } from '@frontend/transaction/types';
import { TransactionServiceEventListener } from './transaction-service-event-listener';

declare global {
    interface WindowEventMap {
        'useTransaction:request': CustomEvent<string>;
    }
}

const REQUEST_TRANSACTION_EVENT = 'useTransaction:request';
const STORAGE_KEY = 'TRX_REQ';

export function transactionRequest(transactionId: string) {
    window.dispatchEvent(new CustomEvent(REQUEST_TRANSACTION_EVENT, { detail: transactionId }));
}

interface Props {
    dispatch: ThunkDispatch<any, any, AnyAction>;
    enablePubSub?: boolean;
}

export function useTransactions(props: Props) {
    const [pending, changePending] = useState<string[]>([]);
    const [timeoutSet, changeTimeoutSet] = useState<boolean>(false);
    const [timeoutFinished, changeTimeoutFinished] = useState<boolean>(false);
    const [transactionEventListener, changeTransactionEventListener] = useState<TransactionServiceEventListener | null>(null);

    useEffect(() => {
        window.addEventListener(REQUEST_TRANSACTION_EVENT, listener);
        if (props.enablePubSub) changeTransactionEventListener(TransactionServiceEventListener.getInstance(props.dispatch));
    }, []);

    useEffect(() => {
        if (timeoutFinished) {
            const stored = localStorage.getItem(STORAGE_KEY);
            if (stored != null) {
                changePending(JSON.parse(stored));
                changeTimeoutSet(false);
                changeTimeoutFinished(false);
                localStorage.removeItem(STORAGE_KEY);
            }
        }
    }, [timeoutFinished]);

    useEffect(() => {
        if (pending.length > 0) {
            getTransactions(pending).then((result) => {
                props.dispatch(seedTransactions(result));
                changePending([]);
                changeTimeoutSet(false);
                changeTimeoutFinished(false);
            });
        }
    }, [pending]);

    const listener = (event: CustomEvent) => {
        if (typeof event.detail == 'string') {
            if (timeoutSet === false) {
                changeTimeoutSet(true);
                setTimeout(() => {
                    changeTimeoutFinished(true);
                }, 1000);
            }
            const stored = localStorage.getItem(STORAGE_KEY);
            if (stored == null) {
                localStorage.setItem(STORAGE_KEY, JSON.stringify([event.detail]));
            } else if (!JSON.parse(stored).includes(event.detail)) {
                localStorage.setItem(STORAGE_KEY, JSON.stringify([...JSON.parse(stored), event.detail]));
            }
        }
    };

    return { transactionEventListener };
}

async function getTransactions(transactionIds: string[]): Promise<Transaction[]> {
    const response = await TransactionClient.resolveTransactions(transactionIds);
    return response.results;
}
