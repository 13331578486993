import { useState } from 'react';

import { ChallengeFormProps } from './challenge-form.component';

interface ViewProps {
    submitted: boolean;
    error: boolean;
    value: string;
    changeValue: React.Dispatch<React.SetStateAction<string>>;
    onSubmit: (e: any) => void;
}

const useChallengeForm = (props: ChallengeFormProps): ViewProps => {
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const [value, changeValue] = useState<string>('');
    const [error, changeError] = useState<boolean>(false);

    const onSubmit = (e: any) => {
        e.preventDefault();
        changeSubmitted(true);
        const filteredByChallenge = props.pinCodes.filter((pin) => pin.challenge === value);
        if (filteredByChallenge.length === 1) {
            changeError(false);
            props.callback(filteredByChallenge);
        } else changeError(true);
        changeSubmitted(false);
    };
    return {
        submitted,
        error,
        value,
        changeValue,
        onSubmit
    };
};

export default useChallengeForm;
