import { CommonMessage } from '@frontend/lang';
import { NotificationContent } from '@frontend/notification-service/types';
import { Column } from 'react-table';

const notificationContentColumns: Column<NotificationContent>[] = [
    {
        Header: 'Content'
    },
    {
        Header: 'Channel',
        accessor: 'channel'
    },
    {
        Header: 'Language',
        accessor: 'language'
    },
    {
        Header: CommonMessage.OBJECTS.DEFAULT.CREATION_TIMESTAMP,
        accessor: 'creation_timestamp'
    },
    {
        Header: CommonMessage.OBJECTS.DEFAULT.UPDATE_TIMESTAMP,
        accessor: 'update_timestamp'
    }
];

export default notificationContentColumns;
