import { ContactClient } from '@frontend/contact/api';
import { seedContacts } from '@frontend/contact/redux';
import { Contact } from '@frontend/contact/types';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';

declare global {
    interface WindowEventMap {
        'useContact:request': CustomEvent<string>;
    }
}

const REQUEST_CONTACT_EVENT = 'useContact:request';
const STORAGE_KEY = 'CTS_REQ';

export function contactRequest(contactId: string) {
    window.dispatchEvent(new CustomEvent(REQUEST_CONTACT_EVENT, { detail: contactId }));
}

interface Props {
    dispatch: ThunkDispatch<any, any, AnyAction>;
}

export function ContactResolver(props: Props) {
    const [pending, changePending] = useState<string[]>([]);
    const [timeoutSet, changeTimeoutSet] = useState<boolean>(false);
    const [timeoutFinished, changeTimeoutFinished] = useState<boolean>(false);

    useEffect(() => {
        window.addEventListener(REQUEST_CONTACT_EVENT, listener);
    }, []);


    useEffect(() => {
        if (timeoutFinished) {
            const stored = localStorage.getItem(STORAGE_KEY);
            if (stored != null) {
                changePending(JSON.parse(stored));
                changeTimeoutSet(false);
                changeTimeoutFinished(false);
                localStorage.removeItem(STORAGE_KEY);
            }
        }
    }, [timeoutFinished]);

    useEffect(() => {
        if (pending.length > 0) {
            getContacts(pending).then((result) => {
                props.dispatch(seedContacts(result));
                changePending([]);
                changeTimeoutSet(false);
                changeTimeoutFinished(false);
            });
        }
    }, [pending]);

    const listener = (event: CustomEvent) => {
        if (typeof event.detail == 'string') {
            if (timeoutSet === false) {
                changeTimeoutSet(true);
                setTimeout(() => {
                    changeTimeoutFinished(true);
                }, 1000);
            }

            const stored = localStorage.getItem(STORAGE_KEY);
            if (stored == null) {
                localStorage.setItem(STORAGE_KEY, JSON.stringify([event.detail]));
            } else if (!JSON.parse(stored).includes(event.detail)) {
                localStorage.setItem(STORAGE_KEY, JSON.stringify([...JSON.parse(stored), event.detail]));
            }
        }
    };

    return <></>;
}

async function getContacts(contactIds: string[]): Promise<Contact[]> {
    const response = await ContactClient.resolveContacts(contactIds);
    return response.results;
}
