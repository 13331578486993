import { patchAccountTemplateNotificationContent } from '@frontend/notification-service/redux';
import { Editor, useCurrentEditor } from '@tiptap/react';
import { useParams } from 'react-router-dom';

import { FooterBarProps } from './footer-bar.component';

interface ViewProps {
    editor: Editor | null;
    saveConfiguration: () => void;
    resetContent: () => void;
    disabled?: boolean;
}

const useFooterBar = (props: FooterBarProps): ViewProps => {
    const { accountId, templateId, contentId } = useParams();
    const { editor } = useCurrentEditor();

    const saveConfiguration = () => {
        if (accountId && templateId && contentId && editor) {
            props.dispatch(
                patchAccountTemplateNotificationContent({
                    accountId: accountId,
                    templateId: templateId,
                    contentId: contentId,
                    body: { content: editor?.getHTML(), subject: props.subject }
                })
            );
        }
    };

    const resetContent = () => {
        if (!editor) return;
        editor.commands.setContent(props.originalContent, true);
        props.resetSubject();
    };

    return {
        editor,
        saveConfiguration,
        resetContent,
        disabled: !accountId || !templateId || !contentId || !editor
    };
};

export default useFooterBar;
