import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { PudoTransactionReportListResponse, TransactionQueryParams, WarehouseTransactionReportListResponse } from '@frontend/transaction/types';

const endpoint = '/transaction-api/v1';
export class TransactionReportClient extends APIClient {
    public static async fetchReportPudoTransactions(queryParams?: ApiQueryParams<DefaultQueryParams | TransactionQueryParams>): Promise<PudoTransactionReportListResponse> {
        return await this.apiPaginated<PudoTransactionReportListResponse, DefaultQueryParams | TransactionQueryParams>(`${endpoint}/report-pudo-transactions`, queryParams);
    }
    public static async fetchReportWarehouseTransactions(queryParams?: ApiQueryParams<DefaultQueryParams | TransactionQueryParams>): Promise<WarehouseTransactionReportListResponse> {
        return await this.apiPaginated<WarehouseTransactionReportListResponse, DefaultQueryParams | TransactionQueryParams>(`${endpoint}/report-warehouse-transactions`, queryParams);
    }
}
