import { AuthorizationWrapper, Permission } from '@frontend/authorization';
import React from 'react';
import { IconType } from 'react-icons';

import useHorizontalNavbar from './horizontal-navbar.controller';

export interface NavItem {
    title: string;
    icon?: IconType;
    hide?: boolean;
    requiredPermissions?: Permission[];
}

export interface HorizontalNavbarProps {
    navItems: NavItem[];
    active?: string;
    onChange: (v: string) => void;
    requiredPermissions?: Permission[];
}

/**
 * TODO: if no navbar items exists the bar should be hidden (alos when this happens using permissions on the item itself)
 * transforming it into lowercase also makes this very confusing (it should really just use the string that has been passed to it)
 */

export const HorizontalNavbar = (props: HorizontalNavbarProps) => {
    const viewProps = useHorizontalNavbar(props);
    const content = (
        <div className='card mt-3'>
            <div className='nav-wrapper position-relative end-0'>
                <ul
                    className='nav nav-pills nav-fill p-1'
                    role='tablist'>
                    {viewProps.activeNavbars.map((nav) => {
                        const content = (
                            <li
                                onClick={() => viewProps.changeActive(nav.title)}
                                className='nav-item cursor-pointer'
                                key={nav.title}>
                                <a className={`nav-link mb-0 px-0 py-1 ${viewProps.active.toLowerCase() === nav.title.toLocaleLowerCase() ? 'active' : ''}`}>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        {nav.icon && <nav.icon className='me-2' />} {nav.title}
                                    </div>
                                </a>
                            </li>
                        );
                        if (nav.requiredPermissions) {
                            return (
                                <AuthorizationWrapper
                                    requiredPermissions={nav.requiredPermissions}
                                    key={nav.title}>
                                    {content}
                                </AuthorizationWrapper>
                            );
                        } else return content;
                    })}
                </ul>
            </div>
        </div>
    );

    if (viewProps.activeNavbars.length === 0) return <></>;
    if (props.requiredPermissions) {
        return <AuthorizationWrapper requiredPermissions={props.requiredPermissions}>{content}</AuthorizationWrapper>;
    } else return content;
};
