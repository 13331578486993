import { RowProps } from '@frontend/table';
import { User } from '@frontend/user/types';
import React from 'react';
import { Row } from 'react-table';


interface UserListElementProps extends RowProps {
    selectEnabled?: boolean;
    row: Row<User>;
    onClick?: (element: User) => void;
}

const UserListElement = (props: UserListElementProps) => {
    return (
        <tr
            id='UserList-Row'
            onClick={() => props.onClick && props.onClick(props.row.original)}
            className={'' + (props.onClick && 'cursor-pointer')}>
            {props.row.cells.map((cell) => {
                return cell.render('Cell');
            })}
        </tr>
    );
};

export default UserListElement;
