import { ContactSelect } from '@frontend/contact/components';
import { WorkflowVariable } from '@frontend/workflow-variable/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

import useContactIdContact from './contact-id-contact.controller';

const ID = 'contact-id-contact';
export interface ContactIdContactProps {
    callback: (variable: WorkflowVariable) => void;
    variable: WorkflowVariable;
    dispatch: ThunkDispatch<any, any, Action>;
}

const ContactIdContact = (props: ContactIdContactProps) => {
    const viewProps = useContactIdContact(props);

    return (
        <div
            id={ID}
            className='d-flex flex-column justify-content-between h-100 p-1'>
            <form
                onSubmit={viewProps.onSubmit}
                className='d-flex flex-row'>
                <ContactSelect
                    className='w-100 m-1'
                    value={viewProps.contactId}
                    onChange={viewProps.changeContactId}
                    submitted={viewProps.submitted}
                    dispatch={props.dispatch}
                />
                <button
                    type='submit'
                    className='btn btn-primary btn-lg w-100'
                    disabled={!viewProps.contactId || viewProps.submitted}>
                    Submit
                </button>
            </form>
        </div>
    );
};

export default ContactIdContact;
