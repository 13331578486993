import { SeverityBadgeProps } from './severity-badge.component';

interface ViewProps {
    type: string;
}

const useSeverityBadge = (props: SeverityBadgeProps): ViewProps => {
    if (['alert', 'danger', 'debug', 'info', 'warning'].includes(props.severity)) return { type: props.severity };
    return { type: 'primary' };
};

export default useSeverityBadge;
