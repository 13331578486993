import { addDevice, removeDevice, updateDevice } from "@frontend/edge/redux";
import { Device, DeviceSensorDebugData, DeviceSensorErrorData } from "@frontend/edge/types";
import { Logger } from "@frontend/Logger";
import { EventListener } from "@frontend/pub-sub";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";


export enum MessageTypes {
    SENSOR = 'device_sensor_received',
}

export class ServiceDeviceEventListener extends EventListener<Device> {
    private static instance: ServiceDeviceEventListener | null = null;
    private constructor(dispatch: ThunkDispatch<any, any, Action>) {
        super('edge', dispatch);
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>): ServiceDeviceEventListener {
        if (this.instance == null) {
            this.instance = new ServiceDeviceEventListener(dispatch);
        }
        return this.instance;
    }

    getInstance(dispatch: ThunkDispatch<any, any, Action>): EventListener<Device> {
        return ServiceDeviceEventListener.getInstance(dispatch);
    }

    protected override onMessageEvent(event: MessageEvent<string>): void {
        const payload = JSON.parse(event.data) as { message: MessageTypes; data: DeviceSensorDebugData | DeviceSensorErrorData; changes?: any };
        //TODO handle sensor
    }

    protected onSensor(payload: { message: MessageTypes; data: Device }) { 
        //TODO handle sensor
    }

    protected onUpdate(payload: { message: MessageTypes; data: Device; changes?: any }) {
        Logger.log('Updating device in store', { device: payload.data.id }, payload.data);
        this.dispatch(updateDevice(payload.data));
    }

    protected onDelete(payload: { message: MessageTypes; data: Device }) {
        Logger.log('Removing device from store', { device: payload.data.id }, payload.data);
        this.dispatch(removeDevice(payload.data.id));
    }

    protected onCreate(payload: { message: MessageTypes; data: Device; changes?: any }) {
        Logger.log('Adding device to store', { device: payload.data.id }, payload.data);
        this.dispatch(addDevice(payload.data));
    }
}