import {
    deleteAccountNotificationTemplate,
    fetchAccountNotificationTemplate,
    fetchAccountTemplateNotificationContents,
    notificationContentStore,
    notificationTemplateStore
} from '@frontend/notification-service/redux';
import { NotificationContent, NotificationTemplate } from '@frontend/notification-service/types';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Column } from 'react-table';

import { useAppSelector } from '@frontend/common';
import { useSelector } from 'react-redux';
import notificationContentColumns from '../../notification-content/notification-content-list/notification-content-columns';
import { NotificationTemplateDetailProps } from './notification-template-detail.component';

interface ViewProps {
    isLoading: boolean;
    notificationTemplate: NotificationTemplate | undefined;
    data: NotificationContent[];
    page: number;
    pageSize: number;
    changePage: (value: React.SetStateAction<number>) => void;
    changePageSize: (value: React.SetStateAction<number>) => void;
    columns: Column<NotificationContent>[];
    pageCount: number;
    showUpdateModal: boolean;
    changeShowUpdateModal: (value: React.SetStateAction<boolean>) => void;
    showDeleteModal: boolean;
    changeShowDeleteModal: (value: React.SetStateAction<boolean>) => void;
    removeNotificationTemplate: () => void;
    showCreateContentModal: boolean;
    changeShowCreateContentModal: (value: React.SetStateAction<boolean>) => void;
    showCopyModal: boolean;
    changeShowCopyModal: Dispatch<SetStateAction<boolean>>;
}

const useNotificationTemplateDetail = (props: NotificationTemplateDetailProps): ViewProps => {
    const { accountId, templateId } = useParams();
    const notificationTemplateState = useAppSelector(useSelector, notificationTemplateStore);
    const notificationContentState = useAppSelector(useSelector, notificationContentStore);
    const [notificationTemplate, changeNotificationTemplate] = useState<NotificationTemplate | undefined>(undefined);
    const [page, changePage] = useState<number>(0);
    const [pageSize, changePageSize] = useState<number>(25);
    const [showUpdateModal, changeShowUpdateModal] = useState<boolean>(false);
    const [showDeleteModal, changeShowDeleteModal] = useState<boolean>(false);
    const [showCreateContentModal, changeShowCreateContentModal] = useState<boolean>(false);
    const [showCopyModal, changeShowCopyModal] = useState<boolean>(false);
    const navigate = useNavigate();
    useEffect(() => {
        if (accountId && templateId) {
            const foundTemplate = notificationTemplateState.accountTemplates?.[accountId]?.results.find((t) => t.id == templateId);
            if (foundTemplate == undefined) props.dispatch(fetchAccountNotificationTemplate({ accountId: accountId, notificationTemplateId: templateId }));
            else changeNotificationTemplate(foundTemplate);
            props.dispatch(
                fetchAccountTemplateNotificationContents({
                    accountId: accountId,
                    templateId: templateId,
                    queryParams: { index: page.toString(), size: pageSize.toString() }
                })
            );
        }
    }, []);

    useEffect(() => {
        if (accountId && templateId) {
            const foundTemplate = notificationTemplateState.accountTemplates?.[accountId]?.results.find((t) => t.id == templateId);
            if (foundTemplate && notificationTemplate == undefined) {
                changeNotificationTemplate(foundTemplate);
            }
        }
    }, [notificationTemplateState]);

    const notificationContentArray = useMemo(() => {
        if (
            accountId &&
            templateId &&
            notificationContentState.templateContents &&
            notificationContentState.templateContents[accountId] &&
            notificationContentState.templateContents[accountId][templateId]
        ) {
            return notificationContentState.templateContents[accountId][templateId].results;
        } else return [];
    }, [notificationContentState.templateContents]);

    const data = useMemo(() => {
        return notificationContentArray;
    }, [notificationContentArray]);

    const columns: Column<NotificationContent>[] = useMemo(() => notificationContentColumns, []);

    const calcPageCount = () => {
        if (
            notificationContentState.templateContents &&
            accountId &&
            templateId &&
            notificationContentState.templateContents[accountId] &&
            notificationContentState.templateContents[accountId][templateId]
        ) {
            return Math.ceil(notificationContentState.templateContents[accountId][templateId].count / pageSize);
        } else return 1;
    };

    function removeNotificationTemplate() {
        if (!accountId || !templateId) return;
        props.dispatch(deleteAccountNotificationTemplate({ accountId: accountId, templateId: templateId })).then(() => navigate('/notification-templates'));
    }

    return {
        isLoading: notificationContentState.templateContents == null,
        notificationTemplate,
        data,
        columns,
        page,
        pageSize,
        changePage,
        changePageSize,
        pageCount: calcPageCount(),
        changeShowUpdateModal,
        showUpdateModal,
        changeShowDeleteModal,
        showDeleteModal,
        removeNotificationTemplate,
        changeShowCreateContentModal,
        showCreateContentModal,
        changeShowCopyModal,
        showCopyModal
    };
};

export default useNotificationTemplateDetail;
