import { VERSION } from '../../version';

const ID = 'footer';

const Footer = () => {
    return (
        <small
            id={ID}
            className='d-flex flex-row justify-content-center align-items-center w-100 min-height-20'>
            <span className='text-xs opacity-4'>{VERSION}</span>
        </small>
    );
};

export default Footer;
