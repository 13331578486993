import { WorkflowTrigger, WorkflowTriggerName } from "@frontend/workflow/types";
import React from "react";
import { FormattedMessage } from "react-intl";

export enum ProductTriggerName {
    PRODUCT_STATE_CHANGED = 'product_state_changed',
    PRODUCT_WITHDRAWN = 'product_withdrawn',
    PRODUCT_RETURNED = 'product_returned',
    PRODUCT_CODE_SCANNED = 'product_code_scanned'
}

export class ProductTrigger extends WorkflowTrigger {

    static readonly PRODUCT_STATE_CHANGED = new ProductTrigger(
        ProductTriggerName.PRODUCT_STATE_CHANGED,
        (
            <FormattedMessage
                id='ProductTrigger.product_state_changed'
                description='The displayed value for the ProductTrigger product_state_changed'
                defaultMessage='Product state changed'
            />
        )
    );

    static readonly PRODUCT_WITHDRAWN = new ProductTrigger(
        ProductTriggerName.PRODUCT_WITHDRAWN,
        (
            <FormattedMessage
                id='ProductTrigger.product_withdrawn'
                description='The displayed value for the ProductTrigger product_withdrawn'
                defaultMessage='Product withdrawn'
            />
        )
    );

    static readonly PRODUCT_RETURNED = new ProductTrigger(
        ProductTriggerName.PRODUCT_RETURNED,
        (
            <FormattedMessage
                id='ProductTrigger.product_returned'
                description='The displayed value for the ProductTrigger product_returned'
                defaultMessage='Product returned'
            />
        )
    );

    static readonly PRODUCT_CODE_SCANNED = new ProductTrigger(
        ProductTriggerName.PRODUCT_CODE_SCANNED,
        (
            <FormattedMessage
                id='ProductTrigger.product_code_scanned'
                description='The displayed value for the ProductTrigger product_code_scanned'
                defaultMessage='Product code scanned'
            />
        )
    );

    static override readonly ALL = [
        ProductTrigger.PRODUCT_STATE_CHANGED,
        ProductTrigger.PRODUCT_WITHDRAWN,
        ProductTrigger.PRODUCT_RETURNED,
        ProductTrigger.PRODUCT_CODE_SCANNED,
        ...super.ALL
    ];

    static override getByValue(trigger: ProductTriggerName | WorkflowTriggerName): WorkflowTrigger | undefined {
        return this.ALL.find((a) => a.value == trigger);
    }
}