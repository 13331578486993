import { Restriction } from '@frontend/authorization';
import { ClassType } from '@frontend/common';
import { ConfirmationModal, DefaultModal } from '@frontend/elements';
import { RowProps } from '@frontend/table';
import { lazy } from 'react';
import { FaEdit } from 'react-icons/fa';
import { IoMdTrash } from 'react-icons/io';
import { Row } from 'react-table';

import { useAppDispatch } from '../../../redux/store';
import UpdateRestrictionForm from '../update-restriction-form/update-restriction-form.component';
import useRestrictionListElement from './restriction-list-element.controller';

const ProductBadge = lazy(() => import('@frontend/product/components').then((module) => ({ default: module.ProductBadge })));

export interface RestrictionListElementProps extends RowProps {
    selectEnabled?: boolean;
    row: Row<Restriction>;
    onClick?: (element: Restriction) => void;
}

const RestrictionListElement = (props: RestrictionListElementProps) => {
    const dispatch = useAppDispatch();
    const viewProps = useRestrictionListElement(props);
    return (
        <tr
            id='RestrictionList-Row'
            onClick={() => props.onClick && props.onClick(props.row.original)}
            className={'' + (props.onClick && 'cursor-pointer')}>
            {props.selectEnabled && (
                <td
                    onClick={(e) => e.stopPropagation()}
                    {...props.row.cells[0].getCellProps()}>
                    {props.row.cells[0].render('Cell')}
                </td>
            )}
            <td className='text-secondary text-xs font-weight-bolder align-content-center'>
                <span>{props.row.original.type}</span>
            </td>
            <td className='text-secondary text-xs font-weight-bolder align-content-center'>
                <span>{props.row.original.key}</span>
            </td>
            <td className='text-secondary text-xs font-weight-bolder align-content-center'>
                {getCorrectValueDisplay(props.row.original.type, props.row.original.key, props.row.original.value)}
            </td>
            <td>
                <button
                    onClick={() => viewProps.changeShowDeleteModal(true)}
                    className='btn btn-outline-danger px-1 pb-1 pt-0 my-0 mx-1'>
                    <IoMdTrash />
                </button>
                <button
                    onClick={() => viewProps.changeShowUpdateModal(true)}
                    className='btn btn-outline-warning px-1 pb-1 pt-0 my-0 mx-1'>
                    <FaEdit />
                </button>
            </td>
            {viewProps.showDeleteModal && (
                <ConfirmationModal
                    handleClose={() => viewProps.changeShowDeleteModal(false)}
                    message={`Are you sure you want to delete restriction: \n ${props.row.original.type} => ${props.row.original.key} => ${props.row.original.value}`}
                    onConfirm={viewProps.onDelete}
                    show={viewProps.showDeleteModal}
                    severity={ClassType.DANGER}
                />
            )}
            {viewProps.showUpdateModal && (
                <DefaultModal
                    handleClose={() => viewProps.changeShowUpdateModal(false)}
                    show={viewProps.showUpdateModal}>
                    <UpdateRestrictionForm
                        dispatch={dispatch}
                        onClose={() => viewProps.changeShowUpdateModal(false)}
                        restriction={props.row.original}
                    />
                </DefaultModal>
            )}
        </tr>
    );
};

export default RestrictionListElement;

function getCorrectValueDisplay(type: string, key: string, value: string) {
    switch (key) {
        case 'id': {
            switch (type) {
                case 'PRODUCT':
                    return <ProductBadge id={value} />;
                default:
                    return value;
            }
        }
        default:
            return value;
    }
}
