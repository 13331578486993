import { WorkflowTrigger, WorkflowTriggerName } from "@frontend/workflow/types";
import React from "react";
import { FormattedMessage } from "react-intl";

export enum DeviceTriggerName {
    DEVICE_OPEN_SLOT = 'device_open_slot',
    DEVICE_STATE_CHANGED = 'device_state_changed',
    DOOR_OPENING_SENSOR_RECEIVED = 'door_opening_sensor_received',
    DOOR_UNLOCKED_SENSOR_RECEIVED = 'door_unlocked_sensor_received',
    DOOR_OPENED_SENSOR_RECEIVED = 'door_opened_sensor_received',
    DOOR_CLOSED_SENSOR_RECEIVED = 'door_closed_sensor_received'
}

export class DeviceTrigger extends WorkflowTrigger {
    static readonly DEVICE_OPEN_SLOT = new DeviceTrigger(
        DeviceTriggerName.DEVICE_OPEN_SLOT,
        (
            <FormattedMessage
                id='DeviceTrigger.device_open_slot'
                description='The displayed value for the DeviceTrigger device_open_slot'
                defaultMessage='Device open slot'
            />
        )
    );

    static readonly DEVICE_STATE_CHANGED = new DeviceTrigger(
        DeviceTriggerName.DEVICE_STATE_CHANGED,
        (
            <FormattedMessage
                id='DeviceTrigger.device_state_changed'
                description='The displayed value for the DeviceTrigger device_state_changed'
                defaultMessage='Device state changed'
            />
        )
    );

    static readonly DOOR_OPENING_SENSOR_RECEIVED = new DeviceTrigger(
        DeviceTriggerName.DOOR_OPENING_SENSOR_RECEIVED,
        (
            <FormattedMessage
                id='DeviceTrigger.door_opening_sensor_received'
                description='The displayed value for the DeviceTrigger door_opening_sensor_received'
                defaultMessage='Door opening sensor received'
            />
        )
    );

    static readonly DOOR_UNLOCKED_SENSOR_RECEIVED = new DeviceTrigger(
        DeviceTriggerName.DOOR_UNLOCKED_SENSOR_RECEIVED,
        (
            <FormattedMessage
                id='DeviceTrigger.door_unlocked_sensor_received'
                description='The displayed value for the DeviceTrigger door_unlocked_sensor_received'
                defaultMessage='Door unlocked sensor received'
            />
        )
    );

    static readonly DOOR_OPENED_SENSOR_RECEIVED = new DeviceTrigger(
        DeviceTriggerName.DOOR_OPENED_SENSOR_RECEIVED,
        (
            <FormattedMessage
                id='DeviceTrigger.door_opened_sensor_received'
                description='The displayed value for the DeviceTrigger door_opened_sensor_received'
                defaultMessage='Door opened sensor received'
            />
        )
    );

    static readonly DOOR_CLOSED_SENSOR_RECEIVED = new DeviceTrigger(
        DeviceTriggerName.DOOR_CLOSED_SENSOR_RECEIVED,
        (
            <FormattedMessage
                id='DeviceTrigger.door_closed_sensor_received'
                description='The displayed value for the DeviceTrigger door_closed_sensor_received'
                defaultMessage='Door closed sensor received'
            />
        )
    );

    static override readonly ALL = [
        DeviceTrigger.DEVICE_OPEN_SLOT,
        DeviceTrigger.DEVICE_STATE_CHANGED,
        DeviceTrigger.DOOR_OPENING_SENSOR_RECEIVED,
        DeviceTrigger.DOOR_UNLOCKED_SENSOR_RECEIVED,
        DeviceTrigger.DOOR_OPENED_SENSOR_RECEIVED,
        DeviceTrigger.DOOR_CLOSED_SENSOR_RECEIVED,
        ...super.ALL
    ];

    static override getByValue(trigger: DeviceTriggerName | WorkflowTriggerName): WorkflowTrigger | undefined {
        return this.ALL.find(t => t.value === trigger);
    }
}