import { fetchAccountNotificationTemplates, fetchNotificationTemplates, notificationTemplateStore } from '@frontend/notification-service/redux';
import { NotificationTemplate } from '@frontend/notification-service/types';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { SetURLSearchParams, useSearchParams } from 'react-router-dom';
import { Column, SortingRule } from 'react-table';

import { useAppSelector } from '@frontend/common';
import { useSelector } from 'react-redux';
import notificationTemplateColumns from './notification-template-columns';
import { NotificationTemplateListProps } from './notification-template-list.component';

interface ViewProps {
    isLoading: boolean;
    data: NotificationTemplate[];
    columns: Column<any>[];
    page: number;
    pageSize: number;
    pageCount: number;
    setPage: (value: React.SetStateAction<number>) => void;
    setPageSize: (value: React.SetStateAction<number>) => void;
    showCreateModal: boolean;
    changeShowCreateModal: (value: React.SetStateAction<boolean>) => void;
    accountId: string | null;
    setSearchParams: SetURLSearchParams;
    changeSortState: React.Dispatch<React.SetStateAction<SortingRule<object>[]>>;
    changeSearchValue: Dispatch<SetStateAction<string>>;
}

const useNotificationTemplateList = (props: NotificationTemplateListProps): ViewProps => {
    const notificationTemplateState = useAppSelector(useSelector, notificationTemplateStore);
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(25);
    const [showCreateModal, changeShowCreateModal] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const accountId = searchParams.get('account');
    const [sortState, changeSortState] = useState<SortingRule<object>[]>([]);
    const [searchValue, changeSearchValue] = useState<string>('');

    useEffect(() => {
        if (accountId) {
            props.dispatch(
                fetchAccountNotificationTemplates({
                    accountId: accountId,
                    params: {
                        index: (page - 1).toString(),
                        size: pageSize.toString(),
                        ...(sortState.length > 0 && { sort_field: sortState[0].id, sort_direction: sortState[0].desc ? 'desc' : 'asc' }),
                        ...(searchValue != '' && { search: searchValue })
                    }
                })
            );
        } else {
            props.dispatch(
                fetchNotificationTemplates({
                    index: (page - 1).toString(),
                    size: pageSize.toString(),
                    ...(sortState.length > 0 && { sort_field: sortState[0].id, sort_direction: sortState[0].desc ? 'desc' : 'asc' }),
                    ...(searchValue != '' && { search: searchValue })
                })
            );
        }
    }, [page, pageSize, accountId, sortState, searchValue]);

    const data = useMemo(() => {
        if (accountId) {
            if (!notificationTemplateState.accountTemplates || !notificationTemplateState.accountTemplates[accountId]) return [];
            else return notificationTemplateState.accountTemplates[accountId].results;
        }
        if (notificationTemplateState.templates == null) return [];
        return notificationTemplateState.templates.results;
    }, [notificationTemplateState, accountId]);

    const columns: Column<any>[] = useMemo(() => notificationTemplateColumns, []);

    const calcPageCount = () => {
        if (!accountId) {
            return notificationTemplateState.templates ? Math.ceil(notificationTemplateState.templates.count / pageSize) : 1;
        } else {
            return notificationTemplateState.accountTemplates && notificationTemplateState.accountTemplates[accountId]
                ? Math.ceil(notificationTemplateState.accountTemplates[accountId].count / pageSize)
                : 1;
        }
    };

    return {
        changeShowCreateModal,
        columns,
        data,
        isLoading: notificationTemplateState.templates == null,
        page,
        pageCount: calcPageCount(),
        pageSize,
        setPage,
        setPageSize,
        showCreateModal,
        accountId,
        setSearchParams,
        changeSortState,
        changeSearchValue
    };
};

export default useNotificationTemplateList;
