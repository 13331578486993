import { AuthorizationWrapper, Permission } from '@frontend/authorization';
import { Link } from 'react-router-dom';

import { NavbarItems } from '../navbar/navbar.component';
import useNavbarItem from './navbar-item.controller';

export interface NavbarItemProps {
    text: string;
    link: NavbarItems;
    icon: React.ReactNode;
    parent?: string;
    requiredPermissions?: Permission[];
}

const NavbarItem = (props: NavbarItemProps) => {
    const viewProps = useNavbarItem(props);

    if (props.requiredPermissions) {
        return (
            <AuthorizationWrapper requiredPermissions={props.requiredPermissions}>
                <Link
                    id={'nav-' + props.link.toLowerCase()}
                    to={viewProps.path()}
                    className={viewProps.isActive() ? 'd-block w-100 bg-light rounded' : ''}>
                    <li
                        className='nav-item'
                        key={props.text}>
                        <a className='nav-link'>
                            <div className='icon icon-sm d-flex align-items-center justify-content-center'>
                                <i className='text-primary text-sm opacity-10'>{props.icon}</i>
                            </div>
                            <span className='nav-link-text m-2'>{props.text}</span>
                        </a>
                    </li>
                </Link>
            </AuthorizationWrapper>
        );
    }
    return (
        <Link
            id={'nav-' + props.link.toLowerCase()}
            to={viewProps.path()}
            className={viewProps.isActive() ? 'd-block w-100 bg-light rounded' : ''}>
            <li
                className='nav-item'
                key={props.text}>
                <a className='nav-link'>
                    <div className='icon icon-sm d-flex align-items-center justify-content-center'>
                        <i className='text-primary text-sm opacity-10'>{props.icon}</i>
                    </div>
                    <span className='nav-link-text m-2'>{props.text}</span>
                </a>
            </li>
        </Link>
    );
};

export default NavbarItem;
