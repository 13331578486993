import { lazy } from 'react';

import { useAppDispatch } from '../redux/store';

const AsyncComponent = lazy(() => import('@frontend/elements').then((module) => ({ default: module.AsyncComponent })));
const AccountResolver = lazy(() => import('@frontend/account/events').then((module) => ({ default: module.AccountResolver })));
const WorkflowResolver = lazy(() => import('@frontend/workflow/events').then((module) => ({ default: module.WorkflowResolver })));
const SpotResolver = lazy(() => import('@frontend/spot/events').then((module) => ({ default: module.SpotResolver })));
const ModuleResolver = lazy(() => import('@frontend/module/events').then((module) => ({ default: module.ModuleResolver })));
const UserInterfacesResolver = lazy(() => import('@frontend/user-interface/events').then((module) => ({ default: module.UserInterfacesResolver })));
const ContactResolver = lazy(() => import('@frontend/contact/events').then((module) => ({ default: module.ContactResolver })));
const WareResolver = lazy(() => import('@frontend/stock/events').then((module) => ({ default: module.WareResolver })));
const MovementResolver = lazy(() => import('@frontend/stock/events').then((module) => ({ default: module.MovementResolver })));
const ProductCategoryResolver = lazy(() => import('@frontend/product-category/events').then((module) => ({ default: module.ProductCategoryResolver })));
const ProductCatalogResolver = lazy(() => import('@frontend/product-catalog/events').then((module) => ({ default: module.ProductCatalogResolver })));
const ImportLogResolver = lazy(() => import('@frontend/import-logs/events').then((module) => ({ default: module.ImportLogResolver })));
const CertificateResolver = lazy(() => import('@frontend/certificate/events').then((module) => ({ default: module.CertificateResolver })));
const ProductResolver = lazy(() => import('@frontend/product/events').then((module) => ({ default: module.ProductResolver })));

const Resolver = (props: { children: React.ReactNode }) => {
    const dispatch = useAppDispatch();

    return (
        <AsyncComponent>
            <AccountResolver dispatch={dispatch} />
            <WorkflowResolver dispatch={dispatch} />
            <SpotResolver dispatch={dispatch} />
            <ModuleResolver dispatch={dispatch} />
            <UserInterfacesResolver dispatch={dispatch} />
            <ContactResolver dispatch={dispatch} />
            <WareResolver dispatch={dispatch} />
            <MovementResolver dispatch={dispatch} />
            <ProductCategoryResolver dispatch={dispatch} />
            <ProductCatalogResolver dispatch={dispatch} />
            <ImportLogResolver dispatch={dispatch} />
            <CertificateResolver dispatch={dispatch} />
            <ProductResolver dispatch={dispatch} />
            {props.children}
        </AsyncComponent>
    );
};

export default Resolver;
