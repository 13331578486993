import { jwtDecode } from 'jwt-decode';

import { Token } from './models';

export class AuthenticationProvider {
    #location: string = 'token';
    public loggedIn: boolean;

    protected constructor(location?: string) {
        if (location) this.#location = location;
        try {
            this.loggedIn = this.hasValidToken();
        } catch (e) {
            this.loggedIn = false;
        }
    }

    //TODO
    async getTokenWhenReady(): Promise<Token | null> {
        return new Promise((resolve) => {
            resolve(this.getTokenFromStorage());
        });
    }

    logout(callback?: () => any): void {
        localStorage.removeItem(this.#location);
        this.loggedIn = false;
        callback && callback();
    }

    hasValidToken(): boolean {
        try {
            const credentials = this.getTokenFromStorage();
            if (!credentials) return false;
            const decoded = jwtDecode(credentials.jwt_token);
            if (!decoded.exp) return false;
            const expirationTime = decoded.exp * 1000;
            return new Date().getTime() < expirationTime;
        } catch (e) {
            return false;
        }
    }

    /**
     * This function will wait until a valid token is available.
     * With a default timeout of 5 seconds.
     * @returns
     */
    waitForToken(timeoutMs: number = 5000): Promise<Token> {
        return new Promise((resolve, reject) => {
            const checkToken = () => {
                try {
                    const token = this.getTokenFromStorage();
                    if (token) {
                        resolve(token);
                        clearInterval(interval);
                    }
                } catch (error) {
                    reject(error);
                    clearInterval(interval);
                }
            };

            const interval = setInterval(checkToken, 1000);

            setTimeout(() => {
                clearInterval(interval);
                reject(new Error('Token retrieval timed out'));
            }, timeoutMs);

            checkToken(); // Initial check
        });
    }

    protected getTokenFromStorage(): Token | null {
        const token = localStorage.getItem(this.#location);
        if (token == null) {
            return null;
        }
        return JSON.parse(token) as Token;
    }
    protected saveTokenToStorage(token: Token): void {
        localStorage.setItem(this.#location, JSON.stringify(token));
    }
}
