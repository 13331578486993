import { Logger } from '@frontend/Logger';
import { Spinner } from '@frontend/elements';
import { UserInterfaceType } from '@frontend/user-interface/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

import ButtonDashboard from '../templates/button-dashboard/button-dashboard.component';
import ChooseAckSlot from '../templates/choose-ack-slot/choose-ack-slot.component';
import CreateTransaction from '../templates/create-transaction/create-transaction.component';
import { EnterPin } from '../templates/enter-pin/enter-pin.component';
import PackageProcessing from '../templates/package-processing/package-processing.component';
import ProductNotInUse from '../templates/product-not-in-use/product-not-in-use.component';
import PudoDropoff from '../templates/pudo-dropoff/pudo-dropoff.component';
import PudoPickup from '../templates/pudo-pickup/pudo-pickup.component';
import ReturnProduct from '../templates/return-product/return-product.component';
import ScanBadge from '../templates/scan-badge/scan-badge.component';
import WarehouseProductPickDrop from '../templates/warehouse-product-pick-drop/warehouse-product-pick-drop.component';
import useRenderUserInterface from './render-user-interface.controller';

export interface RenderUserInterfaceProps {
    dispatch: ThunkDispatch<any, any, Action>;
    userInterfaceId: string;
}

export const RenderUserInterface = (props: RenderUserInterfaceProps) => {
    const viewProps = useRenderUserInterface(props);
    if (viewProps.isLoading) return <Spinner />;
    Logger.log('UI found: ' + viewProps.userInterface?.type);
    switch (viewProps.userInterface?.type) {
        case UserInterfaceType.ENTER_PIN:
            return (
                <EnterPin
                    dispatch={props.dispatch}
                    userInterface={viewProps.userInterface}
                />
            );
        case UserInterfaceType.PACKAGE_PROCESSING:
            return <PackageProcessing dispatch={props.dispatch} />;
        case UserInterfaceType.PRODUCT_NOT_IN_USE:
            return <ProductNotInUse dispatch={props.dispatch} />;
        case UserInterfaceType.SCAN_BADGE:
            return <ScanBadge />;
        case UserInterfaceType.DROPOFF_UI:
            return <PudoDropoff dispatch={props.dispatch} />;
        case UserInterfaceType.PICKUP_UI:
            return <PudoPickup dispatch={props.dispatch} />;
        case UserInterfaceType.CREATE_TRANSACTION_UI:
            return (
                <CreateTransaction
                    dispatch={props.dispatch}
                    userInterface={viewProps.userInterface}
                />
            );
        case UserInterfaceType.CHOOSE_ACK_SLOT:
            return (
                <ChooseAckSlot
                    dispatch={props.dispatch}
                    userInterface={viewProps.userInterface}
                />
            );
        case UserInterfaceType.RETURN_PRODUCT:
            return (
                <ReturnProduct
                    dispatch={props.dispatch}
                    userInterface={viewProps.userInterface}
                />
            );
        case UserInterfaceType.WAREHOUSE_PRODUCT_PICK_AND_DROP:
            return (
                <WarehouseProductPickDrop
                    dispatch={props.dispatch}
                    userInterface={viewProps.userInterface}
                />
            );
        case UserInterfaceType.BUTTON_DASHBOARD:
            return (
                <ButtonDashboard
                    dispatch={props.dispatch}
                    userInterface={viewProps.userInterface}
                />
            );
        default:
            return <div>oops somthing went wrong</div>;
    }
};
