import { authenticationMethodsStore } from '@frontend/authentication-methods/redux';
import { useAppSelector } from '@frontend/common';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { BasicAuthBadgeProps } from './basic-auth-badge.component';

interface ViewProps {
    displayName?: { first_name?: string | null; last_name?: string | null } | string;
    disabled: boolean;
}

const useBasicAuthBadge = (props: BasicAuthBadgeProps): ViewProps => {
    const { userId } = useParams();
    const [displayName, changeDisplayName] = useState<{ first_name?: string | null; last_name?: string | null } | string | undefined>({
        first_name: props.first_name,
        last_name: props.last_name
    });
    const [requested, changeRequested] = useState<boolean>(false);
    const [disabled, changeDisabled] = useState<boolean>(false);
    const authState = useAppSelector(useSelector, authenticationMethodsStore).basicAuths;

    useEffect(() => {
        if (props.id != undefined) {
            const found = authState.unordered.find((u) => u.id == props.id);
            if (found) {
                if (!found.first_name) {
                    changeDisplayName(found.email);
                } else {
                    changeDisplayName({ first_name: found.first_name, last_name: found.last_name });
                }
            }
            if (!found && requested) {
                changeDisabled(true);
                changeDisplayName('Not found');
            }
        } else {
            changeDisabled(true);
            changeDisplayName('None');
        }
    }, [authState, props.id]);

    return {
        disabled,
        displayName
    };
};

export default useBasicAuthBadge;
