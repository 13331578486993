import { useMemo } from 'react';

import { useAppSelector } from '../../../redux/store';

interface ViewProps {
    showModal: boolean;
}

const useRestrictionModalContainer = (): ViewProps => {
    const authorizationState = useAppSelector((state) => state.authorization);
    const showModal = useMemo(() => {
        return authorizationState.current != null && authorizationState.current.restrictions != null;
    }, [authorizationState]);
    return { showModal };
};

export default useRestrictionModalContainer;
