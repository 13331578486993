import { BasicAuth, BasicAuthStatus } from '@frontend/authentication-methods/types';
import { ClassType } from '@frontend/common';
import { ActionButton, DropdownButtonGroup, StatusBadge } from '@frontend/elements';
import { RowProps } from '@frontend/table';
import React from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { Row } from 'react-table';

import BasicAuthBadge from '../basic-auth-badge/basic-auth-badge.component';

interface BasicAuthElementProps extends RowProps {
    selectEnabled?: boolean;
    row: Row<BasicAuth>;
    onClick?: (element: BasicAuth) => void;
    onDelete?: (element: BasicAuth) => void;
    onUpdate?: (element: BasicAuth) => void;
}

const BasicAuthElement = (props: BasicAuthElementProps) => {
    return (
        <tr
            id='BasicAuth-Row'
            onClick={() => props.onClick && props.onClick(props.row.original)}
            className={'' + (props.onClick && 'cursor-pointer')}>
            {props.selectEnabled === true && (
                <td
                    onClick={(e) => e.stopPropagation()}
                    {...props.row.cells[0].getCellProps()}>
                    {props.row.cells[0].render('Cell')}
                </td>
            )}
            <td className='text-secondary text-xs font-weight-bolder align-content-center'>
                <BasicAuthBadge
                    id={props.row.original.id}
                    first_name={props.row.original.first_name}
                    last_name={props.row.original.last_name}
                />
            </td>
            <td className='text-secondary text-xs font-weight-bolder align-content-center'>
                <span>{props.row.original.email}</span>
            </td>
            <td className='text-secondary text-xs font-weight-bolder align-content-center'>
                <span>{props.row.original.login_attempt}</span>
            </td>
            <td className='text-secondary text-xs font-weight-bolder align-content-center'>
                <StatusBadge
                    value={props.row.original.status}
                    isTrue={props.row.original.status === BasicAuthStatus.ACTIVE}
                />
            </td>
            <td>
                <ActionButton>
                    <DropdownButtonGroup
                        buttons={[
                            {
                                text: 'Edit',
                                icon: FaEdit,
                                iconColor: ClassType.WARNING,
                                onClick: () => props.onUpdate && props.onUpdate(props.row.original)
                            },
                            {
                                text: 'Delete',
                                icon: FaTrash,
                                iconColor: ClassType.DANGER,
                                onClick: () => props.onDelete && props.onDelete(props.row.original)
                            }
                        ]}
                        showJSON={props.row}
                    />
                </ActionButton>
            </td>
        </tr>
    );
};

export default BasicAuthElement;
