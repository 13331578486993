import { Logger } from '@frontend/Logger';
import { EventListener } from '@frontend/pub-sub';
import { addTransaction, removeTransaction, updateTransaction } from '@frontend/transaction/redux';
import { Transaction } from '@frontend/transaction/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

export enum MessageTypes {
    UPDATED = 'transaction_updated',
    DELETED = 'transaction_deleted',
    CREATED = 'transaction_created'
}

export class TransactionEventListener extends EventListener<Transaction> {
    
    private static instance: TransactionEventListener | null = null;
    private constructor(dispatch: ThunkDispatch<any, any, Action>) {
        super('transaction', dispatch);
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>): TransactionEventListener {
        if (this.instance == null) {
            this.instance = new TransactionEventListener(dispatch);
        }
        return this.instance;
    }

    getInstance(dispatch: ThunkDispatch<any, any, Action>): EventListener<Transaction> {
        return TransactionEventListener.getInstance(dispatch);
    }

    protected onUpdate(payload: { message: MessageTypes; data: Transaction; changes?: any }) {
        Logger.log('Updating transaction in store', { transaction: payload.data.id }, payload.data);
        this.dispatch(updateTransaction(payload.data));
    }

    protected onDelete(payload: { message: MessageTypes; data: Transaction }) {
        Logger.log('Removing transaction from store', { transaction: payload.data.id }, payload.data);
        this.dispatch(removeTransaction(payload.data.id));
    }

    protected onCreate(payload: { message: MessageTypes; data: Transaction; changes?: any }) {
        Logger.log('Adding transaction to store', { transaction: payload.data.id }, payload.data);
        this.dispatch(addTransaction(payload.data));
    }
}