import { AsyncSelectInputSingle } from '@frontend/basic-forms';
import { DefaultModal, ModalType } from '@frontend/elements';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

import CreateContactForm from '../create-contact-form/create-contact-form.component';
import useContactSelect from './contact-select.controller';

const ID = 'contact-select';
export interface ContactSelectProps {
    dispatch: ThunkDispatch<any, any, Action>;
    id?: string;
    label?: string;
    helpMessage?: React.ReactNode;
    required?: boolean;
    submitted: boolean;
    onChange: (newValue: string | null) => void;
    value?: string | null;
    className?: string;
    disabled?: boolean;
    useConditionedStyling?: boolean;
    isClearable?: boolean;

    createEnabled?: boolean;
}

export const ContactSelect = (props: ContactSelectProps) => {
    const viewProps = useContactSelect(props);

    return (
        <>
            <AsyncSelectInputSingle
                className={props.className || 'w-100'}
                id={props.id || ID}
                required={props.required}
                label={props.label || 'Contact'}
                helpMessage={props.helpMessage}
                submitted={props.submitted}
                options={viewProps.options}
                onChange={(newValue) => props.onChange(newValue?.value || null)}
                value={viewProps.value?.value}
                isDisabled={props.disabled}
                onMenuScrollToBottom={viewProps.onScrollToBottom}
                loadOptions={viewProps.search}
                isClearable={props.isClearable}
                useConditionedStyling={props.useConditionedStyling}
                onCreate={props.createEnabled ? () => viewProps.changeShowCreateModal(true) : undefined}
            />
            <DefaultModal
                show={viewProps.showCreateModal}
                handleClose={() => viewProps.changeShowCreateModal(false)}
                type={ModalType.PROMPT}>
                <CreateContactForm
                    onClose={() => viewProps.changeShowCreateModal(false)}
                    dispatch={props.dispatch}
                    callback={(contact) => props.onChange(contact.id)}
                />
            </DefaultModal>
        </>
    );
};
