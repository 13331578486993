import { AccountClient } from '@frontend/account/api';
import { CreateAccount } from '@frontend/account/types';
import { ErrorHandler } from '@frontend/error-handler';
import { useState } from 'react';

import { CreateAccountFormProps } from './create-account-form.component';

interface ViewProps {
    submitted: boolean;
    formValues: CreateAccount;
    formValid: FormValid;
    onSubmit: (e: any) => void;
    changeFormValues: React.Dispatch<React.SetStateAction<CreateAccount>>;
    changeFormValid: React.Dispatch<React.SetStateAction<FormValid>>;
}

interface FormValid {
    address_line_1: boolean;
    address_line_2: boolean;
    zip_code: boolean;
    city: boolean;
    country: boolean;
    contact: boolean;
    name: boolean;
}

const useCreateAccountForm = (props: CreateAccountFormProps): ViewProps => {
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const [formValues, changeFormValues] = useState<CreateAccount>({ name: '' });
    const [formValid, changeFormValid] = useState<FormValid>({
        address_line_1: true,
        address_line_2: true,
        city: true,
        contact: true,
        country: true,
        zip_code: true,
        name: false
    });
    const onSubmit = (e: any) => {
        e.preventDefault();
        changeSubmitted(true);
        AccountClient.postAccount(formValues)
            .then((account) => {
                props.callback && props.callback(account);
                props.onClose();
            })
            .catch((err) => ErrorHandler.handleError(err));
    };

    return {
        submitted,
        formValues,
        formValid,
        onSubmit,
        changeFormValues,
        changeFormValid
    };
};

export default useCreateAccountForm;
