import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface ViewProps {
    modals: string[];
    handleClose: () => void;
}

const useDetailModalContainer = (): ViewProps => {
    const navigate = useNavigate();
    const location = useLocation();
    const [foundIds, changeFoundIds] = useState<string[]>([]);

    useEffect(() => {
        const detail = new URLSearchParams(location.search).get('details');
        if (detail) changeFoundIds(JSON.parse(detail));
        else if (foundIds.length != 0) changeFoundIds([]);
    }, [location]);

    const handleClose = () => {
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.has('details')) {
            const details = queryParams.get('details');
            const detailsArray = details!.slice(1, -1).split(',');

            if (detailsArray.length > 1) {
                detailsArray.pop();
                queryParams.set('details', `[${detailsArray.join(',')}]`);
            } else {
                queryParams.delete('details');
            }
        }

        navigate({
            pathname: location.pathname,
            search: queryParams.toString()
        });
    };

    return {
        modals: foundIds,
        handleClose
    };
};

export default useDetailModalContainer;