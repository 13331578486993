import { contactStore, deleteContacts, fetchContacts } from '@frontend/contact/redux';
import { ErrorHandler } from '@frontend/error-handler';
import { ToastUtil } from '@frontend/toast-utils';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Column, SortingRule } from 'react-table';

import { useAppSelector } from '@frontend/common';
import { Contact } from '@frontend/contact/types';
import { useSelector } from 'react-redux';
import ContactListColumns from './contact-list-columns';
import { ContactListProps } from './contact-list.component';

interface ViewProps {
    isLoading: boolean;
    data: Contact[];
    columns: Column<any>[];
    pageSize: number;
    pageCount: number;
    setPage: (value: React.SetStateAction<number>) => void;
    setPageSize: (value: React.SetStateAction<number>) => void;
    showCreateContactModal: boolean;
    changeShowCreateContactModal: (value: React.SetStateAction<boolean>) => void;
    selectedContacts: string[];
    changeSelectedContacts: React.Dispatch<React.SetStateAction<string[]>>;
    showDeleteModal: boolean;
    changeShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
    removeContacts: () => void;
    changeSortState: React.Dispatch<React.SetStateAction<SortingRule<object>[]>>;
    changeSearchValue: Dispatch<SetStateAction<string>>;
}

const useContactList = (props: ContactListProps): ViewProps => {
    const contactState = useAppSelector(useSelector, contactStore);
    const [pageSize, setPageSize] = useState(25);
    const [page, setPage] = useState(1);
    const [showCreateContactModal, changeShowCreateContactModal] = useState<boolean>(false);
    const [selectedContacts, changeSelectedContacts] = useState<string[]>([]);
    const [showDeleteModal, changeShowDeleteModal] = useState<boolean>(false);
    const [sortState, changeSortState] = useState<SortingRule<object>[]>([]);
    const [searchValue, changeSearchValue] = useState<string>('');

    useEffect(() => {
        props.dispatch(
            fetchContacts({
                index: (page - 1).toString(),
                size: pageSize.toString(),
                ...(sortState.length > 0 && { sort_field: sortState[0].id, sort_direction: sortState[0].desc ? 'desc' : 'asc' }),
                ...(searchValue != '' && { search: searchValue })
            })
        );
    }, [page, pageSize, sortState, searchValue]);

    const removeContacts = () => {
        if (selectedContacts.length === 0) return;
        props.dispatch(deleteContacts(selectedContacts))
            .then(() => {
                ToastUtil.success(`Success`, `Deleted ${selectedContacts.length} contacts`);
                props.dispatch(
                    fetchContacts({
                        index: (page - 1).toString(),
                        size: pageSize.toString(),
                        ...(sortState.length > 0 && { sort_field: sortState[0].id, sort_direction: sortState[0].desc ? 'desc' : 'asc' }),
                        ...(searchValue != '' && { search: searchValue })
                    })
                );
            })
            .catch(ErrorHandler.handleError)
            .finally(() => changeShowDeleteModal(false));
    };

    const data = useMemo(() => {
        if (contactState.contacts == null) return [];
        return contactState.contacts.results;
    }, [contactState]);
    const columns: Column<any>[] = useMemo(() => ContactListColumns, []);

    return {
        isLoading: contactState.contacts == null,
        data,
        columns,
        pageSize,
        pageCount: contactState.contacts ? Math.ceil(contactState.contacts.count / pageSize) : 1,
        setPage,
        setPageSize,
        showCreateContactModal,
        changeShowCreateContactModal,
        changeSelectedContacts,
        changeShowDeleteModal,
        removeContacts,
        selectedContacts,
        showDeleteModal,
        changeSortState,
        changeSearchValue
    };
};

export default useContactList;
