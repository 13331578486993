import { EntityType, useAppSelector } from '@frontend/common';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { resolveStore } from '../resolve/resolve-slice';

export function useDetailModalWrapperRepository<T>(type: EntityType, resolve: (id: string) => void, id?: string) {
    const resolveState = useAppSelector(useSelector, resolveStore);
    const [object, changeObject] = useState<T | null>(null);
    const [requested, changeRequested] = useState<boolean>(false);

    useEffect(() => {
        if (id != undefined) {
            const found = resolveState.resolved ? (resolveState.resolved[type] ? resolveState.resolved[type][id] : null) : null;
            if (found) {
                changeObject(found as T);
            } else if (requested == false) {
                changeRequested(true);
                resolve(id);
            }
        }
    }, [resolveState, id]);

    return {
        object
    };
}
