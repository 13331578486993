export interface Token {
    entity_type: string;
    entity_id: string;
    context: {
        entity_type: string;
        entity_id: string;
    } | null;
    jwt_token: string;
    refresh_token: string;
}

export interface DriverProvision {
    identifier: string;
    type: string;
    brand: string;
    user_id?: string | null;
}

export interface AuthenticationErrorResponse {
    ctx: { [key: string]: string };
    input: string;
    loc: string[];
    msg: string;
    type: string;
}

export class AuthenticationError extends Error {
    detail: string | AuthenticationErrorResponse[];

    constructor(message: string, response: { detail: string | AuthenticationErrorResponse[] }) {
        super(message);
        this.detail = response.detail;
    }
}
