import { certificateRequest } from '@frontend/certificate/events';
import { Certificate } from '@frontend/certificate/types';
import { EntityType } from '@frontend/common';
import { DefaultModalProps, ModalType } from '@frontend/elements';
import { ObjectDetailModal } from '@frontend/rendering/modal';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

export interface CertificateDetailModalProps extends DefaultModalProps {
    id: string;
    dispatch: ThunkDispatch<any, any, Action>;
}
const CertificateDetailModal = (props: CertificateDetailModalProps) => {
    return (
        <ObjectDetailModal<Certificate>
            type={ModalType.PANE}
            dispatch={props.dispatch}
            id={props.id}
            objectType={EntityType.CERTIFICATE}
            handleClose={props.handleClose}
            show={props.show}
            resolve={certificateRequest}
            overwriteKeys={
                new Map([
                    ['description_nl_be', 'description (NL)'],
                    ['description_en_us', 'description (EN)'],
                    ['description_fr_fr', 'description (FR)'],
                    ['description_de_de', 'description (DE)']
                ])
            }
        />
    );
};

export default CertificateDetailModal;
