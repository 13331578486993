import React from 'react';

import useBadgeTemplate from './badge-template.controller';

export interface BadgeTemplateProps {
    type: DetailModalType;
    objectId?: string | null;
    children: React.ReactNode;
    disabled?: boolean;
}

/**
 * @deprecated
 * @param props
 * @returns
 */
export const BadgeTemplate = (props: BadgeTemplateProps) => {
    const viewProps = useBadgeTemplate(props);

    return (
        <span
            key={props.objectId}
            id={'badge-' + props.objectId}
            className={`badge badge-secondary ${props.disabled ? 'disabled' : 'cursor-pointer'} m-1`}
            onClick={(e) => !props.disabled && viewProps.onClick(e)}>
            {props.children}
        </span>
    );
};

export enum DetailModalType {
    ACCOUNT = 'account',
    CONTACT = 'contact',
    USER = 'user',
    TERMINAL = 'terminal',
    RAKINDA = 'rakinda',
    SLOT = 'slot',
    BADGE = 'badge',
    TRANSACTION = 'transaction',
    WAREHOUSE_TRANSACTION = 'warehouse_transaction',
    USER_INTERFACE = 'user_interface',
    EVENT = 'event',
    SPOT = 'spot',
    BASIC_AUTH = 'basic_auth',
    DRIVER = 'driver',
    DEVICE = 'device',
    MODULE = 'module',
    PRODUCT = 'product',
    WORKFLOW = 'workflow',
    WARE = 'ware',
    PACKAGE = 'package',
    WORKFLOW_STEP = 'workflow_step',
    MOVEMENT = 'movement',
    PRODUCT_CATEGORY = 'product_category',
    PRODUCT_CATALOG = 'product_catalog',
    IMPORT_LOG = 'import_log',
    CERTIFICATE = 'certificate',
    UNKNOWN = 'unknown'
}
