import { PubSubConnection } from '@frontend/pub-sub';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

import { DeviceEventHandler } from './device-event-handler';

export class DeviceServiceEventListener extends PubSubConnection {
    private static instance: DeviceServiceEventListener | null = null;
    #deviceEventHandler: DeviceEventHandler;

    private constructor(dispatch: ThunkDispatch<any, any, Action>) {
        super('edge');
        this.#deviceEventHandler = new DeviceEventHandler(dispatch);
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>): DeviceServiceEventListener {
        if (this.instance == null) {
            this.instance = new DeviceServiceEventListener(dispatch);
        }
        return this.instance;
    }

    protected override onMessageEvent(event: MessageEvent<string>): void {
        this.#deviceEventHandler.onMessageEvent(event);
    }
}
