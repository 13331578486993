import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { ProductQueryParams, ProductReportListResponse } from '@frontend/product/types';

const endpoint = '/product-api/v1';

export class ProductReportClient extends APIClient {
    public static async fetchReportProduct(queryParams?: ApiQueryParams<DefaultQueryParams & ProductQueryParams>): Promise<ProductReportListResponse> {
        return await this.apiPaginated<ProductReportListResponse, DefaultQueryParams & ProductQueryParams>(`${endpoint}/report-products`, queryParams);
    }
}
