import { FaEdit, FaPlus, FaTrash } from 'react-icons/fa';
import { HiHome } from 'react-icons/hi';
import { LuLogOut } from 'react-icons/lu';

export const BUTTON_ICONS = {
    HOME: HiHome,
    LOGOUT: LuLogOut,
    UPDATE: FaEdit,
    DELETE: FaTrash,
    CREATE: FaPlus
};
