import { NumberInput } from '@frontend/basic-forms';

import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import useEnterPinDataForm from './enter-pin-data-form.controller';

const ID = 'enter-pin-data-form';
export interface EnterPinDataFormProps {
    onChange: (value: any) => void;
    submitted: boolean;
    dispatch: ThunkDispatch<any, any, Action>;
}

const EnterPinDataForm = (props: EnterPinDataFormProps) => {
    const viewProps = useEnterPinDataForm(props);

    return (
        <div id={ID}>
            <NumberInput
                label='Pin code length'
                onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, pinLength: value })}
                submitted={props.submitted}
                min={4}
                max={10}
                value={viewProps.formValues.pinLength}
            />
        </div>
    );
};

export default EnterPinDataForm;
