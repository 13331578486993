import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { ImportLogsClient } from '@frontend/import-logs/api';
import { ImportLog, ImportLogListResponse } from '@frontend/import-logs/types';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

interface ImportLogsState {
    unordered: ImportLog[];
    importLogs: ImportLogListResponse | null;
    importLogsByAccount: { [accountId: string]: ImportLogListResponse } | null;
    status: SliceStatus;
}

const initialState: ImportLogsState = {
    unordered: [],
    importLogs: null,
    importLogsByAccount: null,
    status: SliceStatus.INIT
};

const importLogsSlice = createSlice({
    name: 'importLogsSlice',
    initialState,
    reducers: {
        seedImportlogs(state, action: PayloadAction<ImportLog[]>) {
            state.unordered = [...state.unordered.filter((log) => action.payload.find((l) => l.id == log.id) == undefined), ...action.payload];
        },
        updateImportLog(state, action: PayloadAction<ImportLog>) {
            state.unordered = state.unordered.map((l) => (l.id == action.payload.id ? action.payload : l));
            if (state.importLogs != null) {
                state.importLogs.results = state.importLogs.results.map((l) => (l.id == action.payload.id ? action.payload : l));
            }

            if (!state.importLogsByAccount) {
                state.importLogsByAccount = { [action.payload.account_id]: { count: 1, results: [action.payload] } };
            } else {
                if (!state.importLogsByAccount[action.payload.account_id]) {
                    state.importLogsByAccount[action.payload.account_id] = { count: 1, results: [action.payload] };
                } else {
                    state.importLogsByAccount[action.payload.account_id].results = state.importLogsByAccount[action.payload.account_id].results.map((l) =>
                        l.id == action.payload.id ? action.payload : l
                    );
                }
            }
        },
        addImportLog(state, action: PayloadAction<ImportLog>) {
            state.unordered.push(action.payload);
            if (state.importLogs != null) {
                state.importLogs.count++;
                state.importLogs.results.splice(0, 0, action.payload);
            }

            if (!state.importLogsByAccount) {
                state.importLogsByAccount = { [action.payload.account_id]: { count: 1, results: [action.payload] } };
            } else {
                if (!state.importLogsByAccount[action.payload.account_id]) {
                    state.importLogsByAccount[action.payload.account_id] = { count: 1, results: [action.payload] };
                } else {
                    state.importLogsByAccount[action.payload.account_id].count++;
                    state.importLogsByAccount[action.payload.account_id].results.splice(0, 0, action.payload);
                }
            }
        },
        removeImportLog(state, action: PayloadAction<string>) {
            state.unordered = state.unordered.filter((l) => l.id != action.payload);
            if (state.importLogs != null) {
                state.importLogs.count--;
                state.importLogs.results = state.importLogs.results.filter((l) => l.id != action.payload);
            }

            if (state.importLogsByAccount) {
                Object.keys(state.importLogsByAccount).forEach((accountId) => {
                    state.importLogsByAccount![accountId].count--;
                    state.importLogsByAccount![accountId].results = state.importLogsByAccount![accountId].results.filter((l) => l.id != action.payload);
                });
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchImportLogs.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchImportLogs.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                const startPos = toNumber(action.meta.arg.index) * toNumber(action.meta.arg.size);
                if (state.importLogs == null) {
                    state.importLogs = { ...action.payload, results: new Array(action.payload.count) };
                    state.importLogs.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.importLogs.results.length < startPos + action.payload.results.length) {
                        state.importLogs.count = action.payload.count;
                        state.importLogs.results = new Array(action.payload.count);
                    }
                    state.importLogs.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.unordered = [
                    ...state.unordered.filter((log) => action.payload.results.find((l) => l.id == log.id) == undefined),
                    ...action.payload.results
                ];
            })
            .addCase(fetchAccountImportLogs.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchAccountImportLogs.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                const startPos = toNumber(action.meta.arg.queryParams?.index) * toNumber(action.meta.arg.queryParams?.size);
                state.unordered = [
                    ...state.unordered.filter((log) => action.payload.results.find((l) => l.id == log.id) == undefined),
                    ...action.payload.results
                ];
                if (state.importLogsByAccount == null) {
                    state.importLogsByAccount = { [action.meta.arg.accountId]: { ...action.payload, results: new Array(action.payload.count) } };
                    state.importLogsByAccount[action.meta.arg.accountId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (!state.importLogsByAccount[action.meta.arg.accountId]) {
                        state.importLogsByAccount[action.meta.arg.accountId] = { ...action.payload, results: new Array(action.payload.count) };
                        state.importLogsByAccount[action.meta.arg.accountId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    } else {
                        if (state.importLogsByAccount[action.meta.arg.accountId].results.length != action.payload.count) {
                            state.importLogsByAccount[action.meta.arg.accountId].count = action.payload.count;
                            state.importLogsByAccount[action.meta.arg.accountId].results = new Array(action.payload.count);
                        }
                    }
                }
            });
    }
});

export const fetchImportLogs = createAsyncThunk<ImportLogListResponse, ApiQueryParams<DefaultQueryParams>>(
    'fetchImportLogs',
    async (queryParams: ApiQueryParams<DefaultQueryParams>, { rejectWithValue }) => {
        try {
            return await ImportLogsClient.fetchImportLogs(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchAccountImportLogs = createAsyncThunk<ImportLogListResponse, { accountId: string; queryParams?: ApiQueryParams<DefaultQueryParams> }>(
    'fetchAccountImportLogs',
    async (variables: { accountId: string; queryParams?: ApiQueryParams<DefaultQueryParams> }, { rejectWithValue }) => {
        try {
            return await ImportLogsClient.fetchAccountImportLogs(variables.accountId, variables.queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const importLogStore = { importLogs: importLogsSlice.reducer };
export const { addImportLog, removeImportLog, seedImportlogs, updateImportLog } = importLogsSlice.actions;
