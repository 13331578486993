import { authenticationMethodsStore } from '@frontend/authentication-methods/redux';
import { BasicAuth } from '@frontend/authentication-methods/types';
import { useAppSelector } from '@frontend/common';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { BasicAuthDetailModalWrapperProps } from './basic-auth-detail-modal-wrapper.component';

interface ViewProps {
    basicAuth?: BasicAuth;
}

const useBasicAuthDetailModalWrapper = (props: BasicAuthDetailModalWrapperProps): ViewProps => {
    const authenticationState = useAppSelector(useSelector, authenticationMethodsStore);
    const [basicAuth, changeBasicAuth] = useState<BasicAuth | undefined>(undefined);

    useEffect(() => {
        const found = authenticationState.basicAuths.unordered.find((b) => b.id === props.id);
        if (found) changeBasicAuth(found);
    }, [props.id, authenticationState]);

    return { basicAuth };
};

export default useBasicAuthDetailModalWrapper;
