import { SelectInput } from '@frontend/basic-forms';
import { UserActionName } from '@frontend/user/types';
import { WorkflowActionName } from '@frontend/workflow/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import { ActionMeta } from 'react-select';
import useUserActionSelect from './user-action-select.controller';

const ID = 'user-action-select';
export interface UserActionSelectProps {
    label?: string;
    className?: string;
    required?: boolean;
    submitted: boolean;
    onChange: (newValue: { value: UserActionName | WorkflowActionName | string; label: any }, actionMeta: ActionMeta<any>) => void;
    value?: string;
    dispatch: ThunkDispatch<any, any, Action>;
}

export const UserActionSelect = (props: UserActionSelectProps) => {
    const viewProps = useUserActionSelect(props);

    return (
        <SelectInput
            id={ID}
            className={props.className}
            label={props.label || 'User action'}
            submitted={props.submitted}
            required={props.required}
            options={viewProps.options}
            value={viewProps.value}
            onChange={(value, actionMeta) => props.onChange(value as { value: UserActionName | WorkflowActionName | string; label: any }, actionMeta)}
        />
    );
};

export default UserActionSelect;
