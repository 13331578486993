import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { WorkflowVariableClient } from '@frontend/workflow/api';
import { WorkflowVariable, WorkflowVariableListResponse } from '@frontend/workflow/types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';


interface WorkflowVariableState {
    unordered: WorkflowVariable[];
    workflowVariablesByAccountAndWorkflow: { [accAndWorkId: string]: WorkflowVariableListResponse } | null;
    unknownWorkflowVariablesByAccountAndWorkflow: { [accAndWorkId: string]: WorkflowVariable[] } | null;
    status: SliceStatus;
}

const initialState: WorkflowVariableState = {
    unordered: [],
    workflowVariablesByAccountAndWorkflow: null,
    unknownWorkflowVariablesByAccountAndWorkflow: null,
    status: SliceStatus.INIT
};

export const workflowVariableSlice = createSlice({
    name: 'workflowVariables',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAccountWorkflowVariables.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchAccountWorkflowVariables.fulfilled, (state, action) => {
                if (action.meta.arg.queryParams.size == null || action.meta.arg.queryParams.index == null) return;
                const listId = action.meta.arg.accountId + ':' + action.meta.arg.workflowId;
                const startPos = toNumber(action.meta.arg.queryParams.size) * (toNumber(action.meta.arg.queryParams.index) - 1);
                if (state.workflowVariablesByAccountAndWorkflow == null) state.workflowVariablesByAccountAndWorkflow = {};
                if (state.workflowVariablesByAccountAndWorkflow[listId] == undefined) {
                    state.workflowVariablesByAccountAndWorkflow[listId] = {
                        ...action.payload,
                        results: new Array(action.payload.count)
                    };
                    state.workflowVariablesByAccountAndWorkflow[listId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.workflowVariablesByAccountAndWorkflow[listId].results.length !== action.payload.count) {
                        state.workflowVariablesByAccountAndWorkflow[listId].count = action.payload.count;
                        state.workflowVariablesByAccountAndWorkflow[listId].results = new Array(action.payload.count);
                    }
                    state.workflowVariablesByAccountAndWorkflow[listId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.unordered = [
                    ...state.unordered.filter((w) => action.payload.results.find((res) => res.id == w.id) == undefined),
                    ...action.payload.results
                ];
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchUnknownAccountWorkflowVariables.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchUnknownAccountWorkflowVariables.fulfilled, (state, action) => {
                const listId = action.meta.arg.accountId + ':' + action.meta.arg.workflowId;
                if (state.unknownWorkflowVariablesByAccountAndWorkflow == null) state.unknownWorkflowVariablesByAccountAndWorkflow = {};
                state.unknownWorkflowVariablesByAccountAndWorkflow[listId] = action.payload.results;
                state.unordered = [
                    ...state.unordered.filter((w) => action.payload.results.find((res) => res.id == w.id) == undefined),
                    ...action.payload.results
                ];
                state.status = SliceStatus.IDLE;
            });
    }
});

export const fetchAccountWorkflowVariables = createAsyncThunk<
    WorkflowVariableListResponse,
    { accountId: string; workflowId: string; queryParams: ApiQueryParams<DefaultQueryParams> }
>(
    'fetchAccountWorkflowVariables',
    async (params: { accountId: string; workflowId: string; queryParams: ApiQueryParams<DefaultQueryParams> }, { rejectWithValue }) => {
        try {
            return await WorkflowVariableClient.fetchAccountWorkflowVariables(params.accountId, params.workflowId, params.queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchUnknownAccountWorkflowVariables = createAsyncThunk<
    WorkflowVariableListResponse,
    { accountId: string; workflowId: string; queryParams: ApiQueryParams<DefaultQueryParams> }
>(
    'fetchUnknownAccountWorkflowVariables',
    async (params: { accountId: string; workflowId: string; queryParams: ApiQueryParams<DefaultQueryParams> }, { rejectWithValue }) => {
        try {
            return await WorkflowVariableClient.fetchUnknownAccountWorkflowVariables(params.accountId, params.workflowId, params.queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const workflowVariablesStore = { workflowVariables: workflowVariableSlice.reducer };
