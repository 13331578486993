import { WorkflowAction, WorkflowActionName } from "@frontend/workflow/types";
import React from "react";
import { FormattedMessage } from "react-intl";

export enum ProductActionName {
    PRODUCT_CHANGE_STATE = 'product_change_state',
    PRODUCT_UPDATE_PARAMETER = 'product_update_parameter',
    PRODUCT_VERIFY_PARAMETER = 'product_verify_parameter'
}

export class ProductAction extends WorkflowAction {
    static readonly PRODUCT_CHANGE_STATE = new ProductAction(
        ProductActionName.PRODUCT_CHANGE_STATE,
        (
            <FormattedMessage
                id='ProductAction.product_change_state'
                description='The displayed value for the ProductAction product_change_state'
                defaultMessage='Change state'
            />
        )
    );

    static readonly PRODUCT_UPDATE_PARAMETER = new ProductAction(
        ProductActionName.PRODUCT_UPDATE_PARAMETER,
        (
            <FormattedMessage
                id='ProductAction.product_update_parameter'
                description='The displayed value for the ProductAction product_update_parameter'
                defaultMessage='Update parameter'
            />
        )
    );

    static readonly PRODUCT_VERIFY_PARAMETER = new ProductAction(
        ProductActionName.PRODUCT_VERIFY_PARAMETER,
        (
            <FormattedMessage
                id='ProductAction.product_verify_parameter'
                description='The displayed value for the ProductAction product_verify_parameter'
                defaultMessage='Verify parameter'
            />
        )
    );

    static override readonly ALL = [
        ProductAction.PRODUCT_CHANGE_STATE,
        ProductAction.PRODUCT_UPDATE_PARAMETER,
        ProductAction.PRODUCT_VERIFY_PARAMETER,
        ...super.ALL
    ];

    static override getByValue(action: ProductActionName | WorkflowActionName): WorkflowAction | undefined {
        return this.ALL.find((a) => a.value == action);
    }
}