import { WorkflowAction, WorkflowActionName } from "@frontend/workflow/types";
import React from "react";
import { FormattedMessage } from "react-intl";

export enum DeviceActionName {
    DEVICE_CHANGE_STATE = 'device_change_state',
    DEVICE_ACKNOWLEDGE = 'device_acknowledge',
    DEVICE_ACKNOWLEDGE_SLOT_OPENED = 'device_acknowledge_slot_opened',
    DEVICE_ACKNOWLEDGE_SLOT_CLOSED = 'device_acknowledge_slot_closed'
}

export class DeviceAction extends WorkflowAction {
    static readonly DEVICE_CHANGE_STATE = new DeviceAction(
        DeviceActionName.DEVICE_CHANGE_STATE,
        (
            <FormattedMessage
                id='DeviceAction.change_state'
                description='The displayed value for the DeviceAction change_state'
                defaultMessage='Change state'
            />
        )
    );

    static readonly DEVICE_ACKNOWLEDGE = new DeviceAction(
        DeviceActionName.DEVICE_ACKNOWLEDGE,
        (
            <FormattedMessage
                id='DeviceAction.acknowledge'
                description='The displayed value for the DeviceAction acknowledge'
                defaultMessage='Acknowledge'
            />
        )
    );

    static readonly DEVICE_ACKNOWLEDGE_SLOT_OPENED = new DeviceAction(
        DeviceActionName.DEVICE_ACKNOWLEDGE_SLOT_OPENED,
        (
            <FormattedMessage
                id='DeviceAction.acknowledge_slot_opened'
                description='The displayed value for the DeviceAction acknowledge_slot_opened'
                defaultMessage='Acknowledge slot opened'
            />
        )
    );

    static readonly DEVICE_ACKNOWLEDGE_SLOT_CLOSED = new DeviceAction(
        DeviceActionName.DEVICE_ACKNOWLEDGE_SLOT_CLOSED,
        (
            <FormattedMessage
                id='DeviceAction.acknowledge_slot_closed'
                description='The displayed value for the DeviceAction acknowledge_slot_closed'
                defaultMessage='Acknowledge slot closed'
            />
        )
    );

    static override readonly ALL: DeviceAction[] = [
        DeviceAction.DEVICE_CHANGE_STATE,
        DeviceAction.DEVICE_ACKNOWLEDGE,
        DeviceAction.DEVICE_ACKNOWLEDGE_SLOT_OPENED,
        DeviceAction.DEVICE_ACKNOWLEDGE_SLOT_CLOSED,
        ...super.ALL
    ];

    static override getByValue(action: DeviceActionName | WorkflowActionName): WorkflowAction | undefined {
        return this.ALL.find(a => a.value === action);
    }
}