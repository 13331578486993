import { UserInterfaceButton } from '@frontend/user-interface-button/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

import useUserInterfaceButtonElement from './button-element.controller';

const ID = 'user-interface-button-element';
export interface UserInterfaceButtonElementProps {
    dispatch: ThunkDispatch<any, any, Action>;
    button: UserInterfaceButton;
}

const UserInterfaceButtonElement = (props: UserInterfaceButtonElementProps) => {
    const viewProps = useUserInterfaceButtonElement(props);

    if (viewProps.icon == null) return <></>;
    return (
        <div
            id={ID}
            className='w-md-50 w-xl-25 ratio ratio-1x1'>
            <div className='p-3'>
                <div
                    className={`card shadow-lg overflow-hidden h-100`}
                    onClick={viewProps.onClick}>
                    <div className='d-flex flex-column h-100'>
                        <div className='d-flex align-items-center justify-content-center h-75 placeholder-glow'>
                            <viewProps.icon
                                size={190}
                                className='text-primary'
                            />
                        </div>
                        <div className='d-flex align-items-center justify-content-center h-25 bg-primary'>
                            <h4 className='text-white placeholder-glow'>
                                <strong>{props.button.name}</strong>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserInterfaceButtonElement;
