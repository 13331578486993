import { StringPlaceholder } from '@frontend/elements';
import React from 'react';

import { UserInterfaceTemplateProps } from '../../user-interface-template-props';
import useSlotOpened from './slot-opened.controller';

const SlotOpened = (props: UserInterfaceTemplateProps) => {
    const viewProps = useSlotOpened(props);

    return (
        <div
            id='slot-opened'
            className='d-flex flex-column align-items-center justify-content-center m-3'>
            <h3 className='pb-4'>
                SLOT{' '}
                <StringPlaceholder
                    loaded={!!viewProps.slot}
                    className='col-2 bg-info'>
                    <span className='text-info'>{viewProps.slot?.number}</span>
                </StringPlaceholder>{' '}
                is open, please close it after pickup.
            </h3>
        </div>
    );
};

export default SlotOpened;
