export interface LabeledValue {
    label: any;
    value: any;
}

/**
 * Replaces all {value, label} objects by returning the value
 *
 * @param source - The object you want to parse.
 * @returns A new object without {value, label} objects.
 */
export function parseObject(source: any): any {
    if (Array.isArray(source)) {
        return source.map((item) => (typeof item === 'object' && item !== null ? ('label' in item && 'value' in item ? item.value : parseObject(item)) : item));
    } else if (typeof source === 'object' && source !== null) {
        return Object.fromEntries(
            Object.entries(source)
                .filter(([key]) => Object.prototype.hasOwnProperty.call(source, key))
                .map(([key, value]) => [
                    key,
                    typeof value === 'object' && value !== null ? ('label' in value && 'value' in value ? value.value : parseObject(value)) : value
                ])
        );
    } else {
        return source;
    }
}

/**
 * Replaces all {value, label} objects by returning the value
 *
 * @param obj - The object you want to parse.
 * @returns A new object without null values.
 */
export function removeNullValues(obj: { [key: string]: any }): { [key: string]: any } {
    const newObj: { [key: string]: any } = {};
    for (const key in obj) {
        if (obj[key] !== null) {
            newObj[key] = obj[key];
        }
    }
    return newObj;
}

/**
 * Removes all keys from an object except for the specified ones.
 *
 * @param obj - The object to filter.
 * @param keepKeys - An array of keys to keep in the resulting object.
 * @returns A new object containing only the specified keys and their values.
 */
export function removeExcept<T extends Record<string, unknown>>(obj: T, keepKeys: string[]): Partial<T> {
    const filteredObj: Partial<T> = {};
    for (const key in obj) {
        if (keepKeys.includes(key)) {
            filteredObj[key] = obj[key];
        }
    }
    return filteredObj;
}

export function recursiveSearch<T extends { name: string; children?: T[] }>(searchString: string, data: T[]): T[] {
    const results: T[] = [];

    const search = (items: T[]) => {
        for (const item of items) {
            if (item.name.toLowerCase().includes(searchString.toLowerCase())) {
                results.push(item);
            } else if (item.children) {
                search(item.children);
            }
        }
    };

    search(data);
    return results;
}
