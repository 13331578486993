import { AsyncSelectInputSingle } from '@frontend/basic-forms';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

import { DefaultModal, ModalType } from '@frontend/elements';
import { CreateUserInterfaceForm } from '../create-user-interface-form/create-user-interface-form.component';
import useUserInterfaceSelect from './user-interface-select.controller';


const ID = 'user-interface-select';
export interface UserInterfaceSelectProps {
    id?: string;
    label?: string;
    helpMessage?: React.ReactNode;
    required?: boolean;
    submitted: boolean;
    onChange: (newValue: string | null) => void;
    value?: string | null;
    className?: string;
    disabled?: boolean;
    useConditionedStyling?: boolean;
    isClearable?: boolean;

    dispatch: ThunkDispatch<any, any, Action>;
    createEnabled?: boolean;
}

export const UserInterfaceSelect = (props: UserInterfaceSelectProps) => {
    const viewProps = useUserInterfaceSelect(props);
    return (
        <>
            <AsyncSelectInputSingle
                id={props.id || ID}
                label={props.label || 'User interface'}
                helpMessage={props.helpMessage}
                className={props.className}
                submitted={props.submitted}
                required={props.required}
                options={viewProps.options}
                onChange={(newValue) => props.onChange(newValue?.value || null)}
                value={viewProps.value?.value}
                isDisabled={props.disabled}
                onMenuScrollToBottom={viewProps.onScrollToBottom}
                loadOptions={viewProps.search}
                isClearable={props.isClearable}
                useConditionedStyling={props.useConditionedStyling}
                onCreate={props.createEnabled ? (e) => {
                    e?.preventDefault();
                    viewProps.changeShowCreateModal(true);
                } : undefined}
            />
            <DefaultModal
                show={viewProps.showCreateModal}
                handleClose={() => viewProps.changeShowCreateModal(false)}
                type={ModalType.PROMPT}>
                <CreateUserInterfaceForm
                    onClose={() => viewProps.changeShowCreateModal(false)}
                    dispatch={props.dispatch}
                    callback={(contact) => props.onChange(contact.id)}
                />
            </DefaultModal>
        </>
    );
};
