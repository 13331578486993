import { ClassType, EntityType } from '@frontend/common';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import { IconType } from 'react-icons';
import { IoMdKey } from 'react-icons/io';
import { VscJson } from 'react-icons/vsc';
import { Row } from 'react-table';

import useDropdownButtonGroup from './dropdown-button-group.controller';

interface Button {
    text?: React.ReactNode;
    icon?: IconType;
    iconSize?: number;
    iconColor?: ClassType;
    onClick?: () => void;
    disabled?: boolean;
    className?: string;
}

export interface DropdownButtonGroupProps {
    buttons?: Button[];
    showJSON?: Row<any>;
    showRoles?: { entityId: string; entityType: EntityType };
    dispatch?: ThunkDispatch<any, any, Action>;
}

export const DropdownButtonGroup = (props: DropdownButtonGroupProps) => {
    const viewProps = useDropdownButtonGroup(props);

    return (
        <>
            {props.buttons?.map((button, index) => (
                <li
                    className='dropdown-item'
                    onClick={button.onClick}
                    key={`${button.text}-${index}`}>
                    {button.icon && (
                        <button.icon
                            size={button.iconSize || 16}
                            className={`text-${button.iconColor || 'primary'} ${button.className || 'me-2'}`}
                        />
                    )}
                    <small>{button.text}</small>
                </li>
            ))}
            {props.showJSON && (
                <li
                    className='dropdown-item'
                    onClick={viewProps.onClick}>
                    <VscJson
                        size={16}
                        className='me-2'
                    />
                    <small>View JSON</small>
                </li>
            )}
            {props.showRoles && (
                <div
                    className='dropdown-item'
                    onClick={viewProps.fetchRoles}>
                    <IoMdKey
                        size={18}
                        className='me-2'
                    />
                    <small>Roles</small>
                </div>
            )}
        </>
    );
};
