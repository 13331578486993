import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

import { ContactClient } from '@frontend/contact/api';
import { Contact, ContactListResponse } from '@frontend/contact/types';

export interface ContactState {
    unordered: Contact[];
    contacts: ContactListResponse | null;
    status: SliceStatus;
}

const initialState: ContactState = {
    unordered: [],
    contacts: null,
    status: SliceStatus.INIT
};

const contactSlice = createSlice({
    name: 'contacts',
    initialState,
    reducers: {
        seedContacts(state, action: PayloadAction<Contact[]>) {
            state.unordered = [...state.unordered.filter((contact) => action.payload.find((c) => c.id == contact.id) == undefined), ...action.payload];
        },
        updateContact(state, action: PayloadAction<Contact>) {
            state.unordered = state.unordered.map((contact) => (contact.id == action.payload.id ? action.payload : contact));
            if (state.contacts) {
                state.contacts.results = state.contacts.results.map((contact) => (contact.id == action.payload.id ? action.payload : contact));
            }
        },
        addContact(state, action: PayloadAction<Contact>) {
            state.unordered.push(action.payload);
            if (state.contacts) {
                state.contacts.count++;
                state.contacts.results = [action.payload].concat(state.contacts.results);
            }
        },
        removeContact(state, action: PayloadAction<string>) {
            state.unordered = state.unordered.filter((contact) => contact.id != action.payload);
            if (state.contacts) {
                state.contacts.count--;
                state.contacts.results = state.contacts.results.filter((contact) => contact.id != action.payload);
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchContacts.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchContacts.fulfilled, (state, action) => {
                const startPos = toNumber(action.meta.arg.size) * toNumber(action.meta.arg.index);
                if (state.contacts == null) {
                    state.contacts = {
                        ...action.payload,
                        results: new Array(action.payload.count)
                    };
                    state.contacts.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.contacts.results.length !== action.payload.count) {
                        state.contacts.count = action.payload.count;
                        state.contacts.results = new Array(action.payload.count);
                    }
                    state.contacts.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.unordered = [
                    ...state.unordered.filter((contact) => action.payload.results.find((c) => c.id == contact.id) == undefined),
                    ...action.payload.results
                ];
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchContact.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchContact.fulfilled, (state, action) => {
                if (state.contacts) {
                    const found = state.contacts.results.find((c) => c.id == action.payload.id);
                    if (found) {
                        const index = state.contacts.results.indexOf(found);
                        if (index !== -1) {
                            state.contacts.results.splice(index, 1, action.payload);
                        } else state.contacts.results.push(action.payload);
                    }
                }
                state.unordered = [...state.unordered.filter((contact) => action.payload.id !== contact.id), action.payload];
            })
            .addCase(deleteContact.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(deleteContact.fulfilled, (state, action) => {
                const contactId = action.meta.arg;
                if (state.contacts) {
                    state.contacts.results = [...state.contacts.results.filter((contact) => contact.id !== contact.id)];
                }
                state.unordered = [...state.unordered.filter((contact) => contactId !== contact.id)];
            })
            .addCase(deleteContacts.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(deleteContacts.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                state.unordered = state.unordered.filter((u) => !action.meta.arg.includes(u.id));
                if (state.contacts == null) return;
                state.contacts.count = state.contacts.count - action.meta.arg.length;
                state.contacts.results = state.contacts.results.filter((c) => !action.meta.arg.includes(c.id));
            });
    }
});

export const fetchContacts = createAsyncThunk<ContactListResponse, ApiQueryParams<DefaultQueryParams>>(
    'fetchContacts',
    async (queryParams: ApiQueryParams<DefaultQueryParams>, { rejectWithValue }) => {
        try {
            return await ContactClient.fetchContacts(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchContact = createAsyncThunk<Contact, string>('fetchContact', async (contactId: string, { rejectWithValue }) => {
    try {
        return await ContactClient.fetchContact(contactId);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const deleteContact = createAsyncThunk<void, string>('deleteContact', async (contactId: string, { rejectWithValue }) => {
    try {
        return await ContactClient.deleteContact(contactId);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const deleteContacts = createAsyncThunk<void, string[]>('deleteContacts', async (contactIds: string[], { rejectWithValue }) => {
    try {
        return await ContactClient.deleteContacts(contactIds);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const contactStore = { contacts: contactSlice.reducer };
export const { seedContacts, updateContact, addContact, removeContact } = contactSlice.actions;
