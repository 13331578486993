import React from 'react';
import { FormattedMessage } from 'react-intl';

const ID = 'warehouse-pick-drop-finished';
const WarehousePickDropFinished = () => {
    return (
        <div id={ID}>
            <h4 className='text-center'>
                <FormattedMessage
                    id='Terminal.WarehousePickDrop.Finished.message'
                    description='Message displayed when the user has checked out their items and the transaction is being processed.'
                    defaultMessage='Your stock movements are being processed.'
                />
            </h4>
            <h4 className='text-center'>
                <FormattedMessage
                    id='Terminal.WarehousePickDrop.Finished.Telloport.Thank.You'
                    description='Telloport thank you message.'
                    defaultMessage='Thank you for using Telloport.'
                />
            </h4>
        </div>
    );
};

export default WarehousePickDropFinished;
