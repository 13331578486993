import { Filter, Spinner } from '@frontend/elements';
import { ENTITY_ICONS } from '@frontend/icons';
import { CommonMessage } from '@frontend/lang';
import { Package } from '@frontend/package/types';
import { ProductCategory } from '@frontend/product-category/types';
import { Transaction } from '@frontend/transaction/types';
import { UserInterfaceImplementation, WarehouseProductPickDropData } from '@frontend/user-interface/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import { TbChecklist } from 'react-icons/tb';
import { FormattedMessage } from 'react-intl';

import DefaultTitle from '../../../default-title/default-title.component';
import ProductDetail from '../product-detail/product-detail.component';
import useBasketEditor from './basket-editor.controller';

const ID = 'basket-editor';
export interface BasketEditorProps {
    dispatch: ThunkDispatch<any, any, Action>;
    transaction: Transaction | null;
    categories?: ProductCategory[];
    onBack?: (id?: string) => void;
    packages: Package[];
    changePackages: React.Dispatch<React.SetStateAction<Package[]>>;
    toCheckout: () => void;
    createTransaction: () => Promise<Transaction>;
    userInterface: UserInterfaceImplementation<WarehouseProductPickDropData>;
}

const BasketEditor = (props: BasketEditorProps) => {
    const viewProps = useBasketEditor(props);

    if (viewProps.category)
        return (
            <BasketEditor
                dispatch={props.dispatch}
                userInterface={props.userInterface}
                categories={props.categories ? [...props.categories, viewProps.category] : [viewProps.category]}
                onBack={viewProps.onBack}
                packages={props.packages}
                createTransaction={props.createTransaction}
                changePackages={props.changePackages}
                transaction={props.transaction}
                toCheckout={props.toCheckout}
            />
        );

    return (
        <div
            id={ID}
            className='d-flex flex-column justify-content-between p-1 fs-5'>
            <DefaultTitle dispatch={props.dispatch} />
            <div className='overflow-scroll'>
                {props.categories && props.categories.length >= 1 && (
                    <h4 className='d-flex flex-row justify-content-start align-items-center'>
                        <button
                            className='btn btn-light mb-0 p-2'
                            onClick={() => props.onBack && props.onBack()}>
                            <FaChevronLeft className='m-0' />
                        </button>
                        {props.categories.map((category) => {
                            return (
                                <span
                                    className='ms-2'
                                    onClick={() => viewProps.onBack(category.id)}
                                    key={category.id}>
                                    / <span className='badge bg-primary'>{category.name}</span>
                                </span>
                            );
                        })}
                    </h4>
                )}

                <Filter filterValue={viewProps.changeTextFilter} />
                {viewProps.categories && viewProps.products ? (
                    <ul className={`list-group ${props.packages.length > 0 && 'mb-6'}`}>
                        {viewProps.categorieCount !== 0 && (
                            <>
                                <li
                                    className='list-group-item list-group-item-primary'
                                    key='Categories'>
                                    <span className='d-flex flex-row justify-content-start'>{CommonMessage.OBJECTS.CATEGORY.PLURAL}</span>
                                </li>
                                {viewProps.categories.map((category) => {
                                    return (
                                        <li
                                            className='list-group-item'
                                            key={category.id}
                                            onClick={() => viewProps.changeCategory(category)}>
                                            {category.name}
                                        </li>
                                    );
                                })}
                            </>
                        )}

                        <li
                            className='list-group-item list-group-item-primary'
                            key='Products'>
                            <span className='d-flex flex-row justify-content-between'>
                                <span className='d-flex flex-row align-items-center'>
                                    <ENTITY_ICONS.PRODUCT className='me-2' />
                                    {CommonMessage.OBJECTS.PRODUCTS.PLURAL}
                                </span>
                                {/* <span className='badge bg-primary rounded-pill'>
                                    {props.packages.map((p) => p.quantity).reduce((q, current) => current + q, 0)}
                                </span> */}
                            </span>
                        </li>
                        {viewProps.products.map((product) => {
                            let total = 0;
                            props.packages.filter((p) => p.product_id === product.id).forEach((package_) => (total += package_.quantity));
                            return (
                                <li
                                    className='list-group-item'
                                    key={product.id}
                                    onClick={() => viewProps.changeProduct(product)}>
                                    <span className='d-flex flex-row justify-content-between'>
                                        <span className='d-flex flex-row align-items-center'>
                                            <ENTITY_ICONS.PRODUCT className='me-2' />
                                            {product.name || product.code}
                                        </span>
                                        {/* <span className='badge bg-primary rounded-pill'>{total}</span> */}
                                    </span>
                                </li>
                            );
                        })}
                        {viewProps.productCount !== null && viewProps.products.length < viewProps.productCount && (
                            <li
                                className='list-group-item list-group-item-secondary'
                                key='more'
                                onClick={viewProps.changePage}>
                                <span className='d-flex flex-row justify-content-center'>{CommonMessage.LISTS.LOAD_MORE}</span>
                            </li>
                        )}
                    </ul>
                ) : (
                    <Spinner />
                )}
            </div>
            {props.packages.length > 0 && (
                <div className='pe-none fixed-bottom d-flex justify-content-end'>
                    <div
                        id={ID}
                        className={` align-self-end w-35 ratio ratio-1x1`}>
                        <div className='p-3'>
                            <div
                                className='card shadow-lg overflow-hidden h-100 pe-auto'
                                onClick={props.toCheckout}>
                                <div className='d-flex flex-column h-100'>
                                    <div className='d-flex align-items-center justify-content-center h-75 placeholder-glow'>
                                        <TbChecklist
                                            size={170}
                                            className='text-primary'
                                        />
                                    </div>
                                    <div className='d-flex align-items-center justify-content-center h-25 bg-primary'>
                                        <h4 className='text-white placeholder-glow'>
                                            <strong>
                                                <FormattedMessage
                                                    id='warehouse-product-pick-drop.checkout.button'
                                                    description='Button on the warehouse pick and drop wf allowing the user to checkout their basket.'
                                                    defaultMessage='Proceed to checkout'
                                                />
                                            </strong>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {viewProps.product && (
                <ProductDetail
                    userInterface={props.userInterface}
                    transaction={props.transaction}
                    packages={props.packages}
                    product={viewProps.product}
                    createTransaction={props.createTransaction}
                    onClose={() => viewProps.changeProduct(null)}
                    changePackages={props.changePackages}
                />
            )}
        </div>
    );
};

export default BasketEditor;
