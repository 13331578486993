import { AccountSelect } from '@frontend/account/select';
import { EmailInput, TextInput } from '@frontend/basic-forms';
import { ClassType } from '@frontend/common';
import { ModalFooter, TitleSelect } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';
import { WorkflowSelect } from '@frontend/workflow/common';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import { FaPlus } from 'react-icons/fa';

import useCreateUserForm from './create-user.controller';

const ID = 'create-user-form';

export interface CreateUserFormProps {
    onClose: () => void;
    dispatch: ThunkDispatch<any, any, Action>;
}

export const CreateUserForm = (props: CreateUserFormProps) => {
    const viewProps = useCreateUserForm(props);

    return (
        <div id={ID}>
            <form onSubmit={viewProps.onSubmit}>
                <div className='modal-header'>
                    <h4>{CommonMessage.FORMS.USER.CREATE}</h4>
                </div>
                <div className='modal-body'>
                    <div className='d-flex flex-row'>
                        <AccountSelect
                            submitted={viewProps.submitted}
                            value={viewProps.account}
                            onChange={viewProps.changeAccount}
                            className='w-50 m-1'
                            required
                        />
                        <WorkflowSelect
                            dispatch={props.dispatch}
                            submitted={viewProps.submitted}
                            value={viewProps.formValues.workflow_id}
                            onChange={(v) => viewProps.changeFormValues({ ...viewProps.formValues, workflow_id: v })}
                            className='w-50 m-1'
                            required
                            queryParams={{ account_id: viewProps.account, type: 'user' }}
                            disabled={!viewProps.account}
                        />
                    </div>
                    <div className='d-flex flex-row'>
                        <TextInput
                            id={ID + '-first-nameInput'}
                            className='w-50 m-1'
                            label={CommonMessage.FORMS.FIRST_NAME}
                            value={viewProps.formValues.first_name}
                            onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, first_name: value })}
                            isValidCallback={(valid) => {
                                viewProps.changeFormValid({ ...viewProps.formValid, first_name: valid });
                            }}
                            submitted={viewProps.submitted}
                            required
                        />

                        <TextInput
                            id={ID + '-last-nameInput'}
                            className='w-50 m-1'
                            label={CommonMessage.FORMS.LAST_NAME}
                            value={viewProps.formValues.last_name}
                            onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, last_name: value })}
                            isValidCallback={(valid) => {
                                viewProps.changeFormValid({ ...viewProps.formValid, last_name: valid });
                            }}
                            submitted={viewProps.submitted}
                            required
                        />
                    </div>

                    <div className='d-flex flex-row'>
                        <EmailInput
                            id={ID + '-emailInput'}
                            className='w-50 m-1'
                            label={CommonMessage.FORMS.EMAIL()}
                            value={viewProps.formValues.email}
                            onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, email: value })}
                            isValidCallback={(valid) => {
                                viewProps.changeFormValid({ ...viewProps.formValid, email: valid });
                            }}
                            submitted={viewProps.submitted}
                            required
                        />
                        <TitleSelect
                            className='w-50 m-1'
                            label={CommonMessage.OBJECTS.USER.TITLE}
                            value={viewProps.formValues.title}
                            onChange={(value: any) => viewProps.changeFormValues({ ...viewProps.formValues, title: value })}
                            submitted={viewProps.submitted}
                        />
                    </div>
                </div>
                <ModalFooter
                    justifyContent='between'
                    buttons={[
                        {
                            hide: false,
                            text: CommonMessage.BUTTONS.CANCEL,
                            type: ClassType.SECONDARY,
                            onClick: (e) => {
                                e?.preventDefault();
                                props.onClose();
                            }
                        },
                        {
                            hide: false,
                            text: CommonMessage.BUTTONS.SUBMIT,
                            type: ClassType.SUCCESS,
                            icon: FaPlus,
                            buttonType: 'submit',
                            disabled: Object.values(viewProps.formValid).some((a) => !a) || !viewProps.account
                        }
                    ]}
                />
            </form>
        </div>
    );
};

export default CreateUserForm;
