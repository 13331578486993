import { APIClient, ApiQueryParams, DefaultQueryParams, DefaultSearchQueryParams } from '@frontend/api-utils';

import { CreateDriver, Driver, DriverListResponse, DriverQueryParams, UpdateDriver } from '@frontend/edge/types';

const endpoint = '/edge-api/v1';
export class DriverClient extends APIClient {
    public static async fetchDrivers(queryParams?: ApiQueryParams<DefaultQueryParams | DriverQueryParams>): Promise<DriverListResponse> {
        return await this.apiPaginated<DriverListResponse, DefaultQueryParams | DriverQueryParams>(`${endpoint}/drivers`, queryParams);
    }

    public static async postDriver(body: CreateDriver): Promise<Driver> {
        const response = await this.post(`${endpoint}/drivers`, body);
        return this.handleResponse<Driver>(response);
    }

    public static async searchDrivers(queryParams?: ApiQueryParams<DefaultQueryParams | DefaultSearchQueryParams>): Promise<DriverListResponse> {
        return await this.apiPaginated<DriverListResponse, DefaultQueryParams | DefaultSearchQueryParams>(`${endpoint}/drivers-search`, queryParams);
    }

    public static async resolveDrivers(driverIds: string[]): Promise<DriverListResponse> {
        const response = await this.post(`${endpoint}/drivers-resolve`, { ids: driverIds });
        return await this.handleResponse<DriverListResponse>(response);
    }

    public static async deleteDrivers(driverIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/drivers-delete`, { ids: driverIds });
        return await this.handleVoidResponse(response);
    }

    public static async fetchDriver(driverId: string): Promise<Driver> {
        const response = await this.fetch(`${endpoint}/drivers/${driverId}`);
        return await this.handleResponse<Driver>(response);
    }

    public static async patchDriver(driverId: string, body: UpdateDriver): Promise<Driver> {
        const response = await this.patch(`${endpoint}/drivers/${driverId}`, body);
        return await this.handleResponse<Driver>(response);
    }

    public static async deleteDriver(driverId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/drivers/${driverId}`);
        return await this.handleVoidResponse(response);
    }
}
