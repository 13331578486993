import { removeCurrentRoles } from '@frontend/authorization';
import { EntityType } from '@frontend/common';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../redux/store';

interface ViewProps {
    isLoading: boolean;
    handleClose: () => void;
    restrictions: any;
    restrictionModalOpen: boolean;
    changeRestrictionModalOpen: Dispatch<SetStateAction<boolean>>;
    dispatch: ThunkDispatch<any, any, Action>;
    entity: { type: EntityType; id: string } | undefined;
}

const useRestrictionModal = (): ViewProps => {
    const dispatch = useAppDispatch();
    const authorizationState = useAppSelector((state) => state.authorization);
    const isLoading = useMemo(() => authorizationState.current!.restrictions == null, [authorizationState]);
    const [restrictionModalOpen, changeRestrictionModalOpen] = useState<boolean>(false);
    const handleClose = () => {
        dispatch(removeCurrentRoles());
    };

    //TODO: show what entity is being edited in the restriction modal
    return {
        isLoading,
        handleClose,
        restrictions: authorizationState.current!.restrictions,
        changeRestrictionModalOpen,
        dispatch,
        restrictionModalOpen,
        entity: authorizationState.current ? { type: authorizationState.current.type, id: authorizationState.current.id } : undefined
    };
};

export default useRestrictionModal;
