import { useEffect, useState } from 'react';

import { HorizontalNavbarProps, NavItem } from './horizontal-navbar.component';

interface ViewProps {
    active: string;
    changeActive: React.Dispatch<React.SetStateAction<string>>;
    activeNavbars: NavItem[];
}

const useHorizontalNavbar = (props: HorizontalNavbarProps): ViewProps => {
    const activeNavbars = props.navItems.filter((item) => !item.hide);
    const [active, changeActive] = useState<string>(props.active?.toLocaleLowerCase() ?? activeNavbars.filter((n) => n.hide !== true)[0]?.title ?? '');

    useEffect(() => {
        props.onChange(active.toLowerCase());
    }, [active]);

    return {
        active,
        changeActive,
        activeNavbars
    };
};

export default useHorizontalNavbar;
