import { DateBadge } from '@frontend/elements';
import { NotificationTemplateBadge } from '@frontend/notification-service/common';
import { NotificationTemplate } from '@frontend/notification-service/types';
import { RowAnchor, RowProps } from '@frontend/table';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React, { lazy } from 'react';
import { Row } from 'react-table';


const AccountBadge = lazy(() => import('@frontend/account/common').then((module) => ({ default: module.AccountBadge })));

interface NotificationTemplateListElementProps extends RowProps {
    selectEnabled?: boolean;
    row: Row<NotificationTemplate>;
    onClick?: (element: NotificationTemplate) => void;
    dispatch: ThunkDispatch<any, any, Action>;
}

const NotificationTemplateListElement = (props: NotificationTemplateListElementProps) => {
    return (
        <tr
            id='NotificationTemplate-Row'
            onClick={() => props.onClick && props.onClick(props.row.original)}
            className={'' + (props.onClick && 'cursor-pointer')}>
            {props.selectEnabled && (
                <td
                    onClick={(e) => e.stopPropagation()}
                    {...props.row.cells[0].getCellProps()}>
                    {props.row.cells[0].render('Cell')}
                </td>
            )}
            <td className='align-content-center'>
                <NotificationTemplateBadge
                    id={props.row.original.id}
                    name={props.row.original.name}
                    accountId={props.row.original.account_id}
                />
            </td>
            <td className='align-content-center'>
                <AccountBadge id={props.row.original.account_id} />
            </td>
            <td className='text-secondary text-xs font-weight-bolder align-content-center text-wrap max-width-200'>{props.row.original.description}</td>
            <td className='text-secondary text-xs font-weight-bolder align-content-center'>
                <DateBadge
                    date={props.row.original.creation_timestamp}
                    displayTime
                />
            </td>
            <td>
                <RowAnchor {...props} />
            </td>
        </tr>
    );
};

export default NotificationTemplateListElement;