import { APIClient } from "@frontend/api-utils";

const endpoint = '/transaction-api/v1';

export class TransactionEnumClient extends APIClient {
    public static async fetchTransactionStates(): Promise<string[]> {
        const response = await this.fetch(`${endpoint}/transaction-states`);
        return await this.handleResponse<string[]>(response);
    }

    public static async fetchTransactionTriggers(): Promise<string[]> {
        const response = await this.fetch(`${endpoint}/transaction-triggers`);
        return await this.handleResponse<string[]>(response);
    }

    public static async fetchTransactionActions(): Promise<string[]> {
        const response = await this.fetch(`${endpoint}/transaction-actions`);
        return await this.handleResponse<string[]>(response);
    }
}