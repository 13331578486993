import { WorkflowAction, WorkflowActionName } from "@frontend/workflow/types";
import React from "react";
import { FormattedMessage } from "react-intl";

export enum UserActionName {
    USER_CHANGE_STATE = 'user_change_state'
}

export class UserAction extends WorkflowAction {
    static readonly USER_CHANGE_STATE = new UserAction(
        UserActionName.USER_CHANGE_STATE,
        (
            <FormattedMessage
                id='UserAction.change_state'
                description='The displayed value for the UserAction change_state'
                defaultMessage='Change state'
            />
        )
    );
    
    static override readonly ALL = [
        UserAction.USER_CHANGE_STATE,
        ...super.ALL
    ];

    static override getByValue(action: UserActionName | WorkflowActionName): WorkflowAction | undefined {
        return this.ALL.find((a) => a.value == action);
    }
}