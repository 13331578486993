import { PaginatedResponse } from '@frontend/api-utils';

export interface UserInterface {
    id: string;
    name: string;
    platform: 'mobile' | 'terminal';
    type: UserInterfaceType;
    account_id: string;
    data: any;
    update_timestamp?: string;
    creation_timestamp: string;
}

export type UserInterfaceListResponse = PaginatedResponse<UserInterface>;

export interface CreateUserInterface {
    name: string;
    platform: 'mobile' | 'terminal';
    type: UserInterfaceType;
    data: any;
}

export interface UpdateUserInterface {
    name: string | null;
    platform: 'mobile' | 'terminal' | null;
    type: UserInterfaceType | null;
    data: any;
}

export enum UserInterfaceType {
    UNKNOWN = '',
    //TERMINAL
    BUTTON_DASHBOARD = 'Button dashboard',
    ENTER_PIN = 'Enter pin',
    RETURN_PRODUCT = 'Return product',
    PRODUCT_NOT_IN_USE = 'Product not in use',
    PACKAGE_PROCESSING = 'Package processing',
    SCAN_BADGE = 'Scan badge',
    PICKUP_UI = 'Pickup UI',
    CHOOSE_ACK_SLOT = 'Choose/acknoledge slot',
    DROPOFF_UI = 'Dropoff UI',
    CREATE_TRANSACTION_UI = 'Create transaction UI',
    WAREHOUSE_PRODUCT_PICK_AND_DROP = 'Warehouse product pick and drop',
    
    //MOBILE
    NAVBAR_BUTTONS = 'Navbar buttons',
    SCAN_CODE = 'Scan code',
    AUTHENTICATION_QR = 'Authentication QR',
    PROFILE = 'Profile',
}

export const mobileInterfaceTypeInformation = new Map<UserInterfaceType, string[]>([
    [UserInterfaceType.NAVBAR_BUTTONS, ['Used as the main form of navigation on the mobile app. This will always be visible on the mobile app.', 'Will use buttons to navigate.']],
    [UserInterfaceType.SCAN_CODE, ['A screen that allows the user to scan a code.', 'This will post a scan on the linked scanning device.']],
    [UserInterfaceType.AUTHENTICATION_QR, ['A screen that shows a QR code linked to the user. Allowing the user to authenticate.']],
    [UserInterfaceType.PROFILE, ['Screen showing the user profile information and allows the user to manage it.']],
])

export const terminalInterfaceTypeInformation = new Map<UserInterfaceType, string[]>([
    [UserInterfaceType.BUTTON_DASHBOARD, ['A screen with configurable buttons, used as the main form of navigation.', 'Will use buttons to navigate.']],
    [UserInterfaceType.ENTER_PIN, ['Allows the user to enter a pin code, and if multiple pin codes are found also presents the user to a challenge.', 'Triggers PACKAGE_CODE_SCANNED when a correct pin code has been entered.']],
    [UserInterfaceType.RETURN_PRODUCT, ['Allows the user to return a product.', 'Creates a transaction, package and triggers TRANSACTION_PROCESS when submitted.']],
    [UserInterfaceType.PRODUCT_NOT_IN_USE, ['Informative screen telling the user that the product is not in use. If found it will also show the SLOT where it is located.']],
    [UserInterfaceType.PACKAGE_PROCESSING, ['Informative screen telling the user that the package is being processed. Should be used as a intermediate screen.']],
    [UserInterfaceType.SCAN_BADGE, ['Screen simply telling the user a badge needs to be scanned before any further actions can be taken.']],
    [UserInterfaceType.CREATE_TRANSACTION_UI, ['Allows users to create a new (PuDo) transaction (and fill in variables) on the terminal.', 'Creates a transaction and triggers TRANSACTION_PROCESS when submitted.']],
    [UserInterfaceType.PICKUP_UI, ['Informative screen telling the user to pick up the package.']],
    [UserInterfaceType.DROPOFF_UI, ['Informative screen telling the user to dorpoff the package.']],
    [UserInterfaceType.CHOOSE_ACK_SLOT, ['Allows users to acknowledge and/or change the SLOT if allowed.', 'Updates package state to PACKAGE_DROP_OFF_STARTED or PACKAGE_PICK_UP_STARTED (depending on the current package state) when the user confirms. And will also add and remove SLOT constraints when updating the SLOT of the package.']],
    [UserInterfaceType.WAREHOUSE_PRODUCT_PICK_AND_DROP, ['Allows the user to select one or more products.', 'Creates a transaction and one package for each product. Triggers TRANSACTION_PROCESS when submitted.']],
])