import React from 'react';

interface NameBadgeProps {
    firstName?: string | null;
    lastName?: string | null;
}

export function NameBadge(props: NameBadgeProps) {
    if (props.firstName === undefined || props.firstName === '' || props.firstName === null) return <></>;
    const url = `https://eu.ui-avatars.com/api/?background=random&bold=true&name=${props.firstName + ' ' + props.lastName}`;
    return (
        <>
            <img
                src={url}
                className='rounded me-2'
                height={20}
            />
            {props.firstName + ' ' + props.lastName}
        </>
    );
}
