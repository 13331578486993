import { Spinner, StringPlaceholder } from '@frontend/elements';
import { ChooseAckSlotData, UserInterfaceImplementation, UserInterfaceType } from '@frontend/user-interface/types';
import React from 'react';

import SpotLayoutSlotSelect from '../../spot-layout-slot-select/spot-layout-slot-select.component';
import { UserInterfaceTemplateProps } from '../../user-interface-template-props';
import useChooseAckSlot, { ChooseAckSlotState } from './choose-ack-slot.controller';

export interface ChooseAckSlotProps extends UserInterfaceTemplateProps {
    userInterface: UserInterfaceImplementation<ChooseAckSlotData>;
}

const ChooseAckSlot = (props: ChooseAckSlotProps) => {
    const viewProps = useChooseAckSlot(props);

    if (viewProps.state === ChooseAckSlotState.INIT)
        return (
            <div
                id={UserInterfaceType.CHOOSE_ACK_SLOT}
                className='d-flex flex-column justify-content-center'>
                <h3 className='pb-4 text-center'>
                    You are about to dropoff your package in SLOT{' '}
                    <StringPlaceholder
                        loaded={!!viewProps.slot}
                        className='col-2 bg-info'>
                        <span className='text-info'>{viewProps.slot?.number}</span>
                    </StringPlaceholder>{' '}
                    .
                </h3>
                <span className='d-flex flex-row w-100'>
                    <button
                        onClick={() => viewProps.changeState(ChooseAckSlotState.SUBMITTED)}
                        className='w-50 mx-1 btn btn-primary btn-lg'>
                        Open SLOT
                    </button>
                    {props.userInterface.data.allowChangeSlot && (
                        <button
                            onClick={() => viewProps.changeState(ChooseAckSlotState.CHANGE_SLOT)}
                            className='w-50 mx-1 btn btn-warning btn-lg'>
                            Change SLOT
                        </button>
                    )}
                </span>
            </div>
        );

    if (viewProps.state === ChooseAckSlotState.CHANGE_SLOT)
        return (
            <SpotLayoutSlotSelect
                dispatch={props.dispatch}
                slot={viewProps.slot}
                callback={viewProps.changeSlot}
            />
        );

    return <Spinner />;
};

export default ChooseAckSlot;
