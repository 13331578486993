import { NumberInput } from '@frontend/basic-forms';
import { WorkflowVariable } from '@frontend/workflow/types';
import React from 'react';

import { TransactionVariable } from '../../../../transaction-variables';

const ID = 'number-field-input';
export interface NumberFieldInputProps {
    submitted: boolean;
    variable: TransactionVariable | WorkflowVariable;
    onChange: (variable: TransactionVariable | WorkflowVariable) => void;
}

const NumberFieldInput = (props: NumberFieldInputProps) => {
    return (
        <NumberInput
            id={ID}
            label={props.variable.name}
            submitted={props.submitted}
            value={typeof props.variable.value === 'number' ? props.variable.value : undefined}
            onChange={(value) => {
                props.onChange({ ...props.variable, value: value });
            }}
        />
    );
};

export default NumberFieldInput;
