import { Terminal } from '@frontend/terminal-service';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

import { DefaultModal, DefaultModalProps, ModalType } from '../../../modals/default-modal/default-modal.component';
import { ModalHeader } from '../../../modals/modal-header/modal-header.component';
import { TerminalDetail } from '../terminal-detail.component';
import { useTerminalDetailModal } from './terminal-detail-modal.controller';

const ID = 'terminal-detail-modal';

export interface TerminalDetailModalProps extends DefaultModalProps {
    terminal: Terminal;
    dispatch: ThunkDispatch<any, any, AnyAction>;
}

const TerminalDetailModal = (props: TerminalDetailModalProps) => {
    const viewProps = useTerminalDetailModal(props);
    return (
        <DefaultModal
            type={ModalType.PANE}
            id={ID}
            {...props}>
            <ModalHeader
                id={ID + '-header'}
                handleClose={props.handleClose}
                title={props.terminal.identifier}
                handleView={viewProps.viewDetails}
            />
            <div className='modal-body'>
                <TerminalDetail terminal={props.terminal} />
            </div>
        </DefaultModal>
    );
};

export default TerminalDetailModal;
