import { useEffect, useRef, useState } from 'react';

import { DropZoneProps } from './drop-zone.component';

interface ViewProps {
    dropZoneRef: React.RefObject<HTMLDivElement>;
    isDropping: boolean;
}

const useDropZone = (props: DropZoneProps): ViewProps => {
    const dropZoneRef = useRef<HTMLDivElement>(null);
    const [isDropping, changeIsDropping] = useState<boolean>(false);

    useEffect(() => {
        if (!dropZoneRef) return;
        ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
            dropZoneRef.current?.addEventListener(eventName, preventDefaults, false);
        });
        ['dragenter', 'dragover'].forEach((eventName) => {
            dropZoneRef.current?.addEventListener(eventName, () => changeIsDropping(true), false);
        });
        ['dragleave', 'drop'].forEach((eventName) => {
            dropZoneRef.current?.addEventListener(eventName, () => changeIsDropping(false), false);
        });
        dropZoneRef.current?.addEventListener('drop', handleDrop, false);
    }, [dropZoneRef, props.file]);

    function preventDefaults(e: any) {
        e.preventDefault();
        e.stopPropagation();
    }

    function handleDrop(e: DragEvent) {
        const droppedFile = e.dataTransfer;
        if (!droppedFile) return;
        const files = droppedFile.files;
        props.onChange(files.item(0));
    }

    return {
        dropZoneRef,
        isDropping
    };
};

export default useDropZone;
