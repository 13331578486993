import React from 'react';
import { Tooltip as BootstrapToolTip, OverlayTrigger, OverlayTriggerProps } from 'react-bootstrap';

const ID = 'tooltip';
interface TooltipProps extends Omit<OverlayTriggerProps, 'overlay'> {
    text: React.ReactNode;
    children: React.ReactElement;
    id?: string;
    disabled?: boolean;
}

export const Tooltip = (props: TooltipProps) => {
    return (
        <OverlayTrigger
            overlay={<BootstrapToolTip id={ID || props.id}>{props.text}</BootstrapToolTip>}
            delay={props.delay || { show: 300, hide: 150 }}
            placement={props.placement || 'auto'}>
            {props.children}
        </OverlayTrigger>
    );
};

export default Tooltip;
