import { DetailModalType } from '@frontend/elements';
import React from 'react';

import useBadgeTemplate from './badge-template.controller';

export interface BadgeTemplateProps {
    type: DetailModalType;
    objectId?: string | null;
    children: React.ReactNode;
    disabled?: boolean;
}

const BadgeTemplate = (props: BadgeTemplateProps) => {
    const viewProps = useBadgeTemplate(props);

    return (
        <span
            id={'badge-' + props.objectId}
            className={`badge badge-secondary badge-clickable ${props.disabled && 'disabled'} m-1`}
            onClick={() => viewProps.onClick()}>
            {props.children}
        </span>
    );
};

export default BadgeTemplate;
