import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';

import { Badge, BadgeListResponse, BadgeQueryParams, CreateBadge, SearchBadgeQueryParams, UpdateBadge } from '@frontend/badge/types';

const endpoint = '/badge-api/v1';

export class BadgeClient extends APIClient {
    public static async fetchBadges(queryParams?: ApiQueryParams<BadgeQueryParams>): Promise<BadgeListResponse> {
        return await this.apiPaginated<BadgeListResponse, BadgeQueryParams>(`${endpoint}/badges`, queryParams);
    }

    public static async searchBadges(queryParams?: ApiQueryParams<DefaultQueryParams | SearchBadgeQueryParams>): Promise<BadgeListResponse> {
        return await this.apiPaginated<BadgeListResponse, DefaultQueryParams | SearchBadgeQueryParams>(`${endpoint}/badges-search`, queryParams);
    }

    public static async fetchAccountBadges(accountId: string, queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<BadgeListResponse> {
        return await this.apiPaginated<BadgeListResponse, DefaultQueryParams>(`${endpoint}/accounts/${accountId}/badges`, queryParams);
    }

    public static async postAccountBadge(accountId: string, badge: CreateBadge): Promise<Badge> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/badges`, badge);
        return await this.handleResponse<Badge>(response);
    }

    public static async fetchAccountBadge(accountId: string, badgeId: string): Promise<Badge> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/badges/${badgeId}`);
        return await this.handleResponse<Badge>(response);
    }

    public static async patchAccountBadge(accountId: string, badgeId: string, badge: UpdateBadge): Promise<Badge> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/badges/${badgeId}`, badge);
        return await this.handleResponse<Badge>(response);
    }

    public static async removeAccountBadge(accountId: string, badgeId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/badges/${badgeId}`);
        return await this.handleVoidResponse(response);
    }

    public static async resolveBadges(badgeIds: string[]): Promise<BadgeListResponse> {
        const response = await this.post(`${endpoint}/badges-resolve`, { ids: badgeIds });
        return await this.handleResponse<BadgeListResponse>(response);
    }

    public static async deleteBadges(badgeIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/badges-delete`, { ids: badgeIds });
        return await this.handleVoidResponse(response);
    }
}
