import { DetailPageType, convertLanguage, useDetailPage } from '@frontend/common';
import { DateBadge, EmailBadge, NameBadge, ObjectField, PhoneNumberBadge } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';
import React from 'react';

import { Contact } from '@frontend/contact/types';
import { UserBadge } from '@frontend/user/common';

const AccountBadge = React.lazy(() => import('@frontend/account/common').then((module) => ({ default: module.AccountBadge })));

export interface ContactDetailProps {
    contact: Contact;
}

export const ContactDetail = (props: ContactDetailProps) => {
    const viewProps = useDetailPage(DetailPageType.CONTACT);
    return (
        <>
            <ObjectField
                fieldName={CommonMessage.FORMS.ALIAS}
                value={props.contact.alias}
            />
            <ObjectField
                fieldName={CommonMessage.FORMS.NAME}
                value={
                    <NameBadge
                        firstName={props.contact.first_name}
                        lastName={props.contact.last_name}
                    />
                }
            />
            <ObjectField
                fieldName={CommonMessage.FORMS.LANGUAGE}
                value={convertLanguage(props.contact.language)}
            />
            <ObjectField
                fieldName={CommonMessage.FORMS.MOBILE}
                value={<PhoneNumberBadge phone={props.contact.mobile} />}
            />
            <ObjectField
                fieldName={CommonMessage.FORMS.EMAIL()}
                value={<EmailBadge email={props.contact.email} />}
            />
            <ObjectField
                fieldName='Type'
                value={props.contact.type}
            />
            {viewProps.isDetailPage && (
                <>
                    <ObjectField
                        fieldName='Account'
                        value={<AccountBadge id={props.contact.account_id} />}
                    />
                    <ObjectField
                        fieldName='User'
                        value={<UserBadge id={props.contact.user_id} />}
                    />
                    <ObjectField
                        fieldName={CommonMessage.OBJECTS.DEFAULT.CREATION_TIMESTAMP}
                        value={
                            <DateBadge
                                date={props.contact.creation_timestamp}
                                displayTime
                                inline
                            />
                        }
                    />
                    <ObjectField
                        fieldName={CommonMessage.OBJECTS.DEFAULT.UPDATE_TIMESTAMP}
                        value={
                            <DateBadge
                                date={props.contact.update_timestamp}
                                displayTime
                                inline
                            />
                        }
                    />
                </>
            )}
        </>
    );
};
