import { Languages } from '@frontend/common';
import { useMemo } from 'react';

import { LanguageSelectProps } from './language-select.component';

interface ViewProps {
    options: {
        value: string;
        label: string;
    }[];
    value?: { value: string; label: string } | string;
}

const useLanguageSelect = (props: LanguageSelectProps): ViewProps => {
    const options = useMemo(() => {
        return Languages.map((l) => ({ value: l.value, label: l.displayName }));
    }, []);
    const value = useMemo(() => {
        if (typeof props.value == 'string') {
            const op = options.filter((o) => o != undefined);
            return op.find((o) => o.value == props.value);
        } else return props.value;
    }, [props.value, options]);
    return {
        options,
        value
    };
};

export default useLanguageSelect;
