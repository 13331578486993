import { useAppSelector } from '@frontend/common';
import { fetchSlots, slotStore } from '@frontend/slot/redux';
import { Slot } from '@frontend/slot/types';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { navigationStore } from '../redux/user-interface-navigation-slice';
import { SelectSlotFormProps } from './select-slot-form.component';

interface ViewProps {
    slots: Slot[];
    availableSlots: Slot[];
    selectedSlot: Slot | null;
    changeSelectedSlot: Dispatch<SetStateAction<Slot | null>>;
    onSubmit: (e: any) => void;
    submitted: boolean;
}

const useSelectSlotForm = (props: SelectSlotFormProps): ViewProps => {
    const navigationState = useAppSelector(useSelector, navigationStore);
    const cache = props.cache || navigationState.cache;

    const slotState = useAppSelector(useSelector, slotStore);
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const [slots, changeSlots] = useState<Slot[]>([]);
    const [availableSlots, changeAvailableSlots] = useState<Slot[]>([]);
    const [selectedSlot, changeSelectedSlot] = useState<Slot | null>(props.slot ?? null);

    useEffect(() => {
        props.dispatch(fetchSlots({}));
    }, []);

    useEffect(() => {
        //TODO should actualy only use the available slots
        // const productFilter = cache?.product_id ? { product: { product_id: cache.product_id } } : {};
        // if (cache?.spot_id && cache?.account_id && cache?.workflow_id && cache?.transaction_id) {
        //     SlotWorkflowClient.postAvailableSlots(cache?.spot_id, {
        //         account_id: cache.account_id,
        //         workflow_id: cache.workflow_id,
        //         ...productFilter
        //     }).then((result) => {
        //         changeAvailableSlots(result.results);
        //     });
        // } else {
        //     Logger.error('Could not load available slots because of missing information.', {}, cache);
        // }
    }, [navigationState]);

    useEffect(() => {
        if (slotState.slots) {
            changeSlots(slotState.slots.results);
            changeAvailableSlots(slotState.slots.results);
        }
    }, [slotState]);

    const onSubmit = (e: any) => {
        e.preventDefault();
        changeSubmitted(true);
        props.callback(selectedSlot);
    };

    return {
        slots,
        availableSlots,
        selectedSlot,
        changeSelectedSlot,
        onSubmit,
        submitted
    };
};

export default useSelectSlotForm;
