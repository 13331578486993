import { ApiError, ApiQueryParams, DefaultQueryParams } from "@frontend/api-utils";
import { SliceStatus } from "@frontend/common";
import { WorkflowStepClient } from "@frontend/workflow-step/api";
import { WorkflowStep, WorkflowStepListResponse } from "@frontend/workflow-step/types";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toNumber } from "lodash";

interface WorkflowStepState {
    unordered: WorkflowStep[];
    workflowStepsByAccountAndWorkflow: { [accAndWorkId: string]: WorkflowStepListResponse } | null;
    status: SliceStatus;
}

const initialState: WorkflowStepState = {
    unordered: [],
    workflowStepsByAccountAndWorkflow: null,
    status: SliceStatus.INIT
};

const workflowStepSlice = createSlice({
    name: 'workflowSteps',
    initialState,
    reducers: {
        updateWorkflowStep: (state, action: PayloadAction<WorkflowStep>) => {
            state.unordered = state.unordered.map((workflowStep) => (workflowStep.id == action.payload.id ? action.payload : workflowStep));
            if (state.workflowStepsByAccountAndWorkflow != null) {
                const listId = action.payload.account_id + ':' + action.payload.workflow_id;
                if (state.workflowStepsByAccountAndWorkflow[listId] != null) {
                    state.workflowStepsByAccountAndWorkflow[listId].results = state.workflowStepsByAccountAndWorkflow[listId].results.map(
                        (workflowStep: WorkflowStep) => (workflowStep.id == action.payload.id ? action.payload : workflowStep)
                    );
                }
            }
        },
        addWorkflowStep: (state, action: PayloadAction<WorkflowStep>) => {
            state.unordered.push(action.payload);
            if (state.workflowStepsByAccountAndWorkflow != null) {
                const listId = action.payload.account_id + ':' + action.payload.workflow_id;
                if (state.workflowStepsByAccountAndWorkflow[listId] != null) {
                    state.workflowStepsByAccountAndWorkflow[listId].count++;
                    state.workflowStepsByAccountAndWorkflow[listId].results = [action.payload].concat(state.workflowStepsByAccountAndWorkflow[listId].results);
                }
            }
        },
        removeWorkflowStep: (state, action: PayloadAction<string>) => {
            state.unordered = state.unordered.filter((w) => w.id != action.payload);
            if (state.workflowStepsByAccountAndWorkflow != null) {
                Object.keys(state.workflowStepsByAccountAndWorkflow).forEach((key) => {
                    state.workflowStepsByAccountAndWorkflow![key].results = state.workflowStepsByAccountAndWorkflow![key].results.filter(
                        (w) => w.id != action.payload
                    );
                });
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAccountWorkflowSteps.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchAccountWorkflowSteps.fulfilled, (state, action) => {
                if (action.meta.arg.queryParams.size == null || action.meta.arg.queryParams.index == null) return;
                const listId = action.meta.arg.accountId + ':' + action.meta.arg.workflowId;
                const startPos = toNumber(action.meta.arg.queryParams.size) * (toNumber(action.meta.arg.queryParams.index) - 1);
                if (state.workflowStepsByAccountAndWorkflow == null) state.workflowStepsByAccountAndWorkflow = {};
                if (state.workflowStepsByAccountAndWorkflow[listId] == undefined) {
                    state.workflowStepsByAccountAndWorkflow[listId] = {
                        ...action.payload,
                        results: new Array(action.payload.count)
                    };
                    state.workflowStepsByAccountAndWorkflow[listId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.workflowStepsByAccountAndWorkflow[listId].results.length !== action.payload.count) {
                        state.workflowStepsByAccountAndWorkflow[listId].count = action.payload.count;
                        state.workflowStepsByAccountAndWorkflow[listId].results = new Array(action.payload.count);
                    }
                    state.workflowStepsByAccountAndWorkflow[listId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.unordered = [
                    ...state.unordered.filter((w) => action.payload.results.find((res) => res.id == w.id) == undefined),
                    ...action.payload.results
                ];
                state.status = SliceStatus.IDLE;
            });
    }
});

export const fetchAccountWorkflowSteps = createAsyncThunk<
    WorkflowStepListResponse,
    { accountId: string; workflowId: string; queryParams: ApiQueryParams<DefaultQueryParams> }
>(
    'fetchAccountWorkflowSteps',
    async (params: { accountId: string; workflowId: string; queryParams: ApiQueryParams<DefaultQueryParams> }, { rejectWithValue }) => {
        try {
            return await WorkflowStepClient.fetchAccountWorkflowSteps(params.accountId, params.workflowId, params.queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const workflowStepStore = { workflowSteps: workflowStepSlice.reducer };
export const { updateWorkflowStep, addWorkflowStep, removeWorkflowStep } = workflowStepSlice.actions;