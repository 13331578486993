import { ProductClass } from '@frontend/product/types';
import { useMemo } from 'react';

import { RestrictionKeySelectProps } from './restriction-key-select.component';

const useRestrictionKeySelect = (props: RestrictionKeySelectProps) => {
    const options = useMemo(() => {
        switch (props.type) {
            case 'product': {
                const productKeys = Object.keys(new ProductClass());
                return productKeys.map((v) => ({
                    value: v,
                    label: v
                }));
            }
        }
    }, [props.type]);

    return {
        options
    };
};

export default useRestrictionKeySelect;
