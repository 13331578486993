import { useState } from 'react';

import { ContactIdContactProps } from './contact-id-contact.component';

interface ViewProps {
    submitted: boolean;
    contactId: string | null;
    changeContactId: (value: string | null) => void;
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}

const useContactIdContact = (props: ContactIdContactProps): ViewProps => {
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const [contactId, changeContactId] = useState<string | null>(null);

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (contactId) {
            changeSubmitted(true);
            props.callback({
                ...props.variable,
                value: contactId
            });
        }
    };

    return {
        submitted,
        contactId,
        changeContactId,
        onSubmit
    };
};

export default useContactIdContact;
