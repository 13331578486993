import { ChooseAckSlotData } from '@frontend/user-interface/types';
import { useEffect, useState } from 'react';
import { ChooseAckSlotDataFormProps } from './choose-ack-slot-data-form.component';

interface ViewProps {
    formValues: ChooseAckSlotData;
    changeFormValues: React.Dispatch<React.SetStateAction<ChooseAckSlotData>>;
}

const useChooseAckSlotDataForm = (props: ChooseAckSlotDataFormProps): ViewProps => {
    const [formValues, changeFormValues] = useState<ChooseAckSlotData>({ allowChangeSlot: false });

    useEffect(() => {
        props.onChange(formValues);
    }, [formValues]);

    return {
        formValues,
        changeFormValues
    };
};

export default useChooseAckSlotDataForm;
