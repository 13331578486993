import { SelectInput } from '@frontend/basic-forms';
import React from 'react';

import useTitleSelect from './title-select.controller';

const ID = 'title-select';

export interface TitleSelectProps {
    label?: React.ReactNode;
    className?: string;
    required?: boolean;
    submitted: boolean;
    onChange: (newValue: string) => void;
    value?: string | null;
}

export const TitleSelect = (props: TitleSelectProps) => {
    const viewProps = useTitleSelect(props);
    return (
        <SelectInput
            id={ID}
            className={props.className}
            label={props.label || 'Title'}
            required={props.required}
            submitted={props.submitted}
            options={viewProps.options}
            value={viewProps.value}
            onChange={(value) => props.onChange((value as { value: string; label: any })?.value)}
        />
    );
};
