import { APIClient, ApiQueryParams, DefaultQueryParams } from "@frontend/api-utils";
import { CreateSeed, Seed, SeedListResponse } from "@frontend/sync/types";

const endpoint = '/sync-api/v1'

export class SeedClient extends APIClient {
    public static async fetchSeeds(queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<SeedListResponse> {
        return await this.apiPaginated<SeedListResponse, DefaultQueryParams>(`${endpoint}/seeds`, queryParams);
    }

    public static async fetchDriverSeeds(driver_id: string, queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<SeedListResponse> { 
        return await this.apiPaginated<SeedListResponse, DefaultQueryParams>(`${endpoint}/drivers/${driver_id}/seeds`, queryParams);
    }

    public static async postDriverSeed(driver_id: string, body: CreateSeed): Promise<Seed> {
        const response = await this.post(`${endpoint}/drivers/${driver_id}/seeds`, body);
        return await this.handleResponse<Seed>(response);
    }

    public static async fetchDriverSeed(driver_id: string, seed_id: string): Promise<Seed>{
        const response = await this.fetch(`${endpoint}/drivers/${driver_id}/seeds/${seed_id}`);
        return await this.handleResponse<Seed>(response);
    }

    public static async deleteDriverSeed(driver_id: string, seed_id: string): Promise<void> { 
        const response = await this.delete(`${endpoint}/drivers/${driver_id}/seeds/${seed_id}`);
        return await this.handleVoidResponse(response);
    }
}