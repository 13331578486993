import React from 'react';
import { FormattedMessage } from 'react-intl';

export enum Locale {
    ENGLISH = 'en-US',
    DUTCH = 'nl-BE',
    FRENCH = 'fr-FR',
    GERMAN = 'de-DE'
}

export const Languages: { value: Locale; displayName: string }[] = [
    {
        value: Locale.ENGLISH,
        displayName: 'English'
    },
    {
        value: Locale.DUTCH,
        displayName: 'Nederlands'
    },
    {
        value: Locale.FRENCH,
        displayName: 'Français'
    },
    {
        value: Locale.GERMAN,
        displayName: 'Deutsch'
    }
];

export const convertLanguage = (lang: Locale | '') => {
    switch (lang) {
        case Locale.DUTCH:
            return Dutch;
        case Locale.ENGLISH:
            return English;
        case Locale.FRENCH:
            return French;
        case Locale.GERMAN:
            return German;
        default:
            return '';
    }
};

const Dutch = (
    <FormattedMessage
        id='LanguageConverter.Dutch'
        description={'Converts nl_BE to Dutch in its corresponding language'}
        defaultMessage='Dutch'
    />
);

const English = (
    <FormattedMessage
        id='LanguageConverter.English'
        description={'Converts en_GB to English in its corresponding language'}
        defaultMessage='English'
    />
);

const French = (
    <FormattedMessage
        id='LanguageConverter.French'
        description={'Converts fr_BE to French in its corresponding language'}
        defaultMessage='French'
    />
);

const German = (
    <FormattedMessage
        id='LanguageConverter.German'
        description={'Converts de-DE to German in its corresponding language'}
        defaultMessage='German'
    />
);
