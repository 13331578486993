import { AsyncSelectInputSingle } from '@frontend/basic-forms';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

import useNotificationTemplateByAccountSelect from './notification-template-by-account-select.controller';

const ID = 'notification-template-by-account-select';
export interface NotificationTemplateByAccountSelectProps {
    id?: string;
    label?: string;
    helpMessage?: string;
    required?: boolean;
    submitted: boolean;
    onChange: (newValue?: string) => void;
    value?: string;
    className?: string;
    disabled?: boolean;
    useConditionedStyling?: boolean;
    isClearable?: boolean;
    dispatch: ThunkDispatch<any, any, Action>;

    accountId?: string;
}

export const NotificationTemplateByAccountSelect = (props: NotificationTemplateByAccountSelectProps) => {
    const viewProps = useNotificationTemplateByAccountSelect(props);

    return (
        <AsyncSelectInputSingle
            id={props.id || ID}
            label={props.label || 'Notification template'}
            helpMessage={props.helpMessage}
            className={props.className}
            submitted={props.submitted}
            required={props.required}
            options={viewProps.options}
            onChange={(newValue) => props.onChange(newValue?.value)}
            value={viewProps.value?.value}
            isDisabled={!props.accountId || props.disabled}
            onMenuScrollToBottom={viewProps.onScrollToBottom}
            loadOptions={viewProps.search}
            isClearable={props.isClearable}
            useConditionedStyling={props.useConditionedStyling}
        />
    );
};
