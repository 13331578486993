import { Image as ImageExtension } from '@tiptap/extension-image';
import Underline from '@tiptap/extension-underline';
import { EditorProvider, Extension } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import ImageResize from 'tiptap-extension-resize-image';

import useEditor from './editor.controller';
import FooterBar from './footer-bar/footer-bar.component';
import MenuBar from './menu-bar/menu-bar.component';
import NotificationPreview from './notification-preview/notification-preview.component';
import React from 'react';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

export interface ContentEditorProps {
    content: string;
    subject?: string;
    resetSubject: () => void;
    dispatch: ThunkDispatch<any, any, Action>;
}

const extensions: Extension[] = [StarterKit, ImageExtension as Extension, ImageResize as Extension, Underline as Extension];

const ContentEditor = (props: ContentEditorProps) => {
    const viewProps = useEditor(props);

    // async function uploadImage(file: File) {
    //     const data = new FormData();
    //     data.append('file', file);
    //     return await 'https://telloport-media-production.s3.amazonaws.com/product_images/243000d4bf5405f744ff6bdaf0dff5da39f18277a80f37a60b6ed7df9fd6ec26_AC%2012.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIASQAB3RKRH6XO6HP2%2F20240402%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240402T073440Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEC8aCWV1LXdlc3QtMSJIMEYCIQDHaQu2XRol%2BOv9827VxrsvuQkFqT9XGMQ5sXtf33rsMwIhALCyG5TZu%2F2V%2FVgdW84tXdHeNOKby53LT1FExnkAbYFGKo8DCFgQAhoMMTcxODAyNTkzOTU0IgzUpZv8cfVhq%2BoNxGUq7AJ549d6UlJ0H5Waxt6mbYUNgeLlvHkNSGUa72mflPCV9f1CNuknzwxuWT%2FwRQqiGKvWnQHutzHzrgtohWRPGyQpTsTG6P%2F9MqiO9fjTy560s7EPl5%2FbLVvRRGayMRfAoVmqSnLb1%2FAQPYfNNZSe83YdSWNguKV4QBdmU%2BH0M9Tp9TPmoU3DPxETSz2%2FPBN0OLrWb8Im6X3xnRKeKPsjk7qEQB2x8zSBXaLT5EfcPwagt9jgXixKv9mKEcbu411zFJp8M5FQcgXYPzV1B28DO1VRfR1SZgS%2F%2F2OP1d2wII7JttDEmkkopknpLh0hyAIQmmvwl0iW1VQrIA4%2FD2SWDkWO4TAnTTrk3X%2Fd%2BIWGg5lqpJ8GN3D7JRuY7whOQkJJzSKZAesLJd9%2FACXnBderSZ3bfCPli0xCvtqNLxQkpIgb%2B897Z1v5n8fKwVmuJoDwAdNatNgvoKrq8S6cTSWy2RNvFJ0QGQThpoVijI61MI3RrrAGOpwBXmJ%2BBYGj1w8SykVNeFq54aJsheF%2FexIhVuZZB2OlSE9URIO%2F8KODdSpeUdgrzTN9tXGAoUNqASoPek1ogpOZPRvCi7YQ1A8hRL%2FLu%2BtpNuGSEIrpruuY10FDWWpifJfe7oXjXb8F67O1uecVYGglyjkJ0gun%2B9b5pxYnyPrX2mAMPSooLV6n%2FHsaHwBFpJhZwfuPHhDKIlpw0AlV&X-Amz-Signature=fe87ce932c938a7d857b3ed08bf11a390d6fd3ce3cef8386a3dd51da7ccc0f1d';
    // }

    return (
        <>
            <EditorProvider
                slotBefore={<MenuBar />}
                slotAfter={
                    <FooterBar
                        originalContent={props.content}
                        subject={props.subject}
                        resetSubject={props.resetSubject}
                        changeShowPreview={() => viewProps.changeShowPreview((prev) => !prev)}
                        dispatch={props.dispatch}
                    />
                }
                extensions={extensions}
                content={viewProps.content}
                onUpdate={(e) => viewProps.changeContent(e.editor.getHTML())}
                // editorProps={{
                //     handleDrop: (view, event, slice, moved) => {
                //         if (!moved && event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files[0]) {
                //             const file = event.dataTransfer.files[0];
                //             const filesize = parseInt((file.size / 1024 / 1024).toFixed(4));
                //             if ((file.type === 'image/jpeg' || file.type === 'image/png') && filesize < 10) {
                //                 const _URL = window.URL || window.webkitURL;
                //                 const img = new Image();
                //                 img.src = _URL.createObjectURL(file);
                //                 img.onload = function () {
                //                     if (img.width > 5000 || img.height > 5000) {
                //                         window.alert('Your images need to be less than 5000 pixels in height and width.');
                //                     } else {
                //                         uploadImage(file)
                //                             .then(function (response) {
                //                                 const image = new Image();
                //                                 image.src = response;
                //                                 image.onload = function () {
                //                                     const { schema } = view.state;
                //                                     const coordinates = view.posAtCoords({ left: event.clientX, top: event.clientY });
                //                                     if (!coordinates) return;
                //                                     const node = schema.nodes.image.create({ src: response });
                //                                     const transaction = view.state.tr.insert(coordinates.pos, node);
                //                                     return view.dispatch(transaction);
                //                                 };
                //                             })
                //                             .catch(function (error) {
                //                                 if (error) {
                //                                     window.alert('There was a problem uploading your image, please try again.');
                //                                 }
                //                             });
                //                     }
                //                 };
                //             } else {
                //                 window.alert('Images need to be in jpg or png format and less than 10mb in size.');
                //             }
                //             return true;
                //         }
                //         return false;
                //     }
                // }}
            >
                <div></div>
            </EditorProvider>
            <NotificationPreview
                content={viewProps.content}
                subject={props.subject}
                extensions={[StarterKit]}
                handleClose={() => viewProps.changeShowPreview((prev) => !prev)}
                show={viewProps.showPreview}
            />
        </>
    );
};

export default ContentEditor;
