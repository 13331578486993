import { Table } from '@frontend/table';

import RestrictionListElement from './restriction-list-element.component';
import useRestrictionList from './restriction-list.controller';

const RestrictionList = () => {
    const viewProps = useRestrictionList();

    return (
        <Table
            RowElement={RestrictionListElement}
            columns={viewProps.columns}
            data={viewProps.data}
            page={viewProps.changePage}
            pageSize={viewProps.changePageSize}
        />
    );
};

export default RestrictionList;
