import { ClassType } from '@frontend/common';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export enum ProductStateName {
    PRODUCT_CREATED = 'product_created',
    PRODUCT_INITIALIZING = 'product_initializing',
    PRODUCT_INITIALIZED = 'product_initialized',
    PRODUCT_NEW = 'product_new',
    PRODUCT_AVAILABLE = 'product_available',
    PRODUCT_IN_USE = 'product_in_use',
    PRODUCT_MAINTENANCE = 'product_maintenance',
    PRODUCT_CHARGING = 'product_charging',
    PRODUCT_UNAVAILABLE = 'product_unavailable',
    PRODUCT_CERTIFICATION = 'product_certification',
    PRODUCT_LOST = 'product_lost',
    PRODUCT_BROKEN = 'product_broken',
    PRODUCT_CONTAMINATED = 'product_contaminated',
    UNKNOWN = '',
}

export class ProductState {
    static readonly PRODUCT_CREATED = new ProductState(
        ProductStateName.PRODUCT_CREATED,
        (
            <FormattedMessage
                id='ProductState.product_created'
                description='The displayed value for the ProductState product_created'
                defaultMessage='Product created'
            />
        )
    );

    static readonly PRODUCT_INITIALIZING = new ProductState(
        ProductStateName.PRODUCT_INITIALIZING,
        (
            <FormattedMessage
                id='ProductState.product_initializing'
                description='The displayed value for the ProductState product_initializing'
                defaultMessage='Product initializing'
            />
        )
    );

    static readonly PRODUCT_INITIALIZED = new ProductState(
        ProductStateName.PRODUCT_INITIALIZED,
        (
            <FormattedMessage
                id='ProductState.product_initialized'
                description='The displayed value for the ProductState product_initialized'
                defaultMessage='Product initialized'
            />
        )
    );

    static readonly PRODUCT_NEW = new ProductState(
        ProductStateName.PRODUCT_NEW,
        (
            <FormattedMessage
                id='ProductState.product_new'
                description='The displayed value for the ProductState product_new'
                defaultMessage='Product new'
            />
        )
    );

    static readonly PRODUCT_AVAILABLE = new ProductState(
        ProductStateName.PRODUCT_AVAILABLE,
        (
            <FormattedMessage
                id='ProductState.product_available'
                description='The displayed value for the ProductState product_available'
                defaultMessage='Product available'
            />
        )
    );

    static readonly PRODUCT_IN_USE = new ProductState(
        ProductStateName.PRODUCT_IN_USE,
        (
            <FormattedMessage
                id='ProductState.product_in_use'
                description='The displayed value for the ProductState product_in_use'
                defaultMessage='Product in use'
            />
        )
    );

    static readonly PRODUCT_MAINTENANCE = new ProductState(
        ProductStateName.PRODUCT_MAINTENANCE,
        (
            <FormattedMessage
                id='ProductState.product_maintenance'
                description='The displayed value for the ProductState product_maintenance'
                defaultMessage='Product maintenance'
            />
        )
    );

    static readonly PRODUCT_CHARGING = new ProductState(
        ProductStateName.PRODUCT_CHARGING,
        (
            <FormattedMessage
                id='ProductState.product_charging'
                description='The displayed value for the ProductState product_charging'
                defaultMessage='Product charging'
            />
        )
    );

    static readonly PRODUCT_UNAVAILABLE = new ProductState(
        ProductStateName.PRODUCT_UNAVAILABLE,
        (
            <FormattedMessage
                id='ProductState.product_unavailable'
                description='The displayed value for the ProductState product_unavailable'
                defaultMessage='Product unavailable'
            />
        )
    );

    static readonly PRODUCT_CERTIFICATION = new ProductState(
        ProductStateName.PRODUCT_CERTIFICATION,
        (
            <FormattedMessage
                id='ProductState.product_certification'
                description='The displayed value for the ProductState product_certification'
                defaultMessage='Product certification'
            />
        )
    );

    static readonly PRODUCT_LOST = new ProductState(
        ProductStateName.PRODUCT_LOST,
        (
            <FormattedMessage
                id='ProductState.product_lost'
                description='The displayed value for the ProductState product_lost'
                defaultMessage='Product lost'
            />
        )
    );

    static readonly PRODUCT_BROKEN = new ProductState(
        ProductStateName.PRODUCT_BROKEN,
        (
            <FormattedMessage
                id='ProductState.product_broken'
                description='The displayed value for the ProductState product_broken'
                defaultMessage='Product broken'
            />
        )
    );

    static readonly PRODUCT_CONTAMINATED = new ProductState(
        ProductStateName.PRODUCT_CONTAMINATED,
        (
            <FormattedMessage
                id='ProductState.product_contaminated'
                description='The displayed value for the ProductState product_contaminated'
                defaultMessage='Product contaminated'
            />
        )
    );

    static readonly ALL = [
        ProductState.PRODUCT_CREATED,
        ProductState.PRODUCT_INITIALIZING,
        ProductState.PRODUCT_INITIALIZED,
        ProductState.PRODUCT_NEW,
        ProductState.PRODUCT_AVAILABLE,
        ProductState.PRODUCT_IN_USE,
        ProductState.PRODUCT_MAINTENANCE,
        ProductState.PRODUCT_CHARGING,
        ProductState.PRODUCT_UNAVAILABLE,
        ProductState.PRODUCT_CERTIFICATION,
        ProductState.PRODUCT_LOST,
        ProductState.PRODUCT_BROKEN,
        ProductState.PRODUCT_CONTAMINATED,
    ];

    private constructor(public readonly value: ProductStateName, public readonly displayName: React.ReactNode) {}

    static getByValue(action: ProductStateName): ProductState | undefined {
        return this.ALL.find((a) => a.value == action);
    }

    static getType(state: ProductStateName): ClassType {
        switch (state) {
            case ProductStateName.PRODUCT_AVAILABLE:
                return ClassType.SUCCESS;
            case ProductStateName.PRODUCT_BROKEN:
            case ProductStateName.PRODUCT_CONTAMINATED:
            case ProductStateName.PRODUCT_UNAVAILABLE:
            case ProductStateName.PRODUCT_LOST:
                return ClassType.DANGER;
            case ProductStateName.PRODUCT_IN_USE:
            case ProductStateName.PRODUCT_MAINTENANCE:
            case ProductStateName.PRODUCT_CHARGING:
                return ClassType.WARNING;
            case ProductStateName.PRODUCT_INITIALIZING:
                return ClassType.SECONDARY;
            case ProductStateName.PRODUCT_CREATED:
            case ProductStateName.PRODUCT_INITIALIZED:
            case ProductStateName.PRODUCT_NEW:
            case ProductStateName.PRODUCT_CERTIFICATION:
                return ClassType.INFO;
        }

        return ClassType.DANGER;
    }
}
