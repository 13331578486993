import { useEffect, useRef, useState } from 'react';

interface ViewProps {
    showMenu: boolean;
    setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
    menuRef: React.RefObject<HTMLDivElement>;
    spanRef: React.RefObject<HTMLSpanElement>;
}

const useActionButton = (): ViewProps => {
    const [showMenu, setShowMenu] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);
    const spanRef = useRef<HTMLSpanElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (spanRef.current && spanRef.current.contains(event.target as Node)) return;
        if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
            setShowMenu(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return {
        showMenu,
        setShowMenu,
        menuRef,
        spanRef
    };
};

export default useActionButton;
