import { BadgeTemplate, DetailModalType, NameBadge } from '@frontend/elements';
import React from 'react';

import useContactBadge from './contact-badge.controller';

export interface ContactBadgeProps {
    id?: string;
    first_name?: string;
    last_name?: string;
}

/**
 * @deprecated
 */
export const ContactBadge = (props: ContactBadgeProps) => {
    const viewProps = useContactBadge(props);

    return (
        <BadgeTemplate
            disabled={viewProps.disabled}
            type={DetailModalType.CONTACT}
            objectId={props.id}>
            {!viewProps.displayName ? (
                <span className='placeholder'>
                    <span className='placeholder-glow'>Loading</span>
                </span>
            ) : typeof viewProps.displayName === 'string' ? (
                viewProps.displayName
            ) : (
                <NameBadge
                    firstName={viewProps.displayName.first_name}
                    lastName={viewProps.displayName.last_name}
                />
            )}
        </BadgeTemplate>
    );
};
