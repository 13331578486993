import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

import { DriverClient } from '@frontend/edge/api';
import { CreateDriver, Driver, DriverListResponse, DriverQueryParams, UpdateDriver } from '@frontend/edge/types';

export interface DriverState {
    unordered: Driver[];
    drivers: DriverListResponse | null;
    status: SliceStatus;
}

const initialState: DriverState = {
    unordered: [],
    drivers: null,
    status: SliceStatus.INIT
};

export const driverSlice = createSlice({
    name: 'driverSlice',
    initialState,
    reducers: {
        seedDrivers(state, action: PayloadAction<Driver[]>) {
            state.unordered = [...state.unordered.filter((driver) => action.payload.find((d) => d.id == driver.id) == undefined), ...action.payload];
        },
        updateDriver(state, action: PayloadAction<Driver>) {
            state.unordered = state.unordered.map((d) => (d.id == action.payload.id ? action.payload : d));
            if (state.drivers != null) {
                state.drivers.results = state.drivers.results.map((d) => (d.id == action.payload.id ? action.payload : d));
            }
        },
        addDriver(state, action: PayloadAction<Driver>) {
            state.unordered.push(action.payload);
            if (state.drivers != null) {
                state.drivers.count++;
                state.drivers.results.splice(0, 0, action.payload);
            }
        },
        removeDriver(state, action: PayloadAction<string>) {
            state.unordered = state.unordered.filter((d) => d.id != action.payload);
            if (state.drivers != null) {
                state.drivers.count--;
                state.drivers.results = state.drivers.results.filter((d) => d.id != action.payload);
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDrivers.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchDrivers.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                const startPos = toNumber(action.meta.arg.size) * toNumber(action.meta.arg.index);
                if (state.drivers === null) {
                    state.drivers = {
                        ...action.payload,
                        results: new Array(action.payload.count)
                    };
                    state.drivers.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.drivers.results.length !== action.payload.count) {
                        state.drivers.count = action.payload.count;
                        state.drivers.results = new Array(action.payload.count);
                    }
                    state.drivers.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.unordered = [
                    ...state.unordered.filter((driver) => action.payload.results.find((d) => d.id == driver.id) == undefined),
                    ...action.payload.results
                ];
            });
    }
});

export const fetchDrivers = createAsyncThunk<DriverListResponse, ApiQueryParams<DefaultQueryParams | DriverQueryParams>>(
    'fetchDrivers',
    async (queryParams: ApiQueryParams<DefaultQueryParams | DriverQueryParams>, { rejectWithValue }) => {
        try {
            return await DriverClient.fetchDrivers(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const deleteDriver = createAsyncThunk<void, string>('deleteDriver', async (driverId: string, { rejectWithValue }) => {
    try {
        return await DriverClient.deleteDriver(driverId);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const patchDriver = createAsyncThunk<Driver, { driverId: string; body: UpdateDriver }>(
    'updateDriver',
    async (params: { driverId: string; body: UpdateDriver }, { rejectWithValue }) => {
        try {
            return await DriverClient.patchDriver(params.driverId, params.body);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const postDriver = createAsyncThunk<Driver, CreateDriver>('createDriver', async (body: CreateDriver, { rejectWithValue }) => {
    try {
        return await DriverClient.postDriver(body);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const driverStore = { drivers: driverSlice.reducer };
export const { seedDrivers, updateDriver, addDriver, removeDriver } = driverSlice.actions;
