import { ClassType } from '@frontend/common';
import { ActionButton, DefaultModal, DropdownButtonGroup } from '@frontend/elements';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { Row } from 'react-table';

import UpdateTransactionVariable from '../../forms/update-transaction-variable/update-transaction-variable.component';
import { TransactionVariableBadge } from '../../transaction-variable-badge/transaction-variable-badge.component';
import { TransactionVariable } from '../../transaction-variables';
import useTransactionVariablesElement from './transaction-variables-element.controller';

export interface TransactionVariablesElementProps {
    selectEnabled?: boolean;
    row: Row<TransactionVariable>;
    onClick?: (element: TransactionVariable) => void;
    dispatch: ThunkDispatch<any, any, AnyAction>;
}

const TransactionVariablesElement = (props: TransactionVariablesElementProps) => {
    const viewProps = useTransactionVariablesElement();
    return (
        <tr
            id='TransactionVariables-Row'
            onClick={() => props.onClick && props.onClick(props.row.original)}
            className={'' + (props.onClick && 'cursor-pointer')}>
            {props.selectEnabled === true && (
                <td
                    onClick={(e) => e.stopPropagation()}
                    {...props.row.cells[0].getCellProps()}>
                    {props.row.cells[0].render('Cell')}
                </td>
            )}
            <td className='text-secondary text-xs font-weight-bolder align-content-center'>
                <span>{props.row.original.name}</span>
            </td>
            <td className='text-secondary text-xs font-weight-bolder align-content-center'>
                <TransactionVariableBadge transactionVariable={props.row.original} />
            </td>
            <td className='text-secondary text-xs font-weight-bolder align-content-center'>
                <ActionButton>
                    <DropdownButtonGroup
                        buttons={[
                            {
                                text: 'Edit',
                                icon: FaEdit,
                                iconColor: ClassType.WARNING,
                                onClick: () => viewProps.changeShowUpdateModal(true)
                            },
                            {
                                text: 'Delete',
                                icon: FaTrash,
                                iconColor: ClassType.DANGER,
                                onClick: () => viewProps.changeShowDeleteModal(true)
                            }
                        ]}
                        showJSON={props.row}
                    />
                </ActionButton>
            </td>
            {viewProps.showUpdateModal && (
                <DefaultModal
                    handleClose={() => viewProps.changeShowUpdateModal(false)}
                    show={viewProps.showUpdateModal}>
                    <UpdateTransactionVariable
                        onClose={() => viewProps.changeShowUpdateModal(false)}
                        transactionVariable={props.row.original}
                        dispatch={props.dispatch}
                    />
                </DefaultModal>
            )}
        </tr>
    );
};

export default TransactionVariablesElement;
