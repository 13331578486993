import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { CreateUserInterfaceButton, UpdateUserInterfaceButton, UserInterfaceButton, UserInterfaceButtonListResponse } from '@frontend/user-interface-button/types';


const endpoint = '/user-interface-api/v1';
export class UserInterfaceButtonClient extends APIClient {
    public static async fetchUserInterfaceButtons(queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<UserInterfaceButtonListResponse> {
        return await this.apiPaginated<UserInterfaceButtonListResponse, any>(`${endpoint}/user-interface-buttons`, queryParams);
    }

    public static async fetchAccountUserInterfaceButtons(
        accountId: string,
        userInterfaceId: string,
        queryParams?: ApiQueryParams<DefaultQueryParams>
    ): Promise<UserInterfaceButtonListResponse> {
        return await this.apiPaginated(`${endpoint}/accounts/${accountId}/user-interfaces/${userInterfaceId}/buttons`, queryParams);
    }

    public static async postAccountUserInterfaceButton(
        userInterfaceButton: CreateUserInterfaceButton,
        accountId: string,
        userInterfaceId: string
    ): Promise<UserInterfaceButton> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/user-interfaces/${userInterfaceId}/buttons`, userInterfaceButton);
        return await this.handleResponse<UserInterfaceButton>(response);
    }

    public static async fetchAccountUserInterfaceButton(
        accountId: string,
        userInterfaceId: string,
        userInterfaceButtonId: string
    ): Promise<UserInterfaceButton> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/user-interfaces/${userInterfaceId}/buttons/${userInterfaceButtonId}`);
        return await this.handleResponse<UserInterfaceButton>(response);
    }

    public static async patchAccountUserInterfaceButton(
        userInterfaceButton: UpdateUserInterfaceButton,
        accountId: string,
        userInterfaceId: string,
        userInterfaceButtonId: string
    ): Promise<UserInterfaceButton> {
        const response = await this.patch(
            `${endpoint}/accounts/${accountId}/user-interfaces/${userInterfaceId}/buttons/${userInterfaceButtonId}`,
            userInterfaceButton
        );
        return await this.handleResponse<UserInterfaceButton>(response);
    }

    public static async deleteAccountUserInterfaceButton(accountId: string, userInterfaceId: string, userInterfaceButtonId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/user-interfaces/${userInterfaceId}/buttons/${userInterfaceButtonId}`);
        return await this.handleVoidResponse(response);
    }
}
