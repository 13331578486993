export const TELLOPORT_SELECTED_ACCOUNT_COOKIE = 'tp_prev_sel_acc';
export const DEVICE_UNIQUE_ID = 'tp_prev_d_u_id';
export const ONBOARD_SCANNER_AVAILABLE_COOKIE = 'onb_scn_tlp';
export function setCookie(cname: string, cvalue: string, exdays = 400): void {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

export function getCookie(cname: string): string | undefined {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return undefined;
}

export function removeCookie(cname: string): void {
    document.cookie = cname + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}
