import { Spinner } from '@frontend/elements';
import { UserInterfaceImplementation, WarehouseProductPickDropData } from '@frontend/user-interface/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

import BasketEditor from './basket-editor/basket-editor.component';
import Checkout from './checkout/checkout.component';
import WarehousePickDropFinished from './warehouse-pick-drop-finished/warehouse-pick-drop-finished.component';
import useWarehouseProductPickDrop from './warehouse-product-pick-drop.controller';

export interface WarehouseProductPickDropProps {
    dispatch: ThunkDispatch<any, any, Action>;
    userInterface: UserInterfaceImplementation<WarehouseProductPickDropData>;
}

const WarehouseProductPickDrop = (props: WarehouseProductPickDropProps) => {
    const viewProps = useWarehouseProductPickDrop(props);
    if (viewProps.loading) return <Spinner />;
    if (viewProps.finished) return <WarehousePickDropFinished />;
    if (!viewProps.checkoutBasket || viewProps.packages.length == 0 || viewProps.transaction == null)
        return (
            <BasketEditor
                dispatch={props.dispatch}
                userInterface={props.userInterface}
                packages={viewProps.packages}
                changePackages={viewProps.changePackages}
                createTransaction={viewProps.createTransaction}
                transaction={viewProps.transaction}
                toCheckout={() => viewProps.changeCheckoutBasket(true)}
            />
        );
    return (
        <Checkout
            createTransaction={viewProps.createTransaction}
            dispatch={props.dispatch}
            transaction={viewProps.transaction}
            packages={viewProps.packages}
            changePackages={viewProps.changePackages}
            onConfirm={viewProps.confirmBasket}
            back={() => viewProps.changeCheckoutBasket(false)}
            userInterface={props.userInterface}
        />
    );
};

export default WarehouseProductPickDrop;
