import { useState } from 'react';

import { ContentEditorProps } from './editor.component';

interface ViewProps {
    content: string;
    changeContent: (value: React.SetStateAction<string>) => void;
    showPreview: boolean;
    changeShowPreview: (value: React.SetStateAction<boolean>) => void;
}

const useEditor = (props: ContentEditorProps): ViewProps => {
    const [content, changeContent] = useState<string>(props.content);
    const [showPreview, changeShowPreview] = useState<boolean>(false);

    return {
        changeContent,
        content,
        changeShowPreview,
        showPreview
    };
};

export default useEditor;
