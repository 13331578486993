import React from 'react';
import { FormattedMessage } from 'react-intl';

export interface WorkflowStepTriggeredEvent {
    account_id: string;
    workflow_id: string;
    spot_id?: string | null;
    transaction_id?: string | null;
    product_id?: string | null;
    package_id?: string | null;
    user_id?: string | null;
    module_id?: string | null;
    slot_id?: string | null;
    driver_id?: string | null;
    data: any;
}


/**
 * @deprecated
 * this should come from transaction/types instead of workflow/types
 */
export enum TransactionWorkflowStepTriggerName {
    TRANSACTION_STATE_CHANGED = 'transaction_state_changed',
    TRANSACTION_PROCESS = 'transaction_process',
    TRANSACTION_PACKAGE_PROCESSED = 'transaction_package_processed',
    TRANSACTION_PROCESSED = 'transaction_processed',
    PERSONNEL_BADGE_SCANNED = 'personnel_badge_scanned',

    TRANSACTION_CANCELLED = 'transaction_cancelled',
    REMOVAL_CODE_CREATED = 'removal_code_created',
    TRANSACTION_CODE_SCANNED = 'transaction_code_scanned',
    DROP_OFF_COMPLETED = 'drop_off_completed',
    PICK_UP_COMPLETED = 'pick_up_completed',
    REMOVAL_COMPLETED = 'removal_completed',
    WAREHOUSE_ITEMS_CONFIRMED = 'warehouse_items_confirmed',
    TRANSACTION_STATE_TIMED_OUT = 'transaction_state_timed_out',
    TRANSACTION_COMPLETED = 'transaction_completed',
    USER_INTERFACE_COMPLETED = 'transaction_user_interface_completed',
    TRACKING_NUMBER_SCANNED = 'transaction_tracking_number_scanned',
    TRANSACTION_USER_INTERFACE_BUTTON_CLICKED = 'transaction_user_interface_button_clicked'
}

/**
 * @deprecated
 * this should come from transaction/types instead of workflow/types
 */
export class TransactionWorkflowStepTrigger {
    static readonly TRANSACTION_STATE_CHANGED = new TransactionWorkflowStepTrigger(
        TransactionWorkflowStepTriggerName.TRANSACTION_STATE_CHANGED,
        (
            <FormattedMessage
                id='WorkflowStepTrigger.transaction_state_changed'
                description='The displayed value for the WorkflowStepTrigger transaction_state_changed'
                defaultMessage='Transaction state changed'
            />
        )
    );
    static readonly TRANSACTION_PROCESS = new TransactionWorkflowStepTrigger(
        TransactionWorkflowStepTriggerName.TRANSACTION_PROCESS,
        (
            <FormattedMessage
                id='WorkflowStepTrigger.transaction_process'
                description='The displayed value for the WorkflowStepTrigger transaction_process'
                defaultMessage='Transaction process'
            />
        )
    );
    static readonly TRANSACTION_PACKAGE_PROCESSED = new TransactionWorkflowStepTrigger(
        TransactionWorkflowStepTriggerName.TRANSACTION_PACKAGE_PROCESSED,
        (
            <FormattedMessage
                id='WorkflowStepTrigger.transaction_package_processed'
                description='The displayed value for the WorkflowStepTrigger transaction_package_processed'
                defaultMessage='Transaction package processed'
            />
        )
    );
    static readonly TRANSACTION_PROCESSED = new TransactionWorkflowStepTrigger(
        TransactionWorkflowStepTriggerName.TRANSACTION_PROCESSED,
        (
            <FormattedMessage
                id='WorkflowStepTrigger.transaction_processed'
                description='The displayed value for the WorkflowStepTrigger transaction_processed'
                defaultMessage='Transaction processed'
            />
        )
    );
    static readonly PERSONNEL_BADGE_SCANNED = new TransactionWorkflowStepTrigger(
        TransactionWorkflowStepTriggerName.PERSONNEL_BADGE_SCANNED,
        (
            <FormattedMessage
                id='WorkflowStepTrigger.personnel_badge_scanned'
                description='The displayed value for the WorkflowStepTrigger personnel_badge_scanned'
                defaultMessage='Personnel badge scanned'
            />
        )
    );
    static readonly TRANSACTION_CANCELLED = new TransactionWorkflowStepTrigger(
        TransactionWorkflowStepTriggerName.TRANSACTION_CANCELLED,
        (
            <FormattedMessage
                id='WorkflowStepTrigger.transaction_cancelled'
                description='The displayed value for the WorkflowStepTrigger transaction_cancelled'
                defaultMessage='Transaction cancelled'
            />
        )
    );
    static readonly REMOVAL_CODE_CREATED = new TransactionWorkflowStepTrigger(
        TransactionWorkflowStepTriggerName.REMOVAL_CODE_CREATED,
        (
            <FormattedMessage
                id='WorkflowStepTrigger.removal_code_created'
                description='The displayed value for the WorkflowStepTrigger removal_code_created'
                defaultMessage='Removal code created'
            />
        )
    );
    static readonly TRANSACTION_CODE_SCANNED = new TransactionWorkflowStepTrigger(
        TransactionWorkflowStepTriggerName.TRANSACTION_CODE_SCANNED,
        (
            <FormattedMessage
                id='WorkflowStepTrigger.transaction_code_scanned'
                description='The displayed value for the WorkflowStepTrigger transaction_code_scanned'
                defaultMessage='Code scanned'
            />
        )
    );
    static readonly DROP_OFF_COMPLETED = new TransactionWorkflowStepTrigger(
        TransactionWorkflowStepTriggerName.DROP_OFF_COMPLETED,
        (
            <FormattedMessage
                id='WorkflowStepTrigger.drop_off_completed'
                description='The displayed value for the WorkflowStepTrigger drop_off_completed'
                defaultMessage='DropOff completed'
            />
        )
    );
    static readonly PICK_UP_COMPLETED = new TransactionWorkflowStepTrigger(
        TransactionWorkflowStepTriggerName.PICK_UP_COMPLETED,
        (
            <FormattedMessage
                id='WorkflowStepTrigger.pick_up_completed'
                description='The displayed value for the WorkflowStepTrigger pick_up_completed'
                defaultMessage='PickUp completed'
            />
        )
    );
    static readonly REMOVAL_COMPLETED = new TransactionWorkflowStepTrigger(
        TransactionWorkflowStepTriggerName.REMOVAL_COMPLETED,
        (
            <FormattedMessage
                id='WorkflowStepTrigger.removal_completed'
                description='The displayed value for the WorkflowStepTrigger removal_completed'
                defaultMessage='Removal complete'
            />
        )
    );
    static readonly WAREHOUSE_ITEMS_CONFIRMED = new TransactionWorkflowStepTrigger(
        TransactionWorkflowStepTriggerName.WAREHOUSE_ITEMS_CONFIRMED,
        (
            <FormattedMessage
                id='WorkflowStepTrigger.warehouse_items_confirmed'
                description='The displayed value for the WorkflowStepTrigger warehouse_items_confirmed'
                defaultMessage='Warehouse items confirmed'
            />
        )
    );
    static readonly TRANSACTION_STATE_TIMED_OUT = new TransactionWorkflowStepTrigger(
        TransactionWorkflowStepTriggerName.TRANSACTION_STATE_TIMED_OUT,
        (
            <FormattedMessage
                id='WorkflowStepTrigger.transaction_state_timed_out'
                description='The displayed value for the WorkflowStepTrigger transaction_state_timed_out'
                defaultMessage='Transaction state timed out'
            />
        )
    );
    static readonly TRANSACTION_COMPLETED = new TransactionWorkflowStepTrigger(
        TransactionWorkflowStepTriggerName.TRANSACTION_COMPLETED,
        (
            <FormattedMessage
                id='WorkflowStepTrigger.transaction_completed'
                description='The displayed value for the WorkflowStepTrigger transaction_completed'
                defaultMessage='Transaction completed'
            />
        )
    );
    static readonly USER_INTERFACE_COMPLETED = new TransactionWorkflowStepTrigger(
        TransactionWorkflowStepTriggerName.USER_INTERFACE_COMPLETED,
        (
            <FormattedMessage
                id='WorkflowStepTrigger.user_interface_completed'
                description='The displayed value for the WorkflowStepTrigger user_interface_completed'
                defaultMessage='User interface completed'
            />
        )
    );
    static readonly TRACKING_NUMBER_SCANNED = new TransactionWorkflowStepTrigger(
        TransactionWorkflowStepTriggerName.TRACKING_NUMBER_SCANNED,
        (
            <FormattedMessage
                id='WorkflowStepTrigger.transaction_tracking_number_scanned'
                description='The displayed value for the WorkflowStepTrigger transaction_tracking_number_scanned'
                defaultMessage='Tracking number scanned'
            />
        )
    );
    static readonly TRANSACTION_USER_INTERFACE_BUTTON_CLICKED = new TransactionWorkflowStepTrigger(
        TransactionWorkflowStepTriggerName.TRANSACTION_USER_INTERFACE_BUTTON_CLICKED,
        (
            <FormattedMessage
                id='WorkflowStepTrigger.transaction_user_interface_button_clicked'
                description='The displayed value for the WorkflowStepTrigger transaction_user_interface_button_clicked'
                defaultMessage='User interface button clicked'
            />
        )
    );

    static readonly ALL = [
        TransactionWorkflowStepTrigger.TRANSACTION_CODE_SCANNED,
        TransactionWorkflowStepTrigger.DROP_OFF_COMPLETED,
        TransactionWorkflowStepTrigger.PICK_UP_COMPLETED,
        TransactionWorkflowStepTrigger.REMOVAL_CODE_CREATED,
        TransactionWorkflowStepTrigger.REMOVAL_COMPLETED,
        TransactionWorkflowStepTrigger.TRANSACTION_CANCELLED,
        TransactionWorkflowStepTrigger.TRANSACTION_STATE_CHANGED,
        TransactionWorkflowStepTrigger.WAREHOUSE_ITEMS_CONFIRMED,
        TransactionWorkflowStepTrigger.TRANSACTION_STATE_TIMED_OUT,
        TransactionWorkflowStepTrigger.TRANSACTION_COMPLETED,
        TransactionWorkflowStepTrigger.USER_INTERFACE_COMPLETED,
        TransactionWorkflowStepTrigger.TRACKING_NUMBER_SCANNED,
        TransactionWorkflowStepTrigger.TRANSACTION_USER_INTERFACE_BUTTON_CLICKED
    ];

    private constructor(public readonly value: TransactionWorkflowStepTriggerName, public readonly displayName: React.ReactNode) {}

    static getByValue(action: string) {
        return this.ALL.find((a) => a.value == action);
    }
}
