import { PhoneNumberBadgeProps } from './phone-number-badge.component';

interface ViewProps {
    phoneNumber: string;
}

const usePhoneNumberBadge = (props: PhoneNumberBadgeProps): ViewProps => {
    const phoneNumber = props.phone !== undefined && props.phone !== null && props.phone !== '' ? props.phone : '';
    return { phoneNumber };
};

export default usePhoneNumberBadge;
