import { AsyncSelectInputSingle } from '@frontend/basic-forms';

import { ApiQueryParams } from '@frontend/api-utils';
import { UserQueryParams } from '@frontend/user/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import useUserSelect from './user-select.controller';

const ID = 'user-select';
export interface UserSelectProps {
    id?: string;
    label?: React.ReactNode;
    required?: boolean;
    submitted: boolean;
    onChange?: (newValue: string | null) => void;
    value?: string | null;
    className?: string;
    disabled?: boolean;
    useConditionedStyling?: boolean;
    isClearable?: boolean;
    allowURLChange?: boolean;
    queryParams?: ApiQueryParams<UserQueryParams>;

    dispatch: ThunkDispatch<any, any, Action>;
}

export const UserSelect = (props: UserSelectProps) => {
    const viewProps = useUserSelect(props);

    return (
        <AsyncSelectInputSingle
            id={props.id || ID}
            label={props.label || 'User'}
            className={props.className}
            submitted={props.submitted}
            required={props.required}
            options={viewProps.options}
            onChange={(newValue: any) => viewProps.onChange(newValue?.value, props.allowURLChange)}
            value={viewProps.value?.value}
            isDisabled={props.disabled}
            onMenuScrollToBottom={viewProps.onScrollToBottom}
            loadOptions={viewProps.search}
            isClearable={props.isClearable}
            useConditionedStyling={props.useConditionedStyling}
        />
    );
};

