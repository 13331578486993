import { SelectInput } from '@frontend/basic-forms';
import React from 'react';
import { ActionMeta } from 'react-select';

import { UserInterfaceType } from '@frontend/user-interface/types';
import useUserInterfaceTypeSelect from './user-interface-type-select.controller';

const ID = 'user-interface-type-select';
export interface UserInterfaceTypeSelectProps {
    required: boolean;
    submitted: boolean;
    onChange: (newValue: { value: UserInterfaceType; label: any }, actionMeta: ActionMeta<any>) => void;
    value?: string;
    className?: string;

    platform?: 'terminal' | 'mobile';
}

export const UserInterfaceTypeSelect = (props: UserInterfaceTypeSelectProps) => {
    const viewProps = useUserInterfaceTypeSelect(props);

    return (
        <SelectInput
            id={ID}
            label={'Type'}
            className={props.className}
            submitted={props.submitted}
            required={props.required}
            options={viewProps.options}
            value={props.value}
            onChange={(value, actionMeta) => props.onChange(value as { value: UserInterfaceType; label: any }, actionMeta)}
        />
    );
};
