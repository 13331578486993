import { useAppSelector } from '@frontend/common';
import { fetchUserTriggers, userStore } from '@frontend/user/redux';
import { UserTrigger, UserTriggerName } from '@frontend/user/types';
import { fetchGeneralWorkflowTriggers, workflowSharedStore } from '@frontend/workflow/redux';
import { WorkflowTriggerName } from '@frontend/workflow/types';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { UserTriggerSelectProps } from './user-trigger-select.component';

interface ViewProps {
    options: { value: string; label: React.ReactNode }[];
    value:
        | {
              value: UserTriggerName | WorkflowTriggerName | string;
              label: React.ReactNode;
          }
        | {
              value: string;
              label: string;
          }
        | undefined;
}

const useUserTriggerSelect = (props: UserTriggerSelectProps): ViewProps => {
    const userTrigger = useAppSelector(useSelector, userStore);
    const workflowSharedState = useAppSelector(useSelector, workflowSharedStore);

    useEffect(() => {
        props.dispatch(fetchUserTriggers());
        props.dispatch(fetchGeneralWorkflowTriggers());
    }, []);

    const options = useMemo(() => {
        if (!userTrigger.userTriggers || !workflowSharedState.generalWorkflowTriggers) return [];
        return [...userTrigger.userTriggers, ...workflowSharedState.generalWorkflowTriggers].map((userTrigger) => {
            const found = UserTrigger.ALL.find((t) => t.value === userTrigger);
            if (found) return { value: found.value, label: found.displayName };
            else
                return {
                    value: userTrigger,
                    label: userTrigger.replace(/_/g, ' ')
                };
        });
    }, [userTrigger.userTriggers, workflowSharedState.generalWorkflowTriggers]);

    const value = useMemo(() => {
        if (!options || !props.value) return undefined;
        else return options.find((op) => op.value == props.value);
    }, [props.value, options]);

    return { options, value };
};

export default useUserTriggerSelect;
