import { BasicAuth, BasicAuthStatus } from '@frontend/authentication-methods/types';
import { CheckboxInput, PasswordInput } from '@frontend/basic-forms';
import { ClassType } from '@frontend/common';
import { ModalFooter } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import { FaPlus } from 'react-icons/fa';

import useUpdateBasicAuthForm from './update-basic-auth-form.controller';

const ID = 'update-basic-auth-form';

export interface UpdateBasicAuthFormProps {
    onClose: () => void;
    basicAuth: BasicAuth;
    dispatch: ThunkDispatch<any, any, Action>;
}

const UpdateBasicAuthForm = (props: UpdateBasicAuthFormProps) => {
    const viewProps = useUpdateBasicAuthForm(props);

    return (
        <div id={ID}>
            <form onSubmit={viewProps.submit}>
                <div className='modal-header'>
                    <h4>{CommonMessage.FORMS.UPDATE_OBJECT('Basic Auth')}</h4>
                </div>
                <div className='modal-body'>
                    <div className='d-flex flex-row'>
                        <CheckboxInput
                            label='Active'
                            submitted={viewProps.submitted}
                            value={viewProps.formValues.status === BasicAuthStatus.ACTIVE}
                            onChange={(v) =>
                                viewProps.changeFormValues({ ...viewProps.formValues, status: v ? BasicAuthStatus.ACTIVE : BasicAuthStatus.BLOCKED })
                            }
                            className=' w-50 m-1'
                            id={ID + 'checkboxInput'}
                        />
                    </div>
                    <div className='d-flex flex-row'>
                        <PasswordInput
                            className='w-100 m-1'
                            value={viewProps.formValues.password ? viewProps.formValues.password : undefined}
                            onChange={(v) => viewProps.changeFormValues({ ...viewProps.formValues, password: v })}
                            id={ID + '-passwordInput'}
                            isValidCallback={(v) => {
                                viewProps.changeFormValid({ ...viewProps.formValid, password: v });
                            }}
                            strength={!!viewProps.formValues.password}
                        />
                    </div>
                </div>
                <ModalFooter
                    justifyContent='between'
                    buttons={[
                        {
                            hide: false,
                            text: CommonMessage.BUTTONS.CANCEL,
                            type: ClassType.SECONDARY,
                            onClick: (e) => {
                                e?.preventDefault();
                                props.onClose();
                            }
                        },
                        {
                            hide: false,
                            text: CommonMessage.BUTTONS.SUBMIT,
                            type: ClassType.SUCCESS,
                            icon: FaPlus,
                            disabled: Object.values(viewProps.formValid).some((v) => !v),
                            buttonType: 'submit'
                        }
                    ]}
                />
            </form>
        </div>
    );
};

export default UpdateBasicAuthForm;
