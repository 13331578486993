import { PaginatedResponse } from '@frontend/api-utils';

import { PackageStateName } from './package-state';

export interface Package {
    driver_id: string | null;
    user_id: string | null;
    spot_id: string;
    module_id: string;
    slot_id: string;
    quantity: number;
    state: PackageStateName;
    workflow_id: string;
    priority: number;
    update_timestamp: string;
    product_id: string;
    id: string;
    account_id: string;
    transaction_id: string;
    creation_timestamp: string;
    type: PackageType;
}

export interface CreatePackage {
    driver_id?: string | null;
    product_id?: string;
    quantity?: number;
    workflow_id?: string;
    spot_id?: string;
    module_id?: string;
    slot_id?: string;
    user_id?: string;
    state?: PackageStateName;
    priority?: number;
    type: PackageType;
}

export interface UpdatePackage {
    driver_id?: string | null;
    spot_id?: string | null;
    module_id?: string | null;
    slot_id?: string | null;
    quantity?: number | null;
    state?: PackageStateName | null;
    workflow_id?: string | null;
    priority?: number | null;
}

export type PackageListResponse = PaginatedResponse<Package>;

export enum PackageQueryParams {
    DRIVER_ID = 'driver_id',
    PRODUCT_ID = 'product_id',
    STATE = 'state',
    WORKFLOW_ID = 'workflow_id',
    SPOT_ID = 'spot_id',
    MODULE_ID = 'module_id',
    SLOT_ID = 'slot_id',
    TRANSACTION_ID = 'transaction_id',
    PACKAGE_ID = 'package_id'
}

export enum PackageType {
    DROP_OFF = 'drop_off',
    PICK_UP = 'pick_up',
    VENDING = 'vending'
}
