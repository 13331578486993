import { Locale } from '@frontend/common';
import { ErrorHandler } from '@frontend/error-handler';
import { UserClient } from '@frontend/user/api';
import { CreateUser, UserTitle } from '@frontend/user/types';
import { Dispatch, SetStateAction, useState } from 'react';

import { CreateUserFormProps } from './create-user.component';

interface ViewProps {
    submitted: boolean;
    formValues: CreateUser;
    formValid: FormValid;
    onSubmit: (e: any) => void;
    changeFormValues: Dispatch<SetStateAction<CreateUser>>;
    changeFormValid: Dispatch<SetStateAction<FormValid>>;
    account: string | null;
    changeAccount: Dispatch<SetStateAction<string | null>>;
}

interface FormValid {
    first_name: boolean;
    last_name: boolean;
    title: boolean;
    email: boolean;
    workflow_id: boolean;
}
const useCreateUserForm = (props: CreateUserFormProps): ViewProps => {
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const [formValues, changeFormValues] = useState<CreateUser>({ email: '', title: UserTitle.MISTER, language: Locale.DUTCH });
    const [formValid, changeFormValid] = useState<FormValid>({
        first_name: false,
        last_name: false,
        title: true,
        email: false,
        workflow_id: true
    });
    const [account, changeAccount] = useState<string | null>(null);

    const onSubmit = (e: any) => {
        e.preventDefault();
        if (!account) return;
        changeSubmitted(true);
        UserClient.postUser(account, formValues)
            .then(props.onClose)
            .catch(ErrorHandler.handleError)
            .finally(() => changeSubmitted(false));
    };

    return {
        changeFormValid,
        changeFormValues,
        formValid,
        formValues,
        onSubmit,
        submitted,
        account,
        changeAccount
    };
};

export default useCreateUserForm;
