import RestrictionModal from '../restriction-modal/restriction-modal.component';
import useRestrictionModalContainer from './restriction-modal-container.controller';

const ID = 'restriction-modal-container';

const RestrictionModalContainer = () => {
    const viewProps = useRestrictionModalContainer();

    return <div id={ID}>{viewProps.showModal && <RestrictionModal />}</div>;
};

export default RestrictionModalContainer;
