import { Logger } from '@frontend/Logger';

import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { patchBasicAuth } from '@frontend/authentication-methods/redux';
import { UpdateBasicAuth } from '@frontend/authentication-methods/types';
import { UpdateBasicAuthFormProps } from './update-basic-auth-form.component';

interface FormValid {
    status: boolean;
    password: boolean;
}

interface ViewProps {
    formValid: FormValid;
    changeFormValid: React.Dispatch<React.SetStateAction<FormValid>>;
    formValues: UpdateBasicAuth;
    changeFormValues: React.Dispatch<React.SetStateAction<UpdateBasicAuth>>;
    submit: (e: any) => void;
    submitted: boolean;
}

const useUpdateBasicAuthForm = (props: UpdateBasicAuthFormProps): ViewProps => {
    const { userId } = useParams();
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const [formValid, changeFormValid] = useState<FormValid>({
        status: true,
        password: true
    });

    const [formValues, changeFormValues] = useState<UpdateBasicAuth>({
        status: props.basicAuth.status
    });

    const submit = (e: any) => {
        e.preventDefault();
        if (!userId) return;

        changeSubmitted(true);
        if (!formValues.password) delete formValues?.password;
        props.dispatch(patchBasicAuth({ userId: userId, basicAuthId: props.basicAuth.id, body: formValues }))
            .then(props.onClose)
            .catch(() => {
                Logger.error(`Failed to update basic auth for user with id ${userId}`, undefined, formValues);
            });
    };

    return {
        changeFormValid,
        changeFormValues,
        formValid,
        formValues,
        submit,
        submitted
    };
};

export default useUpdateBasicAuthForm;
