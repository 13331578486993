import { SelectInput } from '@frontend/basic-forms';
import { AsyncComponent } from '@frontend/elements';
import { WorkflowType } from '@frontend/workflow/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import useCreateTransactionDataForm from './create-transaction-data-form.controller';

const WorkflowSelect = React.lazy(() => import('@frontend/workflow/common').then((module) => ({ default: module.WorkflowSelect })));
const ID = 'create-transaction-data-form';
export interface CreateTransactionDataFormProps {
    accountId: string;
    onChange: (value: any) => void;
    submitted: boolean;
    dispatch: ThunkDispatch<any, any, Action>;
}

const CreateTransactionDataForm = (props: CreateTransactionDataFormProps) => {
    const viewProps = useCreateTransactionDataForm(props);

    return (
        <div id={ID}>
            <AsyncComponent>
                <WorkflowSelect
                    required
                    label='Transaction workflow'
                    submitted={props.submitted}
                    onChange={(value) => value && viewProps.changeFormValues({ ...viewProps.formValues, transactionWorkflowId: value })}
                    value={viewProps.formValues.transactionWorkflowId}
                    dispatch={props.dispatch}
                    queryParams={{type: WorkflowType.TRANSACTION, account_id: props.accountId}}
                />
                <WorkflowSelect
                    required
                    label='DropOff workflow'
                    submitted={props.submitted}
                    onChange={(value) => value && viewProps.changeFormValues({ ...viewProps.formValues, dropPackageWorkflowId: value })}
                    value={viewProps.formValues.dropPackageWorkflowId}
                    dispatch={props.dispatch}
                    queryParams={{type: WorkflowType.PACKAGE, account_id: props.accountId}}
                />
                <WorkflowSelect
                    required
                    label='PickUp workflow'
                    submitted={props.submitted}
                    onChange={(value) => value && viewProps.changeFormValues({ ...viewProps.formValues, pickPackageWorkflowId: value })}
                    value={viewProps.formValues.pickPackageWorkflowId}
                    dispatch={props.dispatch}
                    queryParams={{type: WorkflowType.PACKAGE, account_id: props.accountId}}
                />
            </AsyncComponent>
            <SelectInput
                required
                submitted={props.submitted}
                onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, senderSelectMethod: value as "email" | "company" | "contact" })}
                value={viewProps.formValues.senderSelectMethod}
                id='senderSelectMethod-select'
                isClearable
                label='Sender select method'
                options={['contact', 'company', 'email']}
            />
            <SelectInput
                required
                submitted={props.submitted}
                onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, receiverSelectMethod: value as "email" | "company" | "contact" })}
                value={viewProps.formValues.receiverSelectMethod}
                id='receiverSelectMethod-select'
                isClearable
                label='Receiver select method'
                options={['contact', 'company', 'email']}
            />
        </div>
    );
};

export default CreateTransactionDataForm;
