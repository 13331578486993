import { BasicAuth } from '@frontend/authentication-methods/types';
import { Column } from 'react-table';

const BasicAuthColumns: Column<BasicAuth>[] = [
    {
        Header: 'name',
        accessor: (row) => row.first_name + ' ' + row.last_name
    },
    {
        Header: 'email',
        accessor: 'email'
    },
    {
        Header: 'login attempts',
        accessor: 'login_attempt'
    },
    {
        Header: 'status',
        accessor: 'status'
    },
    {
        Header: 'actions',
        id: 'id'
    }
];

export default BasicAuthColumns;
