import { Logger } from "@frontend/Logger";
import { IconType } from "react-icons";
import { BsBoxArrowInDown, BsBoxArrowUp } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import { ImQrcode } from "react-icons/im";
import { IoKeypadOutline } from "react-icons/io5";

export enum UserInterfaceButtonType {
    PIN_CODE = 'Enter pin',
    DROP_OFF = 'DropOff',
    PICK_UP = 'PickUp',
    SCAN = 'Scan',
    SELECT_PRODUCT = 'Select product',
    PROFILE = 'Profile',
    UNKNOWN = ''
}

export function getIconByUIButtonType(type: UserInterfaceButtonType): IconType | null {
    switch (type) {
        case UserInterfaceButtonType.DROP_OFF:
            return BsBoxArrowInDown;
        case UserInterfaceButtonType.PICK_UP:
        case UserInterfaceButtonType.SELECT_PRODUCT:
            return BsBoxArrowUp;
        case UserInterfaceButtonType.PIN_CODE:
            return IoKeypadOutline;
        case UserInterfaceButtonType.SCAN:
            return ImQrcode;
        case UserInterfaceButtonType.PROFILE:
            return FaUser;
        default:
            Logger.error('Unknown workflow button type.', {}, type);
            return null;
    }
}