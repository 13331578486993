import { Role } from '@frontend/authorization';

import usePermissionOverview from './permission-overview.controller';

const ID = 'permission-overview';
export interface PermissionOverviewProps {
    roles: Role[];
}

const PermissionOverview = (props: PermissionOverviewProps) => {
    const viewProps = usePermissionOverview(props);

    return (
        <div
            id={ID}
            className='border-top m-3 pt-3'>
            {props.roles.length === 0 ? (
                <p className='text-xs font-weight-bold'>Select one or more roles to get a summarised version of their rights.</p>
            ) : (
                <p className='text-xs font-weight-bold'>
                    This is a summary of all selected rights given to the current asset and does not include the specific items the rights are based upon.
                    <br /> Ex.: If the list says this asset has read rights to &quot;spot&quot;, the asset will have read rights to at least one SPOT, not
                    necessarily all.
                </p>
            )}
            {viewProps.permissions?.map(PermissionsView)}
        </div>
    );
};

export default PermissionOverview;

function PermissionsView(permission: { item: string; rights: string[] }) {
    return (
        <div key={permission.item}>
            <p className='text-sm font-weight-bolder mb-0'>{permission.item}</p>
            <small>{permission.rights.join(', ')}</small>
        </div>
    );
}
