import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { Change, ChangeListResponse, CreateChange } from '@frontend/sync/types';

const endpoint = '/sync-api/v1'
export class ChangeClient extends APIClient {
    public static async fetchChanges(queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<ChangeListResponse> {
        return await this.apiPaginated<ChangeListResponse, DefaultQueryParams>(`${endpoint}/changes`, queryParams);
    }

    public static async postChanges(body: CreateChange): Promise<Change> {
        const response = await this.post(`${endpoint}/changes`, body);
        return await this.handleResponse<Change>(response);
    }

    public static async fetchChange(changeId: string): Promise<Change> { 
        const response = await this.fetch(`${endpoint}/changes/${changeId}`);
        return await this.handleResponse<Change>(response);
    }

    public static async deleteChange(changeId: string): Promise<void> { 
        const response = await this.delete(`${endpoint}/changes/${changeId}`);
        return await this.handleVoidResponse(response);
    }
}