import { TextInput } from '@frontend/basic-forms';
import { lazy } from 'react';

import { RestrictionValueSelectProps } from './restriction-value-select.component';

const AccountSelect = lazy(() => import('@frontend/account/common').then((module) => ({ default: module.AccountSelect })));
const ProductSelect = lazy(() => import('@frontend/product/common').then((module) => ({ default: module.ProductSelect })));
const WorkflowSelect = lazy(() => import('@frontend/workflow/common').then((module) => ({ default: module.WorkflowSelect })));
const ProductStateSelect = lazy(() => import('@frontend/product/components').then((module) => ({ default: module.ProductStateSelect })));
const ProductTypeSelect = lazy(() => import('@frontend/product/components').then((module) => ({ default: module.ProductTypeSelect })));
const ProductCategorySelect = lazy(() => import('@frontend/product-category/common').then((module) => ({ default: module.ProductCategorySelect })));

const useRestrictionValueSelect = (props: RestrictionValueSelectProps) => {
    switch (props.restriction_key) {
        case 'id': {
            switch (props.type) {
                case 'product':
                    return (
                        <ProductSelect
                            required={props.required}
                            dispatch={props.dispatch}
                            onChange={props.onChange}
                            submitted={props.submitted}
                            value={props.value}
                            className={props.className}
                        />
                    );
                default:
                    return null;
            }
        }
        case 'state': {
            switch (props.type) {
                case 'product':
                    return (
                        <ProductStateSelect
                            required={props.required}
                            dispatch={props.dispatch}
                            onChange={(v) => props.onChange(v?.value)}
                            submitted={props.submitted}
                            className={props.className}
                            value={props.value}
                        />
                    );

                default:
                    return null;
            }
        }
        case 'type': {
            switch (props.type) {
                case 'product':
                    return (
                        <ProductTypeSelect
                            required={props.required}
                            submitted={props.submitted}
                            value={props.value}
                            onChange={(v: any) => props.onChange(v?.value)}
                            className={props.className}
                        />
                    );
                default:
                    return null;
            }
        }
        case 'workflow_id':
            return (
                <WorkflowSelect
                    required={props.required}
                    dispatch={props.dispatch}
                    onChange={(v) => props.onChange(v ?? undefined)}
                    submitted={props.submitted}
                    className={props.className}
                    value={props.value ?? null}
                />
            );
        case 'account_id':
            return (
                <AccountSelect
                    required={props.required}
                    dispatch={props.dispatch}
                    onChange={(v) => props.onChange(v ?? undefined)}
                    submitted={props.submitted}
                    className={props.className}
                    value={props.value ?? null}
                />
            );
        case 'category_id':
            return (
                <ProductCategorySelect
                    dispatch={props.dispatch}
                    onChange={(v) => props.onChange(v ? v : undefined)}
                    submitted={props.submitted}
                    className={props.className}
                    value={props.value}
                    required={props.required}
                />
            );
        default:
            return (
                <TextInput
                    required={props.required}
                    label={props.restriction_key}
                    submitted={props.submitted}
                    onChange={props.onChange}
                    className={props.className}
                    value={props.value}
                />
            );
    }
};

export default useRestrictionValueSelect;
