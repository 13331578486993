import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import {
    CreateProductCatalog,
    ProductCatalog,
    ProductCatalogListResponse,
    ProductCatalogQueryParams,
    UpdateProductCatalog
} from '@frontend/product-catalog/types';
import { ProductListResponse } from '@frontend/product/types';

const endpoint = '/product-api/v1';

export class ProductCatalogClient extends APIClient {
    public static async fetchProductCatalogs(
        queryParams?: ApiQueryParams<DefaultQueryParams | ProductCatalogQueryParams>
    ): Promise<ProductCatalogListResponse> {
        return await this.apiPaginated<ProductCatalogListResponse, DefaultQueryParams | ProductCatalogQueryParams>(`${endpoint}/catalogs`, queryParams);
    }

    public static async resolveProductCatalogs(productCatalogIds: string[]): Promise<ProductCatalogListResponse> {
        const response = await this.post(`${endpoint}/catalogs-resolve`, { ids: productCatalogIds });
        return await this.handleResponse<ProductCatalogListResponse>(response);
    }

    public static async deleteProductCatalogs(productCatalogIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/catalogs-delete`, { ids: productCatalogIds });
        return await this.handleVoidResponse(response);
    }

    public static async fetchAccountProductCatalogs(accountId: string, queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<ProductCatalogListResponse> {
        return await this.apiPaginated<ProductCatalogListResponse, DefaultQueryParams>(`${endpoint}/accounts/${accountId}/catalogs`, queryParams);
    }

    public static async postAccountProductCatalog(accountId: string, productCatalog: CreateProductCatalog): Promise<ProductCatalog> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/catalogs`, productCatalog);
        return await this.handleResponse<ProductCatalog>(response);
    }

    public static async fetchAccountProductCatalog(accountId: string, productCatalogId: string): Promise<ProductCatalog> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/catalogs/${productCatalogId}`);
        return await this.handleResponse<ProductCatalog>(response);
    }

    public static async patchAccountProductCatalog(accountId: string, productCatalogId: string, productCatalog: UpdateProductCatalog): Promise<ProductCatalog> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/catalogs/${productCatalogId}`, productCatalog);
        return await this.handleResponse<ProductCatalog>(response);
    }

    public static async deleteAccountProductCatalog(accountId: string, productCatalogId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/catalogs/${productCatalogId}`);
        return await this.handleVoidResponse(response);
    }

    // Products

    public static async postAccountProductCatalogProducts(accountId: string, productCatalogId: string, productIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/catalogs/${productCatalogId}/add-products`, { ids: productIds });
        return await this.handleVoidResponse(response);
    }

    public static async deleteAccountProductCatalogProducts(accountId: string, productCatalogId: string, productIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/catalogs/${productCatalogId}/remove-products`, { ids: productIds });
        return await this.handleVoidResponse(response);
    }

    public static async fetchAccountProductCatalogProducts(
        accountId: string,
        catalogId: string,
        queryParams?: ApiQueryParams<DefaultQueryParams | ProductCatalogQueryParams>
    ): Promise<ProductListResponse> {
        return await this.apiPaginated<ProductListResponse, DefaultQueryParams | ProductCatalogQueryParams>(
            `${endpoint}/accounts/${accountId}/catalogs/${catalogId}/products`,
            queryParams
        );
    }
}
