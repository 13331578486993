import { ClassType } from '@frontend/common';
import React from 'react';
import { FormattedMessage } from 'react-intl';


export enum TransactionStateName {
    TRANSACTION_CREATED = 'transaction_created',
    TRANSACTION_PROCESSING = 'transaction_processing',
    TRANSACTION_CANCELLED = 'transaction_cancelled',
    TRANSACTION_COMPLETED = 'transaction_completed'
}

export class TransactionState {
    static readonly TRANSACTION_CREATED = new TransactionState(
        TransactionStateName.TRANSACTION_CREATED,
        (
            <FormattedMessage
                id='TransactionState.created'
                description='The displayed value for the TransactionState created'
                defaultMessage='Transaction created'
            />
        )
    );
    static readonly TRANSACTION_PROCESSING = new TransactionState(
        TransactionStateName.TRANSACTION_PROCESSING,
        (
            <FormattedMessage
                id='TransactionState.processing'
                description='The displayed value for the TransactionState processing'
                defaultMessage='Transaction processing'
            />
        )
    );
    static readonly TRANSACTION_CANCELLED = new TransactionState(
        TransactionStateName.TRANSACTION_CANCELLED,
        (
            <FormattedMessage
                id='TransactionState.transaction_cancelled'
                description='The displayed value for the TransactionState transaction_cancelled'
                defaultMessage='Transaction cancelled'
            />
        )
    );
    static readonly TRANSACTION_COMPLETED = new TransactionState(
        TransactionStateName.TRANSACTION_COMPLETED,
        (
            <FormattedMessage
                id='TransactionState.transaction_completed'
                description='The displayed value for the TransactionState transaction_completed'
                defaultMessage='Transaction completed'
            />
        )
    );

    static readonly ALL = [
        TransactionState.TRANSACTION_CREATED,
        TransactionState.TRANSACTION_CANCELLED,
        TransactionState.TRANSACTION_COMPLETED,
        TransactionState.TRANSACTION_PROCESSING
    ];

    private constructor(public readonly value: TransactionStateName, public readonly displayName: React.ReactNode) {}

    static getByValue(action: TransactionStateName): TransactionState | undefined {
        return this.ALL.find((a) => a.value == action);
    }

    static getType(state: TransactionStateName): ClassType {
        switch (state) {
            case TransactionStateName.TRANSACTION_COMPLETED:
                return ClassType.SUCCESS;
            case TransactionStateName.TRANSACTION_CREATED:
            case TransactionStateName.TRANSACTION_PROCESSING:
                return ClassType.WARNING;
            case TransactionStateName.TRANSACTION_CANCELLED:
                return ClassType.DARK;
        }

    }
}
