import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React, { useMemo } from 'react';

import { DetailModalType } from '../../../badge-components/badge-template/badge-template.component';
import { DefaultModalProps, ModalType } from '../../default-modal/default-modal.component';

export const ID = 'detail-modal';

export interface Props extends DefaultModalProps {
    detailModalType: DetailModalType;
    id: string;
    modalWrapper: { [key: string]: (props: any) => JSX.Element };
    dispatch: ThunkDispatch<any, any, Action>;
}

/**
 * @deprecated
 * @param props
 * @returns
 */
export const ObjectDetailModal = (props: Props): JSX.Element => {
    const SpecificModalWrapper = useMemo(() => props.modalWrapper?.[props.detailModalType], [props.detailModalType]);

    return (
        <div id={ID}>
            {SpecificModalWrapper && (
                <SpecificModalWrapper
                    type={ModalType.PANE}
                    {...props}
                />
            )}
        </div>
    );
};
