import { Logger } from '@frontend/Logger';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { postBasicAuth } from '@frontend/authentication-methods/redux';
import { BasicAuthStatus, CreateBasicAuth } from '@frontend/authentication-methods/types';
import { CreateBasicAuthFormProps } from './create-basic-auth-form.component';

interface FormValid {
    email: boolean;
    status: boolean;
    password: boolean;
}

interface ViewProps {
    formValid: FormValid;
    changeFormValid: React.Dispatch<React.SetStateAction<FormValid>>;
    formValues: CreateBasicAuth;
    changeFormValues: React.Dispatch<React.SetStateAction<CreateBasicAuth>>;
    submit: (e: any) => void;
    submitted: boolean;
}

const useCreateBasicAuthForm = (props: CreateBasicAuthFormProps): ViewProps => {
    const { userId } = useParams();
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const [formValid, changeFormValid] = useState<FormValid>({
        email: false,
        status: true,
        password: true
    });

    const [formValues, changeFormValues] = useState<CreateBasicAuth>({
        email: '',
        status: BasicAuthStatus.ACTIVE
    });

    const submit = (e: any) => {
        e.preventDefault();
        if (!userId) return;

        changeSubmitted(true);
        props.dispatch(postBasicAuth({ userId: userId, body: formValues }))
            .then(props.onClose)
            .catch(() => {
                Logger.error(`Failed to create basic auth for user with id ${userId}`, undefined, formValues);
            });
    };

    return {
        changeFormValid,
        changeFormValues,
        formValid,
        formValues,
        submit,
        submitted
    };
};

export default useCreateBasicAuthForm;
