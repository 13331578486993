import { EntityType } from '@frontend/common';
import { useResolver } from '@frontend/repository';
import { UserClient } from '@frontend/user/api';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

declare global {
    interface WindowEventMap {
        'useUser:request': CustomEvent<string>;
    }
}

const REQUEST_USER_EVENT = 'useUser:request';
const STORAGE_KEY = 'ACS_REQ';

export function userRequest(userId: string) {
    window.dispatchEvent(new CustomEvent(REQUEST_USER_EVENT, { detail: userId }));
}

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
}

export function UserResolver(props: Props) {
    useResolver(STORAGE_KEY, REQUEST_USER_EVENT, EntityType.USER, (ids) => UserClient.resolveUsers(ids), props.dispatch);
    return <></>;
}
