import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

import { DetailModalType } from '../../badge-components/badge-template/badge-template.component';
import useDetailModalContainer from './detail-modal-container.controller';
import { ObjectDetailModal } from './object-detail-modal/object-detail-modal.component';

export const ID = 'detail-modal-container';

export interface Props {
    modalWrapper: { [key: string]: (props: any) => JSX.Element };
    dispatch: ThunkDispatch<any, any, Action>;
}

/**
 * @deprecated
 * @param props
 * @returns
 */
export const DetailModalContainer = (props: Props): JSX.Element => {
    const viewProps = useDetailModalContainer();
    const prefixRegex = /^([^-]*)-/;
    return (
        <div id={ID}>
            {viewProps.modals.map((modal) => (
                <ObjectDetailModal
                    key={modal}
                    id={modal.slice(prefixRegex.exec(modal)?.[0].length)}
                    detailModalType={modal.split('-')[0] as DetailModalType}
                    handleClose={viewProps.handleClose}
                    show
                    modalWrapper={props.modalWrapper}
                    dispatch={props.dispatch}
                />
            ))}
        </div>
    );
};
