import React from 'react';
import { FormattedMessage } from 'react-intl';

export const Buttons = {
    CANCEL: (
        <FormattedMessage
            id='Buttons.CANCEL'
            description='Cancel text on a button'
            defaultMessage='Cancel'
        />
    ),
    SUBMIT: (
        <FormattedMessage
            id='Buttons.SUBMIT'
            description='Submit text on a button'
            defaultMessage='Submit'
        />
    ),
    DELETE: (
        <FormattedMessage
            id='Buttons.DELETE'
            description='Delete text on a button'
            defaultMessage='Delete'
        />
    ),
    UPDATE: (
        <FormattedMessage
            id='Buttons.UPDATE'
            description='Update text on a button'
            defaultMessage='Update'
        />
    ),
    OPEN: (
        <FormattedMessage
            id='Buttons.OPEN'
            description='Open text on a button'
            defaultMessage='Open'
        />
    ),
    CLOSE: (
        <FormattedMessage
            id='Buttons.CLOSE'
            description='Close text on a button'
            defaultMessage='Close'
        />
    ),
    CHANGE_STATE: (
        <FormattedMessage
            id='Buttons.CHANGE_STATE'
            description='Change state text on a button'
            defaultMessage='Change state'
        />
    ),
    ADD_STOCK: (
        <FormattedMessage
            id='Buttons.ADD_STOCK'
            description='Add stock text on a button'
            defaultMessage='Add stock'
        />
    ),
    CONFIRM: (
        <FormattedMessage
            id='Buttons.CONFIRM'
            description='Confirm text on a button'
            defaultMessage='Confirm'
        />
    ),
    IMPORT: (
        <FormattedMessage
            id='Buttons.IMPORT'
            description='Import text on a button'
            defaultMessage='Import'
        />
    ),
    EXPORT: (
        <FormattedMessage
            id='Buttons.EXPORT'
            description='Export text on a button'
            defaultMessage='Export'
        />
    )
};
