import { UserInterfaceType } from '@frontend/user-interface/types';
import React from 'react';
import { LuNfc } from 'react-icons/lu';

const ScanBadge = () => {
    return (
        <div
            id={UserInterfaceType.SCAN_BADGE}
            className='d-flex flex-column align-items-center justify-content-center m-3'>
            <h3 className='pb-4'>Please first scan your badge to continue.</h3>
            <h3>
                <LuNfc size={300} />
            </h3>
        </div>
    );
};

export default ScanBadge;
