import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Rakinda } from '../../rakinda';
import { RakindaState, fetchRakinda } from '../../redux/rakinda-slice';
import { RakindaDetailModalWrapperProps } from './rakinda-detail-modal-wrapper.component';

interface ViewProps {
    rakinda?: Rakinda;
}

const useRakindaDetailModalWrapper = (props: RakindaDetailModalWrapperProps): ViewProps => {
    const rakindas: RakindaState = useSelector((state: any) => state.rakindas);
    const [rakinda, changeRakinda] = useState<Rakinda | undefined>(undefined);

    useEffect(() => {
        const found = rakindas.unordered.find((r) => r.id == props.id);
        if (found) changeRakinda(found);
        else props.dispatch(fetchRakinda(props.id));
    }, [props.id, rakindas]);

    return { rakinda };
};

export default useRakindaDetailModalWrapper;
