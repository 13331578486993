import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { ProductCatalogClient } from '@frontend/product-catalog/api';
import { ProductCatalog, ProductCatalogListResponse, ProductCatalogQueryParams } from '@frontend/product-catalog/types';
import { ProductListResponse } from '@frontend/product/types';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

interface ProductCatalogState {
    unordered: ProductCatalog[];
    productCatalogs: ProductCatalogListResponse | null;
    productCatalogsByAccount: { [accountId: string]: ProductCatalogListResponse } | null;
    productCatalogProducts: { [accountId: string]: { [productCatalogId: string]: ProductListResponse } } | null;
    status: SliceStatus;
}

const initialState: ProductCatalogState = {
    unordered: [],
    productCatalogs: null,
    productCatalogsByAccount: null,
    productCatalogProducts: null,
    status: SliceStatus.IDLE
};

const productCatalogSlice = createSlice({
    name: 'productCatalogSlice',
    initialState,
    reducers: {
        seedProductCatalogs(state, action: PayloadAction<ProductCatalog[]>) {
            state.unordered = [...state.unordered.filter((pc) => action.payload.find((c) => c.id == pc.id) == undefined), ...action.payload];
        },
        udpateProductCatalog(state, action: PayloadAction<ProductCatalog>) {
            state.unordered = state.unordered.map((catalog) => (catalog.id == action.payload.id ? action.payload : catalog));
            if (state.productCatalogs) {
                state.productCatalogs.results = state.productCatalogs.results.map((catalog) => (catalog.id == action.payload.id ? action.payload : catalog));
            }
        },
        addProductCatalog(state, action: PayloadAction<ProductCatalog>) {
            state.unordered.push(action.payload);
            if (state.productCatalogs) {
                state.productCatalogs.count++;
                state.productCatalogs.results = [action.payload].concat(state.productCatalogs.results);
            }
        },
        removeProductCatalog(state, action: PayloadAction<string>) {
            state.unordered = state.unordered.filter((catalog) => catalog.id != action.payload);
            if (state.productCatalogs) {
                state.productCatalogs.count--;
                state.productCatalogs.results = state.productCatalogs.results.filter((catalog) => catalog.id != action.payload);
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProductCatalogs.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchProductCatalogs.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                const startPos = toNumber(action.meta.arg.index) * toNumber(action.meta.arg.size);
                if (state.productCatalogs == null) {
                    state.productCatalogs = { ...action.payload, results: new Array(action.payload.count) };
                    state.productCatalogs.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.productCatalogs.results.length != action.payload.count) {
                        state.productCatalogs.count = action.payload.count;
                        state.productCatalogs.results = new Array(action.payload.count);
                    }
                    state.productCatalogs.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }

                state.unordered = [
                    ...state.unordered.filter((pc) => action.payload.results.find((c) => c.id == pc.id) == undefined),
                    ...action.payload.results
                ];
            })
            .addCase(fetchProductCatalogProducts.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchProductCatalogProducts.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                const { accountId, productCatalogId } = action.meta.arg;
                const startPos = toNumber(action.meta.arg.queryParams.index) * toNumber(action.meta.arg.queryParams.size);

                if (!state.productCatalogProducts) {
                    state.productCatalogProducts = { [accountId]: { [productCatalogId]: { ...action.payload, results: new Array(action.payload.count) } } };
                    state.productCatalogProducts[accountId][productCatalogId].results.splice(
                        startPos,
                        action.payload.results.length,
                        ...action.payload.results
                    );
                } else if (state.productCatalogProducts && !state.productCatalogProducts[accountId]) {
                    state.productCatalogProducts[accountId] = { [productCatalogId]: { ...action.payload, results: new Array(action.payload.count) } };
                    state.productCatalogProducts[accountId][productCatalogId].results.splice(
                        startPos,
                        action.payload.results.length,
                        ...action.payload.results
                    );
                } else if (
                    state.productCatalogProducts &&
                    state.productCatalogProducts[accountId] &&
                    !state.productCatalogProducts[accountId][productCatalogId]
                ) {
                    state.productCatalogProducts[accountId][productCatalogId] = { ...action.payload, results: new Array(action.payload.count) };
                    state.productCatalogProducts[accountId][productCatalogId].results.splice(
                        startPos,
                        action.payload.results.length,
                        ...action.payload.results
                    );
                } else if (
                    state.productCatalogProducts &&
                    state.productCatalogProducts[accountId] &&
                    state.productCatalogProducts[accountId][productCatalogId]
                ) {
                    if (state.productCatalogProducts[accountId][productCatalogId].results.length != action.payload.count) {
                        state.productCatalogProducts[accountId][productCatalogId].count = action.payload.count;
                        state.productCatalogProducts[accountId][productCatalogId].results = new Array(action.payload.count);
                    }
                    state.productCatalogProducts[accountId][productCatalogId].results.splice(
                        startPos,
                        action.payload.results.length,
                        ...action.payload.results
                    );
                }
            });
    }
});

export const fetchProductCatalogs = createAsyncThunk<ProductCatalogListResponse, ApiQueryParams<DefaultQueryParams | ProductCatalogQueryParams>>(
    'fetchProductCatalogs',
    async (queryParams: ApiQueryParams<DefaultQueryParams | ProductCatalogQueryParams>, { rejectWithValue }) => {
        try {
            return await ProductCatalogClient.fetchProductCatalogs(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchProductCatalogProducts = createAsyncThunk<
    ProductListResponse,
    { accountId: string; productCatalogId: string; queryParams: ApiQueryParams<DefaultQueryParams | ProductCatalogQueryParams> }
>(
    'fetchProductCatalogProducts',
    async (variables: { accountId: string; productCatalogId: string; queryParams: ApiQueryParams<DefaultQueryParams> }, { rejectWithValue }) => {
        try {
            return await ProductCatalogClient.fetchAccountProductCatalogProducts(variables.accountId, variables.productCatalogId, variables.queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const productCatalogStore = { productCatalogs: productCatalogSlice.reducer };
export const { seedProductCatalogs, addProductCatalog, removeProductCatalog, udpateProductCatalog } = productCatalogSlice.actions;
