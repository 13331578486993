import { parseObject } from '@frontend/common';
import { useState } from 'react';

import { ContactClient } from '@frontend/contact/api';
import { UpdateContact } from '@frontend/contact/types';
import { UpdateContactFormProps } from './update-contact-form.component';

interface ViewProps {
    submitted: boolean;
    formValues: UpdateContact;
    formValid: FormValid;
    onSubmit: (e: any) => void;
    changeFormValues: React.Dispatch<React.SetStateAction<UpdateContact>>;
    changeFormValid: React.Dispatch<React.SetStateAction<FormValid>>;
}

interface FormValid {
    alias?: boolean;
    first_name?: boolean;
    last_name?: boolean;
    email?: boolean;
    mobile?: boolean;
    language?: boolean;
}

const useUpdateContactForm = (props: UpdateContactFormProps): ViewProps => {
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const [formValues, changeFormValues] = useState<UpdateContact>({
        alias: props.contact.alias,
        first_name: props.contact.first_name,
        last_name: props.contact.last_name,
        email: props.contact.email,
        mobile: props.contact.mobile,
        language: props.contact.language
    });

    const [formValid, changeFormValid] = useState<FormValid>({
        alias: true,
        first_name: true,
        last_name: true,
        email: true,
        mobile: true,
        language: formValues.language !== null
    });

    const onSubmit = (e: any) => {
        e.preventDefault();
        changeSubmitted(true);
        const body = parseObject(formValues);
        ContactClient.patchContact(body, props.contact.id);
    };

    return {
        changeFormValid,
        changeFormValues,
        formValid,
        formValues,
        onSubmit,
        submitted
    };
};

export default useUpdateContactForm;
