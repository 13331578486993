import { Spinner } from '@frontend/elements';
import { ReturnProductData, UserInterfaceImplementation, UserInterfaceType } from '@frontend/user-interface/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import { VscError } from 'react-icons/vsc';

import SpotLayoutSlotSelect from '../../spot-layout-slot-select/spot-layout-slot-select.component';
import TempImageLoader from '../temp-image-loader';
import useReturnProduct from './return-product.controller';

export interface ReturnProductProps {
    dispatch: ThunkDispatch<any, any, Action>;
    userInterface: UserInterfaceImplementation<ReturnProductData>;
}

const ReturnProduct = (props: ReturnProductProps) => {
    const viewProps = useReturnProduct(props);

    if (viewProps.slot === null) {
        return (
            <SpotLayoutSlotSelect
                dispatch={props.dispatch}
                slot={viewProps.slot}
                callback={viewProps.changeSlot}
            />
        );
    }

    return (
        <div
            id={UserInterfaceType.RETURN_PRODUCT}
            className='d-flex flex-column align-items-center justify-content-center m-3'>
            {viewProps.product === undefined ? (
                <>
                    <h3 className='pb-4'>Searching for your product.</h3>
                    <Spinner />
                </>
            ) : viewProps.product === null ? (
                <>
                    <VscError
                        className='text-danger'
                        size={300}
                    />
                    <h3 className='pb-4'>Oops, we could not find your product. We&apos;re sorry for the inconvenience.</h3>
                </>
            ) : (
                <>
                    <TempImageLoader
                        product={viewProps.product}
                        className='w-md-50 w-xl-25'
                    />
                    {viewProps.product.name && (
                        <h3 className='pb-4'>
                            <span>
                                you are about to return product <span className='text-info'>{viewProps.product.name}</span>!
                            </span>
                        </h3>
                    )}
                    <span className='d-flex flex-row w-100'>
                        <button
                            className='w-50 mx-1 btn btn-primary btn-lg'
                            disabled={viewProps.disabled}
                            onClick={viewProps.submit}>
                            Return
                        </button>
                    </span>
                </>
            )}
        </div>
    );
};

export default ReturnProduct;
