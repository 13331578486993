import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';

import { IntegrationTest, IntegrationTestListResponse, IntegrationTestOverviewListResponse } from '../integration-test';

const endpoint = '/integration-test-api/v1';
export class IntegrationTestClient extends APIClient {
    public static async fetchIntegrationTests(queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<IntegrationTestListResponse> {
        return this.apiPaginated<IntegrationTestListResponse, DefaultQueryParams>(`${endpoint}/integration-tests`, queryParams);
    }

    public static async fetchIntegrationTest(integrationTestId: string): Promise<IntegrationTest> {
        const response = await this.fetch(`${endpoint}/integration-tests/${integrationTestId}`);
        return await this.handleResponse<IntegrationTest>(response);
    }

    public static async deleteIntegrationTest(integrationTestId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/integration-tests/${integrationTestId}`);
        return await this.handleVoidResponse(response);
    }

    public static async fetchIntegrationTestsOverview(): Promise<IntegrationTestOverviewListResponse> {
        const res = await this.fetch(`${endpoint}/integration-tests-overview`);
        return this.handleResponse<IntegrationTestOverviewListResponse>(res);
    }
}
