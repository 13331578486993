import { EmailInput } from '@frontend/basic-forms';
import { WorkflowVariable } from '@frontend/workflow-variable/types';
import React from 'react';

import useContactIdEmail from './contact-id-email.controller';

const ID = 'contact-id-email';
export interface ContactIdEmailProps {
    callback: (variable: WorkflowVariable) => void;
    variable: WorkflowVariable;
}

const ContactIdEmail = (props: ContactIdEmailProps) => {
    const viewProps = useContactIdEmail(props);

    return (
        <div
            id={ID}
            className='d-flex flex-column justify-content-between h-100 p-1'>
            <form
                onSubmit={viewProps.onSubmit}
                className='d-flex flex-row'>
                <EmailInput
                    id={ID + '-EmailInput'}
                    className='w-100 m-1'
                    label='Email'
                    value={viewProps.email}
                    onChange={viewProps.changeEmail}
                    isValidCallback={viewProps.changeFormValid}
                    submitted={viewProps.submitted}
                />
                <button
                    type='submit'
                    className='btn btn-primary btn-lg w-100'
                    disabled={!viewProps.formValid || viewProps.submitted}>
                    Submit
                </button>
            </form>
        </div>
    );
};

export default ContactIdEmail;
