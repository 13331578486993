import { toNumber } from 'lodash';
import { useEffect, useState } from 'react';

import { getClassName } from '../input-class';
import { NumberInputProps } from './number-input.component';

interface ViewProps {
    valid: boolean;
    touched: boolean;
    inputClass: string;
    error: NumberInputError;
    changeTouched: React.Dispatch<React.SetStateAction<boolean>>;
    isValid: () => boolean;
}

export enum NumberInputError {
    NONE,
    MIN_LENGTH,
    MAX_LENGTH,
    MIN_MAX_LENGTH,
    REQUIRED
}

const useNumberInput = (props: NumberInputProps): ViewProps => {
    const [touched, changeTouched] = useState<boolean>(false);
    const [valid, changeValid] = useState<boolean>(false);
    const [error, changeError] = useState<NumberInputError>(NumberInputError.NONE);

    const isValid = (): boolean => {
        let error = NumberInputError.NONE;
        if (props.required === true && (props.value === '' || props.value === undefined)) error = NumberInputError.REQUIRED;
        if ((props.minLength && !props.value) || (props.minLength && props.value && props.value.toString().length < props.minLength)) {
            error = NumberInputError.MIN_LENGTH;
        }
        if ((props.maxLength && !props.value) || (props.maxLength && props.value && props.value.toString().length > props.maxLength)) {
            error = NumberInputError.MAX_LENGTH;
        }
        if (
            (props.minLength && props.maxLength && !props.value) ||
            (props.minLength &&
                props.maxLength &&
                props.value &&
                (props.value.toString().length < props.minLength || props.value?.toString().length > props.maxLength))
        ) {
            error = NumberInputError.MIN_MAX_LENGTH;
        }
        if (props.isClearable && isNaN(toNumber(props.value))) {
            error = NumberInputError.NONE;
        }
        changeError(error);
        changeValid(error === NumberInputError.NONE);
        return error === NumberInputError.NONE;
    };

    useEffect(() => {
        if (props.isValidCallback) {
            props.isValidCallback(isValid());
        } else isValid();
    }, [props.value, props.valid]);

    useEffect(() => {
        if (props.submitted) {
            !touched && changeTouched(true);
        }
    }, [props.submitted]);
    return {
        valid,
        touched,
        inputClass: getClassName(touched, valid, props.value?.toString()),
        error,
        isValid,
        changeTouched
    };
};

export default useNumberInput;
