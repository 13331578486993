import { useAppSelector } from '@frontend/common';
import { TransactionWorkflowClient } from '@frontend/transaction/api';
import { UserInterfaceButtonAction, getIconByUIButtonType } from '@frontend/user-interface-button/types';
import { TransactionWorkflowStepTrigger } from '@frontend/workflow/types';
import { useMemo } from 'react';
import { IconType } from 'react-icons';
import { useSelector } from 'react-redux';

import { changeView, navigationStore } from '../../../redux/user-interface-navigation-slice';
import { UserInterfaceButtonElementProps } from './button-element.component';

interface ViewProps {
    onClick: () => void;
    icon: IconType | null;
}

const useUserInterfaceButtonElement = (props: UserInterfaceButtonElementProps): ViewProps => {
    const navigationState = useAppSelector(useSelector, navigationStore);
    const icon = useMemo(() => getIconByUIButtonType(props.button.type), [props.button.type]);

    const onClick = () => {
        switch (props.button.action) {
            case UserInterfaceButtonAction.NAVIGATION:
                props.dispatch(changeView({ view: props.button.next_user_interface_id!, cache: null }));
                break;
            case UserInterfaceButtonAction.TRIGGER:
                if (navigationState.cache === null) break;
                if (navigationState.cache.spot_id && navigationState.cache.transaction_id) {
                    TransactionWorkflowClient.postTriggerTransactionState(navigationState.cache.spot_id, navigationState.cache.transaction_id, {
                        trigger: TransactionWorkflowStepTrigger.TRANSACTION_USER_INTERFACE_BUTTON_CLICKED.value,
                        source: null
                    });
                }
                break;
            case UserInterfaceButtonAction.DISPLAY:
            default:
                break;
        }
    };

    return {
        onClick,
        icon
    };
};

export default useUserInterfaceButtonElement;
