import { CheckboxInput } from '@frontend/basic-forms';
import { WorkflowVariable } from '@frontend/workflow/types';
import React from 'react';

import { TransactionVariable } from '../../../../transaction-variables';

const ID = 'bool-field-input';
export interface BoolFieldInputProps {
    submitted: boolean;
    variable: TransactionVariable | WorkflowVariable;
    onChange: (variable: TransactionVariable | WorkflowVariable) => void;
}

const BoolFieldInput = (props: BoolFieldInputProps) => {
    return (
        <CheckboxInput
            id={ID}
            label={props.variable.name}
            submitted={props.submitted}
            value={typeof props.variable.value === 'boolean' ? props.variable.value : undefined}
            onChange={(value) => {
                props.onChange({ ...props.variable, value: value.toString() });
            }}
        />
    );
};

export default BoolFieldInput;
