import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isArray, toNumber } from 'lodash';

import { ModuleClient } from '@frontend/module/api';
import { Module, ModuleListResponse, ModuleQueryParams } from '@frontend/module/types';

export interface ModuleState {
    unordered: Module[];
    modules: ModuleListResponse | null;
    spotModules: { [spotId: string]: ModuleListResponse } | null;
    status: SliceStatus;
}

const initialState: ModuleState = {
    unordered: [],
    modules: null,
    spotModules: null,
    status: SliceStatus.INIT
};

const moduleSlice = createSlice({
    name: 'modules',
    initialState,
    reducers: {
        seedModules(state, action: PayloadAction<Module[]>) {
            state.unordered = [...state.unordered.filter((module) => action.payload.find((m) => m.id == module.id) == undefined), ...action.payload];
        },
        updateModule(state, action: PayloadAction<Module>) {
            state.unordered = state.unordered.map((module) => (module.id == action.payload.id ? action.payload : module));
            if (state.modules) {
                state.modules.results = state.modules.results.map((module) => (module.id == action.payload.id ? action.payload : module));
            }
        },
        addModule(state, action: PayloadAction<Module>) {
            state.unordered.push(action.payload);
            if (state.modules) {
                state.modules.count++;
                state.modules.results = [action.payload].concat(state.modules.results);
            }
        },
        removeModule(state, action: PayloadAction<string>) {
            state.unordered = state.unordered.filter((module) => module.id != action.payload);
            if (state.modules) {
                state.modules.count--;
                state.modules.results = state.modules.results.filter((module) => module.id != action.payload);
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchModules.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchModules.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                const startPos = toNumber(action.meta.arg.size) * toNumber(action.meta.arg.index);
                if (state.modules == null) {
                    state.modules = {
                        ...action.payload,
                        results: new Array(action.payload.count)
                    };
                    state.modules.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.modules.results.length !== action.payload.count) {
                        state.modules.count = action.payload.count;
                        state.modules.results = new Array(action.payload.count);
                    }
                    state.modules.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }

                if (action.meta.arg.spot_id && !isArray(action.meta.arg.spot_id)) {
                    if (state.spotModules == null) {
                        state.spotModules = { [action.meta.arg.spot_id]: { ...action.payload, results: new Array(action.payload.count) } };
                        state.spotModules[action.meta.arg.spot_id].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    } else if (state.spotModules[action.meta.arg.spot_id] == undefined) {
                        state.spotModules[action.meta.arg.spot_id] = { ...action.payload, results: new Array(action.payload.count) };
                        state.spotModules[action.meta.arg.spot_id].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    } else {
                        if (state.spotModules[action.meta.arg.spot_id].results.length !== action.payload.count) {
                            state.spotModules[action.meta.arg.spot_id].count = action.payload.count;
                            state.spotModules[action.meta.arg.spot_id].results = new Array(action.payload.count);
                        }
                        state.spotModules[action.meta.arg.spot_id].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    }
                }
                state.unordered = [
                    ...state.unordered.filter((s) => action.payload.results.find((res) => res.id == s.id) == undefined),
                    ...action.payload.results
                ];
            })
            .addCase(fetchSpotModules.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchSpotModules.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                const startPos = toNumber(action.meta.arg.queryParams.size) * toNumber(action.meta.arg.queryParams.index);
                if (state.spotModules == null) {
                    state.spotModules = { [action.meta.arg.spotId]: { ...action.payload, results: new Array(action.payload.count) } };
                    state.spotModules[action.meta.arg.spotId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else if (state.spotModules[action.meta.arg.spotId] == undefined) {
                    state.spotModules[action.meta.arg.spotId] = { ...action.payload, results: new Array(action.payload.count) };
                    state.spotModules[action.meta.arg.spotId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.spotModules[action.meta.arg.spotId].results.length !== action.payload.count) {
                        state.spotModules[action.meta.arg.spotId].count = action.payload.count;
                        state.spotModules[action.meta.arg.spotId].results = new Array(action.payload.count);
                    }
                    state.spotModules[action.meta.arg.spotId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.unordered = [
                    ...state.unordered.filter((s) => action.payload.results.find((res) => res.id == s.id) == undefined),
                    ...action.payload.results
                ];
            })
            .addCase(fetchSpotModule.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchSpotModule.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                if (!state.spotModules) {
                    state.spotModules = { [action.meta.arg.spotId]: { count: 1, results: [action.payload] } };
                } else if (!state.spotModules[action.meta.arg.spotId]) {
                    state.spotModules[action.meta.arg.spotId] = { count: 1, results: [action.payload] };
                } else {
                    const found = state.spotModules[action.meta.arg.spotId].results.find((s) => s.id == action.payload.id);
                    if (!found) state.spotModules[action.meta.arg.spotId].results.push(action.payload);
                    else
                        state.spotModules[action.meta.arg.spotId].results.splice(
                            state.spotModules[action.meta.arg.spotId].results.indexOf(found),
                            1,
                            action.payload
                        );
                }
                state.unordered = [...state.unordered.filter((s) => action.payload.id != s.id), action.payload];
            });
    }
});

export const fetchModules = createAsyncThunk<ModuleListResponse, ApiQueryParams<DefaultQueryParams | ModuleQueryParams>>(
    'fetchModules',
    async (queryParams: ApiQueryParams<DefaultQueryParams | ModuleQueryParams>, { rejectWithValue }) => {
        try {
            return await ModuleClient.fetchModules(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchSpotModules = createAsyncThunk<ModuleListResponse, { spotId: string; queryParams: ApiQueryParams<DefaultQueryParams | ModuleQueryParams> }>(
    'fetchSpotModules',
    async (variables: { spotId: string; queryParams: ApiQueryParams<DefaultQueryParams | ModuleQueryParams> }, { rejectWithValue }) => {
        try {
            return await ModuleClient.fetchSpotModules(variables.spotId, variables.queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchSpotModule = createAsyncThunk<Module, { spotId: string; moduleId: string }>(
    'fetchSpotModule',
    async (params: { spotId: string; moduleId: string }, { rejectWithValue }) => {
        try {
            return await ModuleClient.fetchSpotModule(params.spotId, params.moduleId);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const moduleStore = { modules: moduleSlice.reducer };
export const { seedModules, updateModule, removeModule, addModule } = moduleSlice.actions;
