import { LanguageSelect, NotificationChannelSelect } from '@frontend/basic-forms';
import { ClassType } from '@frontend/common';
import { ModalFooter } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';
import { FaPlus } from 'react-icons/fa';

import useCreateNotificationContent from './create-notification-content.controller';
import React from 'react';

const ID = 'create-notification-content';
export interface CreateNotificationContentProps {
    onClose: () => void;
}

const CreateNotificationContent = (props: CreateNotificationContentProps) => {
    const viewProps = useCreateNotificationContent(props);

    return (
        <div id={ID}>
            <form onSubmit={viewProps.onSubmit}>
                <div className='modal-header'>
                    <h4>{CommonMessage.FORMS.CREATE_OBJECT('Notification Content')}</h4>
                </div>
                <div className='modal-body overflow-y-unset'>
                    <div className='d-flex flex-row'>
                        <LanguageSelect
                            onChange={(e) => {
                                if (e != null) {
                                    viewProps.changeFormValues({ ...viewProps.formValues, language: e.value });
                                }
                            }}
                            value={viewProps.formValues.language}
                            submitted={viewProps.submitted}
                            className='w-50 m-1'
                            required
                        />
                        <NotificationChannelSelect
                            onChange={(e) => {
                                if (e != null) {
                                    viewProps.changeFormValues({ ...viewProps.formValues, channel: e.value });
                                }
                            }}
                            value={viewProps.formValues.channel}
                            submitted={viewProps.submitted}
                            className='w-50 m-1'
                            required
                        />
                    </div>
                </div>
                <ModalFooter
                    justifyContent='between'
                    buttons={[
                        {
                            hide: false,
                            text: CommonMessage.BUTTONS.CANCEL,
                            type: ClassType.SECONDARY,
                            onClick: (e) => {
                                e?.preventDefault();
                                props.onClose();
                            }
                        },
                        {
                            hide: false,
                            text: CommonMessage.BUTTONS.SUBMIT,
                            type: ClassType.SUCCESS,
                            icon: FaPlus,
                            buttonType: 'submit'
                        }
                    ]}
                />
            </form>
        </div>
    );
};

export default CreateNotificationContent;
