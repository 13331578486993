import { ClassType } from '@frontend/common';
import { ConfirmationModal, DefaultModal, HorizontalButtonGroup } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';
import { Table } from '@frontend/table';
import { User } from '@frontend/user/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import { FaPlus } from 'react-icons/fa';

import CreateBasicAuthForm from '../forms/create-basic-auth-form/create-basic-auth-form.component';
import UpdateBasicAuthForm from '../forms/update-basic-auth-form/update-basic-auth-form.component';
import BasicAuthElement from './basic-auth-element';
import useBasicAuthList from './basic-auth-list.controller';

export interface BasicAuthListProps {
    user: User;
    dispatch: ThunkDispatch<any, any, Action>;
}

export const BasicAuthList = (props: BasicAuthListProps) => {
    const viewProps = useBasicAuthList(props);

    return (
        <>
            <div className='card mt-3'>
                <div className='card-header d-flex justify-content-between'>
                    <h4>Basic Auths</h4>
                    <HorizontalButtonGroup
                        direction='left'
                        buttons={[
                            {
                                text: CommonMessage.FORMS.CREATE_OBJECT('Basic Auth'),
                                icon: FaPlus,
                                type: ClassType.PRIMARY,
                                onClick: () => viewProps.changeShowCreateModal(true)
                            }
                        ]}
                    />
                </div>
                <div className='card-body'>
                    <Table
                        RowElement={(props) =>
                            BasicAuthElement({
                                ...props,
                                onDelete: (e) => {
                                    viewProps.changeShowDeleteModal(true);
                                    viewProps.changeSelectedEntity(e);
                                },
                                onUpdate: (e) => {
                                    viewProps.changeShowUpdateModal(true);
                                    viewProps.changeSelectedEntity(e);
                                }
                            })
                        }
                        columns={viewProps.columns}
                        data={viewProps.data}
                        pageCount={viewProps.pageCount}
                        page={viewProps.changePage}
                        pageSize={viewProps.changePageSize}
                    />
                </div>
            </div>
            <DefaultModal
                handleClose={() => viewProps.changeShowCreateModal(false)}
                show={viewProps.showCreateModal}>
                <CreateBasicAuthForm
                    user={props.user}
                    onClose={() => viewProps.changeShowCreateModal(false)}
                    dispatch={props.dispatch}
                />
            </DefaultModal>
            {viewProps.selectedEntity && viewProps.showDeleteModal && (
                <ConfirmationModal
                    show={viewProps.selectedEntity && viewProps.showDeleteModal}
                    handleClose={() => {
                        viewProps.changeSelectedEntity(undefined);
                        viewProps.changeShowDeleteModal(false);
                    }}
                    message={`You are about to delete ${viewProps.selectedEntity.email} basic auth`}
                    onConfirm={viewProps.removeBasicAuth}
                    severity={ClassType.DANGER}
                />
            )}
            {viewProps.selectedEntity && viewProps.showUpdateModal && (
                <DefaultModal
                    handleClose={() => {
                        viewProps.changeSelectedEntity(undefined);
                        viewProps.changeShowUpdateModal(false);
                    }}
                    show={viewProps.selectedEntity && viewProps.showUpdateModal}>
                    <UpdateBasicAuthForm
                        basicAuth={viewProps.selectedEntity}
                        onClose={() => {
                            viewProps.changeSelectedEntity(undefined);
                            viewProps.changeShowUpdateModal(false);
                        }}
                        dispatch={props.dispatch}
                    />
                </DefaultModal>
            )}
        </>
    );
};
