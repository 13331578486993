import { accountRequest } from '@frontend/account/events';
import { Account } from '@frontend/account/types';
import { EntityType } from '@frontend/common';
import { DefaultModalProps, ModalType } from '@frontend/elements';
import { EventEntityType } from '@frontend/events/types';
import { ObjectDetailModal } from '@frontend/rendering/modal';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { lazy, useState } from 'react';

const EventTable = lazy(() => import('@frontend/events/components').then((module) => ({ default: module.EventTable })));
const AccountDetail = lazy(() => import('@frontend/account/components').then((module) => ({ default: module.AccountDetail })));

export interface AccountDetailModalProps extends DefaultModalProps {
    id: string;
    dispatch: ThunkDispatch<any, any, Action>;
}
//TODO uiteindelijk naar /accounts verplaatsen als de rest er is
const AccountDetailModal = (props: AccountDetailModalProps) => {
    const [account, changeAccount] = useState<Account | null>(null);
    return (
        <ObjectDetailModal<Account>
            type={ModalType.PANE}
            dispatch={props.dispatch}
            id={props.id}
            callback={changeAccount}
            objectType={EntityType.ACCOUNT}
            handleClose={props.handleClose}
            show={props.show}
            resolve={accountRequest}>
            {account && (
                <div className='d-flex flex-column justify-content-between align-items-between h-100'>
                    <div>
                        <AccountDetail account={account} />
                    </div>
                    <div>
                        <EventTable
                            entity_id={account.id}
                            entity_type={EventEntityType.ACCOUNT}
                            dispatch={props.dispatch}
                            limit={5}
                            hidePagination
                            hideActions
                            title
                        />
                    </div>
                </div>
            )}
        </ObjectDetailModal>
    );
};

export default AccountDetailModal;
