import React from 'react';
import { FaPhone } from 'react-icons/fa';

import usePhoneNumberBadge from './phone-number-badge.controller';

const ID = 'phone-number-badge';
export interface PhoneNumberBadgeProps {
    phone?: string;
}

export const PhoneNumberBadge = (props: PhoneNumberBadgeProps) => {
    const viewProps = usePhoneNumberBadge(props);

    if (viewProps.phoneNumber === '') return <>-</>;
    return (
        <a
            href={`tel: ${viewProps.phoneNumber}`}
            id={ID}>
            <span className='d-flex flex-row align-items-center'>
                <span className='text-success m-2'>
                    <FaPhone />
                </span>
                {viewProps.phoneNumber}
            </span>
        </a>
    );
};
