import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { MovementClient } from '@frontend/stock/api';
import { Movement, MovementListResponse } from '@frontend/stock/types';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

interface MovementState {
    unordered: Movement[];
    movements: MovementListResponse | null;
    movementBySpotByModuleBySlotList: { [spotId: string]: { [moduleId: string]: { [slotId: string]: MovementListResponse } } } | null;
    status: SliceStatus;
}

const initialState: MovementState = {
    unordered: [],
    movements: null,
    movementBySpotByModuleBySlotList: null,
    status: SliceStatus.INIT
};

const movementSlice = createSlice({
    name: 'movementSlice',
    initialState,
    reducers: {
        seedMovements(state, action: PayloadAction<Movement[]>) {
            state.unordered = [...state.unordered.filter((slot) => action.payload.find((s) => s.id == slot.id) == undefined), ...action.payload];
        },
        updateMovement(state, action: PayloadAction<Movement>) {
            state.unordered = state.unordered.map((s) => (s.id == action.payload.id ? action.payload : s));
            if (state.movements != null) {
                state.movements.results = state.movements.results.map((s) => (s.id == action.payload.id ? action.payload : s));
            }
            if (state.movementBySpotByModuleBySlotList?.[action.payload.spot_id]?.[action.payload.module_id]?.[action.payload.slot_id]) {
                state.movementBySpotByModuleBySlotList[action.payload.spot_id][action.payload.module_id][action.payload.slot_id].results =
                    state.movementBySpotByModuleBySlotList[action.payload.spot_id][action.payload.module_id][action.payload.slot_id].results.map((s) =>
                        s.id == action.payload.id ? action.payload : s
                    );
            }
        },
        addMovement(state, action: PayloadAction<Movement>) {
            state.unordered.push(action.payload);
            if (state.movements != null) {
                state.movements.count++;
                state.movements.results.push(action.payload);
            }
            if (state.movementBySpotByModuleBySlotList?.[action.payload.spot_id]?.[action.payload.module_id]?.[action.payload.slot_id]) {
                state.movementBySpotByModuleBySlotList[action.payload.spot_id][action.payload.module_id][action.payload.slot_id].count++;
                state.movementBySpotByModuleBySlotList[action.payload.spot_id][action.payload.module_id][action.payload.slot_id].results.splice(
                    0,
                    0,
                    action.payload
                );
            }
        },
        removeMovement(state, action: PayloadAction<string>) {
            state.unordered = state.unordered.filter((s) => s.id != action.payload);
            if (state.movements != null) {
                state.movements.count--;
                state.movements.results = state.movements.results.filter((s) => s.id != action.payload);
            }
            if (state.movementBySpotByModuleBySlotList) {
                Object.keys(state.movementBySpotByModuleBySlotList).forEach((key) => {
                    Object.keys(state.movementBySpotByModuleBySlotList![key]).forEach((modKey) => {
                        Object.keys(state.movementBySpotByModuleBySlotList![key][modKey]).forEach((slotKey) => {
                            state.movementBySpotByModuleBySlotList![key][modKey][slotKey].count--;
                            state.movementBySpotByModuleBySlotList![key][modKey][slotKey].results = state.movementBySpotByModuleBySlotList![key][modKey][
                                slotKey
                            ].results.filter((s) => s.id != action.payload);
                        });
                    });
                });
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMovements.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchMovements.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                const startPos = toNumber(action.meta.arg.size) * toNumber(action.meta.arg.index);
                if (state.movements == null) {
                    state.movements = { count: action.payload.count, results: new Array(action.payload.count) };
                    state.movements.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.movements.results.length != action.payload.count) {
                        state.movements.count = action.payload.count;
                        state.movements.results = new Array(action.payload.count);
                    }
                    state.movements.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }

                state.unordered = [
                    ...state.unordered.filter((s) => action.payload.results.find((res) => res.id == s.id) == undefined),
                    ...action.payload.results
                ];
            })
            .addCase(fetchSpotModuleSlotMovements.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchSpotModuleSlotMovements.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                const { spotId, moduleId, slotId } = action.meta.arg;
                state.unordered = [
                    ...state.unordered.filter((s) => action.payload.results.find((res) => res.id == s.id) == undefined),
                    ...action.payload.results
                ];
                const startPos = toNumber(action.meta.arg.queryParams.size) * toNumber(action.meta.arg.queryParams.index);
                if (state.movementBySpotByModuleBySlotList == null) {
                    state.movementBySpotByModuleBySlotList = {
                        [spotId]: { [moduleId]: { [slotId]: { count: action.payload.count, results: new Array(action.payload.count) } } }
                    };
                    state.movementBySpotByModuleBySlotList[spotId][moduleId][slotId].results.splice(
                        startPos,
                        action.payload.results.length,
                        ...action.payload.results
                    );
                } else if (state.movementBySpotByModuleBySlotList && !state.movementBySpotByModuleBySlotList[spotId]) {
                    state.movementBySpotByModuleBySlotList[spotId] = {
                        [moduleId]: { [slotId]: { count: action.payload.count, results: new Array(action.payload.count) } }
                    };
                    state.movementBySpotByModuleBySlotList[spotId][moduleId];
                } else if (
                    state.movementBySpotByModuleBySlotList &&
                    state.movementBySpotByModuleBySlotList[spotId] &&
                    !state.movementBySpotByModuleBySlotList[spotId][moduleId]
                ) {
                    state.movementBySpotByModuleBySlotList[spotId][moduleId] = {
                        [slotId]: { count: action.payload.count, results: new Array(action.payload.count) }
                    };
                    state.movementBySpotByModuleBySlotList[spotId][moduleId][slotId].results.splice(
                        startPos,
                        action.payload.results.length,
                        ...action.payload.results
                    );
                } else if (
                    state.movementBySpotByModuleBySlotList &&
                    state.movementBySpotByModuleBySlotList[spotId] &&
                    state.movementBySpotByModuleBySlotList[spotId][moduleId] &&
                    !state.movementBySpotByModuleBySlotList[spotId][moduleId][slotId]
                ) {
                    state.movementBySpotByModuleBySlotList[spotId][moduleId][slotId] = {
                        count: action.payload.count,
                        results: new Array(action.payload.count)
                    };
                    state.movementBySpotByModuleBySlotList[spotId][moduleId][slotId].results.splice(
                        startPos,
                        action.payload.results.length,
                        ...action.payload.results
                    );
                } else {
                    if (state.movementBySpotByModuleBySlotList[spotId][moduleId][slotId].results.length != action.payload.count) {
                        state.movementBySpotByModuleBySlotList[spotId][moduleId][slotId].count = action.payload.count;
                        state.movementBySpotByModuleBySlotList[spotId][moduleId][slotId].results = new Array(action.payload.count);
                    }
                    state.movementBySpotByModuleBySlotList[spotId][moduleId][slotId].results.splice(
                        startPos,
                        action.payload.results.length,
                        ...action.payload.results
                    );
                }
            });
    }
});

export const fetchMovements = createAsyncThunk<MovementListResponse, ApiQueryParams<DefaultQueryParams>>(
    'fetchMovements',
    async (queryParams, { rejectWithValue }) => {
        try {
            return await MovementClient.fetchMovements(queryParams);
        } catch (e) {
            if ((e as ApiError).json) rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchSpotModuleSlotMovements = createAsyncThunk<
    MovementListResponse,
    { spotId: string; moduleId: string; slotId: string; queryParams: ApiQueryParams<DefaultQueryParams> }
>('fetchSpotModuleSlotMovements', async ({ spotId, moduleId, slotId, queryParams }, { rejectWithValue }) => {
    try {
        return await MovementClient.fetchSpotModuleSlotMovements(spotId, moduleId, slotId, queryParams);
    } catch (e) {
        if ((e as ApiError).json) rejectWithValue(e);
        throw e;
    }
});

export const movementStore = { movements: movementSlice.reducer };
export const { seedMovements, updateMovement, addMovement, removeMovement } = movementSlice.actions;
