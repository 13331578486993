import { APIClient } from '@frontend/api-utils';
import { PackageStateName } from '@frontend/package/types';
import { ProductStateName } from '@frontend/product/types';
import { TransactionStateName } from '@frontend/transaction/types';

const endpoint = '/workflow-api/v1';
export class WorkflowStateClient extends APIClient {
    public static async fetchAccountWorkflowPossibleStates(accountId: string, workflowId: string): Promise<TransactionStateName[] | ProductStateName[] | PackageStateName[]> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/workflows/${workflowId}/possible-states`);
        return await this.handleResponse<TransactionStateName[] | ProductStateName[] | PackageStateName[]>(response);
    }

    public static async validateAccountWorkflowTransactionStates(accountId: string, workflowId: string): Promise<any> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/workflows/${workflowId}/validate-transaction-states`);
        return await this.handleResponse<any>(response);
    }
}
