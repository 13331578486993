import { LogMessageTypeName } from './log-message-type';
import { LogSourceName } from './log-source';

export interface FilterData {
    account?: string | number;
    spot?: string | number;
    slot?: string | number;
    product?: string | number;
    shop?: string | number;
    user?: string | number;
    contact?: string | number;
    transaction?: string | number;
    module?: string | number;
    category?: string | number;
    catalog?: string | number;
    setting?: string | number;
    device?: string | number;
    import_log?: string | number;
    //spot_layout_item?: string|number;
}

export interface LogViewModel {
    account: number;
    event_timestamp: number;
    filter_data: unknown;
    logSource: LogSourceName;
    message: string;
    message_type: LogMessageTypeName;
    raw_data?: any;
}

export class Log {
    private message: string;
    private filter_data: FilterData;
    private message_type: LogMessageTypeName;
    private event_timestamp: number;
    private logSource: LogSourceName;

    private source: any;
    private data?: string; //anything can be stored in here (will be JSON.stringified)

    constructor(message: string, logLevel: LogMessageTypeName, filter_data: FilterData, data?: string, date?: Date | number, source?: any) {
        if (date !== undefined) {
            if (typeof date === 'number') {
                this.event_timestamp = date;
            } else this.event_timestamp = date.getTime();
        } else this.event_timestamp = Date.now();
        this.message = message;
        this.message_type = logLevel;
        this.filter_data = filter_data;
        this.logSource = LogSourceName.WEB;

        try {
            if (source !== undefined) {
                this.source = source;
            } else this.source = new Error().stack?.toString().split(/\r\n|\n/)[3];
        } catch {}
        this.data = data;
    }

    static parseToViewModel(webLog: any): LogViewModel {
        return {
            account: webLog.account,
            event_timestamp: webLog.event_timestamp,
            message: webLog.message,
            message_type: webLog.message_type,
            filter_data: webLog.filter_data,
            logSource: LogSourceName.WEB,
            raw_data: {
                ...(webLog.data ? JSON.parse(webLog.data) : webLog.data),
                source: webLog.source,
                id: webLog.id
            }
        };
    }
}
