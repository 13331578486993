import { Restriction, fetchEntityRestrictions } from '@frontend/authorization';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Column } from 'react-table';

import { useAppDispatch, useAppSelector } from '../../../redux/store';
import RestrictionListColumns from './restriction-list-columns';

interface ViewProps {
    page: number;
    pageSize: number;
    changePage: Dispatch<SetStateAction<number>>;
    changePageSize: Dispatch<SetStateAction<number>>;
    data: Restriction[];
    columns: Column<Restriction>[];
}

const useRestrictionList = (): ViewProps => {
    const dispatch = useAppDispatch();
    const authorizationState = useAppSelector((state) => state.authorization);
    const [page, changePage] = useState<number>(0);
    const [pageSize, changePageSize] = useState<number>(25);

    useEffect(() => {
        if (!authorizationState.current) return;
        dispatch(
            fetchEntityRestrictions({
                type: authorizationState.current.type,
                entityId: authorizationState.current.id,
                queryParams: { index: page.toString(), size: pageSize.toString() }
            })
        );
    }, [page, pageSize]);

    const data = useMemo(() => {
        if (authorizationState.current == null || authorizationState.current.restrictions == null) return [];
        return authorizationState.current.restrictions.results;
    }, [authorizationState]);

    const columns: Column<Restriction>[] = useMemo(() => RestrictionListColumns, []);

    return {
        changePage,
        changePageSize,
        columns,
        data,
        page,
        pageSize
    };
};

export default useRestrictionList;
