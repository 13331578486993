import { DeviceStatusBadgeProps } from './device-status-badge.component';

interface ViewProps {
    status: string;
}

const useDeviceStatusBadge = (props: DeviceStatusBadgeProps): ViewProps => {
    switch (props.status) {
        case 'offline':
            return { status: 'danger' };
        case 'online':
            return { status: 'success' };
        default:
            return { status: 'warning' };
    }
};

export default useDeviceStatusBadge;
