import { AuthorizationClient, CreateRestriction, fetchEntityRestrictions } from '@frontend/authorization';
import { ErrorHandler } from '@frontend/error-handler';
import { Dispatch, SetStateAction, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { CreateRestrictionFormProps } from './create-restriction-form.component';

interface ViewProps {
    submitted: boolean;
    formValues: CreateRestriction;
    changeFormValues: Dispatch<SetStateAction<CreateRestriction>>;
    formValid: FormValid;
    changeFormValid: Dispatch<SetStateAction<FormValid>>;
    onSubmit: (e: any) => void;
}
interface FormValid {
    type: boolean;
    key: boolean;
    value: boolean;
}

const useCreateRestrictionForm = (props: CreateRestrictionFormProps): ViewProps => {
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const [formValid, changeFormValid] = useState<FormValid>({ type: false, key: false, value: false });
    const [formValues, changeFormValues] = useState<CreateRestriction>({ type: '', key: '', value: '', right: 'read' });
    const authorizationState = useAppSelector((state) => state.authorization);
    const dispatch = useAppDispatch();

    const onSubmit = (e: any) => {
        e.preventDefault();
        changeSubmitted(true);
        if (authorizationState.current == null) return;
        AuthorizationClient.postEntityRestrictions(authorizationState.current.type, authorizationState.current.id, formValues)
            .then((res) => {
                if (authorizationState.current)
                    dispatch(fetchEntityRestrictions({ type: authorizationState.current.type, entityId: authorizationState.current.id }));
                props.onClose();
            })
            .catch(ErrorHandler.handleError);
    };

    return {
        changeFormValid,
        changeFormValues,
        formValid,
        formValues,
        onSubmit,
        submitted
    };
};

export default useCreateRestrictionForm;
