import { AsyncSelectInputSingle } from '@frontend/basic-forms';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

import useNotificationTemplateSelect from './notification-template-select.controller';

const ID = 'notification-template-select';
export interface NotificationTemplateSelectProps {
    id?: string;
    label?: string;
    required: boolean;
    submitted: boolean;
    onChange: (newValue?: string) => void;
    value?: string;
    className?: string;
    disabled?: boolean;
    dispatch: ThunkDispatch<any, any, Action>;
}

export const NotificationTemplateSelect = (props: NotificationTemplateSelectProps) => {
    const viewProps = useNotificationTemplateSelect(props);

    return (
        <AsyncSelectInputSingle
            id={props.id || ID}
            label={props.label || 'Notification template'}
            className={props.className}
            submitted={props.submitted}
            required={props.required}
            options={viewProps.options}
            onChange={(newValue) => props.onChange(newValue?.value)}
            value={viewProps.value?.value}
            isDisabled={props.disabled}
            onMenuScrollToBottom={viewProps.onScrollToBottom}
            loadOptions={viewProps.search}
        />
    );
};
