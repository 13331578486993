import { AsyncComponent } from "@frontend/elements";
import React from "react";

const AccountBadge = React.lazy(() => import('@frontend/account/badge').then((module) => ({ default: module.AccountBadge })));
interface Props {
    id: string;
    key: string;
}

const fallback = <span className="placeholder-glow"><span className="placeholder">loading</span></span>
export const ObjectBadgeMapper = (props: Props) => {
    switch (props.key) {
        case 'account_id':
            return <AsyncComponent fallback={fallback}><AccountBadge id={props.id} /></AsyncComponent>;
    }
    return <></>;
}