import { removeCurrentRoles } from '@frontend/authorization';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../redux/store';

interface ViewProps {
    isLoading: boolean;
    handleClose: () => void;
    roles: any;
    rolesModalOpen: boolean;
    changeRolesModalOpen: (value: React.SetStateAction<boolean>) => void;
    dispatch: ThunkDispatch<any, any, AnyAction>;
}

const useRolesModal = (): ViewProps => {
    const dispatch = useAppDispatch();
    const authorizationState = useAppSelector((state) => state.authorization);
    const isLoading = useMemo(() => authorizationState.current!.roles == null, [authorizationState.current]);
    const [rolesModalOpen, changeRolesModalOpen] = useState<boolean>(false);
    const handleClose = () => {
        dispatch(removeCurrentRoles());
    };

    return {
        isLoading,
        handleClose,
        roles: authorizationState.current!.roles,
        changeRolesModalOpen,
        rolesModalOpen,
        dispatch
    };
};

export default useRolesModal;
