import { Logger } from '@frontend/Logger';
import { useAppSelector } from '@frontend/common';
import { fetchProduct, productStore } from '@frontend/product/redux';
import { Product } from '@frontend/product/types';
import { SlotClient } from '@frontend/slot/api';
import { Slot } from '@frontend/slot/types';
import { WareClient } from '@frontend/stock/api';
import { Ware } from '@frontend/stock/types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { navigationStore } from '../../redux/user-interface-navigation-slice';
import { UserInterfaceTemplateProps } from '../../user-interface-template-props';

interface ViewProps {
    product: Product | null;
    slot: Slot | null;
}

const useProductNotInUse = (props: UserInterfaceTemplateProps): ViewProps => {
    const navigationState = useAppSelector(useSelector, navigationStore);
    const cache = props.cache || navigationState.cache;
    const productState = useAppSelector(useSelector, productStore);
    const [product, changeProduct] = useState<Product | null>(null);
    const [stock, changeStock] = useState<Ware | null>(null);
    const [slot, changeSlot] = useState<Slot | null>(null);

    useEffect(() => {
        if (cache && cache.account_id && cache.product_id) {
            props.dispatch(fetchProduct({ accountId: cache.account_id, productId: cache.product_id }));
        } else Logger.error('Not enough information provided to fetch product.', {}, { accountId: cache?.account_id, productId: cache?.product_id });
    }, []);

    useEffect(() => {
        const found = productState.unordered.find((s) => s.id == cache?.product_id);
        if (found) changeProduct(found);
    }, [cache, productState]);

    useEffect(() => {
        if (cache?.product_id) {
            WareClient.fetchWares({ product_id: cache.product_id }).then((result) => {
                if (result.results.length > 0) changeStock(result.results[0]);
            });
        }
    }, [product]);

    useEffect(() => {
        if (stock) {
            SlotClient.fetchSpotModuleSlot(stock.spot_id, stock.module_id, stock.slot_id).then((result) => {
                changeSlot(result);
            });
        }
    }, [stock]);

    return { product, slot };
};

export default useProductNotInUse;
