import { ReturnProductData } from '@frontend/user-interface/types';
import { useEffect, useState } from 'react';
import { ReturnProductDataFormProps } from './return-product-data-form.component';

interface ViewProps {
    formValues: ReturnProductData;
    changeFormValues: React.Dispatch<React.SetStateAction<ReturnProductData>>;
}

const useReturnProductDataForm = (props: ReturnProductDataFormProps): ViewProps => {
    const [formValues, changeFormValues] = useState<ReturnProductData>({
        transactionWorkflowId: '',
        packageWorkflowId: ''
    });

    useEffect(() => {
        props.onChange(formValues);
    }, [formValues]);

    return {
        formValues,
        changeFormValues
    };
};

export default useReturnProductDataForm;
