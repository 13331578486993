import { BasicAuthList } from '@frontend/authentication-methods/components';
import { AuthorizationWrapper } from '@frontend/authorization';
import { ClassType, EntityType, Rights } from '@frontend/common';
import { ConfirmationModal, DefaultModal, HorizontalButtonGroup, HorizontalNavbar, Spinner } from '@frontend/elements';
import { EventEntityType } from '@frontend/events/types';
import { ENTITY_ICONS } from '@frontend/icons';
import { CommonMessage } from '@frontend/lang';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';

import UpdateUserForm from '../../forms/update-user/update-user.component';
import { UserDetail } from '../user-detail.component';
import useUserDetailPage from './user-detail-page.controller';

const EventTable = React.lazy(() => import('@frontend/events/components').then((module) => ({ default: module.EventTable })));

const ID = 'user-detail-page';

export interface UserDetailPageProps {
    dispatch: ThunkDispatch<any, any, Action>;
}

export const UserDetailPage = (props: UserDetailPageProps) => {
    const viewProps = useUserDetailPage(props);
    if (!viewProps.user) {
        return (
            <div className='card'>
                <div className='card-body'>
                    <Spinner />
                </div>
            </div>
        );
    }
    return (
        <div id={ID}>
            <div className='card mt-3'>
                <div className='card-header d-flex justify-content-between'>
                    <h4>{`${viewProps.user.first_name} ${viewProps.user.last_name}`}</h4>
                    <HorizontalButtonGroup
                        direction='left'
                        buttons={[
                            {
                                hide: false,
                                text: CommonMessage.BUTTONS.DELETE,
                                onClick: () => viewProps.changeShowDeleteModal(true),
                                icon: FaTrash,
                                type: ClassType.DANGER
                            },
                            {
                                hide: false,
                                text: CommonMessage.BUTTONS.UPDATE,
                                onClick: () => viewProps.changeShowUpdateModal(true),
                                icon: FaEdit,
                                type: ClassType.INFO
                            }
                        ]}
                    />
                </div>
                <div className='card-body'>
                    <UserDetail user={viewProps.user} />
                </div>
            </div>
            <HorizontalNavbar
                navItems={[
                    {
                        title: 'Events',
                        icon: ENTITY_ICONS.EVENT,
                        requiredPermissions: [{ item: EntityType.EVENT, source: EntityType.USER, right: Rights.READ, uid: '?' }]
                    },
                    {
                        title: 'Basic Auths',
                        icon: ENTITY_ICONS.BASIC_AUTH,
                        requiredPermissions: Object.entries(EntityType)
                            .filter((e) => e[1] !== EntityType.ALL && e[1] !== EntityType.UNKNOWN)
                            .map((k) => ({ item: k[1], source: k[1], right: Rights.SUPER_ADMIN, uid: '?' }))
                    }
                ]}
                onChange={(v) => viewProps.changeActiveSubNav(v.toLowerCase())}
                active={viewProps.activeSubNav}
            />
            {viewProps.activeSubNav === 'events' && (
                <AuthorizationWrapper
                    showLoading={false}
                    requiredPermissions={[{ item: EntityType.EVENT, source: EntityType.USER, right: Rights.READ, uid: '?' }]}>
                    <div className='card mt-3'>
                        <div className='card-header'>
                            <h4>Events</h4>
                        </div>
                        <div className='card-body'>
                            <EventTable
                                entity_id={viewProps.user.id}
                                entity_type={EventEntityType.USER}
                                dispatch={props.dispatch}
                            />
                        </div>
                    </div>
                </AuthorizationWrapper>
            )}

            {viewProps.activeSubNav === 'basic auths' && (
                <BasicAuthList
                    user={viewProps.user}
                    dispatch={props.dispatch}
                />
            )}

            {viewProps.showDeleteModal && (
                <ConfirmationModal
                    handleClose={() => viewProps.changeShowDeleteModal(false)}
                    message={CommonMessage.FORMS.DELETE_OBJECT('user', `${viewProps.user.first_name} ${viewProps.user.last_name}`)}
                    onConfirm={() => viewProps.removeUser()}
                    severity={ClassType.DANGER}
                    show={viewProps.showDeleteModal}
                />
            )}
            {viewProps.showUpdateModal && (
                <DefaultModal
                    handleClose={() => viewProps.changeShowUpdateModal(false)}
                    show={viewProps.showUpdateModal}>
                    <UpdateUserForm
                        user={viewProps.user}
                        onClose={() => viewProps.changeShowUpdateModal(false)}
                        dispatch={props.dispatch}
                    />
                </DefaultModal>
            )}
        </div>
    );
};
