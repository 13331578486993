import { BasicAuthStatus } from '@frontend/authentication-methods/types';
import { IntegrationTestStatus } from '@frontend/integration-test';
import React from 'react';
import { GoDotFill } from 'react-icons/go';

export interface StatusBadgeProps {
    value: string | BasicAuthStatus | IntegrationTestStatus | null;
    type?: 'badge' | 'dots';
    size?: number;
    isTrue?: boolean;
    isPending?: boolean;
}

export const StatusBadge = (props: StatusBadgeProps) => {
    if (!props.value) return <></>;
    switch (props.type) {
        case 'badge':
            return (
                <span className='d-flex flex-row align-items-center m-1'>
                    <span className={`badge badge-${props.isTrue ? 'success' : props.isPending ? 'warning' : 'danger'}`}>{props.value}</span>
                </span>
            );
        case 'dots':
            return (
                <GoDotFill
                    size={props.size || undefined}
                    className={`text-${props.isTrue ? 'success' : props.isPending ? 'warning' : 'danger'}`}
                />
            );
        default:
            return (
                <span className='d-flex flex-row align-items-center m-1'>
                    <span className={`badge badge-${props.isTrue ? 'success' : props.isPending ? 'warning' : 'danger'}`}>{props.value}</span>
                </span>
            );
    }
};
