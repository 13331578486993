import React from 'react';

import { SelectInput } from '../select-input/select-input.component';

interface DataSelectProps {
    onChange: (value: string) => void;
    value?: string;
    submitted: boolean;
    label?: React.ReactNode;
    disabled?: boolean;
    className?: string;
}

export const DataSelect = (props: DataSelectProps) => {
    return (
        <SelectInput
            className={props.className ?? ''}
            submitted={props.submitted}
            label={props.label ?? 'Data type'}
            isDisabled={props.disabled}
            value={props.value}
            onChange={(v) => props.onChange((v as { value: string; label: string })?.value)}
            options={Object.entries(DataTypes).map((k) => ({ value: k[1], label: k[0] }))}
        />
    );
};

enum DataTypes {
    STRING = 'str',
    INTEGER = 'int',
    BOOLEAN = 'bool'
}
