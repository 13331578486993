import { PubSubConnection } from '@frontend/pub-sub';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

import { WorkflowStepEventHandler } from '@frontend/workflow-step/events';
import { WorkflowEventHandler } from './workflow-event-handler';

export class WorkflowServiceEventListener extends PubSubConnection {
    private static instance: WorkflowServiceEventListener | null = null;
    #workflowEventHandler: WorkflowEventHandler;
    #workflowStepEventHandler: WorkflowStepEventHandler;

    private constructor(dispatch: ThunkDispatch<any, any, Action>) {
        super('workflow');
        this.#workflowEventHandler = new WorkflowEventHandler(dispatch);
        this.#workflowStepEventHandler = new WorkflowStepEventHandler(dispatch);
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>): WorkflowServiceEventListener {
        if (this.instance == null) {
            this.instance = new WorkflowServiceEventListener(dispatch);
        }
        return this.instance;
    }

    protected override onMessageEvent(event: MessageEvent<string>): void {
        this.#workflowEventHandler.onMessageEvent(event);
        this.#workflowStepEventHandler.onMessageEvent(event);
    }
}
