import { APIClient } from "@frontend/api-utils";

const endpoint = '/user-api/v1';

export class UserEnumClient extends APIClient {
    public static async fetchUserStates(): Promise<string[]> {
        const response = await this.fetch(`${endpoint}/user-states`);
        return await this.handleResponse<string[]>(response);
    }

    public static async fetchUserTriggers(): Promise<string[]> {
        const response = await this.fetch(`${endpoint}/user-triggers`);
        return await this.handleResponse<string[]>(response);
    }

    public static async fetchUserActions(): Promise<string[]> {
        const response = await this.fetch(`${endpoint}/user-actions`);
        return await this.handleResponse<string[]>(response);
    }
}