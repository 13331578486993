import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { ChangeClient } from '@frontend/sync/api';
import { Change, ChangeListResponse } from '@frontend/sync/types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

interface ChangeState {
    unordered: Change[];
    changeList: ChangeListResponse | null;
    status: SliceStatus;
}

const initialState: ChangeState = {
    unordered: [],
    changeList: null,
    status: SliceStatus.INIT
};

export const ChangeSlice = createSlice({
    name: 'change',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchChanges.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchChanges.fulfilled, (state, action) => {
                const startPos = toNumber(action.meta.arg.index) * toNumber(action.meta.arg.size);
                state.status = SliceStatus.IDLE;
                if (state.changeList == null) {
                    state.changeList = { ...action.payload, results: new Array(action.payload.count) };
                    state.changeList.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.changeList.results.length != action.payload.count) {
                        state.changeList.count = action.payload.count;
                        state.changeList.results = new Array(action.payload.count);
                    }

                    state.changeList.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
            });
    }
});

export const fetchChanges = createAsyncThunk<ChangeListResponse, ApiQueryParams<DefaultQueryParams>>(
    'fetchChanges',
    async (queryParams: ApiQueryParams<DefaultQueryParams>, { rejectWithValue }) => {
        try {
            return await ChangeClient.fetchChanges(queryParams);
        } catch (e) {
            if ((e as ApiError).json) rejectWithValue(e);
            throw e;
        }
    }
);

export const changeStore = { changes: ChangeSlice.reducer };
