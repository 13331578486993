import { DefaultSelectProps, SelectInput } from '@frontend/basic-forms';
import { WorkflowType } from '@frontend/workflow/types';
import React from 'react';



const ID = 'workflow-type-select';
export interface WorkflowTypeSelectProps {}

const WorkflowTypeSelect = (props: DefaultSelectProps) => {
    return (
        <SelectInput
            {...props}
            id={props.id || ID}
            isClearable
            label={props.label || 'Type'}
            options={Object.entries(WorkflowType).map((c) => ({ value: c[1], label: c[1] }))}
            value={props.value}
            submitted={props.submitted}
            onChange={props.onChange}
        />
    );
};

export default WorkflowTypeSelect;
