import { UserInterfaceType, mobileInterfaceTypeInformation, terminalInterfaceTypeInformation } from '@frontend/user-interface/types';
import React, { useMemo } from 'react';
import { UserInterfaceTypeSelectProps } from './user-interface-type-select.component';


interface ViewProps {
    options: { value: string; label: React.ReactNode }[];
}

const useUserInterfaceTypeSelect = (props: UserInterfaceTypeSelectProps): ViewProps => {
    const options = useMemo(() => {
        let relevant = Object.values(UserInterfaceType);
        if (props.platform) relevant = relevant.filter((type) => Array.from((
            props.platform === 'terminal' ? terminalInterfaceTypeInformation : mobileInterfaceTypeInformation
        ).keys()).includes(type));
        
        return relevant.map((type) => {
            return {
                value: type,
                label: (
                    <div>
                        <h6 className='m-0'>{type}</h6>
                        {(props.platform === 'terminal' ? terminalInterfaceTypeInformation : mobileInterfaceTypeInformation).get(type)?.map(t => {return (<p className='text-xs'>{t}</p>)})}
                    </div>
                )
            };
        });
    }, [props.platform]);
    return { options };
};

export default useUserInterfaceTypeSelect;

