import { useDetailModalWrapperRepository } from '@frontend/repository';
import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Props } from './object-detail-modal.component';

interface ViewProps<T> {
    title: string;
    viewDetails?: () => void;
    modalType: string;
    width: any;
    modalRef: React.MutableRefObject<null>;
    object: T | null;
}

const useObjectDetailModal = <T extends { id: string }>(props: Props<T>): ViewProps<T> => {
    const repository = useDetailModalWrapperRepository<T>(props.objectType, props.resolve, props.id);
    const modalRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const modalType = props.type ? 'modal-' + props.type.valueOf() : 'modal-prompt';
    const width = props.customWidth !== undefined ? { width: props.customWidth + '%' } : {};

    useEffect(() => {
        const handleClickOutside = (event: { target: any }) => {
            // Temp fix for prompt modals closing when used inside a pane modal
            // Should be fixed in the future
            const isModalRoot = event.target.closest('.modal-prompt');
            const queryParams = new URLSearchParams(location.search);

            if (isModalRoot) return;
            if (queryParams.has('details')) {
                const details = queryParams.get('details');
                const detailsArray = details!.slice(1, -1).split(',');
                if (!props.id) return;
                const newArray = detailsArray[detailsArray.length - 1].slice(1, -1).split('-');
                newArray.shift();
                if (newArray.join('-') == props.id) {
                    if (modalRef.current && !(modalRef.current as any).contains(event.target)) {
                        props.show && props.handleClose && props.handleClose();
                    }
                }
            } else {
                if (modalRef.current && !(modalRef.current as any).contains(event.target)) {
                    //TODO maybe for future use create wrapper component that removes the top modal only
                    //props.show && props.handleClose && props.handleClose();
                }
            }
        };
        document.addEventListener('mousedown', handleClickOutside, true);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    }, [modalRef, props.handleClose]);

    useEffect(() => {
        if (repository.object) {
            props.callback && props.callback(repository.object);
        }
    }, [repository.object]);

    function viewDetails() {
        //This may need to be provided by the object itself
        if (!repository.object) return;
        if (props.overwriteViewDetails) {
            props.overwriteViewDetails();
        } else {
            navigate(`/${props.objectType}s/${repository.object.id}/detail`);
        }
    }

    return {
        title: (props.objectType[0].toUpperCase() + props.objectType.slice(1)).split('_').join(' '),
        viewDetails: repository.object ? viewDetails : undefined,
        modalRef,
        modalType,
        width,
        ...repository
    };
};

export default useObjectDetailModal;
