import { useChangeUrl } from '@frontend/common';
import { AccessLogType } from '@frontend/user/types';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { AccessLogTypeSelectProps } from './access-log-type-select.component';

interface ViewProps {
    options: { value: string; label: string }[];
    value?: { value: string; label: string } | null;
    onChange: (value?: string | null, allowURLChange?: boolean) => string | null | undefined;
}

const useAccessLogTypeSelect = (props: AccessLogTypeSelectProps): ViewProps => {
    const options = Object.entries(AccessLogType).map((v) => ({ value: v[1], label: v[0] }));
    const [searchParams] = useSearchParams();
    const accessLogType = searchParams.get('access_log_type');
    const { onChange } = useChangeUrl('access_log_type', (value) => {
        props.onChange && props.onChange(value ?? undefined);
    });

    const value = useMemo(() => {
        if (props.allowURLChange) return options.find((o) => o && o.value === accessLogType);
        if (typeof props.value == 'string') return options.find((o) => o && o.value == props.value);
        return props.value;
    }, [props.value, options, accessLogType]);

    return {
        onChange,
        options,
        value
    };
};

export default useAccessLogTypeSelect;
