import { Logger } from '@frontend/Logger';
import { addCertificate, removeCertificate, updateCertificate } from '@frontend/certificate/redux';
import { Certificate } from '@frontend/certificate/types';
import { EventListener } from '@frontend/pub-sub';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

export enum MessageTypes {
    UPDATED = 'certificate_updated',
    DELETED = 'certificate_deleted',
    CREATED = 'certificate_created'
}

export class CertificateEventListener extends EventListener<Certificate> {
    
    private static instance: CertificateEventListener | null = null;
    private constructor(dispatch: ThunkDispatch<any, any, Action>) {
        super('certificate', dispatch);
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>): CertificateEventListener {
        if (this.instance == null) {
            this.instance = new CertificateEventListener(dispatch);
        }
        return this.instance;
    }

    getInstance(dispatch: ThunkDispatch<any, any, Action>): EventListener<Certificate> {
        return CertificateEventListener.getInstance(dispatch);
    }

    protected onUpdate(payload: { message: MessageTypes; data: Certificate; changes?: any }) {
        Logger.log('Updating certificate in store', {}, payload.data);
        this.dispatch(updateCertificate(payload.data));
    }

    protected onDelete(payload: { message: MessageTypes; data: Certificate }) {
        Logger.log('Removing certificate from store', {}, payload.data);
        this.dispatch(removeCertificate(payload.data.id));
    }

    protected onCreate(payload: { message: MessageTypes; data: Certificate; changes?: any }) {
        Logger.log('Adding certificate to store', {}, payload.data);
        this.dispatch(addCertificate(payload.data));
    }
}