import { APIClient } from "@frontend/api-utils";
import { postActuator } from "@frontend/device/types";

const endpoint = '/edge-api/v1';
export class EdgeActuatorClient extends APIClient {
    public static async postOpenSpotModuleSlot(spotId: string, moduleId: string, slotId: string): Promise<void> {
        const response = await this.post(`${endpoint}/spots/${spotId}/modules/${moduleId}/slots/${slotId}/open`);
        return await this.handleVoidResponse(response);
    }

    public static async postDriverDeviceActuator(driverId: string, deviceId: string, body: postActuator ): Promise<void> {
        const response = await this.post(`${endpoint}/drivers/${driverId}/devices/${deviceId}/actuators`, body);
        return await this.handleVoidResponse(response);
    }
}