import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

export const useChangeUrl = (key: string, callback?: (value: string | null) => void) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const value = searchParams.get(key);
    const onChange = (value?: string | null, allowURLChange = true) => {
        if (callback) callback(value == undefined ? null : value);
        if (allowURLChange) {
            if (value) {
                setSearchParams((prev) => {
                    const params = new URLSearchParams(prev);
                    if (params.has(key)) {
                        params.set(key, value);
                    } else {
                        params.append(key, value);
                    }
                    return params;
                });
            } else {
                setSearchParams((prev) => {
                    const params = new URLSearchParams(prev);
                    params.delete(key);
                    return params;
                });
            }
            return;
        } else {
            return value;
        }
    };

    return { value, onChange };
};

export function useOpenDetailModal(type: string, objectId: string) {
    const navigate = useNavigate();
    const location = useLocation();

    const onClick = (e?: any) => {
        e?.stopPropagation();
        const urlValue = type + '-' + objectId;
        const queryParams = new URLSearchParams(location.search);
        const detail = queryParams.get('details');
        if (detail) {
            queryParams.set('details', JSON.stringify([...(JSON.parse(detail) as string[]), urlValue]));
        } else {
            queryParams.set('details', JSON.stringify([urlValue]));
        }

        navigate({
            pathname: location.pathname,
            search: queryParams.toString()
        });
    };

    return { onClick };
}
