import { AddressBadgeProps } from './address-badge.component';

interface ViewProps {
    isValid: boolean;
    url: string;
    address: string;
}

export const useAddressBadge = (props: AddressBadgeProps): ViewProps => {
    const address = `${props.address} ${props.zip} ${props.city}`;
    const isValid = props.address != null && props.address !== '' && props.zip != null && props.zip !== '' && props.city != null && props.city !== '';

    return {
        isValid,
        url: `https://www.google.com/maps/place/${props.address}, ${props.zip} ${props.city}`,
        address
    };
};
