import { useEffect, useMemo, useState } from 'react';

import { getInputClassMultiSelect } from '../input-class';
import { AsyncSelectInputMultiProps } from './async-select-input-multi.component';

interface ViewProps {
    value: any;
    valid: boolean;
    touched: boolean;
    changeValue: React.Dispatch<any>;
    isValid: () => boolean;
    changeTouched: React.Dispatch<React.SetStateAction<boolean>>;
    className: string;
    findValue: () => any;
}

const useAsyncSelectInputMulti = (props: AsyncSelectInputMultiProps): ViewProps => {
    const [touched, changeTouched] = useState<boolean>(false);
    const [valid, changeValid] = useState<boolean>(props.valid !== undefined ? props.valid : true);
    const [value, changeValue] = useState<string[]>(props.value ? props.value : []);
    const className = useMemo(() => {
        return props.useConditionedStyling === false ? 'form-control' : getInputClassMultiSelect(touched, valid, props.value);
    }, [props.useConditionedStyling]);

    const isValid = (): boolean => {
        let check = true;
        if ((!props.value || props.value.length === 0) && props.required) check = false;
        if (props.valid !== undefined && check === true) check = props.valid;
        changeValid(check);
        return check;
    };

    useEffect(() => {
        if (props.isValidCallback) {
            props.isValidCallback(isValid());
        } else isValid();
    }, [props.value, props.valid]);

    useEffect(() => {
        if (props.submitted) {
            !touched && changeTouched(true);
        }
    }, [props.submitted]);

    const findValue = () => {
        if (props.value && props.value.length > 0) {
            if (props.options) {
                return props.options.filter((op) => props.value?.includes(op.value));
            } else return [];
        } else return [];
    };

    return {
        changeTouched,
        changeValue,
        className,
        findValue,
        isValid,
        touched,
        valid,
        value
    };
};

export default useAsyncSelectInputMulti;

export interface Option {
    readonly label: string;
    readonly value: string;
}
