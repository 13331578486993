import React from 'react';
import { FaArrowLeft, FaArrowRight, FaWarehouse } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';

export interface DropPickDividerProps {
    type: 'drop' | 'pick';
    hide?: boolean;
}

export const DropPickDivider = (props: DropPickDividerProps): JSX.Element => {
    if (props.hide) return <></>;
    return (
        <div className={`d-flex align-items-center align-content-center h-30 w-100 rounded ${props.type === 'drop' ? 'bg-primary' : 'bg-info'}`}>
            <div className='d-flex w-100 justify-content-between align-items-center ms-2 me-2'>
                <span className='font-weight-bolder text-white'>{dropPickDividerMessage(props.type)}</span>
                <div className='d-flex justify-content-end align-items-center text-white'>
                    {props.type === 'drop' ? (
                        <FaArrowRight
                            size={50}
                            className='me-2'
                        />
                    ) : (
                        <FaArrowLeft
                            size={50}
                            className='me-2'
                        />
                    )}
                    <FaWarehouse size={100} />
                </div>
            </div>
        </div>
    );
};

function dropPickDividerMessage(type: 'drop' | 'pick'): React.ReactNode {
    return (
        <FormattedMessage
            id='drop-pick-divider.message'
            description='Message for the drop pick divider'
            defaultMessage={`{text}`}
            values={{
                text: type === 'drop' ? 'DropOff in Warehouse' : 'PickUp from Warehouse'
            }}
        />
    );
}
