import { Logger } from '@frontend/Logger';
import { EventListener } from '@frontend/pub-sub';
import { addUser, removeUser, updateUser } from '@frontend/user/redux';
import { User } from '@frontend/user/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

export enum MessageTypes {
    UPDATED = 'user_updated',
    DELETED = 'user_deleted',
    CREATED = 'user_created'
}

export class UserEventListener extends EventListener<User> {
    
    private static instance: UserEventListener | null = null;
    private constructor(dispatch: ThunkDispatch<any, any, Action>) {
        super('user', dispatch);
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>): UserEventListener {
        if (this.instance == null) {
            this.instance = new UserEventListener(dispatch);
        }
        return this.instance;
    }

    getInstance(dispatch: ThunkDispatch<any, any, Action>): EventListener<User> {
        return UserEventListener.getInstance(dispatch);
    }

    protected onUpdate(payload: { message: MessageTypes; data: User; changes?: any }) {
        Logger.log('Updating user in store', { user: payload.data.id }, payload.data);
        this.dispatch(updateUser(payload.data));
    }

    protected onDelete(payload: { message: MessageTypes; data: User }) {
        Logger.log('Removing user from store', { user: payload.data.id }, payload.data);
        this.dispatch(removeUser(payload.data.id));
    }

    protected onCreate(payload: { message: MessageTypes; data: User; changes?: any }) {
        Logger.log('Adding user to store', { user: payload.data.id }, payload.data);
        this.dispatch(addUser(payload.data));
    }
}