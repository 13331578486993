import { Spinner } from '@frontend/elements';
import { Slot } from '@frontend/slot/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React, { Suspense } from 'react';

import useSpotLayoutSlotSelect from './spot-layout-slot-select.controller';

const SpotLayout = React.lazy(() => import('@frontend/spot-layout').then(({ SpotLayout }) => ({ default: SpotLayout })));

const ID = 'spot-layout-slot-select';
export interface SpotLayoutSlotSelectProps {
    dispatch: ThunkDispatch<any, any, Action>;
    slot?: Slot | null;
    callback: (slot: Slot | null) => void;
}

const SpotLayoutSlotSelect = (props: SpotLayoutSlotSelectProps) => {
    const viewProps = useSpotLayoutSlotSelect(props);

    if (!viewProps.spots || !viewProps.slots) return <Spinner />;
    return (
        <div
            id={ID}
            className='d-flex flex-column justify-content-center'>
            {viewProps.spots.map((spot) => {
                return (
                    <Suspense
                        fallback={<Spinner />}
                        key={spot.id}>
                        <SpotLayout
                            dispatch={props.dispatch}
                            spot={spot}
                            highlighted={props.slot ? [props.slot.id] : []}
                            onClick={(slot) => props.callback(viewProps.slots!.find((s) => s.id === slot) ?? null)}
                        />
                    </Suspense>
                );
            })}
        </div>
    );
};

export default SpotLayoutSlotSelect;
