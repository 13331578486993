import { UserInterfaceType } from '@frontend/user-interface/types';

import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import useUserInterfaceDataByTypeForm from './user-interface-data-by-type-form.controller';

const ID = 'user-interface-data-by-type-form';
export interface UserInterfaceDataByTypeFormProps {
    accountId: string;
    dispatch: ThunkDispatch<any, any, Action>;
    type: UserInterfaceType;
    onChange: (value: any) => void;
    submitted: boolean;
}

const UserInterfaceDataByTypeForm = (props: UserInterfaceDataByTypeFormProps) => {
    const viewProps = useUserInterfaceDataByTypeForm(props);

    return <div id={ID}>{viewProps.content}</div>;
};

export default UserInterfaceDataByTypeForm;
