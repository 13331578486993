import { PackageType } from '@frontend/package/types';
import { useState } from 'react';

import { AddToCartButtonsProps } from './add-to-cart-buttons.component';

interface ViewProps {
    isDisabled: (amountToAdd: number) => boolean;
    editing: boolean;
    changeEditing: React.Dispatch<React.SetStateAction<boolean>>;
    changeAmount: (amount: number) => void;
}

const useAddToCartButtons = (props: AddToCartButtonsProps): ViewProps => {
    const [editing, changeEditing] = useState(false);
    const isDisabled = (amountToAdd: number) => {
        if (props.amount === null || props.maxAmount == undefined) return true;
        if (props.type == PackageType.DROP_OFF) {
            if (amountToAdd > 0) return false;
            if (props.amount >= -amountToAdd) return false;
        } else if (props.type == PackageType.PICK_UP) {
            if (amountToAdd < 0) {
                if (props.amount >= -amountToAdd) return false;
            } else if (amountToAdd + props.amount <= props.maxAmount) return false;
        }
        return true;
    };

    const changeAmount = (amount: number) => {
        if (amount < 0) props.changeAmount(0);
        else if (props.maxAmount != undefined && amount > props.maxAmount) props.changeAmount(props.maxAmount);
        else props.changeAmount(amount);
    };

    return {
        isDisabled,
        editing,
        changeEditing,
        changeAmount
    };
};

export default useAddToCartButtons;
