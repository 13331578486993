import { Editor } from '@tiptap/react';

interface ViewProps {
    getUrl: () => void;
}

interface MenuBarProps {
    editor: Editor | null;
}
const useMenuBar = (props: MenuBarProps): ViewProps => {
    function getUrl() {
        const url = window.prompt('URL');

        if (url && props.editor) {
            props.editor.chain().focus().setImage({ src: url }).run();
        }
    }

    return {
        getUrl
    };
};

export default useMenuBar;
