import { useAppSelector } from '@frontend/common';
import { fetchNotificationTemplates, notificationTemplateStore } from '@frontend/notification-service/redux';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { NotificationTemplateBadgeProps } from './notification-template-badge.component';

interface ViewProps {
    onClick: () => void;
    notificationTemplate?: string;
    disabled: boolean;
}

const useNotificationTemplateBadge = (props: NotificationTemplateBadgeProps): ViewProps => {
    const navigate = useNavigate();
    const [requested, changeRequested] = useState<boolean>(false);
    const notificationTemplateState = useAppSelector(useSelector, notificationTemplateStore);
    const [notificationTemplate, changeNotificationTemplate] = useState<string | undefined>(props?.name);
    const [disabled, changeDisabled] = useState<boolean>(false);
    const [accountId, changeAccountId] = useState<string | undefined>(props.accountId);

    useEffect(() => {
        if (props.id) {
            const found = notificationTemplateState.unordered.find((t) => t.id == props.id);
            if (found) {
                changeNotificationTemplate(found.name);
                changeAccountId(found.account_id);
            } else if (!found && requested == false) {
                changeRequested(true);
                if (props.dispatch) {
                    props.dispatch(fetchNotificationTemplates({template_id: props.id}))
                }
            }

            if (!accountId || !props.id) {
                changeDisabled(true);
            } else if (accountId) {
                changeDisabled(false);
            }

            if (requested && !found) {
                changeDisabled(true);
                changeNotificationTemplate('Not found');
            }
        }
    }, [notificationTemplateState, props.id, props.accountId, accountId]);

    const onClick = () => {
        if (disabled) return;
        navigate(`/accounts/${accountId}/notification-templates/${props.id}`);
    };

    return {
        onClick,
        notificationTemplate,
        disabled
    };
};

export default useNotificationTemplateBadge;
