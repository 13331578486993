import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';

import { SlotClient } from '@frontend/slot/api';
import { seedSlots } from '@frontend/slot/redux';
import { Slot } from '@frontend/slot/types';
import { SlotServiceEventListener } from './slot-service-event-listener';

declare global {
    interface WindowEventMap {
        'useSlot:request': CustomEvent<string>;
    }
}

const REQUEST_ACCOUNT_EVENT = 'useSlot:request';
const STORAGE_KEY = 'SLTS_REQ';

export function slotRequest(slotId: string) {
    window.dispatchEvent(new CustomEvent(REQUEST_ACCOUNT_EVENT, { detail: slotId }));
}

interface Props {
    dispatch: ThunkDispatch<any, any, AnyAction>;
    enablePubSub?: boolean;
}

export function useSlots(props: Props) {
    const [pending, changePending] = useState<string[]>([]);
    const [timeoutSet, changeTimeoutSet] = useState<boolean>(false);
    const [timeoutFinished, changeTimeoutFinished] = useState<boolean>(false);
    const [slotEventListener, changeSlotEventListener] = useState<SlotServiceEventListener | null>(null);

    useEffect(() => {
        window.addEventListener(REQUEST_ACCOUNT_EVENT, listener);
    }, []);

    useEffect(() => {
        if (props.enablePubSub) {
            changeSlotEventListener(SlotServiceEventListener.getInstance(props.dispatch));
        }
    }, [props.enablePubSub]);

    useEffect(() => {
        if (timeoutFinished) {
            const stored = localStorage.getItem(STORAGE_KEY);
            if (stored != null) {
                changePending(JSON.parse(stored));
                changeTimeoutSet(false);
                changeTimeoutFinished(false);
                localStorage.removeItem(STORAGE_KEY);
            }
        }
    }, [timeoutFinished]);

    useEffect(() => {
        if (pending.length > 0) {
            getSlots(pending).then((result) => {
                props.dispatch(seedSlots(result));
                changePending([]);
                changeTimeoutSet(false);
                changeTimeoutFinished(false);
            });
        }
    }, [pending]);

    const listener = (event: CustomEvent) => {
        if (typeof event.detail == 'string') {
            if (timeoutSet === false) {
                changeTimeoutSet(true);
                setTimeout(() => {
                    changeTimeoutFinished(true);
                }, 1000);
            }
            const stored = localStorage.getItem(STORAGE_KEY);
            if (stored == null) {
                localStorage.setItem(STORAGE_KEY, JSON.stringify([event.detail]));
            } else if (!JSON.parse(stored).includes(event.detail)) {
                localStorage.setItem(STORAGE_KEY, JSON.stringify([...JSON.parse(stored), event.detail]));
            }
        }
    };

    return { slotEventListener };
}

async function getSlots(slotIds: string[]): Promise<Slot[]> {
    const response = await SlotClient.resolveSlots(slotIds);
    return response.results;
}
