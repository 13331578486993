
import React from 'react';
import useNotificationTemplateBadge from './notification-template-badge.controller';

import { ENTITY_ICONS } from '@frontend/icons';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
const ID = 'notification-template-badge';

export interface NotificationTemplateBadgeProps {
    id?: string;
    accountId?: string;
    name?: string;
    dispatch?: ThunkDispatch<any, any, Action>;
}

export const NotificationTemplateBadge = (props: NotificationTemplateBadgeProps) => {
    const viewProps = useNotificationTemplateBadge(props);
    return (
        <span
            id={ID}
            className={`badge badge-secondary ${!viewProps.disabled && 'cursor-pointer'} m-1`}
            onClick={() => viewProps.onClick()}>
            <ENTITY_ICONS.NOTIFICATION_TEMPLATE className='me-2' />
            {!props.name && !viewProps.notificationTemplate ? (
                <span className='placeholder-glow'>
                    <div className='placeholder'>loading</div>
                </span>
            ) : (
                viewProps.notificationTemplate
            )}
        </span>
    );
};
