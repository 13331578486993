import { Logger } from '@frontend/Logger';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';

import { addDriver, removeDriver, updateDriver } from '@frontend/edge/redux';
import { Driver } from '@frontend/edge/types';

enum MessageTypes {
    UPDATED = 'driver_updated',
    DELETED = 'driver_deleted',
    CREATED = 'driver_created'
}

export class DriverEventHandler {
    #dispatch: ThunkDispatch<any, any, AnyAction>;

    constructor(dispatch: ThunkDispatch<any, any, AnyAction>) {
        this.#dispatch = dispatch;
    }

    public onMessageEvent(event: MessageEvent<string>): void {
        const payload = JSON.parse(event.data) as { message: MessageTypes; data: Driver; changes?: any };
        switch (payload.message) {
            case MessageTypes.UPDATED:
                this.onUpdate(payload);
                break;
            case MessageTypes.DELETED:
                this.onDelete(payload);
                break;
            case MessageTypes.CREATED:
                this.onCreate(payload);
                break;
        }
    }

    private onUpdate(payload: { message: MessageTypes; data: Driver; changes?: any }) {
        Logger.log('Updating driver in store', {}, payload.data);
        this.#dispatch(updateDriver(payload.data));
    }

    private onDelete(payload: { message: MessageTypes; data: Driver }) {
        Logger.log('Removing driver from store', {}, payload.data);
        this.#dispatch(removeDriver(payload.data.id));
    }

    private onCreate(payload: { message: MessageTypes; data: Driver; changes?: any }) {
        Logger.log('Adding driver to store', {}, payload.data);
        this.#dispatch(addDriver(payload.data));
    }
}
