import { Spinner, StringPlaceholder } from '@frontend/elements';
import React from 'react';

import { UserInterfaceTemplateProps } from '../../user-interface-template-props';
import useSlotOpening from './slot-opening.controller';

const SlotOpening = (props: UserInterfaceTemplateProps) => {
    const viewProps = useSlotOpening(props);

    return (
        <div
            id='slot-opening'
            className='d-flex flex-column align-items-center justify-content-center m-3'>
            <h3 className='pb-4'>
                SLOT{' '}
                <StringPlaceholder
                    loaded={!!viewProps.slot}
                    className='col-2 bg-info'>
                    <span className='text-info'>{viewProps.slot?.number}</span>
                </StringPlaceholder>{' '}
                is opening.
            </h3>
            <Spinner />
        </div>
    );
};

export default SlotOpening;
