import { Logger } from '@frontend/Logger';
import { useEffect, useMemo, useState } from 'react';
import { Column } from 'react-table';

import { TransactionVariableClient } from '../api/transaction-variable-client';
import { TransactionVariable } from '../transaction-variables';
import TransactionVariablesColumns from './table/transaction-variables-columns';
import { TransactionVariablesProps } from './transaction-variables.component';

interface ViewProps {
    data: TransactionVariable[];
    columns: Column<TransactionVariable>[];
}

const useTransactionVariables = (props: TransactionVariablesProps): ViewProps => {
    const [variables, changeVariables] = useState<TransactionVariable[]>([]);

    useEffect(() => {
        TransactionVariableClient.fetchAccountTransactionVariables(props.transaction.account_id, props.transaction.id)
            .then((res) => {
                changeVariables(res.results);
            })
            .catch(() => Logger.error(`Error fetching transaction variables for transaction: ${props.transaction.id}`));
    }, [props.transaction]);

    const data = useMemo(() => {
        return variables;
    }, [variables]);

    const columns: Column<TransactionVariable>[] = useMemo(() => TransactionVariablesColumns, []);

    return {
        data,
        columns
    };
};

export default useTransactionVariables;
