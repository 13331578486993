import React from 'react';

import useSeverityBadge from './severity-badge.controller';

export interface SeverityBadgeProps {
    severity: string;
}

export const SeverityBadge = (props: SeverityBadgeProps) => {
    const viewProps = useSeverityBadge(props);
    return (
        <>
            <span className={`badge badge-${viewProps.type}`}>{props.severity}</span>
        </>
    );
};
