import { Spinner } from '@frontend/elements';
import { UserInterface, UserInterfaceType } from '@frontend/user-interface/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

import DefaultTitle from '../../default-title/default-title.component';
import useButtonDashboard from './button-dashboard.controller';
import UserInterfaceButtonElement from './workflow-button-element/button-element.component';

export interface ButtonDashboardProps {
    dispatch: ThunkDispatch<any, any, Action>;
    userInterface: UserInterface;
}

const ButtonDashboard = (props: ButtonDashboardProps) => {
    const viewProps = useButtonDashboard(props);

    if (viewProps.isError)
        return (
            <div id={UserInterfaceType.BUTTON_DASHBOARD}>
                <h4 className='text-center'>Something went wrong trying to get the correct configuration for this terminal.</h4>
            </div>
        );
    if (viewProps.isLoading)
        return (
            <div id={UserInterfaceType.BUTTON_DASHBOARD}>
                <Spinner />
            </div>
        );

    return (
        <div id={UserInterfaceType.BUTTON_DASHBOARD}>
            <DefaultTitle dispatch={props.dispatch} />
            <div className='d-flex flex-wrap justify-content-center'>
                {viewProps.buttons!.map((button) => {
                    return (
                        <UserInterfaceButtonElement
                            dispatch={props.dispatch}
                            button={button}
                            key={button.id}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default ButtonDashboard;
