import { SelectInput } from '@frontend/basic-forms';
import React from 'react';
import { ActionMeta } from 'react-select';
import useUserInterfacePlatformSelect from './user-interface-platform-select.controller';

const ID = 'user-interface-platform-select';
export interface UserInterfacePlatformSelectProps {
    required: boolean;
    submitted: boolean;
    onChange: (newValue: { value: 'terminal' | 'mobile'; label: any }, actionMeta: ActionMeta<any>) => void;
    value?: string;
    className?: string;
}

const UserInterfacePlatformSelect = (props: UserInterfacePlatformSelectProps) => {
    const viewProps = useUserInterfacePlatformSelect();

    return (
        <SelectInput
            id={ID}
            label={'Platform'}
            className={props.className}
            submitted={props.submitted}
            required={props.required}
            options={viewProps.options}
            value={props.value}
            onChange={(value, actionMeta) => props.onChange(value as { value: 'terminal' | 'mobile'; label: any }, actionMeta)}
        />
    );
};

export default UserInterfacePlatformSelect;
