import { ClassType, EntityType, Rights } from '@frontend/common';
import { ConfirmationModal, DefaultModal, Filter, HorizontalButtonGroup, ModalType, Spinner } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';
import { Table } from '@frontend/table';
import { User } from '@frontend/user/types';
import { FaPlus, FaTrash } from 'react-icons/fa';

import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import CreateUserForm from '../forms/create-user/create-user.component';
import UserListElement from './user-list-element';
import useUserList from './user-list.controller';

const ID = 'user-list';

export interface UserListProps {
    dispatch: ThunkDispatch<any, any, Action>;
}

export const UserList = (props: UserListProps) => {
    const viewProps = useUserList(props);

    if (viewProps.isLoading) return <Spinner type={ClassType.LIGHT} />;

    return (
        <div id={ID}>
            <div className='card mt-3'>
                <div className='card-header d-flex justify-content-between align-items-center'>
                    <HorizontalButtonGroup
                        buttons={[
                            {
                                hide: false,
                                onClick: () => viewProps.changeShowCreateUserModal(true),
                                icon: FaPlus,
                                type: ClassType.PRIMARY,
                                id: 'createUserButton',
                                text: CommonMessage.FORMS.USER.CREATE,
                                requiredPermissions: [{item: EntityType.USER, source: EntityType.ACCOUNT, right: Rights.CREATE, uid: '?'}]
                            },
                            {
                                hide: viewProps.selectedUsers.length === 0,
                                text: CommonMessage.BUTTONS.DELETE,
                                icon: FaTrash,
                                type: ClassType.DANGER,
                                id: 'deleteUserButton',
                                onClick: () => viewProps.changeShowDeleteModal(true),
                                requiredPermissions: [{item: EntityType.USER, source: EntityType.ACCOUNT, right: Rights.DELETE, uid: '?'}]
                            }
                        ]}
                        direction='left'
                    />
                    <Filter filterValue={viewProps.changeSearchValue} />
                </div>
                <div className='card-body'>
                    <Table
                        id={ID + '-table'}
                        columns={viewProps.columns}
                        data={viewProps.data}
                        RowElement={UserListElement}
                        page={(value) => viewProps.setPage(value + 1)}
                        pageSize={viewProps.setPageSize}
                        pageCount={viewProps.pageCount}
                        selectEnabled={viewProps.isAdminRoute}
                        selectedItemsCallback={(v: User[]) => viewProps.changeSelectedUsers(v.map((a) => a.id))}
                        sorting={viewProps.changeSortState}
                        dispatch={props.dispatch}
                        hideColumns={viewProps.isAdminRoute ? [] : ['id']}
                        sortingColumns={['last_name', 'email']}
                    />
                </div>
            </div>

            <DefaultModal
                show={viewProps.showCreateUserModal}
                handleClose={() => viewProps.changeShowCreateUserModal(false)}
                type={ModalType.PROMPT}>
                <CreateUserForm onClose={() => viewProps.changeShowCreateUserModal(false)} dispatch={props.dispatch} />
            </DefaultModal>

            <ConfirmationModal
                show={viewProps.showDeleteModal}
                handleClose={() => viewProps.changeShowDeleteModal(false)}
                message={`You are about to delete ${viewProps.selectedUsers.length} users`}
                severity={ClassType.DANGER}
                onConfirm={viewProps.removeUsers}
            />
        </div>
    );
};
