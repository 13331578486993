import { useNavigate } from 'react-router-dom';

import { RakindaDetailModalProps } from './rakinda-detail-modal.component';

interface ViewProps {
    viewDetails: () => void;
}

export const useRakindaDetailModal = (props: RakindaDetailModalProps): ViewProps => {
    const navigate = useNavigate();

    function viewDetails() {
        navigate(`/rakindas/${props.rakinda?.id}/detail`);
    }

    return {
        viewDetails
    };
};
