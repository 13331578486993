import { useAppSelector } from '@frontend/common';
import { accessLogStore, fetchAccessLogs } from '@frontend/user/redux';
import { AccessLog } from '@frontend/user/types';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Column } from 'react-table';

import AccessLogColumns from './access-log-columns';
import { AccessLogListProps } from './access-log-list.component';

interface ViewProps {
    data: AccessLog[];
    columns: Column<AccessLog>[];
    showCreateModal: boolean;
    changeShowCreateModal: Dispatch<SetStateAction<boolean>>;
    page: number;
    pageSize: number;
    changePage: Dispatch<SetStateAction<number>>;
    changePageSize: Dispatch<SetStateAction<number>>;
}

const useAccessLogList = (props: AccessLogListProps): ViewProps => {
    const accessLogState = useAppSelector(useSelector, accessLogStore);
    const [page, changePage] = useState<number>(0);
    const [pageSize, changePageSize] = useState<number>(25);
    const [showCreateModal, changeShowCreateModal] = useState(false);

    useEffect(() => {
        props.dispatch(fetchAccessLogs({ index: page.toString(), size: pageSize.toString() }));
    }, [page, pageSize]);

    const data = useMemo(() => {
        if (accessLogState.accessLogs) {
            return accessLogState.accessLogs.results;
        } else return [];
    }, [accessLogState]);
    const columns = useMemo(() => AccessLogColumns, []);

    return {
        data,
        columns,
        changeShowCreateModal,
        showCreateModal,
        changePage,
        changePageSize,
        page,
        pageSize
    };
};

export default useAccessLogList;
