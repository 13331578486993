import { UpdateNotificationTemplate } from '@frontend/notification-service/types';
import { useState } from 'react';

import { NotificationTemplateClient } from '@frontend/notification-service/api';
import { UpdateNotificationTemplateProps } from './update-notification-template.component';

interface ViewProps {
    submitted: boolean;
    formValues: UpdateNotificationTemplate;
    formValid: FormValid;
    onSubmit: (e: any) => void;
    changeFormValues: React.Dispatch<React.SetStateAction<UpdateNotificationTemplate>>;
    changeFormValid: React.Dispatch<React.SetStateAction<FormValid>>;
}

interface FormValid {
    name: boolean;
    description: boolean;
}

const useUpdateNotificationTemplate = (props: UpdateNotificationTemplateProps): ViewProps => {
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const [formValues, changeFormValues] = useState<UpdateNotificationTemplate>({
        description: props.notificationTemplate?.description ? props.notificationTemplate.description : '',
        name: props.notificationTemplate?.name ? props.notificationTemplate.name : ''
    });
    const [formValid, changeFormValid] = useState<FormValid>({
        name: false,
        description: false
    });
    const onSubmit = (e: any) => {
        e.preventDefault();
        changeSubmitted(true);
        if (!props.notificationTemplate) return;
        NotificationTemplateClient.patchAccountNotificationTemplate(formValues, props.notificationTemplate.account_id, props.notificationTemplate.id).then(
            props.onClose
        );
    };

    return {
        changeFormValid,
        changeFormValues,
        formValid,
        formValues,
        onSubmit,
        submitted
    };
};

export default useUpdateNotificationTemplate;
