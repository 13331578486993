import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { DefaultModalProps } from './default-modal.component';

interface ViewProps {
    modalType: string;
    width: any;
    modalRef: React.MutableRefObject<null>;
}

const useDefaultModal = (props: DefaultModalProps): ViewProps => {
    const modalRef = useRef(null);
    const modalType = props.type ? 'modal-' + props.type.valueOf() : 'modal-prompt';
    const width = props.customWidth !== undefined ? { width: props.customWidth + '%' } : {};
    const location = useLocation();

    useEffect(() => {
        const handleClickOutside = (event: { target: any }) => {
            const queryParams = new URLSearchParams(location.search);
            if (queryParams.has('details')) {
                const details = queryParams.get('details');
                const detailsArray = details!.slice(1, -1).split(',');
                if (!props.id) return;
                const newArray = detailsArray[detailsArray.length - 1].slice(1, -1).split('-');
                newArray.shift();
                if (newArray.join('-') == props.id) {
                    if (modalRef.current && !(modalRef.current as any).contains(event.target)) {
                        props.show && props.handleClose && props.handleClose();
                    }
                }
            } else {
                if (modalRef.current && !(modalRef.current as any).contains(event.target)) {
                    //TODO maybe for future use create wrapper component that removes the top modal only
                    //props.show && props.handleClose && props.handleClose();
                }
            }
        };
        document.addEventListener('mousedown', handleClickOutside, true);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    }, [modalRef, props.handleClose]);

    return {
        modalRef,
        modalType,
        width
    };
};

export default useDefaultModal;
