import { PaginatedResponse } from '@frontend/api-utils';
import { EntityType } from '@frontend/common';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';

import { seed } from './resolve-slice';

export function useResolver<T extends { id: string }>(
    key: string,
    event: keyof WindowEventMap,
    type: EntityType,
    resolveAPI: (ids: string[]) => Promise<PaginatedResponse<T>>,
    dispatch: ThunkDispatch<any, any, Action>
) {
    const [pending, changePending] = useState<string[]>([]);
    const [timeoutSet, changeTimeoutSet] = useState<boolean>(false);
    const [timeoutFinished, changeTimeoutFinished] = useState<boolean>(false);

    useEffect(() => {
        window.addEventListener(event, (e) => listener(e as CustomEvent<string>));
    }, []);

    useEffect(() => {
        if (timeoutFinished) {
            const stored = localStorage.getItem(key);
            if (stored != null) {
                changePending(JSON.parse(stored));
                changeTimeoutSet(false);
                changeTimeoutFinished(false);
                localStorage.removeItem(key);
            }
        }
    }, [timeoutFinished]);

    useEffect(() => {
        if (pending.length > 0) {
            resolveAPI(pending).then((result) => {
                dispatch(seed({ key: type, value: result.results }));
                changePending([]);
                changeTimeoutSet(false);
                changeTimeoutFinished(false);
            });
        }
    }, [pending]);

    const listener = (event: CustomEvent) => {
        if (typeof event.detail == 'string') {
            if (timeoutSet === false) {
                changeTimeoutSet(true);
                setTimeout(() => {
                    changeTimeoutFinished(true);
                }, 1000);
            }
            const stored = localStorage.getItem(key);
            if (stored == null) {
                localStorage.setItem(key, JSON.stringify([event.detail]));
            } else if (!JSON.parse(stored).includes(event.detail)) {
                localStorage.setItem(key, JSON.stringify([...JSON.parse(stored), event.detail]));
            }
        }
    };
}
