import { Logger } from '@frontend/Logger';
import { useAppSelector } from '@frontend/common';
import { fetchSpotModuleSlot, slotStore } from '@frontend/slot/redux';
import { Slot } from '@frontend/slot/types';
import { WorkflowStepTriggeredEvent } from '@frontend/workflow/types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { navigationStore } from '../../redux/user-interface-navigation-slice';
import { UserInterfaceTemplateProps } from '../../user-interface-template-props';

interface ViewProps {
    slot: Slot | null;
    cache: WorkflowStepTriggeredEvent | null;
}

const useSlotOpened = (props: UserInterfaceTemplateProps): ViewProps => {
    const navigationState = useAppSelector(useSelector, navigationStore);
    const cache = props.cache || navigationState.cache;

    const slotState = useAppSelector(useSelector, slotStore);
    const [slot, changeSlot] = useState<Slot | null>(null);

    useEffect(() => {
        if (cache && cache.spot_id && cache.module_id && cache.slot_id) {
            props.dispatch(
                fetchSpotModuleSlot({
                    spotId: cache.spot_id,
                    moduleId: cache.module_id,
                    slotId: cache.slot_id
                })
            );
        } else Logger.error('Incorrect information passed', {}, { cache, slotState });
    }, []);

    useEffect(() => {
        const found = slotState.unordered.find((s) => s.id == cache?.slot_id);
        if (found) changeSlot(found);
    }, [cache, slotState]);

    return { slot, cache };
};

export default useSlotOpened;
