import { useEffect, useState } from 'react';

import { TerminalClient } from './api/terminal-client';
import { Terminal } from './terminal';

declare global {
    interface WindowEventMap {
        'useTerminal:request': CustomEvent<string>;
    }
}

const REQUEST_TERMINAL_EVENT = 'useTerminal:request';
const STORAGE_KEY = 'TRM_REQ';

export function terminalRequest(terminalId: string) {
    window.dispatchEvent(new CustomEvent(REQUEST_TERMINAL_EVENT, { detail: terminalId }));
}

interface Props {
    seedStore: (terminals: Terminal[]) => void;
}

export function useTerminals(props: Props) {
    const [pending, changePending] = useState<string[]>([]);
    const [timeoutSet, changeTimeoutSet] = useState<boolean>(false);
    const [timeoutFinished, changeTimeoutFinished] = useState<boolean>(false);

    useEffect(() => {
        window.addEventListener(REQUEST_TERMINAL_EVENT, listener);
    }, []);

    useEffect(() => {
        if (timeoutFinished) {
            const stored = localStorage.getItem(STORAGE_KEY);
            if (stored != null) {
                changePending(JSON.parse(stored));
                changeTimeoutSet(false);
                changeTimeoutFinished(false);
                localStorage.removeItem(STORAGE_KEY);
            }
        }
    }, [timeoutFinished]);

    useEffect(() => {
        if (pending.length > 0) {
            getTerminals(pending).then((result) => {
                props.seedStore(result);
                changePending([]);
            });
        }
    }, [pending]);

    const listener = (event: CustomEvent) => {
        if (typeof event.detail == 'string') {
            if (timeoutSet === false) {
                changeTimeoutSet(true);
                setTimeout(() => {
                    changeTimeoutFinished(true);
                }, 1000);
            }

            const stored = localStorage.getItem(STORAGE_KEY);
            if (stored == null) {
                localStorage.setItem(STORAGE_KEY, JSON.stringify([event.detail]));
            } else if (!JSON.parse(stored).includes(event.detail)) {
                localStorage.setItem(STORAGE_KEY, JSON.stringify([...JSON.parse(stored), event.detail]));
            }
        }
    };

    async function getTerminals(terminalIds: string[]): Promise<Terminal[]> {
        const response = await TerminalClient.resolveTerminals(terminalIds);
        return response.results;
    }
}
