import { Locale } from '@frontend/common';
import { ContactClient } from '@frontend/contact/api';
import { CreateContact } from '@frontend/contact/types';
import { useState } from 'react';

import { CreateContactFormProps } from './create-contact-form.component';

interface ViewProps {
    submitted: boolean;
    formValues: CreateContact;
    formValid: FormValid;
    onSubmit: (e: any) => void;
    changeFormValues: React.Dispatch<React.SetStateAction<CreateContact>>;
    changeFormValid: React.Dispatch<React.SetStateAction<FormValid>>;
}

interface FormValid {
    alias: boolean;
    first_name: boolean;
    last_name: boolean;
    mobile: boolean;
    email: boolean;
    language: boolean;
    account_id: boolean;
    user_id: boolean;
    type: boolean;
}

const useCreateContactForm = (props: CreateContactFormProps): ViewProps => {
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const [formValues, changeFormValues] = useState<CreateContact>({ language: Locale.ENGLISH, alias: '' });
    const [formValid, changeFormValid] = useState<FormValid>({
        alias: false,
        first_name: true,
        last_name: true,
        mobile: true,
        email: true,
        language: true,
        account_id: true,
        user_id: true,
        type: true
    });

    const onSubmit = (e: any) => {
        e.preventDefault();
        changeSubmitted(true);
        ContactClient.postContact(formValues).then((contact) => {
            props.callback && props.callback(contact);
            props.onClose();
        });
    };

    return {
        submitted,
        formValues,
        formValid,
        onSubmit,
        changeFormValues,
        changeFormValid
    };
};

export default useCreateContactForm;
