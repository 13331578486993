import { DetailPageType, useDetailPage } from '@frontend/common';
import { CommonMessage } from '@frontend/lang';
import { Terminal } from '@frontend/terminal-service';
import React from 'react';

import { DateBadge } from '../../badge-components/date-badge/date-badge.component';
import { ObjectField } from '../../modals/object-field.component';

interface TerminalDetailProps {
    terminal: Terminal;
}

export const TerminalDetail = (props: TerminalDetailProps): JSX.Element => {
    const viewProps = useDetailPage(DetailPageType.TERMINAL);

    return (
        <>
            <ObjectField
                fieldName='Identifier'
                value={props.terminal.identifier}
            />
            {viewProps.isDetailPage && (
                <>
                    <ObjectField
                        fieldName={CommonMessage.OBJECTS.DEFAULT.CREATION_TIMESTAMP}
                        value={
                            <DateBadge
                                date={props.terminal.creation_timestamp}
                                displayTime
                                inline
                            />
                        }
                    />
                    <ObjectField
                        fieldName={CommonMessage.OBJECTS.DEFAULT.UPDATE_TIMESTAMP}
                        value={
                            <DateBadge
                                date={props.terminal.update_timestamp}
                                displayTime
                                inline
                            />
                        }
                    />
                </>
            )}
        </>
    );
};
