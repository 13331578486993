import { ClassType } from '@frontend/common';
import { DriverType } from '@frontend/edge/types';
import { AsyncComponent, ModalFooter } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';
import { UserSelect } from '@frontend/user/components';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React, { lazy } from 'react';
import { FaPlus } from 'react-icons/fa';

import { AccountSelect } from '@frontend/account/common';
import { TextInput } from '@frontend/basic-forms';
import { SpotSelect } from '@frontend/spot/common';
import { AccessLogType } from '@frontend/user/types';
import { AccessLogTypeSelect } from '../access-log-type-select/access-log-type-select.component';
import useCreateAccessLogForm from './create-access-log-form.controller';

const DriverSelect = lazy(() => import('@frontend/edge/common').then((module) => ({ default: module.DriverSelect })));

const ID = 'create-access-log-form';

export interface CreateAccessLogFormProps {
    dispatch: ThunkDispatch<any, any, Action>;
    onClose: () => void;
}

export const CreateAccessLogForm = (props: CreateAccessLogFormProps) => {
    const viewProps = useCreateAccessLogForm(props);
    return (
        <div id={ID}>
            <form onSubmit={viewProps.onSubmit}>
                <div className='modal-header'>
                    <h4>{CommonMessage.FORMS.CREATE_OBJECT('Access log')}</h4>
                </div>
                <div className='modal-body'>
                    <div className='d-flex flex-row'>
                        <SpotSelect
                            dispatch={props.dispatch}
                            submitted={viewProps.submitted}
                            allowURLChange={false}
                            onChange={(v) => viewProps.changeFormValues({ ...viewProps.formValues, spot_id: v })}
                            value={viewProps.formValues.spot_id}
                            className="w-100 m-1"
                            required
                        />
                    </div>
                    <div className='d-flex flex-row'>
                        <AccountSelect
                            dispatch={props.dispatch}
                            submitted={viewProps.submitted}
                            className='w-50 m-1'
                            value={viewProps.formValues.account_id}
                            onChange={(v) => {
                                viewProps.changeFormValues({ ...viewProps.formValues, account_id: v! });
                                viewProps.changeFormValid({ ...viewProps.formValid, account_id: !!v, user_id: false });
                            }}
                            required
                            allowURLChange={false}
                        />
                        <UserSelect
                            dispatch={props.dispatch}
                            submitted={viewProps.submitted}
                            className='w-50 m-1'
                            value={viewProps.formValues.user_id}
                            required
                            queryParams={{ account_id: viewProps.formValues.account_id }}
                            disabled={!viewProps.formValues.account_id}
                            onChange={(v) => {
                                viewProps.changeFormValues({ ...viewProps.formValues, user_id: v! });
                                viewProps.changeFormValid({ ...viewProps.formValid, user_id: !!v });
                            }}
                            allowURLChange={false}
                        />
                        <TextInput
                            label='User Type'
                            value={viewProps.formValues.user_type}
                            className='w-50 m-1'
                            onChange={(v) => viewProps.changeFormValues({ ...viewProps.formValues, user_type: v })}
                            submitted={viewProps.submitted}
                        />
                    </div>
                    <div className='d-flex flex-row'>
                        <AccessLogTypeSelect
                            submitted={viewProps.submitted}
                            value={viewProps.formValues.access_type}
                            onChange={(v) => {
                                viewProps.changeFormValues({ ...viewProps.formValues, access_type: v as AccessLogType });
                                viewProps.changeFormValid({ ...viewProps.formValid, access_type: !!v });
                            }}
                            className="w-50 m-1"
                            allowURLChange={false}
                        />
                        <AsyncComponent>
                            <DriverSelect
                                dispatch={props.dispatch}
                                submitted={viewProps.submitted}
                                value={viewProps.formValues.driver_id}
                                onChange={(v) => viewProps.changeFormValues({ ...viewProps.formValues, driver_id: v ?? '' })}
                                onChangeObject={(v) => viewProps.changeFormValues({ ...viewProps.formValues, driver_type: v ? v.type : null  })}
                                className='w-50 m-1'
                                queryParams={{ type: DriverType.ACCESS_CONTROLLER }}
                                allowURLChange={false}
                            />
                        </AsyncComponent>
                    </div>
                </div>
                <ModalFooter
                    justifyContent='between'
                    buttons={[
                        {
                            hide: false,
                            text: CommonMessage.BUTTONS.CANCEL,
                            type: ClassType.SECONDARY,
                            onClick: (e) => {
                                e?.preventDefault();
                                props.onClose();
                            }
                        },
                        {
                            hide: false,
                            text: CommonMessage.BUTTONS.SUBMIT,
                            type: ClassType.SUCCESS,
                            icon: FaPlus,
                            buttonType: 'submit',
                            disabled: Object.values(viewProps.formValid).some((a) => !a)
                        }
                    ]}
                />
            </form>
        </div>
    );
};
