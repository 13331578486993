import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

import { TerminalClient } from '../api/terminal-client';
import { Terminal, TerminalListResponse } from '../terminal';

export interface TerminalState {
    unordered: Terminal[];
    terminals: TerminalListResponse | null;
    status: SliceStatus;
}

const initialState: TerminalState = {
    unordered: [],
    terminals: null,
    status: SliceStatus.INIT
};

const terminalSlice = createSlice({
    name: 'terminals',
    initialState,
    reducers: {
        seedTerminals(state, action: PayloadAction<Terminal[]>) {
            state.unordered = [...state.unordered.filter((terminal) => action.payload.find((t) => t.id == terminal.id) == undefined), ...action.payload];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTerminals.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchTerminals.fulfilled, (state, action) => {
                const startPos = toNumber(action.meta.arg.size) * toNumber(action.meta.arg.index);
                if (state.terminals == null) {
                    state.terminals = {
                        ...action.payload,
                        results: new Array(action.payload.count)
                    };
                    state.terminals.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.terminals.results.length !== action.payload.count) {
                        state.terminals.count = action.payload.count;
                        state.terminals.results = new Array(action.payload.count);
                    }
                    state.terminals.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.unordered = [
                    ...state.unordered.filter((terminal) => action.payload.results.find((t) => t.id == terminal.id) == undefined),
                    ...action.payload.results
                ];
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchTerminal.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchTerminal.fulfilled, (state, action) => {
                if (state.terminals) {
                    const found = state.terminals.results.find((t) => t.id == action.meta.arg);
                    if (found) {
                        state.terminals.results.splice(state.terminals.results.indexOf(found), 1, action.payload);
                    } else state.terminals.results.push(action.payload);
                }
                state.unordered = [...state.unordered.filter((term) => action.payload.id !== term.id), action.payload];
            })
            .addCase(deleteTerminal.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(deleteTerminal.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                if (state.terminals == null) return;
                const found = state.terminals.results.find((t) => t.id == action.meta.arg);
                if (found == undefined) return;
                state.terminals.results.splice(state.terminals.results.indexOf(found), 1);
            })
            .addCase(deleteTerminals.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(deleteTerminals.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                state.unordered = state.unordered.filter((u) => !action.meta.arg.includes(u.id));
                if (state.terminals == null) return;
                state.terminals.count = state.terminals.count - action.meta.arg.length;
                state.terminals.results = state.terminals.results.filter((t) => !action.meta.arg.includes(t.id));
            });
    }
});

export const fetchTerminals = createAsyncThunk<TerminalListResponse, ApiQueryParams<DefaultQueryParams>>(
    'fetchTerminals',
    async (queryParams: ApiQueryParams<DefaultQueryParams>, { rejectWithValue }) => {
        try {
            return await TerminalClient.fetchTerminals(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchTerminal = createAsyncThunk<Terminal, string>('fetchTerminal', async (terminalId: string, { rejectWithValue }) => {
    try {
        return await TerminalClient.fetchTerminal(terminalId);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const deleteTerminal = createAsyncThunk<void, string>('deleteTerminal', async (terminalId: string, { rejectWithValue }) => {
    try {
        return await TerminalClient.deleteTerminal(terminalId);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const deleteTerminals = createAsyncThunk<void, string[]>('deleteTerminals', async (terminalIds: string[], { rejectWithValue }) => {
    try {
        return await TerminalClient.deleteTerminals(terminalIds);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const terminalStore = { terminals: terminalSlice.reducer };
export const { seedTerminals } = terminalSlice.actions;
