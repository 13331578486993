import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';

import { CreateRakinda, Rakinda, RakindaListResponse, SearchRakindasQueryParams, UpdateRakinda } from '../rakinda';

const endpoint = '/rakinda-api/v1';
export class RakindaClient extends APIClient {
    public static async fetchRakindas(queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<RakindaListResponse> {
        return await this.apiPaginated<RakindaListResponse, DefaultQueryParams>(`${endpoint}/rakindas`, queryParams);
    }

    public static async postRakinda(body: CreateRakinda): Promise<Rakinda> {
        const response = await this.post(`${endpoint}/rakindas`, body);
        return await this.handleResponse<Rakinda>(response);
    }

    public static async searchRakindas(queryParams?: ApiQueryParams<SearchRakindasQueryParams>): Promise<RakindaListResponse> {
        return await this.apiPaginated<RakindaListResponse, SearchRakindasQueryParams>(`${endpoint}/rakindas-search`, queryParams);
    }

    public static async fetchRakinda(rakindaId: string): Promise<Rakinda> {
        const response = await this.fetch(`${endpoint}/rakindas/${rakindaId}`);
        return await this.handleResponse<Rakinda>(response);
    }

    public static async patchRakinda(rakindaId: string, body: UpdateRakinda): Promise<Rakinda> {
        const response = await this.patch(`${endpoint}/rakindas/${rakindaId}`, body);
        return await this.handleResponse<Rakinda>(response);
    }

    public static async deleteRakinda(rakindaId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/rakindas/${rakindaId}`);
        return await this.handleVoidResponse(response);
    }

    public static async resolveRakindas(rakindaIds: string[]): Promise<RakindaListResponse> {
        const response = await this.post(`${endpoint}/rakindas-resolve`, { ids: rakindaIds });
        return await this.handleResponse<RakindaListResponse>(response);
    }

    public static async deleteRakindas(rakindaIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/rakindas-delete`, { ids: rakindaIds });
        return await this.handleVoidResponse(response);
    }
}
