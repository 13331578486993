import { EntityType } from '@frontend/common';
import { DefaultModalProps, ModalType } from '@frontend/elements';
import { ObjectDetailModal } from '@frontend/rendering/modal';
import { userRequest } from '@frontend/user/events';
import { User } from '@frontend/user/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React, { useState } from 'react';

import { UserDetail } from '../user-detail.component';

export interface UserDetailModalProps extends DefaultModalProps {
    id: string;
    dispatch: ThunkDispatch<any, any, Action>;
}

export const UserDetailModal = (props: UserDetailModalProps) => {
    const [user, changeUser] = useState<User | null>(null);
    return (
        <ObjectDetailModal<User>
            type={ModalType.PANE}
            dispatch={props.dispatch}
            id={props.id}
            callback={changeUser}
            objectType={EntityType.USER}
            handleClose={props.handleClose}
            show={props.show}
            resolve={userRequest}>
            {user && (
                <div className='d-flex flex-column justify-content-between align-items-between h-100'>
                    <div>
                        <UserDetail user={user} />
                    </div>
                </div>
            )}
        </ObjectDetailModal>
    );
};

