import { Logger } from '@frontend/Logger';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

import { addSlot, removeSlot, updateSlot } from '@frontend/slot/redux';
import { Slot } from '@frontend/slot/types';

enum MessageTypes {
    UPDATED = 'slot_updated',
    DELETED = 'slot_deleted',
    CREATED = 'slot_created'
}

export class SlotEventHandler {
    #dispatch: ThunkDispatch<any, any, Action>;

    constructor(dispatch: ThunkDispatch<any, any, Action>) {
        this.#dispatch = dispatch;
    }
    public onMessageEvent(event: MessageEvent<string>): void {
        const payload = JSON.parse(event.data) as { message: MessageTypes; data: Slot; changes?: any };
        switch (payload.message) {
            case MessageTypes.UPDATED:
                this.onUpdate(payload);
                break;
            case MessageTypes.DELETED:
                this.onDelete(payload);
                break;
            case MessageTypes.CREATED:
                this.onCreate(payload);
                break;
        }
    }

    private onUpdate(payload: { message: MessageTypes; data: Slot; changes?: any }) {
        Logger.log('Updating slot in store', {}, payload.data);
        this.#dispatch(updateSlot(payload.data));
    }

    private onDelete(payload: { message: MessageTypes; data: Slot }) {
        Logger.log('Removing slot from store', {}, payload.data);
        this.#dispatch(removeSlot(payload.data.id));
    }

    private onCreate(payload: { message: MessageTypes; data: Slot; changes?: any }) {
        Logger.log('Adding slot to store', {}, payload.data);
        this.#dispatch(addSlot(payload.data));
    }
}
