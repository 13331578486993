import { WorkflowVariable } from '@frontend/workflow/types';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

import { TransactionVariable } from '../../transaction-variables';
import useVariableInput from './transaction-input.controller';

const ID = 'transaction-variable-input';
export interface VariableInputProps {
    variable: TransactionVariable | WorkflowVariable;
    accountId: string;
    onChange: (variable: TransactionVariable | WorkflowVariable) => void;
    submitted: boolean;
    dispatch: ThunkDispatch<any, any, AnyAction>;
}

const VariableInput = (props: VariableInputProps) => {
    const viewProps = useVariableInput(props);

    return <div id={ID}>{viewProps.input}</div>;
};

export default VariableInput;
