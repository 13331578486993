import { Logger } from '@frontend/Logger';

import { AuthenticationError, DriverProvision, Token } from './models';

export default class DriverAuthenticationClient {
    static async provision(provision: DriverProvision): Promise<Token> {
        Logger.debug('Sending provision request.');

        let signInUri = 'https://edge-api.' + process.env['NX_API_DOMAIN'] + '/v1/provision';
        if (process.env['NX_BUILD_ENV'] === 'edge') {
            signInUri = 'http://localhost:8001/v1/provision';
        }

        const response = await fetch(signInUri, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(provision)
        });
        Logger.debug('Login response code: ' + response.status);
        if (response.status !== 200) {
            throw new AuthenticationError('provision failed.', await response.json());
        }
        return response.json();
    }

    public static async authorizeUser(provision: DriverProvision, accountId: string, userId: string): Promise<Token> {
        Logger.debug('Authorizing user request.');

        let signInUri = `https://edge-api.${process.env['NX_API_DOMAIN']}/v1/accounts/${accountId}/users/${userId}/authorize`;
        if (process.env['NX_BUILD_ENV'] === 'edge') {
            signInUri = `http://localhost:8001/v1/accounts/${accountId}/users/${userId}/authorize`;
        }

        const response = await fetch(signInUri, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(provision)
        });
        Logger.debug('Login response code: ' + response.status);
        if (response.status !== 200) {
            throw new AuthenticationError('provision failed.', await response.json());
        }
        return response.json();
    }
}
