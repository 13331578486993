import { Logger } from '@frontend/Logger';
import { useAppSelector } from '@frontend/common';
import { ConstraintClient } from '@frontend/constraint/api';
import { ConstraintType, OccupancyConstraintData } from '@frontend/constraint/types';
import { PackageClient, PackageWorkflowClient } from '@frontend/package/api';
import { fetchPackage, packageStore } from '@frontend/package/redux';
import { Package, PackageState } from '@frontend/package/types';
import { fetchSpotModuleSlot, slotStore } from '@frontend/slot/redux';
import { Slot } from '@frontend/slot/types';
import { TransactionClient } from '@frontend/transaction/api';
import { Transaction } from '@frontend/transaction/types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { navigationStore } from '../../redux/user-interface-navigation-slice';
import { ChooseAckSlotProps } from './choose-ack-slot.component';

interface ViewProps {
    state: ChooseAckSlotState;
    changeState: (state: ChooseAckSlotState) => void;
    slot: Slot | null;
    changeSlot: (slot: Slot | null) => void;
}

export enum ChooseAckSlotState {
    INIT = 'init',
    CHANGE_SLOT = 'change_slot',
    SUBMITTED = 'submitted'
}

const useChooseAckSlot = (props: ChooseAckSlotProps): ViewProps => {
    const navigationState = useAppSelector(useSelector, navigationStore);
    const packageState = useAppSelector(useSelector, packageStore);
    const slotState = useAppSelector(useSelector, slotStore);
    const cache = props.cache || navigationState.cache;
    const [state, changeState] = useState<ChooseAckSlotState>(ChooseAckSlotState.INIT);

    const [transaction, changeTransaction] = useState<Transaction | null>(null);
    const [pack, changePackage] = useState<Package | null>(null);
    const [slot, changeSlot] = useState<Slot | null>(null);

    useEffect(() => {
        if (cache && cache.account_id && cache.transaction_id && cache.package_id) {
            props.dispatch(
                fetchPackage({
                    accountId: cache.account_id,
                    transactionId: cache.transaction_id,
                    packageId: cache.package_id
                })
            );
            TransactionClient.fetchAccountTransaction(cache.account_id, cache.transaction_id).then(changeTransaction);
        } else Logger.error('Incorrect information passed', {}, { cache, packageState });
    }, []);

    useEffect(() => {
        const found = packageState.unordered.find((s) => s.id == cache?.package_id);
        if (found) changePackage(found);
    }, [cache, packageState]);

    useEffect(() => {
        if (pack) {
            props.dispatch(
                fetchSpotModuleSlot({
                    spotId: pack.spot_id,
                    moduleId: pack.module_id,
                    slotId: pack.slot_id
                })
            );
        } else Logger.error('Incorrect information passed', {}, { pack, slotState });
    }, [pack]);

    useEffect(() => {
        const found = slotState.unordered.find((s) => s.id == pack?.slot_id);
        if (found) changeSlot(found);
    }, [pack, slotState]);

    useEffect(() => {
        if (!pack) return;
        if (state == ChooseAckSlotState.SUBMITTED) {
            if (pack.state === PackageState.PACKAGE_DROP_OFF_PENDING.value) {
                PackageWorkflowClient.updatePackageState(pack.account_id, pack.transaction_id, pack.id, PackageState.PACKAGE_DROP_OFF_STARTED.value);
            } else if (pack.state === PackageState.PACKAGE_PICK_UP_PENDING.value) {
                PackageWorkflowClient.updatePackageState(pack.account_id, pack.transaction_id, pack.id, PackageState.PACKAGE_PICK_UP_STARTED.value);
            }
        }
    }, [state, pack]);

    const selectSlotCallback = (newSlot: Slot | null) => {
        if (!newSlot || !transaction || !pack) return;
        const oldSlot = slot;
        changeSlot(newSlot);
        const toRemove = oldSlot ? ConstraintClient.fetchConstraints({ slot_id: oldSlot.id }) : null;
        ConstraintClient.postSpotConstraint(newSlot.spot_id, {
            type: ConstraintType.OCCUPANCY,
            strict: true,
            slot_id: newSlot.id,
            data: {
                account_id: transaction.account_id,
                transaction_id: transaction.id,
                workflow_id: transaction.workflow_id
            }
        }).then(() => {
            PackageClient.patchAccountTransactionPackage(transaction.account_id, transaction.id, pack.id, {
                slot_id: newSlot.id
            }).then((result) => {
                changePackage(result);
                if (oldSlot && toRemove) {
                    toRemove.then((constraints) => {
                        const found = constraints.results.find(
                            (c) => c.type == ConstraintType.OCCUPANCY && (c.data as OccupancyConstraintData)?.transaction_id == transaction.id
                        );
                        found && ConstraintClient.deleteSpotConstraint(oldSlot.spot_id, found.id);
                    });
                }
            });
        });
        changeState(ChooseAckSlotState.INIT);
    };

    return {
        state,
        changeState,
        slot,
        changeSlot: selectSlotCallback
    };
};

export default useChooseAckSlot;
