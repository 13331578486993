import { DateBadge, ObjectField } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';
import React from 'react';

import { Rakinda } from '../rakinda';

export interface RakindaDetailProps {
    rakinda: Rakinda;
}

export const RakindaDetail = (props: RakindaDetailProps) => {
    return (
        <>
            <ObjectField
                fieldName={CommonMessage.FORMS.NAME}
                value={props.rakinda.name}
            />
            <ObjectField
                fieldName={CommonMessage.OBJECTS.RAKINDA.MASTER_PIN_CODE}
                value={props.rakinda.master_pin_code}
            />
            <ObjectField
                fieldName={CommonMessage.OBJECTS.DEFAULT.CREATION_TIMESTAMP}
                value={
                    <DateBadge
                        date={props.rakinda.creation_timestamp}
                        displayTime
                        inline
                    />
                }
            />
            <ObjectField
                fieldName={CommonMessage.OBJECTS.DEFAULT.UPDATE_TIMESTAMP}
                value={
                    <DateBadge
                        date={props.rakinda.update_timestamp}
                        displayTime
                        inline
                    />
                }
            />
            <ObjectField
                fieldName={CommonMessage.OBJECTS.RAKINDA.HEARTBEAT}
                value={
                    <DateBadge
                        date={props.rakinda.heartbeat_timestamp}
                        displayTime
                        inline
                    />
                }
            />
        </>
    );
};
