import { useAppSelector } from '@frontend/common';
import { fetchUserInterfaces, userInterfaceStore } from '@frontend/user-interface/redux';
import { UserInterface } from '@frontend/user-interface/types';
import { WorkflowVariable } from '@frontend/workflow/types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { AddTransactionVariablesProps } from './add-transaction-variables-form.component';

interface ViewProps {
    userInterface: UserInterface | null;
    changeUserInterface: React.Dispatch<React.SetStateAction<UserInterface | null>>;
    isLoading: boolean;
}

const useTransactionVariables = (props: AddTransactionVariablesProps): ViewProps => {
    const UIState = useAppSelector(useSelector, userInterfaceStore);
    const [userInterface, changeUserInterface] = useState<UserInterface | null>(null);
    const [isLoading, changeIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (UIState && UIState.userInterfaces && UIState.userInterfaces.results && props.event.data.user_interface_id != null) {
            changeIsLoading(false);
            const found = UIState.userInterfaces.results.find((ui) => ui.id == props.event.data.user_interface_id);
            if (found) changeUserInterface(found);
        } else changeIsLoading(true);
    }, [props.event, UIState]);

    useEffect(() => {
        props.dispatch(fetchUserInterfaces({}));
    }, []);

    return {
        userInterface,
        changeUserInterface,
        isLoading
    };
};

export default useTransactionVariables;

export function isRequired(variable: WorkflowVariable): boolean {
    if (!variable.visible) return true;
    return !variable.editable;
}
