import { Product } from '@frontend/product/types';
import React from 'react';

import accuIMG from './assets/Accu_Boormachine_Makita.png';
import filterIMG from './assets/Filter_Dräger_X-plore_Bajonet.png';
import gasmaskerIMG from './assets/Gasmasker_Dräger_X-plore_5500_Volgelaatsmasker.png';
import handschoenenIMG from './assets/Handschoenen_Stihl.png';
import momentsleutelIMG from './assets/Momentsleutel Facom.png';
import mondmaskerIMG from './assets/Mondmasker_FFP3_Sealey.png';
import multimeterIMG from './assets/Multimeter_Fluke.png';
import veiligheidsschoenIMG from './assets/Veiligheidsschoen_Bata_Traxx.png';

/**
 * @derprecated - should only be used for demo (remove ASAP)
 * @param props
 * @returns
 */
const TempImageLoader = (props: { product: Product; className: string }) => {
    return (
        <>
            {props.product.name?.toLowerCase().includes('accu') && (
                <img
                    src={accuIMG}
                    className={props.className}
                />
            )}
            {props.product.name?.toLowerCase().includes('filter') && (
                <img
                    src={filterIMG}
                    className={props.className}
                />
            )}
            {props.product.name?.toLowerCase().includes('gasmasker') && (
                <img
                    src={gasmaskerIMG}
                    className={props.className}
                />
            )}
            {props.product.name?.toLowerCase().includes('handschoenen') && (
                <img
                    src={handschoenenIMG}
                    className={props.className}
                />
            )}
            {props.product.name?.toLowerCase().includes('momentsleutel') && (
                <img
                    src={momentsleutelIMG}
                    className={props.className}
                />
            )}
            {props.product.name?.toLowerCase().includes('mondmasker') && (
                <img
                    src={mondmaskerIMG}
                    className={props.className}
                />
            )}
            {props.product.name?.toLowerCase().includes('multimeter') && (
                <img
                    src={multimeterIMG}
                    className={props.className}
                />
            )}
            {props.product.name?.toLowerCase().includes('veiligheidsschoen') && (
                <img
                    src={veiligheidsschoenIMG}
                    className={props.className}
                />
            )}
        </>
    );
};

export default TempImageLoader;
