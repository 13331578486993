import { ApiError } from '@frontend/api-utils';
import { ToastUtil } from '@frontend/toast-utils';
import { useState } from 'react';


import { WorkflowClient } from '@frontend/workflow/api';
import { CreateWorkflow, WorkflowType } from '@frontend/workflow/types';
import { Props } from './create-workflow-form.component';

interface ViewProps {
    submitted: boolean;
    formValues: CreateWorkflow;
    accountId: string | null;
    formValid: { name: boolean; account: boolean };
    onSubmit: (e: any) => void;
    changeFormValues: React.Dispatch<React.SetStateAction<CreateWorkflow>>;
    changeAccountId: React.Dispatch<React.SetStateAction<string | null>>;
    changeFormValid: React.Dispatch<React.SetStateAction<{ name: boolean; account: boolean }>>;
}

const useCreateWorkflowForm = (props: Props): ViewProps => {
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const [formValues, changeFormValues] = useState<CreateWorkflow>({ name: '', active: false, revision: 0, type: WorkflowType.TRANSACTION });
    const [accountId, changeAccountId] = useState<string | null>(null);
    const [formValid, changeFormValid] = useState<{ name: boolean; account: boolean }>({ name: false, account: false });

    const onSubmit = (e: any) => {
        e.preventDefault();
        changeSubmitted(true);

        WorkflowClient.postAccountWorkflow(formValues, accountId!)
            .then((workflow) => {
                ToastUtil.success(`Successfully created workflow ${workflow.name}`);
                props.callback && props.callback(workflow);
                props.onClose();
            })
            .catch((error: ApiError) => {
                if (error.json && (error.json as any).detail && (error.json as any).detail.length > 0) {
                    const message = (error.json as any).detail[0].msg;
                    ToastUtil.error(message);
                } else ToastUtil.error(`Failed to create workflow.`);
            })
            .finally(() => changeSubmitted(false));
    };
    return {
        submitted,
        formValues,
        accountId,
        formValid,
        onSubmit,
        changeFormValues,
        changeAccountId,
        changeFormValid
    };
};

export default useCreateWorkflowForm;
