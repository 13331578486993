import { AccountClient } from '@frontend/account/api';
import { UpdateAccount } from '@frontend/account/types';
import { parseObject } from '@frontend/common';
import { ErrorHandler } from '@frontend/error-handler';
import { useState } from 'react';

import { UpdateAccountFormProps } from './update-account-form.component';

interface ViewProps {
    submitted: boolean;
    formValues: UpdateAccount;
    formValid: FormValid;
    onSubmit: (e: any) => void;
    changeFormValues: React.Dispatch<React.SetStateAction<UpdateAccount>>;
    changeFormValid: React.Dispatch<React.SetStateAction<FormValid>>;
}

interface FormValid {
    name: boolean;
    address_line_1: boolean;
    address_line_2: boolean;
    zip_code: boolean;
    city: boolean;
    country: boolean;
    contact: boolean;
}
const useUpdateAccountForm = (props: UpdateAccountFormProps): ViewProps => {
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const [formValues, changeFormValues] = useState<UpdateAccount>({
        name: props.account.name,
        address_line_1: props.account.address_line_1,
        address_line_2: props.account.address_line_2,
        city: props.account.city,
        contact_info: props.account.contact_info,
        country: props.account.country,
        zip_code: props.account.zip_code
    });
    const [formValid, changeFormValid] = useState<FormValid>({
        name: true,
        address_line_1: true,
        address_line_2: true,
        zip_code: true,
        city: true,
        country: true,
        contact: true
    });

    const onSubmit = (e: any) => {
        e.preventDefault();
        changeSubmitted(true);
        const body = parseObject(formValues);
        AccountClient.patchAccount(body, props.account.id)
            .then(props.onClose)
            .catch((err) => ErrorHandler.handleError(err));
    };

    return {
        changeFormValid,
        changeFormValues,
        formValid,
        formValues,
        onSubmit,
        submitted
    };
};

export default useUpdateAccountForm;
