import { Logger } from '@frontend/Logger';
import { PubSubConnection } from '@frontend/pub-sub';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

import { addUser, removeUser, updateUser } from '@frontend/user/redux';
import { User } from '@frontend/user/types';


enum MessageTypes {
    UPDATED = 'user_updated',
    DELETED = 'user_deleted',
    CREATED = 'user_created'
}
/**
 * @deprecated
 * @description This class only exists to support the mobile app
 */
export class OldUserEventListener extends PubSubConnection {
    private static instance: OldUserEventListener | null = null;
    #dispatch: ThunkDispatch<any, any, Action>;
    private constructor(dispatch: ThunkDispatch<any, any, Action>, variables?: Array<{ [key: string]: any }>) {
        super('user', variables);
        this.#dispatch = dispatch;
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>, variables?: Array<{ [key: string]: any }> ): OldUserEventListener {
        if (this.instance == null) {
            this.instance = new OldUserEventListener(dispatch, variables);
        }
        return this.instance;
    }

    protected override onMessageEvent(event: MessageEvent<string>): void {
        const payload = JSON.parse(event.data) as { message: MessageTypes; data: User; changes?: any };
        switch (payload.message) {
            case MessageTypes.UPDATED:
                this.onUpdate(payload);
                break;
            case MessageTypes.DELETED:
                this.onDelete(payload);
                break;
            case MessageTypes.CREATED:
                this.onCreate(payload);
                break;
        }
    }

    private onUpdate(payload: { message: MessageTypes; data: User; changes?: any }) {
        Logger.log('Updating user in store', { user: payload.data.id }, payload.data);
        this.#dispatch(updateUser(payload.data));
    }

    private onDelete(payload: { message: MessageTypes; data: User }) {
        Logger.log('Removing user from store', { user: payload.data.id }, payload.data);
        this.#dispatch(removeUser(payload.data.id));
    }

    private onCreate(payload: { message: MessageTypes; data: User; changes?: any }) {
        Logger.log('Adding user to store', { user: payload.data.id }, payload.data);
        this.#dispatch(addUser(payload.data));
    }
}