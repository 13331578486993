import React from 'react';

interface Props {
    loaded?: boolean;
    children?: React.ReactNode;
    className?: string;
    type?: 'wave' | 'glow';
}

export const StringPlaceholder = (props: Props) => {
    return (
        <span className={`placeholder-${props.type || 'glow'} `}>
            {props.loaded ? props.children : <span className={`placeholder ${props.className ?? ''}`}>Loading</span>}
        </span>
    );
};
