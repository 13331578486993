import { BadgeTemplate, DetailModalType } from '@frontend/elements';
import { ENTITY_ICONS } from '@frontend/icons';
import React from 'react';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import useSpotBadge from './spot-badge.controller';

const ID = 'spot-badge';

export interface SpotBadgeProps {
    id?: string | null;
    name?: string;
}

export const SpotBadge = (props: SpotBadgeProps) => {
    const viewProps = useSpotBadge(props);

    return (
        <BadgeTemplate
            objectId={props.id || ID}
            type={DetailModalType.SPOT}
            disabled={viewProps.disabled}>
            <ENTITY_ICONS.SPOT className='me-2' />
            {viewProps.displayName ? (
                <OverlayTrigger
                    overlay={<Tooltip id={'tooltip'}>{viewProps.displayName}</Tooltip>}
                    placement='top'
                    delay={{ show: 300, hide: 150 }}>
                    <span>{viewProps.displayName.length > 30 ? viewProps.displayName.slice(0, 20) + '...' : viewProps.displayName}</span>
                </OverlayTrigger>
            ) : (
                <span className='placeholder-glow'>
                    <span className='placeholder'>loading</span>
                </span>
            )}
        </BadgeTemplate>
    );
};
