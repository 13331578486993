import { Logger } from '@frontend/Logger';
import { FormEvent, useState } from 'react';


import { WorkflowClient } from '@frontend/workflow/api';
import { UpdateWorkflow } from '@frontend/workflow/types';
import { UpdateWorkflowFormProps } from './update-workflow-form.component';

interface ViewProps {
    formValues: UpdateWorkflow;
    changeFormValues: (values: UpdateWorkflow) => void;
    formValid: FormValid;
    changeFormValid: (valid: FormValid) => void;
    onSubmit: (e: FormEvent) => void;
    submitted: boolean;
}

interface FormValid {
    name: boolean;
    active: boolean;
    revision: boolean;
}

const useUpdateWorkflowForm = (props: UpdateWorkflowFormProps): ViewProps => {
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const [formValues, changeFormValues] = useState<UpdateWorkflow>({
        active: props.workflow.active,
        name: props.workflow.name,
        revision: props.workflow.revision,
        type: props.workflow.type
    });
    const [formValid, changeFormValid] = useState<FormValid>({
        active: true,
        name: true,
        revision: true
    });

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (!props.workflow) return;
        changeSubmitted(true);
        WorkflowClient.patchAccountWorkflow(formValues, props.workflow.account_id, props.workflow.id)
            .catch(() => Logger.error(`Failed to update workflow ${props.workflow.id}`))
            .finally(props.onClose);
    };

    return {
        changeFormValid,
        changeFormValues,
        formValid,
        formValues,
        onSubmit,
        submitted
    };
};

export default useUpdateWorkflowForm;
