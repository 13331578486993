import { AuthenticationManager } from '@frontend/authentication-v2';
import { Breadcrumbs } from '@frontend/breadcrumbs';
import { ClassType } from '@frontend/common';
import { Dropdown, DropdownItem, LanguageSelect } from '@frontend/elements';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import { FaUser } from 'react-icons/fa';
import { IoExit } from 'react-icons/io5';

import useHeader from './header-controller';

export const Header = (props: { dispatch: ThunkDispatch<any, any, Action> }) => {
    const viewProps = useHeader(props);
    return (
        <nav
            className='navbar navbar-main navbar-expand-lg px-2 shadow-none bg-primary'
            id='navbarBlur'
            data-scroll='false'>
            <div className='container-fluid py-1 px-3 mt-1'>
                <div className='sidenav-toggler sidenav-toggler-inner d-xl-block d-none pe-5'>
                    <div
                        onClick={() => viewProps.changeNavbarPinned(!viewProps.navbarPinned)}
                        className='nav-link p-0 cursor-pointer'>
                        <div className='sidenav-toggler-inner'>
                            <i className='sidenav-toggler-line bg-light'></i>
                            <i className='sidenav-toggler-line bg-light'></i>
                            <i className='sidenav-toggler-line bg-light'></i>
                        </div>
                    </div>
                </div>

                <Breadcrumbs />
                <div
                    className='collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4'
                    id='navbar'>
                    <span className='ms-md-auto pe-md-0'>
                        <Dropdown
                            label={viewProps.username}
                            icon={FaUser}
                            classType={ClassType.LIGHT}>
                            <DropdownItem
                                label='Profile'
                                onClick={() => viewProps.navigate('/profile')}
                                classType={ClassType.PRIMARY}
                                icon={FaUser}
                            />
                            <DropdownItem
                                label='Sign out'
                                icon={IoExit}
                                classType={ClassType.DANGER}
                                onClick={() => AuthenticationManager.getInstance().doLogout()}
                            />
                        </Dropdown>

                        <span className='ms-2'>
                            <LanguageSelect />
                        </span>
                    </span>
                </div>
            </div>
        </nav>
    );
};
