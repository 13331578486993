import { useAppSelector } from '@frontend/common';
import { fetchUserStates, userStore } from '@frontend/user/redux';
import { UserState, UserStateName } from '@frontend/user/types';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { UserStateSelectProps } from './user-state-select.component';

interface ViewProps {
    options: { value: string; label: React.ReactNode }[];
    value:
        | {
              value: UserStateName;
              label: React.ReactNode;
          }
        | {
              value: string;
              label: string;
          }
        | undefined;
}

const useUserStateSelect = (props: UserStateSelectProps): ViewProps => {
    const userState = useAppSelector(useSelector, userStore);

    useEffect(() => {
        props.dispatch(fetchUserStates());
    }, []);

    const options = useMemo(() => {
        if (!userState.userStates) return [];
        return userState.userStates.map((state) => {
            const found = UserState.ALL.find((t) => t.value === state);
            if (found) return { value: found.value, label: found.displayName };
            else
                return {
                    value: state,
                    label: state.replace(/_/g, ' ')
                };
        });
    }, [userState.userStates]);

    const value = useMemo(() => {
        if (!options || !props.value) return undefined;
        else return options.find((op) => op.value == props.value);
    }, [props.value, options]);

    return { options, value };
};

export default useUserStateSelect;
