import { BadgeTemplate, DetailModalType } from '@frontend/elements';
import { ENTITY_ICONS } from '@frontend/icons';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import useAccountBadge from './account-badge.controller';

export interface AccountBadgeProps {
    id?: string;
    name?: string;
}

/**
 * @deprecated
 */
export const AccountBadge = (props: AccountBadgeProps) => {
    const viewProps = useAccountBadge(props);

    return (
        <BadgeTemplate
            type={DetailModalType.ACCOUNT}
            objectId={props.id}
            disabled={viewProps.disabled}>
            <ENTITY_ICONS.ACCOUNT className='me-2' />
            {viewProps.displayName ? (
                <OverlayTrigger
                    overlay={<Tooltip id={'tooltip'}>{viewProps.displayName}</Tooltip>}
                    placement='top'
                    delay={{ show: 300, hide: 150 }}>
                    <span>{viewProps.displayName.length > 30 ? viewProps.displayName.slice(0, 20) + '...' : viewProps.displayName}</span>
                </OverlayTrigger>
            ) : (
                <span className='placeholder-glow'>
                    <span className='placeholder'>loading</span>
                </span>
            )}
        </BadgeTemplate>
    );
};
