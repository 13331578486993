import { APIClient } from "@frontend/api-utils";

const endpoint = '/package-api/v1';

export class PackageEnumClient extends APIClient {
    public static async fetchPackageStates(): Promise<string[]> {
        const response = await this.fetch(`${endpoint}/package-states`);
        return await this.handleResponse<string[]>(response);
    }

    public static async fetchPackageTriggers(): Promise<string[]> {
        const response = await this.fetch(`${endpoint}/package-triggers`);
        return await this.handleResponse<string[]>(response);
    }

    public static async fetchPackageActions(): Promise<string[]> {
        const response = await this.fetch(`${endpoint}/package-actions`);
        return await this.handleResponse<string[]>(response);
    }
}