import { ErrorHandler } from '@frontend/error-handler';
import { ToastUtil } from '@frontend/toast-utils';
import { AccessLogClient } from '@frontend/user/api';
import { fetchAccessLogs } from '@frontend/user/redux';
import { CreateAccessLog } from '@frontend/user/types';
import { Dispatch, SetStateAction, useState } from 'react';

import { CreateAccessLogFormProps } from './create-access-log-form.component';

interface ViewProps {
    formValues: FormValues;
    changeFormValues: Dispatch<SetStateAction<FormValues>>;
    formValid: FormValid;
    changeFormValid: Dispatch<SetStateAction<FormValid>>;
    onSubmit: (e: any) => void;
    submitted: boolean;
}

interface FormValues extends CreateAccessLog {
    spot_id?: string;
}

type FormValid = {
    [Key in keyof CreateAccessLog]: boolean;
};

const useCreateAccessLogForm = (props: CreateAccessLogFormProps): ViewProps => {
    const [formValid, changeFormValid] = useState<FormValid>({
        account_id: false,
        access_type: false,
        user_id: false,
        driver_id: true,
        driver_type: true,
        user_type: true
    });
    const [formValues, changeFormValues] = useState<FormValues>({
        spot_id: undefined,
        account_id: '',
        access_type: undefined,
        user_id: '',
        driver_id: null,
        driver_type: null,
        user_type: null
    });
    const [submitted, changeSubmitted] = useState<boolean>(false);

    const onSubmit = (e: any) => {
        e.preventDefault();
        changeSubmitted(true);
        if (!formValues.spot_id) return;
        AccessLogClient.postSpotAccessLog(formValues.spot_id, formValues)
            .then(() => {
                ToastUtil.success('Success', 'Access log created');
                props.dispatch(fetchAccessLogs({}));
                props.onClose();
            })
            .catch(ErrorHandler.handleError);
    };

    return {
        formValues,
        changeFormValues,
        formValid,
        changeFormValid,
        onSubmit,
        submitted
    };
};

export default useCreateAccessLogForm;
