import { Logger } from '@frontend/Logger';

import { AuthenticationProvider } from './authentication-provider';
import BasicAuthenticationClient from './basic-authentication-client';
import { Token } from './models';
import { RefreshHelper } from './refresh-helper';

export class AuthenticationManager extends AuthenticationProvider {
    private static instance: AuthenticationManager | null;

    private constructor() {
        super();
    }

    public static getInstance = (): AuthenticationManager => {
        if (!AuthenticationManager.instance) {
            AuthenticationManager.instance = new AuthenticationManager();
        }
        return AuthenticationManager.instance;
    };

    async authenticate(email: string, password: string): Promise<Token> {
        const credentials = await BasicAuthenticationClient.login(email, password);

        this.saveTokenToStorage(credentials);
        Logger.log(credentials);
        this.loggedIn = true;
        return credentials;
    }

    async refresh(): Promise<void> {
        return RefreshHelper.getInstance().refresh();
    }

    async doLogout() {
        try {
            const token = (await this.waitForToken()).jwt_token;
            await BasicAuthenticationClient.logout(token);
            this.logout(() => {
                window.location.href = '/';
            });
            this.loggedIn = false;
        } catch (error) {
            Logger.error('Something went wrong trying to logout.', {}, error);
        }
    }

    async signUp(email: string, password: string, password_confirmation: string, url: string): Promise<any> {
        try {
            const response = await BasicAuthenticationClient.signUp(email, password, password_confirmation, url);
            this.loggedIn = true;
            return response;
        } catch (err) {
            Logger.error('Sign up failed.', {}, err);
            throw err;
        }
    }

    async confirmSignUp(token: string): Promise<{ type: string; message: string }> {
        try {
            const credentials = await BasicAuthenticationClient.confirmSignUp(token);
            if ((credentials as any).detail) throw new Error((credentials as any).detail);
            this.saveTokenToStorage(credentials);
            Logger.log(credentials);
            this.loggedIn = true;
            return { type: 'success', message: 'Sign up confirmed! You will be redirected to the main page' };
        } catch (err) {
            return { type: 'error', message: 'Sign up confirmation failed. Please try again.' };
        }
    }

    async forgotPassword(email: string, url: string): Promise<{ message: string }> {
        try {
            const response = await BasicAuthenticationClient.forgotPassword(email, url);
            return response;
        } catch (err) {
            Logger.error('Forgot password failed.', {}, err);
            return { message: 'Forgot password failed. Please try again.' };
        }
    }

    async confirmForgotPassword(
        token: string,
        body: { url: string; password: string; password_confirmation: string; email: string }
    ): Promise<{ type: string; message: string }> {
        try {
            const response = await BasicAuthenticationClient.changePassword(token, body);
            if ((response as any).detail) throw new Error((response as any).detail);
            this.saveTokenToStorage(response);
            Logger.log(response);
            this.loggedIn = true;
            return { type: 'success', message: 'Password change confirmed! You will be redirected to the main page' };
        } catch (err) {
            return { type: 'error', message: 'Password change confirmation failed. Please try again.' };
        }
    }
}
