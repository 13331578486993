import { AccountClient } from '@frontend/account/api';
import { Account, AccountQueryParams } from '@frontend/account/types';
import { ApiQueryParams } from '@frontend/api-utils';
import { ObjectSelectInput } from '@frontend/form';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React, { useState } from 'react';

const ID = 'account-select';
export interface AccountSelectProps {
    id?: string;
    label?: React.ReactNode;
    required?: boolean;
    submitted: boolean;
    onChange?: (newValue: string | null) => void;
    onChangeObject?: (newValue: Account | null) => void;
    value?: string | null;
    className?: string;
    disabled?: boolean;
    useConditionedStyling?: boolean;
    isClearable?: boolean;
    allowURLChange?: boolean;
    /**
     * @deprecated
     */
    dispatch?: ThunkDispatch<any, any, Action>;
    createEnabled?: boolean;
    queryParams?: ApiQueryParams<AccountQueryParams>;
}

export const AccountSelect = (props: AccountSelectProps) => {
    const [v, cV] = useState<Account | null>(null); //This is only needed because it is currently used by a returning a ID instead of the object (should probably be updated eventually)
    return (
        <ObjectSelectInput<Account, AccountQueryParams>
            id={`${ID}${props.id && '-' + props.id}`}
            label='Account'
            submitted={false}
            onChange={(v) => {
                props.onChange && props.onChange(v?.id || null);
                props.onChangeObject && props.onChangeObject(v || null);
                cV(v);
            }}
            required={props.required}
            queryParams={props.queryParams}
            value={v}
            fetch={(props) => AccountClient.fetchAccounts(props)}
            itemLabel={(account) => account.name}
            useQueryParam={
                props.allowURLChange
                    ? {
                        param: 'account',
                        fetch: (pathParam) => AccountClient.fetchAccount(pathParam)
                    }
                    : undefined
            }
        />
    );
};
