import { Logger } from '@frontend/Logger';
import { useAppSelector } from '@frontend/common';
import { DeviceServiceEventListener } from '@frontend/device/events';
import { DeviceSensorCreatedEvent, SensorLockerStatus } from '@frontend/device/types';
import { WorkflowStepTriggeredEvent } from '@frontend/workflow/types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { navigationStore } from '../redux/user-interface-navigation-slice';
import { SlotEventListenerProps } from './slot-event-listener.component';

interface ViewProps {
    cache?: WorkflowStepTriggeredEvent | null;
    event: SensorLockerStatus | null;
}

const useSlotEventListener = (props: SlotEventListenerProps): ViewProps => {
    const navigationState = useAppSelector(useSelector, navigationStore);
    const cache = props.cache || navigationState.cache;

    const [deviceEventListener, changeDeviceEventListener] = useState<DeviceServiceEventListener | null>(null);
    const [event, changeEvent] = useState<SensorLockerStatus | null>(null);

    useEffect(() => {
        changeDeviceEventListener(DeviceServiceEventListener.getInstance(props.dispatch));
    }, []);

    useEffect(() => {
        if (deviceEventListener) {
            deviceEventListener.addCallback('useSlotEventListener', (event) => {
                Logger.log(event);
                const payload = JSON.parse(event.data) as { message: string; data: any };
                if (payload.message === 'device_sensor_created') {
                    const data = payload.data as DeviceSensorCreatedEvent;
                    if (data.slot_id === cache?.slot_id) {
                        changeEvent(data.sensor.status);
                        props.callback && props.callback(data.sensor.status);
                    }
                }
            });
        }
        return () => {
            if (deviceEventListener) {
                deviceEventListener.removeCallback('useSlotEventListener');
            }
        };
    }, [deviceEventListener]);

    return {
        cache,
        event
    };
};

export default useSlotEventListener;
