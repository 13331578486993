import { Terminal, TerminalState, fetchTerminal } from '@frontend/terminal-service';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { TerminalDetailModalWrapperProps } from './terminal-detail-modal-wrapper.component';

interface ViewProps {
    terminal?: Terminal;
}

const useTerminalDetailModalWrapper = (props: TerminalDetailModalWrapperProps): ViewProps => {
    const terminals: TerminalState = useSelector((state: any) => state.terminals);
    const [terminal, changeTerminal] = useState<Terminal | undefined>(undefined);

    useEffect(() => {
        const found = terminals.unordered.find((t) => t.id == props.id);
        if (found) changeTerminal(found);
        else props.dispatch(fetchTerminal(props.id));
    }, [props.id, terminals]);

    return { terminal };
};

export default useTerminalDetailModalWrapper;
