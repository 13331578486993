import { Locale, NotificationChannel } from '@frontend/common';
import { NotificationContentClient } from '@frontend/notification-service/api';
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { CreateNotificationContent } from '@frontend/notification-service/types';
import { CreateNotificationContentProps } from './create-notification-content.component';

interface ViewProps {
    submitted: boolean;
    formValues: CreateNotificationContent;
    formValid: FormValid;
    onSubmit: (e: any) => void;
    changeFormValues: React.Dispatch<React.SetStateAction<CreateNotificationContent>>;
    changeFormValid: React.Dispatch<React.SetStateAction<FormValid>>;
}

interface FormValid {
    channel: boolean;
    language: boolean;
    content: boolean;
    subject: boolean;
    footer: boolean;
}

const useCreateNotificationContent = (props: CreateNotificationContentProps): ViewProps => {
    const location = useLocation();
    const navigate = useNavigate();
    const { accountId, templateId } = useParams();
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const [formValues, changeFormValues] = useState<CreateNotificationContent>({ channel: NotificationChannel.EMAIL, language: Locale.DUTCH, content: '' });
    const [formValid, changeFormValid] = useState<FormValid>({
        channel: false,
        language: false,
        content: true,
        subject: true,
        footer: true
    });

    const onSubmit = (e: any) => {
        e.preventDefault();
        changeSubmitted(true);
        if (!accountId || !templateId) return;
        NotificationContentClient.postAccountTemplateNotificationContent(accountId, templateId, formValues).then((e) => {
            props.onClose();
            navigate(`${location.pathname}/contents/${e.id}`);
        });
    };

    return {
        changeFormValid,
        changeFormValues,
        formValid,
        formValues,
        onSubmit,
        submitted
    };
};

export default useCreateNotificationContent;
