import { PaginatedResponse } from "@frontend/api-utils";

export interface Setting {
    id: string;
    entity_type: string;
    entity_id: string;
    key: string;
    value: string | boolean | number | null;
    type: string;
    creation_timestamp: string;
}

export type SettingListResponse = PaginatedResponse<Setting>;

export interface CreateSetting {
    type?: string;
    value?: string | boolean | number | null;
    key?: string;
}

export interface UpdateSetting {
    type?: string | null;
    value?: string | boolean | number | null;
}

export enum SettingQueryParams {
    ENTITY_TYPE = 'entity_type',
    ENTITY_ID = 'entity_id'
}