import { Column } from 'react-table';

import { TransactionVariable } from '../../transaction-variables';

const TransactionVariablesColumns: Column<TransactionVariable>[] = [
    {
        Header: 'name',
        accessor: 'name'
    },
    {
        Header: 'value',
        accessor: 'value'
    },
    {
        Header: '',
        id: 'id'
    }
];

export default TransactionVariablesColumns;
