import React from 'react';
import { FormattedMessage } from 'react-intl';

export const Objects = {
    DEFAULT: {
        CREATION_TIMESTAMP: (
            <FormattedMessage
                id='ContactList.Table.Title.CreationTimestamp'
                description='Table title for creation date'
                defaultMessage='Created date'
            />
        ),
        UPDATE_TIMESTAMP: (
            <FormattedMessage
                id='Objects.DEFAULT.UPDATE_TIMESTAMP'
                description='Table title for update date'
                defaultMessage='Updated date'
            />
        )
    },
    RAKINDA: {
        MAC_ADDRESS: (
            <FormattedMessage
                id='Objects.RAKINDA.MAC_ADDRESS'
                description='Label for mac address'
                defaultMessage='Mac address'
            />
        ),
        STATUS: (
            <FormattedMessage
                id='Objects.RAKINDA.STATUS'
                description='Label for status'
                defaultMessage='Status'
            />
        ),
        HEARTBEAT: (
            <FormattedMessage
                id='Objects.RAKINDA.HEARTBEAT'
                description='Label for heartbeat'
                defaultMessage='Heartbeat'
            />
        ),
        MASTER_PIN_CODE: (
            <FormattedMessage
                id='Forms.MASTER_PIN_CODE'
                description='Label for master pin code'
                defaultMessage='Master pin code'
            />
        )
    },
    SLOT: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.SLOT.DEFAULT'
                description='Label for slot'
                defaultMessage='Slot'
            />
        ),
        SLOTS: (
            <FormattedMessage
                id='Objects.SLOT.SLOTS'
                description='Message for SLOTs'
                defaultMessage='SLOTs'
            />
        )
    },
    BADGE: {
        NUMBER: (
            <FormattedMessage
                id='Objects.BADGE.NUMBER'
                description='Label for number'
                defaultMessage='Number'
            />
        )
    },
    CATEGORY: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.CATEGORY.DEFAULT'
                description='Label for category'
                defaultMessage='Category'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.CATEGORY.PLURAL'
                description='Plural name for categories'
                defaultMessage='Categories'
            />
        )
    },
    PRODUCTS: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.PRODUCTS.DEFAULT'
                description='Label for products'
                defaultMessage='Product'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.PRODUCTS.PLURAL'
                description='Plural name for products'
                defaultMessage='Products'
            />
        )
    },
    WORKFLOW: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.WORKFLOW.DEFAULT'
                description='Label for workflow'
                defaultMessage='Workflow'
            />
        )
    },
    ACCOUNT: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.ACCOUNT.DEFAULT'
                description='Label for account'
                defaultMessage='Account'
            />
        )
    },
    COMMON_FIELDS: {
        DESCRIPTION: (
            <FormattedMessage
                id='Objects.COMMON_FIELDS.DESCRIPTION'
                description='Label for description'
                defaultMessage='Description'
            />
        ),
        TYPE: (
            <FormattedMessage
                id='Objects.COMMON_FIELDS.TYPE'
                description='Label for type'
                defaultMessage='Type'
            />
        ),
        STATUS: (
            <FormattedMessage
                id='Objects.COMMON_FIELDS.STATUS'
                description='Label for status'
                defaultMessage='Status'
            />
        )
    },
    MOVEMENT: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.MOVEMENT.DEFAULT'
                description='Label for movement'
                defaultMessage='Movement'
            />
        ),
        ORIGINAL_QUANTITY: (
            <FormattedMessage
                id='Objects.MOVEMENT.ORIGINAL_QUANTITY'
                description='Label for original quantity'
                defaultMessage='Original quantity'
            />
        ),
        NEW_QUANTITY: (
            <FormattedMessage
                id='Objects.MOVEMENT.NEW_QUANTITY'
                description='Label for new quantity'
                defaultMessage='New quantity'
            />
        ),
        DIFFERENCE: (
            <FormattedMessage
                id='Objects.MOVEMENT.DIFFERENCE'
                description='Label for difference'
                defaultMessage='Difference'
            />
        )
    },
    USER: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.USER.DEFAULT'
                description='Label for user'
                defaultMessage='User'
            />
        ),
        TITLE: (
            <FormattedMessage
                id='Objects.USER.TITLE'
                description='Label for title'
                defaultMessage='Title'
            />
        ),
        USERNAME: (
            <FormattedMessage
                id='Objects.USER.USERNAME'
                description='Label for username'
                defaultMessage='Username'
            />
        )
    },
    SPOT: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.SPOT.DEFAULT'
                description='Label for spot'
                defaultMessage='Spot'
            />
        )
    },
    MODULE: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.MODULE.DEFAULT'
                description='Label for module'
                defaultMessage='Module'
            />
        )
    },
    TRANSACTION: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.TRANSACTION.DEFAULT'
                description='Label for transaction'
                defaultMessage='Transaction'
            />
        ),
        TRACKING_NUMBER: (
            <FormattedMessage
                id='Objects.TRANSACTION.TRACKING_NUMBER'
                description='Label for tracking number'
                defaultMessage='Tracking number'
            />
        )
    },
    IMPORT_LOG: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.IMPORT_LOG.DEFAULT'
                description='Label for import log'
                defaultMessage='Import log'
            />
        ),
        FILE_NAME: (
            <FormattedMessage
                id='Objects.IMPORT_LOG.FILE_NAME'
                description='Label for file name'
                defaultMessage='File name'
            />
        ),
        FILE_SIZE: (
            <FormattedMessage
                id='Objects.IMPORT_LOG.FILE_SIZE'
                description='Label for file size'
                defaultMessage='File size'
            />
        ),
        IMPORT_TYPE: (
            <FormattedMessage
                id='Objects.IMPORT_LOG.IMPORT_TYPE'
                description='Label for import type'
                defaultMessage='Import type'
            />
        ),
        IMPORT_ENTITY_TYPE: (
            <FormattedMessage
                id='Objects.IMPORT_LOG.IMPORT_ENTITY_TYPE'
                description='Label for import entity type'
                defaultMessage='Import entity type'
            />
        )
    },
    CERTIFICATE: {
        CODE: (
            <FormattedMessage
                id='Objects.CERTIFICATE.CODE'
                description='Label for certificate code'
                defaultMessage='Certificate code'
            />
        )
    },
    STOCK: {
        STOCK_OVERVIEW: (
            <FormattedMessage
                id='Objects.STOCK.STOCK_OVERVIEW'
                description='Label for stock overview'
                defaultMessage='Stock overview'
            />
        )
    }
};
