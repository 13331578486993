import { CreateTransactionData } from '@frontend/user-interface/types';
import { useEffect, useState } from 'react';
import { CreateTransactionDataFormProps } from './create-transaction-data-form.component';

interface ViewProps {
    formValues: CreateTransactionData;
    changeFormValues: React.Dispatch<React.SetStateAction<CreateTransactionData>>;
}

const useCreateTransactionDataForm = (props: CreateTransactionDataFormProps): ViewProps => {
    const [formValues, changeFormValues] = useState<CreateTransactionData>({
        transactionWorkflowId: '',
        dropPackageWorkflowId: '',
        pickPackageWorkflowId: '',
        senderSelectMethod: 'contact',
        receiverSelectMethod: 'contact',
    });

    useEffect(() => {
        props.onChange(formValues);
    }, [formValues]);

    return {
        formValues,
        changeFormValues
    };
};

export default useCreateTransactionDataForm;
