import { ClassType } from '@frontend/common';
import React from 'react';

export interface SpinnerProps {
    show?: boolean;
    type?: ClassType;
    size?: string;
}

export const Spinner = (props: SpinnerProps) => {
    if (props.show === false) return <></>;
    const spinnerType = props.type ? props.type.valueOf() : 'secondary';
    const spinnerSize = props.size ? props.size.valueOf() : 'lg';
    return (
        <div
            className='d-flex flex-row justify-content-center text-center w-100'
            data-testid='loading-spinner'>
            <div
                className={'spinner-border text-' + spinnerType + ' spinner-border-' + spinnerSize}
                role='status'>
                <span className='visually-hidden'>Loading...</span>
            </div>
        </div>
    );
};
