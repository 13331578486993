import { Spinner } from '@frontend/elements';
import { ENTITY_ICONS } from '@frontend/icons';
import { Package, PackageType } from '@frontend/package/types';
import { Transaction } from '@frontend/transaction/types';
import { UserInterfaceImplementation, WarehouseProductPickDropData } from '@frontend/user-interface/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import { LuPackagePlus } from 'react-icons/lu';
import { TbChecklist } from 'react-icons/tb';
import { FormattedMessage } from 'react-intl';

import DefaultTitle from '../../../default-title/default-title.component';
import { DropPickDivider } from '../drop-pick-divider/drop-pick-divider.component';
import ProductDetail, { TypeSelectButton } from '../product-detail/product-detail.component';
import useCheckout from './checkout.controller';

const ID = 'checkout';
export interface CheckoutProps {
    dispatch: ThunkDispatch<any, any, Action>;
    transaction: Transaction;
    userInterface: UserInterfaceImplementation<WarehouseProductPickDropData>;
    packages: Package[];
    changePackages: React.Dispatch<React.SetStateAction<Package[]>>;
    createTransaction: () => Promise<Transaction>;
    back: () => void;
    onConfirm: () => void;
}

const Checkout = (props: CheckoutProps) => {
    const viewProps = useCheckout(props);

    return (
        <div
            id={ID}
            className='d-flex flex-column justify-content-between h-100 p-1 fs-5'>
            <div>
                <DefaultTitle dispatch={props.dispatch} />
                <h4 className='text-center m-3'>
                    <FormattedMessage
                        id='warehouse-product-pick-drop.checkout.title'
                        description='Title on the warehouse pick and drop checkout page.'
                        defaultMessage='Checkout'
                    />
                </h4>
                {viewProps.products === null ? (
                    <Spinner />
                ) : (
                    <>
                        <DropPickDivider
                            type='drop'
                            hide={viewProps.products
                                .map((product) => {
                                    return props.packages.filter((p) => p.product_id === product.id && p.type === PackageType.DROP_OFF).length;
                                })
                                .every((k) => k === 0)}
                        />
                        <ul className='list-group'>
                            {viewProps.products.map((product) => {
                                let total = 0;
                                props.packages
                                    .filter((p) => p.product_id === product.id && p.type === PackageType.DROP_OFF)
                                    .forEach((package_) => (total += package_.quantity));
                                if (total === 0) return null;
                                return (
                                    <li
                                        className='list-group-item'
                                        key={product.id}
                                        onClick={() => viewProps.changeProduct(product)}>
                                        <span className='d-flex flex-row justify-content-between align-items-center'>
                                            <span>
                                                <ENTITY_ICONS.PRODUCT className='me-2' />
                                                {product.name || product.code}
                                            </span>
                                            <span className='badge bg-primary rounded-pill'>{total}</span>
                                        </span>
                                    </li>
                                );
                            })}
                        </ul>
                        <DropPickDivider
                            type='pick'
                            hide={viewProps.products
                                .map((product) => {
                                    return props.packages.filter((p) => p.product_id === product.id && p.type === PackageType.PICK_UP).length;
                                })
                                .every((k) => k === 0)}
                        />
                        <ul className='list-group'>
                            {viewProps.products.map((product) => {
                                let total = 0;
                                props.packages
                                    .filter((p) => p.product_id === product.id && p.type === PackageType.PICK_UP)
                                    .forEach((package_) => (total += package_.quantity));
                                if (total === 0) return null;
                                return (
                                    <li
                                        className='list-group-item'
                                        key={product.id}
                                        onClick={() => viewProps.changeProduct(product)}>
                                        <span className='d-flex flex-row justify-content-between align-items-center'>
                                            <span>
                                                <ENTITY_ICONS.PRODUCT className='me-2' />
                                                {product.name || product.code}
                                            </span>
                                            <span className='badge bg-primary rounded-pill'>{total}</span>
                                        </span>
                                    </li>
                                );
                            })}
                        </ul>
                    </>
                )}
            </div>

            <div className='fixed-bottom d-flex justify-content-around'>
                <TypeSelectButton
                    icon={LuPackagePlus}
                    name={
                        <FormattedMessage
                            id='warehouse-product-pick-drop.add-more-products'
                            description='Title on the warehouse pick and drop checkout page.'
                            defaultMessage='Add more products'
                        />
                    }
                    onClick={props.back}
                />
                <TypeSelectButton
                    icon={TbChecklist}
                    name={
                        <FormattedMessage
                            id='warehouse-product-pick-drop.checkout.title'
                            description='Title on the warehouse pick and drop checkout page.'
                            defaultMessage='Checkout'
                        />
                    }
                    onClick={props.onConfirm}
                />
            </div>

            {viewProps.product && (
                <ProductDetail
                    userInterface={props.userInterface}
                    transaction={props.transaction}
                    createTransaction={props.createTransaction}
                    packages={props.packages}
                    product={viewProps.product}
                    onClose={() => viewProps.changeProduct(null)}
                    changePackages={props.changePackages}
                    checkout
                />
            )}
        </div>
    );
};

export default Checkout;
