import { UserInterfaceClient } from '@frontend/user-interface/api';
import { CreateUserInterface, UserInterfaceType } from '@frontend/user-interface/types';
import { useState } from 'react';

import { CreateUserInterfaceFormProps } from './create-user-interface-form.component';

interface ViewProps {
    submitted: boolean;
    formValues: CreateUserInterface;
    accountId: string | null;
    formValid: { name: boolean; account: boolean; type: boolean; data: boolean };
    onSubmit: (e: any) => void;
    changeFormValues: React.Dispatch<React.SetStateAction<CreateUserInterface>>;
    changeAccountId: React.Dispatch<React.SetStateAction<string | null>>;
    changeFormValid: React.Dispatch<React.SetStateAction<{ name: boolean; account: boolean; type: boolean; data: boolean }>>;
}

const useCreateUserInterfaceForm = (props: CreateUserInterfaceFormProps): ViewProps => {
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const [formValues, changeFormValues] = useState<CreateUserInterface>({ name: '', platform: 'terminal', type: UserInterfaceType.UNKNOWN, data: null });
    const [accountId, changeAccountId] = useState<string | null>(null);
    const [formValid, changeFormValid] = useState<{ name: boolean; account: boolean; type: boolean; data: boolean }>({
        name: false,
        account: false,
        type: false,
        data: true
    });

    const onSubmit = (e: any) => {
        e.preventDefault();
        changeSubmitted(true);
        UserInterfaceClient.postAccountUserInterface(accountId!, formValues).then((userInterface) => {
            props.callback && props.callback(userInterface);
            props.onClose();
        });
    };

    return {
        submitted,
        formValues,
        accountId,
        formValid,
        onSubmit,
        changeFormValues,
        changeAccountId,
        changeFormValid
    };
};

export default useCreateUserInterfaceForm;
