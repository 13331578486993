import { Role, fetchEntityRoles } from '@frontend/authorization';
import { useEffect, useMemo, useState } from 'react';
import { Column } from 'react-table';

import { useAppDispatch, useAppSelector } from '../../../redux/store';
import RoleListColumns from './role-list-columns';
import { RoleListProps } from './role-list.component';

interface ViewProps {
    data: Role[];
    columns: Column<Role>[];
    pageSize: number;
    pageCount: number;
    setPage: (value: React.SetStateAction<number>) => void;
    setPageSize: (value: React.SetStateAction<number>) => void;
    selectedRoles: Role[];
    changeSelectedRoles: React.Dispatch<React.SetStateAction<Role[]>>;
}

const useRoleList = (props: RoleListProps): ViewProps => {
    const dispatch = useAppDispatch();
    const authorizationState = useAppSelector((state) => state.authorization);
    const [pageSize, setPageSize] = useState(25);
    const [page, setPage] = useState(1);
    const [selectedRoles, changeSelectedRoles] = useState<Role[]>([]);

    useEffect(() => {
        if (authorizationState.current)
            dispatch(
                fetchEntityRoles({
                    type: authorizationState.current.type,
                    entityId: authorizationState.current.id,
                    queryParams: { index: (page - 1).toString(), size: pageSize.toString() }
                })
            );
    }, [page, pageSize]);

    const data = useMemo(() => {
        if (authorizationState.current == null || authorizationState.current.roles == null) return [];
        return authorizationState.current.roles.results;
    }, [authorizationState]);
    const columns: Column<Role>[] = useMemo(() => RoleListColumns, []);

    return {
        data,
        columns,
        pageSize,
        pageCount: authorizationState.current?.roles ? Math.ceil(authorizationState.current.roles.count / pageSize) : 1,
        setPage,
        setPageSize,
        selectedRoles,
        changeSelectedRoles
    };
};

export default useRoleList;
