import useAuthentication from './authentication.controller';
import BasicLogin from './basic-login/basic-login.component';

export const ID = 'authentication';
export interface Props {
    children: React.ReactNode;
}
//TODO use same implementation as the Initialise component from terminal
const Authentication = (props: Props) => {
    const viewProps = useAuthentication();
    return <div id={ID}>{viewProps.authenticated ? props.children : <BasicLogin callback={viewProps.onLoginSucceeded} />}</div>;
};

export default Authentication;
