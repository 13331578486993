import { DetailPageType, useAppSelector, useDetailPage } from '@frontend/common';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { accountRequest } from '@frontend/account/events';
import { accountStore } from '@frontend/account/redux';
import { AccountBadgeProps } from './account-badge.component';

interface ViewProps {
    displayName?: string;
    disabled: boolean;
}

const useAccountBadge = (props: AccountBadgeProps): ViewProps => {
    const [requested, changeRequested] = useState<boolean>(false);
    const accountState = useAppSelector(useSelector, accountStore);
    const [disabled, changeDisabled] = useState<boolean>(false);
    const [displayName, changeDisplayName] = useState<string | undefined>(props.name);
    const { isEntityPage, isEntityBadge } = useDetailPage(DetailPageType.ACCOUNT, props.id);
    useEffect(() => {
        if (props.id != undefined) {
            const found = accountState.unordered.find((ac) => ac.id == props.id);
            if (found) {
                changeDisplayName(found.name);
            } else if (requested == false) {
                changeRequested(true);
                accountRequest(props.id);
            }

            if (requested && !found) {
                changeDisabled(true);
                changeDisplayName('Not found');
            }
        } else {
            changeDisabled(true);
            changeDisplayName('Not found');
        }

        if (isEntityBadge || isEntityPage) changeDisabled(true);
    }, [accountState, props.id]);

    return {
        displayName,
        disabled
    };
};

export default useAccountBadge;
