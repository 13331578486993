import { SliceStatus } from '@frontend/common';
import { UserInterfaceButtonState, fetchAccountUserInterfaceButtons } from '@frontend/user-interface-button/redux';
import { UserInterfaceButton } from '@frontend/user-interface-button/types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ButtonDashboardProps } from './button-dashboard.component';

interface ViewProps {
    isError: boolean;
    isLoading: boolean;
    buttons: UserInterfaceButton[] | null;
}

const useButtonDashboard = (props: ButtonDashboardProps): ViewProps => {
    const userInterfaceButtonState = useSelector((state: any) => state.userInterfaceButtons as UserInterfaceButtonState);
    const [buttons, changeButtons] = useState<UserInterfaceButton[] | null>(null);

    useEffect(() => {
        const listId = props.userInterface.account_id + ':' + props.userInterface.id;
        if (
            (userInterfaceButtonState.userInterfaceButtonsByAccountAndUserInterface === null ||
                userInterfaceButtonState.userInterfaceButtonsByAccountAndUserInterface[listId] == null) &&
            userInterfaceButtonState.status !== SliceStatus.ERROR
        )
            props.dispatch(
                fetchAccountUserInterfaceButtons({
                    accountId: props.userInterface.account_id,
                    userInterfaceId: props.userInterface.id,
                    queryParams: { size: '100', index: '0' }
                })
            );
        if (
            userInterfaceButtonState.userInterfaceButtonsByAccountAndUserInterface != null &&
            userInterfaceButtonState.userInterfaceButtonsByAccountAndUserInterface[listId] != null
        )
            changeButtons([...userInterfaceButtonState.userInterfaceButtonsByAccountAndUserInterface[listId].results].sort((a, b) => a.order - b.order));
    }, [userInterfaceButtonState, props.userInterface]);

    return {
        isError: userInterfaceButtonState.status === SliceStatus.ERROR,
        isLoading: buttons === null || buttons.length === 0,
        buttons
    };
};

export default useButtonDashboard;
