import { APIClient } from "@frontend/api-utils";

const endpoint = '/product-api/v1';

export class ProductEnumClient extends APIClient {
    public static async fetchProductStates(): Promise<string[]> {
        const response = await this.fetch(`${endpoint}/product-states`);
        return await this.handleResponse<string[]>(response);
    }

    public static async fetchProductTriggers(): Promise<string[]> {
        const response = await this.fetch(`${endpoint}/product-triggers`);
        return await this.handleResponse<string[]>(response);
    }

    public static async fetchProductActions(): Promise<string[]> {
        const response = await this.fetch(`${endpoint}/product-actions`);
        return await this.handleResponse<string[]>(response);
    }
}