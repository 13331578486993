import { Logger } from '@frontend/Logger';
import { ToastUtil } from '@frontend/toast-utils';
import { TransactionClient } from '@frontend/transaction/api';
import { CreateTransaction, TransactionType } from '@frontend/transaction/types';
import { useState } from 'react';

import { CreateTransactionProps } from './create-transaction.component';

interface ViewProps {
    loading: boolean;
    submitted: boolean;
    submitDisabled: boolean;
    formValues: FormValues;
    formValid: FormValid;
    onSubmit: (e: any) => void;
    changeFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
    changeFormValid: React.Dispatch<React.SetStateAction<FormValid>>;
}
interface FormValues extends CreateTransaction {
    account_id: string;
}
interface FormValid {
    driver_id: boolean;
    account_id: boolean;
    workflow_id: boolean;
    spot_id: boolean;
}
const useCreateTransaction = (props: CreateTransactionProps): ViewProps => {
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const [loading, changeLoading] = useState<boolean>(false);
    const [formValues, changeFormValues] = useState<FormValues>({ driver_id: null, account_id: '', type: TransactionType.PUDO, workflow_id: '' });
    const [formValid, changeFormValid] = useState<FormValid>({
        driver_id: true,
        account_id: false,
        workflow_id: false,
        spot_id: false
    });

    const onSubmit = (e: any) => {
        e.preventDefault();
        changeSubmitted(true);
        changeLoading(true);
        TransactionClient.postAccountTransaction(
            {
                driver_id: formValues.driver_id,
                workflow_id: formValues.workflow_id,
                type: formValues.type
            },
            formValues.account_id
        )
            .then((result) => {
                props.callback(result);
            })
            .catch((error) => {
                Logger.error(error);
                ToastUtil.error('Something went wrong while creating the transaction.');
                changeLoading(false);
            });
    };

    return {
        loading,
        submitted,
        submitDisabled: Object.values(formValid).find((v) => !v),
        formValues,
        formValid,
        onSubmit,
        changeFormValues,
        changeFormValid
    };
};

export default useCreateTransaction;
