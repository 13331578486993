import { SelectInput } from '@frontend/basic-forms';
import { Slot } from '@frontend/slot/types';
import React from 'react';

import { UserInterfaceTemplateProps } from '../user-interface-template-props';
import useSelectSlotForm from './select-slot-form.controller';

export interface SelectSlotFormProps extends UserInterfaceTemplateProps {
    slot?: Slot | null;
    callback: (slot: Slot | null) => void;
}

const SelectSlotForm = (props: SelectSlotFormProps) => {
    const viewProps = useSelectSlotForm(props);

    return (
        <div
            id='select-slot-ui'
            className='d-flex flex-column align-items-center justify-content-center m-3'>
            <h3 className='pb-4'>Please select the SLOT you would like to use.</h3>
            <SelectInput
                required
                className='w-100'
                label='SLOT'
                submitted={viewProps.submitted}
                options={viewProps.availableSlots.map((slot) => {
                    return { value: slot.id, label: slot.number };
                })}
                onChange={(v) => {
                    if (v && (v as any).value) viewProps.changeSelectedSlot(viewProps.availableSlots.find((slot) => slot.id === (v as any).value) || null);
                    else viewProps.changeSelectedSlot(null);
                }}
                value={viewProps.selectedSlot?.id}
            />
            <button
                type='submit'
                className='btn btn-primary mt-3 btn-lg w-100'
                disabled={viewProps.submitted || viewProps.selectedSlot === null}
                onClick={viewProps.onSubmit}>
                Submit
            </button>
        </div>
    );
};

export default SelectSlotForm;
