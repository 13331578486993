import { AddressBadge, BooleanBadge, DateBadge } from "@frontend/elements";
import { Logger } from "@frontend/Logger";
import moment from "moment";
import React from "react";
import { ObjectBadgeMapper } from "./object-badge-mapper";

interface Props<T extends object> {
    object: T;
    keyOverwrite?: Map<keyof T, React.ReactNode>;
    /**
     * @description will overwrite the value of the key provided.
     */
    valueOverwrite?: Map<keyof T, React.ReactNode>;
    /**
     * @description will hide the keys provided. If not provided, all keys will be shown.
     */
    hidden?: (keyof T)[];
    /**
     * @description will only show the keys provided in the same order as provided. If not provided, all keys will be shown.
     * Keys provided in the hidden array will overrule key provided here.
     */
    order?: (keyof T)[];
    rowClass?: string;
}

export const ObjectDetailModalMapper = <T extends object>(props: Props<T>) => {
    const rows = convert(props);
    return (
        <>
            {rows.map(([key, keyDisplay, value]) => (
                <div key={key} className={`${props.rowClass ? props.rowClass : 'd-flex justify-content-between'}`}>
                    {keyDisplay}
                    <p>{value}</p>
                </div>
            ))}
        </>
    );
}

function convert<T extends object>(props: Props<T>): [string, React.ReactNode, React.ReactNode][] {
    const result: [string, React.ReactNode, React.ReactNode][] = [];
    for(const [key, value] of Object.entries(props.object)) {
        let index = null;
        let finalKey = null;
        if (props.hidden && props.hidden.includes(key as keyof T)) {
            continue;
        }
        if (props.order && !props.order.includes(key as keyof T)) {
            continue;
        } else {
            index = props.order?.indexOf(key as keyof T);
        }
        const translatedKey = props.keyOverwrite?.get(key as keyof T);
        if (translatedKey) {//TODO: Maybe also do some automatic translations for common fields.
            finalKey = translatedKey;
        } else {
            finalKey = key[0].toUpperCase() + key.slice(1);
            finalKey = finalKey.split('_').join(' ');
        }

        let finalValue: React.ReactNode = <></>;
        if(props.valueOverwrite && props.valueOverwrite.has(key as keyof T)) {
            finalValue = props.valueOverwrite.get(key as keyof T);
        } else if (typeof value === 'string') {
            if (isUUID(value)) {
                if (key === 'id') { //Id of object itself so just display id
                    finalValue = (<span className='text-truncate'>{value}</span>);
                } else {//Render as badge
                    finalValue = (<ObjectBadgeMapper id={value} key={key} />);
                }
            } else if ((key.includes('time') || key.includes('date')) && moment(value, moment.ISO_8601, true).isValid()) {
                finalValue = (<DateBadge
                    date={value}
                    displayTime
                    inline
                />);
            } else if (key.includes('address')) {
                const city = 'city' in props.object ? props.object['city'] : undefined;
                const zip = 'zip_code' in props.object ? props.object['zip_code'] : undefined;
                finalValue = (<AddressBadge
                    address={value}
                    city={city as string|undefined}
                    zip={zip as string|undefined}
                />);
            } else {
                finalValue = (<span className='text-truncate'>{value}</span>);
            }
        } else if (typeof value === 'number') {
            finalValue = (<span className='text-truncate'>{value}</span>);
        } else if (typeof value === 'boolean') {
            finalValue = (<BooleanBadge value/>);
        } else if (value === null || value === undefined) {
            finalValue = (<span className='text-truncate'>-</span>);
        } else {
            Logger.warn('Unknown type', {}, value);
        }
        
        const toAdd: [string, React.ReactNode, React.ReactNode] = [key, (
            <strong>{finalKey}</strong>
        ), finalValue];

        if (index != null) result[index] = toAdd;
        else result.push(toAdd);
    }

    return result;
}
function isUUID(str: string): boolean {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidRegex.test(str);
}