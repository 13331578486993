import { WorkflowFieldType } from '@frontend/workflow-variable/types';
import React, { useMemo } from 'react';

import BoolFieldInput from './input-types/bool-field-input/bool-field-input.component';
import ContactIdInput from './input-types/contact-id-input/contact-id-input.component';
import NumberFieldInput from './input-types/number-field-input/number-field-input.component';
import StringFieldInput from './input-types/string-field-input/string-field-input.component';
import TemplateIdInput from './input-types/template-id-input/template-id-input.component';
import { VariableInputProps } from './transaction-input.component';

interface ViewProps {
    input: React.ReactNode;
}

const useVariableInput = (props: VariableInputProps): ViewProps => {
    const input = useMemo(() => {
        switch (props.variable.type) {
            case WorkflowFieldType.CONTACT_ID:
                return <ContactIdInput {...props} />;
            case WorkflowFieldType.TEMPLATE_ID:
                return <TemplateIdInput {...props} />;
            case WorkflowFieldType.INT:
                return <NumberFieldInput {...props} />;
            case WorkflowFieldType.BOOL:
                return <BoolFieldInput {...props} />;
            case WorkflowFieldType.STRING:
                return <StringFieldInput {...props} />;
            default:
                return null;
        }
    }, [props.variable]);

    return { input };
};

export default useVariableInput;
