import { TextInput } from '@frontend/basic-forms';
import { ClassType, convertLanguage } from '@frontend/common';
import { ConfirmationModal, HorizontalButtonGroup, ObjectField, Spinner } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';
import { lazy } from 'react';
import { FaTrash } from 'react-icons/fa';

import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import { default as ContentEditor } from './editor/editor.component';
import useNotificationContentDetail from './notification-content-detail.controller';

const AccountBadge = lazy(() => import('@frontend/account/common').then((module) => ({ default: module.AccountBadge })));

const ID = 'notification-content-detail';

export interface NotificationContentDetailProps {
    dispatch: ThunkDispatch<any, any, Action>;
}

export const NotificationContentDetail = (props: NotificationContentDetailProps) => {
    const viewProps = useNotificationContentDetail(props);

    if (!viewProps.notificationContent) return <Spinner />;

    return (
        <div id={ID}>
            <div className='card mt-3'>
                <div className='card-header'>
                    <div className='d-flex justify-content-between mb-2'>
                        <h6>{viewProps.notificationContent.subject}</h6>
                        <HorizontalButtonGroup
                            direction='left'
                            buttons={[
                                {
                                    hide: false,
                                    icon: FaTrash,
                                    text: CommonMessage.BUTTONS.DELETE,
                                    type: ClassType.DANGER,
                                    onClick: () => viewProps.changeDeleteModal(true)
                                }
                            ]}
                        />
                    </div>
                </div>
                <div className='card-body'>
                    <ObjectField
                        fieldName='Account'
                        value={<AccountBadge id={viewProps.notificationContent.account_id} />}
                    />
                    <ObjectField
                        fieldName='Channel'
                        value={viewProps.notificationContent.channel}
                    />
                    <ObjectField
                        fieldName='Language'
                        value={convertLanguage(viewProps.notificationContent.language)}
                    />
                </div>
            </div>
            <div className='d-flex'>
                <div className='card mt-3 w-80'>
                    <div className='card-body'>
                        <TextInput
                            label='Subject'
                            submitted={false}
                            value={viewProps.subject}
                            onChange={viewProps.changeSubject}
                        />
                        <ContentEditor
                            subject={viewProps.subject}
                            resetSubject={() => viewProps.changeSubject(viewProps.notificationContent?.subject || '')}
                            content={viewProps.notificationContent.content}
                            dispatch={props.dispatch}
                        />
                    </div>
                </div>
                <div className='card ms-3 mt-3 w-20'>
                    <div className='card-header'>placeholders</div>
                    <div className='card-body'>
                        <div className='d-flex flex-column justify-content-start placeholder-container'>
                            {viewProps.placeHolders &&
                                Object.values(viewProps.placeHolders).map((p: any, index: any) => (
                                    <span
                                        className='badge bg-gradient-primary cursor-pointer mt-1'
                                        draggable
                                        onDragStart={(event) => viewProps.placeHolderDrag(event)}
                                        key={index}>
                                        {p.entity_label}
                                    </span>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
            {viewProps.deleteModal && (
                <ConfirmationModal
                    handleClose={() => viewProps.changeDeleteModal(false)}
                    message={`You are about to delete this notification content`}
                    onConfirm={() => viewProps.removeNotificationContent()}
                    show={viewProps.deleteModal}
                    severity={ClassType.DANGER}
                />
            )}
        </div>
    );
};
