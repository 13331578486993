import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { Token } from '@frontend/authentication-v2';
import { CreateProduct, Product, ProductListResponse, ProductQueryParams, UpdateProduct } from '@frontend/product/types';


const endpoint = '/product-api/v1';
export class ProductClient extends APIClient {
    public static async fetchProducts(queryParams?: ApiQueryParams<DefaultQueryParams & ProductQueryParams>, withCredentials?: Token): Promise<ProductListResponse> {
        return await this.apiPaginated<ProductListResponse, DefaultQueryParams & ProductQueryParams>(`${endpoint}/products`, queryParams, withCredentials);
    }

    public static async fetchCatalogProducts(queryParams?: ApiQueryParams<DefaultQueryParams & ProductQueryParams & 'catalog_id'>, withCredentials?: Token): Promise<ProductListResponse> {
        return await this.apiPaginated<ProductListResponse, DefaultQueryParams & ProductQueryParams & 'catalog_id'>(`${endpoint}/catalog-products`, queryParams, withCredentials);
    }

    public static async fetchCategoryProducts(accountId: string, queryParams?: ApiQueryParams<DefaultQueryParams & ProductQueryParams>, withCredentials?: Token): Promise<ProductListResponse> {
        return await this.apiPaginated<ProductListResponse, DefaultQueryParams & ProductQueryParams>(`${endpoint}/accounts/${accountId}/category-products`, queryParams, withCredentials);
    }

    public static async resolveProducts(productIds: string[]): Promise<ProductListResponse> {
        const response = await this.post(`${endpoint}/products-resolve`, { ids: productIds });
        return await this.handleResponse<ProductListResponse>(response);
    }

    public static async fetchAccountProducts(accountId: string, queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<ProductListResponse> {
        return await this.apiPaginated<ProductListResponse, DefaultQueryParams>(`${endpoint}/accounts/${accountId}/products`, queryParams);
    }

    public static async postAccountProduct(product: CreateProduct, accountId: string): Promise<Product> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/products`, product);
        return await this.handleResponse<Product>(response);
    }

    public static async fetchAccountProduct(accountId: string, productId: string): Promise<Product> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/products/${productId}`);
        return await this.handleResponse<Product>(response);
    }

    public static async patchAccountProduct(product: UpdateProduct, accountId: string, productId: string): Promise<Product> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/products/${productId}`, product);
        return await this.handleResponse<Product>(response);
    }

    public static async deleteAccountProduct(accountId: string, productId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/products/${productId}`);
        return await this.handleResponse(response);
    }

    public static async deleteProducts(productIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/products-delete`, { ids: productIds });
        return await this.handleVoidResponse(response);
    }
}
