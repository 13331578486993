import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { CreateWare, UpdateWare, Ware, WareListResponse, WareQueryParams } from '@frontend/stock/types';

const endpoint = '/stock-api/v1';

export class WareClient extends APIClient {
    public static async fetchWares(queryParams?: ApiQueryParams<DefaultQueryParams | WareQueryParams>): Promise<WareListResponse> {
        return await this.apiPaginated<WareListResponse, DefaultQueryParams | WareQueryParams>(`${endpoint}/wares`, queryParams);
    }

    public static async resolveWares(wareIds: string[]): Promise<WareListResponse> {
        const response = await this.post(`${endpoint}/wares-resolve`, { ids: wareIds });
        return await this.handleResponse<WareListResponse>(response);
    }

    public static async fetchSpotModuleSlotWares(
        spotId: string,
        moduleId: string,
        slotId: string,
        queryParams?: ApiQueryParams<DefaultQueryParams>
    ): Promise<WareListResponse> {
        return await this.apiPaginated<WareListResponse, DefaultQueryParams>(
            `${endpoint}/spots/${spotId}/modules/${moduleId}/slots/${slotId}/wares`,
            queryParams
        );
    }

    public static async postSpotModuleSlotWare(spotId: string, moduleId: string, slotId: string, ware: CreateWare): Promise<Ware> {
        const response = await this.post(`${endpoint}/spots/${spotId}/modules/${moduleId}/slots/${slotId}/wares`, ware);
        return await this.handleResponse<Ware>(response);
    }

    public static async fetchSpotModuleSlotWare(spotId: string, moduleId: string, slotId: string, wareId: string): Promise<Ware> {
        const response = await this.fetch(`${endpoint}/spots/${spotId}/modules/${moduleId}/slots/${slotId}/wares/${wareId}`);
        return await this.handleResponse<Ware>(response);
    }

    public static async patchSpotModuleSlotWare(spotId: string, moduleId: string, slotId: string, wareId: string, body: UpdateWare): Promise<Ware> {
        const response = await this.patch(`${endpoint}/spots/${spotId}/modules/${moduleId}/slots/${slotId}/wares/${wareId}`, body);
        return await this.handleResponse<Ware>(response);
    }

    public static async deleteSpotModuleSlotWare(spotId: string, moduleId: string, slotId: string, wareId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/spots/${spotId}/modules/${moduleId}/slots/${slotId}/wares/${wareId}`);
        return await this.handleVoidResponse(response);
    }

    public static async fetchReportWares(queryParams?: ApiQueryParams<DefaultQueryParams | WareQueryParams>): Promise<any> {
        return await this.apiPaginated(`${endpoint}/report-wares`, queryParams);
    }

    public static async fetchReportWaresProductQuantity(queryParams?: ApiQueryParams<DefaultQueryParams | WareQueryParams>): Promise<any> {
        return await this.apiPaginated(`${endpoint}/report-wares-product-quantity`, queryParams);
    }
}
