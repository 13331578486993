import { Action, ThunkDispatch } from '@reduxjs/toolkit';

import useRestrictionValueSelect from './restriction-value-select.controller';

export interface RestrictionValueSelectProps {
    id?: string;
    label?: string;
    required?: boolean;
    submitted: boolean;
    onChange: (newValue?: string) => void;
    value?: string;
    className?: string;
    disabled?: boolean;
    useConditionedStyling?: boolean;
    isClearable?: boolean;
    dispatch: ThunkDispatch<any, any, Action>;

    type: string;
    restriction_key: string;
}

const RestrictionValueSelect = (props: RestrictionValueSelectProps) => {
    const viewProps = useRestrictionValueSelect(props);
    return viewProps;
};

export default RestrictionValueSelect;
