import { CreateTransactionData, UserInterfaceImplementation } from '@frontend/user-interface/types';
import { WorkflowVariable } from '@frontend/workflow-variable/types';
import React from 'react';

import { UserInterfaceTemplateProps } from '../../../../../user-interface-template-props';
import ContactIdContact from './contact-id-contact/contact-id-contact.component';
import ContactIdEmail from './contact-id-email/contact-id-email.component';

export interface ContactIdInputProps extends UserInterfaceTemplateProps {
    userInterface: UserInterfaceImplementation<CreateTransactionData>;
    callback: (variable: WorkflowVariable) => void;
    variable: WorkflowVariable;
}

const ContactIdInput = (props: ContactIdInputProps) => {
    const method = props.variable.name.toLowerCase().includes('sender')
        ? props.userInterface.data.senderSelectMethod
        : props.userInterface.data.receiverSelectMethod;
    switch (method) {
        case 'company':
            return <>Not immplemented yet</>;
        //TODO return <ContactIdCompany callback={props.callback} variable={props.variable} />
        case 'contact':
            return (
                <ContactIdContact
                    callback={props.callback}
                    variable={props.variable}
                    dispatch={props.dispatch}
                />
            );
        case 'email':
            return (
                <ContactIdEmail
                    callback={props.callback}
                    variable={props.variable}
                />
            );
    }
};

export default ContactIdInput;
