import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { CreateSetting, Setting, SettingEntityType, SettingListResponse, SettingQueryParams, UpdateSetting } from '@frontend/setting/types';

const endpoint = '/setting-api/v1';
export class SettingClient extends APIClient {
    public static async fetchSettings(queryParams?: ApiQueryParams<DefaultQueryParams | SettingQueryParams>): Promise<SettingListResponse> {
        return await this.apiPaginated<SettingListResponse, DefaultQueryParams | SettingQueryParams>(`${endpoint}/settings`, queryParams);
    }

    public static async fetchEntitySettings(entityType: SettingEntityType, entityId: string, queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<SettingListResponse> {
        return await this.apiPaginated<SettingListResponse, DefaultQueryParams>(`${endpoint}/${entityType}s/${entityId}/settings`, queryParams);
    }

    public static async postEntitySetting(entityType: SettingEntityType, entityId: string, setting: CreateSetting): Promise<Setting> {
        const response = await this.post(`${endpoint}/${entityType}s/${entityId}/settings`, setting);
        return await this.handleResponse<Setting>(response);
    }

    public static async fetchEntitySetting(entityType: SettingEntityType, entityId: string, settingId: string): Promise<Setting> {
        const response = await this.fetch(`${endpoint}/${entityType}s/${entityId}/settings/${settingId}`);
        return await this.handleResponse<Setting>(response);
    }

    public static async patchEntitySetting(entityType: SettingEntityType, entityId: string, settingId: string, setting: UpdateSetting): Promise<Setting> {
        const response = await this.patch(`${endpoint}/${entityType}s/${entityId}/settings/${settingId}`, setting);
        return await this.handleResponse<Setting>(response);
    }

    public static async deleteEntitySetting(entityType: SettingEntityType, entityId: string, settingId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/${entityType}s/${entityId}/settings/${settingId}`);
        return await this.handleVoidResponse(response);
    }
}