import { CheckboxInput } from '@frontend/basic-forms';
import React from 'react';
import useChooseAckSlotDataForm from './choose-ack-slot-data-form.controller';

const ID = 'choose-ack-slot-data-form';
export interface ChooseAckSlotDataFormProps {
    onChange: (value: any) => void;
    submitted: boolean;
}

const ChooseAckSlotDataForm = (props: ChooseAckSlotDataFormProps) => {
    const viewProps = useChooseAckSlotDataForm(props);

    return (
        <div
            id={ID}
            className='d-flex flex-row justify-content-between'>
            <CheckboxInput
                label='Allow change SLOT'
                onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, allowChangeSlot: value })}
                submitted={props.submitted}
                value={viewProps.formValues.allowChangeSlot}
            />
        </div>
    );
};

export default ChooseAckSlotDataForm;
