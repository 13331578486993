import { PaginatedResponse } from "@frontend/api-utils";

export interface AccessLog {
    update_timestamp: string;
    driver_type: string;
    driver_id: string;
    account_id: string;
    user_id: string;
    user_type: string;
    access_type: AccessLogType;
    id: string;
    spot_id: string;
    creation_timestamp: string;
}

export enum AccessLogType {
    ENTERED = 'entered',
    EXITED = 'exited'
}

export interface CreateAccessLog {
    driver_type: string | null;
    driver_id: string | null;
    user_id: string;
    user_type: string | null;
    access_type?: AccessLogType;
    account_id: string;
}

export type AccessLogListResponse = PaginatedResponse<AccessLog>;

export enum AccessLogQueryParams {
    SPOT_ID = 'spot_id'
}