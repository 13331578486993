import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';

import { CreateTransactionVariable, TransactionVariable, TransactionVariableListResponse, UpdateTransactionVariable } from '../transaction-variables';

const endpoint = '/transaction-api/v1';

export class TransactionVariableClient extends APIClient {
    public static async fetchAccountTransactionVariables(
        accountId: string,
        transactionId: string,
        queryParams?: ApiQueryParams<DefaultQueryParams>
    ): Promise<TransactionVariableListResponse> {
        return await this.apiPaginated<TransactionVariableListResponse, DefaultQueryParams>(
            `${endpoint}/accounts/${accountId}/transactions/${transactionId}/variables`,
            queryParams
        );
    }

    public static async postAccountTransactionVariable(
        accountId: string,
        transactionId: string,
        transactionVariable: CreateTransactionVariable
    ): Promise<TransactionVariable> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/transactions/${transactionId}/variables`, transactionVariable);
        return await this.handleResponse<TransactionVariable>(response);
    }

    public static async fetchAccountTransactionVariable(accountId: string, transactionId: string, transactionVariableId: string): Promise<TransactionVariable> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/transactions/${transactionId}/variables/${transactionVariableId}`);
        return await this.handleResponse<TransactionVariable>(response);
    }

    public static async patchAccountTransactionVariable(
        accountId: string,
        transactionId: string,
        transactionVariableId: string,
        transactionVariable: UpdateTransactionVariable
    ): Promise<TransactionVariable> {
        const response = await this.patch(
            `${endpoint}/accounts/${accountId}/transactions/${transactionId}/variables/${transactionVariableId}`,
            transactionVariable
        );
        return await this.handleResponse<TransactionVariable>(response);
    }

    public static async deleteAccountTransactionVariable(accountId: string, transactionId: string, transactionVariableId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/transactions/${transactionId}/variables/${transactionVariableId}`);
        return await this.handleVoidResponse(response);
    }
}
