import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';

import { SpotClient } from '@frontend/spot/api';
import { seedSpots } from '@frontend/spot/redux';
import { Spot } from '@frontend/spot/types';
import React from 'react';

declare global {
    interface WindowEventMap {
        'useSpot:request': CustomEvent<string>;
    }
}

const REQUEST_SPOT_EVENT = 'useSpot:request';
const STORAGE_KEY = 'SPT_REQ';

export function spotRequest(spotId: string) {
    window.dispatchEvent(new CustomEvent(REQUEST_SPOT_EVENT, { detail: spotId }));
}

interface Props {
    dispatch: ThunkDispatch<any, any, AnyAction>;
}

export function SpotResolver(props: Props) {
    const [pending, changePending] = useState<string[]>([]);
    const [timeoutSet, changeTimeoutSet] = useState<boolean>(false);
    const [timeoutFinished, changeTimeoutFinished] = useState<boolean>(false);

    useEffect(() => {
        window.addEventListener(REQUEST_SPOT_EVENT, listener);
    }, []);

    useEffect(() => {
        if (timeoutFinished) {
            const stored = localStorage.getItem(STORAGE_KEY);
            if (stored != null) {
                changePending(JSON.parse(stored));
                changeTimeoutSet(false);
                changeTimeoutFinished(false);
                localStorage.removeItem(STORAGE_KEY);
            }
        }
    }, [timeoutFinished]);

    useEffect(() => {
        if (pending.length > 0) {
            getSpots(pending).then((result) => {
                props.dispatch(seedSpots(result));
                changePending([]);
                changeTimeoutSet(false);
                changeTimeoutFinished(false);
            });
        }
    }, [pending]);

    const listener = (event: CustomEvent) => {
        if (typeof event.detail == 'string') {
            if (timeoutSet === false) {
                changeTimeoutSet(true);
                setTimeout(() => {
                    changeTimeoutFinished(true);
                }, 1000);
            }

            const stored = localStorage.getItem(STORAGE_KEY);
            if (stored == null) {
                localStorage.setItem(STORAGE_KEY, JSON.stringify([event.detail]));
            } else if (!JSON.parse(stored).includes(event.detail)) {
                localStorage.setItem(STORAGE_KEY, JSON.stringify([...JSON.parse(stored), event.detail]));
            }
        }
    };
    return <></>;
}

async function getSpots(spotIds: string[]): Promise<Spot[]> {
    const response = await SpotClient.resolveSpots(spotIds);
    return response.results;
}
