import React, { lazy } from 'react';

const AccountBadge = lazy(() => import('@frontend/account/badge').then((module) => ({ default: module.AccountBadge })));
const ContactBadge = lazy(() => import('@frontend/contact/badge').then((module) => ({ default: module.ContactBadge })));
const DeviceBadge = lazy(() => import('@frontend/device/badge').then((module) => ({ default: module.DeviceBadge })));
const DriverBadge = lazy(() => import('@frontend/edge/common').then((module) => ({ default: module.DriverBadge })));
const ModuleBadge = lazy(() => import('@frontend/module/common').then((module) => ({ default: module.ModuleBadge })));
const NotificationTemplateBadge = lazy(() => import('@frontend/notification-service/common').then((module) => ({ default: module.NotificationTemplateBadge })));
const MovementBadge = lazy(() => import('@frontend/stock/common').then((module) => ({ default: module.MovementBadge })));
const PackageBadge = lazy(() => import('@frontend/package/common').then((module) => ({ default: module.PackageBadge })));
const ProductBadge = lazy(() => import('@frontend/product/badge').then((module) => ({ default: module.ProductBadge })));
const ProductCatalogBadge = lazy(() => import('@frontend/product-catalog/common').then((module) => ({ default: module.ProductCatalogBadge })));
const ProductCategoryBadge = lazy(() => import('@frontend/product-category/common').then((module) => ({ default: module.ProductCategoryBadge })));
const SlotBadge = lazy(() => import('@frontend/slot/common').then((module) => ({ default: module.SlotBadge })));
const SpotBadge = lazy(() => import('@frontend/spot/common').then((module) => ({ default: module.SpotBadge })));
const WareBadge = lazy(() => import('@frontend/stock/common').then((module) => ({ default: module.WareBadge })));
const TransactionBadge = lazy(() => import('@frontend/transaction/badge').then((module) => ({ default: module.TransactionBadge })));
const UserBadge = lazy(() => import('@frontend/user/badge').then((module) => ({ default: module.UserBadge })));
const WorkflowBadge = lazy(() => import('@frontend/workflow/common').then((module) => ({ default: module.WorkflowBadge })));
const ImportLogBadge = lazy(() => import('@frontend/import-logs/badge').then((module) => ({ default: module.ImportLogBadge })));

const ID = 'object-breadcrumb';
export interface ObjectBreadcrumbProps {
    value: string;
    type: string;
}

const ObjectBreadcrumb = (props: ObjectBreadcrumbProps) => {
    switch (props.type) {
        case 'accounts':
            return <AccountBadge id={props.value} />;
        case 'workflows':
            return <WorkflowBadge id={props.value} />;
        case 'transactions':
            return <TransactionBadge id={props.value} />;
        case 'contacts':
            return <ContactBadge id={props.value} />;
        case 'users':
            return <UserBadge id={props.value} />;
        case 'devices':
            return <DeviceBadge id={props.value} />;
        case 'drivers':
            return <DriverBadge id={props.value} />;
        case 'spots':
            return <SpotBadge id={props.value} />;
        case 'slots':
            return <SlotBadge id={props.value} />;
        case 'modules':
            return <ModuleBadge id={props.value} />;
        case 'products':
            return <ProductBadge id={props.value} />;
        case 'packages':
            return <PackageBadge id={props.value} />;
        case 'notification-templates':
            return <NotificationTemplateBadge id={props.value} />;
        case 'wares':
            return <WareBadge id={props.value} />;
        case 'movements':
            return <MovementBadge id={props.value} />;
        case 'product-categories':
            return <ProductCategoryBadge id={props.value} />;
        case 'product-catalogs':
            return <ProductCatalogBadge id={props.value} />;
        case 'import-logs':
            return <ImportLogBadge id={props.value} />;
    }

    return <div id={ID}>{props.value}</div>;
};

export default ObjectBreadcrumb;
