import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isArray, toNumber } from 'lodash';

import { BadgeClient } from '@frontend/badge/api';
import { Badge, BadgeListResponse, BadgeQueryParams } from '@frontend/badge/types';

export interface BadgeState {
    unordered: Badge[];
    badges: BadgeListResponse | null;
    accountBadges: { [accountId: string]: BadgeListResponse } | null;
    status: SliceStatus;
}

const initialState: BadgeState = {
    unordered: [],
    badges: null,
    accountBadges: null,
    status: SliceStatus.INIT
};

export const badgeSlice = createSlice({
    name: 'badges',
    initialState,
    reducers: {
        seedBadges(state, action: PayloadAction<Badge[]>) {
            state.unordered = [...state.unordered.filter((badge) => action.payload.find((b) => b.id == badge.id) == undefined), ...action.payload];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchBadges.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchBadges.fulfilled, (state, action) => {
                const startPos = toNumber(action.meta.arg.size) * toNumber(action.meta.arg.index);
                if (state.badges == null) {
                    state.badges = {
                        ...action.payload,
                        results: new Array(action.payload.count)
                    };
                    state.badges.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.badges.results.length !== action.payload.count) {
                        state.badges.count = action.payload.count;
                        state.badges.results = new Array(action.payload.count);
                    }
                    state.badges.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }

                if (action.meta.arg.account_id && !isArray(action.meta.arg.account_id)) {
                    if (state.accountBadges == null) {
                        state.accountBadges = { [action.meta.arg.account_id]: { ...action.payload, results: new Array(action.payload.count) } };
                        state.accountBadges[action.meta.arg.account_id].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    } else if (state.accountBadges && !state.accountBadges[action.meta.arg.account_id]) {
                        state.accountBadges[action.meta.arg.account_id] = { ...action.payload, results: new Array(action.payload.count) };
                        state.accountBadges[action.meta.arg.account_id].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    } else {
                        if (state.accountBadges[action.meta.arg.account_id].results.length !== action.payload.count) {
                            state.accountBadges[action.meta.arg.account_id].count = action.payload.count;
                            state.accountBadges[action.meta.arg.account_id].results = new Array(action.payload.count);
                        }
                        state.accountBadges[action.meta.arg.account_id].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    }
                }
                state.unordered = [
                    ...state.unordered.filter((b) => action.payload.results.find((res) => res.id == b.id) == undefined),
                    ...action.payload.results
                ];
            })
            .addCase(fetchAccountBadges.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchAccountBadges.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                const startPos = toNumber(action.meta.arg.queryParams?.size) * toNumber(action.meta.arg.queryParams?.index);
                if (state.accountBadges == null) {
                    state.accountBadges = { [action.meta.arg.accountId]: { ...action.payload, results: new Array(action.payload.count) } };
                    state.accountBadges[action.meta.arg.accountId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else if (state.accountBadges[action.meta.arg.accountId] == undefined) {
                    state.accountBadges[action.meta.arg.accountId] = { ...action.payload, results: new Array(action.payload.count) };
                    state.accountBadges[action.meta.arg.accountId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.accountBadges[action.meta.arg.accountId].results.length !== action.payload.count) {
                        state.accountBadges[action.meta.arg.accountId].count = action.payload.count;
                        state.accountBadges[action.meta.arg.accountId].results = new Array(action.payload.count);
                    }
                    state.accountBadges[action.meta.arg.accountId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.unordered = [
                    ...state.unordered.filter((a) => action.payload.results.find((res) => res.id == a.id) == undefined),
                    ...action.payload.results
                ];
            })
            .addCase(deleteBadges.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(deleteBadges.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                state.unordered = state.unordered.filter((u) => !action.meta.arg.includes(u.id));
                if (state.badges == null) return;
                state.badges.count = state.badges.count - action.meta.arg.length;
                state.badges.results = state.badges.results.filter((b) => !action.meta.arg.includes(b.id));
            });
    }
});

export const fetchBadges = createAsyncThunk<BadgeListResponse, ApiQueryParams<BadgeQueryParams>>(
    'fetchBadges',
    async (queryParams: ApiQueryParams<BadgeQueryParams>, { rejectWithValue }) => {
        try {
            return await BadgeClient.fetchBadges(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchAccountBadge = createAsyncThunk<Badge, { accountId: string; badgeId: string }>(
    'fetchAccountBadge',
    async (variables: { accountId: string; badgeId: string }, { rejectWithValue }) => {
        try {
            return await BadgeClient.fetchAccountBadge(variables.accountId, variables.badgeId);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchAccountBadges = createAsyncThunk<BadgeListResponse, { accountId: string; queryParams?: ApiQueryParams<DefaultQueryParams> }>(
    'fetchAccountBadges',
    async (variables: { accountId: string; queryParams?: ApiQueryParams<DefaultQueryParams> }, { rejectWithValue }) => {
        try {
            return await BadgeClient.fetchAccountBadges(variables.accountId, variables.queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const deleteAccountBadge = createAsyncThunk<void, { accountId: string; badgeId: string }>(
    'deleteAccountBadge',
    async (variables: { accountId: string; badgeId: string }, { rejectWithValue }) => {
        try {
            return await BadgeClient.removeAccountBadge(variables.accountId, variables.badgeId);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const deleteBadges = createAsyncThunk<void, string[]>('deleteBadges', async (badgeIds: string[], { rejectWithValue }) => {
    try {
        return await BadgeClient.deleteBadges(badgeIds);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const badgeStore = { badges: badgeSlice.reducer };
export const { seedBadges } = badgeSlice.actions;
