import { CountryInput, TextArea, TextInput } from '@frontend/basic-forms';
import { ClassType } from '@frontend/common';
import { CommonMessage } from '@frontend/lang';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';

import { Account } from '@frontend/account/types';
import { ModalFooter } from '@frontend/elements';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import useCreateAccountForm from './create-account-form.controller';

const ID = 'create-account-form';
export interface CreateAccountFormProps {
    onClose: () => void;
    callback?: (account: Account) => void;
    dispatch: ThunkDispatch<any, any, Action>;
}

export const CreateAccountForm = (props: CreateAccountFormProps) => {
    const viewProps = useCreateAccountForm(props);

    return (
        <div id={ID}>
            <form onSubmit={viewProps.onSubmit}>
                <div className='modal-header'>
                    <h4>{CommonMessage.FORMS.CREATE_OBJECT('account')}</h4>
                </div>
                <div className='modal-body'>
                    <TextInput
                        id={ID + '-nameInput'}
                        label={CommonMessage.FORMS.NAME}
                        errorMessage={
                            <FormattedMessage
                                id='account.forms.createAccount.nameInput.errorMessage'
                                description='The default error message when the name is not correctly filled in on the account form.'
                                defaultMessage='Please fill in a valid name.'
                            />
                        }
                        value={viewProps.formValues.name}
                        onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, name: value })}
                        required
                        isValidCallback={(valid) => {
                            viewProps.changeFormValid({ ...viewProps.formValid, name: valid });
                        }}
                        submitted={viewProps.submitted}
                    />
                    <div className='d-flex flex-row'>
                        <TextInput
                            id={ID + '-address-line-1Input'}
                            className='w-50 m-1'
                            label={CommonMessage.FORMS.ADDRESS_LINE('1')}
                            value={viewProps.formValues.address_line_1}
                            onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, address_line_1: value })}
                            isValidCallback={(valid) => {
                                viewProps.changeFormValid({ ...viewProps.formValid, address_line_1: valid });
                            }}
                            submitted={viewProps.submitted}
                        />
                        <TextInput
                            id={ID + '-address-line-2Input'}
                            className='w-50 m-1'
                            label={CommonMessage.FORMS.ADDRESS_LINE('2')}
                            value={viewProps.formValues.address_line_2}
                            onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, address_line_2: value })}
                            isValidCallback={(valid) => {
                                viewProps.changeFormValid({ ...viewProps.formValid, address_line_2: valid });
                            }}
                            submitted={viewProps.submitted}
                        />
                    </div>
                    <div className='d-flex flex-row'>
                        <TextInput
                            id={ID + '-zip-codeInput'}
                            className='w-50 m-1'
                            label={CommonMessage.FORMS.ZIP_CODE}
                            value={viewProps.formValues.zip_code}
                            onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, zip_code: value })}
                            isValidCallback={(valid) => {
                                viewProps.changeFormValid({ ...viewProps.formValid, zip_code: valid });
                            }}
                            submitted={viewProps.submitted}
                        />
                        <TextInput
                            id={ID + '-cityInput'}
                            className='w-50 m-1'
                            label={CommonMessage.FORMS.CITY}
                            value={viewProps.formValues.city}
                            onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, city: value })}
                            isValidCallback={(valid) => {
                                viewProps.changeFormValid({ ...viewProps.formValid, city: valid });
                            }}
                            submitted={viewProps.submitted}
                        />
                    </div>
                    <div className='d-flex flex-row'>
                        <CountryInput
                            className='w-50 m-1'
                            label={CommonMessage.FORMS.COUNTRY}
                            value={viewProps.formValues.country}
                            onChange={(value: any) => viewProps.changeFormValues({ ...viewProps.formValues, country: value })}
                            submitted={viewProps.submitted}
                        />
                        <TextArea
                            id={ID + '-contact-infoInput'}
                            className='w-50 m-1'
                            label={CommonMessage.FORMS.CONTACT_INFO}
                            value={viewProps.formValues.contact_info}
                            onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, contact_info: value })}
                            isValidCallback={(valid) => {
                                viewProps.changeFormValid({ ...viewProps.formValid, contact: valid });
                            }}
                            submitted={viewProps.submitted}
                        />
                    </div>
                </div>
                <ModalFooter
                    justifyContent='between'
                    buttons={[
                        {
                            hide: false,
                            text: CommonMessage.BUTTONS.CANCEL,
                            type: ClassType.SECONDARY,
                            onClick: (e) => {
                                e?.preventDefault();
                                props.onClose();
                            }
                        },
                        {
                            hide: false,
                            text: CommonMessage.BUTTONS.SUBMIT,
                            type: ClassType.SUCCESS,
                            icon: FaPlus,
                            buttonType: 'submit'
                        }
                    ]}
                />
            </form>
        </div>
    );
};

export default CreateAccountForm;
