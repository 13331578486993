import React, { useState } from 'react';

import useActionButton from './action-button.controller';

export interface ActionButtonProps {
    children: React.ReactNode;
}

export const ActionButton = (props: ActionButtonProps) => {
    const viewProps = useActionButton();
    const [pos, changePos] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
    return (
        <>
            <span
                ref={viewProps.spanRef}
                id='dropdownMenuButton'
                className='cursor-pointer font-weight-bolder text-lg'
                onClick={(e) => {
                    viewProps.setShowMenu(!viewProps.showMenu);
                    changePos({ x: e.pageX, y: e.pageY });
                }}>
                ...
            </span>
            {viewProps.showMenu && (
                <div
                    className='dropdown position-absolute'
                    ref={viewProps.menuRef}
                    style={{ right: pos.x, left: pos.x - 490 }}>
                    <div
                        className={`dropdown-menu ${viewProps.showMenu && 'show'}`}
                        id='no-margin'>
                        {props.children}
                    </div>
                </div>
            )}
        </>
    );
};
