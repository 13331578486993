import { PaginatedResponse } from '@frontend/api-utils';
import { DeviceStateName } from './device-state';

export interface Device {
    spot_id: string | null;
    module_id: string | null;
    slot_id: string | null;
    name: string | null;
    data: DeviceRakindaData | DeviceZhilaiData | DeviceTerminalData | DeviceS4MModbusData | null;
    type: DeviceType;
    protocol: DeviceProtocol;
    account_id: string | null;
    workflow_id: string | null;
    state: DeviceStateName;
    update_timestamp: string;
    status: string | null;
    heartbeat_timestamp: string | null;
    actuator_service_name: string | null;
    identifier: string;
    id: string;
    driver_id: string;
    creation_timestamp: string;
}

export type DeviceListResponse = PaginatedResponse<Device>;

export interface CreateDevice {
    spot_id?: string | null;
    module_id?: string | null;
    slot_id?: string | null;
    name?: string | null;
    data?: any;
    identifier: string;
    type: DeviceType | null;
    protocol: DeviceProtocol | null;
}

export interface UpdateDevice {
    spot_id?: string | null;
    module_id?: string | null;
    slot_id?: string | null;
    name?: string | null;
    data?: any;
    type: DeviceType | null;
    protocol: DeviceProtocol | null;
}

export interface DeviceZhilaiData {
    board: string;
    slot: string;
    ip_address: string;
}

export interface DeviceTerminalData {
    user_interface_id: string | null;
}

export interface DeviceRakindaData {
    master_pin_code: string | null;
}

export interface DeviceS4MModbusData {
    ip_address: string;
    door_index: number;
    open_command: number;
    rotate_column: number | null;
    max_open_command: number | null;
    max_rotate_column: number | null;
}

export interface DeviceSerialPortData {
    url: string;
    baudrate: number;
}

export interface DeviceS4MSerialData extends DeviceSerialPortData {
    board: string;
    address: string;
}

export enum DeviceQueryParams {
    TYPE = 'type',
    DRIVER_ID = 'driver_id',
    PROTOCOL = 'protocol'
}

export enum DeviceType {
    NFC_READER = 'nfc_reader',
    LOCKER = 'locker',
    QR_READER = 'qr_reader',
    ACCESS_CONTROLLER = 'access_controller',
    WEB_VIEW = 'web_view',
    CAROUSEL = 'carousel',
    DOOR = 'door',
    CABINET = 'cabinet'

}

export enum DeviceProtocol {
    SERIAL = 'serial',
    MODBUS = 'modbus',
    TCP = 'tcp',
    USB = 'usb',
    CAMERA = 'camera'
}

export interface DeviceSensorErrorData {
    code: string;
    body: string;
    solution: string;
    blocking: boolean;
    require_service: boolean;
}

export interface DeviceSensorDebugData {
    healthy: boolean;
    status: string;
    info: string;
}
