import { ClassType } from '@frontend/common';
import { StringPlaceholder } from '@frontend/elements';
import { ICONS } from '@frontend/icons';
import { CommonMessage } from '@frontend/lang';
import { Package, PackageType } from '@frontend/package/types';
import { Product } from '@frontend/product/types';
import { Slot } from '@frontend/slot/types';
import { Transaction } from '@frontend/transaction/types';
import { UserInterfaceImplementation, WarehouseProductPickDropData } from '@frontend/user-interface/types';
import React from 'react';
import { IconType } from 'react-icons';
import { FaWarehouse } from 'react-icons/fa';
import { FaCartArrowDown } from 'react-icons/fa6';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { FormattedMessage } from 'react-intl';

import AddToCartButtons from './add-to-cart-buttons/add-to-cart-buttons.component';
import useProductDetail from './product-detail.controller';

const ID = 'product-detail';
export interface ProductDetailProps {
    transaction: Transaction | null;
    packages: Package[];
    product: Product;
    onClose: () => void;
    changePackages: React.Dispatch<React.SetStateAction<Package[]>>;
    createTransaction: () => Promise<Transaction>;
    userInterface: UserInterfaceImplementation<WarehouseProductPickDropData>;
    checkout?: boolean;
}

const ProductDetail = (props: ProductDetailProps) => {
    const viewProps = useProductDetail(props);

    return (
        <div
            className='modal display-block'
            aria-modal='true'
            role='dialog'
            id={ID}>
            <div className={`modal-mobile-bottom ${props.product ? '' : 'hide'}`}>
                <div className='modal-header'>
                    <h3>{props.product.name || props.product.code}</h3>
                    <button
                        onClick={props.onClose}
                        className='btn btn-secondary'>
                        {CommonMessage.BUTTONS.CLOSE}
                    </button>
                </div>
                <div className='modal-body d-flex flex-column'>
                    <div className='mb-3'>
                        <h5 className='mb-2'>
                            <FormattedMessage
                                id='warehouse-product-pick-drop.current-stock.title'
                                description='Label for the current stock of the product.'
                                defaultMessage='Stock volume'
                            />
                            : <StringPlaceholder loaded={viewProps.amountInStock !== null}>{viewProps.amountInStock}</StringPlaceholder>
                        </h5>
                        <div className='mb-2'>
                            <h5>{CommonMessage.OBJECTS.COMMON_FIELDS.DESCRIPTION}: </h5>
                            <p className='fs-5'>{props.product.description || '-'}</p>
                        </div>
                        <div className='mb-2'>
                            <h5>
                                <FormattedMessage
                                    id='warehouse-product-pick-drop.location.title'
                                    description='Label for location of the product.'
                                    defaultMessage='Location'
                                />
                                :
                            </h5>
                            <p className='fs-5'>
                                <StringPlaceholder loaded={viewProps.slots !== null}>
                                    {viewProps.slots?.map((slot) => slot.number || slot.index).join(', ')}
                                </StringPlaceholder>
                            </p>
                        </div>
                    </div>

                    {props.userInterface.data.dropPackageWorkflowId != undefined && props.userInterface.data.pickPackageWorkflowId != undefined && (
                        <div className='d-flex flex-row justify-content-center'>
                            <TypeSelectButton
                                onClick={() => viewProps.switchType(PackageType.DROP_OFF)}
                                name={
                                    <FormattedMessage
                                        id='warehouse-product-pick-drop.add-to-warehouse'
                                        description='Label for the add to basket button.'
                                        defaultMessage='DropOff in warehouse'
                                    />
                                }
                                icon={FaWarehouse}
                                disabled={props.checkout}
                                partiallyHidden={viewProps.type === PackageType.PICK_UP}
                            />
                            <TypeSelectButton
                                onClick={() => viewProps.switchType(PackageType.PICK_UP)}
                                name={
                                    <FormattedMessage
                                        id='warehouse-product-pick-drop.take-from-warehouse'
                                        description='Label for the add to basket button.'
                                        defaultMessage='PickUp from warehouse'
                                    />
                                }
                                icon={FaCartArrowDown}
                                disabled={props.checkout}
                                partiallyHidden={viewProps.type === PackageType.DROP_OFF}
                            />
                        </div>
                    )}

                    {viewProps.type && viewProps.slots !== null && viewProps.slots?.length > 1 && (
                        <div className='text-center mt-4'>
                            <h4>location</h4>
                            <div className='d-flex flex-row justify-content-center flex-wrap'>
                                {viewProps.slots.map((slot) => {
                                    return (
                                        <SlotSelectButton
                                            key={slot.id}
                                            slot={slot}
                                            onClick={viewProps.changeSlot}
                                            partiallyHidden={!!viewProps.selectedSlot && viewProps.selectedSlot.id !== slot.id}
                                            disabled={props.checkout}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    )}

                    {viewProps.type && viewProps.selectedSlot && (
                        <>
                            <div className='d-flex flex-row justify-content-center align-items-center w-100 mt-4'>
                                <h4>
                                    {viewProps.type === PackageType.DROP_OFF && (
                                        <FormattedMessage
                                            id='warehouse-product-pick-drop.drop-off.question'
                                            description='Message displayed when the user wants to add items to the stock.'
                                            defaultMessage='How many items would you like to add to the stock?'
                                        />
                                    )}
                                    {viewProps.type === PackageType.PICK_UP && (
                                        <FormattedMessage
                                            id='warehouse-product-pick-drop.pick-up.question'
                                            description='Message displayed when the user wants to take items from the stock.'
                                            defaultMessage='How many items would you like to take?'
                                        />
                                    )}
                                </h4>
                            </div>
                            <AddToCartButtons
                                type={viewProps.type}
                                maxAmount={viewProps.type === PackageType.DROP_OFF ? Infinity : viewProps.selectedSlotStock ? viewProps.selectedSlotStock : 0}
                                amount={viewProps.amount}
                                changeAmount={viewProps.changeAmount}
                            />
                        </>
                    )}
                </div>
                <div className='modal-footer'>
                    <TypeSelectButton
                        icon={ICONS.BUTTON.DELETE}
                        name={CommonMessage.BUTTONS.DELETE}
                        partiallyHidden={viewProps.amount === 0}
                        disabled={viewProps.amount === 0}
                        onClick={viewProps.clear}
                        type={ClassType.DANGER}
                    />
                    <TypeSelectButton
                        icon={IoMdCheckmarkCircleOutline}
                        name={
                            props.checkout ? (
                                CommonMessage.BUTTONS.CONFIRM
                            ) : (
                                <FormattedMessage
                                    id='warehouse-product-pick-drop.add-to-basket'
                                    description='Label for the add to basket button.'
                                    defaultMessage='Add to basket'
                                />
                            )
                        }
                        disabled={viewProps.disabled}
                        partiallyHidden={viewProps.disabled}
                        onClick={viewProps.addToCart}
                        type={ClassType.SUCCESS}
                    />
                </div>
            </div>
        </div>
    );
};

export default ProductDetail;

export const TypeSelectButton = (props: {
    name: React.ReactNode | string;
    onClick: () => void;
    icon: IconType;
    partiallyHidden?: boolean;
    disabled?: boolean;
    type?: ClassType;
}) => {
    return (
        <div
            id={ID}
            className={`w-35 ratio ratio-1x1 ${props.partiallyHidden && 'opacity-25'}`}>
            <div className='p-3'>
                <div
                    className={`card shadow-lg overflow-hidden h-100`}
                    onClick={() => (props.disabled ? undefined : props.onClick())}>
                    <div className='d-flex flex-column h-100'>
                        <div className='d-flex align-items-center justify-content-center h-75 placeholder-glow'>
                            <props.icon
                                size={170}
                                className={`text-${props.type ? props.type : 'primary'}`}
                            />
                        </div>
                        <div className={`d-flex align-items-center justify-content-center h-25 bg-${props.type ? props.type : 'primary'}`}>
                            <h4 className='text-white placeholder-glow'>
                                <strong>{props.name}</strong>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const SlotSelectButton = (props: { slot: Slot; onClick: (slot: Slot) => void; partiallyHidden?: boolean; disabled?: boolean }) => {
    return (
        <button
            onClick={() => (props.disabled ? undefined : props.onClick(props.slot))}
            className={`w-25 btn btn-lg btn-primary m-2 ${props.partiallyHidden && 'opacity-25'}`}>
            <h4 className='text-white'>
                <strong>{props.slot.number}</strong>
            </h4>
        </button>
    );
};
