import { useEffect, useState } from 'react';

/**
 * Hook can be used to have a persistent state in local storage.
 * The initial value passed will be ignored if a value is already found in local storage.
 * @param key
 * @param initialValue
 * @returns
 */
export const useLocalStorageState = <T>(key: string, initialValue: T): [T, (value: T) => void] => {
    const [value, setValue] = useState<T>(initialValue);
    const listener = () => {
        const detail = localStorage.getItem(key);
        if (detail) setValue(JSON.parse(detail));
    };
    const EVENT_TYPE = `local-storage:${key}`;

    useEffect(() => {
        const current = localStorage.getItem(key);
        if (current) {
            setValue(JSON.parse(current));
        } else localStorage.setItem(key, JSON.stringify(value));

        window.addEventListener(EVENT_TYPE, listener);
        return () => window.removeEventListener(EVENT_TYPE, listener);
    }, []);
    const changeValue = (newValue: T) => {
        localStorage.setItem(key, JSON.stringify(newValue));
        window.dispatchEvent(new CustomEvent(EVENT_TYPE, { detail: { key, value: newValue } }));
    };

    return [value, changeValue];
};
