import { ActionButton, AddressBadge, DateBadge, DropdownButtonGroup, EmailBadge } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';
import React from 'react';
import { CellProps, Column } from 'react-table';

import { AuthorizationWrapper } from '@frontend/authorization';
import { EntityType, Rights } from '@frontend/common';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { UserBadge } from '../user-badge/user-badge.component';

const UserListColumns = (dispatch: ThunkDispatch<any, any, Action>): Column<any>[] => [
    {
        Header: 'Name',
        accessor: (user) => user.first_name + ' ' + user.last_name,
        id: 'last_name',
        Cell: (props: CellProps<any, string>) => {
            return (
                <td className='align-content-center'>
                    <UserBadge
                        id={props.row.original.id}
                        first_name={props.row.original.first_name}
                        last_name={props.row.original.last_name}
                    />
                </td>
            );
        }
    },
    {
        Header: 'Email',
        accessor: 'email',
        Cell: (props: CellProps<any, string>) => {
            return (
                <td className='text-secondary text-xs font-weight-bolder align-content-center'>
                    <EmailBadge email={props.row.original.email} />
                </td>
            );
        }
    },
    {
        Header: 'Address',
        accessor: (user) => user.address_line_1 + ' ' + user.city + ' ' + user.zip_code + ' ' + user.country,
        Cell: (props: CellProps<any, string>) => {
            return (
                <td className='text-secondary text-xs font-weight-bolder align-content-center'>
                    <AddressBadge
                        address={props.row.original.address_line_1}
                        zip={props.row.original.zip_code}
                        city={props.row.original.city}
                    />
                </td>
            );
        }
    },
    {
        Header: CommonMessage.OBJECTS.DEFAULT.CREATION_TIMESTAMP,
        accessor: 'creation_timestamp',
        Cell: (props: CellProps<any, string>) => {
            return (
                <td className='text-secondary text-xs font-weight-bolder align-content-center'>
                    <DateBadge
                        date={props.row.original.creation_timestamp}
                        displayTime
                    />
                </td>
            );
        }
    },
    {
        Header: '',
        id: 'actions',
        Cell: (props: CellProps<any, any>) => {
            return (
                <AuthorizationWrapper
                    showLoading={false}
                    requiredPermissions={[{ item: EntityType.USER, source: EntityType.ACCOUNT, right: Rights.SUPER_ADMIN, uid: '?' }]}>
                    <td>
                        <ActionButton>
                            <DropdownButtonGroup
                                showRoles={{ entityId: props.row.original.id, entityType: EntityType.USER }}
                                showJSON={props.row}
                                dispatch={dispatch}
                                key={props.row.original.id}
                            />
                        </ActionButton>
                    </td>
                </AuthorizationWrapper>
            );
        }
    }
];

export default UserListColumns;
