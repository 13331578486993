import { useState } from 'react';

import { PasswordBadgeProps } from './password-badge.component';

interface ViewProps {
    showPassword: boolean;
    changeShowPassword: React.Dispatch<React.SetStateAction<boolean>>;
    renderPassword: () => string;
}

const usePasswordBadge = (props: PasswordBadgeProps): ViewProps => {
    const [showPassword, changeShowPassword] = useState(false);

    const renderPassword = () => {
        if (showPassword) {
            return props.password;
        } else {
            return '•'.repeat(10);
        }
    };

    return {
        showPassword,
        changeShowPassword,
        renderPassword
    };
};

export default usePasswordBadge;
