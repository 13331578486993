import { ErrorHandler } from '@frontend/error-handler';
import { ToastUtil } from '@frontend/toast-utils';
import { deleteUsers, fetchUsers, userStore } from '@frontend/user/redux';
import { User } from '@frontend/user/types';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Column, SortingRule } from 'react-table';

import { DetailPageType, useAppSelector, useDetailPage } from '@frontend/common';
import { useSelector } from 'react-redux';
import UserListColumns from './user-list-columns';
import { UserListProps } from './user-list.component';

interface ViewProps {
    isLoading: boolean;
    data: User[];
    columns: Column<any>[];
    pageSize: number;
    pageCount: number;
    setPage: (value: React.SetStateAction<number>) => void;
    setPageSize: (value: React.SetStateAction<number>) => void;
    showCreateUserModal: boolean;
    changeShowCreateUserModal: (value: React.SetStateAction<boolean>) => void;
    selectedUsers: string[];
    changeSelectedUsers: React.Dispatch<React.SetStateAction<string[]>>;
    showDeleteModal: boolean;
    changeShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
    removeUsers: () => void;
    changeSortState: React.Dispatch<React.SetStateAction<SortingRule<object>[]>>;
    changeSearchValue: Dispatch<SetStateAction<string>>;
    isAdminRoute: boolean;
}

const useUserList = (props: UserListProps): ViewProps => {
    const userState = useAppSelector(useSelector, userStore);
    const [pageSize, setPageSize] = useState(25);
    const [page, setPage] = useState(1);
    const [showCreateUserModal, changeShowCreateUserModal] = useState<boolean>(false);
    const [selectedUsers, changeSelectedUsers] = useState<string[]>([]);
    const [showDeleteModal, changeShowDeleteModal] = useState<boolean>(false);
    const [sortState, changeSortState] = useState<SortingRule<object>[]>([]);
    const [searchValue, changeSearchValue] = useState<string>('');
    const { isAdminRoute } = useDetailPage(DetailPageType.USER);

    useEffect(() => {
        props.dispatch(
            fetchUsers({
                index: (page - 1).toString(),
                size: pageSize.toString(),
                ...(sortState.length > 0 && { sort_field: sortState[0].id, sort_direction: sortState[0].desc ? 'desc' : 'asc' }),
                ...(searchValue != '' && { search: searchValue })
            })
        );
    }, [page, pageSize, sortState, searchValue]);

    const removeUsers = () => {
        if (selectedUsers.length === 0) return;
        props.dispatch(deleteUsers(selectedUsers))
            .then(() => {
                ToastUtil.success(`Success`, `Deleted ${selectedUsers.length} users`);
                props.dispatch(
                    fetchUsers({
                        index: (page - 1).toString(),
                        size: pageSize.toString(),
                        ...(sortState.length > 0 && { sort_field: sortState[0].id, sort_direction: sortState[0].desc ? 'desc' : 'asc' }),
                        ...(searchValue != '' && { search: searchValue })
                    })
                );
            })
            .catch(ErrorHandler.handleError)
            .finally(() => changeShowDeleteModal(false));
    };

    const data = useMemo(() => {
        if (userState.users == null) return [];
        return userState.users.results;
    }, [userState]);
    const columns: Column<any>[] = useMemo(() => UserListColumns(props.dispatch), []);

    return {
        isLoading: userState.users == null,
        data,
        columns,
        pageSize,
        pageCount: userState.users ? Math.ceil(userState.users.count / pageSize) : 1,
        changeShowCreateUserModal,
        setPage,
        setPageSize,
        showCreateUserModal,
        changeSelectedUsers,
        changeShowDeleteModal,
        removeUsers,
        selectedUsers,
        showDeleteModal,
        changeSortState,
        changeSearchValue,
        isAdminRoute
    };
};

export default useUserList;
