import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';

import { Constraint, ConstraintListResponse, ConstraintQueryParams, CreateConstraint, UpdateConstraint } from '@frontend/constraint/types';

const endpoint = '/slot-api/v1';

export class ConstraintClient extends APIClient {
    public static async fetchConstraints(queryParams?: ApiQueryParams<DefaultQueryParams | ConstraintQueryParams>): Promise<ConstraintListResponse> {
        return await this.apiPaginated<ConstraintListResponse, DefaultQueryParams>(`${endpoint}/constraints`, queryParams);
    }

    public static async fetchSpotConstraints(spotId: string, queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<ConstraintListResponse> {
        return await this.apiPaginated<ConstraintListResponse, DefaultQueryParams>(`${endpoint}/spots/${spotId}/constraints`, queryParams);
    }

    public static async resolveConstraints(constraintIds: string[]): Promise<ConstraintListResponse> {
        const response = await this.post(`${endpoint}/constraints-resolve`, { ids: constraintIds });
        return await this.handleResponse<ConstraintListResponse>(response);
    }

    public static async postSpotConstraint(spotId: string, constraint: CreateConstraint): Promise<Constraint> {
        const response = await this.post(`${endpoint}/spots/${spotId}/constraints`, constraint);
        return await this.handleResponse<Constraint>(response);
    }

    public static async fetchSpotConstraint(spotId: string, constraintId: string): Promise<Constraint> {
        const response = await this.fetch(`${endpoint}/spots/${spotId}/constraints/${constraintId}`);
        return await this.handleResponse<Constraint>(response);
    }

    public static async patchSpotConstraint(spotId: string, constraintId: string, body: UpdateConstraint): Promise<Constraint> {
        const response = await this.patch(`${endpoint}/spots/${spotId}/constraints/${constraintId}`, body);
        return await this.handleResponse<Constraint>(response);
    }

    public static async deleteSpotConstraint(spotId: string, constraintId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/spots/${spotId}/constraints/${constraintId}`);
        return await this.handleVoidResponse(response);
    }
}
