import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { ImportLog, ImportLogListResponse } from '@frontend/import-logs/types';

const endpoint = '/import-api/v1';
export class ImportLogsClient extends APIClient {
    public static async fetchImportLogs(queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<ImportLogListResponse> {
        return await this.apiPaginated<ImportLogListResponse, DefaultQueryParams>(`${endpoint}/import-logs`, queryParams);
    }

    public static async resolveImportLogs(ids: string[]): Promise<ImportLogListResponse> {
        const response = await this.post(`${endpoint}/import-logs-resolve`, { ids: ids });
        return await this.handleResponse<ImportLogListResponse>(response);
    }

    public static async deleteImportLogs(ids: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/import-logs`, { ids: ids });
        await this.handleVoidResponse(response);
    }

    public static async fetchAccountImportLogs(accountId: string, queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<ImportLogListResponse> {
        return await this.apiPaginated<ImportLogListResponse, DefaultQueryParams>(`${endpoint}/accounts/${accountId}/import-logs`, queryParams);
    }

    public static async fetchAccountImportLog(accountId: string, importLogId: string): Promise<ImportLog> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/import-logs/${importLogId}`);
        return await this.handleResponse<ImportLog>(response);
    }

    public static async deleteAccountImportLog(accountId: string, importLogId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/import-logs/${importLogId}`);
        await this.handleVoidResponse(response);
    }
}
