import React from 'react';
import { FaEye } from 'react-icons/fa';
import { GrClose } from 'react-icons/gr';

interface ModalHeaderProps {
    id: string;
    title: any;
    handleClose: () => void;
    handleView?: () => void;
}

export const ModalHeader = (props: ModalHeaderProps) => {
    return (
        <div className='modal-header d-flex flex-row justify-content-between align-items-center'>
            <h3 className='font-weight-bolder text-info text-gradient w-60'>{props.title}</h3>
            <div className='d-flex justify-content-end'>
                {props.handleView && (
                    <button
                        onClick={props.handleView}
                        className='btn bg-gradient-primary me-2'>
                        <FaEye className='me-2' />
                        View details
                    </button>
                )}
                <button
                    id={props.id}
                    onClick={props.handleClose}
                    className='btn btn-outline-secondary'>
                    <GrClose />
                </button>
            </div>
        </div>
    );
};
