import { useAppSelector } from '@frontend/common';
import { fetchUserActions, userStore } from '@frontend/user/redux';
import { UserAction, UserActionName } from '@frontend/user/types';
import { fetchGeneralWorkflowActions, workflowSharedStore } from '@frontend/workflow/redux';
import { WorkflowActionName } from '@frontend/workflow/types';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { UserActionSelectProps } from './user-action-select.component';

interface ViewProps {
    options: { value: string; label: React.ReactNode }[];
    value:
        | {
              value: UserActionName | WorkflowActionName | string;
              label: React.ReactNode;
          }
        | {
              value: string;
              label: string;
          }
        | undefined;
}

const useUserActionSelect = (props: UserActionSelectProps): ViewProps => {
    const userState = useAppSelector(useSelector, userStore);
    const workflowSharedState = useAppSelector(useSelector, workflowSharedStore);

    useEffect(() => {
        props.dispatch(fetchUserActions());
        props.dispatch(fetchGeneralWorkflowActions());
    }, []);

    const options = useMemo(() => {
        if (!userState.userActions || !workflowSharedState.generalWorkflowActions) return [];
        return [...userState.userActions, ...workflowSharedState.generalWorkflowActions].map((action) => {
            const found = UserAction.ALL.find((t) => t.value === action);
            if (found) return { value: found.value, label: found.displayName };
            else
                return {
                    value: action,
                    label: action.replace(/_/g, ' ')
                };
        });
    }, [userState.userActions, workflowSharedState.generalWorkflowActions]);

    const value = useMemo(() => {
        if (!options || !props.value) return undefined;
        else return options.find((op) => op.value == props.value);
    }, [props.value, options]);

    return { options, value };
};

export default useUserActionSelect;
