import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

import { UserInterfaceButtonClient } from '@frontend/user-interface-button/api';
import { UserInterfaceButton, UserInterfaceButtonListResponse } from '@frontend/user-interface-button/types';

export interface UserInterfaceButtonState {
    unordered: UserInterfaceButton[];
    userInterfaceButtonsByAccountAndUserInterface: { [accAndWorkId: string]: UserInterfaceButtonListResponse } | null;
    userInterfaceButtons: UserInterfaceButtonListResponse | null;
    status: SliceStatus;
}

const initialState: UserInterfaceButtonState = {
    unordered: [],
    userInterfaceButtonsByAccountAndUserInterface: null,
    userInterfaceButtons: null,
    status: SliceStatus.INIT
};

const userInterfaceButtonSlice = createSlice({
    name: 'userInterfaceButtons',
    initialState,
    reducers: {
        updateUserInterfaceButton: (state, action: PayloadAction<UserInterfaceButton>) => {
            state.unordered = state.unordered.map((userInterfaceButton) =>
                userInterfaceButton.id == action.payload.id ? action.payload : userInterfaceButton
            );
            if (state.userInterfaceButtonsByAccountAndUserInterface != null) {
                const listId = action.payload.account_id + ':' + action.payload.user_interface_id;
                if (state.userInterfaceButtonsByAccountAndUserInterface[listId] != null) {
                    state.userInterfaceButtonsByAccountAndUserInterface[listId].results = state.userInterfaceButtonsByAccountAndUserInterface[
                        listId
                    ].results.map((userInterfaceButton: UserInterfaceButton) =>
                        userInterfaceButton.id == action.payload.id ? action.payload : userInterfaceButton
                    );
                }
            }
            if (state.userInterfaceButtons != null) {
                state.userInterfaceButtons.results = state.userInterfaceButtons.results.map((userInterfaceButton: UserInterfaceButton) =>
                    userInterfaceButton.id == action.payload.id ? action.payload : userInterfaceButton
                );
            }
        },
        addUserInterfaceButton(state, action: PayloadAction<UserInterfaceButton>) {
            state.unordered.push(action.payload);
            if (state.userInterfaceButtonsByAccountAndUserInterface != null) {
                const listId = action.payload.account_id + ':' + action.payload.user_interface_id;
                if (state.userInterfaceButtonsByAccountAndUserInterface[listId] != null) {
                    state.userInterfaceButtonsByAccountAndUserInterface[listId].count++;
                    state.userInterfaceButtonsByAccountAndUserInterface[listId].results = [action.payload].concat(
                        state.userInterfaceButtonsByAccountAndUserInterface[listId].results
                    );
                }
            }
            if (state.userInterfaceButtons != null) {
                state.userInterfaceButtons.results = [action.payload].concat(state.userInterfaceButtons.results);
                state.userInterfaceButtons.count++;
            }
        },
        removeUserInterfaceButton(state, action: PayloadAction<string>) {
            state.unordered = state.unordered.filter((w) => action.payload != w.id);
            if (state.userInterfaceButtonsByAccountAndUserInterface != null) {
                Object.keys(state.userInterfaceButtonsByAccountAndUserInterface).forEach((key) => {
                    state.userInterfaceButtonsByAccountAndUserInterface![key].results = state.userInterfaceButtonsByAccountAndUserInterface![
                        key
                    ].results.filter((w) => w.id != action.payload);
                });
            }
            if (state.userInterfaceButtons != null) {
                state.userInterfaceButtons.results = state.userInterfaceButtons.results.filter((w) => w.id != action.payload);
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAccountUserInterfaceButtons.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchAccountUserInterfaceButtons.fulfilled, (state, action) => {
                if (action.meta.arg.queryParams.size == null || action.meta.arg.queryParams.index == null) return;
                const listId = action.meta.arg.accountId + ':' + action.meta.arg.userInterfaceId;
                const startPos = toNumber(action.meta.arg.queryParams.size) * toNumber(action.meta.arg.queryParams.index);
                if (state.userInterfaceButtonsByAccountAndUserInterface == null) state.userInterfaceButtonsByAccountAndUserInterface = {};
                if (state.userInterfaceButtonsByAccountAndUserInterface[listId] == undefined) {
                    state.userInterfaceButtonsByAccountAndUserInterface[listId] = {
                        ...action.payload,
                        results: new Array(action.payload.count)
                    };
                    state.userInterfaceButtonsByAccountAndUserInterface[listId].results.splice(
                        startPos,
                        action.payload.results.length,
                        ...action.payload.results
                    );
                } else {
                    if (state.userInterfaceButtonsByAccountAndUserInterface[listId].results.length !== action.payload.count) {
                        state.userInterfaceButtonsByAccountAndUserInterface[listId].count = action.payload.count;
                        state.userInterfaceButtonsByAccountAndUserInterface[listId].results = new Array(action.payload.count);
                    }
                    state.userInterfaceButtonsByAccountAndUserInterface[listId].results.splice(
                        startPos,
                        action.payload.results.length,
                        ...action.payload.results
                    );
                }
                state.unordered = [
                    ...state.unordered.filter((w) => action.payload.results.find((res) => res.id == w.id) == undefined),
                    ...action.payload.results
                ];
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchUserInterfaceButtons.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchUserInterfaceButtons.fulfilled, (state, action) => {
                state.userInterfaceButtons = action.payload;
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchUserInterfaceButtons.rejected, (state) => {
                state.userInterfaceButtons = { count: 0, results: [] };
                state.status = SliceStatus.IDLE;
            });
    }
});

export const fetchAccountUserInterfaceButtons = createAsyncThunk<
    UserInterfaceButtonListResponse,
    { accountId: string; userInterfaceId: string; queryParams: ApiQueryParams<DefaultQueryParams> }
>(
    'fetchAccountUserInterfaceButtons',
    async (params: { accountId: string; userInterfaceId: string; queryParams: ApiQueryParams<DefaultQueryParams> }, { rejectWithValue }) => {
        try {
            return await UserInterfaceButtonClient.fetchAccountUserInterfaceButtons(params.accountId, params.userInterfaceId, params.queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchUserInterfaceButtons = createAsyncThunk<UserInterfaceButtonListResponse, ApiQueryParams<DefaultQueryParams>>(
    'fetchUserInterfaceButtons',
    async (queryParams: ApiQueryParams<DefaultQueryParams>, { rejectWithValue }) => {
        try {
            return await UserInterfaceButtonClient.fetchUserInterfaceButtons(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const userInterfaceButtonStore = { userInterfaceButtons: userInterfaceButtonSlice.reducer };
export const { updateUserInterfaceButton, addUserInterfaceButton, removeUserInterfaceButton } = userInterfaceButtonSlice.actions;
