import { Role, deleteEntityRole } from '@frontend/authorization';
import { SpotBadge } from '@frontend/spot/common';
import { RowAnchor, RowProps } from '@frontend/table';
import { lazy } from 'react';
import { IoMdTrash } from 'react-icons/io';
import { Row } from 'react-table';

import RakindaBadge from '../../../badges/rakinda-badge/rakinda-badge.component';
import TerminalBadge from '../../../badges/terminal-badge/terminal-badge.component';
import { useAppDispatch } from '../../../redux/store';

const AccountBadge = lazy(() => import('@frontend/account/common').then((module) => ({ default: module.AccountBadge })));
const TransactionBadge = lazy(() => import('@frontend/transaction/common').then((module) => ({ default: module.TransactionBadge })));
const UserBadge = lazy(() => import('@frontend/user/common').then((module) => ({ default: module.UserBadge })));
const SlotBadge = lazy(() => import('@frontend/slot/common').then((module) => ({ default: module.SlotBadge })));
const ContactBadge = lazy(() => import('@frontend/contact/components').then((module) => ({ default: module.ContactBadge })));

interface RoleListElementProps extends RowProps {
    selectEnabled?: boolean;
    row: Row<Role>;
    onClick?: (element: Role) => void;
}

const RoleListElement = (props: RoleListElementProps) => {
    const dispatch = useAppDispatch();
    const onDelete = () => {
        dispatch(deleteEntityRole({ type: props.row.original.entity_type!, entityId: props.row.original.entity_id, roleId: props.row.original.id }));
    };
    return (
        <tr
            id='RoleList-Row'
            onClick={() => props.onClick && props.onClick(props.row.original)}
            className={'' + (props.onClick && 'cursor-pointer')}>
            {props.selectEnabled === true && (
                <td
                    onClick={(e) => e.stopPropagation()}
                    {...props.row.cells[0].getCellProps()}>
                    {props.row.cells[0].render('Cell')}
                </td>
            )}
            <td className='text-secondary text-xs font-weight-bolder align-content-center'>
                <span>{props.row.original.name}</span>
            </td>
            <td className='text-secondary text-xs font-weight-bolder align-content-center'>{getCorrectItem(props.row.original)}</td>
            <td>
                <RowAnchor {...props} />
                <button
                    onClick={onDelete}
                    className='btn btn-outline-danger px-1 pb-1 pt-0 my-0 mx-1'>
                    <IoMdTrash />
                </button>
            </td>
        </tr>
    );
};

function getCorrectItem(role: Role): JSX.Element {
    switch (role.item) {
        case 'account':
            return <AccountBadge id={role.uid} />;
        case 'spot':
            return <SpotBadge id={role.uid} />;
        case 'slot':
            return <SlotBadge id={role.uid} />;
        case 'contact':
            return <ContactBadge id={role.uid} />;
        case 'rakinda':
            return <RakindaBadge id={role.uid} />;
        case 'terminal':
            return <TerminalBadge id={role.uid} />;
        case 'transaction':
            return <TransactionBadge id={role.uid} />;
        case 'user':
            return <UserBadge id={role.uid} />;
        default:
            return (
                <span>
                    {role.item} : {role.uid}
                </span>
            );
    }
}

export default RoleListElement;
