import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { Certificate, CertificateListResponse, CreateCertificate, UpdateCertificate } from '@frontend/certificate/types';


const endpoint = '/certificate-api/v1';

export class CertificateClient extends APIClient {
    public static async fetchCertificates(queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<CertificateListResponse> {
        return await this.apiPaginated<CertificateListResponse, DefaultQueryParams>(`${endpoint}/certificates`, queryParams);
    }

    public static async resolveCertificates(certificateIds: string[]): Promise<CertificateListResponse> {
        const response = await this.post(`${endpoint}/certificates-resolve`, { ids: certificateIds });
        return await this.handleResponse<CertificateListResponse>(response);
    }

    public static async deleteCertificates(certificateIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/certificates-delete`, { ids: certificateIds });
        return await this.handleVoidResponse(response);
    }

    public static async postCertificate(accountId: string, certificate: CreateCertificate): Promise<Certificate> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/certificates`, certificate);
        return await this.handleResponse<Certificate>(response);
    }

    public static async fetchCertificate(accountId: string, certificateId: string): Promise<Certificate> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/certificates/${certificateId}`);
        return await this.handleResponse<Certificate>(response);
    }

    public static async patchCertificate(accountId: string, certificateId: string, certificate: UpdateCertificate): Promise<Certificate> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/certificates/${certificateId}`, certificate);
        return await this.handleResponse<Certificate>(response);
    }

    public static async deleteCertificate(accountId: string, certificateId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/certificates/${certificateId}`);
        return await this.handleVoidResponse(response);
    }
}
