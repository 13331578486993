import { useAppSelector } from '@frontend/common';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { NotificationTemplateClient } from '@frontend/notification-service/api';
import { fetchAccountNotificationTemplates, notificationTemplateStore } from '@frontend/notification-service/redux';
import { NotificationTemplate } from '@frontend/notification-service/types';
import { NotificationTemplateByAccountSelectProps } from './notification-template-by-account-select.component';

interface ViewProps {
    value?: { value: string; label: string };
    options: { value: string; label: string }[];
    onScrollToBottom: () => void;
    search: (inputValue: string, callback: (options: { value: any; label: string }[]) => void) => void;
}

const pageSize = '100';
const useNotificationTemplateByAccountSelect = (props: NotificationTemplateByAccountSelectProps): ViewProps => {
    const timer = useRef<NodeJS.Timeout | null>(null);
    const [count, changeCount] = useState<number>(0);
    const [index, changeIndex] = useState<number>(0);
    const templatesState = useAppSelector(useSelector, notificationTemplateStore);
    const [options, changeOptions] = useState<{ value: string; label: string }[]>([]);
    const value = useMemo(() => {
        if (typeof props.value == 'string') return options.find((o) => o && o.value == props.value);
        return props.value;
    }, [props.value, options]);

    useEffect(() => {
        if (props.accountId)
            props.dispatch(fetchAccountNotificationTemplates({ accountId: props.accountId, params: { index: index.toString(), size: pageSize } }));
    }, [props.accountId, index]);

    useEffect(() => {
        if (props.accountId && templatesState.accountTemplates && templatesState.accountTemplates[props.accountId]) {
            changeCount(templatesState.accountTemplates[props.accountId].count);
            changeOptions(mapResultSetToOptions(templatesState.accountTemplates[props.accountId].results));
        }
    }, [templatesState.accountTemplates]);

    const search = (inputValue: string, callback: (options: { value: any; label: string }[]) => void) => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
            if (props.accountId)
                NotificationTemplateClient.fetchAccountNotificationTemplates(props.accountId, { search: inputValue, index: '0', size: pageSize }).then(
                    (result) => {
                        callback(mapResultSetToOptions(result.results));
                    }
                );
        }, 500);
    };

    const onScrollToBottom = () => {
        if (count / parseInt(pageSize) > 1 && parseInt(pageSize) * index < count) {
            changeIndex(index + 1);
        }
    };

    return {
        options,
        value,
        onScrollToBottom,
        search
    };
};

function mapResultSetToOptions(templates: NotificationTemplate[]): { value: any; label: string }[] {
    return templates.map((t) => ({ value: t.id, label: t.name }));
}

export default useNotificationTemplateByAccountSelect;
