import React from 'react';

interface Props {
    page: number;
    currentPage: number;
    onClick: () => void;
}

export const PageNumber = (props: Props) => {
    return (
        <li
            className={'page-item' + (props.page == props.currentPage ? ' active' : '')}
            onClick={props.onClick}>
            <button className={'page-link' + (props.page == props.currentPage ? ' text-light' : '')}>{props.page}</button>
        </li>
    );
};
