import React from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';

import { useAddressBadge } from './address-badge.controller';

export interface AddressBadgeProps {
    address?: string;
    zip?: string;
    city?: string;
}

export const AddressBadge = (props: AddressBadgeProps) => {
    const viewProps = useAddressBadge(props);

    if (!viewProps.isValid)
        return (
            <div>
                <FormattedMessage
                    id='AddressBadge.InvalidAddressMessage'
                    description='message shown when the address provided is invalid'
                    defaultMessage='Invalid address'
                />
            </div>
        );

    return (
        <a
            target='_blank'
            rel='noreferrer'
            className='d-flex flex-row justify-content-start align-items-center'
            href={viewProps.url}>
            <span className='pe-2'>
                <FaMapMarkerAlt className=' text-success text-gradient' />
            </span>
            <span className='d-flex flex-column'>
                <span className='text-xs'>{viewProps.isValid ? viewProps.address : '-'}</span>
            </span>
        </a>
    );
};
