import { SelectInput } from '@frontend/basic-forms';

import useRestrictionKeySelect from './restriction-key-select.controller';

const ID = 'restriction-key-select';

export interface RestrictionKeySelectProps {
    id?: string;
    label?: string;
    required?: boolean;
    submitted: boolean;
    onChange: (newValue?: any) => void;
    value?: string;
    className?: string;
    disabled?: boolean;
    useConditionedStyling?: boolean;
    isClearable?: boolean;
    type: string;
}

const RestrictionKeySelect = (props: RestrictionKeySelectProps) => {
    const viewProps = useRestrictionKeySelect(props);
    return (
        <SelectInput
            id={props.id || ID}
            label={props.label || 'Key'}
            className={props.className}
            submitted={props.submitted}
            required={props.required}
            options={viewProps.options}
            onChange={(newValue: any) => props.onChange(newValue?.value)}
            value={props.value}
            isDisabled={props.disabled}
            isClearable={props.isClearable}
            useConditionedStyling={props.useConditionedStyling}
        />
    );
};
export default RestrictionKeySelect;
