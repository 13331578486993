import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { CreateWorkflowField, UpdateWorkflowField, WorkflowField, WorkflowFieldListResponse } from '@frontend/workflow/types';



const endpoint = '/workflow-api/v1';

export class WorkflowFieldClient extends APIClient {
    public static async fetchWorkflowFields(queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<WorkflowFieldListResponse> {
        return await this.apiPaginated<WorkflowFieldListResponse, DefaultQueryParams>(`${endpoint}/workflow-fields`, queryParams);
    }
    public static async fetchAccountWorkflowStepFields(
        accountId: string,
        workflowId: string,
        stepId: string,
        queryParams?: ApiQueryParams<DefaultQueryParams>
    ): Promise<WorkflowFieldListResponse> {
        return await this.apiPaginated<WorkflowFieldListResponse, DefaultQueryParams>(
            `${endpoint}/accounts/${accountId}/workflows/${workflowId}/steps/${stepId}/fields`,
            queryParams
        );
    }

    public static async postAccountWorkflowStepField(
        accountId: string,
        workflowId: string,
        stepId: string,
        workflowField: CreateWorkflowField
    ): Promise<WorkflowField> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/workflows/${workflowId}/steps/${stepId}/fields`, workflowField);
        return await this.handleResponse<WorkflowField>(response);
    }

    public static async patchAccountWorkflowStepField(
        accountId: string,
        workflowId: string,
        stepId: string,
        workflowFieldId: string,
        workflowField: UpdateWorkflowField
    ): Promise<WorkflowField> {
        const response = await this.patch(
            `${endpoint}/accounts/${accountId}/workflows/${workflowId}/steps/${stepId}/fields/${workflowFieldId}`,
            workflowField
        );
        return await this.handleResponse<WorkflowField>(response);
    }
}
