import { PaginatedResponse } from '@frontend/api-utils';
import { ClassType } from '@frontend/common';

export enum EventEntityType {
    ACCOUNT = 'account',
    AUTHENTICATION = 'authentication',
    BADGE = 'badge',
    CONTACT = 'contact',
    DEVICE = 'device',
    DRIVER = 'driver',
    MODULE = 'module',
    PRODUCT = 'product',
    RAKINDA = 'rakinda',
    SERVER = 'server',
    SLOT = 'slot',
    SPOT = 'spot',
    TRANSACTION = 'transaction',
    USER = 'user',
    WORKFLOW = 'workflow',
    PACKAGE = 'package'
}

export enum EventLevel {
    DEBUG = 'debug',
    INFO = 'info',
    WARNING = 'warning',
    ERROR = 'error',
    ALERT = 'alert'
}

export enum EventType {
    TRANSACTION_STATE_CHANGED = 'transaction_state_changed',
    PRODUCT_STATE_CHANGED = 'product_state_changed',
    PACKAGE_STATE_CHANGED = 'package_state_changed',
    USER_STATE_CHANGED = 'user_state_changed',
    DEVICE_STATE_CHANGED = 'device_state_changed',
    TRANSACTION_TRIGGER_RECEIVED = 'transaction_trigger_received',
    PRODUCT_TRIGGER_RECEIVED = 'product_trigger_received',
    PACKAGE_TRIGGER_RECEIVED = 'package_trigger_received',
    USER_TRIGGER_RECEIVED = 'user_trigger_received',
    DEVICE_TRIGGER_RECEIVED = 'device_trigger_received',
    TRANSACTION_ACTION_TRIGGERED = 'transaction_action_triggered',
    PRODUCT_ACTION_TRIGGERED = 'product_action_triggered',
    PACKAGE_ACTION_TRIGGERED = 'package_action_triggered',
    USER_ACTION_TRIGGERED = 'user_action_triggered',
    DEVICE_ACTION_TRIGGERED = 'device_action_triggered',
    SLOT_SENSOR_RECEIVED = 'slot_sensor_received',
    NEW_DRIVER_REGISTERED = 'new_driver_registered',
    NEW_DEVICE_REGISTERED = 'new_device_registered',
    DEVICE_HEARTBEAT_MISSED = 'device_heartbeat_missed',
    DEVICE_HEARTBEAT_RECOVERED = 'device_heartbeat_recovered',
    RAKINDA_MASTER_PIN_USED = 'rakinda_master_pin_code_used',
    RAKINDA_ACCESS_GRANTED = 'rakinda_access_granted',
    RAKINDA_ACCESS_DENIED = 'rakinda_access_denied',
    EMAIL_SENT = 'email_sent',
    ENTITY_SYNC_CREATED = 'entity_sync_created',
    ENTITY_SYNC_UPDATED = 'entity_sync_updated',
    ENTITY_SYNC_DELETED = 'entity_sync_deleted',
    QR_CODE_SCANNED = 'qr_code_scanned',
    NFC_BADGE_SCANNED = 'nfc_badge_scanned'
}

export enum EventCategory {
    ACCESS_CONTROL = 'access_control',
    WORKFLOW = 'workflow',
    IOT = 'iot',
    NOTIFICATION = 'notification',
    SYNC = 'sync'
}

export interface Event {
    level: EventLevel;
    type: EventType;
    data: string;
    id: string;
    creation_timestamp: string;
    category: EventCategory;
}

export interface EventMessage {
    message: string;
    level: { severity: ClassType; icon: React.ReactNode };
    type: React.ReactElement;
    timestamp: string;
    id: string;
    category: string;
}

export interface EventEntity {
    id: string;
    event_id: string;
    entity_type: EventEntityType;
    entity_id: string;
}

export type EventListResponse = PaginatedResponse<Event>;

export type EventEntitiesListResponse = PaginatedResponse<EventEntity>;


export interface IEventsFilter {
    type?: EventEntityType;
    id?: string;
    from?: string;
    to?: string;
}

export enum EventQueryParams {
    LEVEL = 'level',
    TYPE = 'type',
    CATEGORY = 'category'
}