import { DetailPageType, parseObject, useDetailPage } from '@frontend/common';
import { ErrorHandler } from '@frontend/error-handler';
import { ToastUtil } from '@frontend/toast-utils';
import { UserClient } from '@frontend/user/api';
import { UpdateUser } from '@frontend/user/types';
import { useState } from 'react';

import { UpdateUserFormProps } from './update-user.component';

interface ViewProps {
    submitted: boolean;
    formValues: UpdateUser;
    formValid: FormValid;
    onSubmit: (e: any) => void;
    changeFormValues: React.Dispatch<React.SetStateAction<UpdateUser>>;
    changeFormValid: React.Dispatch<React.SetStateAction<FormValid>>;
    isAdminRoute: boolean;
}

interface FormValid {
    first_name?: boolean;
    last_name?: boolean;
    title?: boolean;
    workflow_id?: boolean;
}

const useUpdateUserForm = (props: UpdateUserFormProps): ViewProps => {
    const { isAdminRoute } = useDetailPage(DetailPageType.USER);
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const [formValues, changeFormValues] = useState<UpdateUser>({
        first_name: props.user.first_name,
        last_name: props.user.last_name,
        title: props.user.title,
        language: props.user.language,
        workflow_id: props.user.workflow_id
    });
    const [formValid, changeFormValid] = useState<FormValid>({
        first_name: true,
        last_name: true,
        title: true,
        workflow_id: true
    });

    const onSubmit = (e: any) => {
        e.preventDefault();
        changeSubmitted(true);
        const body = parseObject(formValues);
        UserClient.patchUser(props.user.account_id, body, props.user.id)
            .then(() => {
                ToastUtil.success('Success', 'User updated successfully');
                props.onClose();
            })
            .catch(ErrorHandler.handleError)
            .finally(() => changeSubmitted(false));
    };

    return {
        changeFormValid,
        changeFormValues,
        formValid,
        formValues,
        onSubmit,
        submitted,
        isAdminRoute
    };
};

export default useUpdateUserForm;
