import { useEffect, useState } from 'react';

import { BadgeClient } from '@frontend/badge/api';
import { Badge } from '@frontend/badge/types';

declare global {
    interface WindowEventMap {
        'useBadge:request': CustomEvent<string>;
    }
}

const REQUEST_BADGE_EVENT = 'useBadge:request';
const STORAGE_KEY = 'BDG_REQ';

export function badgeRequest(badgeId: string) {
    window.dispatchEvent(new CustomEvent(REQUEST_BADGE_EVENT, { detail: badgeId }));
}

interface Props {
    seedStore: (badges: Badge[]) => void;
}

export function useBadges(props: Props) {
    const [pending, changePending] = useState<string[]>([]);
    const [timeoutSet, changeTimeoutSet] = useState<boolean>(false);
    const [timeoutFinished, changeTimeoutFinished] = useState<boolean>(false);

    useEffect(() => {
        window.addEventListener(REQUEST_BADGE_EVENT, listener);
    }, []);

    useEffect(() => {
        if (timeoutFinished) {
            const stored = localStorage.getItem(STORAGE_KEY);
            if (stored != null) {
                changePending(JSON.parse(stored));
                changeTimeoutSet(false);
                changeTimeoutFinished(false);
                localStorage.removeItem(STORAGE_KEY);
            }
        }
    }, [timeoutFinished]);

    useEffect(() => {
        if (pending.length > 0) {
            getBadges(pending).then((result) => {
                props.seedStore(result);
                changePending([]);
            });
        }
    }, [pending]);

    const listener = (event: CustomEvent) => {
        if (typeof event.detail == 'string') {
            if (timeoutSet === false) {
                changeTimeoutSet(true);
                setTimeout(() => {
                    changeTimeoutFinished(true);
                }, 1000);
            }

            const stored = localStorage.getItem(STORAGE_KEY);
            if (stored == null) {
                localStorage.setItem(STORAGE_KEY, JSON.stringify([event.detail]));
            } else if (!JSON.parse(stored).includes(event.detail)) {
                localStorage.setItem(STORAGE_KEY, JSON.stringify([...JSON.parse(stored), event.detail]));
            }
        }
    };

    async function getBadges(badgeIds: string[]): Promise<Badge[]> {
        const response = await BadgeClient.resolveBadges(badgeIds);
        return response.results;
    }
}
