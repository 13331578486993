import { DefaultModal, ModalFooter } from '@frontend/elements';
import { EditorProvider, Extension } from '@tiptap/react';

import useNotificationPreview from './notification-preview.controller';
import React from 'react';

export interface NotificationPreviewProps {
    content: string;
    subject?: string;
    show: boolean;
    handleClose: () => void;
    extensions: Extension[];
}

const NotificationPreview = (props: NotificationPreviewProps) => {
    const viewProps = useNotificationPreview(props);

    return (
        <DefaultModal
            handleClose={() => {
                viewProps.editor?.destroy();
                viewProps.changeEditor(null);
                props.handleClose();
            }}
            show={props.show}>
            <div className='modal-body'>
                <h6>{viewProps.subject}</h6>
                <EditorProvider
                    extensions={props.extensions}
                    onCreate={(e) => viewProps.changeEditor(e.editor)}
                    editable={false}>
                    <div></div>
                </EditorProvider>
            </div>
            <ModalFooter
                buttons={[
                    {
                        hide: false,
                        text: 'Close',
                        onClick: () => {
                            viewProps.editor?.destroy();
                            viewProps.changeEditor(null);
                            props.handleClose();
                        }
                    }
                ]}
            />
        </DefaultModal>
    );
};

export default NotificationPreview;
