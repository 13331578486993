import { APIClient } from '@frontend/api-utils';
import { FormatTypeName, ImportResponse } from '@frontend/impex/types';

const endpoint = '/certificate-api/v1';

export class CertificateImpexClient extends APIClient {
    public static async fetchCertificatesTemplate(): Promise<void> {
        const response = await this.fetch(`${endpoint}/certificates-template`);
        await this.handleFileDownload(response, 'certificates-template');
    }

    public static async exportCertificates(format: FormatTypeName): Promise<void> {
        const response = await this.fetch(`${endpoint}/certificates-export?format_type=${format}`);
        await this.handleFileDownload(response, 'certificates');
    }

    public static async importCertificates(args: { accountId: string; file: File; dry_run: boolean }): Promise<ImportResponse> {
        const formData = new FormData();
        formData.append('file', args.file);
        const response = await this.post(`${endpoint}/accounts/${args.accountId}/certificates-import?dry_run=${args.dry_run}`, formData);
        return await this.handleResponse<ImportResponse>(response);
    }
}
