import { Reducer } from '@reduxjs/toolkit';
import { UseSelector } from 'react-redux';

export interface ReduxError {
    json?: { [key: string]: unknown };
    message: string;
    stack: string;
}
export enum SliceStatus {
    INIT = 'init',
    IDLE = 'idle',
    LOADING = 'loading',
    ERROR = 'error'
}
export enum SliceOperation {
    GET,
    PUT,
    POST,
    DELETE
}
/**
 * Will determin if it is usefull to refetch data
 * @param status
 * @param lastUpdate
 * @param staleTime overwrite the time between the last update in minutes (defaults to 2 minutes if no param is provided)
 * @returns
 */
export function shouldFetch(status: SliceStatus, lastUpdate: number, staleTime?: number): boolean {
    if (status === SliceStatus.INIT) return true;
    const actualStaleTime = staleTime !== undefined ? staleTime : 2;
    if (status === SliceStatus.IDLE && (new Date().getTime() - lastUpdate) / 60000 > actualStaleTime) return true;
    return false;
}

export function addOrUpdateList<T extends { id: string | number }>(element: T, list: T[]): void {
    const found = list.find((el) => el.id == element.id);
    if (found) list[list.indexOf(found)] = element;
    else list.push(element);
}

export function removeListElement<T extends { id: string | number }>(id: string | number, list: T[]): void {
    const found = list.find((el) => el.id == id);
    if (found) list.splice(list.indexOf(found), 1);
}

export function useAppSelector<T>(selector: UseSelector<unknown>, reducer: { [key: string]: Reducer<T> }): T {
    const accessor = Object.keys(reducer)[0];
    const state = selector((state: any) => state[accessor]);
    if (state) {
        return state;
    } else {
        throw new Error(
            `Accessor ${accessor} does not exist in this redux store \n the accepted keys are \n${Object.keys(selector((state: any) => state)).join('\n')}`
        );
    }
}

/**
 *
 * @param array
 * Array of objects you want to map
 * @param keys
 * Array of keys to be used. The first key will be the value and the second key will be the label
 * @returns
 */

export function mapArrayToOptions<T>(array: T[], value: keyof T, label: [keyof T, keyof T]): { value: any; label: string }[] {
    return array.map((item) => ({ value: item[value] as any, label: (item[label[0]] as string) || (item[label[1]] as string) }));
}
