import { convertLanguage } from '@frontend/common';
import { DateBadge } from '@frontend/elements';
import { NotificationContent } from '@frontend/notification-service/types';
import { RowProps } from '@frontend/table';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Row } from 'react-table';

interface NotificationContentElementProps extends RowProps {
    selectEnabled?: boolean;
    row: Row<NotificationContent>;
    onClick?: (element: NotificationContent) => void;
}

const NotificationContentListElement = (props: NotificationContentElementProps) => {
    const language = convertLanguage(props.row.original.language);
    const location = useLocation();
    const navigate = useNavigate();
    return (
        <tr
            id='NotificationContentList-Row'
            onClick={() => props.onClick && props.onClick(props.row.original)}
            className={'' + (props.onClick && 'cursor-pointer')}>
            {props.selectEnabled === true && (
                <td
                    onClick={(e) => e.stopPropagation()}
                    {...props.row.cells[0].getCellProps()}>
                    {props.row.cells[0].render('Cell')}
                </td>
            )}
            <td className='align-content-center'>
                <span
                    className='badge badge-secondary cursor-pointer'
                    onClick={() => navigate(`${location.pathname}/contents/${props.row.original.id}/detail`)}>
                    View content
                </span>
            </td>
            <td className='text-secondary text-xs font-weight-bolder align-content-center'>
                <span>{props.row.original.channel}</span>
            </td>
            <td className='text-secondary text-xs font-weight-bolder align-content-center'>
                <span>{language}</span>
            </td>
            <td className='text-secondary text-xs font-weight-bolder align-content-center'>
                <DateBadge
                    date={props.row.original.creation_timestamp}
                    displayTime
                />
            </td>
            <td className='text-secondary text-xs font-weight-bolder align-content-center'>
                <DateBadge
                    date={props.row.original.update_timestamp}
                    displayTime
                />
            </td>
        </tr>
    );
};

export default NotificationContentListElement;
