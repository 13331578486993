import { WorkflowAction, WorkflowActionName } from "@frontend/workflow/types";
import React from "react";
import { FormattedMessage } from "react-intl";

export enum TransactionActionName {
    TRANSACTION_CHANGE_STATE = 'transaction_change_state',
    TRANSACTION_PROCESS_PACKAGES = 'transaction_process_packages',
    TRANSACTION_REMOVE_CONSTRAINT = 'transaction_remove_constraint'
}

export class TransactionAction extends WorkflowAction {
    static readonly TRANSACTION_CHANGE_STATE = new TransactionAction(
        TransactionActionName.TRANSACTION_CHANGE_STATE,
        (
            <FormattedMessage
                id='TransactionAction.change_state'
                description='The displayed value for the TransactionAction change_state'
                defaultMessage='Change state'
            />
        )
    );

    static readonly TRANSACTION_PROCESS_PACKAGES = new TransactionAction(
        TransactionActionName.TRANSACTION_PROCESS_PACKAGES,
        (
            <FormattedMessage
                id='TransactionAction.process_packages'
                description='The displayed value for the TransactionAction process_packages'
                defaultMessage='Process packages'
            />
        )
    );

    static readonly TRANSACTION_REMOVE_CONSTRAINT = new TransactionAction(
        TransactionActionName.TRANSACTION_REMOVE_CONSTRAINT,
        (
            <FormattedMessage
                id='TransactionAction.remove_constraint'
                description='The displayed value for the TransactionAction remove_constraint'
                defaultMessage='Remove constraint'
            />
        )
    );
    
    static override readonly ALL = [
        TransactionAction.TRANSACTION_CHANGE_STATE,
        TransactionAction.TRANSACTION_PROCESS_PACKAGES,
        TransactionAction.TRANSACTION_REMOVE_CONSTRAINT,
        ...super.ALL
    ];

    static override getByValue(action: TransactionActionName | WorkflowActionName): WorkflowAction | undefined {
        return this.ALL.find((a) => a.value == action);
    }
}