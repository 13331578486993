import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

import { UserInterfaceClient } from '@frontend/user-interface/api';
import { UserInterface, UserInterfaceListResponse } from '@frontend/user-interface/types';

export interface UserInterfaceState {
    unordered: UserInterface[];
    userInterfaces: UserInterfaceListResponse | null;
    userInterfacesByAccount: { [accountId: string]: UserInterfaceListResponse } | null;
    status: SliceStatus;
}

const initialState: UserInterfaceState = {
    unordered: [],
    userInterfaces: null,
    userInterfacesByAccount: null,
    status: SliceStatus.INIT
};

const userInterfaceSlice = createSlice({
    name: 'userInterfaces',
    initialState,
    reducers: {
        seedUserInterfaces(state, action: PayloadAction<UserInterface[]>) {
            state.unordered = [
                ...state.unordered.filter((userInterface) => action.payload.find((ui) => ui.id == userInterface.id) == undefined),
                ...action.payload
            ];
        },
        updateUserInterface(state, action: PayloadAction<UserInterface>) {
            state.unordered = state.unordered.map((ui) => (ui.id == action.payload.id ? action.payload : ui));
            if (state.userInterfaces) {
                state.userInterfaces.results = state.userInterfaces.results.map((ui) => (ui.id == action.payload.id ? action.payload : ui));
            }
            if (state.userInterfacesByAccount != null && state.userInterfacesByAccount[action.payload.account_id] != null) {
                state.userInterfacesByAccount[action.payload.account_id].results = state.userInterfacesByAccount[action.payload.account_id].results.map((ui) =>
                    ui.id == action.payload.id ? action.payload : ui
                );
            }
        },
        addUserInterface(state, action: PayloadAction<UserInterface>) {
            state.unordered.push(action.payload);
            if (state.userInterfaces) {
                state.userInterfaces.count++;
                state.userInterfaces.results = [action.payload].concat(state.userInterfaces.results);
            }
            if (state.userInterfacesByAccount != null && state.userInterfacesByAccount[action.payload.account_id] != null) {
                state.userInterfacesByAccount[action.payload.account_id].results = [action.payload].concat(
                    state.userInterfacesByAccount[action.payload.account_id].results
                );
                state.userInterfacesByAccount[action.payload.account_id].count++;
            }
        },
        removeUserInterface(state, action: PayloadAction<string>) {
            state.unordered = state.unordered.filter((ui) => ui.id != action.payload);
            if (state.userInterfaces) {
                state.userInterfaces.count--;
                state.userInterfaces.results = state.userInterfaces.results.filter((ui) => ui.id != action.payload);
            }
            if (state.userInterfacesByAccount != null) {
                Object.keys(state.userInterfacesByAccount).forEach((key) => {
                    state.userInterfacesByAccount![key].results = state.userInterfacesByAccount![key].results.filter((ui) => ui.id != action.payload);
                });
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserInterfaces.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchUserInterfaces.fulfilled, (state, action) => {
                const startPos = toNumber(action.meta.arg.size) * toNumber(action.meta.arg.index);
                if (state.userInterfaces == null) {
                    state.userInterfaces = {
                        ...action.payload,
                        results: new Array(action.payload.count)
                    };
                    state.userInterfaces.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.userInterfaces.results.length !== action.payload.count) {
                        state.userInterfaces.count = action.payload.count;
                        state.userInterfaces.results = new Array(action.payload.count);
                    }
                    state.userInterfaces.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.unordered = [
                    ...state.unordered.filter((account) => action.payload.results.find((a) => a.id == account.id) == undefined),
                    ...action.payload.results
                ];
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchAccountUserInterfaces.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchAccountUserInterfaces.fulfilled, (state, action) => {
                const startPos = toNumber(action.meta.arg.params?.size) * toNumber(action.meta.arg.params?.index);
                const accountId = action.meta.arg.accountId;
                if (state.userInterfacesByAccount == null) {
                    state.userInterfacesByAccount = { [accountId]: { ...action.payload, results: new Array(action.payload.count) } };
                    state.userInterfacesByAccount[accountId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else if (state.userInterfacesByAccount) {
                    if (state.userInterfacesByAccount[accountId] == undefined) {
                        state.userInterfacesByAccount[accountId] = { ...action.payload, results: new Array(action.payload.count) };
                        state.userInterfacesByAccount[accountId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    } else {
                        if (state.userInterfacesByAccount[accountId].results.length != action.payload.count) {
                            state.userInterfacesByAccount[accountId].count = action.payload.count;
                            state.userInterfacesByAccount[accountId].results = new Array(action.payload.count);
                        }
                        state.userInterfacesByAccount[accountId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    }
                }
                state.unordered = [
                    ...state.unordered.filter((account) => action.payload.results.find((a) => a.id == account.id) == undefined),
                    ...action.payload.results
                ];
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchAccountUserInterface.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchAccountUserInterface.fulfilled, (state, action) => {
                if (state.userInterfaces) {
                    const found = state.userInterfaces.results.find((a) => a.id == action.meta.arg.userInterfaceId);
                    if (found) {
                        state.userInterfaces.results.splice(state.userInterfaces.results.indexOf(found), 1, action.payload);
                    }
                }
                state.status = SliceStatus.IDLE;
                state.unordered = [...state.unordered.filter((acc) => action.payload.id !== acc.id), action.payload];
            });
    }
});

export const fetchUserInterfaces = createAsyncThunk<UserInterfaceListResponse, ApiQueryParams<DefaultQueryParams>>(
    'fetchUserInterfaces',
    async (queryParams: ApiQueryParams<DefaultQueryParams>, { rejectWithValue }) => {
        try {
            return await UserInterfaceClient.fetchUserInterfaces(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchAccountUserInterfaces = createAsyncThunk<UserInterfaceListResponse, { accountId: string; params: ApiQueryParams<DefaultQueryParams> }>(
    'fetchAccountUserInterfaces',
    async (variables: { accountId: string; params: ApiQueryParams<DefaultQueryParams> }, { rejectWithValue }) => {
        try {
            return await UserInterfaceClient.fetchAccountUserInterfaces(variables.accountId, variables.params);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchAccountUserInterface = createAsyncThunk<UserInterface, { accountId: string; userInterfaceId: string }>(
    'fetchAccountUserInterface',
    async (variables: { accountId: string; userInterfaceId: string }, { rejectWithValue }) => {
        try {
            return await UserInterfaceClient.fetchAccountUserInterface(variables.accountId, variables.userInterfaceId);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const userInterfaceStore = { userInterfaces: userInterfaceSlice.reducer };
export const { seedUserInterfaces, addUserInterface, removeUserInterface, updateUserInterface } = userInterfaceSlice.actions;
