import { CreateTransactionData, UserInterfaceImplementation } from '@frontend/user-interface/types';
import { WorkflowVariable } from '@frontend/workflow-variable/types';
import React from 'react';

import { UserInterfaceTemplateProps } from '../../../user-interface-template-props';
import useUnknownVariableForm from './unknown-variable-form.controller';

const ID = 'unknown-variable-form';
export interface UnknownVariableFormProps extends UserInterfaceTemplateProps {
    userInterface: UserInterfaceImplementation<CreateTransactionData>;
    callback: (variable: WorkflowVariable) => void;
    variable: WorkflowVariable;
}

const UnknownVariableForm = (props: UnknownVariableFormProps) => {
    const viewProps = useUnknownVariableForm(props);

    return <div id={ID}>{viewProps.input}</div>;
};

export default UnknownVariableForm;
