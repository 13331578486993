import { ModalFooter } from '@frontend/elements';
import { NotificationTemplate } from '@frontend/notification-service/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React, { lazy } from 'react';

import { ClassType } from '@frontend/common';
import { FaPlus } from 'react-icons/fa';
import useCopyNotificationTemplate from './copy-notification-template.controller';

const AccountSelect = lazy(() => import('@frontend/account/common').then((module) => ({ default: module.AccountSelect })));

export interface CopyNotificationTemplateProps {
    dispatch: ThunkDispatch<any, any, Action>;
    notificationTemplate: NotificationTemplate;
    onClose: () => void;
}

const CopyNotificationTemplate = (props: CopyNotificationTemplateProps) => {
    const viewProps = useCopyNotificationTemplate(props);
    return (
        <div>
            <form action=''>
                <div className='modal-header'>
                    <h4>Copy notification template</h4>
                </div>
                <div className='modal-body'>
                    <div className='d-flex flex-row'>
                        <AccountSelect
                            dispatch={props.dispatch}
                            submitted={viewProps.submitted}
                            value={viewProps.account}
                            onChange={(v) => viewProps.changeAccount(v)}
                            className='w-100 m-1'
                        />
                    </div>
                </div>
                <ModalFooter
                    justifyContent='between'
                    buttons={[
                        {
                            text: 'Cancel',
                            onClick: props.onClose
                        },
                        {
                            text: 'Submit',
                            onClick: viewProps.onSubmit,
                            disabled: !viewProps.account,
                            buttonType: 'submit',
                            icon: FaPlus,
                            type: ClassType.SUCCESS
                        }
                    ]}
                />
            </form>
        </div>
    );
};

export default CopyNotificationTemplate;
