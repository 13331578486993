import { ClassType } from '@frontend/common';
import { ModalFooter } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import { FaPlus } from 'react-icons/fa';

import { TransactionVariable } from '../../transaction-variables';
import VariableInput from '../transaction-variable-input/transaction-input.component';
import useUpdateTransactionVariable from './update-transaction-variable.controller';

const ID = 'update-transaction-variable-form';

export interface UpdateTransactionVariableProps {
    transactionVariable: TransactionVariable;
    onClose: () => void;
    dispatch: ThunkDispatch<any, any, AnyAction>;
}

const UpdateTransactionVariable = (props: UpdateTransactionVariableProps) => {
    const viewProps = useUpdateTransactionVariable(props);

    return (
        <div id={ID}>
            <form onSubmit={viewProps.onSubmit}>
                <div className='modal-header'>
                    <h4>Update Transaction Variable</h4>
                </div>
                <div className='modal-body'>
                    <VariableInput
                        accountId={props.transactionVariable.account_id}
                        variable={{ ...props.transactionVariable, value: viewProps.formValues.value }}
                        dispatch={props.dispatch}
                        submitted={viewProps.submitted}
                        onChange={(v) => viewProps.changeFormValues({ value: v.value! })}
                    />
                </div>
                <ModalFooter
                    justifyContent='between'
                    buttons={[
                        {
                            hide: false,
                            text: CommonMessage.BUTTONS.CANCEL,
                            type: ClassType.SECONDARY,
                            onClick: (e) => {
                                e?.preventDefault();
                                props.onClose();
                            }
                        },
                        {
                            hide: false,
                            text: CommonMessage.BUTTONS.SUBMIT,
                            type: ClassType.SUCCESS,
                            icon: FaPlus,
                            disabled: Object.values(viewProps.formValid).some((v) => !v),
                            buttonType: 'submit'
                        }
                    ]}
                />
            </form>
        </div>
    );
};

export default UpdateTransactionVariable;
