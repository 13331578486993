import { Logger } from '@frontend/Logger';
import { addDevice, removeDevice, updateDevice } from '@frontend/device/redux';
import { Device } from '@frontend/device/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';


enum MessageTypes {
    UPDATED = 'device_updated',
    DELETED = 'device_deleted',
    CREATED = 'device_created'
}

export class DeviceEventHandler {
    #dispatch: ThunkDispatch<any, any, Action>;

    constructor(dispatch: ThunkDispatch<any, any, Action>) {
        this.#dispatch = dispatch;
    }

    public onMessageEvent(event: MessageEvent<string>): void {
        const payload = JSON.parse(event.data) as { message: MessageTypes; data: Device; changes?: any };
        switch (payload.message) {
            case MessageTypes.UPDATED:
                this.onUpdate(payload);
                break;
            case MessageTypes.DELETED:
                this.onDelete(payload);
                break;
            case MessageTypes.CREATED:
                this.onCreate(payload);
                break;
        }
    }

    private onUpdate(payload: { message: MessageTypes; data: Device; changes?: any }) {
        Logger.log('Updating device in store', {}, payload.data);
        this.#dispatch(updateDevice(payload.data));
    }

    private onDelete(payload: { message: MessageTypes; data: Device }) {
        Logger.log('Removing device from store', {}, payload.data);
        this.#dispatch(removeDevice(payload.data.id));
    }

    private onCreate(payload: { message: MessageTypes; data: Device; changes?: any }) {
        Logger.log('Adding device to store', {}, payload.data);
        this.#dispatch(addDevice(payload.data));
    }
}
