import { WarehouseProductPickDropData } from '@frontend/user-interface/types';
import { useEffect, useState } from 'react';
import { WarehouseProductPickDropDataFormProps } from './warehouse-product-pick-drop-data-form.component';

interface ViewProps {
    formValues: WarehouseProductPickDropData;
    changeFormValues: React.Dispatch<React.SetStateAction<WarehouseProductPickDropData>>;
}

/**
 * TODO once implemented search for the correct WF's that correspond to the selects (with tags)
 */
const useWarehouseProductPickDropDataForm = (props: WarehouseProductPickDropDataFormProps): ViewProps => {
    const [formValues, changeFormValues] = useState<WarehouseProductPickDropData>({
        transactionWorkflowId: '',
        pickPackageWorkflowId: ''
    });

    useEffect(() => {
        props.onChange(formValues);
    }, [formValues]);

    return {
        formValues,
        changeFormValues
    };
};

export default useWarehouseProductPickDropDataForm;
