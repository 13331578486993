import { WorkflowType } from '@frontend/workflow/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import useReturnProductDataForm from './return-product-data-form.controller';

const WorkflowSelect = React.lazy(() => import('@frontend/workflow/common').then((module) => ({ default: module.WorkflowSelect })));

const ID = 'return-product-data-form';
export interface ReturnProductDataFormProps {
    accountId: string;
    onChange: (value: any) => void;
    submitted: boolean;
    dispatch: ThunkDispatch<any, any, Action>;
}

const ReturnProductDataForm = (props: ReturnProductDataFormProps) => {
    const viewProps = useReturnProductDataForm(props);

    return (
        <div id={ID}>
            <WorkflowSelect
                required
                label='Transaction workflow'
                submitted={props.submitted}
                onChange={(value) => value && viewProps.changeFormValues({ ...viewProps.formValues, transactionWorkflowId: value })}
                value={viewProps.formValues.transactionWorkflowId}
                dispatch={props.dispatch}
                queryParams={{type: WorkflowType.TRANSACTION, account_id: props.accountId}}
            />
            <WorkflowSelect
                required
                label='Package workflow'
                submitted={props.submitted}
                onChange={(value) => value && viewProps.changeFormValues({ ...viewProps.formValues, packageWorkflowId: value })}
                value={viewProps.formValues.packageWorkflowId}
                dispatch={props.dispatch}
                queryParams={{type: WorkflowType.PACKAGE, account_id: props.accountId}}
            />
        </div>
    );
};

export default ReturnProductDataForm;
