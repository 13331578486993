import { mapArrayToOptions, useAppSelector } from '@frontend/common';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';


import { UserInterfaceClient } from '@frontend/user-interface/api';
import { fetchUserInterfaces, userInterfaceStore } from '@frontend/user-interface/redux';
import { UserInterfaceSelectProps } from './user-interface-select.component';

interface ViewProps {
    value?: { value: string; label: string } | null;
    options: { value: string; label: string }[];
    onScrollToBottom: () => void;
    search: (inputValue: string, callback: (options: { value: any; label: string }[]) => void) => void;

    showCreateModal: boolean;
    changeShowCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const pageSize = '100';
const useUserInterfaceSelect = (props: UserInterfaceSelectProps): ViewProps => {
    const timer = useRef<NodeJS.Timeout | null>(null);
    const [showCreateModal, changeShowCreateModal] = useState<boolean>(false);
    const [count, changeCount] = useState<number>(0);
    const [index, changeIndex] = useState<number>(0);
    const userInterfacesState = useAppSelector(useSelector, userInterfaceStore);
    const [options, changeOptions] = useState<{ value: string; label: string }[]>([]);
    const value = useMemo(() => {
        if (typeof props.value == 'string') return options.find((o) => o && o.value == props.value);
        return props.value;
    }, [props.value, options]);

    useEffect(() => {
        props.dispatch(fetchUserInterfaces({ index: index.toString(), size: pageSize }));
    }, [index]);

    useEffect(() => {
        if (userInterfacesState.userInterfaces) {
            changeCount(userInterfacesState.userInterfaces.count);
            changeOptions(mapArrayToOptions(userInterfacesState.userInterfaces.results, 'id', ['name', 'id']));
        }
    }, [userInterfacesState.userInterfaces]);

    const search = (inputValue: string, callback: (options: { value: any; label: string }[]) => void) => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
            UserInterfaceClient.fetchUserInterfaces({ search: inputValue, index: '0', size: pageSize }).then((result) => {
                callback(mapArrayToOptions(result.results, 'id', ['name', 'id']));
            });
        }, 500);
    };

    const onScrollToBottom = () => {
        if (count / parseInt(pageSize) > 1 && parseInt(pageSize) * index < count) {
            changeIndex(index + 1);
        }
    };

    return {
        options,
        value,
        onScrollToBottom,
        search,
        showCreateModal,
        changeShowCreateModal
    };
};

export default useUserInterfaceSelect;
