import React from 'react';

const ID = 'checkbox-input';
export interface CheckboxInputProps {
    id?: string;
    onChange?: (value: boolean) => void;
    onFocusChange?: () => void;
    value?: boolean;
    label: React.ReactNode;
    autoFocus?: boolean;
    disabled?: boolean;
    submitted: boolean;
    useConditionedStyling?: boolean;
    className?: string;
}

export const CheckboxInput = (props: CheckboxInputProps) => {
    return (
        <div
            id={ID}
            className={'form-check ' + props.className}>
            <input
                className='form-check-input'
                autoFocus={props.autoFocus}
                id={props.id}
                checked={props.value}
                type='checkbox'
                onFocus={() => {
                    props.onFocusChange && props.onFocusChange();
                }}
                onChange={(event) => {
                    if (props.onChange) props.onChange(event.target.checked);
                }}
                disabled={props.disabled}
            />
            <label
                className='custom-control-label'
                htmlFor={props.id}>
                {props.label}
            </label>
        </div>
    );
};
