import { ClassType } from '@frontend/common';
import { ConfirmationModal, DefaultModal, Filter, HorizontalButtonGroup, ModalType, Spinner } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';
import { Table } from '@frontend/table';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';

import { Contact } from '@frontend/contact/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import CreateContactForm from '../forms/create-contact-form/create-contact-form.component';
import ContactListElement from './contact-list-element';
import useContactList from './contact-list.controller';

const ID = 'contact-list';
export interface ContactListProps {
    dispatch: ThunkDispatch<any, any, Action>;
}

export const ContactList = (props: ContactListProps) => {
    const viewProps = useContactList(props);

    if (viewProps.isLoading) return <Spinner type={ClassType.LIGHT} />;

    return (
        <div id={ID}>
            <div className='card mt-3'>
                <div className='card-header d-flex justify-content-between align-items-center'>
                    <HorizontalButtonGroup
                        buttons={[
                            {
                                hide: false,
                                onClick: () => viewProps.changeShowCreateContactModal(true),
                                icon: FaPlus,
                                type: ClassType.PRIMARY,
                                id: 'createTransactionButton',
                                text: (
                                    <FormattedMessage
                                        id='ContactList.Create.Contact'
                                        description='Text to create a contact'
                                        defaultMessage='Create Contact'
                                    />
                                )
                            },
                            {
                                hide: viewProps.selectedContacts.length === 0,
                                onClick: () => viewProps.changeShowDeleteModal(true),
                                icon: FaTrash,
                                text: CommonMessage.BUTTONS.DELETE,
                                type: ClassType.DANGER,
                                id: 'deleteContactsButton'
                            }
                        ]}
                        direction='left'
                    />
                    <Filter filterValue={viewProps.changeSearchValue} />
                </div>
                <div className='card-body'>
                    <Table
                        id={ID + '-table'}
                        columns={viewProps.columns}
                        data={viewProps.data}
                        RowElement={ContactListElement}
                        page={(value) => viewProps.setPage(value + 1)}
                        pageSize={(value) => viewProps.setPageSize(value)}
                        pageCount={viewProps.pageCount}
                        selectEnabled
                        selectedItemsCallback={(v: Contact[]) => viewProps.changeSelectedContacts(v.map((a) => a.id))}
                        sorting={viewProps.changeSortState}
                        dispatch={props.dispatch}
                        sortingColumns={['name', 'alias', 'email', 'mobile']}
                    />
                </div>
            </div>

            <DefaultModal
                show={viewProps.showCreateContactModal}
                handleClose={() => viewProps.changeShowCreateContactModal(false)}
                type={ModalType.PROMPT}>
                <CreateContactForm
                    onClose={() => viewProps.changeShowCreateContactModal(false)}
                    dispatch={props.dispatch}
                />
            </DefaultModal>

            <ConfirmationModal
                show={viewProps.showDeleteModal}
                handleClose={() => viewProps.changeShowDeleteModal(false)}
                message={`You are about to delete ${viewProps.selectedContacts.length} contacts`}
                severity={ClassType.DANGER}
                onConfirm={viewProps.removeContacts}
            />
        </div>
    );
};
