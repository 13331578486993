import { PubSubConnection } from '@frontend/pub-sub';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

import { TransactionEventHandler } from './transaction-event-handler';

export class TransactionServiceEventListener extends PubSubConnection {
    private static instance: TransactionServiceEventListener | null = null;
    #transactionEventHandler: TransactionEventHandler;

    private constructor(dispatch: ThunkDispatch<any, any, Action>) {
        super('transaction');
        this.#transactionEventHandler = new TransactionEventHandler(dispatch);
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>): TransactionServiceEventListener {
        if (this.instance == null) {
            this.instance = new TransactionServiceEventListener(dispatch);
        }
        return this.instance;
    }

    protected override onMessageEvent(event: MessageEvent<string>): void {
        this.#transactionEventHandler.onMessageEvent(event);
    }
}
