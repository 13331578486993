import { AuthenticationManager } from '@frontend/authentication-v2';
import { useAppSelector } from '@frontend/common';
import { fetchEntitySettings, settingStore } from '@frontend/setting/redux';
import { SettingEntityType } from '@frontend/setting/types';
import { UserClient } from '@frontend/user/api';
import { User } from '@frontend/user/types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { navigationStore } from '../redux/user-interface-navigation-slice';
import { DefaultTitleProps } from './default-title.component';

interface ViewProps {
    user: User | null;
    spotTitle: string | null;
}

const useDefaultTitle = (props: DefaultTitleProps): ViewProps => {
    const navigationState = useAppSelector(useSelector, navigationStore);
    const settingState = useAppSelector(useSelector, settingStore);
    const [currentEntityId, changeCurrentEntityId] = useState<string | null>(null);
    const [user, changeUser] = useState<User | null>(null);
    const [spotTitle, changeSpotTitle] = useState<string | null>(null);

    useEffect(() => {
        AuthenticationManager.getInstance()
            .waitForToken()
            .then((token) => {
                changeCurrentEntityId(token.entity_id);
                props.dispatch(fetchEntitySettings({ entityType: SettingEntityType.DRIVER, entityId: token.entity_id }));
            });
    }, []);

    useEffect(() => {
        if (
            currentEntityId &&
            settingState.orderedSettings &&
            settingState.orderedSettings[SettingEntityType.DRIVER] &&
            settingState.orderedSettings[SettingEntityType.DRIVER] &&
            settingState.orderedSettings[SettingEntityType.DRIVER][currentEntityId]
        ) {
            const found = settingState.orderedSettings[SettingEntityType.DRIVER][currentEntityId].results.find((s) => s.key.toLowerCase() === 'name');
            changeSpotTitle(found ? (found.value as string) : null);
        }
    }, [settingState.orderedSettings, currentEntityId]);

    useEffect(() => {
        if (navigationState.user) UserClient.fetchUser(navigationState.user.accountId, navigationState.user.id).then(changeUser);
    }, [navigationState.user]);

    return {
        user,
        spotTitle
    };
};

export default useDefaultTitle;
