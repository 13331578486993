import { PaginatedResponse } from "@frontend/api-utils";
import { UserInterfaceButtonType } from "./user-interface-button-type";

export interface UserInterfaceButton {
    id: string;
    next_user_interface_id: string | null;
    action: UserInterfaceButtonAction;
    name: string;
    order: number;
    type: UserInterfaceButtonType;
    account_id: string;
    default: boolean;
    user_interface_id: string;
    creation_timestamp: string;
}

export type UserInterfaceButtonListResponse = PaginatedResponse<UserInterfaceButton>;

export interface CreateUserInterfaceButton {
    next_user_interface_id: string | null;
    name: string;
    order?: number;
    action: UserInterfaceButtonAction;
    type: UserInterfaceButtonType;
    default?: boolean;
}

export interface UpdateUserInterfaceButton {
    next_user_interface_id: string | null;
    name: string | null;
    order?: number | null;
    type: UserInterfaceButtonType | null;
    default?: boolean;
}
export enum UserInterfaceButtonAction {
    DISPLAY = 'display',
    NAVIGATION = 'navigation',
    TRIGGER = 'trigger'
}