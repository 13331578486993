
interface ViewProps {
    options: { value: string; label: React.ReactNode }[];
}

const useUserInterfacePlatformSelect = (): ViewProps => {
    return {
        options: [
            { value: 'terminal', label: 'Terminal' },
            { value: 'mobile', label: 'Mobile' },
        ],
    };
};

export default useUserInterfacePlatformSelect;