import { Logger } from '@frontend/Logger';

import { AuthenticationProvider } from './authentication-provider';
import DriverAuthenticationClient from './driver-authentication-client';
import { DriverProvision, Token } from './models';

export class DriverAuthenticationManager extends AuthenticationProvider {
    private static instance: DriverAuthenticationManager | null;
    public provisionInfo?: DriverProvision;
    public userLoggedIn: boolean;

    private constructor(location?: string) {
        super(location ?? 'driver_token');
        this.userLoggedIn = false;
    }

    public static getInstance = (location?: string): DriverAuthenticationManager => {
        if (!DriverAuthenticationManager.instance) {
            DriverAuthenticationManager.instance = new DriverAuthenticationManager(location);
        }
        return DriverAuthenticationManager.instance;
    };

    async authenticate(provision: DriverProvision, accountId?: string, userId?: string): Promise<Token> {
        this.provisionInfo = provision;
        let credentials: Token;
        if (userId && accountId) credentials = await DriverAuthenticationClient.authorizeUser(provision, accountId, userId);
        else credentials = await DriverAuthenticationClient.provision(provision);

        if (credentials.context != null) this.userLoggedIn = true;
        super.saveTokenToStorage(credentials);
        Logger.log(credentials);
        this.loggedIn = true;
        return credentials;
    }

    override logout(callback?: (() => any) | undefined): void {
        this.userLoggedIn = false;
        super.logout(callback);
    }
}
