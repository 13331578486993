import { CommonMessage } from '@frontend/lang';
import { Column } from 'react-table';

const notificationTemplateColumns: Column<any>[] = [
    {
        Header: 'name',
        accessor: 'name'
    },
    {
        Header: 'account',
        accessor: 'account_id'
    },
    {
        Header: 'description',
        accessor: 'description'
    },
    {
        Header: CommonMessage.OBJECTS.DEFAULT.CREATION_TIMESTAMP,
        accessor: 'creation_timestamp'
    },
    {
        Header: '',
        accessor: 'id'
    }
];

export default notificationTemplateColumns;
