import { TextInput } from '@frontend/basic-forms';
import { PinCode } from '@frontend/package-pin/types';
import React from 'react';

import useChallengeForm from './challenge-form.controller';

const ID = 'challenge-form';
export interface ChallengeFormProps {
    pinCodes: PinCode[];
    callback: (codes: PinCode[]) => void;
}

const ChallengeForm = (props: ChallengeFormProps) => {
    const viewProps = useChallengeForm(props);

    return (
        <form
            id={ID}
            onSubmit={viewProps.onSubmit}
            className='d-flex flex-column h-100 p-5'>
            {viewProps.error && <p className='text-center text-danger display-6 mt-4'>Invalid challenge provided.</p>}

            <TextInput
                label='Enter pin code challenge'
                submitted={viewProps.submitted}
                required
                autoFocus
                value={viewProps.value}
                onChange={viewProps.changeValue}
            />
            <button
                className='btn btn-success'
                disabled={viewProps.submitted}
                type='submit'>
                Submit
            </button>
        </form>
    );
};

export default ChallengeForm;
