import { useCurrentEditor } from '@tiptap/react';
import { FaBold, FaImage, FaItalic, FaUnderline } from 'react-icons/fa';

import useMenuBar from './menu-bar.controller';
import React from 'react';

const MenuBar = () => {
    const { editor } = useCurrentEditor();
    const viewProps = useMenuBar({ editor: editor });
    if (!editor) return null;

    return (
        <>
            <button
                onClick={() => editor.chain().focus().toggleBold().run()}
                disabled={!editor.can().chain().focus().toggleBold().run()}
                className={`btn bg-gradient-${editor.isActive('bold') ? 'light' : 'primary'} me-1`}>
                <FaBold />
            </button>
            <button
                onClick={() => editor.chain().focus().toggleItalic().run()}
                disabled={!editor.can().chain().focus().toggleItalic().run()}
                className={`btn bg-gradient-${editor.isActive('italic') ? 'light' : 'primary'} me-1`}>
                <FaItalic />
            </button>
            <button
                onClick={() => editor.chain().focus().toggleUnderline().run()}
                disabled={!editor.can().chain().focus().toggleUnderline().run()}
                className={`btn bg-gradient-${editor.isActive('underline') ? 'light' : 'primary'} me-1`}>
                <FaUnderline />
            </button>
            <button
                onClick={() => viewProps.getUrl()}
                className='btn bg-gradient-primary me-1'>
                <FaImage />
            </button>
        </>
    );
};

export default MenuBar;
