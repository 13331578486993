import { AsyncSelectInputSingle } from '@frontend/basic-forms';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

import { DefaultModal, ModalType } from '@frontend/elements';
import { CreateUserInterfaceForm } from '../create-user-interface-form/create-user-interface-form.component';
import useUserInterfaceByAccountSelect from './user-interface-by-account-select.controller';

const ID = 'user-interface-by-account-select';
export interface UserInterfaceByAccountSelectProps {
    id?: string;
    label?: string;
    helpMessage?: string;
    required?: boolean;
    submitted: boolean;
    onChange: (newValue?: string) => void;
    value?: string;
    className?: string;
    disabled?: boolean;
    useConditionedStyling?: boolean;
    isClearable?: boolean;
    dispatch: ThunkDispatch<any, any, Action>;

    accountId?: string;
    createEnabled?: boolean;
}

export const UserInterfaceByAccountSelect = (props: UserInterfaceByAccountSelectProps) => {
    const viewProps = useUserInterfaceByAccountSelect(props);

    return (
        <>
            <AsyncSelectInputSingle
                id={props.id || ID}
                label={props.label || 'User interface'}
                helpMessage={props.helpMessage}
                className={props.className}
                submitted={props.submitted}
                required={props.required}
                options={viewProps.options}
                onChange={(newValue) => props.onChange(newValue?.value)}
                value={viewProps.value?.value}
                isDisabled={!props.accountId || props.disabled}
                onMenuScrollToBottom={viewProps.onScrollToBottom}
                loadOptions={viewProps.search}
                isClearable={props.isClearable}
                useConditionedStyling={props.useConditionedStyling}
                onCreate={props.createEnabled ? () => viewProps.changeShowCreateModal(true) : undefined}
            />
            <DefaultModal
                show={viewProps.showCreateModal}
                handleClose={() => viewProps.changeShowCreateModal(false)}
                type={ModalType.PROMPT}>
                <CreateUserInterfaceForm
                    onClose={() => viewProps.changeShowCreateModal(false)}
                    dispatch={props.dispatch}
                    callback={(contact) => props.onChange(contact.id)}
                />
            </DefaultModal>
        </>
    );
};
