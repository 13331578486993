import {
    FloatingFocusManager,
    FloatingList,
    FloatingNode,
    FloatingPortal,
    FloatingTree,
    autoUpdate,
    flip,
    offset,
    safePolygon,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useFloatingNodeId,
    useFloatingParentNodeId,
    useFloatingTree,
    useHover,
    useInteractions,
    useListItem,
    useListNavigation,
    useMergeRefs,
    useRole,
    useTypeahead
} from '@floating-ui/react';
import { AuthorizationWrapper, Permission } from '@frontend/authorization';
import { ClassType } from '@frontend/common';
import React from 'react';
import { IconType } from 'react-icons';
import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5';

export const MenuContext = React.createContext<{
    getItemProps: (userProps?: React.HTMLProps<HTMLElement>) => Record<string, unknown>;
    activeIndex: number | null;
    setActiveIndex: React.Dispatch<React.SetStateAction<number | null>>;
    setHasFocusInside: React.Dispatch<React.SetStateAction<boolean>>;
    isOpen: boolean;
}>({
    getItemProps: () => ({}),
    activeIndex: null,
    setActiveIndex: () => {},
    setHasFocusInside: () => {},
    isOpen: false
});

interface MenuProps {
    label?: React.ReactNode;
    classType?: ClassType;
    nested?: boolean;
    children?: React.ReactNode;
    icon?: IconType;
    requiredPermissions?: Permission[];
    className?: string;
}

export const MenuComponent = React.forwardRef<HTMLButtonElement, MenuProps & React.HTMLProps<HTMLButtonElement>>(function menuComponent(
    { children, label, ...props },
    forwardedRef
) {
    const [isOpen, setIsOpen] = React.useState(false);
    const [hasFocusInside, setHasFocusInside] = React.useState(false);
    const [activeIndex, setActiveIndex] = React.useState<number | null>(null);

    const elementsRef = React.useRef<Array<HTMLButtonElement | null>>([]);
    const labelsRef = React.useRef<Array<string | null>>([]);
    const parent = React.useContext(MenuContext);

    const tree = useFloatingTree();
    const nodeId = useFloatingNodeId();
    const parentId = useFloatingParentNodeId();
    const item = useListItem();

    const isNested = parentId != null;

    const { floatingStyles, refs, context } = useFloating<HTMLButtonElement>({
        nodeId,
        open: isOpen,
        onOpenChange: setIsOpen,
        placement: isNested ? 'right-start' : 'bottom-start',
        middleware: [offset({ mainAxis: isNested ? 0 : 4, alignmentAxis: isNested ? -4 : 0 }), flip(), shift()],
        whileElementsMounted: autoUpdate
    });

    const hover = useHover(context, {
        enabled: isNested,
        delay: { open: 75 },
        handleClose: safePolygon({ blockPointerEvents: true })
    });
    const click = useClick(context, {
        event: 'mousedown',
        toggle: !isNested,
        ignoreMouse: isNested
    });
    const role = useRole(context, { role: 'menu' });
    const dismiss = useDismiss(context, { bubbles: true });
    const listNavigation = useListNavigation(context, {
        listRef: elementsRef,
        activeIndex,
        nested: isNested,
        onNavigate: setActiveIndex
    });
    const typeahead = useTypeahead(context, {
        listRef: labelsRef,
        onMatch: isOpen ? setActiveIndex : undefined,
        activeIndex
    });

    const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([hover, click, role, dismiss, listNavigation, typeahead]);

    // Event emitter allows you to communicate across tree components.
    // This effect closes all menus when an item gets clicked anywhere
    // in the tree.
    React.useEffect(() => {
        if (!tree) return;

        function handleTreeClick() {
            setIsOpen(false);
        }

        function onSubMenuOpen(event: { nodeId: string; parentId: string }) {
            if (event.nodeId !== nodeId && event.parentId === parentId) {
                setIsOpen(false);
            }
        }

        tree.events.on('click', handleTreeClick);
        tree.events.on('menuopen', onSubMenuOpen);

        return () => {
            tree.events.off('click', handleTreeClick);
            tree.events.off('menuopen', onSubMenuOpen);
        };
    }, [tree, nodeId, parentId]);

    React.useEffect(() => {
        if (isOpen && tree) {
            tree.events.emit('menuopen', { parentId, nodeId });
        }
    }, [tree, isOpen, nodeId, parentId]);

    return (
        <FloatingNode id={nodeId}>
            <button
                ref={useMergeRefs([refs.setReference, item.ref, forwardedRef])}
                tabIndex={!isNested ? undefined : parent.activeIndex === item.index ? 0 : -1}
                role={isNested ? 'menuitem' : undefined}
                data-open={isOpen ? '' : undefined}
                data-nested={isNested ? '' : undefined}
                data-focus-inside={hasFocusInside ? '' : undefined}
                className={`${
                    isNested
                        ? 'dropdown-item d-flex align-items-center m-0 p-0'
                        : `btn btn-${props.classType || 'primary'} ${props.className ? props.className : 'ps-3 pe-2'}`
                }`}
                {...getReferenceProps(
                    parent.getItemProps({
                        ...props,
                        onFocus(event: React.FocusEvent<HTMLButtonElement>) {
                            // eslint-disable-next-line react/prop-types
                            props.onFocus?.(event);
                            setHasFocusInside(false);
                            parent.setHasFocusInside(true);
                        }
                    })
                )}>
                {isNested ? (
                    <span
                        className={`d-flex flex-row align-items-center justify-content-start px-3 py-1 w-100 ${
                            parent.activeIndex === item.index && ' bg-light'
                        }`}>
                        <span className='btn-inner--icon'>{props.icon && <props.icon className={`text-${props.classType || 'primary'}`} />}</span>
                        <span>{label}</span>
                        <span
                            aria-hidden
                            style={{ marginLeft: 10, fontSize: 10 }}>
                            &#x25B6;
                        </span>
                    </span>
                ) : (
                    <span className='d-flex flex-row align-items-center justify-content-start'>
                        {props.icon && (
                            <span className='btn-inner--icon me-2'>
                                <props.icon />
                            </span>
                        )}
                        {label}
                        {isOpen ? <IoChevronDownOutline className='ms-2' /> : <IoChevronUpOutline className='ms-2' />}
                    </span>
                )}
            </button>
            <MenuContext.Provider
                value={{
                    activeIndex,
                    setActiveIndex,
                    getItemProps,
                    setHasFocusInside,
                    isOpen
                }}>
                <FloatingList
                    elementsRef={elementsRef}
                    labelsRef={labelsRef}>
                    {isOpen && (
                        <FloatingPortal>
                            <FloatingFocusManager
                                context={context}
                                modal={false}
                                initialFocus={isNested ? -1 : 0}
                                returnFocus={!isNested}>
                                <div
                                    ref={refs.setFloating}
                                    className='d-flex flex-column bg-white border shadow-lg rounded z-index-1051'
                                    style={floatingStyles}
                                    {...getFloatingProps()}>
                                    {children}
                                </div>
                            </FloatingFocusManager>
                        </FloatingPortal>
                    )}
                </FloatingList>
            </MenuContext.Provider>
        </FloatingNode>
    );
});

export const Dropdown = React.forwardRef<HTMLButtonElement, MenuProps & React.HTMLProps<HTMLButtonElement>>(function menu(props, ref) {
    const parentId = useFloatingParentNodeId();

    if (props.requiredPermissions) {
        if (parentId === null) {
            return (
                <AuthorizationWrapper requiredPermissions={props.requiredPermissions}>
                    <FloatingTree>
                        <MenuComponent
                            {...props}
                            ref={ref}
                        />
                    </FloatingTree>
                </AuthorizationWrapper>
            );
        } else {
            return (
                <AuthorizationWrapper requiredPermissions={props.requiredPermissions}>
                    <MenuComponent
                        {...props}
                        ref={ref}
                    />
                </AuthorizationWrapper>
            );
        }
    }

    if (parentId === null) {
        return (
            <FloatingTree>
                <MenuComponent
                    {...props}
                    ref={ref}
                />
            </FloatingTree>
        );
    }

    return (
        <MenuComponent
            {...props}
            ref={ref}
        />
    );
});
