import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { CreateDevice, Device, DeviceListResponse, DeviceQueryParams, DriverHealthCheck, UpdateDevice } from '@frontend/edge/types';

const endpoint = '/edge-api/v1';

export class DeviceClient extends APIClient {
    public static async fetchDevices(queryParams?: ApiQueryParams<DefaultQueryParams | DeviceQueryParams>): Promise<DeviceListResponse> {
        return await this.apiPaginated<DeviceListResponse, DefaultQueryParams | DeviceQueryParams>(`${endpoint}/devices`, queryParams);
    }

    public static async resolveDevices(deviceIds: string[]): Promise<DeviceListResponse> {
        const response = await this.post(`${endpoint}/devices-resolve`, { ids: deviceIds });
        return await this.handleResponse<DeviceListResponse>(response);
    }

    public static async deleteDevices(deviceIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/devices-delete`, { ids: deviceIds });
        return await this.handleVoidResponse(response);
    }

    public static async postDevice(driverId: string, device: CreateDevice): Promise<Device> {
        const response = await this.post(`${endpoint}/drivers/${driverId}/devices`, device);
        return await this.handleResponse<Device>(response);
    }

    public static async fetchDevice(driverId: string, deviceId: string): Promise<Device> {
        const response = await this.fetch(`${endpoint}/drivers/${driverId}/devices/${deviceId}`);
        return await this.handleResponse<Device>(response);
    }

    public static async updateDevice(driverId: string, deviceId: string, device: UpdateDevice): Promise<Device> {
        const response = await this.patch(`${endpoint}/drivers/${driverId}/devices/${deviceId}`, device);
        return await this.handleResponse<Device>(response);
    }

    public static async deleteDevice(driverId: string, deviceId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/drivers/${driverId}/devices/${deviceId}`);
        return await this.handleVoidResponse(response);
    }

    public static async getDeviceHealth(driverId: string): Promise<DriverHealthCheck> {
        const response = await this.fetch(`${endpoint}/drivers/${driverId}/health-check`);
        return await this.handleResponse<DriverHealthCheck>(response);
    }
}
