import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { ProductCategoryClient } from '@frontend/product-category/api';
import { ProductCategory, ProductCategoryListResponse, ProductCategoryQueryParams } from '@frontend/product-category/types';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

interface ProductCategoryState {
    unordered: ProductCategory[];
    productCategories: ProductCategoryListResponse | null;
    categoryTree: ProductCategory[];
    productCategoriesByAccount: { [accountId: string]: ProductCategoryListResponse } | null;
    status: SliceStatus;
}

const initialState: ProductCategoryState = {
    unordered: [],
    productCategories: null,
    categoryTree: [],
    productCategoriesByAccount: null,
    status: SliceStatus.IDLE
};

const productCategorySlice = createSlice({
    name: 'productCategorySlice',
    initialState,
    reducers: {
        seedProductCategories(state, action: PayloadAction<ProductCategory[]>) {
            state.unordered = [...state.unordered.filter((category) => action.payload.find((u) => u.id == category.id) == undefined), ...action.payload];
        },
        udpateProductCategory(state, action: PayloadAction<ProductCategory>) {
            state.unordered = state.unordered.map((category) => (category.id == action.payload.id ? action.payload : category));
            if (state.productCategories) {
                state.productCategories.results = state.productCategories.results.map((category) =>
                    category.id == action.payload.id ? action.payload : category
                );
            }
        },
        addProductCategory(state, action: PayloadAction<ProductCategory>) {
            state.unordered.push(action.payload);
            if (state.productCategories) {
                state.productCategories.count++;
                state.productCategories.results = [action.payload].concat(state.productCategories.results);
            }
        },
        removeProductCategory(state, action: PayloadAction<string>) {
            state.unordered = state.unordered.filter((category) => category.id != action.payload);
            if (state.productCategories) {
                state.productCategories.count--;
                state.productCategories.results = state.productCategories.results.filter((category) => category.id != action.payload);
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProductCategories.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchProductCategories.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                const startPos = toNumber(action.meta.arg.size) * toNumber(action.meta.arg.index);
                state.unordered = [
                    ...state.unordered.filter((category) => action.payload.results.find((c) => c.id == category.id) == undefined),
                    ...action.payload.results
                ];

                if (state.productCategories == null) {
                    state.productCategories = {
                        ...action.payload,
                        results: new Array(action.payload.count)
                    };
                    state.productCategories.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.productCategories.results.length !== action.payload.count) {
                        state.productCategories.count = action.payload.count;
                        state.productCategories.results = new Array(action.payload.count);
                    }
                    state.productCategories.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
            })
            .addCase(fetchCategoryTree.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchCategoryTree.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                state.categoryTree = action.payload;
            });
    }
});

export const fetchProductCategories = createAsyncThunk<ProductCategoryListResponse, ApiQueryParams<DefaultQueryParams | ProductCategoryQueryParams>>(
    'fetchProductCategories',
    async (queryParams: ApiQueryParams<DefaultQueryParams | ProductCategoryQueryParams>, { rejectWithValue }) => {
        try {
            return await ProductCategoryClient.fetchProductCategories(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchCategoryTree = createAsyncThunk<ProductCategory[], ApiQueryParams<DefaultQueryParams | ProductCategoryQueryParams>>(
    'fetchCategoryTree',
    async (queryParams: ApiQueryParams<DefaultQueryParams | ProductCategoryQueryParams>, { rejectWithValue }) => {
        try {
            return await ProductCategoryClient.fetchCategoriesTree(queryParams);
        } catch (e) {
            if ((e as ApiError).json) rejectWithValue(e);
            throw e;
        }
    }
);

export const productCategoryStore = { productCategories: productCategorySlice.reducer };
export const { seedProductCategories, addProductCategory, removeProductCategory, udpateProductCategory } = productCategorySlice.actions;
