import { useEffect, useState } from 'react';

import { SelectInputProps } from './select-input.component';

interface ViewProps {
    value: any;
    valid: boolean;
    touched: boolean;
    changeValue: React.Dispatch<any>;
    isValid: () => boolean;
    changeTouched: React.Dispatch<React.SetStateAction<boolean>>;
}

const useSelectInput = (props: SelectInputProps): ViewProps => {
    const [value, changeValue] = useState<any>();
    const [touched, changeTouched] = useState<boolean>(false);
    const [valid, changeValid] = useState<boolean>(props.valid !== undefined ? props.valid : true);

    const isValid = (): boolean => {
        let check = true;
        if ((props.value === undefined || props.value === null) && props.required === true) check = false;
        if (props.isMulti) {
            if (check === true && props.required === true && (props.value === undefined || props.value === null || props.value.length === 0)) check = false;
        } else {
            if (props.value && typeof props.value == 'string') {
                if (check === true && props.required === true && props.value.length === 0) check = false;
            } else if (props.value && (props.value.value || props.value.value === undefined || props.value.value.length === 0)) {
                if (
                    check === true &&
                    props.required === true &&
                    (props.value.value === undefined || props.value.value === null || props.value.value.length === 0)
                )
                    check = false;
            } else {
                if (check === true && props.required === true && (props.value === undefined || props.value === null || props.value.length === 0)) check = false;
            }
        }
        if (props.valid !== undefined && check === true) check = props.valid;
        changeValid(check);
        return check;
    };

    useEffect(() => {
        if (props.isValidCallback) {
            props.isValidCallback(isValid());
        } else isValid();
    }, [props.value, props.valid]);

    useEffect(() => {
        if (props.submitted) {
            !touched && changeTouched(true);
        }
    }, [props.submitted]);

    useEffect(() => {
        if (props.resetValue) {
            changeValue(null);
            changeTouched(false);
        }
    }, [props.resetValue]);

    useEffect(() => {
        findValue();
    }, [props.value]);

    const multiValues = props.isMulti === true && props.value !== undefined ? props.options?.filter((op: any) => props.value.includes(op.value)) : [];

    const findValue = () => {
        if (props.isMulti) {
            if (props.options?.every((op: any) => op.options)) {
                changeValue(props.options.flatMap((o: any) => o.options).filter((v) => props.value.includes(v.value)));
            } else changeValue(multiValues);
        } else if (props.value && props.value !== undefined && props.value !== null && props.options) {
            if (props.options?.every((op: any) => op.options)) {
                if (props.value.value) {
                    changeValue(props.options.flatMap((op: any) => op.options).find((v) => v.value == props.value.value));
                } else changeValue(props.options.flatMap((op: any) => op.options).find((v) => v.value == props.value));
            } else {
                if (props.value.value) {
                    changeValue(props.options.find((op: any) => op.value == props.value.value));
                } else changeValue(props.options.find((op: any) => op.value == props.value));
            }
        }
    };
    return {
        value,
        valid,
        touched,
        changeValue,
        isValid,
        changeTouched
    };
};

export default useSelectInput;
