import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';

import { CreateWorkflowVariable, UpdateWorkflowVariable, WorkflowVariable, WorkflowVariableListResponse } from '@frontend/workflow-variable/types';

const endpoint = '/workflow-api/v1';
export class WorkflowVariableClient extends APIClient {
    public static async fetchWorkflowVariables(queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<WorkflowVariableListResponse> {
        return await this.apiPaginated<WorkflowVariableListResponse, any>(`${endpoint}/workflow-variables`, queryParams);
    }

    public static async fetchAccountWorkflowVariables(
        accountId: string,
        workflowId: string,
        queryParams?: ApiQueryParams<DefaultQueryParams>
    ): Promise<WorkflowVariableListResponse> {
        return await this.apiPaginated(`${endpoint}/accounts/${accountId}/workflows/${workflowId}/variables`, queryParams);
    }

    public static async postAccountWorkflowVariable(
        workflowVariable: CreateWorkflowVariable,
        accountId: string,
        workflowId: string
    ): Promise<WorkflowVariable> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/workflows/${workflowId}/variables`, workflowVariable);
        return await this.handleResponse<WorkflowVariable>(response);
    }

    public static async fetchAccountWorkflowVariable(accountId: string, workflowId: string, workflowVariableId: string): Promise<WorkflowVariable> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/workflows/${workflowId}/variables/${workflowVariableId}`);
        return await this.handleResponse<WorkflowVariable>(response);
    }

    public static async patchAccountWorkflowVariable(
        workflowVariable: UpdateWorkflowVariable,
        accountId: string,
        workflowId: string,
        workflowVariableId: string
    ): Promise<WorkflowVariable> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/workflows/${workflowId}/variables/${workflowVariableId}`, workflowVariable);
        return await this.handleResponse<WorkflowVariable>(response);
    }

    public static async deleteAccountWorkflowVariable(accountId: string, workflowId: string, workflowVariableId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/workflows/${workflowId}/variables/${workflowVariableId}`);
        return await this.handleVoidResponse(response);
    }

    public static async fetchUnknownAccountWorkflowVariables(
        accountId: string,
        workflowId: string,
        queryParams?: ApiQueryParams<DefaultQueryParams>
    ): Promise<WorkflowVariableListResponse> {
        return await this.apiPaginated(`${endpoint}/accounts/${accountId}/workflows/${workflowId}/unknown-variables`, queryParams);
    }
}
