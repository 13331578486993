import React from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

import useNavbarGroupItemController from './navbar-group-item.controller';

export interface NavbarGroupItemProps {
    title: string;
    link: string;
    children: React.ReactNode;
    navbarRef: React.RefObject<HTMLDivElement>;
}

export const NavbarGroupItem = (props: NavbarGroupItemProps) => {
    const viewProps = useNavbarGroupItemController(props);

    return (
        <>
            <li
                className={`nav-item cursor-pointer`}
                ref={viewProps.navbarGroupItemRef}>
                <div
                    className='nav-link'
                    onClick={viewProps.handleTitleClick}>
                    <div className='icon icon-sm d-flex align-items-center justify-content-center'>
                        <i className='text-primary text-sm opacity-10'>{viewProps.openIndex != null ? <FaChevronDown /> : <FaChevronUp />}</i>
                    </div>
                    <span className={`nav-link-text m-2`}>{props.title}</span>
                </div>
            </li>
            {viewProps.openIndex !== null && (
                <div
                    className='d-flex navbar-group-items flex-column'
                    ref={viewProps.navbarItemRef}>
                    {props.children}
                </div>
            )}
        </>
    );
};
