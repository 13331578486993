import { EntityType, useAppSelector } from '@frontend/common';
import { iconByType } from '@frontend/icons';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { resolveStore } from '../resolve/resolve-slice';

export function useBadgeRepository<T>(type: EntityType, mapDisplayName: (entity: T) => string | undefined, resolve: (id: string) => void, id?: string) {
    const navigate = useNavigate();
    const location = useLocation();
    const resolveState = useAppSelector(useSelector, resolveStore);
    const [object, changeObject] = useState<T | null>(null);
    const [requested, changeRequested] = useState<boolean>(false);
    const [disabled, changeDisabled] = useState<boolean>(true);
    const [displayName, changeDisplayName] = useState<string | undefined>();

    useEffect(() => {
        if (id != undefined) {
            const found = resolveState.resolved ? (resolveState.resolved[type] ? resolveState.resolved[type][id] : null) : null;
            if (found) {
                changeDisplayName(mapDisplayName(found as T));
                changeObject(found as T);
                changeDisabled(false);
            } else if (requested == false) {
                changeRequested(true);
                resolve(id);
            }

            if (requested && !found) {
                changeDisabled(true);
                changeDisplayName('Not found');
            }
        } else {
            changeDisabled(true);
            changeDisplayName('None');
        }
    }, [resolveState, id]);

    const onClick = (e: any) => {
        if (disabled) return;
        e.stopPropagation();
        const urlValue = type + '-' + id;
        const queryParams = new URLSearchParams(location.search);
        const detail = queryParams.get('details');
        if (detail) {
            queryParams.set('details', JSON.stringify([...(JSON.parse(detail) as string[]), urlValue]));
        } else {
            queryParams.set('details', JSON.stringify([urlValue]));
        }

        navigate({
            pathname: location.pathname,
            search: queryParams.toString()
        });
    };

    return {
        displayName,
        disabled,
        object,
        onClick,
        icon: iconByType(type)
    };
}
