import React from "react";
import { FormattedMessage } from "react-intl";

export enum DeviceStateName {
    DEVICE_CREATED = 'device_created',
    DEVICE_OPENING = 'device_opening',
    DEVICE_UNLOCKED = 'device_unlocked',
    DEVICE_OPENED = 'device_opened',
    DEVICE_CLOSED = 'device_closed'
}

export class DeviceState {
    static readonly DEVICE_CREATED = new DeviceState(
        DeviceStateName.DEVICE_CREATED,
        (
            <FormattedMessage
                id='DeviceState.created'
                description='The displayed value for the DeviceState created'
                defaultMessage='Device created'
            />
        )
    );

    static readonly DEVICE_OPENING = new DeviceState(
        DeviceStateName.DEVICE_OPENING,
        (
            <FormattedMessage
                id='DeviceState.opening'
                description='The displayed value for the DeviceState opening'
                defaultMessage='Device opening'
            />
        )
    );

    static readonly DEVICE_UNLOCKED = new DeviceState(
        DeviceStateName.DEVICE_UNLOCKED,
        (
            <FormattedMessage
                id='DeviceState.unlocked'
                description='The displayed value for the DeviceState unlocked'
                defaultMessage='Device unlocked'
            />
        )
    );

    static readonly DEVICE_OPENED = new DeviceState(
        DeviceStateName.DEVICE_OPENED,
        (
            <FormattedMessage
                id='DeviceState.opened'
                description='The displayed value for the DeviceState opened'
                defaultMessage='Device opened'
            />
        )
    );

    static readonly DEVICE_CLOSED = new DeviceState(
        DeviceStateName.DEVICE_CLOSED,
        (
            <FormattedMessage
                id='DeviceState.closed'
                description='The displayed value for the DeviceState closed'
                defaultMessage='Device closed'
            />
        )
    );

    static readonly ALL = [DeviceState.DEVICE_CREATED, DeviceState.DEVICE_OPENING, DeviceState.DEVICE_UNLOCKED, DeviceState.DEVICE_OPENED, DeviceState.DEVICE_CLOSED];

    private constructor(public readonly value: DeviceStateName, public readonly displayName: React.ReactNode) {}

    static getByValue(value: DeviceStateName): DeviceState | undefined {
        return DeviceState.ALL.find(state => state.value === value);
    }
}