/* eslint-disable react/prop-types */
import { useMergeRefs } from '@floating-ui/react';
import { AuthorizationWrapper, Permission } from '@frontend/authorization';
import { ClassType } from '@frontend/common';
import React from 'react';
import { IconType } from 'react-icons';

import useTableActionItem from './table-action-item.controller';

const ID = 'table-action-item-item';
export interface TableActionItemProps {
    label: React.ReactNode;
    disabled?: boolean;
    classType?: ClassType;
    icon?: IconType;
    requiredPermissions?: Permission[];
}

export const TableActionItem = React.forwardRef<HTMLButtonElement, TableActionItemProps & React.ButtonHTMLAttributes<HTMLButtonElement>>(function item(
    { label, disabled, ...props },
    forwardedRef
) {
    const viewProps = useTableActionItem();

    if (props.requiredPermissions) {
        return (
            <AuthorizationWrapper requiredPermissions={props.requiredPermissions}>
                <button
                    {...props}
                    ref={useMergeRefs([viewProps.item.ref, forwardedRef])}
                    id={ID}
                    type='button'
                    role='menuitem'
                    className='dropdown-item d-flex align-items-center'
                    tabIndex={viewProps.isActive ? 0 : -1}
                    disabled={disabled}
                    {...viewProps.menu.getItemProps({
                        onClick(event: React.MouseEvent<HTMLButtonElement>) {
                            props.onClick?.(event);
                            viewProps.tree?.events.emit('click');
                        },
                        onFocus(event: React.FocusEvent<HTMLButtonElement>) {
                            props.onFocus?.(event);
                            viewProps.menu.setHasFocusInside(true);
                        }
                    })}>
                    <span className={`d-flex flex-row align-items-center justify-content-start px-3 py-1 w-100 ${viewProps.isActive && ` bg-light`}`}>
                        <span className='btn-inner--icon'>{props.icon && <props.icon className={`text-${props.classType || 'primary'} me-2`} />}</span>
                        <span>{label}</span>
                    </span>
                </button>
            </AuthorizationWrapper>
        );
    }

    return (
        <button
            {...props}
            ref={useMergeRefs([viewProps.item.ref, forwardedRef])}
            id={ID}
            type='button'
            role='menuitem'
            className='dropdown-item d-flex align-items-center'
            tabIndex={viewProps.isActive ? 0 : -1}
            disabled={disabled}
            {...viewProps.menu.getItemProps({
                onClick(event: React.MouseEvent<HTMLButtonElement>) {
                    props.onClick?.(event);
                    viewProps.tree?.events.emit('click');
                },
                onFocus(event: React.FocusEvent<HTMLButtonElement>) {
                    props.onFocus?.(event);
                    viewProps.menu.setHasFocusInside(true);
                }
            })}>
            <span className={`d-flex flex-row align-items-center justify-content-start px-3 py-1 w-100 ${viewProps.isActive && ` bg-light`}`}>
                <span className='btn-inner--icon'>{props.icon && <props.icon className={`text-${props.classType || 'primary'} me-2`} />}</span>
                <span>{label}</span>
            </span>
        </button>
    );
});

export default TableActionItem;
