import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Column } from 'react-table';

const ContactListColumns: Column<any>[] = [
    {
        Header: 'Name',
        accessor: (o) => o.first_name + ' ' + o.last_name,
        id: 'first_name'
    },
    {
        Header: 'Alias',
        accessor: 'alias'
    },
    {
        Header: 'Email',
        accessor: 'email'
    },
    {
        Header: 'Mobile',
        accessor: 'mobile'
    },
    {
        Header: (
            <FormattedMessage
                id='ContactList.Table.Title.CreationTimestamp'
                description='Table title for creation date'
                defaultMessage='Created date'
            />
        ),
        accessor: 'creation_timestamp'
    },
    {
        Header: '',
        accessor: 'id'
    }
];

export default ContactListColumns;
