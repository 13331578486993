import { NotificationContent } from '@frontend/notification-service/types';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { NotificationContentClient } from '@frontend/notification-service/api';
import { fetchAccountTemplateNotificationContent, notificationContentStore } from '@frontend/notification-service/redux';
import { useAppSelector } from '@frontend/common';
import { useSelector } from 'react-redux';
import { NotificationContentDetailProps } from './notification-content-detail.component';

interface ViewProps {
    notificationContent: NotificationContent | undefined;
    placeHolders: any | undefined;
    placeHolderDrag: (event: React.DragEvent) => void;
    subject: string | undefined;
    changeSubject: (value: React.SetStateAction<string | undefined>) => void;
    deleteModal: boolean;
    changeDeleteModal: (value: React.SetStateAction<boolean>) => void;
    removeNotificationContent: () => void;
}

const useNotificationContentDetail = (props: NotificationContentDetailProps): ViewProps => {
    const { accountId, templateId, contentId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const notificationContentState = useAppSelector(useSelector, notificationContentStore)
    const [notificationContent, changeNotificationContent] = useState<NotificationContent | undefined>(undefined);
    const [placeHolders, changePlaceholders] = useState<any | undefined>(undefined);
    const idsInUrl = accountId && templateId && contentId;
    const [subject, changeSubject] = useState<string | undefined>(notificationContent?.subject);
    const [deleteModal, changeDeleteModal] = useState<boolean>(false);
    useEffect(() => {
        NotificationContentClient.fetchPlaceholders()
            .then((res) => changePlaceholders(res))
            .catch((err) => {});
        if (idsInUrl) {
            const found = notificationContentState.templateContents?.[accountId]?.[templateId]?.results.find((t) => t.id === contentId);
            if (found === undefined) props.dispatch(fetchAccountTemplateNotificationContent({ accountId: accountId, templateId: templateId, contentId: contentId }));
            else changeNotificationContent(found);
        }
    }, [notificationContentState]);

    useEffect(() => {
        if (notificationContent) {
            changeSubject(notificationContent.subject);
        }
    }, [notificationContent]);

    function placeHolderDrag(event: React.DragEvent) {
        const eventTarget = event.target as any;
        const target = eventTarget.nodeType === 1 ? eventTarget : eventTarget.parentElement;
        const draggable = target.closest('[draggable]');

        const found = Object.values(placeHolders).find((s: any) => s.entity_label === draggable.innerText);
        const key = Object.keys(placeHolders).find((k: any) => placeHolders[k] === found);
        event.dataTransfer?.setData('text/plain', `{{${key}}}`);
        event.dataTransfer?.setData('text/html', `{{${key}}}`);
    }

    function removeNotificationContent() {
        if (!accountId || !templateId || !contentId) return;
        NotificationContentClient.deleteAccountTemplateNotificationContent(accountId, templateId, contentId).then(() => {
            const newPath = location.pathname.substring(0, location.pathname.indexOf('/content'));
            navigate(newPath);
        });
    }

    return {
        notificationContent,
        placeHolders,
        placeHolderDrag,
        changeSubject,
        subject,
        changeDeleteModal,
        deleteModal,
        removeNotificationContent
    };
};

export default useNotificationContentDetail;
