import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { AccessLogClient } from '@frontend/user/api';
import { AccessLog, AccessLogListResponse, AccessLogQueryParams } from '@frontend/user/types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isArray, toNumber } from 'lodash';

interface AccessLogState {
    unordered: AccessLog[];
    accessLogs: AccessLogListResponse | null;
    accessLogsBySpot: { [spotId: string]: AccessLogListResponse } | null;
    status: SliceStatus;
}

const initialState: AccessLogState = {
    unordered: [],
    accessLogs: null,
    accessLogsBySpot: null,
    status: SliceStatus.INIT
};

const accessLogSlice = createSlice({
    name: 'accessLogSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAccessLogs.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchAccessLogs.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                const startPos = toNumber(action.meta.arg.size) * toNumber(action.meta.arg.index);
                const spotId = action.meta.arg.spot_id;
                if (spotId && !isArray(spotId)) {
                    if (state.accessLogsBySpot == null) {
                        state.accessLogsBySpot = { [spotId]: { ...action.payload, results: new Array(action.payload.count) } };
                        state.accessLogsBySpot[spotId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    } else if (state.accessLogsBySpot && !state.accessLogsBySpot[spotId]) {
                        state.accessLogsBySpot[spotId] = { ...action.payload, results: new Array(action.payload.count) };
                        state.accessLogsBySpot[spotId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    } else {
                        if (state.accessLogsBySpot[spotId].results.length !== action.payload.count) {
                            state.accessLogsBySpot[spotId].count = action.payload.count;
                            state.accessLogsBySpot[spotId].results = new Array(action.payload.count);
                        }
                        state.accessLogsBySpot[spotId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    }
                }
                if (state.accessLogs == null) {
                    state.accessLogs = {
                        ...action.payload,
                        results: new Array(action.payload.count)
                    };
                    state.accessLogs.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.accessLogs.results.length !== action.payload.count) {
                        state.accessLogs.count = action.payload.count;
                        state.accessLogs.results = new Array(action.payload.count);
                    }
                    state.accessLogs.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }

                state.unordered = [
                    ...state.unordered.filter((accessLog) => action.payload.results.find((a) => a.id == accessLog.id) == undefined),
                    ...action.payload.results
                ];
            });
    }
});

export const fetchAccessLogs = createAsyncThunk<AccessLogListResponse, ApiQueryParams<DefaultQueryParams | AccessLogQueryParams>>(
    'fetchAccessLogs',
    async (queryParams: ApiQueryParams<DefaultQueryParams | AccessLogQueryParams>, { rejectWithValue }) => {
        try {
            return await AccessLogClient.fetchAccessLogs(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const accessLogStore = { accessLogs: accessLogSlice.reducer };
