import { Logger } from '@frontend/Logger';
import { PubSubConnection } from '@frontend/pub-sub';
import { addUserInterface, removeUserInterface, updateUserInterface } from '@frontend/user-interface/redux';
import { UserInterface } from '@frontend/user-interface/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

enum MessageTypes {
    UPDATED = 'user-interface_updated',
    DELETED = 'user-interface_deleted',
    CREATED = 'user-interface_created'
}

export class UserInterfaceEventListener extends PubSubConnection {
    private static instance: UserInterfaceEventListener | null = null;
    #dispatch: ThunkDispatch<any, any, Action>;
    private constructor(dispatch: ThunkDispatch<any, any, Action>) {
        super('user-interface');
        this.#dispatch = dispatch;
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>): UserInterfaceEventListener {
        if (this.instance == null) {
            this.instance = new UserInterfaceEventListener(dispatch);
        }
        return this.instance;
    }

    protected override onMessageEvent(event: MessageEvent<string>): void {
        const payload = JSON.parse(event.data) as { message: MessageTypes; data: UserInterface; changes?: any };
        switch (payload.message) {
            case MessageTypes.UPDATED:
                this.onUpdate(payload);
                break;
            case MessageTypes.DELETED:
                this.onDelete(payload);
                break;
            case MessageTypes.CREATED:
                this.onCreate(payload);
                break;
        }
    }

    private onUpdate(payload: { message: MessageTypes; data: UserInterface; changes?: any }) {
        Logger.log('Updating user-interface in store', {}, payload.data);
        this.#dispatch(updateUserInterface(payload.data));
    }

    private onDelete(payload: { message: MessageTypes; data: UserInterface }) {
        Logger.log('Removing user-interface from store', {}, payload.data);
        this.#dispatch(removeUserInterface(payload.data.id));
    }

    private onCreate(payload: { message: MessageTypes; data: UserInterface; changes?: any }) {
        Logger.log('Adding user-interface to store', {}, payload.data);
        this.#dispatch(addUserInterface(payload.data));
    }
}
