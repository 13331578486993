import { WorkflowTrigger, WorkflowTriggerName } from "@frontend/workflow/types";
import React from "react";
import { FormattedMessage } from "react-intl";

export enum UserTriggerName {
    USER_IDENTIFIED = "user_identified",
    USER_STATE_CHANGED = "user_state_changed",
    USER_VERIFIED = "user_verified"
}

export class UserTrigger extends WorkflowTrigger {
    static readonly USER_IDENTIFIED = new UserTrigger(
        UserTriggerName.USER_IDENTIFIED,
        (
            <FormattedMessage
                id='UserTrigger.user_identified'
                description='The displayed value for the UserTrigger user_identified'
                defaultMessage='User identified'
            />
        )
    );

    static readonly USER_STATE_CHANGED = new UserTrigger(
        UserTriggerName.USER_STATE_CHANGED,
        (
            <FormattedMessage
                id='UserTrigger.user_state_changed'
                description='The displayed value for the UserTrigger user_state_changed'
                defaultMessage='User state changed'
            />
        )
    );

    static readonly USER_VERIFIED = new UserTrigger(
        UserTriggerName.USER_VERIFIED,
        (
            <FormattedMessage
                id='UserTrigger.user_verified'
                description='The displayed value for the UserTrigger user_verified'
                defaultMessage='User verified'
            />
        )
    );

    static override readonly ALL = [
        UserTrigger.USER_IDENTIFIED,
        UserTrigger.USER_STATE_CHANGED,
        UserTrigger.USER_VERIFIED,
        ...super.ALL
    ];

    static override getByValue(trigger: UserTriggerName | WorkflowTriggerName): WorkflowTrigger | undefined {
        return this.ALL.find((a) => a.value == trigger);
    }
}