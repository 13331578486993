import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { NotificationContentClient } from '@frontend/notification-service/api';
import {
    CreateNotificationContent,
    NotificationContent,
    NotificationContentListResponse,
    UpdateNotificationContent
} from '@frontend/notification-service/types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

interface NotificationContentState {
    contents: NotificationContentListResponse | null;
    templateContents: { [accountId: string]: { [templateId: string]: NotificationContentListResponse } };
    status: SliceStatus;
}

const initialState: NotificationContentState = {
    contents: null,
    templateContents: {},
    status: SliceStatus.INIT
};

const notificationContentSlice = createSlice({
    name: 'notificationContents',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchNotificationContents.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchNotificationContents.fulfilled, (state, action) => {
                const startPos = toNumber(action.meta.arg.size) * toNumber(action.meta.arg.index);
                if (state.contents == null) {
                    state.contents = { ...action.payload, results: new Array(action.payload.count) };
                    state.contents.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.contents.results.length !== action.payload.count) {
                        state.contents.count = action.payload.count;
                        state.contents.results = new Array(action.payload.count);
                    }
                    state.contents.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchAccountTemplateNotificationContents.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchAccountTemplateNotificationContents.fulfilled, (state, action) => {
                const startPos = toNumber(action.meta.arg.queryParams?.size) * toNumber(action.meta.arg.queryParams?.index);
                const accountId = action.meta.arg.accountId;
                const templateId = action.meta.arg.templateId;
                if (state.templateContents[accountId] !== undefined) {
                    if (state.templateContents[accountId][templateId] !== undefined) {
                        if (state.templateContents[accountId][templateId].results.length !== action.payload.count) {
                            state.templateContents[accountId][templateId].count = action.payload.count;
                            state.templateContents[accountId][templateId].results = new Array(action.payload.count);
                        }
                        state.templateContents[accountId][templateId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    } else {
                        state.templateContents[accountId][templateId] = { ...action.payload, results: new Array(action.payload.count) };
                        state.templateContents[accountId][templateId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    }
                } else {
                    state.templateContents[accountId] = { [templateId]: { ...action.payload, results: new Array(action.payload.count) } };
                    state.templateContents[accountId][templateId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchAccountTemplateNotificationContent.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchAccountTemplateNotificationContent.fulfilled, (state, action) => {
                const { accountId, templateId, contentId } = action.meta.arg;
                if (state.templateContents[accountId] == undefined) {
                    state.templateContents = { [accountId]: { [templateId]: { count: 1, results: [action.payload] } } };
                } else if (state.templateContents[accountId][templateId] == undefined) {
                    state.templateContents[accountId] = { [templateId]: { count: 1, results: [action.payload] } };
                } else {
                    const found = state.templateContents[accountId][templateId].results.find((c) => c.id == contentId);
                    if (found == undefined) state.templateContents[accountId][templateId].results.push(action.payload);
                    else
                        state.templateContents[accountId][templateId].results.splice(
                            state.templateContents[accountId][templateId].results.indexOf(found),
                            1,
                            action.payload
                        );
                }
            })
            .addCase(patchAccountTemplateNotificationContent.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(patchAccountTemplateNotificationContent.fulfilled, (state, action) => {
                const { accountId, templateId, contentId } = action.meta.arg;
                if (!state.templateContents[accountId] || !state.templateContents[accountId][templateId]) return;
                const found = state.templateContents[accountId][templateId].results.find((r) => r.id === contentId);
                if (!found) return;
                state.templateContents[accountId][templateId].results.splice(
                    state.templateContents[accountId][templateId].results.indexOf(found),
                    1,
                    action.payload
                );
                state.status = SliceStatus.IDLE;
            })
            .addCase(postAccountTemplateNotificationContent.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(postAccountTemplateNotificationContent.fulfilled, (state, action) => {
                const { accountId, templateId } = action.meta.arg;
                if (!state.templateContents[accountId] || !state.templateContents[accountId][templateId]) return;
                state.templateContents[accountId][templateId].count = state.templateContents[accountId][templateId].count + 1;
                state.templateContents[accountId][templateId].results.push(action.payload);
            });
    }
});

export const fetchNotificationContents = createAsyncThunk<NotificationContentListResponse, ApiQueryParams<DefaultQueryParams>>(
    'fetchNotificationContents',
    async (queryParams: ApiQueryParams<DefaultQueryParams>, { rejectWithValue }) => {
        try {
            return await NotificationContentClient.fetchNotificationContents(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchAccountTemplateNotificationContents = createAsyncThunk<
    NotificationContentListResponse,
    { accountId: string; templateId: string; queryParams?: ApiQueryParams<DefaultQueryParams> }
>(
    'fetchAccountTemplateNotificationContents',
    async (variables: { accountId: string; templateId: string; queryParams?: ApiQueryParams<DefaultQueryParams> }, { rejectWithValue }) => {
        try {
            return await NotificationContentClient.fetchAccountTemplateNotificationContents(variables.accountId, variables.templateId, variables.queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchAccountTemplateNotificationContent = createAsyncThunk<NotificationContent, { accountId: string; templateId: string; contentId: string }>(
    'fetchAccountTemplateNotificationContent',
    async (variables: { accountId: string; templateId: string; contentId: string }, { rejectWithValue }) => {
        try {
            return await NotificationContentClient.fetchAccountTemplateNotificationContent(variables.accountId, variables.templateId, variables.contentId);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const patchAccountTemplateNotificationContent = createAsyncThunk<
    NotificationContent,
    { accountId: string; templateId: string; contentId: string; body: UpdateNotificationContent }
>(
    'patchAccountTemplateNotificationContent',
    async (variables: { accountId: string; templateId: string; contentId: string; body: UpdateNotificationContent }, { rejectWithValue }) => {
        try {
            return await NotificationContentClient.patchAccountTemplateNotificationContent(
                variables.accountId,
                variables.templateId,
                variables.contentId,
                variables.body
            );
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const postAccountTemplateNotificationContent = createAsyncThunk<
    NotificationContent,
    { accountId: string; templateId: string; content: CreateNotificationContent }
>(
    'postAccountTemplateNotificationContent',
    async (variables: { accountId: string; templateId: string; content: CreateNotificationContent }, { rejectWithValue }) => {
        try {
            return await NotificationContentClient.postAccountTemplateNotificationContent(variables.accountId, variables.templateId, variables.content);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const notificationContentStore = { notificationContents: notificationContentSlice.reducer };
