import { useEffect, useMemo, useState } from 'react';


import { PackageStateName } from '@frontend/package/types';
import { ProductStateName } from '@frontend/product/types';
import { TransactionStateName } from '@frontend/transaction/types';
import { WorkflowStateClient } from '@frontend/workflow/api';
import { WorkflowPossibleStateSelectProps } from './workflow-possible-transaction-state-select.component';

interface ViewProps {
    options: { value: string; label: React.ReactNode }[];
    value:
        | {
              value: TransactionStateName;
              label: React.ReactNode;
          }
        | {
              value: string;
              label: string;
          }
        | undefined;
    isLoading: boolean;
}

const useWorkflowPossibleStateSelect = (props: WorkflowPossibleStateSelectProps): ViewProps => {
    const [possibleStates, changePossibleStates] = useState<TransactionStateName[] | ProductStateName[] | PackageStateName[] | null>(null);

    const options = useMemo(() => {
        if (!possibleStates) return [];

        return possibleStates.map((state) => {
            return { value: state, label: state };
        });
    }, [possibleStates]);
    const value = useMemo(() => {
        if (!options || !props.value) return undefined;
        else return options.find((op) => op.value == props.value);
    }, [props.value, options]);

    useEffect(() => {
        WorkflowStateClient.fetchAccountWorkflowPossibleStates(props.accountId, props.workflowId).then((res) => {
            changePossibleStates(res);
        });
    }, []);

    return { options, value, isLoading: possibleStates === null };
};

export default useWorkflowPossibleStateSelect;
