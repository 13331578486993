import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

import { DefaultModalProps, ModalType } from '../../../../modals/default-modal/default-modal.component';
import { LoadingModal } from '../../../../modals/loading-modal/loading-modal.component';
import BasicAuthDetailModal from '../basic-auth-detail-modal/basic-auth-detail-modal.component';
import useBasicAuthDetailModalWrapper from './basic-auth-detail-modal-wrapper.controller';

export interface BasicAuthDetailModalWrapperProps extends DefaultModalProps {
    id: string;
    dispatch: ThunkDispatch<any, any, AnyAction>;
}

export const BasicAuthDetailModalWrapper = (props: BasicAuthDetailModalWrapperProps): JSX.Element => {
    const viewProps = useBasicAuthDetailModalWrapper(props);

    if (!viewProps.basicAuth) {
        return (
            <LoadingModal
                type={ModalType.PANE}
                customWidth={40}
                {...props}
            />
        );
    }

    return (
        <BasicAuthDetailModal
            basicAuth={viewProps.basicAuth}
            customWidth={40}
            {...props}
        />
    );
};
