import { ClassType, EntityType, Rights } from '@frontend/common';
import { DefaultModal, HorizontalButtonGroup } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';
import { RowElement, Table } from '@frontend/table';
import { AccessLog } from '@frontend/user/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import { FaPlus } from 'react-icons/fa';

import { CreateAccessLogForm } from '../forms/create-access-log-form/create-access-log-form.component';
import useAccessLogList from './access-log-list.controller';

const ID = 'access-log-list';

export interface AccessLogListProps {
    dispatch: ThunkDispatch<any, any, Action>;
}

export const AccessLogList = (props: AccessLogListProps) => {
    const viewProps = useAccessLogList(props);
    return (
        <div id={ID}>
            <div className='card mt-3'>
                <div className='card-header'>
                    <HorizontalButtonGroup
                        direction='left'
                        buttons={[
                            {
                                text: CommonMessage.FORMS.CREATE_OBJECT('Access log'),
                                onClick: () => viewProps.changeShowCreateModal(true),
                                type: ClassType.PRIMARY,
                                icon: FaPlus,
                                requiredPermissions: [{ item: EntityType.ACCESS_LOG, source: EntityType.ACCESS_LOG, right: Rights.CREATE, uid: '?' }]
                            }
                        ]}
                    />
                </div>
                <div className='card-body'>
                    <Table
                        RowElement={RowElement<AccessLog>}
                        columns={viewProps.columns}
                        data={viewProps.data}
                    />
                </div>
            </div>
            {viewProps.showCreateModal && (
                <DefaultModal
                    handleClose={() => viewProps.changeShowCreateModal(false)}
                    show={viewProps.showCreateModal}>
                    <CreateAccessLogForm
                        dispatch={props.dispatch}
                        onClose={() => viewProps.changeShowCreateModal(false)}
                    />
                </DefaultModal>
            )}
        </div>
    );
};
