import { AuthorizationClient, fetchEntityRestrictions } from '@frontend/authorization';
import { ErrorHandler } from '@frontend/error-handler';
import { Dispatch, SetStateAction, useState } from 'react';

import { useAppDispatch } from '../../../redux/store';
import { RestrictionListElementProps } from './restriction-list-element.component';

interface ViewProps {
    showUpdateModal: boolean;
    showDeleteModal: boolean;
    changeShowDeleteModal: Dispatch<SetStateAction<boolean>>;
    changeShowUpdateModal: Dispatch<SetStateAction<boolean>>;
    onDelete: () => void;
}

const useRestrictionListElement = (props: RestrictionListElementProps): ViewProps => {
    const [showUpdateModal, changeShowUpdateModal] = useState<boolean>(false);
    const [showDeleteModal, changeShowDeleteModal] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const onDelete = () => {
        AuthorizationClient.deleteEntityRestriction(props.row.original.entity_type, props.row.original.entity_id, props.row.original.id)
            .then((res) => {
                dispatch(fetchEntityRestrictions({ type: props.row.original.entity_type, entityId: props.row.original.entity_id }));
                changeShowDeleteModal(false);
            })
            .catch(ErrorHandler.handleError);
    };

    return {
        changeShowDeleteModal,
        changeShowUpdateModal,
        onDelete,
        showDeleteModal,
        showUpdateModal
    };
};

export default useRestrictionListElement;
