import { useAppSelector } from '@frontend/common';
import { NotificationTemplateClient } from '@frontend/notification-service/api';
import { fetchNotificationTemplates, notificationTemplateStore } from '@frontend/notification-service/redux';
import { NotificationTemplate } from '@frontend/notification-service/types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { NotificationTemplateSelectProps } from './notification-template-select.component';

interface ViewProps {
    options: { value: string; label: string }[];
    value?: { value: string; label: string };
    onScrollToBottom: () => void;
    search: (inputValue: string, callback: (options: { value: any; label: string }[]) => void) => void;
}

const pageSize = '100';
const useNotificationTemplateSelect = (props: NotificationTemplateSelectProps): ViewProps => {
    const timer = useRef<NodeJS.Timeout | null>(null);
    const [count, setCount] = useState<number>(0);
    const [index, changeIndex] = useState<number>(0);
    const [options, changeOptions] = useState<{ value: any; label: string }[]>([]);
    const templatesState = useAppSelector(useSelector, notificationTemplateStore);
    const value = useMemo(() => {
        if (typeof props.value == 'string') return options.find((o) => o && o.value == props.value);
        return props.value;
    }, [props.value, options]);

    useEffect(() => {
        props.dispatch(fetchNotificationTemplates({ index: index.toString(), size: pageSize }));
    }, [index]);

    useEffect(() => {
        if (templatesState.templates) {
            setCount(templatesState.templates.count);
            changeOptions(templatesState.templates.results.map((t) => ({ value: t.id, label: t.name })));
        }
    }, [templatesState]);

    const search = (inputValue: string, callback: (options: { value: any; label: string }[]) => void) => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
            NotificationTemplateClient.fetchNotificationTemplates({ search: inputValue, index: '0', size: pageSize }).then((result) => {
                callback(mapResultSetToOptions(result.results));
            });
        }, 500);
    };

    const onScrollToBottom = () => {
        if (count / parseInt(pageSize) > 1) {
            changeIndex(index + 1);
        }
    };

    return {
        value,
        options,
        onScrollToBottom,
        search
    };
};

function mapResultSetToOptions(templates: NotificationTemplate[]): { value: any; label: string }[] {
    return templates.map((t) => ({ value: t.id, label: t.name }));
}

export default useNotificationTemplateSelect;
