import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { SeedClient } from '@frontend/sync/api';
import { Seed, SeedListResponse } from '@frontend/sync/types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

interface SeedState {
    unordered: Seed[];
    seedList: SeedListResponse | null;
    status: SliceStatus;
}

const initialState: SeedState = {
    unordered: [],
    seedList: null,
    status: SliceStatus.INIT
};

export const SeedSlice = createSlice({
    name: 'seed',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSeeds.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchSeeds.fulfilled, (state, action) => {
                const startPos = toNumber(action.meta.arg.index) * toNumber(action.meta.arg.size);
                state.status = SliceStatus.IDLE;
                if (state.seedList == null) {
                    state.seedList = { ...action.payload, results: new Array(action.payload.count) };
                    state.seedList.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.seedList.results.length != action.payload.count) {
                        state.seedList.count = action.payload.count;
                        state.seedList.results = new Array(action.payload.count);
                    }

                    state.seedList.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
            });
    }
});

export const fetchSeeds = createAsyncThunk<SeedListResponse, ApiQueryParams<DefaultQueryParams>>(
    'fetchSeeds',
    async (queryParams: ApiQueryParams<DefaultQueryParams>, { rejectWithValue }) => {
        try {
            return await SeedClient.fetchSeeds(queryParams);
        } catch (e) {
            if ((e as ApiError).json) rejectWithValue(e);
            throw e;
        }
    }
);

export const seedStore = { seeds: SeedSlice.reducer };
