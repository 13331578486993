import { TextArea, TextInput } from '@frontend/basic-forms';
import { ClassType } from '@frontend/common';
import { ModalFooter } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';
import { NotificationTemplate } from '@frontend/notification-service/types';
import { FaPlus } from 'react-icons/fa';

import React from 'react';
import useUpdateNotificationTemplate from './update-notification-template.controller';

const ID = 'update-notification-template-form';

export interface UpdateNotificationTemplateProps {
    onClose: () => void;
    notificationTemplate?: NotificationTemplate;
}

const UpdateNotificationTemplate = (props: UpdateNotificationTemplateProps) => {
    const viewProps = useUpdateNotificationTemplate(props);

    return (
        <div id={ID}>
            <form onSubmit={viewProps.onSubmit}>
                <div className='modal-header'>
                    <h4>{CommonMessage.FORMS.CREATE_OBJECT('Notification Template')}</h4>
                </div>
                <div className='modal-body'>
                    <div className='d-flex flex-row'>
                        <TextInput
                            required
                            id={ID + '-nameInput'}
                            className='w-50 m-1'
                            label={CommonMessage.FORMS.NAME}
                            value={viewProps.formValues.name}
                            onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, name: value })}
                            isValidCallback={(valid) => {
                                viewProps.changeFormValid({ ...viewProps.formValid, name: valid });
                            }}
                            submitted={viewProps.submitted}
                        />

                        <TextArea
                            required
                            id={ID + '-descriptionInput'}
                            className='w-50 m-1'
                            label={CommonMessage.OBJECTS.COMMON_FIELDS.DESCRIPTION}
                            value={viewProps.formValues.description}
                            onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, description: value })}
                            isValidCallback={(valid) => {
                                viewProps.changeFormValid({ ...viewProps.formValid, description: valid });
                            }}
                            submitted={viewProps.submitted}
                        />
                    </div>
                </div>
                <ModalFooter
                    justifyContent='between'
                    buttons={[
                        {
                            hide: false,
                            text: CommonMessage.BUTTONS.CANCEL,
                            type: ClassType.SECONDARY,
                            onClick: (e) => {
                                e?.preventDefault();
                                props.onClose();
                            }
                        },
                        {
                            hide: false,
                            text: CommonMessage.BUTTONS.SUBMIT,
                            type: ClassType.SUCCESS,
                            icon: FaPlus,
                            buttonType: 'submit'
                        }
                    ]}
                />
            </form>
        </div>
    );
};

export default UpdateNotificationTemplate;
