import { PaginatedResponse } from '@frontend/api-utils';

export interface Driver {
    id: string;
    identifier: string;
    name: string | null;
    version: string;
    data: DriverBootstrapData | DriverTerminalData | null;
    type: DriverType;
    brand: DriverBrand;
    update_timestamp: string;
    creation_timestamp: string;
}

export enum DriverType {
    BOOTSTRAP = 'bootstrap',
    TERMINAL = 'terminal',
    NFC_READER = 'nfc_reader',
    LOCKER = 'locker',
    QR_READER = 'qr_reader',
    SYNC = 'sync',
    MOBILE = 'mobile',
    ACCESS_CONTROLLER = 'access_controller'
}

export enum DriverBrand {
    ACS = 'acs',
    DATALOGIC = 'datalogic',
    HONEYWELL = 'honeywell',
    RAKINDA = 'rakinda',
    S4M = 's4m',
    TELLOPORT = 'telloport',
    ZHILAI = 'zhilai',
    UNIPAS = 'unipas'
}

export interface CreateDriver {
    name?: string | null;
    identifier: string;
    version: string;
    type: DriverType | null;
    brand: DriverBrand | null;
    data?: DriverBootstrapData | DriverTerminalData | null;
}

export interface UpdateDriver {
    name?: string | null;
    identifier?: string | null;
    version?: string | null;
    data?: DriverBootstrapData | DriverTerminalData | null;
}

export interface DriverProvision {
    identifier: string;
    type: DriverType;
    brand: DriverBrand;
    user_id?: string | null;
}

export interface DriverBootstrapData {
    domain: string;
}

export interface DriverTerminalData {
    user_interface_id: string | null;
}

export type DriverListResponse = PaginatedResponse<Driver>;

export enum DriverQueryParams {
    TYPE = 'type',
    BRAND = 'brand'
}

export interface DriverHealthCheck {
    healthy: boolean;
}
