import { PinCode } from '@frontend/package-pin/types';
import { PackageWorkflowClient } from '@frontend/package/api';
import { PackageTriggerName } from '@frontend/package/types';
import { useEffect, useState } from 'react';

interface ViewProps {
    state: PinWorkflowState;
    pinCodes: PinCode[];
    changePinCodes: React.Dispatch<React.SetStateAction<PinCode[]>>;
}

export enum PinWorkflowState {
    INIT = 'init',
    LOADING = 'loading',
    ENTER_PIN = 'enter_pin',
    ENTER_CHALLENGE = 'enter_challenge',
    DONE = 'done'
}
const useEnterPin = (): ViewProps => {
    const [pinCodes, changePinCodes] = useState<PinCode[]>([]);
    const [state, changeState] = useState<PinWorkflowState>(PinWorkflowState.INIT);

    useEffect(() => {
        changeState(PinWorkflowState.ENTER_PIN);
    }, []);

    useEffect(() => {
        if (pinCodes && pinCodes.length > 0) {
            const pin = pinCodes[0];
            if (pinCodes.length > 1) {
                changeState(PinWorkflowState.ENTER_CHALLENGE);
            } else {
                PackageWorkflowClient.triggerPackageState(pin.account_id, pin.transaction_id, pin.package_id, {
                    trigger: PackageTriggerName.PACKAGE_CODE_SCANNED,
                    source: null
                });
                changeState(PinWorkflowState.DONE);
            }
        }
    }, [pinCodes]);

    return {
        state,
        pinCodes,
        changePinCodes
    };
};

export default useEnterPin;
