import { APIClient, ApiQueryParams, DefaultQueryParams } from "@frontend/api-utils";
import { AccessLog, AccessLogListResponse, AccessLogQueryParams, CreateAccessLog } from "@frontend/user/types";

const endpoint = '/user-api/v1'

export class AccessLogClient extends APIClient {
    public static async fetchAccessLogs(queryParams?: ApiQueryParams<DefaultQueryParams | AccessLogQueryParams>): Promise<AccessLogListResponse> {
        return this.apiPaginated<AccessLogListResponse, DefaultQueryParams | AccessLogQueryParams>(`${endpoint}/access-logs`, queryParams);
    }

    public static async postSpotAccessLog(spotId: string, accessLog: CreateAccessLog): Promise<AccessLog> {
        const response = await this.post(`${endpoint}/spots/${spotId}/access-logs`, accessLog);
        return await this.handleResponse<AccessLog>(response);
    }

    public static async fetchSpotAccessLog(spotId: string, accessLogId: string): Promise<AccessLog> {
        const response = await this.fetch(`${endpoint}/spots/${spotId}/access-logs/${accessLogId}`);
        return await this.handleResponse<AccessLog>(response);
    }

    public static async deleteSpotAccessLog(spotId: string, accessLogId: string): Promise<void> { 
        const response = await this.delete(`${endpoint}/spots/${spotId}/access-logs/${accessLogId}`);
        return await this.handleVoidResponse(response);
    }
}