import { APIClient } from '@frontend/api-utils';
import { Product, ProductStateName, ProductStateTrigger } from '@frontend/product/types';

const endpoint = '/product-api/v1';

export class ProductWorkflowClient extends APIClient {
    public static async updateProductState(accountId: string, productId: string, productState: ProductStateName): Promise<Product> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/products/${productId}/change-state`, { state: productState });
        return await this.handleResponse<Product>(response);
    }

    public static async postTriggerProductState(accountId: string, productId: string, trigger: ProductStateTrigger): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/products/${productId}/trigger`, trigger);
        return await this.handleVoidResponse(response);
    }
}
