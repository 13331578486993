import { ActionButton, DateBadge, DropdownButtonGroup } from '@frontend/elements';
import { SpotBadge } from '@frontend/spot/common';
import { AccessLog, AccessLogType } from '@frontend/user/types';
import React, { lazy } from 'react';
import { ImEnter, ImExit } from 'react-icons/im';
import { CellProps, Column } from 'react-table';

import { UserBadge } from '../user-badge/user-badge.component';

const DriverBadge = lazy(() => import('@frontend/edge/common').then((module) => ({ default: module.DriverBadge })));

const AccessLogColumns: Column<AccessLog>[] = [
    {
        Header: 'User ID',
        accessor: 'user_id',
        Cell: (props: CellProps<AccessLog, string>) => {
            return (
                <div className='align-content-center'>
                    <UserBadge id={props.row.original.user_id} />
                </div>
            );
        }
    },
    {
        Header: 'User Type',
        accessor: 'user_type',
        Cell: (props: CellProps<AccessLog, string>) => {
            return <td className='text-secondary text-xs font-weight-bolder align-content-center'>{props.row.original.user_type}</td>;
        }
    },
    {
        Header: 'Access Type',
        accessor: 'access_type',
        Cell: (props: CellProps<AccessLog, string>) => {
            const icon =
                props.row.original.access_type === AccessLogType.ENTERED ? (
                    <ImEnter
                        className='text-success ms-1'
                        size={18}
                    />
                ) : (
                    <ImExit
                        className='text-danger ms-1'
                        size={18}
                    />
                );
            return (
                <td className='text-secondary text-xs font-weight-bolder align-content-center'>
                    {props.row.original.access_type}
                    {icon}
                </td>
            );
        }
    },

    {
        Header: 'Spot ID',
        accessor: 'spot_id',
        Cell: (props: CellProps<AccessLog, string>) => {
            return (
                <td className='align-content-center'>
                    <SpotBadge id={props.row.original.spot_id} />
                </td>
            );
        }
    },

    {
        Header: 'Driver ID',
        accessor: 'driver_id',
        Cell: (props: CellProps<AccessLog, string>) => {
            return (
                <div className='align-content-center'>
                    <DriverBadge id={props.row.original.driver_id} />
                </div>
            );
        }
    },
    {
        Header: 'Driver Type',
        accessor: 'driver_type',
        Cell: (props: CellProps<AccessLog, string>) => {
            return <td className='text-secondary text-xs font-weight-bolder align-content-center'>{props.row.original.driver_type}</td>;
        }
    },
    {
        Header: 'Creation Timestamp',
        accessor: 'creation_timestamp',
        Cell: (props: CellProps<AccessLog, string>) => {
            return (
                <td className='text-secondary text-xs font-weight-bolder align-content-center'>
                    <DateBadge
                        date={props.row.original.creation_timestamp}
                        inline
                        displayTime
                    />
                </td>
            );
        }
    },
    {
        Header: 'Update Timestamp',
        accessor: 'update_timestamp',
        Cell: (props: CellProps<AccessLog, string>) => {
            return (
                <td className='text-secondary text-xs font-weight-bolder align-content-center'>
                    <DateBadge
                        date={props.row.original.update_timestamp}
                        inline
                        displayTime
                    />
                </td>
            );
        }
    },
    {
        Header: '',
        id: 'id',
        Cell: (props: CellProps<AccessLog, string>) => {
            return (
                <td>
                    <ActionButton>
                        <DropdownButtonGroup showJSON={props.row} />
                    </ActionButton>
                </td>
            );
        }
    }
];

export default AccessLogColumns;
