import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

import { DefaultModalProps, ModalType } from '../../../modals/default-modal/default-modal.component';
import { LoadingModal } from '../../../modals/loading-modal/loading-modal.component';
import TerminalDetailModal from '../terminal-detail-modal/terminal-detail-modal.component';
import useTerminalDetailModalWrapper from './terminal-detail-modal-wrapper.controller';

export interface TerminalDetailModalWrapperProps extends DefaultModalProps {
    id: string;
    dispatch: ThunkDispatch<any, any, AnyAction>;
}

export const TerminalDetailModalWrapper = (props: TerminalDetailModalWrapperProps): JSX.Element => {
    const viewProps = useTerminalDetailModalWrapper(props);
    if (!viewProps.terminal) {
        return (
            <LoadingModal
                type={ModalType.PANE}
                customWidth={40}
                {...props}
            />
        );
    }
    return (
        <TerminalDetailModal
            terminal={viewProps.terminal}
            customWidth={40}
            {...props}
        />
    );
};
