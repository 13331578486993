import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { SettingClient } from '@frontend/setting/api';
import { Setting, SettingEntityType, SettingListResponse, SettingQueryParams } from '@frontend/setting/types';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

export interface SettingState {
    unordered: Setting[];
    settings: SettingListResponse | null;
    orderedSettings: { [entityType: string]: { [entityId: string]: SettingListResponse } } | null;
    status: SliceStatus;
}

const initialState: SettingState = {
    unordered: [],
    settings: null,
    orderedSettings: null,
    status: SliceStatus.INIT
};

const settingSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        seedSettings(state, action: PayloadAction<Setting[]>) {
            state.unordered = [...state.unordered.filter((setting) => action.payload.find((s) => s.id == setting.id) == undefined), ...action.payload];
        },
        updateSetting(state, action: PayloadAction<Setting>) {
            state.unordered = state.unordered.map((setting) => (setting.id == action.payload.id ? action.payload : setting));
            if (state.settings) {
                state.settings.results = state.settings.results.map((setting) => (setting.id == action.payload.id ? action.payload : setting));
            }
            if (state.orderedSettings) {
                const entityType = action.payload.entity_type;
                const entityId = action.payload.entity_id;
                if (state.orderedSettings[entityType] && state.orderedSettings[entityType][entityId]) {
                    state.orderedSettings[entityType][entityId].results = state.orderedSettings[entityType][entityId].results.map((setting) =>
                        setting.id == action.payload.id ? action.payload : setting
                    );
                }
            }
        },
        addSetting(state, action: PayloadAction<Setting>) {
            state.unordered.push(action.payload);
            if (state.settings) {
                state.settings.count++;
                state.settings.results = [action.payload].concat(state.settings.results);
            }
            if (state.orderedSettings) {
                const entityType = action.payload.entity_type;
                const entityId = action.payload.entity_id;
                if (state.orderedSettings[entityType] && state.orderedSettings[entityType][entityId]) {
                    state.orderedSettings[entityType][entityId].count++;
                    state.orderedSettings[entityType][entityId].results = [action.payload].concat(state.orderedSettings[entityType][entityId].results);
                } else if (state.orderedSettings[entityType] && !state.orderedSettings[entityType][entityId]) {
                    state.orderedSettings[entityType][entityId] = { count: 1, results: [action.payload] };
                } else {
                    state.orderedSettings[entityType] = { [entityId]: { count: 1, results: [action.payload] } };
                }
            } else {
                state.orderedSettings = {
                    [action.payload.entity_type]: {
                        [action.payload.entity_id]: { count: 1, results: [action.payload] }
                    }
                };
            }
        },
        removeSetting(state, action: PayloadAction<string>) {
            state.unordered = state.unordered.filter((setting) => setting.id != action.payload);
            if (state.settings) {
                state.settings.count--;
                state.settings.results = state.settings.results.filter((setting) => setting.id != action.payload);
            }
            if (state.orderedSettings) {
                for (const entityType in state.orderedSettings) {
                    for (const entityId in state.orderedSettings[entityType]) {
                        const found = state.orderedSettings[entityType][entityId].results.find((setting) => setting.id == action.payload);
                        state.orderedSettings[entityType][entityId].results = state.orderedSettings[entityType][entityId].results.filter(
                            (setting) => setting.id != action.payload
                        );
                        if (found) state.orderedSettings[entityType][entityId].count--;
                    }
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchEntitySettings.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchEntitySettings.fulfilled, (state, action) => {
                const startPos = toNumber(action.meta.arg.queryParams?.size ?? 20) * (toNumber(action.meta.arg.queryParams?.index ?? 1) - 1);
                if (state.orderedSettings == null) {
                    state.orderedSettings = {
                        [action.meta.arg.entityType]: {
                            [action.meta.arg.entityId]: { ...action.payload, results: new Array(action.payload.count) }
                        }
                    };
                    state.orderedSettings[action.meta.arg.entityType][action.meta.arg.entityId].results.splice(
                        startPos,
                        action.payload.results.length,
                        ...action.payload.results
                    );
                } else {
                    if (state.orderedSettings[action.meta.arg.entityType] == undefined) {
                        state.orderedSettings[action.meta.arg.entityType] = {};
                    }
                    if (state.orderedSettings[action.meta.arg.entityType][action.meta.arg.entityId] == undefined) {
                        state.orderedSettings[action.meta.arg.entityType][action.meta.arg.entityId] = {
                            ...action.payload,
                            results: new Array(action.payload.count)
                        };
                        state.orderedSettings[action.meta.arg.entityType][action.meta.arg.entityId].results.splice(
                            startPos,
                            action.payload.results.length,
                            ...action.payload.results
                        );
                    } else {
                        if (state.orderedSettings[action.meta.arg.entityType][action.meta.arg.entityId].results.length !== action.payload.count) {
                            state.orderedSettings[action.meta.arg.entityType][action.meta.arg.entityId].count = action.payload.count;
                            state.orderedSettings[action.meta.arg.entityType][action.meta.arg.entityId].results = new Array(action.payload.count);
                        }
                        state.orderedSettings[action.meta.arg.entityType][action.meta.arg.entityId].results.splice(
                            startPos,
                            action.payload.results.length,
                            ...action.payload.results
                        );
                    }
                }
                state.unordered = [
                    ...state.unordered.filter((s) => action.payload.results.find((res) => res.id == s.id) == undefined),
                    ...action.payload.results
                ];
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchSettings.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchSettings.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                const startPos = toNumber(action.meta.arg.size) * toNumber(action.meta.arg.index);
                if (state.settings == null) {
                    state.settings = { ...action.payload, results: new Array(action.payload.count) };
                    state.settings.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.settings.results.length !== action.payload.count) {
                        state.settings.count = action.payload.count;
                        state.settings.results = new Array(action.payload.count);
                    }
                    state.settings.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }

                state.unordered = [
                    ...state.unordered.filter((s) => action.payload.results.find((res) => res.id == s.id) == undefined),
                    ...action.payload.results
                ];
            });
    }
});

export const fetchEntitySettings = createAsyncThunk<
    SettingListResponse,
    { entityType: SettingEntityType; entityId: string; queryParams?: ApiQueryParams<DefaultQueryParams> }
>(
    'fetchEntitySettings',
    async (variables: { entityType: SettingEntityType; entityId: string; queryParams?: ApiQueryParams<DefaultQueryParams> }, { rejectWithValue }) => {
        try {
            return await SettingClient.fetchEntitySettings(variables.entityType, variables.entityId, variables.queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchSettings = createAsyncThunk<SettingListResponse, ApiQueryParams<DefaultQueryParams | SettingQueryParams>>(
    'fetchSettings',
    async (queryParams: ApiQueryParams<DefaultQueryParams | SettingQueryParams>, { rejectWithValue }) => {
        try {
            return await SettingClient.fetchSettings(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const settingStore = { settings: settingSlice.reducer };
export const { seedSettings, addSetting, removeSetting, updateSetting } = settingSlice.actions;
