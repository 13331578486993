import { Logger } from '@frontend/Logger';
import { useAppSelector } from '@frontend/common';
import { PackageClient } from '@frontend/package/api';
import { Package, PackageType } from '@frontend/package/types';
import { fetchProduct, productStore } from '@frontend/product/redux';
import { Product } from '@frontend/product/types';
import { Slot } from '@frontend/slot/types';
import { TransactionClient, TransactionWorkflowClient } from '@frontend/transaction/api';
import { Transaction, TransactionState, TransactionTriggerName, TransactionType } from '@frontend/transaction/types';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { navigationStore } from '../../redux/user-interface-navigation-slice';
import { ReturnProductProps } from './return-product.component';

interface ViewProps {
    product?: Product | null;
    disabled: boolean;
    submit: (e: any) => void;
    slot: Slot | null;
    changeSlot: Dispatch<SetStateAction<Slot | null>>;
}

const useReturnProduct = (props: ReturnProductProps): ViewProps => {
    const navigationState = useAppSelector(useSelector, navigationStore);
    const productState = useAppSelector(useSelector, productStore);
    const [transaction, changeTransaction] = useState<Transaction | null>(null);
    const [slot, changeSlot] = useState<Slot | null>(null);
    const [pack, changePack] = useState<Package | null>(null);
    const [product, changeProduct] = useState<Product | undefined | null>(undefined);

    useEffect(() => {
        TransactionClient.postAccountTransaction(
            {
                workflow_id: props.userInterface.data.transactionWorkflowId,
                driver_id: null,
                state: TransactionState.TRANSACTION_CREATED.value,
                type: TransactionType.VENDING,
                user_id: navigationState.user?.id
            },
            props.userInterface.account_id
        )
            .then(changeTransaction)
            .catch((e) => Logger.error('Could not create transaction.', {}, e));
    }, []);

    useEffect(() => {
        if (slot && product && transaction && pack === null) {
            PackageClient.postAccountTransactionPackage(props.userInterface.account_id, transaction.id, {
                workflow_id: props.userInterface.data.packageWorkflowId,
                product_id: product.id,
                spot_id: slot.spot_id,
                module_id: slot.module_id,
                slot_id: slot.id,
                type: PackageType.VENDING,
                user_id: navigationState.user?.id,
                quantity: 1
            }).then(changePack);
        }
    }, [slot, transaction, product]);

    useEffect(() => {
        const found = productState.unordered.find((s) => s.id == navigationState.cache?.product_id);
        if (found) changeProduct(found);
        if (!found && navigationState.cache && navigationState.cache.account_id && navigationState.cache.product_id) {
            props.dispatch(fetchProduct({ accountId: navigationState.cache.account_id, productId: navigationState.cache.product_id }));
        } else
            Logger.error(
                'Not enough information provided to fetch product.',
                {},
                { accountId: navigationState.cache?.account_id, productId: navigationState.cache?.product_id }
            );
    }, [navigationState, productState]);

    const submit = (e: any) => {
        e.preventDefault();
        if (!transaction) {
            Logger.error('Could not trigger transaction state because of missing account or product id.', {}, navigationState.cache, transaction);
            return;
        }
        TransactionWorkflowClient.triggerTransactionState(transaction.account_id, transaction.id, {
            trigger: TransactionTriggerName.TRANSACTION_PROCESS,
            source: undefined
        })
            .then(() => Logger.log('Trigger transaction state success, waiting for next event.'))
            .catch((e) => Logger.error('Trigger transaction state failed.', {}, e));
    };

    return {
        product,
        disabled: !navigationState.cache || !navigationState.cache.account_id || !navigationState.cache.product_id,
        submit,
        slot,
        changeSlot
    };
};

export default useReturnProduct;
