import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { NotificationTemplateClient } from '@frontend/notification-service/api';
import { NotificationTemplate, NotificationTemplateListResponse, NotificationTemplateQueryParams } from '@frontend/notification-service/types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

interface NotificationTemplateState {
    unordered: NotificationTemplate[];
    templates: NotificationTemplateListResponse | null;
    accountTemplates: { [accountId: string]: NotificationTemplateListResponse } | null;
    status: SliceStatus;
}

const initialState: NotificationTemplateState = {
    unordered: [],
    templates: null,
    accountTemplates: null,
    status: SliceStatus.INIT
};

const notificationTemplateSlice = createSlice({
    name: 'notificationTemplates',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchNotificationTemplates.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchNotificationTemplates.fulfilled, (state, action) => {
                const startPos = toNumber(action.meta.arg.size) * toNumber(action.meta.arg.index);
                if (state.templates == null) {
                    state.templates = { ...action.payload, results: new Array(action.payload.count) };
                    state.templates.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.templates.results.length !== action.payload.count) {
                        state.templates.count = action.payload.count;
                        state.templates.results = new Array(action.payload.count);
                    }
                    state.templates.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.unordered = [
                    ...state.unordered.filter((tmp) => action.payload.results.find((t) => t.id == tmp.id) == undefined),
                    ...action.payload.results
                ];

                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchAccountNotificationTemplates.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchAccountNotificationTemplates.fulfilled, (state, action) => {
                const startPos = toNumber(action.meta.arg.params.size) * toNumber(action.meta.arg.params.index);
                if (state.accountTemplates == null) {
                    state.accountTemplates = { [action.meta.arg.accountId]: { ...action.payload, results: new Array(action.payload.count) } };
                    state.accountTemplates[action.meta.arg.accountId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else if (state.accountTemplates[action.meta.arg.accountId] == undefined) {
                    state.accountTemplates[action.meta.arg.accountId] = { ...action.payload, results: new Array(action.payload.count) };
                    state.accountTemplates[action.meta.arg.accountId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.accountTemplates[action.meta.arg.accountId].results.length !== action.payload.count) {
                        state.accountTemplates[action.meta.arg.accountId].count = action.payload.count;
                        state.accountTemplates[action.meta.arg.accountId].results = new Array(action.payload.count);
                    }
                    state.accountTemplates[action.meta.arg.accountId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.unordered = [
                    ...state.unordered.filter((tmp) => action.payload.results.find((t) => t.id == tmp.id) == undefined),
                    ...action.payload.results
                ];
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchAccountNotificationTemplate.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchAccountNotificationTemplate.fulfilled, (state, action) => {
                if (state.accountTemplates == null) {
                    state.accountTemplates = { [action.meta.arg.accountId]: { count: 1, results: [action.payload] } };
                } else if (state.accountTemplates[action.meta.arg.accountId] == undefined) {
                    state.accountTemplates[action.meta.arg.accountId] = { count: 1, results: [action.payload] };
                } else {
                    const found = state.accountTemplates[action.meta.arg.accountId].results.find((a) => a && a.id == action.meta.arg.notificationTemplateId);
                    if (found == undefined) {
                        state.accountTemplates[action.meta.arg.accountId].results.push(action.payload);
                    } else {
                        state.accountTemplates[action.meta.arg.accountId].results.splice(
                            state.accountTemplates[action.meta.arg.accountId].results.indexOf(found),
                            1,
                            action.payload
                        );
                    }
                }
                state.unordered = [...state.unordered.filter((tmp) => tmp.id != action.payload.id), action.payload];
                state.status = SliceStatus.IDLE;
            })
            .addCase(deleteAccountNotificationTemplate.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(deleteAccountNotificationTemplate.fulfilled, (state, action) => {
                if (!state.templates) return;
                state.templates.results = state.templates.results.filter((t) => t.id != action.meta.arg.templateId);
                if (!state.accountTemplates || !state.accountTemplates[action.meta.arg.accountId]) return;
                state.accountTemplates[action.meta.arg.accountId].results = state.accountTemplates[action.meta.arg.accountId].results.filter(
                    (at) => at.id == action.meta.arg.templateId
                );
            });
    }
});

export const fetchNotificationTemplates = createAsyncThunk<
    NotificationTemplateListResponse,
    ApiQueryParams<DefaultQueryParams | NotificationTemplateQueryParams>
>('fetchNotificationTemplates', async (queryParams, { rejectWithValue }) => {
    try {
        return await NotificationTemplateClient.fetchNotificationTemplates(queryParams);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const fetchAccountNotificationTemplates = createAsyncThunk<
    NotificationTemplateListResponse,
    { accountId: string; params: ApiQueryParams<DefaultQueryParams> }
>('fetchAccountNotificationTemplates', async (vars, { rejectWithValue }) => {
    try {
        return await NotificationTemplateClient.fetchAccountNotificationTemplates(vars.accountId, vars.params);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const fetchAccountNotificationTemplate = createAsyncThunk<NotificationTemplate, { accountId: string; notificationTemplateId: string }>(
    'fetchAccountNotificationTemplate',
    async (variables: { accountId: string; notificationTemplateId: string }, { rejectWithValue }) => {
        try {
            return await NotificationTemplateClient.fetchAccountNotificationTemplate(variables.accountId, variables.notificationTemplateId);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const deleteAccountNotificationTemplate = createAsyncThunk<void, { accountId: string; templateId: string }>(
    'deleteAccountNotificationTemplate',
    async (variables: { accountId: string; templateId: string }, { rejectWithValue }) => {
        try {
            return await NotificationTemplateClient.deleteAccountNotificationTemplate(variables.accountId, variables.templateId);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const notificationTemplateStore = { notificationTemplates: notificationTemplateSlice.reducer };
