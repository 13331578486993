const AccountSelect = React.lazy(() => import('@frontend/account/common').then((module) => ({ default: module.AccountSelect })));
import { TextInput } from '@frontend/basic-forms';
import { ClassType } from '@frontend/common';
import { ModalFooter } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';
import { UserInterface, UserInterfaceType } from '@frontend/user-interface/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { FaPlus } from 'react-icons/fa';

import React from 'react';
import UserInterfacePlatformSelect from '../user-interface-platform-select/user-interface-platform-select.component';
import { UserInterfaceTypeSelect } from '../user-interface-type-select/user-interface-type-select.component';
import useCreateUserInterfaceForm from './create-user-interface-form.controller';
import UserInterfaceDataByTypeForm from './user-interface-data-by-type-form/user-interface-data-by-type-form.component';

const ID = 'create-user-interface-form';
export interface CreateUserInterfaceFormProps {
    onClose: () => void;
    callback?: (userInterface: UserInterface) => void;
    dispatch: ThunkDispatch<any, any, Action>;
}

export const CreateUserInterfaceForm = (props: CreateUserInterfaceFormProps) => {
    const viewProps = useCreateUserInterfaceForm(props);

    return (
        <div id={ID}>
            <form onSubmit={viewProps.onSubmit}>
                <div className='modal-header'>
                    <h4>Create user interface</h4>
                </div>
                <div className='modal-body'>
                    <div className='d-flex flex-row'>
                        <AccountSelect
                            className='w-50 m-1'
                            required
                            submitted={viewProps.submitted}
                            onChange={(val) => {
                                viewProps.changeFormValid({ ...viewProps.formValid, account: val != undefined });
                                viewProps.changeAccountId(val);
                            }}
                            value={viewProps.accountId}
                            dispatch={props.dispatch}
                        />
                        <UserInterfacePlatformSelect
                            className='w-50 m-1'
                            required
                            value={viewProps.formValues.platform}
                            onChange={(value) => {
                                viewProps.changeFormValues({ ...viewProps.formValues, platform: value.value });
                            }}
                            submitted={viewProps.submitted}
                        />
                    </div>
                    <div className='d-flex flex-row'>
                        <UserInterfaceTypeSelect
                            className='w-50 m-1'
                            required
                            platform={viewProps.formValues.platform}
                            value={viewProps.formValues.type}
                            onChange={(value) => {
                                viewProps.changeFormValid({ ...viewProps.formValid, type: value.value != UserInterfaceType.UNKNOWN });
                                viewProps.changeFormValues({ ...viewProps.formValues, type: value.value });
                            }}
                            submitted={viewProps.submitted}
                        />
                        <TextInput
                            className='w-50 m-1'
                            id={ID + '-nameInput'}
                            label='Name'
                            errorMessage='Please fill in a valid name.'
                            value={viewProps.formValues.name}
                            onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, name: value })}
                            required
                            isValidCallback={(valid) => {
                                viewProps.changeFormValid({ ...viewProps.formValid, name: valid });
                            }}
                            submitted={viewProps.submitted}
                        />
                    </div>
                    {viewProps.formValues.type != UserInterfaceType.UNKNOWN && viewProps.accountId && (
                        <UserInterfaceDataByTypeForm
                            accountId={viewProps.accountId}
                            onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, data: value })}
                            submitted={viewProps.submitted}
                            type={viewProps.formValues.type}
                            dispatch={props.dispatch}
                        />
                    )}
                </div>
                <ModalFooter
                    justifyContent='between'
                    buttons={[
                        {
                            hide: false,
                            text: CommonMessage.BUTTONS.CANCEL,
                            type: ClassType.SECONDARY,
                            onClick: (e) => {
                                e?.preventDefault();
                                props.onClose();
                            }
                        },
                        {
                            hide: false,
                            text: CommonMessage.BUTTONS.SUBMIT,
                            type: ClassType.SUCCESS,
                            icon: FaPlus,
                            buttonType: 'submit'
                        }
                    ]}
                />
            </form>
        </div>
    );
};