import { useNavigate } from 'react-router-dom';

import { ContactDetailModalProps } from './contact-detail-modal.component';

interface ViewProps {
    viewDetails: () => void;
}

export const useContactDetailModal = (props: ContactDetailModalProps): ViewProps => {
    const navigate = useNavigate();

    function viewDetails() {
        navigate(`/contacts/${props.contact?.id}/detail`);
    }

    return {
        viewDetails
    };
};
