import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

import { RakindaAccessClient } from '../api/rakinda-access-client';
import { RakindaClient } from '../api/rakinda-client';
import { Rakinda, RakindaListResponse } from '../rakinda';

export interface RakindaState {
    unordered: Rakinda[];
    rakindas: RakindaListResponse | null;
    status: SliceStatus;
}

const initialState: RakindaState = {
    unordered: [],
    rakindas: null,
    status: SliceStatus.INIT
};

const rakindaSlice = createSlice({
    name: 'rakindas',
    initialState,
    reducers: {
        seedRakindas(state, action: PayloadAction<Rakinda[]>) {
            state.unordered = [...state.unordered.filter((rakinda) => action.payload.find((r) => r.id == rakinda.id) == undefined), ...action.payload];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchRakindas.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchRakindas.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                const startPos = toNumber(action.meta.arg.size) * toNumber(action.meta.arg.index);
                if (state.rakindas == null) {
                    state.rakindas = {
                        ...action.payload,
                        results: new Array(action.payload.count)
                    };
                    state.rakindas.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.rakindas.results.length !== action.payload.count) {
                        state.rakindas.count = action.payload.count;
                        state.rakindas.results = new Array(action.payload.count);
                    }
                    state.rakindas.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.unordered = [
                    ...state.unordered.filter((r) => action.payload.results.find((res) => res.id == r.id) == undefined),
                    ...action.payload.results
                ];
            })
            .addCase(deleteRakinda.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(deleteRakinda.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                if (state.rakindas == null) return;
                const found = state.rakindas.results.find((r) => r.id == action.meta.arg);
                if (found == undefined) return;
                state.rakindas.results.splice(state.rakindas.results.indexOf(found), 1);
            })
            .addCase(fetchRakinda.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchRakinda.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                if (state.rakindas) {
                    const found = state.rakindas.results.find((r) => r.id == action.meta.arg);
                    if (found) {
                        state.rakindas.results.splice(state.rakindas.results.indexOf(found), 1, action.payload);
                    } else state.rakindas.results.push(action.payload);
                } else state.rakindas = { count: 1, results: [action.payload] };

                state.unordered = [...state.unordered.filter((rakinda) => rakinda.id != action.meta.arg), action.payload];
            })
            .addCase(deleteRakindas.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(deleteRakindas.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                state.unordered = state.unordered.filter((u) => !action.meta.arg.includes(u.id));
                if (state.rakindas == null) return;
                state.rakindas.count = state.rakindas.count - action.meta.arg.length;
                state.rakindas.results = state.rakindas.results.filter((r) => !action.meta.arg.includes(r.id));
            });
    }
});

export const fetchRakindas = createAsyncThunk<RakindaListResponse, ApiQueryParams<DefaultQueryParams>>(
    'fetchRakindas',
    async (queryParams: ApiQueryParams<DefaultQueryParams>, { rejectWithValue }) => {
        try {
            return await RakindaClient.fetchRakindas(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const deleteRakinda = createAsyncThunk<void, string>('deleteRakinda', async (rakindaId: string, { rejectWithValue }) => {
    try {
        return await RakindaClient.deleteRakinda(rakindaId);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const fetchRakinda = createAsyncThunk<Rakinda, string>('fetchRakinda', async (rakindaId: string, { rejectWithValue }) => {
    try {
        return await RakindaClient.fetchRakinda(rakindaId);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const openRakinda = createAsyncThunk<string, string>('openRakinda', async (rakindaId: string, { rejectWithValue }) => {
    try {
        return await RakindaAccessClient.openRakinda(rakindaId);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const deleteRakindas = createAsyncThunk<void, string[]>('deleteRakindas', async (rakindaIds: string[], { rejectWithValue }) => {
    try {
        return await RakindaClient.deleteRakindas(rakindaIds);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const rakindaStore = { rakindas: rakindaSlice.reducer };
export const { seedRakindas } = rakindaSlice.actions;
