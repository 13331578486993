import { UserTitle } from '@frontend/user/types';
import { useMemo } from 'react';

import { TitleSelectProps } from './title-select.component';

interface ViewProps {
    options: { value: string; label: React.ReactNode }[];
    value:
        | {
              value: string;
              label: string;
          }
        | undefined;
}

const useTitleSelect = (props: TitleSelectProps): ViewProps => {
    const options = useMemo(() => {
        return Object.entries(UserTitle).map(([key, value]) => {
            return {
                value: value,
                label: key
            };
        });
    }, []);

    const value = useMemo(() => {
        if (!options || !props.value) return undefined;
        else return options.find((op) => op.value == props.value);
    }, [props.value, options]);

    return { options, value };
};

export default useTitleSelect;
