import { APIClient, ApiQueryParams, DefaultQueryParams, DefaultSearchQueryParams } from '@frontend/api-utils';

import { BasicAuth, BasicAuthListResponse, BasicAuthQueryParams, ChangePassword, CreateBasicAuth, UpdateBasicAuth } from '@frontend/authentication-methods/types';

const endpoint = '/authentication-api/v1';

export class BasicAuthClient extends APIClient {
    public static async fetchBasicAuths(queryParams?: ApiQueryParams<DefaultQueryParams | BasicAuthQueryParams>): Promise<BasicAuthListResponse> {
        return await this.apiPaginated<BasicAuthListResponse, DefaultQueryParams>(`${endpoint}/basic-auths`, queryParams);
    }

    public static async searchBasicAuths(queryParams?: ApiQueryParams<DefaultQueryParams | DefaultSearchQueryParams>): Promise<BasicAuthListResponse> {
        return await this.apiPaginated<BasicAuthListResponse, DefaultQueryParams | DefaultSearchQueryParams>(`${endpoint}/basic-auths-search`, queryParams);
    }

    public static async postBasicAuth(userId: string, body: CreateBasicAuth): Promise<BasicAuth> {
        const response = await this.post(`${endpoint}/users/${userId}/basic-auths`, body);
        return await this.handleResponse<BasicAuth>(response);
    }

    public static async fetchBasicAuth(userId: string, basicAuthId: string): Promise<BasicAuth> {
        const response = await this.fetch(`${endpoint}/users/${userId}/basic-auths/${basicAuthId}`);
        return await this.handleResponse<BasicAuth>(response);
    }

    public static async patchBasicAuth(userId: string, basicAuthId: string, body: UpdateBasicAuth): Promise<BasicAuth> {
        const response = await this.patch(`${endpoint}/users/${userId}/basic-auths/${basicAuthId}`, body);
        return await this.handleResponse<BasicAuth>(response);
    }

    public static async deleteBasicAuth(userId: string, basicAuthId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/users/${userId}/basic-auths/${basicAuthId}`);
        return await this.handleVoidResponse(response);
    }

    public static async changePassword(userId: string, basicAuthId: string, body: ChangePassword): Promise<BasicAuth> {
        const response = await this.patch(`${endpoint}/users/${userId}/basic-auths/${basicAuthId}/change-password`, body);
        return await this.handleResponse<BasicAuth>(response);
    }
}
