import React from 'react';
import { FormattedMessage } from 'react-intl';

export const Events = {
    TRANSACTION_STATE_CHANGED: (
        <FormattedMessage
            id='Events.TRANSACTION_STATE_CHANGED'
            description='Transaction state changed event'
            defaultMessage='Transaction state changed'
        />
    ),
    PRODUCT_STATE_CHANGED: (
        <FormattedMessage
            id='Events.PRODUCT_STATE_CHANGED'
            description='Product state changed event'
            defaultMessage='Product state changed'
        />
    ),
    PACKAGE_STATE_CHANGED: (
        <FormattedMessage
            id='Events.PACKAGE_STATE_CHANGED'
            description='Package state changed event'
            defaultMessage='Package state changed'
        />
    ),
    USER_STATE_CHANGED: (
        <FormattedMessage
            id='Events.USER_STATE_CHANGED'
            description='User state changed event'
            defaultMessage='User state changed'
        />
    ),
    DEVICE_STATE_CHANGED: (
        <FormattedMessage
            id='Events.DEVICE_STATE_CHANGED'
            description='Device state changed event'
            defaultMessage='Device state changed'
        />
    ),
    TRANSACTION_TRIGGER_RECEIVED: (
        <FormattedMessage
            id='Events.TRANSACTION_TRIGGER_RECEIVED'
            description='Transaction trigger received event'
            defaultMessage='Transaction trigger received'
        />
    ),
    PRODUCT_TRIGGER_RECEIVED: (
        <FormattedMessage
            id='Events.PRODUCT_TRIGGER_RECEIVED'
            description='Product trigger received event'
            defaultMessage='Product trigger received'
        />
    ),
    PACKAGE_TRIGGER_RECEIVED: (
        <FormattedMessage
            id='Events.PACKAGE_TRIGGER_RECEIVED'
            description='Package trigger received event'
            defaultMessage='Package trigger received'
        />
    ),
    USER_TRIGGER_RECEIVED: (
        <FormattedMessage
            id='Events.USER_TRIGGER_RECEIVED'
            description='User trigger received event'
            defaultMessage='User trigger received'
        />
    ),
    DEVICE_TRIGGER_RECEIVED: (
        <FormattedMessage
            id='Events.DEVICE_TRIGGER_RECEIVED'
            description='Device trigger received event'
            defaultMessage='Device trigger received'
        />
    ),
    TRANSACTION_ACTION_TRIGGERED: (
        <FormattedMessage
            id='Events.TRANSACTION_ACTION_TRIGGERED'
            description='Transaction action triggered event'
            defaultMessage='Transaction action triggered'
        />
    ),
    PRODUCT_ACTION_TRIGGERED: (
        <FormattedMessage
            id='Events.PRODUCT_ACTION_TRIGGERED'
            description='Product action triggered event'
            defaultMessage='Product action triggered'
        />
    ),
    PACKAGE_ACTION_TRIGGERED: (
        <FormattedMessage
            id='Events.PACKAGE_ACTION_TRIGGERED'
            description='Package action triggered event'
            defaultMessage='Package action triggered'
        />
    ),
    USER_ACTION_TRIGGERED: (
        <FormattedMessage
            id='Events.USER_ACTION_TRIGGERED'
            description='User action triggered event'
            defaultMessage='User action triggered'
        />
    ),
    DEVICE_ACTION_TRIGGERED: (
        <FormattedMessage
            id='Events.DEVICE_ACTION_TRIGGERED'
            description='Device action triggered event'
            defaultMessage='Device action triggered'
        />
    ),
    SLOT_SENSOR_RECEIVED: (
        <FormattedMessage
            id='Events.SLOT_SENSOR_RECEIVED'
            description='Slot sensor received event'
            defaultMessage='Slot sensor received'
        />
    ),
    NEW_DRIVER_REGISTERED: (
        <FormattedMessage
            id='Events.NEW_DRIVER_REGISTERED'
            description='New driver registered event'
            defaultMessage='New driver registered'
        />
    ),
    NEW_DEVICE_REGISTERED: (
        <FormattedMessage
            id='Events.NEW_DEVICE_REGISTERED'
            description='New device registered event'
            defaultMessage='New device registered'
        />
    ),
    DEVICE_HEARTBEAT_MISSED: (
        <FormattedMessage
            id='Events.DEVICE_HEARTBEAT_MISSED'
            description='Device heartbeat missed event'
            defaultMessage='Device heartbeat missed'
        />
    ),
    DEVICE_HEARTBEAT_RECOVERED: (
        <FormattedMessage
            id='Events.DEVICE_HEARTBEAT_RECOVERED'
            description='Device heartbeat recovered event'
            defaultMessage='Device heartbeat recovered'
        />
    ),
    RAKINDA_MASTER_PIN_CODE_USED: (
        <FormattedMessage
            id='Events.RAKINDA_MASTER_PIN_CODE_USED'
            description='Rakinda master pin code used event'
            defaultMessage='Rakinda master pin code used'
        />
    ),
    RAKINDA_ACCESS_GRANTED: (
        <FormattedMessage
            id='Events.RAKINDA_ACCESS_GRANTED'
            description='Rakinda access granted event'
            defaultMessage='Rakinda access granted'
        />
    ),
    RAKINDA_ACCESS_DENIED: (
        <FormattedMessage
            id='Events.RAKINDA_ACCESS_DENIED'
            description='Rakinda access denied event'
            defaultMessage='Rakinda access denied'
        />
    ),
    EMAIL_SENT: (
        <FormattedMessage
            id='Events.EMAIL_SENT'
            description='Email sent event'
            defaultMessage='Email sent'
        />
    ),
    ENTITY_SYNC_CREATED: (
        <FormattedMessage
            id='Events.ENTITY_SYNC_CREATED'
            description='Entity sync created event'
            defaultMessage='Entity sync created'
        />
    ),
    ENTITY_SYNC_UPDATED: (
        <FormattedMessage
            id='Events.ENTITY_SYNC_UPDATED'
            description='Entity sync updated event'
            defaultMessage='Entity sync updated'
        />
    ),
    ENTITY_SYNC_DELETED: (
        <FormattedMessage
            id='Events.ENTITY_SYNC_DELETED'
            description='Entity sync deleted event'
            defaultMessage='Entity sync deleted'
        />
    ),
    QR_CODE_SCANNED: (
        <FormattedMessage
            id='Events.QR_CODE_SCANNED'
            description='QR code scanned event'
            defaultMessage='QR code scanned'
        />
    ),
    NFC_BADGE_SCANNED: (
        <FormattedMessage
            id='Events.NFC_BADGE_SCANNED'
            description='NFC badge scanned event'
            defaultMessage='NFC badge scanned'
        />
    )
};
