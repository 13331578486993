import { BadgeTemplate, DetailModalType, NameBadge } from '@frontend/elements';
import React from 'react';

import useBasicAuthBadge from './basic-auth-badge.controller';

export interface BasicAuthBadgeProps {
    id?: string;
    first_name?: string | null;
    last_name?: string | null;
}

const BasicAuthBadge = (props: BasicAuthBadgeProps) => {
    const viewProps = useBasicAuthBadge(props);

    return (
        <BadgeTemplate
            disabled={viewProps.disabled}
            type={DetailModalType.BASIC_AUTH}
            objectId={props.id}>
            {!viewProps.displayName ? (
                <span className='placeholder'>
                    <span className='placeholder-glow'>Loading</span>
                </span>
            ) : typeof viewProps.displayName === 'string' ? (
                <>
                    <img
                        alt=''
                        className='rounded'
                        height={20}
                    />
                    {viewProps.displayName}
                </>
            ) : (
                <NameBadge
                    firstName={viewProps.displayName.first_name}
                    lastName={viewProps.displayName.last_name}
                />
            )}
        </BadgeTemplate>
    );
};

export default BasicAuthBadge;
