import { NotificationContentClient } from '@frontend/notification-service/api';
import { Editor } from '@tiptap/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { NotificationPreviewProps } from './notification-preview.component';

interface ViewProps {
    changeEditor: (e: any) => void;
    subject: string | null;
    editor: Editor | null;
}

const useNotificationPreview = (props: NotificationPreviewProps): ViewProps => {
    const { accountId } = useParams();
    const [editor, changeEditor] = useState<Editor | null>(null);
    const [subject, changeSubject] = useState<string | null>(null);
    const [content, changeContent] = useState<string | null>(null);

    useEffect(() => {
        if (props.show && accountId && editor) {
            NotificationContentClient.postAccountNotificationPreview(accountId, { body: props.content, subject: props.subject, footer: '' })
                .then((res) => {
                    if (res.subject) {
                        changeSubject(res.subject);
                    }
                    changeContent(res.body);
                })
                .catch((err) => {});
        }
    }, [props.show, editor, props.content]);

    useEffect(() => {
        if (editor && content) {
            editor.commands.setContent(content);
        }
    }, [content, editor]);

    return {
        changeEditor,
        subject,
        editor
    };
};

export default useNotificationPreview;
