import { BasicAuth } from '@frontend/authentication-methods/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

import { DefaultModal, DefaultModalProps, ModalType } from '../../../../modals/default-modal/default-modal.component';
import { ModalHeader } from '../../../../modals/modal-header/modal-header.component';
import { BasicAuthDetail } from '../basic-auth-detail.component';

const ID = 'basic-auth-detail-modal';

export interface BasicAuthDetailModalProps extends DefaultModalProps {
    basicAuth: BasicAuth;
    dispatch: ThunkDispatch<any, any, Action>;
}

const BasicAuthDetailModal = (props: BasicAuthDetailModalProps) => {
    return (
        <DefaultModal
            type={ModalType.PANE}
            id={ID}
            {...props}>
            <ModalHeader
                id={ID + '-header'}
                handleClose={props.handleClose}
                title={props.basicAuth.first_name + ' ' + props.basicAuth.last_name}
            />
            <div className='modal-body'>
                <BasicAuthDetail basicAuth={props.basicAuth} />
            </div>
        </DefaultModal>
    );
};

export default BasicAuthDetailModal;
