import { useState } from 'react';

interface ViewProps {
    showDeleteModal: boolean;
    changeShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
    showUpdateModal: boolean;
    changeShowUpdateModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const useTransactionVariablesElement = (): ViewProps => {
    const [showDeleteModal, changeShowDeleteModal] = useState<boolean>(false);
    const [showUpdateModal, changeShowUpdateModal] = useState<boolean>(false);

    return {
        changeShowDeleteModal,
        changeShowUpdateModal,
        showDeleteModal,
        showUpdateModal
    };
};

export default useTransactionVariablesElement;
