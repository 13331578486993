import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { WareClient } from '@frontend/stock/api';
import { Ware, WareListResponse, WareQueryParams } from '@frontend/stock/types';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isArray, toNumber } from 'lodash';

interface WareState {
    unordered: Ware[];
    wares: WareListResponse | null;
    wareBySlotByModuleBySpotList: { [spotId: string]: { [moduleId: string]: { [slotId: string]: WareListResponse } } } | null;
    wareByProduct: { [productId: string]: WareListResponse } | null;
    status: SliceStatus;
}

const initialState: WareState = {
    unordered: [],
    wares: null,
    wareBySlotByModuleBySpotList: null,
    wareByProduct: null,
    status: SliceStatus.IDLE
};

const wareSlice = createSlice({
    name: 'wares',
    initialState,
    reducers: {
        seedWares(state, action: PayloadAction<Ware[]>) {
            state.unordered = [...state.unordered.filter((slot) => action.payload.find((s) => s.id == slot.id) == undefined), ...action.payload];
        },
        updateWare(state, action: PayloadAction<Ware>) {
            state.unordered = state.unordered.map((s) => (s.id == action.payload.id ? action.payload : s));
            if (state.wares != null) {
                state.wares.results = state.wares.results.map((s) => (s.id == action.payload.id ? action.payload : s));
            }
            if (state.wareBySlotByModuleBySpotList?.[action.payload.spot_id]?.[action.payload.module_id]?.[action.payload.slot_id]) {
                state.wareBySlotByModuleBySpotList[action.payload.spot_id][action.payload.module_id][action.payload.slot_id].results =
                    state.wareBySlotByModuleBySpotList[action.payload.spot_id][action.payload.module_id][action.payload.slot_id].results.map((s) =>
                        s.id == action.payload.id ? action.payload : s
                    );
            }
            if (state.wareByProduct?.[action.payload.product_id]) {
                state.wareByProduct[action.payload.product_id].results = state.wareByProduct[action.payload.product_id].results.map((s) =>
                    s.id == action.payload.id ? action.payload : s
                );
            }
        },
        addWare(state, action: PayloadAction<Ware>) {
            state.unordered.push(action.payload);
            if (state.wares != null) {
                state.wares.count++;
                state.wares.results.push(action.payload);
            }
            if (state.wareBySlotByModuleBySpotList?.[action.payload.spot_id]?.[action.payload.module_id]?.[action.payload.slot_id]) {
                state.wareBySlotByModuleBySpotList[action.payload.spot_id][action.payload.module_id][action.payload.slot_id].count++;
                state.wareBySlotByModuleBySpotList[action.payload.spot_id][action.payload.module_id][action.payload.slot_id].results.splice(
                    0,
                    0,
                    action.payload
                );
            }
            if (state.wareByProduct?.[action.payload.product_id]) {
                state.wareByProduct[action.payload.product_id].count++;
                state.wareByProduct[action.payload.product_id].results.splice(0, 0, action.payload);
            }
        },
        removeWare(state, action: PayloadAction<string>) {
            state.unordered = state.unordered.filter((s) => s.id != action.payload);
            if (state.wares != null) {
                state.wares.count--;
                state.wares.results = state.wares.results.filter((s) => s.id != action.payload);
            }
            if (state.wareBySlotByModuleBySpotList) {
                Object.keys(state.wareBySlotByModuleBySpotList).forEach((key) => {
                    Object.keys(state.wareBySlotByModuleBySpotList![key]).forEach((modKey) => {
                        Object.keys(state.wareBySlotByModuleBySpotList![key][modKey]).forEach((slotKey) => {
                            state.wareBySlotByModuleBySpotList![key][modKey][slotKey].count--;
                            state.wareBySlotByModuleBySpotList![key][modKey][slotKey].results = state.wareBySlotByModuleBySpotList![key][modKey][
                                slotKey
                            ].results.filter((s) => s.id != action.payload);
                        });
                    });
                });
            }
            if (state.wareByProduct) {
                Object.keys(state.wareByProduct).forEach((key) => {
                    state.wareByProduct![key].count--;
                    state.wareByProduct![key].results = state.wareByProduct![key].results.filter((s) => s.id != action.payload);
                });
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchWares.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchWares.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                const startPos = toNumber(action.meta.arg.size) * toNumber(action.meta.arg.index);
                if (state.wares == null) {
                    state.wares = { count: action.payload.count, results: new Array(action.payload.count) };
                    state.wares.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.wares.results.length != action.payload.count) {
                        state.wares.count = action.payload.count;
                        state.wares.results = new Array(action.payload.count);
                    }
                    state.wares.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }

                if (action.meta.arg.product_id && !isArray(action.meta.arg.product_id)) {
                    if (state.wareByProduct == null) {
                        state.wareByProduct = { [action.meta.arg.product_id]: { count: action.payload.count, results: new Array(action.payload.count) } };
                        state.wareByProduct[action.meta.arg.product_id].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    } else if(state.wareByProduct[action.meta.arg.product_id] !== undefined) {
                        if (state.wareByProduct[action.meta.arg.product_id].results.length != action.payload.count) {
                            state.wareByProduct[action.meta.arg.product_id].count = action.payload.count;
                            state.wareByProduct[action.meta.arg.product_id].results = new Array(action.payload.count);
                        }
                        state.wareByProduct[action.meta.arg.product_id].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    } else {
                        state.wareByProduct[action.meta.arg.product_id] = { count: action.payload.count, results: new Array(action.payload.count) };
                        state.wareByProduct[action.meta.arg.product_id].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    }
                }

                state.unordered = [
                    ...state.unordered.filter((s) => action.payload.results.find((res) => res.id == s.id) == undefined),
                    ...action.payload.results
                ];
            })
            .addCase(fetchSpotModuleSlotWares.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchSpotModuleSlotWares.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                const { spotId, moduleId, slotId } = action.meta.arg;
                state.unordered = [
                    ...state.unordered.filter((s) => action.payload.results.find((res) => res.id == s.id) == undefined),
                    ...action.payload.results
                ];
                const startPos = toNumber(action.meta.arg.queryParams.size) * toNumber(action.meta.arg.queryParams.index);
                if (state.wareBySlotByModuleBySpotList == null) {
                    state.wareBySlotByModuleBySpotList = {
                        [spotId]: { [moduleId]: { [slotId]: { count: action.payload.count, results: new Array(action.payload.count) } } }
                    };
                    state.wareBySlotByModuleBySpotList[spotId][moduleId][slotId].results.splice(
                        startPos,
                        action.payload.results.length,
                        ...action.payload.results
                    );
                } else if (state.wareBySlotByModuleBySpotList && !state.wareBySlotByModuleBySpotList[spotId]) {
                    state.wareBySlotByModuleBySpotList[spotId] = {
                        [moduleId]: { [slotId]: { count: action.payload.count, results: new Array(action.payload.count) } }
                    };
                    state.wareBySlotByModuleBySpotList[spotId][moduleId];
                } else if (
                    state.wareBySlotByModuleBySpotList &&
                    state.wareBySlotByModuleBySpotList[spotId] &&
                    !state.wareBySlotByModuleBySpotList[spotId][moduleId]
                ) {
                    state.wareBySlotByModuleBySpotList[spotId][moduleId] = {
                        [slotId]: { count: action.payload.count, results: new Array(action.payload.count) }
                    };
                    state.wareBySlotByModuleBySpotList[spotId][moduleId][slotId].results.splice(
                        startPos,
                        action.payload.results.length,
                        ...action.payload.results
                    );
                } else if (
                    state.wareBySlotByModuleBySpotList &&
                    state.wareBySlotByModuleBySpotList[spotId] &&
                    state.wareBySlotByModuleBySpotList[spotId][moduleId] &&
                    !state.wareBySlotByModuleBySpotList[spotId][moduleId][slotId]
                ) {
                    state.wareBySlotByModuleBySpotList[spotId][moduleId][slotId] = { count: action.payload.count, results: new Array(action.payload.count) };
                    state.wareBySlotByModuleBySpotList[spotId][moduleId][slotId].results.splice(
                        startPos,
                        action.payload.results.length,
                        ...action.payload.results
                    );
                } else {
                    if (state.wareBySlotByModuleBySpotList[spotId][moduleId][slotId].results.length != action.payload.count) {
                        state.wareBySlotByModuleBySpotList[spotId][moduleId][slotId].count = action.payload.count;
                        state.wareBySlotByModuleBySpotList[spotId][moduleId][slotId].results = new Array(action.payload.count);
                    }
                    state.wareBySlotByModuleBySpotList[spotId][moduleId][slotId].results.splice(
                        startPos,
                        action.payload.results.length,
                        ...action.payload.results
                    );
                }
            })
            .addCase(fetchSpotModuleSlotWare.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchSpotModuleSlotWare.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                const { spotId, moduleId, slotId } = action.meta.arg;
                state.unordered = [...state.unordered.filter((s) => action.payload.id != s.id), action.payload];
                if (state.wareBySlotByModuleBySpotList == null) {
                    state.wareBySlotByModuleBySpotList = {
                        [spotId]: { [moduleId]: { [slotId]: { count: 1, results: [action.payload] } } }
                    };
                } else if (state.wareBySlotByModuleBySpotList && !state.wareBySlotByModuleBySpotList[spotId]) {
                    state.wareBySlotByModuleBySpotList[spotId] = {
                        [moduleId]: { [slotId]: { count: 1, results: [action.payload] } }
                    };
                    state.wareBySlotByModuleBySpotList[spotId][moduleId];
                } else if (
                    state.wareBySlotByModuleBySpotList &&
                    state.wareBySlotByModuleBySpotList[spotId] &&
                    !state.wareBySlotByModuleBySpotList[spotId][moduleId]
                ) {
                    state.wareBySlotByModuleBySpotList[spotId][moduleId] = {
                        [slotId]: { count: 1, results: [action.payload] }
                    };
                } else if (
                    state.wareBySlotByModuleBySpotList &&
                    state.wareBySlotByModuleBySpotList[spotId] &&
                    state.wareBySlotByModuleBySpotList[spotId][moduleId] &&
                    !state.wareBySlotByModuleBySpotList[spotId][moduleId][slotId]
                ) {
                    state.wareBySlotByModuleBySpotList[spotId][moduleId][slotId] = { count: 1, results: [action.payload] };
                } else {
                    state.wareBySlotByModuleBySpotList[spotId][moduleId][slotId].count++;
                    state.wareBySlotByModuleBySpotList[spotId][moduleId][slotId].results.splice(0, 0, action.payload);
                }
            });
    }
});

export const fetchWares = createAsyncThunk<WareListResponse, ApiQueryParams<DefaultQueryParams | WareQueryParams>>(
    'fetchWares',
    async (queryParams: ApiQueryParams<DefaultQueryParams | WareQueryParams>, { rejectWithValue }) => {
        try {
            return await WareClient.fetchWares(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchSpotModuleSlotWares = createAsyncThunk<
    WareListResponse,
    { spotId: string; moduleId: string; slotId: string; queryParams: ApiQueryParams<DefaultQueryParams> }
>(
    'fetchSpotModuleSlotWares',
    async (variables: { spotId: string; moduleId: string; slotId: string; queryParams: ApiQueryParams<DefaultQueryParams> }, { rejectWithValue }) => {
        try {
            return await WareClient.fetchSpotModuleSlotWares(variables.spotId, variables.moduleId, variables.slotId, variables.queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchSpotModuleSlotWare = createAsyncThunk<Ware, { spotId: string; moduleId: string; slotId: string; wareId: string }>(
    'fetchSpotModuleSlotWare',
    async (variables: { spotId: string; moduleId: string; slotId: string; wareId: string }, { rejectWithValue }) => {
        try {
            return await WareClient.fetchSpotModuleSlotWare(variables.spotId, variables.moduleId, variables.slotId, variables.wareId);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const wareStore = { wares: wareSlice.reducer };
export const { seedWares, updateWare, addWare, removeWare } = wareSlice.actions;
