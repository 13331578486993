import { ClassType } from '@frontend/common';
import { DefaultModal, ModalFooter, ModalType, Spinner } from '@frontend/elements';
import { FaPlus } from 'react-icons/fa';
import { GrClose } from 'react-icons/gr';

import CreateRestrictionForm from '../create-restriction-form/create-restriction-form.component';
import RestrictionList from '../restrictions-list/restriction-list.component';
import useRestrictionModal from './restriction-modal.controller';

const ID = 'restriction-modal';

const RestrictionModal = () => {
    const viewProps = useRestrictionModal();

    return (
        <div id={ID}>
            <DefaultModal
                type={ModalType.PANE}
                handleClose={viewProps.handleClose}
                show>
                <div className='modal-header'>
                    <h4>Restrictions</h4>
                    <button
                        className='btn btn-outline-secondary'
                        onClick={viewProps.handleClose}>
                        <GrClose />
                    </button>
                </div>
                <div className='modal-body p-0'>{viewProps.isLoading ? <Spinner /> : <RestrictionList />}</div>
                <ModalFooter
                    buttons={[
                        {
                            text: 'Add restriction',
                            icon: FaPlus,
                            type: ClassType.PRIMARY,
                            onClick: () => viewProps.changeRestrictionModalOpen(true)
                        }
                    ]}
                />
                <DefaultModal
                    handleClose={() => viewProps.changeRestrictionModalOpen(false)}
                    show={viewProps.restrictionModalOpen}>
                    <CreateRestrictionForm
                        onClose={() => viewProps.changeRestrictionModalOpen(false)}
                        dispatch={viewProps.dispatch}
                    />
                </DefaultModal>
            </DefaultModal>
        </div>
    );
};

export default RestrictionModal;
