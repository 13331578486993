import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { Token } from '@frontend/authentication-v2';
import { CreateProductCategory, ProductCategory, ProductCategoryListResponse, ProductCategoryQueryParams, UpdateProductCategory } from '@frontend/product-category/types';

const endpoint = '/product-api/v1';

export class ProductCategoryClient extends APIClient {
    static async fetchProductCategories(queryParams: ApiQueryParams<DefaultQueryParams | ProductCategoryQueryParams>, withCredentials?: Token): Promise<ProductCategoryListResponse> {
        return await this.apiPaginated<ProductCategoryListResponse, DefaultQueryParams | ProductCategoryQueryParams>(`${endpoint}/categories`, queryParams, withCredentials);
    }

    static async resolveProductCategories(productCategoryIds: string[]): Promise<ProductCategoryListResponse> {
        const response = await this.post(`${endpoint}/categories-resolve`, { ids: productCategoryIds });
        return await this.handleResponse<ProductCategoryListResponse>(response);
    }

    static async deleteProductCategories(productCategoryIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/categories-delete`, { ids: productCategoryIds });
        return await this.handleVoidResponse(response);
    }

    static async fetchAccountProductCategories(accountId: string, queryParams: ApiQueryParams<DefaultQueryParams>): Promise<ProductCategoryListResponse> {
        return await this.apiPaginated<ProductCategoryListResponse, DefaultQueryParams>(`${endpoint}/accounts/${accountId}/categories`, queryParams);
    }

    static async postAccountProductCategory(accountId: string, productCategory: CreateProductCategory): Promise<ProductCategory> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/categories`, productCategory);
        return await this.handleResponse<ProductCategory>(response);
    }

    static async fetchAccountProductCategory(accountId: string, productCategoryId: string): Promise<ProductCategory> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/categories/${productCategoryId}`);
        return await this.handleResponse<ProductCategory>(response);
    }

    static async patchAccountProductCategory(accountId: string, productCategoryId: string, productCategory: UpdateProductCategory): Promise<ProductCategory> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/categories/${productCategoryId}`, productCategory);
        return await this.handleResponse<ProductCategory>(response);
    }

    static async deleteAccountProductCategory(accountId: string, productCategoryId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/categories/${productCategoryId}`);
        return await this.handleVoidResponse(response);
    }

    static async fetchAccountCategoriesTree(accountId: string, queryParams?: ApiQueryParams<DefaultQueryParams | ProductCategoryQueryParams>): Promise<any> {
        return await this.apiPaginated(`${endpoint}/accounts/${accountId}/categories-tree`, queryParams);
    }

    static async fetchCategoriesTree(queryParams?: ApiQueryParams<DefaultQueryParams | ProductCategoryQueryParams>): Promise<any> {
        return await this.apiPaginated(`${endpoint}/categories-tree`, queryParams);
    }
}
