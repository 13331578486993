import { EntityType } from '@frontend/common';
import { DefaultModalProps, ModalType } from '@frontend/elements';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React, { useMemo } from 'react';
import useDetailModalContainer from './detail-modal-container.controller';

const ID = 'detail-modal-container';
export interface DetailModalContainerProps {
    dispatch: ThunkDispatch<any, any, Action>;
    modals: { [key: string]: (props: DefaultModalProps) => JSX.Element };
}

export const DetailModalContainer = (props: DetailModalContainerProps) => {
    const viewProps = useDetailModalContainer();
    const prefixRegex = /^([^-]*)-/;

    return (
        <div id={ID}>
            {viewProps.modals.map((modal) => (
                <Modal
                    key={modal}
                    id={modal.slice(prefixRegex.exec(modal)?.[0].length)}
                    entityType={modal.split('-')[0] as EntityType}
                    handleClose={viewProps.handleClose}
                    modals={props.modals}
                    show
                    dispatch={props.dispatch}
                />
            ))}
        </div>
    );
};

export default DetailModalContainer;

interface Props extends DefaultModalProps {
    entityType: EntityType;
    id: string;
    modals: { [key: string]: (props: any) => JSX.Element };
    dispatch: ThunkDispatch<any, any, Action>;
}

const Modal = (props: Props): JSX.Element => {
    const EntityModal = useMemo(() => props.modals[props.entityType], [props.entityType]);

    return (
        <div id={ID}>
            {EntityModal && (
                <EntityModal
                    type={ModalType.PANE}
                    {...props}
                />
            )}
        </div>
    );
};