import React from 'react';
import { FormattedMessage } from 'react-intl';

export const Forms = {
    NAME: (
        <FormattedMessage
            id='account.forms.createAccount.nameInput.label'
            description='The label for the name input'
            defaultMessage='Name'
        />
    ),
    FIRST_NAME: (
        <FormattedMessage
            id='Forms.FIRST_NAME'
            description='The label for the first name input'
            defaultMessage='First name'
        />
    ),
    LAST_NAME: (
        <FormattedMessage
            id='Forms.LAST_NAME'
            description='The label for the last name input'
            defaultMessage='Last name'
        />
    ),
    EMAIL: formattedEmail,
    COUNTRY: (
        <FormattedMessage
            id='Forms.COUNTRY'
            description='The label for the country input'
            defaultMessage='Country'
        />
    ),
    ZIP_CODE: (
        <FormattedMessage
            id='Forms.ZIP_CODE'
            description='The label for the zip code input'
            defaultMessage='Zip code'
        />
    ),
    CITY: (
        <FormattedMessage
            id='Forms.CITY'
            description='The label for the city input'
            defaultMessage='City'
        />
    ),
    SPOT: (
        <FormattedMessage
            id='Forms.SPOT'
            description='Label for SPOT'
            defaultMessage='SPOT'
        />
    ),
    SLOT_NUMBER: (
        <FormattedMessage
            id='Forms.SLOT_NUMBER'
            description='Label for SLOT number'
            defaultMessage='SLOT number'
        />
    ),
    ALIAS: (
        <FormattedMessage
            id='Forms.ALIAS'
            description='Label for alias'
            defaultMessage='Alias'
        />
    ),
    LANGUAGE: (
        <FormattedMessage
            id='Forms.LANGUAGE'
            description='Label for language'
            defaultMessage='Language'
        />
    ),
    MOBILE: (
        <FormattedMessage
            id='Forms.MOBILE'
            description='Label for mobile'
            defaultMessage='Mobile'
        />
    ),
    ADDRESS: (
        <FormattedMessage
            id='Forms.ADDRESS'
            description='Label for address'
            defaultMessage='Address'
        />
    ),
    IDENTIFIER: (
        <FormattedMessage
            id='Forms.IDENTIFIER'
            description='Label for identifier'
            defaultMessage='Identifier'
        />
    ),
    CONTACT: (
        <FormattedMessage
            id='Forms.CONTACT'
            description='Label for contact'
            defaultMessage='Contact'
        />
    ),
    CONTACT_INFO: (
        <FormattedMessage
            id='Forms.CONTACT_INFO'
            description='Label for contact info'
            defaultMessage='Contact info'
        />
    ),
    USER: {
        CREATE: (
            <FormattedMessage
                id='Forms.USER.CREATE'
                description='Create message for user form'
                defaultMessage='Create User'
            />
        )
    },
    CREATE_OBJECT: formattedCreateMessage,
    UPDATE_OBJECT: formattedUpdateMessage,
    ADDRESS_LINE: formattedAddressLine,
    DELETE_OBJECT: formattedDelete,
    MODULE: (
        <FormattedMessage
            id='Forms.MODULE'
            description='Label for module'
            defaultMessage='Module'
        />
    )
};

function formattedAddressLine(number: string): React.ReactNode {
    return (
        <FormattedMessage
            id='Forms.ADDRESS_LINE'
            description='Label for address line'
            defaultMessage='Address line {number}'
            values={{
                number: number
            }}
        />
    );
}

function formattedCreateMessage(text: string): React.ReactNode {
    return (
        <FormattedMessage
            id='Forms.CREATE'
            description='Text for create with variable'
            defaultMessage='Create {text}'
            values={{
                text: text
            }}
        />
    );
}

function formattedUpdateMessage(text: string): React.ReactNode {
    return (
        <FormattedMessage
            id='Forms.UPDATE'
            description='Text for update with variable'
            defaultMessage='Update {text}'
            values={{
                text: text
            }}
        />
    );
}

function formattedEmail(text?: string): React.ReactNode {
    return (
        <FormattedMessage
            id='Forms.EMAIL'
            description='The label for the email input'
            defaultMessage='Email {text}'
            values={{ text: text }}
        />
    );
}

function formattedDelete(property: string, name?: string): React.ReactNode {
    return (
        <FormattedMessage
            id='Forms.DELETE'
            description='delete message'
            defaultMessage='You are about to delete {property} {name}'
            values={{
                property: property,
                name: name
            }}
        />
    );
}
