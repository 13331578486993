import { CheckboxInput, NumberInput, TextInput } from '@frontend/basic-forms';
import { ClassType } from '@frontend/common';
import { ModalFooter } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';

import { Workflow, WorkflowType } from '@frontend/workflow/types';
import WorkflowTypeSelect from '../workflow-type-select/workflow-type-select.component';
import useCreateWorkflowForm from './create-workflow-form.controller';

const AccountSelect = React.lazy(() => import('@frontend/account/common').then((module) => ({ default: module.AccountSelect })));

const ID = 'create-workflow-form';
export interface Props {
    onClose: () => void;
    callback?: (workflow: Workflow) => void;
    dispatch?: ThunkDispatch<any, any, Action>;
}

export const CreateWorkflowForm = (props: Props) => {
    const viewProps = useCreateWorkflowForm(props);

    return (
        <div id={ID}>
            <form onSubmit={viewProps.onSubmit}>
                <div className='modal-header'>
                    <h4>Create workflow</h4>
                </div>
                <div className='modal-body'>
                    <AccountSelect
                        required
                        submitted={viewProps.submitted}
                        onChange={(val) => {
                            viewProps.changeFormValid({ ...viewProps.formValid, account: val != null });
                            viewProps.changeAccountId(val);
                        }}
                        value={viewProps.accountId}
                        dispatch={props.dispatch}
                        allowURLChange={false}
                    />
                    <TextInput
                        id={ID + '-nameInput'}
                        label={
                            <FormattedMessage
                                id='workflows.forms.createWorkflow.nameInput.label'
                                description='The label for the name input on the create workflow form.'
                                defaultMessage='Name'
                            />
                        }
                        errorMessage={
                            <FormattedMessage
                                id='workflow.forms.createWorkflow.nameInput.errorMessage'
                                description='The default error message when the name is not correctly filled in on the workflow form.'
                                defaultMessage='Please fill in a valid name.'
                            />
                        }
                        value={viewProps.formValues.name}
                        onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, name: value })}
                        required
                        isValidCallback={(valid) => {
                            viewProps.changeFormValid({ ...viewProps.formValid, name: valid });
                        }}
                        submitted={viewProps.submitted}
                    />
                    <div className='d-flex flex-row'>
                        <WorkflowTypeSelect
                            className='w-50 me-1'
                            submitted={viewProps.submitted}
                            onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, type: (value as any)?.value as WorkflowType })}
                            value={viewProps.formValues.type}
                        />
                        <NumberInput
                            id={ID + '-revisionInput'}
                            className='w-50 ms-1'
                            label='Revision'
                            submitted={viewProps.submitted}
                            value={viewProps.formValues.revision}
                            onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, revision: value })}
                        />
                    </div>

                    <CheckboxInput
                        id={ID + '-activeInput'}
                        className='w-50'
                        label='Active'
                        submitted={viewProps.submitted}
                        value={viewProps.formValues.active}
                        onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, active: value })}
                    />
                </div>
                <ModalFooter
                    justifyContent='between'
                    buttons={[
                        {
                            hide: false,
                            text: CommonMessage.BUTTONS.CANCEL,
                            type: ClassType.SECONDARY,
                            onClick: (e) => {
                                e?.preventDefault();
                                props.onClose();
                            }
                        },
                        {
                            hide: false,
                            text: CommonMessage.BUTTONS.SUBMIT,
                            type: ClassType.SUCCESS,
                            icon: FaPlus,
                            buttonType: 'submit'
                        }
                    ]}
                />
            </form>
        </div>
    );
};
