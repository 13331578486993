import { Locale } from '@frontend/common';
import { ContactClient } from '@frontend/contact/api';
import { useState } from 'react';

import { ContactIdEmailProps } from './contact-id-email.component';

interface ViewProps {
    submitted: boolean;
    formValid: boolean;
    email: string;
    changeEmail: (value: string) => void;
    changeFormValid: (value: boolean) => void;
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}

const useContactIdEmail = (props: ContactIdEmailProps): ViewProps => {
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const [email, changeEmail] = useState<string>('');
    const [formValid, changeFormValid] = useState<boolean>(false);

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (formValid) {
            changeSubmitted(true);
            ContactClient.postContact({
                alias: email,
                email: email,
                language: Locale.ENGLISH
            })
                .then((res) => {
                    props.callback({
                        ...props.variable,
                        value: res.id
                    });
                })
                .finally(() => changeSubmitted(false));
        }
    };
    return {
        submitted,
        formValid,
        email,
        changeEmail,
        changeFormValid,
        onSubmit
    };
};

export default useContactIdEmail;
