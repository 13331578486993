import { DefaultModal, DefaultModalProps, ModalHeader, ModalType } from '@frontend/elements';
import { EventEntityType } from '@frontend/events/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

import { Contact } from '@frontend/contact/types';
import { ContactDetail } from '../contact-detail.component';
import { useContactDetailModal } from './contact-detail-modal.controller';

const EventTable = React.lazy(() => import('@frontend/events/components').then((module) => ({ default: module.EventTable })));

const ID = 'contact-detail-modal';

export interface ContactDetailModalProps extends DefaultModalProps {
    contact: Contact;
    dispatch: ThunkDispatch<any, any, Action>;
}

export const ContactDetailModal = (props: ContactDetailModalProps) => {
    const viewProps = useContactDetailModal(props);
    return (
        <DefaultModal
            type={ModalType.PANE}
            id={ID}
            {...props}>
            <ModalHeader
                id='contact-detail-modal-header'
                handleClose={props.handleClose}
                title={`${props.contact.first_name} ${props.contact.last_name}`}
                handleView={viewProps.viewDetails}
            />
            <div className='modal-body'>
                <ContactDetail contact={props.contact} />
            </div>
            <EventTable
                entity_id={props.contact.id}
                entity_type={EventEntityType.CONTACT}
                dispatch={props.dispatch}
                limit={5}
                hidePagination
                hideActions
                title
            />
        </DefaultModal>
    );
};
