import { useFloatingTree, useListItem } from '@floating-ui/react';
import React from 'react';

import { MenuContext } from '../dropdown.component';

interface ViewProps {
    menu: {
        getItemProps: (userProps?: React.HTMLProps<HTMLElement>) => Record<string, unknown>;
        activeIndex: number | null;
        setActiveIndex: React.Dispatch<React.SetStateAction<number | null>>;
        setHasFocusInside: React.Dispatch<React.SetStateAction<boolean>>;
        isOpen: boolean;
    };
    item: {
        ref: (node: HTMLElement | null) => void;
        index: number;
    };
    isActive: boolean;
    tree: any | null;
}

const useDropdownItem = (): ViewProps => {
    const menu = React.useContext(MenuContext);
    const item = useListItem();
    const tree = useFloatingTree();
    const isActive = item.index === menu.activeIndex;
    return {
        menu,
        item,
        tree,
        isActive
    };
};

export default useDropdownItem;
