import { APIClient } from "@frontend/api-utils";

const endpoint = '/edge-api/v1';

export class DeviceEnumClient extends APIClient {
    public static async fetchDeviceStates(): Promise<string[]> {
        const response = await this.fetch(`${endpoint}/device-states`);
        return await this.handleResponse<string[]>(response);
    }

    public static async fetchDeviceTriggers(): Promise<string[]> {
        const response = await this.fetch(`${endpoint}/device-triggers`);
        return await this.handleResponse<string[]>(response);
    }

    public static async fetchDeviceActions(): Promise<string[]> {
        const response = await this.fetch(`${endpoint}/device-actions`);
        return await this.handleResponse<string[]>(response);
    }
}