export interface TableSubRows<T> {
    subRows: TableSubRows<T>[];
}

export function createSubRowsFromParent<T extends { id: string; parent_id: string | null }>(data: T[]): TableSubRows<T>[] {
    const dataMap: TableSubRows<T>[] = [];
    const idMap: { [key: string]: TableSubRows<T> } = {};

    for (const item of data) {
        const tableRow: TableSubRows<T> = {
            subRows: [],
            ...item
        };
        idMap[item.id] = tableRow;
    }

    for (const item of data) {
        if (!item.parent_id) {
            dataMap.push(idMap[item.id]);
        } else {
            if (idMap[item.parent_id]) {
                idMap[item.parent_id].subRows.push(idMap[item.id]);
            } else {
                dataMap.push(idMap[item.id]);
            }
        }
    }

    return dataMap;
}

export function createSubRowsFromChildren<T extends { children: Array<T> }>(data: T[]): any {
    return data.map((item) => {
        const newItem = { ...item, subRows: [] };

        if (newItem.children) {
            newItem.subRows = createSubRowsFromChildren(newItem.children);
        }

        return newItem;
    });
}
