import { Logger } from '@frontend/Logger';
import { addTransaction, removeTransaction, updateTransaction } from '@frontend/transaction/redux';
import { Transaction } from '@frontend/transaction/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';


enum MessageTypes {
    UPDATED = 'transaction_updated',
    DELETED = 'transaction_deleted',
    CREATED = 'transaction_created'
}

export class TransactionEventHandler {
    #dispatch: ThunkDispatch<any, any, Action>;

    constructor(dispatch: ThunkDispatch<any, any, Action>) {
        this.#dispatch = dispatch;
    }
    public onMessageEvent(event: MessageEvent<string>): void {
        const payload = JSON.parse(event.data) as { message: MessageTypes; data: Transaction; changes?: any };
        switch (payload.message) {
            case MessageTypes.UPDATED:
                this.onUpdate(payload);
                break;
            case MessageTypes.DELETED:
                this.onDelete(payload);
                break;
            case MessageTypes.CREATED:
                this.onCreate(payload);
                break;
        }
    }

    private onUpdate(payload: { message: MessageTypes; data: Transaction; changes?: any }) {
        Logger.log('Updating transaction in store', {}, payload.data);
        this.#dispatch(updateTransaction(payload.data));
    }

    private onDelete(payload: { message: MessageTypes; data: Transaction }) {
        Logger.log('Removing transaction from store', {}, payload.data);
        this.#dispatch(removeTransaction(payload.data.id));
    }

    private onCreate(payload: { message: MessageTypes; data: Transaction; changes?: any }) {
        Logger.log('Adding transaction to store', {}, payload.data);
        this.#dispatch(addTransaction(payload.data));
    }
}
