import { Logger } from '@frontend/Logger';

import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Column } from 'react-table';

import { authenticationMethodsStore, deleteBasicAuth, fetchBasicAuths } from '@frontend/authentication-methods/redux';
import { BasicAuth } from '@frontend/authentication-methods/types';
import { useAppSelector } from '@frontend/common';
import { useSelector } from 'react-redux';
import BasicAuthColumns from './basic-auth-columns';
import { BasicAuthListProps } from './basic-auth-list.component';

interface ViewProps {
    data: BasicAuth[];
    columns: Column<BasicAuth>[];
    pageCount: number;
    page: number;
    changePage: React.Dispatch<React.SetStateAction<number>>;
    pageSize: number;
    changePageSize: React.Dispatch<React.SetStateAction<number>>;
    showCreateModal: boolean;
    changeShowCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
    showDeleteModal: boolean;
    changeShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
    showUpdateModal: boolean;
    changeShowUpdateModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedEntity: BasicAuth | undefined;
    changeSelectedEntity: React.Dispatch<React.SetStateAction<BasicAuth | undefined>>;
    removeBasicAuth: () => void;
}

const useBasicAuthList = (props: BasicAuthListProps): ViewProps => {
    const basicAuthState = useAppSelector(useSelector, authenticationMethodsStore).basicAuths;
    const [page, changePage] = useState<number>(0);
    const [pageSize, changePageSize] = useState<number>(25);
    const { userId } = useParams();
    const [showCreateModal, changeShowCreateModal] = useState<boolean>(false);
    const [showDeleteModal, changeShowDeleteModal] = useState<boolean>(false);
    const [showUpdateModal, changeShowUpdateModal] = useState<boolean>(false);
    const [selectedEntity, changeSelectedEntity] = useState<BasicAuth | undefined>(undefined);

    useEffect(() => {
        props.dispatch(fetchBasicAuths({ index: page.toString(), size: pageSize.toString(), ...(userId && { user_id: userId }) }));
    }, [page, pageSize, userId]);

    const data = useMemo(() => {
        if (!basicAuthState.basicAuthByUser || !userId || !basicAuthState.basicAuthByUser[userId]) return [];
        else return basicAuthState.basicAuthByUser[userId].results;
    }, [basicAuthState, userId]);

    const columns = useMemo(() => BasicAuthColumns, []);

    const removeBasicAuth = () => {
        if (!selectedEntity || !userId) return;
        props.dispatch(deleteBasicAuth({ userId: userId, basicAuthId: selectedEntity.id }))
            .then(() => changeShowDeleteModal(false))
            .catch(() => {
                Logger.error(`Error deleting ${selectedEntity.email} basic auth`, undefined, { selectedEntity });
            });
    };

    return {
        changePage,
        changePageSize,
        columns,
        data,
        page,
        pageCount: basicAuthState.basicAuths ? Math.ceil(basicAuthState.basicAuths.count / pageSize) : 1,
        pageSize,
        changeShowCreateModal,
        showCreateModal,
        changeShowDeleteModal,
        changeShowUpdateModal,
        showDeleteModal,
        showUpdateModal,
        changeSelectedEntity,
        selectedEntity,
        removeBasicAuth
    };
};

export default useBasicAuthList;
