import { Logger } from '@frontend/Logger';
import { useAppSelector } from '@frontend/common';
import { fetchProduct, productStore } from '@frontend/product/redux';
import { Product } from '@frontend/product/types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { navigationStore } from '../../redux/user-interface-navigation-slice';
import { UserInterfaceTemplateProps } from '../../user-interface-template-props';

interface ViewProps {
    product: Product | null;
}

const usePackageProcessing = (props: UserInterfaceTemplateProps): ViewProps => {
    const navigationState = useAppSelector(useSelector, navigationStore);
    const cache = props.cache || navigationState.cache;
    const productState = useAppSelector(useSelector, productStore);
    const [product, changeProduct] = useState<Product | null>(null);

    useEffect(() => {
        if (cache && cache.account_id && cache.product_id) {
            props.dispatch(fetchProduct({ accountId: cache.account_id, productId: cache.product_id }));
        } else Logger.error('Not enough information provided to fetch product.', {}, { accountId: cache?.account_id, productId: cache?.product_id });
    }, []);

    useEffect(() => {
        const found = productState.unordered.find((s) => s.id == cache?.product_id);
        if (found) changeProduct(found);
    }, [cache, productState]);

    return { product };
};

export default usePackageProcessing;
