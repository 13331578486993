import { ApiError } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IntegrationTestClient } from '../api/integration-test-client';
import { IntegrationTest, IntegrationTestListResponse, IntegrationTestOverviewListResponse } from '../integration-test';

interface IntegrationTestState {
    unordered: IntegrationTest[];
    integrationTests: IntegrationTestListResponse | null;
    integrationTestsOverview: IntegrationTestOverviewListResponse | null;
    status: SliceStatus;
}

const initialState: IntegrationTestState = {
    unordered: [],
    integrationTests: null,
    integrationTestsOverview: null,
    status: SliceStatus.INIT
};

const integrationTestSlice = createSlice({
    name: 'integrationTestSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchIntegrationTestsOverview.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchIntegrationTestsOverview.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                state.integrationTestsOverview = action.payload;
            });
    }
});

export const fetchIntegrationTestsOverview = createAsyncThunk<IntegrationTestOverviewListResponse>('fetchIntegrationTests', async (_, { rejectWithValue }) => {
    try {
        return await IntegrationTestClient.fetchIntegrationTestsOverview();
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const integrationTestStore = { integration_tests: integrationTestSlice.reducer };
