import { useEffect, useState } from 'react';

import { PasswordInputError, Props } from './password-input.component';

interface ViewProps {
    valid: boolean;
    touched: boolean;
    visible: boolean;
    error: PasswordInputError;
    strength: number;
    style: {
        width: string;
    };
    color: 'danger' | 'warning' | 'success';
    isValid: () => boolean;
    getClassName: () => string;
    calcStrengthPercentage: (value: string) => number;
    changeTouched: React.Dispatch<React.SetStateAction<boolean>>;
    changeVisible: React.Dispatch<React.SetStateAction<boolean>>;
    changeStrength: React.Dispatch<React.SetStateAction<number>>;
}

enum InputClass {
    normal = 'form-control',
    success = 'form-control is-valid',
    error = 'form-control is-invalid'
}

const usePasswordInput = (props: Props): ViewProps => {
    const [touched, changeTouched] = useState<boolean>(false);
    const [visible, changeVisible] = useState<boolean>(false);
    const [valid, changeValid] = useState<boolean>(true);
    const [strength, changeStrength] = useState<number>(0);
    const [error, changeError] = useState<PasswordInputError>(PasswordInputError.NONE);
    const style = { width: strength + '%' };
    const color = strength < 50 ? 'danger' : strength < 90 ? 'warning' : 'success';

    const isValid = (): boolean => {
        let error = PasswordInputError.NONE;
        if (error === PasswordInputError.NONE && props.required === true && (props.value === '' || props.value === undefined))
            error = PasswordInputError.REQUIRED;
        if (props.toRepeat !== undefined && props.toRepeat != props.value) error = PasswordInputError.REPEAT_NOT_IDENTICAL;
        if (props.strength && !(props.value && strength === 100)) error = PasswordInputError.STRENGTH;
        if (props.compare !== undefined && props.value !== undefined && compareTwoStrings(props.value, props.compare) >= 0.3)
            error = PasswordInputError.SIMILARITY;
        if (props.compareMultiple !== undefined && props.value !== undefined && props.compareMultiple.find((s) => compareTwoStrings(props.value!, s) >= 0.3))
            error = PasswordInputError.SIMILARITY;
        if (props.valid) {
            error = PasswordInputError.NONE;
        }
        changeError(error);
        changeValid(error === PasswordInputError.NONE);
        return error === PasswordInputError.NONE;
    };

    const calcStrengthPercentage = (value: string): number => {
        let strength = 0;

        if (value.length >= 8) strength += 20;
        if (/[A-Z]/.test(value)) strength += 20;
        if (/[a-z]/.test(value)) strength += 20;
        if (/\d/.test(value)) strength += 20;
        // eslint-disable-next-line no-useless-escape
        if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value)) strength += 20;
        return strength;
    };

    const getClassName = (): string => {
        if (props.noConditionedStyling) {
            return 'form-control';
        }
        if (!touched) {
            return InputClass.normal; // Untouched: always return normal
        }
        if (!valid) {
            return InputClass.error; // Invalid: always return error
        }
        // Handle both empty and non-empty values for success state
        return props.value && props.value.trim() !== '' ? InputClass.success : InputClass.normal;
    };

    useEffect(() => {
        if (props.isValidCallback) {
            props.isValidCallback(isValid());
        } else isValid();
    }, [props.value, props.compare, props.compareMultiple, props.toRepeat]);

    return {
        valid,
        touched,
        visible,
        error,
        strength,
        style,
        color,
        isValid,
        getClassName,
        calcStrengthPercentage,
        changeTouched,
        changeVisible,
        changeStrength
    };
};

export default usePasswordInput;

export function compareTwoStrings(first: string, second: string) {
    first = first.replace(/\s+/g, '');
    second = second.replace(/\s+/g, '');

    if (first === second) return 1; // identical or empty
    if (first.length < 2 || second.length < 2) return 0; // if either is a 0-letter or 1-letter string

    const firstBigrams = new Map();
    for (let i = 0; i < first.length - 1; i++) {
        const bigram = first.substring(i, i + 2);
        const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) + 1 : 1;

        firstBigrams.set(bigram, count);
    }

    let intersectionSize = 0;
    for (let i = 0; i < second.length - 1; i++) {
        const bigram = second.substring(i, i + 2);
        const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) : 0;

        if (count > 0) {
            firstBigrams.set(bigram, count - 1);
            intersectionSize++;
        }
    }

    return (2.0 * intersectionSize) / (first.length + second.length - 2);
}
