import { CreateNotificationTemplate } from '@frontend/notification-service/types';
import { useState } from 'react';

import { NotificationTemplateClient } from '@frontend/notification-service/api';
import { CreateNotificationTemplateProps } from './create-notification-template.component';

interface ViewProps {
    submitted: boolean;
    formValues: CreateNotificationTemplate;
    formValid: FormValid;
    onSubmit: (e: any) => void;
    changeFormValues: React.Dispatch<React.SetStateAction<CreateNotificationTemplate>>;
    changeFormValid: React.Dispatch<React.SetStateAction<FormValid>>;
    account: string | null;
    changeAccount: (value: React.SetStateAction<string | null>) => void;
}

interface FormValid {
    name: boolean;
    description: boolean;
}

const useCreateNotificationTemplate = (props: CreateNotificationTemplateProps): ViewProps => {
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const [formValues, changeFormValues] = useState<CreateNotificationTemplate>({ description: '', name: '' });
    const [formValid, changeFormValid] = useState<FormValid>({
        name: false,
        description: false
    });
    const [account, changeAccount] = useState<string | null>(null);
    const onSubmit = (e: any) => {
        e.preventDefault();
        changeSubmitted(true);
        if (!account) return;
        NotificationTemplateClient.postAccountNotificationTemplate(account, formValues).then(props.onClose);
    };

    return {
        changeFormValid,
        changeFormValues,
        formValid,
        formValues,
        onSubmit,
        submitted,
        account,
        changeAccount
    };
};

export default useCreateNotificationTemplate;
