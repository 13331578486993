import { PubSubConnection } from '@frontend/pub-sub';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

import { SlotEventHandler } from './slot-event-handler';

export class SlotServiceEventListener extends PubSubConnection {
    private static instance: SlotServiceEventListener | null = null;
    #slotEventHandler: SlotEventHandler;

    private constructor(dispatch: ThunkDispatch<any, any, Action>) {
        super('slot');
        this.#slotEventHandler = new SlotEventHandler(dispatch);
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>): SlotServiceEventListener {
        if (this.instance == null) {
            this.instance = new SlotServiceEventListener(dispatch);
        }
        return this.instance;
    }

    protected override onMessageEvent(event: MessageEvent<string>): void {
        this.#slotEventHandler.onMessageEvent(event);
    }
}
