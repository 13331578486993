export enum EntityType {
    ACCESS_LOG = 'access_log',
    ACCOUNT = 'account',
    ACTUATOR = 'actuator',
    AUTHENTICATION = 'authentication',
    BADGE = 'badge',
    CATALOG = 'catalog',
    CHANGE = 'change',
    CONTACT = 'contact',
    CONSTRAINT = 'constraint',
    DEVICE = 'device',
    DRIVER = 'driver',
    EVENT = 'event',
    MODULE = 'module',
    MOVEMENT = 'movement',
    NOTIFICATION = 'notification',
    OCCUPANT = 'occupant',
    PACKAGE = 'package',
    PRODUCT = 'product',
    RAKINDA = 'rakinda',
    SEED = 'seed',
    SERVER = 'server',
    SETTING = 'setting',
    SLOT = 'slot',
    SPOT = 'spot',
    TEST = 'test',
    TRANSACTION = 'transaction',
    TRANSLATION = 'translation',
    USER = 'user',
    USER_INTERFACE = 'user_interface',
    WARE = 'ware',
    WORKFLOW = 'workflow',
    CERTIFICATE = 'certificate',
    NOTIFICATION_TEMPLATE = 'notification_template',
    INTEGRATION_TEST = 'integration_test',
    BASIC_AUTH = 'basic_auth',
    PRODUCT_CATALOG = 'product_catalog',
    PRODUCT_CATEGORY = 'product_category',
    SETTINGS = 'settings',
    ALL = '*',
    UNKNOWN = ''
}
