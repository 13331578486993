import { useAppSelector } from '@frontend/common';
import { PackageClient } from '@frontend/package/api';
import { Package, PackageType } from '@frontend/package/types';
import { SlotClient } from '@frontend/slot/api';
import { Slot } from '@frontend/slot/types';
import { WareClient } from '@frontend/stock/api';
import { Ware } from '@frontend/stock/types';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { navigationStore } from '../../../redux/user-interface-navigation-slice';
import { ProductDetailProps } from './product-detail.component';

interface ViewProps {
    isLoading: boolean;
    type: PackageType | null;
    productPackages: Package[] | null;
    wares: Ware[] | null;
    slots: Slot[] | null;
    selectedSlot: Slot | null;
    changeSlot: (slot: Slot | null) => void;
    amountInStock: number | null;
    amount: number | null;
    initialAmount: number | null;
    disabled: boolean;
    selectedSlotStock: number | null | undefined;
    changeAmount: React.Dispatch<React.SetStateAction<number | null>>;
    switchType: (newType: PackageType) => void;
    addToCart: () => Promise<void>;
    clear: () => void;
}

const useProductDetail = (props: ProductDetailProps): ViewProps => {
    const navigationState = useAppSelector(useSelector, navigationStore);
    const [isLoading, changeIsLoading] = useState<boolean>(false);
    const [type, changeType] = useState<PackageType | null>(null);
    const [productPackages, changeProductPackages] = useState<Package[] | null>(null);
    const [initialAmount, changeInitialAmount] = useState<number | null>(null);
    const [wares, changeWares] = useState<Ware[] | null>(null);
    const [slots, changeSlots] = useState<Slot[] | null>(null);
    const [selectedSlot, changeSelectedSlot] = useState<Slot | null>(null);
    const amountInStock = useMemo(() => {
        if (!wares) return null;
        let amount = 0;
        wares.forEach((ware) => {
            amount += ware.quantity;
        });
        return amount;
    }, [props.product, wares]);
    const selectedSlotStock = useMemo(() => {
        if (!wares || selectedSlot === null) return null;
        return wares.find((w) => w.slot_id === selectedSlot.id)?.quantity;
    }, [props.product, wares, selectedSlot]);
    const [amount, changeAmount] = useState<number | null>(null);

    useEffect(() => {
        if (productPackages && type === null && productPackages.length > 0) {
            changeType(productPackages[0].type);
        }
    }, [productPackages]);

    useEffect(() => {
        const relevantPackages = props.packages.filter((package_) => package_.product_id === props.product.id);
        changeProductPackages(relevantPackages);
        const initialAmount = relevantPackages.reduce((total, package_) => total + package_.quantity, 0);
        changeInitialAmount(initialAmount);
        changeAmount(initialAmount);
        WareClient.fetchWares({ index: '0', size: '500', product_id: props.product.id }).then((response) => {
            changeWares(response.results);
            SlotClient.resolveSlots(response.results.map((w) => w.slot_id)).then((response) => {
                changeSlots(response.results);
                if (response.count === 1) changeSelectedSlot(response.results[0]);
            });
        });
    }, [props.product.id, props.packages]);

    const switchType = (newType: PackageType) => {
        if (newType === type) return;
        if (newType === PackageType.DROP_OFF && !props.userInterface.data.dropPackageWorkflowId) return;
        if (newType === PackageType.PICK_UP && !props.userInterface.data.pickPackageWorkflowId) return;
        const toRemove: string[] = [];
        productPackages?.forEach((package_) => {
            toRemove.push(package_.id);
            PackageClient.deleteAccountTransactionPackage(package_.account_id, package_.transaction_id, package_.id);
        });
        props.changePackages((packages) => packages.filter((package_) => !toRemove.includes(package_.id)));
        changeType(newType);
        changeSelectedSlot(null);
    };

    const changeSlot = (slot: Slot | null) => {
        const toRemove: string[] = [];
        productPackages?.forEach((package_) => {
            toRemove.push(package_.id);
            PackageClient.deleteAccountTransactionPackage(package_.account_id, package_.transaction_id, package_.id);
        });
        props.changePackages((packages) => packages.filter((package_) => !toRemove.includes(package_.id)));
        changeSelectedSlot(slot);
    };

    const clear = () => {
        const toRemove: string[] = [];
        productPackages?.forEach((package_) => {
            toRemove.push(package_.id);
            PackageClient.deleteAccountTransactionPackage(package_.account_id, package_.transaction_id, package_.id);
        });
        props.changePackages((packages) => packages.filter((package_) => !toRemove.includes(package_.id)));
        changeSelectedSlot(null);
        props.onClose();
    };

    const addToCart = async () => {
        let transaction = props.transaction;
        if (transaction === null) transaction = await props.createTransaction();
        if (initialAmount === null || amount === null || productPackages === null || wares === null) return;
        changeIsLoading(true);
        const updates: Promise<Package>[] = [];
        const removeIds: string[] = [];

        if (initialAmount > amount) {
            //Same logic for pick and drop
            //Remove packages
            let toRemove = initialAmount - amount;
            productPackages?.forEach((package_) => {
                if (toRemove > 0) {
                    if (package_.quantity <= toRemove) {
                        removeIds.push(package_.id);
                        PackageClient.deleteAccountTransactionPackage(package_.account_id, package_.transaction_id, package_.id);
                        toRemove -= package_.quantity;
                    } else {
                        updates.push(
                            PackageClient.patchAccountTransactionPackage(package_.account_id, package_.transaction_id, package_.id, {
                                quantity: package_.quantity - toRemove
                            })
                        );
                        toRemove = 0;
                    }
                }
            });
        } else if (initialAmount < amount) {
            //Add packages
            if (type === PackageType.DROP_OFF) {
                const toAdd = amount - initialAmount;
                if (productPackages.length > 0) {
                    const package_ = productPackages[0];
                    updates.push(
                        PackageClient.patchAccountTransactionPackage(package_.account_id, package_.transaction_id, package_.id, {
                            quantity: package_.quantity + toAdd
                        })
                    );
                } else if (wares.length > 0) {
                    updates.push(
                        PackageClient.postAccountTransactionPackage(transaction.account_id, transaction.id, {
                            product_id: props.product.id,
                            quantity: toAdd,
                            slot_id: wares[0].slot_id,
                            module_id: wares[0].module_id,
                            spot_id: wares[0].spot_id,
                            workflow_id: props.userInterface.data.dropPackageWorkflowId,
                            user_id: navigationState.user?.id,
                            type: PackageType.DROP_OFF
                        })
                    );
                }
            } else if (type === PackageType.PICK_UP) {
                let toAdd = amount - initialAmount;
                productPackages?.forEach((package_) => {
                    if (toAdd > 0) {
                        const found = wares?.find((ware) => ware.slot_id === package_.slot_id);
                        if (found && package_.quantity < found.quantity) {
                            const available = found.quantity - package_.quantity;
                            const adding = toAdd >= available ? available : toAdd;
                            updates.push(
                                PackageClient.patchAccountTransactionPackage(package_.account_id, package_.transaction_id, package_.id, {
                                    quantity: package_.quantity + adding
                                })
                            );
                            toAdd -= adding;
                        }
                    }
                });
                if (toAdd > 0) {
                    wares
                        ?.filter((ware) => !productPackages?.find((package_) => package_.slot_id === ware.slot_id))
                        .forEach((ware) => {
                            if (toAdd > 0) {
                                const adding = toAdd >= ware.quantity ? ware.quantity : toAdd;
                                updates.push(
                                    PackageClient.postAccountTransactionPackage(transaction!.account_id, transaction!.id, {
                                        product_id: props.product.id,
                                        quantity: adding,
                                        slot_id: ware.slot_id,
                                        module_id: ware.module_id,
                                        spot_id: ware.spot_id,
                                        workflow_id: props.userInterface.data.pickPackageWorkflowId,
                                        user_id: navigationState.user?.id,
                                        type: PackageType.PICK_UP
                                    })
                                );
                                toAdd -= adding;
                            }
                        });
                }
            }
        }

        await Promise.all(updates).then((packages) => {
            const packagesToAdd = packages.filter((p) => !props.packages.find((p2) => p2.id === p.id));
            const packagesToUpdate = props.packages.filter((p) => !removeIds.includes(p.id)).map((p) => packages.find((p2) => p2.id === p.id) ?? p);
            props.changePackages([...packagesToUpdate, ...packagesToAdd]);
            props.onClose();
            changeIsLoading(false);
        });
    };

    return {
        isLoading,
        type,
        productPackages,
        wares,
        slots,
        selectedSlot,
        changeSlot,
        amountInStock,
        amount,
        initialAmount,
        disabled: productPackages === null || initialAmount === amount || wares?.length === 0 || isLoading,
        selectedSlotStock,
        changeAmount,
        switchType,
        addToCart,
        clear
    };
};

export default useProductDetail;
