import { WorkflowFieldType } from '@frontend/workflow-variable/types';
import React, { useMemo } from 'react';

import ContactIdInput from './inputs/contact-id-input/contact-id-input.component';
import { UnknownVariableFormProps } from './unknown-variable-form.component';

interface ViewProps {
    input: React.ReactNode;
}

const useUnknownVariableForm = (props: UnknownVariableFormProps): ViewProps => {
    const input = useMemo(() => {
        switch (props.variable.type) {
            case WorkflowFieldType.CONTACT_ID:
                return <ContactIdInput {...props} />;
            default:
                return null;
        }
    }, [props.variable]);
    return { input };
};

export default useUnknownVariableForm;
