import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface ResolveState {
    resolved: {
        [key: string]: {
            //Entity type
            [key: string]: {
                //Entity id
                id: string;
                [key: string]: any;
            };
        };
    } | null;
}

const initialState: ResolveState = {
    resolved: null
};

export const resolveSlice = createSlice({
    name: 'resolve',
    initialState,
    reducers: {
        seed(state, action: PayloadAction<{ key: string; value: { id: string; [key: string]: any }[] }>) {
            if (state.resolved == null) {
                state.resolved = {};
            }
            if (state.resolved[action.payload.key] == null) {
                state.resolved[action.payload.key] = {};
            }
            action.payload.value.forEach((v) => (state.resolved![action.payload.key]![v.id] = v));
        }
    }
});

export const resolveStore = { resolved: resolveSlice.reducer };
export const { seed } = resolveSlice.actions;
