import { TextInput } from '@frontend/basic-forms';
import { WorkflowVariable } from '@frontend/workflow/types';
import React from 'react';

import { TransactionVariable } from '../../../../transaction-variables';

const ID = 'string-field-input';
export interface StringFieldInputProps {
    submitted: boolean;
    variable: TransactionVariable | WorkflowVariable;
    onChange: (variable: TransactionVariable | WorkflowVariable) => void;
}

const StringFieldInput = (props: StringFieldInputProps) => {
    return (
        <TextInput
            id={ID}
            label={props.variable.name}
            submitted={props.submitted}
            value={typeof props.variable.value === 'string' ? props.variable.value : ''}
            onChange={(value) => {
                props.onChange({ ...props.variable, value: value.toString() });
            }}
        />
    );
};

export default StringFieldInput;
