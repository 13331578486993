import { Logger } from '@frontend/Logger';
import { useAppSelector } from '@frontend/common';
import { ConstraintClient } from '@frontend/constraint/api';
import { ConstraintType } from '@frontend/constraint/types';
import { PackageVariableClient } from '@frontend/package-variables/api';
import { PackageClient } from '@frontend/package/api';
import { Package, PackageType } from '@frontend/package/types';
import { Slot } from '@frontend/slot/types';
import { TransactionVariableClient } from '@frontend/transaction-variables';
import { TransactionClient, TransactionWorkflowClient } from '@frontend/transaction/api';
import { Transaction, TransactionTriggerName, TransactionType } from '@frontend/transaction/types';
import { WorkflowVariableClient } from '@frontend/workflow-variable/api';
import { WorkflowVariable } from '@frontend/workflow-variable/types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { navigationStore } from '../../redux/user-interface-navigation-slice';
import { CreateTransactionProps } from './create-transaction.component';

interface ViewProps {
    transaction: Transaction | null;
    transactionVars: WorkflowVariable[] | null;
    dropPackageVars: WorkflowVariable[] | null;
    pickPackageVars: WorkflowVariable[] | null;
    slot: Slot | null;
    changeSlot: (slot: Slot | null) => void;
    submitVariable: (variable: WorkflowVariable) => void;
}

const useCreateTransaction = (props: CreateTransactionProps): ViewProps => {
    const navigationState = useAppSelector(useSelector, navigationStore);
    const cache = props.cache || navigationState.cache;

    const [slot, changeSlot] = useState<Slot | null>(null);
    const [transaction, changeTransaction] = useState<Transaction | null>(null);
    const [transactionVars, changeTransactionVars] = useState<WorkflowVariable[] | null>([]);
    const [dropPackage, changeDropPackage] = useState<Package | null>(null);
    const [dropPackageVars, changeDropPackageVars] = useState<WorkflowVariable[] | null>([]);
    const [pickPackage, changePickPackage] = useState<Package | null>(null);
    const [pickPackageVars, changePickPackageVars] = useState<WorkflowVariable[] | null>([]);

    useEffect(() => {
        if (cache && cache.account_id) {
            WorkflowVariableClient.fetchUnknownAccountWorkflowVariables(cache.account_id, props.userInterface.data.transactionWorkflowId).then((result) =>
                changeTransactionVars(result.results)
            );
            WorkflowVariableClient.fetchUnknownAccountWorkflowVariables(cache.account_id, props.userInterface.data.dropPackageWorkflowId).then((result) =>
                changeDropPackageVars(result.results)
            );
            WorkflowVariableClient.fetchUnknownAccountWorkflowVariables(cache.account_id, props.userInterface.data.pickPackageWorkflowId).then((result) =>
                changePickPackageVars(result.results)
            );
            TransactionClient.postAccountTransaction(
                { workflow_id: props.userInterface.data.transactionWorkflowId, driver_id: null, type: TransactionType.PUDO, user_id: navigationState.user?.id },
                cache.account_id
            ).then(changeTransaction);
        } else
            Logger.error('Not enough information provided to fetch workflow variables.', {}, { accountId: cache?.account_id, workflowId: cache?.workflow_id });
    }, []);

    useEffect(() => {
        if (slot && !dropPackage && !pickPackage) {
            ConstraintClient.postSpotConstraint(slot.spot_id, {
                type: ConstraintType.OCCUPANCY,
                strict: true,
                slot_id: slot.id,
                data: {
                    account_id: transaction!.account_id,
                    transaction_id: transaction!.id,
                    workflow_id: transaction!.workflow_id
                }
            });
            PackageClient.postAccountTransactionPackage(cache!.account_id, transaction!.id, {
                workflow_id: props.userInterface.data.dropPackageWorkflowId,
                spot_id: slot.spot_id,
                module_id: slot.module_id,
                slot_id: slot.id,
                type: PackageType.DROP_OFF,
                user_id: navigationState.user?.id
            }).then(changeDropPackage);
            PackageClient.postAccountTransactionPackage(cache!.account_id, transaction!.id, {
                workflow_id: props.userInterface.data.pickPackageWorkflowId,
                spot_id: slot.spot_id,
                module_id: slot.module_id,
                slot_id: slot.id,
                type: PackageType.PICK_UP,
                user_id: navigationState.user?.id
            }).then(changePickPackage);
        }
    }, [slot]);

    useEffect(() => {
        if (
            transactionVars &&
            transactionVars.length === 0 &&
            dropPackageVars &&
            dropPackageVars.length === 0 &&
            pickPackageVars &&
            pickPackageVars.length === 0
        ) {
            TransactionWorkflowClient.triggerTransactionState(cache!.account_id, transaction!.id, {
                trigger: TransactionTriggerName.TRANSACTION_PROCESS,
                source: null
            });
        }
    }, [transactionVars, dropPackageVars, pickPackageVars]);

    const submitVariable = (variable: WorkflowVariable) => {
        let found = transactionVars!.find((v) => v.id == variable.id);
        if (found) {
            TransactionVariableClient.postAccountTransactionVariable(cache!.account_id, transaction!.id, {
                value: found.value!,
                workflow_id: props.userInterface.data.transactionWorkflowId,
                name: found.name,
                type: found.type
            }).then(() => changeTransactionVars(transactionVars!.filter((v) => v.id === variable.id)));
            return;
        } else found = dropPackageVars?.find((v) => v.id == variable.id);
        if (found) {
            PackageVariableClient.postAccountTransactionPackageVariable(cache!.account_id, transaction!.id, dropPackage!.id, {
                value: found.value!,
                workflow_id: props.userInterface.data.dropPackageWorkflowId,
                name: found.name,
                type: found.type
            }).then(() => changeDropPackageVars(dropPackageVars!.filter((v) => v.id === variable.id)));
            return;
        } else found = pickPackageVars?.find((v) => v.id == variable.id);
        if (found) {
            PackageVariableClient.postAccountTransactionPackageVariable(cache!.account_id, transaction!.id, pickPackage!.id, {
                value: found.value!,
                workflow_id: props.userInterface.data.pickPackageWorkflowId,
                name: found.name,
                type: found.type
            }).then(() => changePickPackageVars(pickPackageVars!.filter((v) => v.id === variable.id)));
            return;
        }
    };

    return {
        transaction,
        transactionVars,
        dropPackageVars,
        pickPackageVars,
        slot,
        changeSlot,
        submitVariable
    };
};

export default useCreateTransaction;
