import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { BasicAuthClient } from '@frontend/authentication-methods/api';
import { BasicAuth, BasicAuthListResponse, BasicAuthQueryParams, CreateBasicAuth, UpdateBasicAuth } from '@frontend/authentication-methods/types';
import { SliceStatus } from '@frontend/common';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isArray, toNumber } from 'lodash';

export interface BasicAuthState {
    unordered: BasicAuth[];
    basicAuths: BasicAuthListResponse | null;
    basicAuthByUser: { [userId: string]: BasicAuthListResponse } | null;
    status: SliceStatus;
}

const initialState: BasicAuthState = {
    unordered: [],
    basicAuths: null,
    basicAuthByUser: null,
    status: SliceStatus.INIT
};

const basicAuthSlice = createSlice({
    name: 'basic-auth-slice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchBasicAuths.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchBasicAuths.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                const startPos = toNumber(action.meta.arg.size) * toNumber(action.meta.arg.index);
                const userId = action.meta.arg.user_id;
                if (userId && !isArray(userId)) {
                    if (!state.basicAuthByUser) {
                        state.basicAuthByUser = { [userId]: { ...action.payload, results: new Array(action.payload.count) } };
                        state.basicAuthByUser[userId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    } else if (state.basicAuthByUser && !state.basicAuthByUser[userId]) {
                        state.basicAuthByUser[userId] = { ...action.payload, results: new Array(action.payload.count) };
                        state.basicAuthByUser[userId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    } else {
                        if (action.payload.count != state.basicAuthByUser[userId].results.length) {
                            state.basicAuthByUser[userId].count = action.payload.count;
                            state.basicAuthByUser[userId].results = new Array(action.payload.count);
                        }
                        state.basicAuthByUser[userId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    }
                } else {
                    if (!state.basicAuths) {
                        state.basicAuths = { ...action.payload, results: new Array(action.payload.count) };
                        state.basicAuths.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    } else {
                        if (state.basicAuths.count != action.payload.count) {
                            state.basicAuths.count = action.payload.count;
                            state.basicAuths.results = new Array(action.payload.count);
                        }
                        state.basicAuths.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    }
                }

                state.unordered = [
                    ...state.unordered.filter((auth) => action.payload.results.find((a) => auth.id == a.id) == undefined),
                    ...action.payload.results
                ];
            })
            .addCase(fetchBasicAuth.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchBasicAuth.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                const userId = action.meta.arg.userId;
                if (!state.basicAuthByUser) {
                    state.basicAuthByUser = { [userId]: { count: 1, results: [action.payload] } };
                } else if (state.basicAuthByUser && !state.basicAuthByUser[userId]) {
                    state.basicAuthByUser[userId] = { count: 1, results: [action.payload] };
                } else {
                    const found = state.basicAuthByUser[userId].results.find((b) => b.id == action.meta.arg.basicAuthId);
                    if (!found) state.basicAuthByUser[userId].results.push(action.payload);
                    else state.basicAuthByUser[userId].results.splice(state.basicAuthByUser[userId].results.indexOf(found), 1, action.payload);
                }

                state.unordered = [...state.unordered.filter((u) => u.id != action.meta.arg.basicAuthId), action.payload];
            })
            .addCase(postBasicAuth.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(postBasicAuth.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                const userId = action.meta.arg.userId;
                if (!state.basicAuthByUser) {
                    state.basicAuthByUser = { [userId]: { count: 1, results: [action.payload] } };
                } else if (state.basicAuthByUser && !state.basicAuthByUser[userId]) {
                    state.basicAuthByUser[userId] = { count: 1, results: [action.payload] };
                } else {
                    state.basicAuthByUser[userId].count++;
                    state.basicAuthByUser[userId].results.splice(0, 0, action.payload);
                }

                state.unordered.push(action.payload);
            })
            .addCase(deleteBasicAuth.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(deleteBasicAuth.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                const userId = action.meta.arg.userId;
                const basicAuthId = action.meta.arg.basicAuthId;
                if (state.basicAuthByUser && state.basicAuthByUser[userId]) {
                    state.basicAuthByUser[userId].results = state.basicAuthByUser[userId].results.filter((auth) => auth.id != basicAuthId);
                    state.basicAuthByUser[userId].count--;
                }
                state.unordered = state.unordered.filter((auth) => auth.id != basicAuthId);
            })
            .addCase(patchBasicAuth.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(patchBasicAuth.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                const userId = action.meta.arg.userId;
                if (!state.basicAuthByUser || !state.basicAuthByUser[userId]) return;
                else {
                    const found = state.basicAuthByUser[userId].results.find((b) => b.id == action.meta.arg.basicAuthId);
                    if (!found) return;
                    else state.basicAuthByUser[userId].results.splice(state.basicAuthByUser[userId].results.indexOf(found), 1, action.payload);
                }

                state.unordered = [...state.unordered.filter((auth) => action.meta.arg.basicAuthId != auth.id), action.payload];
            });
    }
});

export const fetchBasicAuths = createAsyncThunk<BasicAuthListResponse, ApiQueryParams<DefaultQueryParams | BasicAuthQueryParams>>(
    'fetchBasicAuths',
    async (queryParams: ApiQueryParams<DefaultQueryParams | BasicAuthQueryParams>, { rejectWithValue }) => {
        try {
            return await BasicAuthClient.fetchBasicAuths(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchBasicAuth = createAsyncThunk<BasicAuth, { userId: string; basicAuthId: string }>(
    'fetchBasicAuth',
    async (params: { userId: string; basicAuthId: string }, { rejectWithValue }) => {
        try {
            return await BasicAuthClient.fetchBasicAuth(params.userId, params.basicAuthId);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const postBasicAuth = createAsyncThunk<BasicAuth, { userId: string; body: CreateBasicAuth }>(
    'postBasicAuth',
    async (params: { userId: string; body: CreateBasicAuth }, { rejectWithValue }) => {
        try {
            return await BasicAuthClient.postBasicAuth(params.userId, params.body);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const deleteBasicAuth = createAsyncThunk<void, { userId: string; basicAuthId: string }>(
    'deleteBasicAuth',
    async (params: { userId: string; basicAuthId: string }, { rejectWithValue }) => {
        try {
            return await BasicAuthClient.deleteBasicAuth(params.userId, params.basicAuthId);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const patchBasicAuth = createAsyncThunk<BasicAuth, { userId: string; basicAuthId: string; body: UpdateBasicAuth }>(
    'patchBasicAuth',
    async (params: { userId: string; basicAuthId: string; body: UpdateBasicAuth }, { rejectWithValue }) => {
        try {
            return await BasicAuthClient.patchBasicAuth(params.userId, params.basicAuthId, params.body);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const basicAuthStore = { basicAuths: basicAuthSlice.reducer };
