import { DetailModalType } from '@frontend/elements';
import { LuTablet } from 'react-icons/lu';

import BadgeTemplate from '../badge-template/badge-template.component';
import useTerminalBadge from './terminal-badge.controller';

export interface TerminalBadgeProps {
    id?: string;
    name?: string | null;
}

const TerminalBadge = (props: TerminalBadgeProps) => {
    const viewProps = useTerminalBadge(props);

    return (
        <BadgeTemplate
            disabled={viewProps.disabled}
            type={DetailModalType.TERMINAL}
            objectId={props.id}>
            <LuTablet className='me-2' />
            {viewProps.displayName ? (
                viewProps.displayName
            ) : (
                <span className='placeholder-glow'>
                    <span className='placeholder'>loading</span>
                </span>
            )}
        </BadgeTemplate>
    );
};

export default TerminalBadge;
