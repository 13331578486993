import { SpotSelect } from '@frontend/spot/common';
import { UserSelect } from '@frontend/user/components';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { lazy } from 'react';

const AccountSelect = lazy(() => import('@frontend/account/common').then((module) => ({ default: module.AccountSelect })));
const DriverSelect = lazy(() => import('@frontend/edge/common').then((module) => ({ default: module.DriverSelect })));
const ContactSelect = lazy(() => import('@frontend/contact/components').then((module) => ({ default: module.ContactSelect })));

const ID = 'role-asset-select';
export interface EntityByTypeSelectProps {
    required?: boolean;
    submitted: boolean;
    type: string;
    onChange: (value: string) => void;
    value: any;
    dispatch: ThunkDispatch<any, any, Action>;
}

export const EntityByTypeSelect = (props: EntityByTypeSelectProps) => {
    return (
        <div id={ID}>
            {props.type === 'account' && (
                <AccountSelect
                    required
                    label='Item (account)'
                    submitted={props.submitted}
                    onChange={(val) => props.onChange(val || '')}
                    value={props.value}
                    dispatch={props.dispatch}
                />
            )}

            {props.type === 'contact' && (
                <ContactSelect
                    required
                    dispatch={props.dispatch}
                    label='Item (contact)'
                    submitted={props.submitted}
                    value={props.value}
                    onChange={(val) => props.onChange(val || '')}
                />
            )}

            {props.type === 'spot' && (
                <SpotSelect
                    required
                    label='Item (spot)'
                    submitted={props.submitted}
                    onChange={(val) => props.onChange(val || '')}
                    value={props.value}
                    dispatch={props.dispatch}
                />
            )}

            {props.type === 'driver' && (
                <DriverSelect
                    required
                    label='Item (driver)'
                    submitted={props.submitted}
                    onChange={(val) => props.onChange(val || '')}
                    value={props.value}
                    dispatch={props.dispatch}
                />
            )}

            {props.type === 'user' && (
                <UserSelect
                    required
                    label='Item (user)'
                    submitted={props.submitted}
                    onChange={(val) => props.onChange(val || '')}
                    value={props.value}
                    dispatch={props.dispatch}
                />
            )}

            {/*props.type === 'rakinda' && (
                <RakindaSelect
                    required
                    label='Item (rakinda)'
                    submitted={props.submitted}
                    onChange={(val) => props.onChange(val || '')}
                    value={props.value}
                />
            )}
            {props.type === 'terminal' && (
                <TerminalSelect
                    required
                    label='Item (terminal)'
                    submitted={props.submitted}
                    onChange={(val) => props.onChange(val || '')}
                    value={props.value}
                />
            )}
            {props.type === 'transaction' && (
                <TransactionSelect
                    required
                    label='Item (transaction)'
                    submitted={props.submitted}
                    onChange={(val) => props.onChange(val || '')}
                    value={props.value}
                />
            )*/}
        </div>
    );
};
