import { ClassType } from '@frontend/common';
import { ConfirmationModal, DefaultModal, HorizontalButtonGroup, Spinner } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';
import { Table } from '@frontend/table';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import { FaCopy, FaEdit, FaPlus, FaTrash } from 'react-icons/fa';

import CreateNotificationContent from '../../notification-content/forms/create-notification-content/create-notification-content.component';
import NotificationContentListElement from '../../notification-content/notification-content-list/notification-content-element';
import CopyNotificationTemplate from '../forms/copy-notification-template/copy-notification-template.component';
import UpdateNotificationTemplate from '../forms/update-notification-template/update-notification-template.component';
import useNotificationTemplateDetail from './notification-template-detail.controller';

const ID = 'notification-template-detail';

export interface NotificationTemplateDetailProps {
    dispatch: ThunkDispatch<any, any, Action>;
}

export const NotificationTemplateDetail = (props: NotificationTemplateDetailProps) => {
    const viewProps = useNotificationTemplateDetail(props);

    if (!viewProps.notificationTemplate) {
        return (
            <div className='card mt-3 w-100'>
                <Spinner />
            </div>
        );
    }

    return (
        <div id={ID}>
            <div className='card mt-3 w-100'>
                <div className='card-header d-flex justify-content-between'>
                    <h4>{viewProps.notificationTemplate.name}</h4>
                    <HorizontalButtonGroup
                        noFlex
                        direction='left'
                        buttons={[
                            {
                                hide: false,
                                onClick: () => viewProps.changeShowUpdateModal(true),
                                icon: FaEdit,
                                type: ClassType.WARNING,
                                id: 'notification-template-detail-update-spot',
                                text: CommonMessage.BUTTONS.UPDATE
                            },
                            {
                                hide: false,
                                onClick: () => viewProps.changeShowDeleteModal(true),
                                icon: FaTrash,
                                type: ClassType.DANGER,
                                id: 'notification-template-detail-delete-spot',
                                text: CommonMessage.BUTTONS.DELETE
                            },
                            {
                                text: 'Copy template',
                                onClick: () => viewProps.changeShowCopyModal(true),
                                icon: FaCopy,
                                type: ClassType.WARNING,
                                id: 'notification-template-detail-copy-spot'
                            }
                        ]}
                    />
                </div>
                <div className='card-body'>
                    <div className='text-sm font-weight-bold text-wrap w-50'>{viewProps.notificationTemplate.description}</div>
                </div>
            </div>
            <div className='card mt-3'>
                <div className='card-header d-flex justify-content-between'>
                    <h4>Content</h4>
                    <HorizontalButtonGroup
                        direction='left'
                        buttons={[
                            {
                                hide: false,
                                text: CommonMessage.FORMS.CREATE_OBJECT('Content'),
                                icon: FaPlus,
                                type: ClassType.PRIMARY,
                                onClick: () => viewProps.changeShowCreateContentModal(true)
                            }
                        ]}
                    />
                </div>
                <div className='card-body'>
                    <Table
                        columns={viewProps.columns}
                        data={viewProps.data}
                        page={(v) => viewProps.changePage(v)}
                        pageSize={(v) => viewProps.changePageSize(v)}
                        pageCount={viewProps.pageCount}
                        RowElement={NotificationContentListElement}
                    />
                </div>
            </div>
            {viewProps.showDeleteModal && (
                <ConfirmationModal
                    handleClose={() => viewProps.changeShowDeleteModal(false)}
                    message={`You are about to delete template ${viewProps.notificationTemplate?.name}`}
                    severity={ClassType.DANGER}
                    onConfirm={() => viewProps.removeNotificationTemplate()}
                    show={viewProps.showDeleteModal}
                />
            )}
            {viewProps.showUpdateModal && (
                <DefaultModal
                    handleClose={() => viewProps.changeShowUpdateModal(false)}
                    show={viewProps.showUpdateModal}>
                    <UpdateNotificationTemplate
                        notificationTemplate={viewProps.notificationTemplate}
                        onClose={() => viewProps.changeShowUpdateModal(false)}
                    />
                </DefaultModal>
            )}
            {viewProps.showCreateContentModal && (
                <DefaultModal
                    handleClose={() => viewProps.changeShowCreateContentModal(false)}
                    show={viewProps.showCreateContentModal}>
                    <CreateNotificationContent onClose={() => viewProps.changeShowCreateContentModal(false)} />
                </DefaultModal>
            )}
            {viewProps.showCopyModal && (
                <DefaultModal
                    handleClose={() => viewProps.changeShowCopyModal(false)}
                    show={viewProps.showCopyModal}>
                    <CopyNotificationTemplate
                        dispatch={props.dispatch}
                        notificationTemplate={viewProps.notificationTemplate}
                        onClose={() => viewProps.changeShowCopyModal(false)}
                    />
                </DefaultModal>
            )}
        </div>
    );
};
