import { Transaction } from '@frontend/transaction/types';
import { WorkflowStepTriggeredEvent } from '@frontend/workflow/types';
import { useEffect, useState } from 'react';

import { CreateTransactionFormProps } from './create-transaction-form.component';

interface ViewProps {
    transaction: Transaction | null;
    changeTransaction: React.Dispatch<React.SetStateAction<Transaction | null>>;
    workflowStepEvent: WorkflowStepTriggeredEvent | null;
    changeWorkflowStepEvent: React.Dispatch<React.SetStateAction<WorkflowStepTriggeredEvent | null>>;
}

const useCreateTransactionForm = (props: CreateTransactionFormProps): ViewProps => {
    // const { workflowEventListener } = WorkflowResolver({ enablePubSub: true, dispatch: props.dispatch });
    const [transaction, changeTransaction] = useState<Transaction | null>(null);
    const [workflowStepEvent, changeWorkflowStepEvent] = useState<WorkflowStepTriggeredEvent | null>(null);

    useEffect(() => {
        if (transaction) {
            // workflowEventListener?.addCallback('REQUEST_WORKFLOW_EVENT', (event) => {
            //     const payload = JSON.parse(event.data) as { message: string; data: any };
            //     if (payload.message === 'workflow_step_triggered') {
            //         const data = payload.data as WorkflowStepTriggeredEvent;
            //         if (data.action === 'show_user_interface' && transaction.id === data.transaction_id) {
            //             changeWorkflowStepEvent(data);
            //         }
            //         if (data.action === 'hide_user_interface' && transaction.id === data.transaction_id) {
            //             ToastUtil.success('Transaction creation finished successfully.');
            //             props.onClose();
            //         }
            //     }
            // });
        }
    }, [transaction]);

    return {
        transaction,
        changeTransaction,
        workflowStepEvent,
        changeWorkflowStepEvent
    };
};

export default useCreateTransactionForm;
