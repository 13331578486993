import RolesModal from '../roles-modal/roles-modal.component';
import useRolesModalContainer from './roles-modal-container.controller';

const ID = 'roles-modal-container';
export interface RolesModalContainerProps {}

const RolesModalContainer = (props: RolesModalContainerProps) => {
    const viewProps = useRolesModalContainer(props);

    return <div id={ID}>{viewProps.showModal && <RolesModal />}</div>;
};

export default RolesModalContainer;
