import { PaginatedResponse } from '@frontend/api-utils';

export interface IntegrationTest {
    status: IntegrationTestStatus;
    topic: string;
    test: string;
    message: string | null;
    id: string;
    creation_timestamp: string;
}

export interface IntegrationTestOverview {
    topic: string;
    test: string;
    last_execution_timestamp: string;
    tests: IntegrationTest[];
}

export enum IntegrationTestStatus {
    SUCCEEDED = 'succeeded',
    FAILED = 'failed'
}

export type IntegrationTestListResponse = PaginatedResponse<IntegrationTest>;
export type IntegrationTestOverviewListResponse = PaginatedResponse<IntegrationTestOverview>;
