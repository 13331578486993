import { DeviceClient } from '@frontend/device/api';
import { seedDevices } from '@frontend/device/redux';
import { Device } from '@frontend/device/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { DeviceServiceEventListener } from './device-service-event-listener';

declare global {
    interface WindowEventMap {
        'useDevice:request': CustomEvent<string>;
    }
}

const REQUEST_DEVICE_EVENT = 'useDevice:request';
const STORAGE_KEY = 'DEV_REQ';

export function deviceRequest(deviceId: string) {
    window.dispatchEvent(new CustomEvent(REQUEST_DEVICE_EVENT, { detail: deviceId }));
}

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
    enablePubSub?: boolean;
}

export function useDevices(props: Props) {
    const [pending, changePending] = useState<string[]>([]);
    const [timeoutSet, changeTimeoutSet] = useState<boolean>(false);
    const [timeoutFinished, changeTimeoutFinished] = useState<boolean>(false);
    const [deviceEventListener, changeDeviceEventListener] = useState<DeviceServiceEventListener | null>(null);

    useEffect(() => {
        window.addEventListener(REQUEST_DEVICE_EVENT, listener);
    });

    useEffect(() => {
        if (props.enablePubSub) {
            changeDeviceEventListener(DeviceServiceEventListener.getInstance(props.dispatch));
        }
    }, [props.enablePubSub]);

    useEffect(() => {
        if (timeoutFinished) {
            const stored = localStorage.getItem(STORAGE_KEY);
            if (stored != null) {
                changePending(JSON.parse(stored));
                changeTimeoutSet(false);
                changeTimeoutFinished(false);
                localStorage.removeItem(STORAGE_KEY);
            }
        }
    }, [timeoutFinished]);

    useEffect(() => {
        if (pending.length > 0) {
            getDevices(pending).then((result) => {
                props.dispatch(seedDevices(result));
                changePending([]);
                changeTimeoutSet(false);
                changeTimeoutFinished(false);
            });
        }
    }, [pending]);

    const listener = (event: CustomEvent) => {
        if (typeof event.detail == 'string') {
            if (timeoutSet === false) {
                changeTimeoutSet(true);
                setTimeout(() => {
                    changeTimeoutFinished(true);
                }, 1000);
            }
            const stored = localStorage.getItem(STORAGE_KEY);
            if (stored == null) {
                localStorage.setItem(STORAGE_KEY, JSON.stringify([event.detail]));
            } else if (!JSON.parse(stored).includes(event.detail)) {
                localStorage.setItem(STORAGE_KEY, JSON.stringify([...JSON.parse(stored), event.detail]));
            }
        }
    };

    return { deviceEventListener };
}

async function getDevices(deviceIds: string[]): Promise<Device[]> {
    const response = await DeviceClient.resolveDevices(deviceIds);
    return response.results;
}
