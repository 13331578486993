import { EntityType } from '@frontend/common';
import { Contact } from '@frontend/contact/types';
import { ActionButton, DateBadge, DropdownButtonGroup, EmailBadge, PhoneNumberBadge } from '@frontend/elements';
import { RowProps } from '@frontend/table';
import React from 'react';
import { Row } from 'react-table';

import { ContactBadge } from '../contact-badge/contact-badge.component';

interface ContactListElementProps extends RowProps {
    selectEnabled?: boolean;
    row: Row<Contact>;
    onClick?: (element: Contact) => void;
}

const ContactListElement = (props: ContactListElementProps) => {
    return (
        <tr
            id='ContactList-Row'
            onClick={() => props.onClick && props.onClick(props.row.original)}
            className={'' + (props.onClick && 'cursor-pointer')}>
            {props.selectEnabled === true && (
                <td
                    onClick={(e) => e.stopPropagation()}
                    {...props.row.cells[0].getCellProps()}>
                    {props.row.cells[0].render('Cell')}
                </td>
            )}
            <td className='align-content-center'>
                <ContactBadge
                    id={props.row.original.id}
                    first_name={props.row.original.first_name}
                    last_name={props.row.original.last_name}
                />
            </td>
            <td className='text-secondary text-xs font-weight-bolder align-content-center'>
                <span>{props.row.original.alias}</span>
            </td>
            <td className='text-secondary text-xs font-weight-bolder align-content-center'>
                <EmailBadge email={props.row.original.email} />
            </td>
            <td className='text-secondary text-xs font-weight-bolder align-content-center'>
                <PhoneNumberBadge phone={props.row.original.mobile} />
            </td>
            <td className='text-secondary text-xs font-weight-bolder align-content-center'>
                <DateBadge
                    date={props.row.original.creation_timestamp}
                    displayTime
                />
            </td>
            <td>
                <ActionButton>
                    <DropdownButtonGroup
                        showRoles={{entityId: props.row.original.id, entityType: EntityType.CONTACT}}
                        showJSON={props.row}
                        dispatch={props.dispatch}
                    />
                </ActionButton>
            </td>
        </tr>
    );
};

export default ContactListElement;
