import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

import useCreateTransactionForm from './create-transaction-form.controller';
import CreateTransaction from './create-transaction/create-transaction.component';

const ID = 'create-transaction-form';
export interface CreateTransactionFormProps {
    onClose: () => void;
    dispatch: ThunkDispatch<any, any, Action>;
}

export const CreateTransactionForm = (props: CreateTransactionFormProps) => {
    const viewProps = useCreateTransactionForm(props);

    return (
        <div id={ID}>
            {viewProps.transaction === null && (
                <CreateTransaction
                    callback={viewProps.changeTransaction}
                    onClose={props.onClose}
                    dispatch={props.dispatch}
                />
            )}
            {/* {viewProps.transaction !== null &&
                (viewProps.workflowStepEvent !== null ? (
                    <AddTransactionVariablesForm
                        event={viewProps.workflowStepEvent}
                        transaction={viewProps.transaction}
                        callback={props.onClose}
                        onClose={props.onClose}
                        dispatch={props.dispatch}
                    />
                ) : (
                    <Spinner />
                ))} */}
        </div>
    );
};
