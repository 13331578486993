import { EmailInput, PhoneInput, SelectInput, TextInput } from '@frontend/basic-forms';
import { ClassType, Languages } from '@frontend/common';
import { ModalFooter } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';
import React from 'react';
import { FaPlus } from 'react-icons/fa';

import { Contact } from '@frontend/contact/types';
import useUpdateContactForm from './update-contact-form.controller';

const ID = 'update-contact-form';

export interface UpdateContactFormProps {
    onClose: () => void;
    contact: Contact;
}

export const UpdateContactForm = (props: UpdateContactFormProps) => {
    const viewProps = useUpdateContactForm(props);

    return (
        <div id={ID}>
            <form onSubmit={viewProps.onSubmit}>
                <div className='modal-header'>
                    <h4>{CommonMessage.FORMS.UPDATE_OBJECT('Contact')}</h4>
                </div>
                <div className='modal-body'>
                    <div className='d-flex flex-row'>
                        <TextInput
                            id={ID + '-aliasInput'}
                            className='w-50 m-1'
                            label='Alias'
                            value={viewProps.formValues.alias}
                            onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, alias: value })}
                            isValidCallback={(valid) => {
                                viewProps.changeFormValid({ ...viewProps.formValid, alias: valid });
                            }}
                            submitted={viewProps.submitted}
                        />
                        <SelectInput
                            id={ID + '-languageInput'}
                            label='Language'
                            className='w-50 m-1'
                            required
                            value={viewProps.formValues.language}
                            onChange={(value: any) => viewProps.changeFormValues({ ...viewProps.formValues, language: value.value })}
                            options={Languages.map((t) => ({ value: t.value, label: t.displayName }))}
                            isValidCallback={(valid) => {
                                viewProps.changeFormValid({ ...viewProps.formValid, language: valid });
                            }}
                            submitted={viewProps.submitted}
                        />
                    </div>
                    <div className='d-flex flex-row'>
                        <TextInput
                            id={ID + '-firstnameInput'}
                            className='w-100 m-1'
                            label='Firstname'
                            value={viewProps.formValues.first_name}
                            onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, first_name: value })}
                            isValidCallback={(valid) => {
                                viewProps.changeFormValid({ ...viewProps.formValid, first_name: valid });
                            }}
                            submitted={viewProps.submitted}
                        />

                        <TextInput
                            id={ID + '-lastnameInput'}
                            className='w-100 m-1'
                            label='Lastname'
                            value={viewProps.formValues.last_name}
                            onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, last_name: value })}
                            isValidCallback={(valid) => {
                                viewProps.changeFormValid({ ...viewProps.formValid, last_name: valid });
                            }}
                            submitted={viewProps.submitted}
                        />
                    </div>
                    <div className='d-flex flex-row'>
                        <PhoneInput
                            id={ID + '-mobileInput'}
                            className='w-100 m-1'
                            label='Mobile'
                            value={viewProps.formValues.mobile}
                            onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, mobile: value })}
                            isValidCallback={(valid) => {
                                viewProps.changeFormValid({ ...viewProps.formValid, mobile: valid });
                            }}
                            submitted={viewProps.submitted}
                        />
                        <EmailInput
                            id={ID + '-EmailInput'}
                            className='w-100 m-1'
                            label='Email'
                            value={viewProps.formValues.email}
                            onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, email: value })}
                            isValidCallback={(valid) => {
                                viewProps.changeFormValid({ ...viewProps.formValid, email: valid });
                            }}
                            submitted={viewProps.submitted}
                        />
                    </div>
                </div>
                <ModalFooter
                    justifyContent='between'
                    buttons={[
                        {
                            hide: false,
                            text: CommonMessage.BUTTONS.CANCEL,
                            type: ClassType.SECONDARY,
                            onClick: (e) => {
                                e?.preventDefault();
                                props.onClose();
                            }
                        },
                        {
                            hide: false,
                            text: CommonMessage.BUTTONS.SUBMIT,
                            type: ClassType.SUCCESS,
                            icon: FaPlus,
                            buttonType: 'submit'
                        }
                    ]}
                />
            </form>
        </div>
    );
};

export default UpdateContactForm;
