import React, { Suspense } from 'react';

import ErrorBoundary from './error-boundary';
import { Spinner, SpinnerProps } from './spinner.component';

interface Props {
    spinner?: SpinnerProps;
    children: React.ReactNode;
    fallback?: React.ReactNode;
}

export const AsyncComponent = (props: Props) => {
    return (
        <ErrorBoundary>
            <Suspense
                fallback={
                    props.fallback || (
                        <Spinner
                            show={props.spinner?.show}
                            type={props.spinner?.type}
                            size={props.spinner?.size}
                        />
                    )
                }>
                {props.children}
            </Suspense>
        </ErrorBoundary>
    );
};

export default AsyncComponent;
