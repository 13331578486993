import { AccountBadge } from '@frontend/account/badge';
import { DetailPageType, useDetailPage } from '@frontend/common';
import { DateBadge, NameBadge, ObjectField } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';
import { User } from '@frontend/user/types';
import { WorkflowBadge } from '@frontend/workflow/common';
import React from 'react';

export interface UserDetailProps {
    user: User;
}

export const UserDetail = (props: UserDetailProps) => {
    const viewProps = useDetailPage(DetailPageType.USER);
    return (
        <>
            <ObjectField
                fieldName={CommonMessage.OBJECTS.USER.USERNAME}
                value={props.user.email}
            />
            <ObjectField
                fieldName={CommonMessage.FORMS.NAME}
                value={
                    <NameBadge
                        firstName={props.user.first_name}
                        lastName={props.user.last_name}
                    />
                }
            />
            <ObjectField
                fieldName={CommonMessage.FORMS.EMAIL()}
                value={props.user.email}
            />
            <ObjectField
                fieldName={CommonMessage.OBJECTS.USER.TITLE}
                value={props.user.title}
            />
            {viewProps.isAdminRoute && (
                <>
                    <ObjectField
                        fieldName={CommonMessage.OBJECTS.ACCOUNT.DEFAULT}
                        value={<AccountBadge id={props.user.account_id} />}
                    />
                    <ObjectField
                        fieldName={CommonMessage.OBJECTS.WORKFLOW.DEFAULT}
                        value={<WorkflowBadge id={props.user.workflow_id} />}
                    />
                </>
            )}

            <ObjectField
                fieldName={CommonMessage.OBJECTS.DEFAULT.CREATION_TIMESTAMP}
                value={
                    <DateBadge
                        date={props.user.creation_timestamp}
                        displayTime
                        inline
                    />
                }
            />
            <ObjectField
                fieldName={CommonMessage.OBJECTS.DEFAULT.UPDATE_TIMESTAMP}
                value={
                    <DateBadge
                        date={props.user.update_timestamp}
                        displayTime
                        inline
                    />
                }
            />
        </>
    );
};
