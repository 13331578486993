import { ErrorHandler } from '@frontend/error-handler';
import { NotificationTemplateClient } from '@frontend/notification-service/api';
import { Dispatch, SetStateAction, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { CopyNotificationTemplateProps } from './copy-notification-template.component';

interface ViewProps {
    account: string | null;
    changeAccount: Dispatch<SetStateAction<string | null>>;
    onSubmit: (e: any) => void;
    submitted: boolean;
}

const useCopyNotificationTemplate = (props: CopyNotificationTemplateProps): ViewProps => {
    const [account, changeAccount] = useState<string | null>(null);
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const navigate = useNavigate();

    const onSubmit = (e: any) => {
        e.preventDefault();
        changeSubmitted(true);
        if (!account) return;
        NotificationTemplateClient.copyTemplate(props.notificationTemplate.account_id, props.notificationTemplate.id, { account_id: account })
            .then((res) => {
                props.onClose();
                navigate(`/accounts/${res.account_id}/notification-templates/${res.id}`);
            })
            .catch(ErrorHandler.handleError)
            .finally(() => changeSubmitted(false));
    };

    return {
        account,
        changeAccount,
        onSubmit,
        submitted
    };
};

export default useCopyNotificationTemplate;
