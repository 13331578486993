import { SensorLockerStatus } from '@frontend/device/types';
import { Spinner } from '@frontend/elements';
import React from 'react';

import { UserInterfaceTemplateProps } from '../user-interface-template-props';
import SlotClosed from './slot-closed/slot-closed.component';
import useSlotEventListener from './slot-event-listener.controller';
import SlotOpened from './slot-opened/slot-opened.component';
import SlotOpening from './slot-opening/slot-opening.component';
import SlotUnlocked from './slot-unlocked/slot-unlocked.component';

export interface SlotEventListenerProps extends UserInterfaceTemplateProps {
    callback?: (status: SensorLockerStatus) => void;
}

const SlotEventListener = (props: SlotEventListenerProps) => {
    const viewProps = useSlotEventListener(props);

    if (!viewProps.event || !viewProps.cache) return <Spinner />;
    switch (viewProps.event) {
        case SensorLockerStatus.OPENING:
            return <SlotOpening dispatch={props.dispatch} />;
        case SensorLockerStatus.UNLOCKED:
            return <SlotUnlocked dispatch={props.dispatch} />;
        case SensorLockerStatus.OPENED:
            return <SlotOpened dispatch={props.dispatch} />;
        case SensorLockerStatus.CLOSED:
            return <SlotClosed dispatch={props.dispatch} />;
        default:
            return <div>SLOT is in a unknown state.</div>;
    }
};

export default SlotEventListener;
