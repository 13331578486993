export enum Rights {
    CREATE = 'create',
    READ = 'read',
    UPDATE = 'update',
    DELETE = 'delete',
    ACCESS = 'access',
    TRIGGER = 'trigger',
    OPEN = 'open',
    STATE = 'state',
    DROP_OFF = 'drop_off',
    PICK_UP = 'pick_up',
    SUPER_ADMIN = '*'
}
