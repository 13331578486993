import { AsyncComponent } from '@frontend/elements';
import { UserInterfaceType } from '@frontend/user-interface/types';
import { WorkflowType } from '@frontend/workflow/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import useWarehouseProductPickDropDataForm from './warehouse-product-pick-drop-data-form.controller';

const WorkflowSelect = React.lazy(() => import('@frontend/workflow/common').then((module) => ({ default: module.WorkflowSelect })));
const CatalogSelect = React.lazy(() => import('@frontend/product-catalog/common').then((module) => ({ default: module.CatalogSelect })));

const ID = 'warehouse-product-pick-drop-data-form';
export interface WarehouseProductPickDropDataFormProps {
    accountId: string;
    onChange: (value: any) => void;
    submitted: boolean;
    type: UserInterfaceType;
    dispatch: ThunkDispatch<any, any, Action>;
}

const WarehouseProductPickDropDataForm = (props: WarehouseProductPickDropDataFormProps) => {
    const viewProps = useWarehouseProductPickDropDataForm(props);

    return (
        <div id={ID}>
            <AsyncComponent>
                <WorkflowSelect
                    required
                    label='Transaction workflow'
                    submitted={props.submitted}
                    onChange={(value) => value && viewProps.changeFormValues({ ...viewProps.formValues, transactionWorkflowId: value })}
                    value={viewProps.formValues.transactionWorkflowId}
                    dispatch={props.dispatch}
                    queryParams={{type: WorkflowType.TRANSACTION, account_id: props.accountId}}
                />
                <WorkflowSelect
                    label='Dropoff package workflow'
                    submitted={props.submitted}
                    onChange={(value) => value && viewProps.changeFormValues({ ...viewProps.formValues, dropPackageWorkflowId: value })}
                    value={viewProps.formValues.dropPackageWorkflowId || null}
                    dispatch={props.dispatch}
                    queryParams={{type: WorkflowType.PACKAGE, account_id: props.accountId}}
                />
                <WorkflowSelect
                    label='Pickup package workflow'
                    submitted={props.submitted}
                    onChange={(value) => value && viewProps.changeFormValues({ ...viewProps.formValues, pickPackageWorkflowId: value })}
                    value={viewProps.formValues.pickPackageWorkflowId || null}
                    dispatch={props.dispatch}
                    queryParams={{type: WorkflowType.PACKAGE, account_id: props.accountId}}
                />
                <CatalogSelect
                    label='Catalogue'
                    submitted={props.submitted}
                    onChange={(value) => value && viewProps.changeFormValues({ ...viewProps.formValues, catalogId: value })}
                    value={viewProps.formValues.catalogId ?? null}
                    dispatch={props.dispatch}
                    queryParams={{account_id: props.accountId}}
                />
            </AsyncComponent>
        </div>
    );
};

export default WarehouseProductPickDropDataForm;
