import { useEffect, useMemo, useState } from 'react';

import { getClassName, getInputClassMultiSelect } from '../input-class';
import { MultiSelectInputProps } from './multi-select-input.component';

interface ViewProps {
    value: any;
    valid: boolean;
    touched: boolean;
    changeValue: React.Dispatch<any>;
    isValid: () => boolean;
    changeTouched: React.Dispatch<React.SetStateAction<boolean>>;
    className: string;
    findValue: () => void;
}

const useMultiSelectInput = (props: MultiSelectInputProps): ViewProps => {
    const [touched, changeTouched] = useState<boolean>(false);
    const [valid, changeValid] = useState<boolean>(props.valid !== undefined ? props.valid : true);
    const [value, changeValue] = useState<any | null | undefined>(null);
    const className = useMemo(() => {
        return props.useConditionedStyling === false
            ? 'form-control'
            : props.isMulti
            ? getInputClassMultiSelect(touched, valid, props.value)
            : getClassName(touched, valid, props.value);
    }, [props.useConditionedStyling, props.isMulti]);

    const isValid = (): boolean => {
        let check = true;
        if ((props.value === undefined || props.value === null) && props.required === true) check = false;
        if (props.isMulti) {
            if (check === true && props.required === true && (props.value === undefined || props.value === null || props.value.length === 0)) check = false;
        } else {
            if (props.value && (props.value.value || props.value.value === undefined || props.value.value.length === 0)) {
                if (
                    check === true &&
                    props.required === true &&
                    (props.value.value === undefined || props.value.value === null || props.value.value.length === 0)
                )
                    check = false;
            } else {
                if (check === true && props.required === true && (props.value === undefined || props.value === null || props.value.length === 0)) check = false;
            }
        }
        if (props.valid !== undefined && check === true) check = props.valid;
        changeValid(check);
        return check;
    };

    useEffect(() => {
        if (props.isValidCallback) {
            props.isValidCallback(isValid());
        } else isValid();
    }, [props.value, props.valid]);

    useEffect(() => {
        if (props.submitted) {
            !touched && changeTouched(true);
        }
    }, [props.submitted]);

    const multiValues = props.isMulti === true && props.value !== undefined ? props.options?.filter((op: any) => props.value.includes(op.value)) : [];

    const findValue = () => {
        if (props.options?.every((op: any) => op.options)) {
            return props.options.flatMap((o: any) => o.options).filter((v) => props.value.includes(v.value));
        } else return multiValues;
    };

    return {
        changeTouched,
        changeValue,
        className,
        isValid,
        touched,
        valid,
        value,
        findValue
    };
};

export default useMultiSelectInput;

export interface Option {
    readonly label: string;
    readonly value: string;
}
