import { Logger } from '@frontend/Logger';
import { addAccount, removeAccount, updateAccount } from '@frontend/account/redux';
import { Account } from '@frontend/account/types';
import { EventListener } from '@frontend/pub-sub';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

export enum MessageTypes {
    UPDATED = 'account_updated',
    DELETED = 'account_deleted',
    CREATED = 'account_created'
}

export class AccountEventListener extends EventListener<Account> {
    
    private static instance: AccountEventListener | null = null;
    private constructor(dispatch: ThunkDispatch<any, any, Action>) {
        super('account', dispatch);
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>): AccountEventListener {
        if (this.instance == null) {
            this.instance = new AccountEventListener(dispatch);
        }
        return this.instance;
    }

    getInstance(dispatch: ThunkDispatch<any, any, Action>): EventListener<Account> {
        return AccountEventListener.getInstance(dispatch);
    }

    protected onUpdate(payload: { message: MessageTypes; data: Account; changes?: any }) {
        Logger.log('Updating account in store', { account: payload.data.id }, payload.data);
        this.dispatch(updateAccount(payload.data));
    }

    protected onDelete(payload: { message: MessageTypes; data: Account }) {
        Logger.log('Removing account from store', { account: payload.data.id }, payload.data);
        this.dispatch(removeAccount(payload.data.id));
    }

    protected onCreate(payload: { message: MessageTypes; data: Account; changes?: any }) {
        Logger.log('Adding account to store', { account: payload.data.id }, payload.data);
        this.dispatch(addAccount(payload.data));
    }
}