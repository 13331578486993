import { EnterPinData } from '@frontend/user-interface/types';
import { useEffect, useState } from 'react';

import { EnterPinDataFormProps } from './enter-pin-data-form.component';

interface ViewProps {
    formValues: EnterPinData;
    changeFormValues: React.Dispatch<React.SetStateAction<EnterPinData>>;
}

const useEnterPinDataForm = (props: EnterPinDataFormProps): ViewProps => {
    const [formValues, changeFormValues] = useState<EnterPinData>({ pinLength: 4 });

    useEffect(() => {
        props.onChange(formValues);
    }, [formValues]);

    return {
        formValues,
        changeFormValues
    };
};

export default useEnterPinDataForm;
