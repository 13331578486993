import { PaginatedResponse } from '@frontend/api-utils';
import { ProductStateName } from './product-state';
import { ProductTriggerName } from './product-trigger';

export interface Product {
    id: string;
    name: string | null;
    code: string;
    description: string | null;
    supplier: string | null;
    state: ProductStateName;
    type: ProductType;
    category_id: string | null;
    account_id: string;
    update_timestamp: string;
    creation_timestamp: string;
    workflow_id: string | null;
    owner: string | null;
}

export type ProductListResponse = PaginatedResponse<Product>;

export enum ProductType {
    CONSUMABLE = 'consumable',
    TOOL = 'tool',
    UNKNOWN = ''
}
export interface CreateProduct {
    name: string | null;
    code: string;
    description: string | null;
    supplier: string | null;
    state: ProductStateName;
    type: ProductType;
    category_id: string | null;
    workflow_id: string | null;
    owner?: string | null
}

export interface UpdateProduct {
    name: string | null;
    code: string | null;
    description: string | null;
    supplier: string | null;
    state: ProductStateName | null;
    category_id: string | null;
    workflow_id: string | null;
    owner?: string | null
}

export enum ProductQueryParams {
    CATEGORY_ID = 'category_id',
    TYPE = 'type',
    STATE = 'state',
    SUPPLIER = 'supplier',
    NAME = 'name',
    CODE = 'code',
    ACCOUNT_ID = 'account_id',
    CATALOG_ID = 'catalog_id',
    ROOT_CATEGORY_ID = 'root_category_id',
}

export interface ProductStateTrigger {
    trigger: ProductTriggerName;
    source: any;
}

export class ProductClass implements Omit<Product, 'update_timestamp' | 'creation_timestamp' | 'description'> {
    id: string;
    name: string | null;
    code: string;
    supplier: string | null;
    state: ProductStateName;
    type: ProductType;
    category_id: string | null;
    account_id: string;
    workflow_id: string | null;
    owner: string | null;

    constructor() {
        this.id = '';
        this.name = null;
        this.code = '';
        this.supplier = null;
        this.state = ProductStateName.UNKNOWN;
        this.type = ProductType.UNKNOWN;
        this.category_id = null;
        this.account_id = '';
        this.workflow_id = null;
        this.owner = null;
    }
}

export interface ProductReport {
    id: string;
    account_id: string;
    code: string | null;
    name: string | null;
    description: string | null;
    supplier: string | null;
    owner: string | null;
    category_id: string | null;
    total_quantity: number;
    update_timestamp: string;
}

export type ProductReportListResponse = PaginatedResponse<ProductReport>;
