import { Spinner } from '@frontend/elements';
import { CreateTransactionData, UserInterfaceImplementation } from '@frontend/user-interface/types';
import React from 'react';

import SelectSlotForm from '../../select-slot-form/select-slot-form.component';
import { UserInterfaceTemplateProps } from '../../user-interface-template-props';
import useCreateTransaction from './create-transaction.controller';
import UnknownVariableForm from './unknown-variable-form/unknown-variable-form.component';

export interface CreateTransactionProps extends UserInterfaceTemplateProps {
    userInterface: UserInterfaceImplementation<CreateTransactionData>;
}

const CreateTransaction = (props: CreateTransactionProps) => {
    const viewProps = useCreateTransaction(props);

    if (!viewProps.slot)
        return (
            <SelectSlotForm
                {...props}
                callback={viewProps.changeSlot}
            />
        );

    if (viewProps.transactionVars == null) return <Spinner />;
    if (viewProps.transactionVars.length != 0)
        return (
            <UnknownVariableForm
                {...props}
                variable={viewProps.transactionVars[0]}
                dispatch={props.dispatch}
                callback={viewProps.submitVariable}
            />
        );
    if (viewProps.dropPackageVars == null) return <Spinner />;
    if (viewProps.dropPackageVars.length != 0)
        return (
            <UnknownVariableForm
                {...props}
                variable={viewProps.dropPackageVars[0]}
                dispatch={props.dispatch}
                callback={viewProps.submitVariable}
            />
        );
    if (viewProps.pickPackageVars == null) return <Spinner />;
    if (viewProps.pickPackageVars.length != 0)
        return (
            <UnknownVariableForm
                {...props}
                variable={viewProps.pickPackageVars[0]}
                dispatch={props.dispatch}
                callback={viewProps.submitVariable}
            />
        );

    return <Spinner />;
};

export default CreateTransaction;
