import { ClassType } from '@frontend/common';
import { ModalFooter, Spinner } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';
import { Transaction, TransactionType } from '@frontend/transaction/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React, { lazy } from 'react';
import { FaPlus } from 'react-icons/fa';

import { TransactionTypeSelect } from '../../transaction-type-select/transaction-type-select.component';
import useCreateTransaction from './create-transaction.controller';

const WorkflowSelect = lazy(() => import('@frontend/workflow/common').then((module) => ({ default: module.WorkflowSelect })));
const AccountSelect = lazy(() => import('@frontend/account/common').then((module) => ({ default: module.AccountSelect })));
const DriverSelect = lazy(() => import('@frontend/edge/common').then((module) => ({ default: module.DriverSelect })));

const ID = 'create-transaction';
export interface CreateTransactionProps {
    callback: (transaction: Transaction) => void;
    onClose: () => void;
    dispatch: ThunkDispatch<any, any, Action>;
}

const CreateTransaction = (props: CreateTransactionProps) => {
    const viewProps = useCreateTransaction(props);

    return (
        <div id={ID}>
            <form onSubmit={viewProps.onSubmit}>
                <div className='modal-header'>
                    <h4>{CommonMessage.FORMS.CREATE_OBJECT('transaction')}</h4>
                </div>
                {viewProps.loading ? (
                    <div className='modal-body'>
                        <Spinner type={ClassType.DARK} />
                    </div>
                ) : (
                    <div className='modal-body'>
                        <div className='d-flex mb-3'>
                            <AccountSelect
                                className='w-50 m-1'
                                required
                                submitted={viewProps.submitted}
                                value={viewProps.formValues.account_id}
                                onChange={(value) => {
                                    viewProps.changeFormValues({ ...viewProps.formValues, account_id: value || '' });
                                    viewProps.changeFormValid({ ...viewProps.formValid, account_id: value !== '' });
                                }}
                                dispatch={props.dispatch}
                            />
                            <WorkflowSelect
                                className='w-50 m-1'
                                required
                                submitted={viewProps.submitted}
                                value={viewProps.formValues.workflow_id}
                                onChange={(value) => {
                                    viewProps.changeFormValues({ ...viewProps.formValues, workflow_id: value || '' });
                                    viewProps.changeFormValid({ ...viewProps.formValid, workflow_id: value !== '' });
                                }}
                                disabled={!viewProps.formValues.account_id}
                                queryParams={{ account_id: viewProps.formValues.account_id }}
                                dispatch={props.dispatch}
                            />
                        </div>

                        <div className='d-flex'>
                            <TransactionTypeSelect
                                required
                                submitted={viewProps.submitted}
                                className='w-50 m-1'
                                onChange={(v) => {
                                    viewProps.changeFormValues({ ...viewProps.formValues, type: v as TransactionType });
                                }}
                            />
                            <DriverSelect
                                id={ID + '-driver-input'}
                                label='Driver'
                                value={viewProps.formValues.driver_id ?? undefined}
                                onChange={(value) => {
                                    viewProps.changeFormValues({ ...viewProps.formValues, driver_id: value ?? null });
                                    viewProps.changeFormValid({ ...viewProps.formValid, driver_id: !!value });
                                }}
                                submitted={viewProps.submitted}
                                dispatch={props.dispatch}
                                className='w-50 m-1'
                            />
                        </div>
                    </div>
                )}
                <ModalFooter
                    justifyContent='between'
                    buttons={[
                        {
                            text: CommonMessage.BUTTONS.CANCEL,
                            type: ClassType.SECONDARY,
                            onClick: props.onClose
                        },
                        {
                            disabled: viewProps.submitDisabled,
                            text: CommonMessage.BUTTONS.SUBMIT,
                            type: ClassType.SUCCESS,
                            icon: FaPlus,
                            buttonType: 'submit'
                        }
                    ]}
                />
            </form>
        </div>
    );
};

export default CreateTransaction;
