import { StringPlaceholder } from '@frontend/elements';
import { UserInterfaceType } from '@frontend/user-interface/types';
import React from 'react';

import SlotEventListener from '../../slot-event-listener/slot-event-listener.component';
import { UserInterfaceTemplateProps } from '../../user-interface-template-props';
import TempImageLoader from '../temp-image-loader';
import usePackageProcessing from './package-processing.controller';

const PackageProcessing = (props: UserInterfaceTemplateProps) => {
    const viewProps = usePackageProcessing(props);
    return (
        <div
            id={UserInterfaceType.PACKAGE_PROCESSING}
            className='d-flex flex-column justify-content-center'>
            {viewProps.product && (
                <TempImageLoader
                    product={viewProps.product}
                    className='w-md-50 w-xl-25'
                />
            )}
            <h3 className='pb-4 text-center'>
                Processing{' '}
                <StringPlaceholder
                    loaded={!!viewProps.product}
                    className='col-2 bg-info'>
                    <span className='text-info'>{viewProps.product?.name}</span>
                </StringPlaceholder>
                .
            </h3>
            <SlotEventListener {...props} />
        </div>
    );
};

export default PackageProcessing;
