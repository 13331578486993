import { ClassType } from '@frontend/common';
import { HorizontalButtonGroup } from '@frontend/elements';
import { FaEye, FaSave, FaTrash } from 'react-icons/fa';

import useFooterBar from './footer-bar.controller';
import React from 'react';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

export interface FooterBarProps {
    originalContent: string;
    subject?: string;
    changeShowPreview: (value: React.SetStateAction<boolean>) => void;
    resetSubject: () => void;
    dispatch: ThunkDispatch<any, any, Action>;
}

const FooterBar = (props: FooterBarProps) => {
    const viewProps = useFooterBar(props);

    if (!viewProps.editor) return null;
    return (
        <div className='mt-3'>
            <HorizontalButtonGroup
                buttons={[
                    {
                        text: 'Reset',
                        type: ClassType.DANGER,
                        onClick: () => viewProps.resetContent(),
                        disabled: !viewProps.editor.can().setContent(props.originalContent),
                        icon: FaTrash
                    },
                    {
                        text: 'Save',
                        type: ClassType.SUCCESS,
                        icon: FaSave,
                        disabled: viewProps.disabled,
                        onClick: () => viewProps.saveConfiguration()
                    },
                    {
                        text: 'Preview',
                        type: ClassType.PRIMARY,
                        icon: FaEye,
                        onClick: () => props.changeShowPreview(true)
                    }
                ]}
                direction='left'
            />
        </div>
    );
};

export default FooterBar;
