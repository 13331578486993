import { ContactSelect } from '@frontend/contact/components';
import { WorkflowVariable } from '@frontend/workflow/types';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

import { TransactionVariable } from '../../../../transaction-variables';

const ID = 'contact-id-action-input';
export interface ContactIdInputProps {
    submitted: boolean;
    variable: TransactionVariable | WorkflowVariable;
    onChange: (variable: TransactionVariable | WorkflowVariable) => void;
    dispatch: ThunkDispatch<any, any, AnyAction>;
}

const ContactIdInput = (props: ContactIdInputProps) => {
    return (
        <ContactSelect
            id={ID}
            dispatch={props.dispatch}
            submitted={props.submitted}
            value={typeof props.variable.value === 'string' ? props.variable.value : undefined}
            onChange={(value) => {
                props.onChange({ ...props.variable, value: value! });
            }}
        />
    );
};

export default ContactIdInput;
