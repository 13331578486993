import { useMemo } from 'react';

import { useAppSelector } from '../../redux/store';
import { RolesModalContainerProps } from './roles-modal-container.component';

interface ViewProps {
    showModal: boolean;
}

const useRolesModalContainer = (props: RolesModalContainerProps): ViewProps => {
    const authorizationState = useAppSelector((state) => state.authorization);
    const showModal = useMemo(() => {
        return authorizationState.current != null && authorizationState.current.roles != null;
    }, [authorizationState.current]);
    return { showModal };
};

export default useRolesModalContainer;
