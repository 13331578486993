import { BadgeTemplate, DetailModalType } from '@frontend/elements';
import React from 'react';
import { GoProjectTemplate } from 'react-icons/go';

import useUserInterfaceBadge from './user-interface-badge.controller';

export interface UserInterfaceBadgeProps {
    id?: string;
    name?: string;
}

export const UserInterfaceBadge = (props: UserInterfaceBadgeProps) => {
    const viewProps = useUserInterfaceBadge(props);

    return (
        <BadgeTemplate
            type={DetailModalType.USER_INTERFACE}
            objectId={props.id}
            disabled={viewProps.disabled}>
            <GoProjectTemplate className='me-2' />
            {viewProps.displayName ? (
                viewProps.displayName
            ) : (
                <span className='placeholder-glow'>
                    <span className='placeholder'>loading</span>
                </span>
            )}
        </BadgeTemplate>
    );
};
