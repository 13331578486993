import React from 'react';

import { Spinner } from '../../spinner.component';
import { DefaultModal, DefaultModalProps } from '../default-modal/default-modal.component';

export const LoadingModal = (props: DefaultModalProps) => {
    return (
        <DefaultModal {...props}>
            <Spinner />
        </DefaultModal>
    );
};
