import { Logger } from '@frontend/Logger';
import { useAppSelector } from '@frontend/common';
import { fetchUserInterfaces, userInterfaceStore } from '@frontend/user-interface/redux';
import { UserInterface } from '@frontend/user-interface/types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { RenderUserInterfaceProps } from './render-user-interface.component';

interface ViewProps {
    userInterface: UserInterface | null;
    isLoading: boolean;
}

const useRenderUserInterface = (props: RenderUserInterfaceProps): ViewProps => {
    const UIState = useAppSelector(useSelector, userInterfaceStore);
    const [userInterface, changeUserInterface] = useState<UserInterface | null>(null);
    const [isLoading, changeIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (UIState && UIState.userInterfaces && UIState.userInterfaces.results) {
            changeIsLoading(false);
            const found = UIState.userInterfaces.results.find((ui) => ui.id == props.userInterfaceId);
            if (found) {
                Logger.log('Found user interface', {}, found);
                changeUserInterface(found);
            }
        } else changeIsLoading(true);
    }, [props.userInterfaceId, UIState]);

    useEffect(() => {
        props.dispatch(fetchUserInterfaces({}));
    }, []);

    return {
        userInterface,
        isLoading
    };
};

export default useRenderUserInterface;
