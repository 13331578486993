import { Logger } from '@frontend/Logger';
import { FormEvent, useState } from 'react';

import { TransactionVariableClient } from '../../api/transaction-variable-client';
import { UpdateTransactionVariable } from '../../transaction-variables';
import { UpdateTransactionVariableProps } from './update-transaction-variable.component';

interface FormValid {
    value: boolean;
}

interface ViewProps {
    formValid: FormValid;
    formValues: UpdateTransactionVariable;
    changeFormValues: React.Dispatch<React.SetStateAction<UpdateTransactionVariable>>;
    changeFormValid: React.Dispatch<React.SetStateAction<FormValid>>;
    onSubmit: (e: FormEvent) => void;
    submitted: boolean;
}

const useUpdateTransactionVariable = (props: UpdateTransactionVariableProps): ViewProps => {
    const [formValid, changeFormValid] = useState<FormValid>({ value: true });
    const [formValues, changeFormValues] = useState<UpdateTransactionVariable>({
        value: props.transactionVariable.value
    });
    const [submitted, changeSubmitted] = useState<boolean>(false);

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        changeSubmitted(true);
        TransactionVariableClient.patchAccountTransactionVariable(
            props.transactionVariable.account_id,
            props.transactionVariable.transaction_id,
            props.transactionVariable.id,
            formValues
        )
            .catch(() => Logger.error(`Failed to update transaction variable ${props.transactionVariable.id}`))
            .finally(props.onClose);
    };

    return {
        changeFormValid,
        changeFormValues,
        formValid,
        formValues,
        onSubmit,
        submitted
    };
};

export default useUpdateTransactionVariable;
