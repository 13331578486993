import React from 'react';

import useDateBadge from './date-badge.controller';

export interface DateBadgeProps {
    date?: Date | string | null;
    displayTime?: boolean;
    inline?: boolean;
}

export const DateBadge = (props: DateBadgeProps) => {
    const viewProps = useDateBadge(props);

    if (props.date == null) return <></>;
    return (
        <span>
            <>
                {viewProps.date}
                {props.inline ? ' ' : <br />}
                {props.displayTime && <>{viewProps.time}</>}
            </>
        </span>
    );
};
