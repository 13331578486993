import { useLocation } from 'react-router-dom';

import { NavbarItemProps } from './navbar-item.component';

interface ViewProps {
    isActive: () => boolean;
    path: () => string;
}

const useNavbarItem = (props: NavbarItemProps): ViewProps => {
    const location = useLocation();

    const isActive = () => {
        if (props.parent) {
            return location.pathname.split('/')[2] === props.link.toLowerCase();
        } else return location.pathname.split('/')[1] === props.link.toLowerCase();
    };
    const path = () => {
        if (props.parent) {
            return `/${props.parent}/${props.link.toLowerCase()}`;
        } else {
            return `/${props.link.toLowerCase()}`;
        }
    };

    return {
        isActive,
        path
    };
};

export default useNavbarItem;
