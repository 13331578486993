import { Logger } from '@frontend/Logger';
import { PubSubConnection } from '@frontend/pub-sub';
import { addSpot, removeSpot, updateSpot } from '@frontend/spot/redux';
import { Spot } from '@frontend/spot/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';



enum MessageTypes {
    UPDATED = 'spot_updated',
    DELETED = 'spot_deleted',
    CREATED = 'spot_created'
}

export class SpotEventListener extends PubSubConnection {
    private static instance: SpotEventListener | null = null;
    #dispatch: ThunkDispatch<any, any, Action>;
    private constructor(dispatch: ThunkDispatch<any, any, Action>) {
        super('spot');
        this.#dispatch = dispatch;
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>): SpotEventListener {
        if (this.instance == null) {
            this.instance = new SpotEventListener(dispatch);
        }
        return this.instance;
    }

    protected override onMessageEvent(event: MessageEvent<string>): void {
        const payload = JSON.parse(event.data) as { message: MessageTypes; data: Spot; changes?: any };
        switch (payload.message) {
            case MessageTypes.UPDATED:
                this.onUpdate(payload);
                break;
            case MessageTypes.DELETED:
                this.onDelete(payload);
                break;
            case MessageTypes.CREATED:
                this.onCreate(payload);
                break;
        }
    }

    private onUpdate(payload: { message: MessageTypes; data: Spot; changes?: any }) {
        Logger.log('Updating spot in store', { spot: payload.data.id }, payload.data);
        this.#dispatch(updateSpot(payload.data));
    }

    private onDelete(payload: { message: MessageTypes; data: Spot }) {
        Logger.log('Removing spot from store', { spot: payload.data.id }, payload.data);
        this.#dispatch(removeSpot(payload.data.id));
    }

    private onCreate(payload: { message: MessageTypes; data: Spot; changes?: any }) {
        Logger.log('Adding spot to store', { spot: payload.data.id }, payload.data);
        this.#dispatch(addSpot(payload.data));
    }
}
