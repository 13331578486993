import { useAppSelector } from '@frontend/common';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { contactStore, fetchContact } from '@frontend/contact/redux';
import { Contact } from '@frontend/contact/types';
import { ContactDetailModalWrapperProps } from './contact-detail-modal-wrapper.component';

interface ViewProps {
    contact?: Contact;
}

const useContactDetailModalWrapper = (props: ContactDetailModalWrapperProps): ViewProps => {
    const contacts = useAppSelector(useSelector, contactStore);
    const [contact, changeContact] = useState<Contact | undefined>(undefined);

    useEffect(() => {
        const found = contacts.unordered.find((c) => c.id === props.id);
        if (found) changeContact(found);
        else props.dispatch(fetchContact(props.id));
    }, [props.id, contacts]);

    return { contact };
};

export default useContactDetailModalWrapper;
