import useDataTypeInput from './data-type-input.controller';

export interface DataTypeInputProps {
    onChange: (value: string | number | boolean) => void;
    type: string;
    value: string | number | boolean | undefined | null;
    label: React.ReactNode;
    submitted: boolean;
    className?: string;
    disabled?: boolean;
}

export const DataTypeInput = (props: DataTypeInputProps) => {
    const viewProps = useDataTypeInput(props);

    return viewProps.input;
};
