import { contactStore, deleteContact, fetchContact } from '@frontend/contact/redux';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAppSelector } from '@frontend/common';
import { Contact } from '@frontend/contact/types';
import { useSelector } from 'react-redux';
import { ContactDetailPageProps } from './contact-detail-page.component';

interface ViewProps {
    contact: Contact | undefined;
    showDeleteModal: boolean;
    showUpdateModal: boolean;
    changeShowDeleteModal: (value: React.SetStateAction<boolean>) => void;
    changeShowUpdateModal: (value: React.SetStateAction<boolean>) => void;
    removeContact: () => void;
    activeSubNav: string;
    changeActiveSubNav: Dispatch<SetStateAction<string>>;
}

const useContactDetailPage = (props: ContactDetailPageProps): ViewProps => {
    const { contactId } = useParams();
    const contactState = useAppSelector(useSelector, contactStore);
    const [contact, changeContact] = useState<Contact | undefined>(undefined);
    const [showDeleteModal, changeShowDeleteModal] = useState<boolean>(false);
    const [showUpdateModal, changeShowUpdateModal] = useState<boolean>(false);
    const [activeSubNav, changeActiveSubNav] = useState<string>('events');

    useEffect(() => {
        if (!contactId) return;
        const found = contactState.unordered.find((c) => c.id == contactId);
        if (found == undefined) props.dispatch(fetchContact(contactId));
        else changeContact(found);
    }, [contactId, contactState]);

    function removeContact() {
        if (!contactId) return;
        props.dispatch(deleteContact(contactId));
        changeShowDeleteModal(false);
    }

    return {
        changeShowDeleteModal,
        changeShowUpdateModal,
        contact,
        removeContact,
        showDeleteModal,
        showUpdateModal,
        activeSubNav,
        changeActiveSubNav
    };
};

export default useContactDetailPage;
