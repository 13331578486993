import { BadgeTemplate, DetailModalType, NameBadge } from '@frontend/elements';

import React from 'react';
import useUserBadge from './user-badge.controller';

export interface UserBadgeProps {
    id?: string | null;
    first_name?: string;
    last_name?: string;
    name?: string;
}
/**
 * @deprecated 
 */

export const UserBadge = (props: UserBadgeProps) => {
    const viewProps = useUserBadge(props);

    return (
        <BadgeTemplate
            disabled={viewProps.disabled}
            type={DetailModalType.USER}
            objectId={props.id}>
            {!viewProps.displayName ? (
                <span className='placeholder'>
                    <span className='placeholder-glow'>Loading</span>
                </span>
            ) : typeof viewProps.displayName === 'string' ? (
                <>
                    <img
                        alt=''
                        className='rounded'
                        height={20}
                    />
                    {viewProps.displayName}
                </>
            ) : (
                <NameBadge
                    firstName={viewProps.displayName.first_name}
                    lastName={viewProps.displayName.last_name}
                />
            )}
        </BadgeTemplate>
    );
};
