import { PubSubConnection } from '@frontend/pub-sub';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

import { DriverEventHandler } from './driver-event-handler';

export class DriverServiceEventListener extends PubSubConnection {
    private static instance: DriverServiceEventListener | null = null;
    #deviceEventHandler: DriverEventHandler;

    private constructor(dispatch: ThunkDispatch<any, any, Action>) {
        super('edge');
        this.#deviceEventHandler = new DriverEventHandler(dispatch);
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>): DriverServiceEventListener {
        if (this.instance == null) {
            this.instance = new DriverServiceEventListener(dispatch);
        }
        return this.instance;
    }

    protected override onMessageEvent(event: MessageEvent<string>): void {
        this.#deviceEventHandler.onMessageEvent(event);
    }
}
