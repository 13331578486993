import { ContactBadge } from '@frontend/contact/components';
import { UserInterfaceBadge } from '@frontend/user-interface/common';
import { WorkflowFieldType } from '@frontend/workflow-variable/types';
import React from 'react';

import { TransactionVariable } from '../transaction-variables';

interface TransactionVariableBadgeProps {
    transactionVariable: TransactionVariable;
}

export const TransactionVariableBadge = (props: TransactionVariableBadgeProps) => {
    switch (props.transactionVariable.type) {
        case WorkflowFieldType.CONTACT_ID:
            return <ContactBadge id={props.transactionVariable.value as string} />;
        case WorkflowFieldType.USER_INTERFACE_ID:
            return <UserInterfaceBadge id={props.transactionVariable.value as string} />;
        default:
            return null;
    }
};
