import { APIClient } from "@frontend/api-utils";
import { Package, PackageStateName, PackageTriggerName } from '@frontend/package/types';

const endpoint = '/package-api/v1';

export class PackageWorkflowClient extends APIClient {
    public static async updatePackageState(accountId: string, transactionId: string, packageId: string, state: PackageStateName): Promise<Package> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/transactions/${transactionId}/packages/${packageId}/change-state`, { state: state });
        return await this.handleResponse<Package>(response);
    }

    public static async triggerPackageState(accountId: string, transactionId: string, packageId: string, trigger: { trigger: PackageTriggerName; source: any }): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/transactions/${transactionId}/packages/${packageId}/trigger`, trigger);
        return await this.handleVoidResponse(response);
    }
}