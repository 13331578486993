import { DetailPageType, useAppSelector, useDetailPage } from '@frontend/common';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';



import { workflowRequest } from '@frontend/workflow/events';
import { workflowStore } from '@frontend/workflow/redux';
import { WorkflowBadgeProps } from './workflow-badge.component';

interface ViewProps {
    displayName?: string | null;
    disabled: boolean;
    onClick: () => void;
}

const useWorkflowBadge = (props: WorkflowBadgeProps): ViewProps => {
    const navigate = useNavigate();
    const [requested, changeRequested] = useState<boolean>(false);
    const workflowState = useAppSelector(useSelector, workflowStore);
    const [disabled, changeDisabled] = useState<boolean>(false);
    const [displayName, changeDisplayName] = useState<string | undefined | null>(props.name);
    const [accountId, changeAccountId] = useState<string | undefined | null>(props.accountId);
    const { isEntityBadge, isEntityPage } = useDetailPage(DetailPageType.WORKFLOW, props.id);
    useEffect(() => {
        if (props.id) {
            const found = workflowState.unordered.find((w) => w.id == props.id);
            if (found) {
                changeDisplayName(found.name);
                changeAccountId(found.account_id);
            } else if (requested == false) {
                changeRequested(true);
                workflowRequest(props.id);
            }

            if (requested && !found) {
                changeDisabled(true);
                changeDisplayName('Not found');
            }
        } else {
            changeDisplayName('None')
        }

        if (!props.id || !accountId) changeDisabled(true);
        if (accountId && props.id) changeDisabled(false);
        if (isEntityBadge || isEntityPage) changeDisabled(true);
    }, [workflowState, props.id, accountId]);

    const onClick = () => {
        if (!props.id || !accountId) return;
        navigate(`/accounts/${accountId}/workflows/${props.id}`);
    };
    return {
        displayName,
        disabled,
        onClick
    };
};

export default useWorkflowBadge;
