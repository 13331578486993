import { StringPlaceholder } from '@frontend/elements';
import { UserInterfaceType } from '@frontend/user-interface/types';
import React from 'react';

import { UserInterfaceTemplateProps } from '../../user-interface-template-props';
import TempImageLoader from '../temp-image-loader';
import useProductNotInUse from './product-not-in-use.controller';

const ProductNotInUse = (props: UserInterfaceTemplateProps) => {
    const viewProps = useProductNotInUse(props);

    return (
        <div
            id={UserInterfaceType.PRODUCT_NOT_IN_USE}
            className='d-flex flex-column align-items-center justify-content-center text-center m-3'>
            {viewProps.product && (
                <TempImageLoader
                    product={viewProps.product}
                    className='w-md-50 w-xl-25'
                />
            )}
            <h3 className='pb-4'>
                Product{' '}
                <StringPlaceholder
                    loaded={!!viewProps.product}
                    className='col-2 bg-info'>
                    <span className='text-info'>{viewProps.product?.name}</span>
                </StringPlaceholder>{' '}
                not in use.
            </h3>
            {viewProps.slot && (
                <p>
                    It is currently in SLOT{' '}
                    <StringPlaceholder
                        loaded={!!viewProps.slot}
                        className='col-2 bg-info'>
                        <span className='text-info'>{viewProps.slot.number}</span>
                    </StringPlaceholder>
                    .
                </p>
            )}
        </div>
    );
};

export default ProductNotInUse;
