/* eslint-disable react/prop-types */
// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../react-table-config.d.ts" />
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React, { useEffect, useMemo } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { HiOutlineChevronDoubleLeft, HiOutlineChevronDoubleRight, HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';
import { FormattedMessage } from 'react-intl';
import { Column, SortingRule, useExpanded, usePagination, useResizeColumns, useRowSelect, useSortBy, useTable } from 'react-table';

import { THeader } from './headers/THeader';
import { PageNaveButton } from './pagination/PageNavButton';
import { PageNumber } from './pagination/PageNumber';
import { TRow } from './rows/TRow';
import { Checkbox } from './selection/CheckBox';

interface Props {
    selectEnabled?: boolean;
    expandEnabled?: boolean;
    selectedItemsCallback?: (objects: any[]) => void;
    columns: Column<any>[];
    data: any[];
    autoResetPage?: boolean;
    next?: string | null | undefined;
    count?: number | undefined;
    pageCount?: number | undefined;
    pageSize?: (value: number) => void;
    page?: (value: number) => void;
    onSelectElement?: (object: any) => void;
    RowElement: (props: any) => JSX.Element;
    renderSubComponent?: (props: { row: any }) => React.ReactElement;
    noPagination?: boolean;
    id?: string;
    hidePagination?: boolean;
    sorting?: (value: SortingRule<object>[]) => void;
    hideColumns?: string[];
    sortingColumns?: string[];

    dispatch?: ThunkDispatch<any, any, Action>;
}

export function Table(props: Props) {
    if (props.noPagination) {
        const tableInstance = useTable(
            {
                columns: props.columns,
                data: props.data
            },
            useSortBy,
            useResizeColumns,
            useExpanded,
            useRowSelect,
            (hooks) => {
                props.selectEnabled === true &&
                    hooks.visibleColumns.push((columns) => [
                        {
                            id: 'selection',
                            Header: ({ getToggleAllRowsSelectedProps }) => <Checkbox {...getToggleAllRowsSelectedProps()} />,
                            Cell: ({ row }) => (
                                <td>
                                    <Checkbox {...row.getToggleRowSelectedProps()} />
                                </td>
                            )
                        },
                        ...columns
                    ]);
                props.expandEnabled === true &&
                    hooks.visibleColumns.push((columns) => [
                        ...columns,
                        {
                            id: 'expand',
                            Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
                                <span {...getToggleAllRowsExpandedProps()}>{isAllRowsExpanded ? <FaChevronDown /> : <FaChevronUp />}</span>
                            ),
                            Cell: ({ row }) =>
                                // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
                                // to build the toggle for expanding a row
                                row.canExpand ? (
                                    <td>
                                        <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? <FaChevronDown /> : <FaChevronUp />}</span>
                                    </td>
                                ) : null
                        }
                    ]);
            }
        );
        const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;
        return (
            <div className='table-responsive'>
                <table
                    {...getTableProps()}
                    className='table align-items-center mb-0'>
                    <thead>
                        {headerGroups.map((headerGroup, index) => (
                            <THeader
                                key={index + '_' + headerGroup.id}
                                headerGroup={headerGroup}
                                sortingColumns={props.sortingColumns}
                            />
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.length === 0 && (
                            <tr>
                                <td>
                                    <p>No data to display</p>
                                </td>
                            </tr>
                        )}
                        {rows.map((row) => {
                            prepareRow(row);
                            return (
                                <>
                                    <TRow
                                        key={row.id}
                                        row={row}
                                        expandEnabled={props.expandEnabled}
                                        selectEnabled={props.selectEnabled}
                                        onClick={props.onSelectElement}
                                        RowElement={props.RowElement}
                                        dispatch={props.dispatch}
                                    />
                                    {row.isExpanded ? <>{props.renderSubComponent && props.renderSubComponent({ row })}</> : null}
                                </>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    } else {
        const tableInstance = useTable(
            {
                columns: props.columns,
                data: props.data,
                autoResetPage: props.autoResetPage ? props.autoResetPage : false,
                manualPagination: props.pageCount ? true : false,
                initialState: { pageSize: 25 },
                ...(props.pageCount && { pageCount: props.pageCount }),
                manualSortBy: true,
                autoResetExpanded: false
            },
            useSortBy,
            useExpanded,
            usePagination,
            useRowSelect,
            (hooks) => {
                props.selectEnabled === true &&
                    hooks.visibleColumns.push((columns) => [
                        {
                            id: 'selection',
                            Header: ({ getToggleAllRowsSelectedProps }) => <Checkbox {...getToggleAllRowsSelectedProps()} />,
                            Cell: ({ row }) => (
                                <td>
                                    <Checkbox {...row.getToggleRowSelectedProps()} />
                                </td>
                            )
                        },
                        ...columns
                    ]);
            }
        );
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            prepareRow,
            page,
            pageOptions,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            setPageSize,
            selectedFlatRows,
            state: { pageIndex, pageSize, sortBy }
        } = tableInstance;
        const pageCountPagination = props.pageCount ? props.pageCount : pageCount;
        const pagesToDisplay = useMemo(() => getPagesToDisplay(pageCountPagination, pageIndex + 1), [pageCountPagination, pageIndex]);

        useEffect(() => {
            if (props.selectEnabled && props.selectedItemsCallback) {
                props.selectedItemsCallback(
                    selectedFlatRows.filter((row) => row.index >= pageIndex * pageSize && row.index < (pageIndex + 1) * pageSize).map((r) => r.original)
                );
            }
        }, [selectedFlatRows]);
        useEffect(() => {
            setPageSize(25);
        }, []);

        useEffect(() => {
            if (props.pageSize) {
                props.pageSize(pageSize);
            }
            if (props.page) {
                props.page(pageIndex);
            }
        }, [pageSize, pageIndex]);

        useEffect(() => {
            props.sorting && props.sorting(sortBy);
        }, [sortBy]);

        useEffect(() => {
            tableInstance.setHiddenColumns(props.hideColumns ?? []);
        }, [props.hideColumns]);

        const startRow = pageSize * pageIndex;
        const endRow = startRow + pageSize;

        return (
            <div
                className='table-responsive'
                id={props.id}>
                {!props.hidePagination && (
                    <div className='d-flex justify-content-between p-2'>
                        <div className='d-flex justify-content-start'>
                            <div className='d-flex flex-row dataTable-dropdown align-items-center'>
                                <select
                                    className='form-control me-2'
                                    value={pageSize}
                                    onChange={(e) => {
                                        setPageSize(Number(e.target.value));
                                    }}>
                                    {[25, 50, 100, 150].map((pageSize) => (
                                        <option
                                            key={pageSize}
                                            value={pageSize}>
                                            {pageSize}
                                        </option>
                                    ))}
                                </select>
                                <span className='text-nowrap'>
                                    <FormattedMessage
                                        id='table.entriesPerPage'
                                        description='The entries per page in the default table message.'
                                        defaultMessage='entries per page'
                                    />
                                </span>
                            </div>
                        </div>
                        {/* {allColumns.filter((col) => props.hideColumns && !props.hideColumns.includes(col.id)).map((column) => (
                            <div key={column.id}>
                                <label>
                                    <input
                                        type='checkbox'
                                        {...column.getToggleHiddenProps()}
                                    />{' '}
                                    {column.id}
                                </label>
                            </div>
                        ))} */}
                        <span className='d-flex flex-row align-items-center'>
                            <FormattedMessage
                                id='table.currentPage'
                                description='The current page in the default table message.'
                                defaultMessage='Page'
                            />
                            <input
                                className='form-control mx-1'
                                type='number'
                                defaultValue={pageIndex + 1}
                                value={pageIndex + 1}
                                onChange={(e) => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                    gotoPage(page);
                                }}
                                style={{ width: '75px' }}
                            />
                            <strong>
                                <FormattedMessage
                                    id='table.currentPage.ofPages'
                                    description='The current page of total amount of pages in the default table message.'
                                    defaultMessage='of'
                                />
                                {' ' + (props.pageCount ? pageCountPagination : pageOptions.length)}
                            </strong>
                        </span>

                        {pageCountPagination > 1 && (
                            <ul className='pagination pagination-primary'>
                                <PageNaveButton
                                    icon={<HiOutlineChevronDoubleLeft />}
                                    onClick={() => gotoPage(0)}
                                    disabled={pageIndex === 0}
                                />
                                <PageNaveButton
                                    icon={<HiOutlineChevronLeft />}
                                    onClick={() => previousPage()}
                                    disabled={pageIndex === 0}
                                />

                                {pagesToDisplay.map((n) => (
                                    <PageNumber
                                        key={n}
                                        page={n}
                                        currentPage={pageIndex + 1}
                                        onClick={() => {
                                            props.page && props.page(n - 1);
                                            gotoPage(n - 1);
                                        }}
                                    />
                                ))}

                                <PageNaveButton
                                    icon={<HiOutlineChevronRight />}
                                    onClick={() => {
                                        props.page && props.page(pageIndex + 1);
                                        nextPage();
                                    }}
                                    disabled={pageIndex === pageCount - 1}
                                />
                                <PageNaveButton
                                    icon={<HiOutlineChevronDoubleRight />}
                                    onClick={() => gotoPage(pageCountPagination - 1)}
                                    disabled={pageIndex === pageCount - 1}
                                />
                            </ul>
                        )}
                    </div>
                )}

                <table
                    {...getTableProps()}
                    className='table align-items-center mb-0'>
                    <thead>
                        {headerGroups.map((headerGroup, index) => (
                            <THeader
                                key={index + '_' + headerGroup.id}
                                headerGroup={headerGroup}
                                sortingColumns={props.sortingColumns}
                            />
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                        {page.length === 0 && (
                            <tr>
                                <td>
                                    <p>No data to display</p>
                                </td>
                            </tr>
                        )}
                        {props.pageCount
                            ? page.map((row) => {
                                  if (startRow <= row.index && row.index < endRow) {
                                      prepareRow(row);
                                      return (
                                          <>
                                              <TRow
                                                  key={row.id}
                                                  row={row}
                                                  expandEnabled={props.expandEnabled}
                                                  selectEnabled={props.selectEnabled}
                                                  onClick={props.onSelectElement}
                                                  RowElement={props.RowElement}
                                                  dispatch={props.dispatch}
                                              />
                                              {row.isExpanded ? <>{props.renderSubComponent && props.renderSubComponent({ row })}</> : null}
                                          </>
                                      );
                                  } else return <></>;
                              })
                            : page.map((row) => {
                                  prepareRow(row);
                                  return (
                                      <>
                                          <TRow
                                              key={row.id}
                                              row={row}
                                              expandEnabled={props.expandEnabled}
                                              selectEnabled={props.selectEnabled}
                                              onClick={props.onSelectElement}
                                              RowElement={props.RowElement}
                                              dispatch={props.dispatch}
                                          />
                                          {row.isExpanded ? <>{props.renderSubComponent && props.renderSubComponent({ row })}</> : null}
                                      </>
                                  );
                              })}
                    </tbody>
                </table>
                {!props.hidePagination && (
                    <>
                        {pageCountPagination > 1 && <hr className='horizontal dark my-3' />}

                        {pageCountPagination > 1 && (
                            <div className='d-flex flex-row justify-content-between align-items-center m-2'>
                                <span className='d-flex flex-row align-items-center'>
                                    <FormattedMessage
                                        id='table.currentPage'
                                        description='The current page in the default table message.'
                                        defaultMessage='Page'
                                    />
                                    <input
                                        className='form-control mx-1'
                                        type='number'
                                        defaultValue={pageIndex + 1}
                                        onChange={(e) => {
                                            const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                            gotoPage(page);
                                        }}
                                        style={{ width: '75px' }}
                                    />
                                    <strong>
                                        <FormattedMessage
                                            id='table.currentPage.ofPages'
                                            description='The current page of total amount of pages in the default table message.'
                                            defaultMessage='of'
                                        />
                                        {' ' + (props.pageCount ? pageCountPagination : pageOptions.length)}
                                    </strong>
                                </span>

                                <ul className='pagination pagination-primary'>
                                    <PageNaveButton
                                        icon={<HiOutlineChevronDoubleLeft />}
                                        onClick={() => gotoPage(0)}
                                    />
                                    <PageNaveButton
                                        icon={<HiOutlineChevronLeft />}
                                        onClick={() => previousPage()}
                                    />

                                    {pagesToDisplay.map((n) => (
                                        <PageNumber
                                            key={n}
                                            page={n}
                                            currentPage={pageIndex + 1}
                                            onClick={() => {
                                                props.page && props.page(n - 1);
                                                gotoPage(n - 1);
                                            }}
                                        />
                                    ))}

                                    <PageNaveButton
                                        icon={<HiOutlineChevronRight />}
                                        onClick={() => {
                                            props.page && props.page(pageIndex + 1);
                                            nextPage();
                                        }}
                                    />
                                    <PageNaveButton
                                        icon={<HiOutlineChevronDoubleRight />}
                                        onClick={() => gotoPage(pageCountPagination - 1)}
                                    />
                                </ul>
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    }
}

function getPagesToDisplay(pageCount: number, currentPage: number): number[] {
    const pagesToDisplay = 5; //should always be odd
    const pagesOnEverySide = (pagesToDisplay - (pagesToDisplay % 2)) / 2;
    let pages: number[] = [];

    for (let i = currentPage - pagesOnEverySide; i <= currentPage + pagesOnEverySide; i++) {
        if (i < 1) continue;
        if (i > pageCount) break;
        pages.push(i);
    }

    while (pages.length < pagesToDisplay) {
        if (pages[0] > 1) {
            pages = [pages[0] - 1, ...pages];
        } else if (pages.indexOf(currentPage) !== pagesOnEverySide + 1 && pages[pages.length - 1] < pageCount) {
            pages = [...pages, pages[pages.length - 1] + 1];
        } else break;
    }
    return pages;
}
