import React from 'react';

interface Props {
    onClick: () => void;
    icon: JSX.Element;
    disabled?: boolean;
}

export const PageNaveButton = (props: Props) => {
    const buttonClass = props.disabled ? 'page-link disabled' : 'page-link';
    return (
        <li
            className='page-item'
            onClick={props.disabled ? undefined : props.onClick}>
            <button className={buttonClass}>
                <span aria-hidden='true'>{props.icon}</span>
            </button>
        </li>
    );
};
