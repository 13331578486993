import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { ObjectSelectInputProps } from './object-select-input.component';

interface ViewProps<T extends { id: string }> {
    options: { value: string; label: string }[];
    value?: T | null;
    onScrollToBottom: () => void;
    search: (inputValue: string, callback: (options: { value: string; label: string }[]) => void) => void;
    onChange: (value: any) => void;
    overwriteDisabled?: boolean;
}

const pageSize = '100';

const useObjectSelectInput = <T extends { id: string }, D extends string | number>(props: ObjectSelectInputProps<T, D>): ViewProps<T> => {
    const params = useParams();
    const [urlOverwrite, changeUrlOverwrite] = useState<T | null>(null);
    const [cache, changeCache] = useState<T[]>([]);
    const timer = useRef<NodeJS.Timeout | null>(null);
    const [count, changeCount] = useState<number>(0);
    const [index, changeIndex] = useState<number>(0);
    const [options, changeOptions] = useState<{ value: string; label: string }[]>([]);
    const { queryParam, onQueryParamChange } = useChangeUrl(props.useQueryParam?.param);
    const onChange = (value: string) => {
        const object = cache.find((o) => o.id === value);
        props.onChange && props.onChange(object ?? null);
    };

    const value: T | null = useMemo(() => {
        !urlOverwrite && onQueryParamChange(props.value?.id ?? null);
        if (options.length === 1) {
            const found = cache.find((o) => o.id === options[0].value);
            if (!found) return null;
            props.onChange && props.onChange(found);
        }
        return props.value ?? null;
    }, [props.value, options]);
    useEffect(() => {
        if (props.useUrlOverwrite) {
            const param = params[props.useUrlOverwrite.param];
            if (param !== undefined)
                props.useUrlOverwrite.fetch(param).then((result) => {
                    changeUrlOverwrite(result);
                    props.onChange && props.onChange(result);
                });
        }
    }, [props.useUrlOverwrite]);

    useEffect(() => {
        if (props.useQueryParam && value === null && queryParam !== null) {
            props.useQueryParam.fetch(queryParam).then((result) => {
                props.onChange && props.onChange(result);
            });
        }
    }, [props.useQueryParam, queryParam, value]);

    //TODO: this usePrevious is used because the useEffect triggered incorrectly (but should be investigated and fixed properly later when we have the time)
    const previous = usePrevious({ params: props.queryParams, index: index });
    useEffect(() => {
        if (previous == undefined || JSON.stringify(previous.params) != JSON.stringify(props.queryParams) || previous.index != index) {
            props.fetch({ index: index.toString(), size: pageSize, ...props.queryParams }).then((result) => {
                changeCount(result.count);
                if (index == 0) {
                    changeOptions(mapResultSetToOptions(result.results, props.itemLabel));
                    changeCache(result.results);
                } else {
                    changeOptions((options ?? []).concat(mapResultSetToOptions(result.results, props.itemLabel)));
                    changeCache((cache ?? []).concat(result.results));
                }
            });
        }
    }, [index, props.queryParams]);

    const search = (inputValue: string, callback: (options: { value: any; label: string }[]) => void) => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
            props.fetch({ search: inputValue, index: '0', size: pageSize, ...props.queryParams }).then((result) => {
                callback(mapResultSetToOptions(result.results, props.itemLabel));
                changeCache(result.results);
            });
        }, 500);
    };

    const onScrollToBottom = () => {
        if (count / parseInt(pageSize) > 1 && parseInt(pageSize) * index < count) {
            changeIndex(index + 1);
        }
    };

    return {
        options,
        value,
        onScrollToBottom,
        search,
        onChange,
        overwriteDisabled: urlOverwrite !== null || options.length === 1
    };
};

export default useObjectSelectInput;

function mapResultSetToOptions<T extends { id: string }>(result: T[], labels: (item: T) => string): { value: any; label: string }[] {
    return result.map((item) => ({
        value: item.id,
        label: labels(item) ?? item.id
    }));
}

function usePrevious<T>(value: T) {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const useChangeUrl = (key?: string): { queryParam: string | null; onQueryParamChange: (value: string | null) => void } => {
    const [searchParams, setSearchParams] = useSearchParams();
    const param = key && searchParams.get(key);
    const onChange = (value: string | null) => {
        if (value && key) {
            setSearchParams((prev) => {
                const params = new URLSearchParams(prev);
                if (params.has(key)) {
                    params.set(key, value);
                } else {
                    params.append(key, value);
                }
                return params;
            });
        } else if (key) {
            setSearchParams((prev) => {
                const params = new URLSearchParams(prev);
                params.delete(key);
                return params;
            });
        }
    };

    return {
        queryParam: param || null,
        onQueryParamChange: onChange
    };
};
