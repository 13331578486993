import { ClassType } from '@frontend/common';
import { DefaultModal, Filter, HorizontalButtonGroup } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';
import { Table } from '@frontend/table';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React, { lazy } from 'react';
import { FaPlus } from 'react-icons/fa';

import CreateNotificationTemplate from '../forms/create-notification-template/create-notification-template.component';
import NotificationTemplateListElement from './notification-template-element';
import useNotificationTemplateList from './notification-template-list.controller';

const AccountSelect = lazy(() => import('@frontend/account/common').then((module) => ({ default: module.AccountSelect })));

const ID = 'notification-template-list';

export interface NotificationTemplateListProps {
    dispatch: ThunkDispatch<any, any, Action>;
}

export const NotificationTemplateList = (props: NotificationTemplateListProps) => {
    const viewProps = useNotificationTemplateList(props);

    return (
        <div id={ID}>
            <div className='card mt-3'>
                <div className='card-header d-flex justify-content-between align-items-center'>
                    <HorizontalButtonGroup
                        noFlex
                        direction='left'
                        buttons={[
                            {
                                hide: false,
                                icon: FaPlus,
                                type: ClassType.PRIMARY,
                                id: 'createNotificationTemplateButton',
                                text: CommonMessage.FORMS.CREATE_OBJECT('Notification Template'),
                                onClick: () => viewProps.changeShowCreateModal(true)
                            }
                        ]}
                    />
                    <div className='d-flex flex-row'>
                        <div className='px-1'>
                            <AccountSelect
                                submitted={false}
                                onChange={(v) => {
                                    if (v) {
                                        viewProps.setSearchParams({ account: v });
                                    } else {
                                        viewProps.setSearchParams(undefined);
                                    }
                                }}
                                isClearable
                                value={viewProps.accountId}
                                dispatch={props.dispatch}
                            />
                        </div>
                        <Filter filterValue={viewProps.changeSearchValue} />
                    </div>
                </div>
                <div className='card-body'>
                    <Table
                        id={ID + '-table'}
                        columns={viewProps.columns}
                        data={viewProps.data}
                        RowElement={(row) => NotificationTemplateListElement({ ...row, dispatch: props.dispatch })}
                        page={(value) => viewProps.setPage(value + 1)}
                        pageSize={(value) => viewProps.setPageSize(value)}
                        pageCount={viewProps.pageCount}
                        sorting={viewProps.changeSortState}
                    />
                </div>
            </div>
            {viewProps.showCreateModal && (
                <DefaultModal
                    handleClose={() => viewProps.changeShowCreateModal(false)}
                    show={viewProps.showCreateModal}>
                    <CreateNotificationTemplate
                        onClose={() => viewProps.changeShowCreateModal(false)}
                        dispatch={props.dispatch}
                    />
                </DefaultModal>
            )}
        </div>
    );
};
