import { Restriction } from '@frontend/authorization';
import { Column } from 'react-table';

const RestrictionListColumns: Column<Restriction>[] = [
    {
        Header: 'type',
        accessor: 'type'
    },
    {
        Header: 'key',
        accessor: 'key'
    },
    {
        Header: 'value',
        accessor: 'value'
    },
    {
        Header: '',
        id: 'id'
    }
];

export default RestrictionListColumns;
