import { Location } from '@frontend/common';
import { useEffect, useState } from 'react';

import { LocationInputProps } from './location-input.component';

interface ViewProps {
    location: Location;
    changeLocation: React.Dispatch<React.SetStateAction<Location>>;
}

const useLocationInput = (props: LocationInputProps): ViewProps => {
    const [location, changeLocation] = useState<Location>(props.value || { x: 0, y: 0, z: 0, rotation: 0 });

    useEffect(() => {
        props.onChange(location);
    }, [location]);

    return {
        location,
        changeLocation
    };
};

export default useLocationInput;
