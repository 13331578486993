import React from "react";
import { FormattedMessage } from "react-intl";

export enum WorkflowActionName {
    SHOW_USER_INTERFACE = 'show_user_interface'
}

export class WorkflowAction {
    static readonly SHOW_USER_INTERFACE = new WorkflowAction(
        WorkflowActionName.SHOW_USER_INTERFACE,
        (
            <FormattedMessage
                id='WorkflowGeneralAction.show_user_interface'
                description='The displayed value for the WorkflowGeneralAction show_user_interface'
                defaultMessage='Show user interface'
            />
        )
    );

    static readonly ALL = [WorkflowAction.SHOW_USER_INTERFACE];

    constructor(
        public readonly value: WorkflowActionName | string,
        public readonly displayName: React.ReactNode
    ) {}

    static getByValue(action: string) {
        return this.ALL.find((a) => a.value == action);
    }
}