import { Role } from '@frontend/authorization';
import { Column } from 'react-table';

const RoleListColumns: Column<Role>[] = [
    {
        Header: 'Role name',
        accessor: 'name'
    },
    {
        Header: 'Item',
        accessor: 'uid'
    },
    {
        Header: '',
        accessor: 'id'
    }
];

export default RoleListColumns;
