import { DefaultSelectProps, SelectInput } from '@frontend/basic-forms';
import { TransactionType } from '@frontend/transaction/types';
import React from 'react';

const ID = 'transaction-type-select';

export const TransactionTypeSelect = (props: DefaultSelectProps) => {
    return (
        <SelectInput
            {...props}
            id={props.id || ID}
            className={props.className}
            label={props.label || 'Transaction type'}
            submitted={props.submitted}
            required={props.required}
            options={Object.entries(TransactionType).map((k) => ({ value: k[1], label: k[0] }))}
            value={props.value}
            onChange={(newValue) => props.onChange && props.onChange((newValue as { value: TransactionType; label: string })?.value)}
        />
    );
};
