export interface Actuator {
    device_ids: string[] | null;
    type: ActuatorType;
    data: any;
}

export enum ActuatorType {
    OPEN = 'open',
    QUERY = 'query',
    SERVICE = 'service'
}

export interface postActuator {
    type: ActuatorType;
    data?: {[key: string]: any}
}
