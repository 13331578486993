import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { FormatTypeName } from '@frontend/impex/types';
import { CreateMovement, Movement, MovementListResponse, MovementQueryParams, MovementReportListResponse } from '@frontend/stock/types';

const endpoint = '/stock-api/v1';

export class MovementClient extends APIClient {
    static async fetchMovements(queryParams: ApiQueryParams<DefaultQueryParams>): Promise<MovementListResponse> {
        return this.apiPaginated<MovementListResponse, DefaultQueryParams>(`${endpoint}/movements`, queryParams);
    }

    static async resolveMovements(ids: string[]): Promise<MovementListResponse> {
        const response = await this.post(`${endpoint}/movements-resolve`, { ids: ids });
        return this.handleResponse<MovementListResponse>(response);
    }

    static async fetchSpotModuleSlotMovements(
        spotId: string,
        moduleId: string,
        slotId: string,
        queryParams: ApiQueryParams<DefaultQueryParams>
    ): Promise<MovementListResponse> {
        return this.apiPaginated<MovementListResponse, DefaultQueryParams>(
            `${endpoint}/spots/${spotId}/modules/${moduleId}/slots/${slotId}/movements`,
            queryParams
        );
    }

    static async postSpotModuleSlotMovement(spotId: string, moduleId: string, slotId: string, movement: CreateMovement): Promise<Movement> {
        const response = await this.post(`${endpoint}/spots/${spotId}/modules/${moduleId}/slots/${slotId}/movements`, movement);
        return this.handleResponse<Movement>(response);
    }

    static async fetchSpotModuleSlotMovement(spotId: string, moduleId: string, slotId: string, movementId: string): Promise<Movement> {
        const response = await this.fetch(`${endpoint}/spots/${spotId}/modules/${moduleId}/slots/${slotId}/movements/${movementId}`);
        return this.handleResponse<Movement>(response);
    }

    static async deleteSpotModuleSlotMovement(spotId: string, moduleId: string, slotId: string, movementId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/spots/${spotId}/modules/${moduleId}/slots/${slotId}/movements/${movementId}`);
        return this.handleVoidResponse(response);
    }

    //Reports

    static async fetchMovementReport(queryParams: ApiQueryParams<DefaultQueryParams | MovementQueryParams>): Promise<MovementReportListResponse> {
        return this.apiPaginated<MovementReportListResponse, DefaultQueryParams | MovementQueryParams>(`${endpoint}/report-movements`, queryParams);
    }

    // Export

    static async exportMovements(format: FormatTypeName): Promise<void> {
        const response = await this.fetch(`${endpoint}/movements-export?format_type=${format}`);
        await this.handleFileDownload(response, 'stock-updates-export');
    }
}
