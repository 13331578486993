import { PackageClient } from '@frontend/package/api';
import { seedPackages } from '@frontend/package/redux';
import { Package } from '@frontend/package/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';

declare global {
    interface WindowEventMap {
        'usePackage:request': CustomEvent<string>;
    }
}

const REQUEST_PACKAGE_EVENT = 'usePackage:request';
const STORAGE_KEY = 'PCK_REQ';

export function packageRequest(packageId: string) {
    window.dispatchEvent(new CustomEvent(REQUEST_PACKAGE_EVENT, { detail: packageId }));
}

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
}

export function usePackages(props: Props) {
    const [pending, changePending] = useState<string[]>([]);
    const [timeoutSet, changeTimeoutSet] = useState<boolean>(false);
    const [timeoutFinished, changeTimeoutFinished] = useState<boolean>(false);

    useEffect(() => {
        window.addEventListener(REQUEST_PACKAGE_EVENT, listener);
    }, []);

    useEffect(() => {
        if (timeoutFinished) {
            const stored = localStorage.getItem(STORAGE_KEY);
            if (stored != null) {
                changePending(JSON.parse(stored));
                changeTimeoutSet(false);
                changeTimeoutFinished(false);
                localStorage.removeItem(STORAGE_KEY);
            }
        }
    }, [timeoutFinished]);

    useEffect(() => {
        if (pending.length > 0) {
            getPackages(pending).then((result) => {
                props.dispatch(seedPackages(result));
                changePending([]);
                changeTimeoutSet(false);
                changeTimeoutFinished(false);
            });
        }
    }, [pending]);

    const listener = (event: CustomEvent) => {
        if (typeof event.detail == 'string') {
            if (timeoutSet === false) {
                changeTimeoutSet(true);
                setTimeout(() => {
                    changeTimeoutFinished(true);
                }, 1000);
            }
            const stored = localStorage.getItem(STORAGE_KEY);
            if (stored == null) {
                localStorage.setItem(STORAGE_KEY, JSON.stringify([event.detail]));
            } else if (!JSON.parse(stored).includes(event.detail)) {
                localStorage.setItem(STORAGE_KEY, JSON.stringify([...JSON.parse(stored), event.detail]));
            }
        }
    };
}

async function getPackages(packageIds: string[]): Promise<Package[]> {
    const response = await PackageClient.resolvePackages(packageIds);
    return response.results;
}
