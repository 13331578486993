import React from 'react';

import usePhoneInput from './phone-input.controller';

const ID = 'phone-input';
export interface PhoneInputProps {
    required?: boolean;
    valid?: boolean;
    isValidCallback?: (valid: boolean) => void;
    onChange?: (value: string) => void;
    onFocusChange?: () => void;
    value?: string;
    errorMessage?: React.ReactNode;
    label?: React.ReactNode;
    helpText?: React.ReactNode;
    submitted: boolean;
    id?: string;

    className?: string;
}

export const PhoneInput = (props: PhoneInputProps) => {
    const viewProps = usePhoneInput(props);

    return (
        <div
            id={ID}
            className={'form-group ' + props.className}>
            <label>
                {props.required ? <span className='text-danger me-1'>&#9679;</span> : <></>}
                {props.label ? props.label : 'Phone number'}
            </label>
            <input
                className={viewProps.inputClass}
                id={props.id ? props.id : 'PhonenumberInput'}
                value={props.value}
                type='tel'
                placeholder='ex. +32123456789'
                onFocus={() => {
                    viewProps.changeTouched(true);
                    props.onFocusChange && props.onFocusChange();
                }}
                required={props.required}
                onChange={(event) => {
                    if (props.onChange && viewProps.allowedInput(event.target.value)) props.onChange(event.target.value);
                    viewProps.isValid();
                }}
            />
            {viewProps.valid === false && viewProps.touched === true ? (
                <span className='badge bg-gradient-danger mt-2'>
                    <small>{props.errorMessage ? props.errorMessage : 'Please enter a valid phone number.'}</small>
                </span>
            ) : (
                <></>
            )}
        </div>
    );
};
