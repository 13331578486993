import { PaginatedResponse } from '@frontend/api-utils';
import { Locale } from '@frontend/common';
import { UserStateName } from './user-state';

export interface User {
    first_name: string;
    last_name: string;
    title: UserTitle;
    language: Locale;
    workflow_id: string | null;
    account_id: string;
    email: string;
    id: string;
    state: UserStateName;
    creation_timestamp: string;
    update_timestamp: string;
}

export type UserListResponse = PaginatedResponse<User>;

export interface CreateUser {
    title: UserTitle;
    first_name?: string | null;
    last_name?: string | null;
    phone_number?: string | null;
    state?: UserStateName | null;
    email?: string;
    workflow_id?: string | null;
    language: Locale;
}

export interface UpdateUser {
    first_name?: string;
    last_name?: string;
    workflow_id?: string | null;
    phone_number?: string | null;
    title: UserTitle;
    language: Locale;
}

export enum UserQueryParams {
    USER_ID = 'user_id',
    ACCOUNT_ID = 'account_id'
}

export enum UserTitle {
    MISTER = 'Mr.',
    MISSES = 'Ms.',
    NONE = ""
}
