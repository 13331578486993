import { ProductClient } from '@frontend/product/api';
import { Product } from '@frontend/product/types';
import { useEffect, useState } from 'react';

import { CheckoutProps } from './checkout.component';

interface ViewProps {
    products: Product[] | null;
    product: Product | null;
    changeProduct: React.Dispatch<React.SetStateAction<Product | null>>;
}

const useCheckout = (props: CheckoutProps): ViewProps => {
    const [product, changeProduct] = useState<Product | null>(null);
    const [products, changeProducts] = useState<Product[] | null>(null);

    useEffect(() => {
        ProductClient.resolveProducts(props.packages.map((p) => p.product_id)).then((response) => changeProducts(response.results));
    }, []);

    return {
        products,
        product,
        changeProduct
    };
};

export default useCheckout;
