import { useEffect, useMemo, useRef, useState } from 'react';

import { WorkflowClient } from '@frontend/workflow/api';
import { Workflow } from '@frontend/workflow/types';
import { useSearchParams } from 'react-router-dom';
import { WorkflowSelectProps } from './workflow-select.component';

interface ViewProps {
    options: { value: string; label: string }[];
    value?: { value: string; label: string } | null;
    onScrollToBottom: () => void;
    search: (inputValue: string, callback: (options: { value: any; label: string }[]) => void) => void;
    onChange: (value: any) => void;
    showCreateModal: boolean;
    changeShowCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
    overwriteDisabled?: boolean;
}

const pageSize = '100';
const useWorkflowSelect = (props: WorkflowSelectProps): ViewProps => {
    const timer = useRef<NodeJS.Timeout | null>(null);
    const [showCreateModal, changeShowCreateModal] = useState<boolean>(false);
    const [count, changeCount] = useState<number>(0);
    const [index, changeIndex] = useState<number>(0);
    const [options, changeOptions] = useState<{ value: string; label: string }[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const workflow = searchParams.get('workflow');
    const value = useMemo(() => {
        let result = undefined;
        if (options.length === 1) {
            props.onChange && props.onChange(options[0].value);
            result = options[0];
        }
        else if (props.allowURLChange) result = options.find((o) => o && o.value === workflow);
        else if (typeof props.value == 'string') result = options.find((o) => o && o.value == props.value);
        else result = props.value;
        return result;
    }, [props.value, options, workflow]);

    //TODO: this usePrevieus is used because the useEffect triggered incorrectly (but should be investigated and fixed properly later when we have the time)
    const previeus = usePrevious({params: props.queryParams, index: index});
    useEffect(() => {
        if(previeus == undefined || JSON.stringify(previeus.params) != JSON.stringify(props.queryParams) || previeus.index != index) {
            WorkflowClient
                .fetchWorkflows({ index: index.toString(), size: pageSize, ...props.queryParams })
                .then((response) => {
                    changeCount(response.count);
                    if (index == 0) changeOptions(mapResultSetToOptions(response.results));
                    else changeOptions((options ?? []).concat(mapResultSetToOptions(response.results)));
                });
        }
    }, [index, props.queryParams]);

    const search = (inputValue: string, callback: (options: { value: any; label: string }[]) => void) => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
            WorkflowClient.fetchWorkflows({ search: inputValue, index: '0', size: pageSize, ...props.queryParams }).then((result) => {
                callback(mapResultSetToOptions(result.results));
            });
        }, 500);
    };

    const onScrollToBottom = () => {
        if (count / parseInt(pageSize) > 1 && parseInt(pageSize) * index < count) {
            changeIndex(index + 1);
        }
    };

    const onChange = (value: any) => {
        const newValue = value.value
        if (props.allowURLChange) {
            if (newValue != null) {
                setSearchParams((prev) => {
                    const params = new URLSearchParams(prev);
                    if (params.has('workflow')) {
                        params.set('workflow', newValue);
                    } else {
                        params.append('workflow', newValue);
                    }
                    return params;
                });
            } else {
                setSearchParams((prev) => {
                    const params = new URLSearchParams(prev);
                    params.delete('workflow');
                    return params;
                });
            }
        } else props.onChange && props.onChange(newValue);
    };

    return {
        options,
        value,
        onScrollToBottom,
        search,
        onChange,
        showCreateModal,
        changeShowCreateModal,
        overwriteDisabled: options.length === 1
    };
};

function mapResultSetToOptions(workflows: Workflow[]): { value: any; label: string }[] {
    return workflows.map((a) => ({ value: a.id, label: a.name }));
}

export default useWorkflowSelect;

function usePrevious<T>(value: T) {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}