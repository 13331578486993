import { useEffect, useState } from 'react';

import { getClassName } from '../input-class';
import { Props } from './email-input.component';

interface ViewProps {
    valid: boolean;
    touched: boolean;
    inputClass: string;
    isValid: () => boolean;
    changeTouched: React.Dispatch<React.SetStateAction<boolean>>;
}

const emailRegex = /(.+)@(.+){2,}\.(.+){2,}/;

const useEmailInput = (props: Props): ViewProps => {
    const [touched, changeTouched] = useState<boolean>(false);
    const [valid, changeValid] = useState<boolean>(props.valid !== undefined ? props.valid : true);

    const isValid = (): boolean => {
        let check = true;
        if (check === true && props.required === true && (props.value === '' || props.value === undefined || !emailRegex.test(props.value))) check = false;
        if (props.valid !== undefined) check = props.valid;
        changeValid(check);
        return check;
    };
    useEffect(() => {
        if (props.isValidCallback) {
            props.isValidCallback(isValid());
        } else isValid();
    }, [props.value, props.valid]);

    useEffect(() => {
        if (props.submitted) {
            !touched && changeTouched(true);
        }
    }, [props.submitted]);

    return {
        valid,
        touched,
        inputClass: props.noConditionedStyling ? 'form-control' : getClassName(touched, valid, props.value),
        isValid,
        changeTouched
    };
};

export default useEmailInput;
