import React from 'react';
import { IntlProvider } from 'react-intl';

import useLocale from '../useLocale';

export interface TranslationProviderProps {
    children: React.ReactNode;
}

export const TranslationProvider = (props: TranslationProviderProps) => {
    const { messages, locale } = useLocale();

    return (
        <IntlProvider
            messages={messages}
            defaultLocale={locale}
            locale='en'>
            {props.children}
        </IntlProvider>
    );
};

export default TranslationProvider;
