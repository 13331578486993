import { useAppSelector, useChangeUrl } from '@frontend/common';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { SpotClient } from '@frontend/spot/api';
import { SpotState, fetchSpots, spotStore } from '@frontend/spot/redux';
import { Spot } from '@frontend/spot/types';
import { SpotSelectProps } from './spot-select.component';

interface ViewProps {
    options: { value: string; label: string }[];
    value?: { value: string; label: string } | null;
    onScrollToBottom: () => void;
    search: (inputValue: string, callback: (options: { value: any; label: string }[]) => void) => void;
    onChange: (value?: string | null, allowURLChange?: boolean) => string | null | undefined;
}

const pageSize = '100';
const useSpotSelect = (props: SpotSelectProps): ViewProps => {
    const timer = useRef<NodeJS.Timeout | null>(null);
    const [count, changeCount] = useState<number>(0);
    const [index, changeIndex] = useState<number>(0);
    const spotState = useAppSelector<SpotState>(useSelector, spotStore);
    const [options, changeOptions] = useState<{ value: string; label: string }[]>([]);
    const [searchParams] = useSearchParams();
    const spot = searchParams.get('spot');
    const { onChange } = useChangeUrl('spot', (value) => {props.onChange && props.onChange(value ?? undefined)});

    const value = useMemo(() => {
        if (props.allowURLChange) return options.find((o) => o && o.value === spot);
        if (typeof props.value == 'string') return options.find((o) => o && o.value == props.value);
        return props.value;
    }, [props.value, options, spot]);

    useEffect(() => {
        props.dispatch(fetchSpots({ index: index.toString(), size: pageSize }));
    }, [index]);

    useEffect(() => {
        if (spotState.spots) {
            changeCount(spotState.spots.count);
            changeOptions(mapResultSetToOptions(spotState.spots.results));
        }
    }, [spotState.spots]);

    const search = (inputValue: string, callback: (options: { value: any; label: string }[]) => void) => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
            SpotClient.fetchSpots({ search: inputValue, index: '0', size: pageSize }).then((result) => {
                callback(mapResultSetToOptions(result.results));
            });
        }, 500);
    };

    const onScrollToBottom = () => {
        if (count / parseInt(pageSize) > 1 && parseInt(pageSize) * index < count) {
            changeIndex(index + 1);
        }
    };

    return {
        options,
        value,
        onScrollToBottom,
        search,
        onChange
    };
};

function mapResultSetToOptions(spots: Spot[]): { value: any; label: string }[] {
    return spots.map((s) => ({ value: s.id, label: s.name }));
}

export default useSpotSelect;
