export enum ConstraintType {
    ACCOUNT = 'account',
    WORKFLOW = 'workflow',
    DIMENSION = 'dimension',
    OCCUPANCY = 'occupancy',
    PRODUCT = 'product',
    STOCK = 'stock',
    TRANSACTION = 'transaction',
    LOCATION = 'location'
}

export enum ConstraintQueryParams {
    SLOT_ID = 'slot_id',
    SPOT_ID = 'spot_id'
}

export type ConstraintData =
    | DimensionConstraintData
    | ProductConstraintData
    | AccountConstraintData
    | WorkflowConstraintData
    | OccupancyConstraintData
    | StockConstraintData
    | TransactionConstraintData
    | LocationConstraintData;

export const isAccountConstraintData = (constraintData: any): constraintData is AccountConstraintData => {
    return (
        constraintData &&
        (constraintData as AccountConstraintData).account_id !== undefined &&
        (constraintData as AccountConstraintData).percentage !== undefined
    );
};

export interface AccountConstraintData {
    account_id?: string;
    percentage?: number;
}

export const isWorkflowConstraintData = (constraintData: any): constraintData is WorkflowConstraintData => {
    return (
        constraintData &&
        (constraintData as WorkflowConstraintData).account_id !== undefined &&
        (constraintData as WorkflowConstraintData).workflow_id !== undefined
    );
};

export interface WorkflowConstraintData {
    account_id?: string;
    workflow_id?: string;
}

export const isDimensionConstraintData = (constraintData: any): constraintData is DimensionConstraintData => {
    return (
        constraintData &&
        (constraintData as DimensionConstraintData).height !== undefined &&
        (constraintData as DimensionConstraintData).width !== undefined &&
        (constraintData as DimensionConstraintData).depth !== undefined
    );
};
export interface DimensionConstraintData {
    height?: number;
    width?: number;
    depth?: number;
}

export const isProductConstraintData = (constraintData: any): constraintData is ProductConstraintData => {
    return constraintData && (constraintData as ProductConstraintData).type !== undefined && (constraintData as ProductConstraintData).product_id !== undefined;
};

export interface ProductConstraintData {
    type?: string | null;
    product_id?: string | null;
}

export const isStockConstraintData = (constraintData: any): constraintData is StockConstraintData => {
    return constraintData && (constraintData as StockConstraintData).product_id !== undefined && (constraintData as StockConstraintData).quantity !== undefined;
};
export interface StockConstraintData {
    product_id?: string | null;
    quantity?: number | null;
}

export const isTransactionConstraintData = (constraintData: any): constraintData is TransactionConstraintData => {
    return constraintData && (constraintData as TransactionConstraintData).count !== undefined;
};

export interface TransactionConstraintData {
    count?: number | null;
}

export const isLocationConstraintData = (constraintData: any): constraintData is LocationConstraintData => {
    return constraintData && (constraintData as LocationConstraintData).min_y !== undefined && (constraintData as LocationConstraintData).max_y !== undefined;
};

export interface LocationConstraintData {
    min_y?: number | null;
    max_y?: number | null;
}

export const isOccupancyConstraintData = (constraintData: any): constraintData is OccupancyConstraintData => {
    return (
        constraintData &&
        (constraintData as OccupancyConstraintData).account_id !== undefined &&
        (constraintData as OccupancyConstraintData).workflow_id !== undefined &&
        (constraintData as OccupancyConstraintData).transaction_id !== undefined
    );
};

export interface OccupancyConstraintData {
    before?: Date | null; //timestamp
    after?: Date | null; //timestamp
    account_id?: string;
    workflow_id?: string;
    transaction_id?: string;
}
