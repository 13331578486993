import React from "react";
import { FormattedMessage } from "react-intl";

export enum WorkflowTriggerName {
    STATE_TIMED_OUT = 'state_timed_out'
}

export class WorkflowTrigger {
    static readonly STATE_TIMED_OUT = new WorkflowTrigger(
        WorkflowTriggerName.STATE_TIMED_OUT,
        (
            <FormattedMessage
                id='WorkflowTrigger.state_timed_out'
                description='The displayed value for the WorkflowTrigger state_timed_out'
                defaultMessage='State timed out'
            />
        )
    );

    static readonly ALL = [WorkflowTrigger.STATE_TIMED_OUT]

    constructor(public readonly value: WorkflowTriggerName | string, public readonly displayName: React.ReactNode) {}

    static getByValue(trigger: string) {
        return this.ALL.find((t) => t.value == trigger);
    }
}