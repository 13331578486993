import { SelectInput } from '@frontend/basic-forms';
import { Spinner } from '@frontend/elements';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { GrClose } from 'react-icons/gr';

import useCreateRoleForm from './create-role-form.controller';
import { EntityByTypeSelect } from './entity-by-type-select/entity-by-type-select.component';

const ID = 'create-role-form';
export interface CreateRoleFormProps {
    handleClose: () => void;
    dispatch: ThunkDispatch<any, any, Action>;
}

const CreateRoleForm = (props: CreateRoleFormProps) => {
    const viewProps = useCreateRoleForm(props);

    return (
        <div
            id={ID}
            className='p-2'>
            <form
                onSubmit={viewProps.onSubmit}
                className=''>
                <div className='modal-header d-flex justify-content-between'>
                    <h4>Roles</h4>
                    <button
                        onClick={props.handleClose}
                        className='btn btn-outline-secondary'>
                        <GrClose />
                    </button>
                </div>
                <div className='modal-body static'>
                    {viewProps.objectTypes != null ? (
                        <>
                            <SelectInput
                                label='Item type'
                                helpMessage='The type of the item of which permissions will be based upon.'
                                required
                                submitted={viewProps.submitted}
                                options={viewProps.objectTypes.map((o) => {
                                    return { label: o, value: o };
                                })}
                                value={viewProps.formValues.item}
                                onChange={(value) => {
                                    const val = (value as { value: string; label: string }).value;
                                    viewProps.changeFormValues({
                                        item: val,
                                        name: '',
                                        uid: val === '*' ? val : ''
                                    });
                                }}
                                resetValue={viewProps.formValues.item === ''}
                                isValidCallback={(valid) => viewProps.changeFormValid({ item: valid, name: false, uid: viewProps.formValues.item === '*' })}
                            />
                            {viewProps.formValues.item !== '*' && (
                                <EntityByTypeSelect
                                    submitted={viewProps.submitted}
                                    type={viewProps.formValues.item}
                                    onChange={(value) => {
                                        viewProps.changeFormValues({ ...viewProps.formValues, uid: value });
                                        viewProps.changeFormValid({ ...viewProps.formValid, uid: value != null && value != '' });
                                    }}
                                    value={viewProps.formValues.uid}
                                    dispatch={props.dispatch}
                                />
                            )}
                        </>
                    ) : (
                        <Spinner />
                    )}
                    {viewProps.roleNames != null && viewProps.formValues.uid != '' && (
                        <SelectInput
                            label='Role'
                            helpMessage='Select a role to see its underlying permissions.'
                            required
                            submitted={viewProps.submitted}
                            options={
                                viewProps.roleNames
                                    ? viewProps.roleNames.map((o) => {
                                          return { label: o, value: o };
                                      })
                                    : []
                            }
                            value={viewProps.formValues.name}
                            onChange={(value) =>
                                viewProps.changeFormValues({ ...viewProps.formValues, name: (value as { value: string; label: string }).value })
                            }
                            isValidCallback={(valid) => viewProps.changeFormValid({ ...viewProps.formValid, name: valid })}
                        />
                    )}
                    {viewProps.formValues.name && viewProps.permissions == undefined ? (
                        <p className='text-xs font-weight-bold text-danger'>You are about to submit a role to an item which has no permissions</p>
                    ) : (
                        viewProps.permissions != undefined && (
                            <div className='d-flex flex-column'>
                                <p className='text-sm font-weight-bolder text-primary'>Permissions</p>
                                {viewProps.permissions.map((r) => (
                                    <p
                                        key={r.item}
                                        className='text-xs font-weight-bolder text-danger'>
                                        {r.item}: {r.rights.join(' - ')}
                                    </p>
                                ))}
                            </div>
                        )
                    )}
                </div>

                <div className='modal-footer d-flex justify-content-between align-items-center pb-0'>
                    <button
                        onClick={viewProps.onClear}
                        className='btn btn-secondary w-25'>
                        Clear
                    </button>
                    <button
                        disabled={!viewProps.isValid}
                        type='submit'
                        className='btn btn-success w-25'>
                        Add
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CreateRoleForm;
