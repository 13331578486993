import { APIClient } from "@frontend/api-utils";

const endpoint = '/workflow-api/v1';

export class WorkflowEnumClient extends APIClient {
    public static async fetchGeneralWorkflowTriggers(): Promise<string[]> {
        const response = await this.fetch(`${endpoint}/general-workflow-triggers`);
        return await this.handleResponse<string[]>(response);
    }

    public static async fetchGeneralWorkflowActions(): Promise<string[]> {
        const response = await this.fetch(`${endpoint}/general-workflow-actions`);
        return await this.handleResponse<string[]>(response);
    }
}