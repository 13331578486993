import { useAppSelector } from '@frontend/common';
import { fetchSlots, slotStore } from '@frontend/slot/redux';
import { Slot } from '@frontend/slot/types';
import { fetchSpots, spotStore } from '@frontend/spot/redux';
import { Spot } from '@frontend/spot/types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { SpotLayoutSlotSelectProps } from './spot-layout-slot-select.component';

interface ViewProps {
    spots?: Spot[] | null;
    slots?: Slot[] | null;
}

const useSpotLayoutSlotSelect = (props: SpotLayoutSlotSelectProps): ViewProps => {
    const spotState = useAppSelector(useSelector, spotStore);
    const [spots, changeSpots] = useState<Spot[] | null>(null);
    const slotState = useAppSelector(useSelector, slotStore);
    const [slots, changeSlots] = useState<Slot[] | null>(null);

    useEffect(() => {
        props.dispatch(fetchSpots({}));
        props.dispatch(fetchSlots({}));
    }, []);

    useEffect(() => {
        if (spotState.spots) {
            changeSpots(spotState.spots.results);
        }
    }, [spotState]);

    useEffect(() => {
        if (slotState.slots) {
            changeSlots(slotState.slots.results);
        }
    }, [slotState]);
    return {
        spots,
        slots
    };
};

export default useSpotLayoutSlotSelect;
