import { Logger } from '@frontend/Logger';
import { Token } from '@frontend/authentication-v2';
import { DriverProvision } from '@frontend/edge/types';
import { UserInterfaceType } from '@frontend/user-interface/types';
import { WorkflowStepTriggeredEvent } from '@frontend/workflow/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface UserInterfaceNavigationState {
    driver: DriverProvision | null;
    root: string | null;
    current: string | null;
    cache: WorkflowStepTriggeredEvent | null;
    user: {
        id: string;
        accountId: string;
        auth: Token;
    } | null;
    history: string[];
}

const initialState: UserInterfaceNavigationState = {
    driver: null,
    root: null,
    current: null,
    cache: null,
    user: null,
    history: []
};

const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        initialiseDriver: (state, action: PayloadAction<DriverProvision | null>) => {
            state.driver = action.payload;
        },
        initialiseNavigation: (state, action: PayloadAction<string | null>) => {
            if (action.payload == null) {
                state.root = UserInterfaceType.BUTTON_DASHBOARD;
            } else state.root = action.payload;
        },
        changeView: (state, action: PayloadAction<{ view: string; cache: WorkflowStepTriggeredEvent | null } | null>) => {
            Logger.log('View changed', {}, action.payload);
            state.current = action.payload?.view || null;
            state.cache = action.payload?.cache || null;
        },
        startUserSession: (state, action: PayloadAction<{ id: string; accountId: string; auth: Token }>) => {
            Logger.log('User session started', {}, action.payload);
            state.user = action.payload;
        },
        endUserSession: (state) => {
            Logger.log('User session ended');
            state.user = null;
        }
    }
});

export const navigationStore = { navigation: navigationSlice.reducer };
export const { initialiseNavigation, changeView, initialiseDriver, startUserSession, endUserSession } = navigationSlice.actions;
