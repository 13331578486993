import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { CertificateClient } from '@frontend/certificate/api';
import { Certificate, CertificateListResponse } from '@frontend/certificate/types';
import { SliceStatus } from '@frontend/common';
import { ErrorHandler } from '@frontend/error-handler';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';


export interface CertificateState {
    unordered: Certificate[];
    status: SliceStatus;
}

const initialState: CertificateState = {
    unordered: [],
    status: SliceStatus.INIT
};

const certificateSlice = createSlice({
    name: 'certificates',
    initialState,
    reducers: {
        seedCertificates(state, action: PayloadAction<Certificate[]>) {
            state.unordered = [...state.unordered.filter((certificate) => action.payload.find((a) => a.id == certificate.id) == undefined), ...action.payload];
        },
        updateCertificate(state, action: PayloadAction<Certificate>) {
            state.unordered = state.unordered.map((certificate) => (certificate.id == action.payload.id ? action.payload : certificate));
        },
        addCertificate(state, action: PayloadAction<Certificate>) {
            state.unordered.push(action.payload);
        },
        removeCertificate(state, action: PayloadAction<string>) {
            state.unordered = state.unordered.filter((certificate) => certificate.id != action.payload);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCertificates.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchCertificates.fulfilled, (state, action) => {
                state.unordered = [
                    ...state.unordered.filter((certificate) => action.payload.results.find((a) => a.id == certificate.id) == undefined),
                    ...action.payload.results
                ];
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchCertificates.rejected, (state, action) => {
                ErrorHandler.handleError(action.payload as ApiError);
            })
            .addCase(fetchCertificate.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchCertificate.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                state.unordered = [...state.unordered.filter((acc) => action.payload.id !== acc.id), action.payload];
            })
            .addCase(fetchCertificate.rejected, (state, action) => {
                ErrorHandler.handleError(action.payload as ApiError);
            });
    }
});

export const fetchCertificates = createAsyncThunk<CertificateListResponse, ApiQueryParams<DefaultQueryParams>>(
    'fetchCertificates',
    async (queryParams: ApiQueryParams<DefaultQueryParams>, { rejectWithValue }) => {
        try {
            return await CertificateClient.fetchCertificates(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchCertificate = createAsyncThunk<Certificate, {accountId: string, certificateId: string}>('fetchCertificate', async (props: {accountId: string, certificateId: string}, { rejectWithValue }) => {
    try {
        return await CertificateClient.fetchCertificate(props.accountId, props.certificateId);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const deleteCertificate = createAsyncThunk<void, {accountId: string, certificateId: string}>('deleteCertificate', async (props: {accountId: string, certificateId: string}, { rejectWithValue }) => {
    try {
        return await CertificateClient.deleteCertificate(props.accountId, props.certificateId);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const certificateStore = { certificates: certificateSlice.reducer };
export const { seedCertificates, updateCertificate, addCertificate, removeCertificate } = certificateSlice.actions;
