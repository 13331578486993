import { DetailPageType, useAppSelector, useDetailPage } from '@frontend/common';
import { spotStore } from '@frontend/spot/redux';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { spotRequest } from '@frontend/spot/events';
import { Spot } from '@frontend/spot/types';
import { SpotBadgeProps } from './spot-badge.component';

interface ViewProps {
    displayName?: string;
    disabled: boolean;
}

const useSpotBadge = (props: SpotBadgeProps): ViewProps => {
    const [requested, changeRequested] = useState<boolean>(false);
    const spotState = useAppSelector(useSelector, spotStore);
    const [disabled, changeDisabled] = useState<boolean>(false);
    const [displayName, changeDisplayName] = useState<string | undefined>(undefined);
    const { isEntityPage, isEntityBadge } = useDetailPage(DetailPageType.SPOT, props.id);

    useEffect(() => {
        if (props.id != undefined) {
            const found = spotState.unordered.find((c: Spot) => c.id.toString() == props.id);
            if (found) {
                changeDisplayName(found.name);
                changeDisabled(false);
            } else if (requested == false) {
                changeRequested(true);
                spotRequest(props.id);
            }

            if (requested && !found) {
                changeDisabled(true);
                changeDisplayName('Not found');
            }
            if (isEntityPage || isEntityBadge) changeDisabled(true);
        } else {
            changeDisabled(true);
            changeDisplayName('None');
        }
    }, [spotState, props.id, requested]);

    return { displayName, disabled };
};

export default useSpotBadge;
