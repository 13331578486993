import { fetchPermissionTemplates } from '@frontend/authorization';
import { useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { PermissionOverviewProps } from './permission-overview.component';

interface ViewProps {
    permissions: { item: string; rights: string[] }[] | undefined;
}

const usePermissionOverview = (props: PermissionOverviewProps): ViewProps => {
    const dispatch = useAppDispatch();
    const authorizationState = useAppSelector((state) => state.authorization);

    const permissions = useMemo(() => {
        const allPermissions: {
            item: string;
            rights: string[];
        }[] = [];
        if (authorizationState.permissionTemplates != null) {
            props.roles.forEach((role) => {
                const itemPermissions = authorizationState.permissionTemplates![role.item];
                if (itemPermissions != null) {
                    const rolePermissions = itemPermissions[role.name];
                    rolePermissions.forEach((permission) => {
                        const foundPremission = allPermissions.find((i) => i.item === permission.item);
                        if (foundPremission) {
                            allPermissions.splice(allPermissions.indexOf(foundPremission), 1, {
                                ...foundPremission,
                                rights: [...foundPremission.rights, ...permission.rights.filter((r) => !foundPremission.rights.includes(r))]
                            });
                        } else allPermissions.push(permission);
                    });
                }
            });
        } else dispatch(fetchPermissionTemplates());

        return allPermissions;
    }, [authorizationState.permissionTemplates, props.roles]);

    return { permissions };
};

export default usePermissionOverview;
