import { Spinner } from '@frontend/elements';
import { Transaction } from '@frontend/transaction/types';
import { WorkflowStepTriggeredEvent } from '@frontend/workflow/types';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

import useAddTransactionVariablesForm from './add-transaction-variables-form.controller';

export interface AddTransactionVariablesProps {
    event: WorkflowStepTriggeredEvent;
    transaction: Transaction;
    callback: () => void;
    onClose: () => void;
    dispatch: ThunkDispatch<any, any, AnyAction>;
}

export const AddTransactionVariablesForm = (props: AddTransactionVariablesProps) => {
    const viewProps = useAddTransactionVariablesForm(props);
    if (viewProps.isLoading || viewProps.userInterface == null) return <Spinner />;
    switch (viewProps.userInterface?.type) {
        // case CHOOSE_SLOT_UI:
        //     return (
        //         <ChooseSlot
        //             userInterface={viewProps.userInterface}
        //             transaction={props.transaction}
        //             onClose={props.onClose}
        //             onSubmitted={() => viewProps.changeUserInterface(null)}
        //             dispatch={props.dispatch}
        //         />
        //     );
        // case CHOOSE_SENDER_UI:
        //     return (
        //         <ChooseSender
        //             userInterface={viewProps.userInterface}
        //             transaction={props.transaction}
        //             onClose={props.onClose}
        //             onSubmitted={() => viewProps.changeUserInterface(null)}
        //             dispatch={props.dispatch}
        //         />
        //     );
        // case CHOOSE_RECEIVER_UI:
        //     return (
        //         <ChooseReceiver
        //             userInterface={viewProps.userInterface}
        //             transaction={props.transaction}
        //             onClose={props.onClose}
        //             onSubmitted={() => viewProps.changeUserInterface(null)}
        //             dispatch={props.dispatch}
        //         />
        //     );
        default:
            return <div>oops something went wrong</div>;
    }
};
