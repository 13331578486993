import React from 'react';

import { SelectInput } from '../select-input/select-input.component';

const ID = 'operator-select';
export interface OperatorSelectProps {
    type: 'arithmetic' | 'comparison' | 'assignment';
    value?: string;
    id?: string;
    required?: boolean;
    submitted: boolean;
    label?: string;
    onChange: (value: string) => void;
    className?: string;
    disabled?: boolean;
    useConditionedStyling?: boolean;
    isClearable?: boolean;
}
const arithmetic = ['+', '-', '*', '/', '%', '**', '//'];
const comparison = ['==', '!=', '>', '<', '>=', '<='];
const assignment = ['=', '+=', '-=', '*=', '/=', '%=', '**=', '//=', '&=', '|=', '^=', '<<=', '>>='];

export const OperatorSelect = (props: OperatorSelectProps) => {
    const options = props.type === 'arithmetic' ? arithmetic : props.type === 'comparison' ? comparison : assignment;
    const mappedOptions = options.map((o) => ({ value: o, label: o }));
    return (
        <SelectInput
            id={props.id || ID}
            label={props.label || 'Operator'}
            className={props.className}
            submitted={props.submitted}
            required={props.required}
            options={mappedOptions}
            onChange={(value) => props.onChange((value as any).value as string)}
            value={props.value}
            isDisabled={props.disabled}
            isClearable={props.isClearable}
        />
    );
};
