import { UserInterfaceType } from '@frontend/user-interface/types';
import { useMemo } from 'react';

import React from 'react';
import ChooseAckSlotDataForm from './choose-ack-slot-data-form/choose-ack-slot-data-form.component';
import CreateTransactionDataForm from './create-transaction-data-form/create-transaction-data-form.component';
import EnterPinDataForm from './enter-pin-data-form/enter-pin-data-form.component';
import ReturnProductDataForm from './return-product-data-form/return-product-data-form.component';
import { UserInterfaceDataByTypeFormProps } from './user-interface-data-by-type-form.component';
import WarehouseProductPickDropDataForm from './warehouse-product-pick-drop-data-form/warehouse-product-pick-drop-data-form.component';

interface ViewProps {
    content: React.ReactNode;
}

const useUserInterfaceDataByTypeForm = (props: UserInterfaceDataByTypeFormProps): ViewProps => {
    const content = useMemo(() => {
        switch (props.type) {
            case UserInterfaceType.ENTER_PIN:
                return <EnterPinDataForm {...props} />;
            case UserInterfaceType.WAREHOUSE_PRODUCT_PICK_AND_DROP:
                return <WarehouseProductPickDropDataForm {...props} />;
            case UserInterfaceType.RETURN_PRODUCT:
                return <ReturnProductDataForm {...props} />;
            case UserInterfaceType.CHOOSE_ACK_SLOT:
                return <ChooseAckSlotDataForm {...props} />;
            case UserInterfaceType.CREATE_TRANSACTION_UI:
                return <CreateTransactionDataForm {...props} />;
        }
        return <></>;
    }, [props]);
    return { content };
};

export default useUserInterfaceDataByTypeForm;
