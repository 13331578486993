import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { DeviceClient, DeviceEnumClient } from '@frontend/edge/api';
import { Device, DeviceListResponse, DeviceQueryParams } from '@frontend/edge/types';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

interface DeviceSlice {
    unordered: Device[];
    devices: DeviceListResponse | null;
    deviceByType: { [type: string]: DeviceListResponse } | null;
    deviceStates: string[] | null;
    deviceTriggers: string[] | null;
    deviceActions: string[] | null;
    status: SliceStatus;
}

const initialState: DeviceSlice = {
    unordered: [],
    devices: null,
    deviceByType: null,
    deviceStates: null,
    deviceTriggers: null,
    deviceActions: null,
    status: SliceStatus.INIT
};

const deviceSlice = createSlice({
    name: 'deviceSlice',
    initialState,
    reducers: {
        seedDevices(state, action: PayloadAction<Device[]>) {
            state.unordered = [...state.unordered.filter((device) => action.payload.find((s) => s.id == device.id) == undefined), ...action.payload];
        },
        updateDevice(state, action: PayloadAction<Device>) {
            state.unordered = state.unordered.map((d) => (d.id == action.payload.id ? action.payload : d));
            if (state.devices != null) {
                state.devices.results = state.devices.results.map((d) => (d.id == action.payload.id ? action.payload : d));
            }
            if (state.deviceByType != null && action.payload.type) {
                if (!state.deviceByType[action.payload.type]) {
                    state.deviceByType[action.payload.type] = { count: 1, results: [action.payload] };
                }
                const found = state.deviceByType[action.payload.type].results.find((d) => d.id == action.payload.id);
                if (!found) {
                    state.deviceByType[action.payload.type].count++;
                    state.deviceByType[action.payload.type].results.splice(0, 0, action.payload);
                }
                state.deviceByType[action.payload.type].results = state.deviceByType[action.payload.type].results.map((d) =>
                    d.id == action.payload.id ? action.payload : d
                );
            }
        },
        addDevice(state, action: PayloadAction<Device>) {
            state.unordered.push(action.payload);
            if (state.devices != null) {
                state.devices.count++;
                state.devices.results.splice(0, 0, action.payload);
            }
            if (state.deviceByType != null && action.payload.type) {
                state.deviceByType[action.payload.type].count++;
                state.deviceByType[action.payload.type].results.splice(0, 0, action.payload);
            }
        },
        removeDevice(state, action: PayloadAction<string>) {
            state.unordered = state.unordered.filter((d) => d.id != action.payload);
            if (state.devices != null) {
                state.devices.count--;
                state.devices.results = state.devices.results.filter((d) => d.id != action.payload);
            }
            if (state.deviceByType != null) {
                Object.keys(state.deviceByType).forEach((key) => {
                    state.deviceByType![key].results = state.deviceByType![key].results.filter((d) => d.id != action.payload);
                });
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDevices.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchDevices.fulfilled, (state, action) => {
                const startPos = toNumber(action.meta.arg.index) * toNumber(action.meta.arg.size);
                const type = action.meta.arg.type;
                state.status = SliceStatus.IDLE;
                if (!state.devices) {
                    state.devices = { ...action.payload, results: new Array(action.payload.count) };
                    state.devices.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (action.payload.count != state.devices.results.length) {
                        state.devices.count = action.payload.count;
                        state.devices.results = new Array(action.payload.count);
                    }
                    state.devices.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                if (type && !Array.isArray(type)) {
                    if (!state.deviceByType) {
                        state.deviceByType = { [type]: { ...action.payload, results: new Array(action.payload.count) } };
                        state.deviceByType[type].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    } else if (!state.deviceByType[type]) {
                        state.deviceByType[type] = { ...action.payload, results: new Array(action.payload.count) };
                        state.deviceByType[type].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    } else {
                        if (action.payload.count != state.deviceByType[type].results.length) {
                            state.deviceByType[type].count = action.payload.count;
                            state.deviceByType[type].results = new Array(action.payload.count);
                        }

                        state.deviceByType[type].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    }
                }
                state.unordered = [
                    ...state.unordered.filter((device) => action.payload.results.find((a) => a.id == device.id) == undefined),
                    ...action.payload.results
                ];
            })
            .addCase(fetchDeviceStates.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchDeviceStates.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                state.deviceStates = action.payload;
            })
            .addCase(fetchDeviceTriggers.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchDeviceTriggers.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                state.deviceTriggers = action.payload;
            })
            .addCase(fetchDeviceActions.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchDeviceActions.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                state.deviceActions = action.payload;
            });
    }
});

export const fetchDevices = createAsyncThunk<DeviceListResponse, ApiQueryParams<DefaultQueryParams | DeviceQueryParams>>(
    'fetchDevices',
    async (queryParams: ApiQueryParams<DefaultQueryParams | DeviceQueryParams>, { rejectWithValue }) => {
        try {
            return await DeviceClient.fetchDevices(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchDevice = createAsyncThunk<Device, { driverId: string; deviceId: string }>(
    'fetchDevice',
    async (params: { driverId: string; deviceId: string }, { rejectWithValue }) => {
        try {
            return await DeviceClient.fetchDevice(params.driverId, params.deviceId);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchDeviceStates = createAsyncThunk<string[]>('fetchDeviceStates', async (_, { rejectWithValue }) => {
    try {
        return await DeviceEnumClient.fetchDeviceStates();
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const fetchDeviceTriggers = createAsyncThunk<string[]>('fetchDeviceTriggers', async (_, { rejectWithValue }) => {
    try {
        return await DeviceEnumClient.fetchDeviceTriggers();
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const fetchDeviceActions = createAsyncThunk<string[]>('fetchDeviceActions', async (_, { rejectWithValue }) => {
    try {
        return await DeviceEnumClient.fetchDeviceActions();
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const deviceStore = { devices: deviceSlice.reducer };
export const { seedDevices, updateDevice, addDevice, removeDevice } = deviceSlice.actions;
