import { APIClient } from '@frontend/api-utils';

const endpoint = '/edge-api/v1';
export class SlotAccessClient extends APIClient {
    /**
     * @deprecated
     * @param spotId
     * @param moduleId
     * @param slotId
     * @returns
     */
    public static async postOpenSpotModuleSlot(spotId: string, moduleId: string, slotId: string): Promise<void> {
        const response = await this.post(`${endpoint}/spots/${spotId}/modules/${moduleId}/slots/${slotId}/open`);
        return await this.handleVoidResponse(response);
    }
}
