import { useNavigate } from 'react-router-dom';

import { TerminalDetailModalProps } from './terminal-detail-modal.component';

interface ViewProps {
    viewDetails: () => void;
}

export const useTerminalDetailModal = (props: TerminalDetailModalProps): ViewProps => {
    const navigate = useNavigate();

    function viewDetails() {
        navigate(`/terminals/${props.terminal?.id}/detail`);
    }

    return {
        viewDetails
    };
};
