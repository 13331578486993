import { Logger } from '@frontend/Logger';
import { AuthenticationManager, DriverAuthenticationManager, Token } from '@frontend/authentication-v2';
import { fileDownload } from '@frontend/common';
import { merge } from 'lodash';

import { ApiError, ApiQueryParams } from './models';

let lastRequests: { url: string; init?: RequestInit; timestamp: number }[] = [];
const requestTimeout = 2 * 1000;
const maxRequests = 3;

export abstract class APIClient {
    protected static async apiPaginated<T, S extends string | number>(
        url: string,
        queryParams?: ApiQueryParams<S> | null,
        withCredentials?: Token
    ): Promise<T> {
        const endpoint = buildApiEndpoint(url, queryParams);
        const response = await this.fetch(endpoint, undefined, true, withCredentials);
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError(`Error fetching ${endpoint}`);
            }
            throw new ApiError(`Error fetching ${endpoint}`, json, response.status);
        }
        return await response.json();
    }

    protected static async handleResponse<T>(response: Response): Promise<T> {
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError(`Error fetching ${response.url}`, response);
            }
            throw new ApiError(`Error fetching ${response.url}`, json, response.status);
        }
        return (await response.json()) as T;
    }

    protected static async handleVoidResponse(response: Response): Promise<void> {
        if (!response.ok) {
            let json;
            try {
                json = await response.json();
            } catch (e) {
                throw new ApiError(`Error fetching ${response.url}`, response);
            }
            throw new ApiError(`Error fetching ${response.url}`, json, response.status);
        }
    }

    protected static async handleBlobResponse(response: Response): Promise<Blob> {
        if (!response.ok) {
            let blob;
            try {
                blob = await response.blob();
            } catch (e) {
                throw new ApiError(`Error fetching ${response.url}`, response);
            }
            throw new ApiError(`Error fetching ${response.url}`, blob, response.status);
        }
        return await response.blob();
    }

    protected static async handleFileDownload(response: Response, name: string): Promise<void> {
        const file = await this.handleBlobResponse(response);
        const date = new Date().toLocaleString();
        const fileName = name + ' ' + date;
        fileDownload(file, fileName);
    }

    protected static async fetch(endpoint: string, init?: RequestInit, includeAccessToken = true, withCredentials?: Token): Promise<Response> {
        if (includeAccessToken) {
            if (withCredentials) Logger.log('Request sent using override credentials.');
            const credentials = withCredentials || (await AuthenticationManager.getInstance().waitForToken());
            init = merge(init, {
                headers: {
                    authorization: 'Bearer ' + credentials.jwt_token
                }
            });
        }
        if (!endpoint.startsWith('http')) {
            endpoint = api(endpoint);
        }
        lastRequests = lastRequests.filter((request) => Date.now() - request.timestamp < requestTimeout);

        const currentRequest = { url: endpoint, init, timestamp: Date.now() };
        const matchingRequest = lastRequests.find(
            (request) => request.url === currentRequest.url && JSON.stringify(request.init) === JSON.stringify(currentRequest.init)
        );

        if (matchingRequest) {
            Logger.error(`Duplicate request: ${matchingRequest.url}`);
            //return Promise.reject(new Error('Duplicate request: ' + matchingRequest.url));
        }

        lastRequests.push(currentRequest);
        if (lastRequests.length > maxRequests) {
            lastRequests.splice(0, lastRequests.length - maxRequests);
        }
        let response = await window.fetch(endpoint, init);
        if (response.status === 401) {
            Logger.error('Unauthorized refreshing token.');
            try {
                await AuthenticationManager.getInstance().refresh();
            } catch (e) {
                const token = localStorage.getItem('token');
                if (
                    token != null &&
                    (JSON.parse(token) as Token).entity_type === 'driver' &&
                    DriverAuthenticationManager.getInstance().provisionInfo !== undefined
                ) {
                    await DriverAuthenticationManager.getInstance().authenticate(DriverAuthenticationManager.getInstance().provisionInfo!);
                } else {
                    await AuthenticationManager.getInstance().doLogout();
                }
            }
            const credentials = await AuthenticationManager.getInstance().waitForToken();
            init = merge(init, {
                headers: {
                    authorization: 'Bearer ' + credentials.jwt_token
                }
            });
            response = await window.fetch(endpoint, init);
        }
        return response;
    }

    protected static async post(endpoint: string, data?: any, includeAccessToken = true, withCredentials?: Token): Promise<Response> {
        const isFormData = data instanceof FormData;
        return this.fetch(
            endpoint,
            {
                method: 'POST',
                ...(!isFormData && { headers: { 'Content-Type': 'application/json' } }),
                body: isFormData ? data : JSON.stringify(data)
            },
            includeAccessToken,
            withCredentials
        );
    }

    protected static async put(endpoint: string, data?: any, includeAccessToken = true, withCredentials?: Token): Promise<Response> {
        const isFormData = data instanceof FormData;
        return this.fetch(
            endpoint,
            {
                method: 'PUT',
                ...(!isFormData && { headers: { 'Content-Type': 'application/json' } }),
                body: isFormData ? data : JSON.stringify(data)
            },
            includeAccessToken,
            withCredentials
        );
    }

    protected static async patch(endpoint: string, data?: any, includeAccessToken = true, withCredentials?: Token): Promise<Response> {
        return this.fetch(
            endpoint,
            {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: data ? JSON.stringify(data) : ''
            },
            includeAccessToken,
            withCredentials
        );
    }

    protected static async delete(endpoint: string, includeAccessToken = true, withCredentials?: Token): Promise<Response> {
        return this.fetch(
            endpoint,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            },
            includeAccessToken,
            withCredentials
        );
    }
}

function buildApiEndpoint<T extends string>(endpoint: string, queryParams?: ApiQueryParams<T> | null): string {
    if (!queryParams) {
        return endpoint;
    }

    // This bizarre construct is necessary because Tyepscript 4.2.3 does not seem to understand that queryParams has
    // string values but instead uses unknown. This looks like a bug in Typescript. If you are using a newer version of
    // Typescript, try removing this parameter and see if it validates.
    const queryParams2: { [key in string]?: string | string[] | undefined | null } = queryParams;
    const searchParams = Object.entries(queryParams2).reduce((result, queryParam) => {
        if (queryParam[1]) {
            if (typeof queryParam[1] === 'string') {
                result.append(queryParam[0], queryParam[1]);
            } else {
                for (const queryParamValue of queryParam[1]) {
                    result.append(queryParam[0], queryParamValue);
                }
            }
        }
        return result;
    }, new URLSearchParams());
    const queryString = searchParams.toString();
    if (queryString !== '') {
        return `${endpoint}?${queryString}`;
    }
    return endpoint;
}

/**
 * TODO update to make sure edge no longer needs a seperate build
 * @param endpoint
 * @returns
 */
function api(endpoint: string): string {
    const serviceName: string = endpoint.split('/')[1];
    const apiEndpoint = endpoint.replace('/' + serviceName, '');
    if (process.env['NX_BUILD_ENV'] === 'edge') {
        const service_proxy_ports: { [key: string]: number } = {
            'account-api': 8000,
            'authentication-api': 8001,
            'authorization-api': 8002,
            'badge-api': 8003,
            'budget-api': 8027,
            'certificate-api': 8021,
            'contact-api': 8004,
            'document-api': 8020,
            'edge-api': 8019,
            'event-api': 8005,
            'import-api': 8022,
            'module-api': 8006,
            'notification-api': 8007,
            'package-api': 8016,
            'product-api': 8008,
            'rakinda-api': 8018,
            'slot-api': 8009,
            'spot-api': 8010,
            'stock-api': 8017,
            'sync-api': 8011,
            'transaction-api': 8012,
            'user-interface-api': 8015,
            'user-api': 8013,
            'workflow-api': 8014
        };
        return 'http://localhost:' + service_proxy_ports[serviceName] + apiEndpoint;
    } else {
        return 'https://' + serviceName + '.' + process.env['NX_API_DOMAIN'] + apiEndpoint;
    }
}
