import { SelectInput } from '@frontend/basic-forms';
import { ClassType, EntityType } from '@frontend/common';
import { ModalFooter } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { FaPlus } from 'react-icons/fa';
import { GrClose } from 'react-icons/gr';

import RestrictionKeySelect from '../templates/restriction-key-select/restriction-key-select.component';
import RestrictionValueSelect from '../templates/restriction-value-select/restriction-value-select.component';
import useCreateRestrictionForm from './create-restriction-form.controller';

const ID = 'create-restriction-form';

export interface CreateRestrictionFormProps {
    onClose: () => void;
    dispatch: ThunkDispatch<any, any, Action>;
}

const CreateRestrictionForm = (props: CreateRestrictionFormProps) => {
    const viewProps = useCreateRestrictionForm(props);
    return (
        <div id={ID}>
            <form onSubmit={viewProps.onSubmit}>
                <div className='modal-header d-flex justify-content-between'>
                    <h4>Restrictions</h4>
                    <button
                        onClick={props.onClose}
                        className='btn btn-outline-secondary'>
                        <GrClose />
                    </button>
                </div>
                <div className='modal-body static'>
                    <SelectInput
                        label='Type'
                        required
                        options={Object.entries(EntityType).map((k) => ({ label: k[0], value: k[1] }))}
                        submitted={false}
                        value={viewProps.formValues.type}
                        onChange={(v: any) => {
                            viewProps.changeFormValues({ ...viewProps.formValues, type: v.value, key: '', value: '' });
                            viewProps.changeFormValid({ ...viewProps.formValid, type: v != '' });
                        }}
                    />
                    {viewProps.formValues.type !== '' && (
                        <RestrictionKeySelect
                            type={viewProps.formValues.type}
                            onChange={(v: any) => {
                                viewProps.changeFormValues({ ...viewProps.formValues, key: v, value: '' });
                                viewProps.changeFormValid({ ...viewProps.formValid, key: v != '' });
                            }}
                            submitted={false}
                            required
                            value={viewProps.formValues.key}
                            label='Key'
                        />
                    )}
                    {viewProps.formValues.key !== '' && (
                        <RestrictionValueSelect
                            dispatch={props.dispatch}
                            restriction_key={viewProps.formValues.key!}
                            type={viewProps.formValues.type}
                            onChange={(v: any) => {
                                viewProps.changeFormValues({ ...viewProps.formValues, value: v });
                                viewProps.changeFormValid({ ...viewProps.formValid, value: v != '' });
                            }}
                            submitted={false}
                            value={viewProps.formValues.value}
                            required
                        />
                    )}
                </div>
                <ModalFooter
                    justifyContent='between'
                    buttons={[
                        {
                            hide: false,
                            text: CommonMessage.BUTTONS.CANCEL,
                            type: ClassType.SECONDARY,
                            onClick: (e) => {
                                e?.preventDefault();
                                props.onClose();
                            }
                        },
                        {
                            hide: false,
                            text: CommonMessage.BUTTONS.SUBMIT,
                            type: ClassType.SUCCESS,
                            icon: FaPlus,
                            buttonType: 'submit'
                        }
                    ]}
                />
            </form>
        </div>
    );
};

export default CreateRestrictionForm;
