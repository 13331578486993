import { EmailInput, TextInput } from '@frontend/basic-forms';
import { ClassType } from '@frontend/common';
import { ModalFooter, TitleSelect } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';
import { User } from '@frontend/user/types';
import { WorkflowSelect } from '@frontend/workflow/common';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import { FaPlus } from 'react-icons/fa';

import useUpdateUserForm from './update-user.controller';

const ID = 'update-user-form';

export interface UpdateUserFormProps {
    onClose: () => void;
    user: User;
    dispatch: ThunkDispatch<any, any, Action>;
}

export const UpdateUserForm = (props: UpdateUserFormProps) => {
    const viewProps = useUpdateUserForm(props);

    return (
        <div id={ID}>
            <form onSubmit={viewProps.onSubmit}>
                <div className='modal-header'>
                    <h4>{CommonMessage.FORMS.UPDATE_OBJECT('User')}</h4>
                </div>
                <div className='modal-body'>
                    <div className='d-flex flex-row'>
                        <TextInput
                            label={CommonMessage.OBJECTS.USER.USERNAME}
                            submitted={false}
                            value={props.user.email}
                            disabled
                            className='w-50 m-1'
                        />
                        <EmailInput
                            submitted={false}
                            value={props.user.email}
                            disabled
                            className='w-50 m-1'
                        />
                    </div>
                    <div className='d-flex flex-row'>
                        <TextInput
                            id={ID + '-first-nameInput'}
                            className='w-50 m-1'
                            label={CommonMessage.FORMS.FIRST_NAME}
                            value={viewProps.formValues.first_name}
                            onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, first_name: value })}
                            isValidCallback={(valid) => {
                                viewProps.changeFormValid({ ...viewProps.formValid, first_name: valid });
                            }}
                            submitted={viewProps.submitted}
                        />

                        <TextInput
                            id={ID + '-last-nameInput'}
                            className='w-50 m-1'
                            label={CommonMessage.FORMS.LAST_NAME}
                            value={viewProps.formValues.last_name}
                            onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, last_name: value })}
                            isValidCallback={(valid) => {
                                viewProps.changeFormValid({ ...viewProps.formValid, last_name: valid });
                            }}
                            submitted={viewProps.submitted}
                        />
                    </div>
                    <div className='d-flex flex-row'>
                        <TitleSelect
                            className='w-50 m-1'
                            label={CommonMessage.OBJECTS.USER.TITLE}
                            value={viewProps.formValues.title}
                            onChange={(value: any) => viewProps.changeFormValues({ ...viewProps.formValues, title: value })}
                            submitted={viewProps.submitted}
                        />
                        <WorkflowSelect
                            dispatch={props.dispatch}
                            className='w-50 m-1'
                            label={CommonMessage.OBJECTS.WORKFLOW.DEFAULT}
                            value={viewProps.formValues.workflow_id}
                            onChange={(value: any) => viewProps.changeFormValues({ ...viewProps.formValues, workflow_id: value })}
                            submitted={viewProps.submitted}
                            queryParams={{ account_id: props.user.account_id }}
                        />
                    </div>
                </div>
                <ModalFooter
                    justifyContent='between'
                    buttons={[
                        {
                            hide: false,
                            text: CommonMessage.BUTTONS.CANCEL,
                            type: ClassType.SECONDARY,
                            onClick: (e) => {
                                e?.preventDefault();
                                props.onClose();
                            }
                        },
                        {
                            hide: false,
                            text: CommonMessage.BUTTONS.SUBMIT,
                            type: ClassType.SUCCESS,
                            icon: FaPlus,
                            buttonType: 'submit'
                        }
                    ]}
                />
            </form>
        </div>
    );
};

export default UpdateUserForm;
