import React, { ReactNode } from 'react';
import { FaPlus } from 'react-icons/fa';
import { GroupBase, InputActionMeta, OptionsOrGroups } from 'react-select';
import AsyncSelect from 'react-select/async';

import useAsyncSelectInputMulti from './async-select-input-multi.controller';

const ID = 'async-select-multi-single';
export interface AsyncSelectInputMultiProps {
    isClearable?: boolean;
    isDisabled?: boolean;
    cacheOptions?: any;
    loadOptions?: (
        inputValue: string,
        callback: (options: OptionsOrGroups<any, GroupBase<any>>) => void
    ) => void | Promise<OptionsOrGroups<any, GroupBase<any>>>;
    options?: { value: string; label: string }[];
    placeholder?: ReactNode;
    noOptionsMessage?: (obj: { inputValue: string }) => ReactNode;
    onChange: (newValue: string[]) => void;
    onInputChange?: ((newValue: string, actionMeta: InputActionMeta) => void) | undefined;
    onMenuScrollToBottom?: (event: WheelEvent | TouchEvent) => void;
    id?: string;
    required?: boolean;
    label: React.ReactNode;
    helpMessage?: React.ReactNode;
    valid?: boolean;
    isValidCallback?: (valid: boolean) => void;
    onFocusChange?: () => void;
    submitted: boolean;
    value?: string[] | null;
    useConditionedStyling?: boolean;
    className?: string;
    errorMessage?: React.ReactNode;

    onCreate?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const AsyncSelectInputMulti = (props: AsyncSelectInputMultiProps) => {
    const viewProps = useAsyncSelectInputMulti(props);

    return (
        <div
            id={props.id || ID}
            className={props.className || 'form-group'}>
            <label>
                {props.required && <span className='text-danger me-1'>&#9679;</span>} {props.label}
                {props.helpMessage && (
                    <>
                        <br />
                        <small className='form-text text-muted'>{props.helpMessage}</small>
                    </>
                )}
            </label>
            <div className='d-flex flex-row align-items-end'>
                <AsyncSelect
                    styles={styles}
                    className='w-100'
                    // classNames={{
                    //     control: () =>
                    //         props.useConditionedStyling === false ? 'form-control' : getInputClassMultiSelect(viewProps.touched, viewProps.valid, props.value)
                    // }}
                    isClearable={props.isClearable}
                    isDisabled={props.isDisabled}
                    value={viewProps.findValue()}
                    cacheOptions={props.cacheOptions}
                    loadOptions={props.loadOptions}
                    defaultOptions={props.options}
                    minMenuHeight={300}
                    placeholder={props.placeholder}
                    noOptionsMessage={props.noOptionsMessage}
                    onChange={(newValue) => {
                        viewProps.changeValue(newValue);
                        props.onChange && props.onChange(newValue.map((v) => v.value));
                        viewProps.isValid();
                    }}
                    onFocus={() => {
                        viewProps.changeTouched(true);
                        props.onFocusChange && props.onFocusChange();
                    }}
                    onInputChange={props.onInputChange}
                    onMenuScrollToBottom={props.onMenuScrollToBottom}
                    isMulti
                />

                {props.onCreate && (
                    <button
                        className='d-flex align-items-center justify-content-center btn btn-outline-secondary ms-2 mb-0'
                        style={{ height: '41px', width: '41px' }}
                        onClick={props.onCreate}>
                        <span className='d-flex align-items-center justify-content-center'>
                            <FaPlus />
                        </span>
                    </button>
                )}
                {viewProps.valid === false && viewProps.touched === true && (
                    <span className='badge bg-gradient-danger mt-2'>
                        <small>{props.errorMessage ? props.errorMessage : 'Please select a valid option.'}</small>
                    </span>
                )}
            </div>
        </div>
    );
};

const styles = {
    control: (baseStyles: any) => ({
        ...baseStyles,
        boxShadow: 'none',
        minWidth: '190px',
        padding: '1.204px 0px 1.204px 10px',
        borderRadius: '0.5rem'
    }),
    menu: (baseStyle: any) => ({
        ...baseStyle,
        zIndex: 9999
    }),
    valueContainer: (baseStyle: any) => ({
        ...baseStyle,
        padding: '0px'
    })
};
