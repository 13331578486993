import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { NavbarGroupItemProps } from './navbar-group-item.component';

interface ViewProps {
    handleTitleClick: () => void;
    openIndex: number | null;
    navbarGroupItemRef: React.RefObject<HTMLLIElement>;
    navbarItemRef: React.RefObject<HTMLDivElement>;
}

const useNavbarGroupItemController = (props: NavbarGroupItemProps): ViewProps => {
    const { pathname } = useLocation();
    const [openIndex, setOpenIndex] = useState<number | null>(null);
    const prevOpenIndexRef = useRef<number | null>(null);
    const navbarGroupItemRef = useRef<HTMLLIElement>(null);
    const navbarItemRef = useRef<HTMLDivElement>(null);

    const handleTitleClick = () => {
        setOpenIndex(prevOpenIndexRef.current === openIndex ? -1 : null);
    };

    useEffect(() => {
        if (pathname.includes(props.link.toLowerCase())) {
            setOpenIndex(-1);
        }
    }, [pathname]);

    const handleClickOutside = (event: MouseEvent) => {
        if (props.navbarRef.current && props.navbarRef.current.contains(event.target as Node)) {
            if (navbarItemRef.current && navbarItemRef.current.contains(event.target as Node)) return;
            if (navbarGroupItemRef.current && !navbarGroupItemRef.current.contains(event.target as Node)) {
                setOpenIndex(null);
            }
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return {
        handleTitleClick,
        navbarGroupItemRef,
        openIndex,
        navbarItemRef
    };
};

export default useNavbarGroupItemController;
