import React from 'react';

import useBreadcrumbs from './breadcrumbs.controller';

const ID = 'breadcrumbs';

export const Breadcrumbs = () => {
    const viewProps = useBreadcrumbs();

    return (
        <nav
            id={ID}
            aria-label='breadcrumbs'>
            <ol className='d-flex align-items-center breadcrumb bg-transparent m-0 p-0'>{viewProps.breadcrumbs}</ol>
        </nav>
    );
};
