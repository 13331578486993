import { PaginatedResponse } from '@frontend/api-utils';

export interface Rakinda {
    master_pin_code: string | null;
    name: string | null;
    update_timestamp: string;
    heartbeat_timestamp: string;
    status: string | null;
    identifier: string;
    id: string;
    creation_timestamp: string;
}

export type RakindaListResponse = PaginatedResponse<Rakinda>;

export interface CreateRakinda {
    name?: string | null;
    identifier: string;
    master_pin_code?: string | null;
}

export interface UpdateRakinda {
    name?: string | null;
    master_pin_code?: string | null;
}

export enum SearchRakindasQueryParams {
    PARTIAL_TEXT = 'partial_text'
}
