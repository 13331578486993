import { PackageType } from '@frontend/package/types';
import React from 'react';

import useAddToCartButtons from './add-to-cart-buttons.controller';

const ID = 'add-to-cart-buttons';
export interface AddToCartButtonsProps {
    type: PackageType | null;
    amount: number | null;
    changeAmount: (amount: number) => void;
    maxAmount?: number;
}

const AddToCartButtons = (props: AddToCartButtonsProps) => {
    const viewProps = useAddToCartButtons(props);

    return (
        <div
            id={ID}
            className='d-flex flex-row justify-content-center align-items-center w-100 mt-4'>
            <div
                className='btn-group'
                role='group'>
                <button
                    className='btn btn-secondary btn-lg fs-4'
                    disabled={viewProps.isDisabled(-10)}
                    onClick={() => viewProps.changeAmount(props.amount! - 10)}>
                    - 10
                </button>
                <button
                    className='btn btn-primary btn-lg fs-4'
                    disabled={viewProps.isDisabled(-1)}
                    onClick={() => viewProps.changeAmount(props.amount! - 1)}>
                    - 1
                </button>
                {viewProps.editing ? (
                    <input
                        type='number'
                        className='btn btn-lg fs-4 w-25'
                        value={props.amount || undefined}
                        step={1}
                        autoFocus
                        min={0}
                        onChange={(e) => viewProps.changeAmount(Number(e.target.value ?? 0))}
                        onBlur={() => viewProps.changeEditing(false)}
                    />
                ) : (
                    <button
                        onClick={() => viewProps.changeEditing(true)}
                        className='btn btn-lg fs-4'>
                        {props.amount}
                        {props.type === PackageType.PICK_UP && '/' + props.maxAmount}
                    </button>
                )}

                <button
                    className='btn btn-primary btn-lg fs-4'
                    disabled={viewProps.isDisabled(1)}
                    onClick={() => viewProps.changeAmount(props.amount! + 1)}>
                    + 1
                </button>
                <button
                    className='btn btn-secondary btn-lg fs-4'
                    disabled={viewProps.isDisabled(10)}
                    onClick={() => viewProps.changeAmount(props.amount! + 10)}>
                    + 10
                </button>
            </div>
        </div>
    );
};

export default AddToCartButtons;
