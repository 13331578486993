import { AuthenticationManager } from '@frontend/authentication-v2';
import { useState } from 'react';

interface ViewProps {
    authenticated: boolean;
    onLoginSucceeded: () => void;
}

const useAuthentication = (): ViewProps => {
    const [authenticated, changeAuthenticated] = useState<boolean>(AuthenticationManager.getInstance().hasValidToken());

    const onLoginSucceeded = (): void => {
        changeAuthenticated(AuthenticationManager.getInstance().hasValidToken());
    };

    return { authenticated, onLoginSucceeded };
};

export default useAuthentication;
