import React from 'react';

import { accountRequest } from '@frontend/account/events';
import { Account } from '@frontend/account/types';
import { EntityType } from '@frontend/common';
import { ObjectBadge } from '@frontend/rendering/badge';

export interface AccountBadgeProps {
    id?: string;
    name?: string;
}

export const AccountBadge = (props: AccountBadgeProps) => {
    return (
        <ObjectBadge<Account>
            id={props.id}
            type={EntityType.ACCOUNT}
            mapDisplayName={(ac: Account) => ac.name}
            resolve={accountRequest}
        />
    );
};
